import getLayout from "utilities/styles";
import getLanguage from "utilities/language";

export const AppActionTypes = {
  UPDATE_USER: "opslite/UPDATE_USER",
  UPDATE_IS_LOADING: "opslite/UPDATE_IS_LOADING",
  UPDATE_LAYOUT: "opslite/UPDATE_LAYOUT",
  UPDATE_GROUPS: "opslite/UPDATE_GROUPS",
  UPDATE_COURSES: "opslite/UPDATE_COURSES",
  UPDATE_FACILITATORS: "opslite/UPDATE_FACILITATORS",
  UPDATE_STAKE_MEMBERS: "opslite/UPDATE_STAKE_MEMBERS",
  UPDATE_NOTIFICATIONS: "opslite/UPDATE_NOTIFICATIONS",
  UPDATE_ALERTS: "opslite/UPDATE_ALERTS",
  UPDATE_PROMPTS: "opslite/UPDATE_PROMPTS",
  UPDATE_PROGRAMS: "opslite/UPDATE_PROGRAMS",
  UPDATE_PROVIDERS: "opslite/UPDATE_PROVIDERS",
  UPDATE_STUDENTS: "opslite/UPDATE_STUDENTS",
  UPDATE_LANGUAGE: "opslite/UPDATE_LANGUAGE",
  UPDATE_KIOSK_MODE: "opslite/UPDATE_KIOSK_MODE",
  CREATE_GROUP: "opslite/ADD_GROUP",
  EDIT_GROUP: "opslite/EDIT_GROUP",
  UPDATE_ENROLLMENTS: "opslite/UPDATE_ENROLLMENTS",
  EDIT_ENROLLMENT_STATUSES: "opslite/EDIT_ENROLLMENT_STATUSES",
  UPDATE_PARTICIPANTS: "opslite/UPDATE_PARTICIPANT"
};

const initState = {
  isAuthenticated: false,
  layout: getLayout(),
  groups: null,
  enrollments: null,
  courses: null,
  facilitators: null,
  notifications: [],
  alerts: [],
  prompts: [],
  programs: [],
  providers: [],
  mimicProvider: {},
  language: getLanguage(),
  stakeMembers: {}
};

const appReducer = (state = initState, action) => {
  switch (action.type) {
    case AppActionTypes.UPDATE_LAYOUT:
      state = {
        ...state,
        layout: action.payload,
      };
      break;
    case AppActionTypes.UPDATE_GROUPS:
      state = {
        ...state,
        groups: action.payload,
      };
      break;
    case AppActionTypes.UPDATE_COURSES:
      state = {
        ...state,
        courses: action.payload,
      };
      break;
    case AppActionTypes.UPDATE_FACILITATORS:
      state = {
        ...state,
        facilitators: action.payload,
      };
      break;
    case AppActionTypes.UPDATE_STAKE_MEMBERS: {
      let newStakeMembers = {
        ...state.stakeMembers,
      };
      newStakeMembers[action.payload.stakeUnitNumber] =
        action.payload.stakeMembers;
      state = {
        ...state,
        stakeMembers: newStakeMembers,
      };
      break;
    }
    case AppActionTypes.UPDATE_NOTIFICATIONS:
      state = {
        ...state,
        notifications: action.payload,
      };
      break;
    case AppActionTypes.UPDATE_ALERTS:
      state = {
        ...state,
        alerts: action.payload,
      };
      break;
    case AppActionTypes.UPDATE_PROMPTS:
      state = {
        ...state,
        prompts: action.payload,
      };
      break;
    case AppActionTypes.UPDATE_PROGRAMS:
      state = {
        ...state,
        programs: action.payload,
      };
      break;
    case AppActionTypes.UPDATE_PROVIDERS:
      state = {
        ...state,
        providers: action.payload,
      };
      break;
    case AppActionTypes.UPDATE_STUDENTS:
      state = {
        ...state,
        students: action.payload,
      };
      break;
    case AppActionTypes.UPDATE_LANGUAGE:
      state = {
        ...state,
        language: action.payload,
      };
      break;
    case AppActionTypes.CREATE_GROUP:
      state = {
        ...state,
        groups: [...state.groups, action.payload],
      };
      break;
    case AppActionTypes.EDIT_GROUP:
      if (state.groups) {
        const newGroups = [...state.groups];
        const index = newGroups.findIndex(
          (group) => group.id == action.payload.id
        );
        if (index !== -1) {
          newGroups[index] = action.payload;
          state = {
            ...state,
            groups: newGroups,
          };
        }
      }
      break;
    case AppActionTypes.UPDATE_ENROLLMENTS:
      state = {
        ...state,
        enrollments: action.payload
      };
      break;
    case AppActionTypes.EDIT_ENROLLMENT_STATUSES:
      if (state.enrollments) {
        const newEnrollments = [...state.enrollments];
        action.payload.forEach(enrollmentUpdate => {
          const studentEnrollmentIndex = newEnrollments.findIndex(enrollment => enrollment.studentId == enrollmentUpdate.studentId && enrollment.isLimitedStudent == enrollmentUpdate.isLimitedStudent);
          if (studentEnrollmentIndex != -1) {
            const courseIndex = newEnrollments[studentEnrollmentIndex].courses.findIndex(enrollmentCourse => enrollmentCourse.groupId == enrollmentUpdate.groupId);
            if (courseIndex != -1) {
              newEnrollments[studentEnrollmentIndex].courses[courseIndex].status = enrollmentUpdate.status;
              newEnrollments[studentEnrollmentIndex].courses[courseIndex].date = new Date(Date.now());
            }
          }
        });
        state = {
          ...state,
          enrollments: newEnrollments
        };
        break;
      }
      break;
    case AppActionTypes.UPDATE_PARTICIPANTS:
      if (state.groups) {
        const newGroups = [...state.groups];
        action.payload.forEach(updatedQuickRegParticipant => {
          const groupIndex = newGroups.findIndex(group => group.id == updatedQuickRegParticipant.groupId);
          if (groupIndex != -1) {
            const participantIndex = newGroups[groupIndex].quickRegParticipants.findIndex(participant => participant.id == updatedQuickRegParticipant.id);
            if (participantIndex != -1) {
              newGroups[groupIndex].quickRegParticipants[participantIndex] = updatedQuickRegParticipant.participant;
            }
          }
        });
        state = {
          ...state,
          groups: newGroups
        };
        break;
      }
      break;
  }
  return state;
};

export default appReducer;
