import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import "./paginator.scss";
const Paginator = (props) => {

  const [dataset, setDataSet] = useState(props.dataset || []);
  const [recordsPerPage, setRecordsPerPage] = useState(props.recordsPerPage || 30);
  const [currentPageNumber, setCurrentPageNumber] = useState(props?.startPage || 1);
  const numberOfPages = Math.ceil(dataset.length / recordsPerPage);
  const startIndex = recordsPerPage * (currentPageNumber - 1);
  const endIndex = ((startIndex + (recordsPerPage - 1)) < dataset.length) ? startIndex + (recordsPerPage - 1) : dataset.length - 1;
  const maxPagesToDisplay = 5; //Number of page buttons to display at one time before cutting off with ellipses. For best results use an odd number

  useEffect(() => {
    setDataSet(props.dataset);
    setRecordsPerPage(props.recordsPerPage || 30);
    setCurrentPageNumber(props?.startPage || 1);
  }, [props.dataset]);
  useEffect(() => {
    updateParent();
  }, [currentPageNumber, dataset, recordsPerPage]);
  const getRecordsForCurrentPage = () => {
    const currentPageRecords = [];
    if (dataset.length) {
      for (let i = startIndex; i <= endIndex; i++) {
        currentPageRecords.push(dataset[i]);
      }
    }
    return currentPageRecords;
  };
  const updateParent = () => {
    if (props.onPageChange) {
      props.onPageChange({ number: currentPageNumber, records: getRecordsForCurrentPage() });
    }
  };
  const handlePageNumberClick = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= numberOfPages) {
      setCurrentPageNumber(pageNumber);
    }
  };
  const decrementRange = () => {
    const newPageNumber = currentPageNumber - maxPagesToDisplay;
    if (newPageNumber > 0) {
      setCurrentPageNumber(newPageNumber);
    } else {
      return 1;
    }
  };
  const incrementRange = () => {
    const newPageNumber = currentPageNumber + maxPagesToDisplay;
    if (newPageNumber < numberOfPages) {
      setCurrentPageNumber(newPageNumber);
    } else {
      setCurrentPageNumber(numberOfPages);
    }
  };
  const getStartPage = () => {
    if (numberOfPages > maxPagesToDisplay) {
      const range = Math.floor(maxPagesToDisplay / 2);
      const possibleStartPage = currentPageNumber - range;
      if (possibleStartPage <= 0) {
        return currentPageNumber;
      } else {
        return possibleStartPage;
      }
    } else {
      return 1;
    }
  };
  return (
    <div className="paginatorContainer">
      <button className={`firstPage ${currentPageNumber <= 1 ? "disabled" : ""}`} onClick={() => handlePageNumberClick(1)}>{"<<"}</button>
      <button className={`previousPage ${currentPageNumber <= 1 ? "disabled" : ""}`} onClick={() => handlePageNumberClick(currentPageNumber - 1)}>{"<"}</button>
      {(() => {
        let numOfPagesToDisplay = (numberOfPages <= maxPagesToDisplay) ? numberOfPages : maxPagesToDisplay;
        let startPage = getStartPage();
        let buttons = [];
        if (startPage > 1) {
          buttons.push(<button key={"page-decrement-range"} onClick={decrementRange}>...</button>);
        }
        for (let i = 0; i < numOfPagesToDisplay; i++) {
          const iteratingPage = startPage + i;
          if (iteratingPage > numberOfPages) {
            break;
          }
          buttons.push(<button key={`page-${iteratingPage}`} className={`specificPage ${iteratingPage == currentPageNumber ? "disabled" : ""}`} onClick={() => handlePageNumberClick(iteratingPage)}>{iteratingPage}</button>);
        }
        if (startPage + maxPagesToDisplay < numberOfPages) {
          buttons.push(<button key={"page-increment-range"} onClick={incrementRange}>...</button>);
        }
        return buttons;
      })()}
      <button className={`nextPage ${currentPageNumber >= numberOfPages ? "disabled" : ""}`} onClick={() => handlePageNumberClick(currentPageNumber + 1)}>{">"}</button>
      <button className={`lastPage ${currentPageNumber >= numberOfPages ? "disabled" : ""}`} onClick={() => handlePageNumberClick(numberOfPages)}>{">>"}</button>
      {!props.recordsPerPage &&
        <div className="recordsPerPageInputContainer">
          <label><FormattedMessage id="per_page" defaultMessage="Per Page:" /></label>
          <select defaultValue={recordsPerPage} onChange={(event) => setRecordsPerPage(event.target.value)}>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      }
      <span className="pageIndicator">{startIndex + 1} - {endIndex <= 0 ? 1 : endIndex + 1} of {dataset.length <= 0 ? 1 : dataset.length}</span>
    </div>
  );
};
export default Paginator;