import axios, { AxiosResponse } from "axios";
import config from "../axios.config.json";
import { FeatureFlagDto } from "../../model";
import { ApiUrl } from "constants/api-auth-constants";

const FeatureFlagControllerPath = `${ApiUrl}feature-flags`;

export const getFeatureFlagValue = async (featureFlagName: string): Promise<FeatureFlagDto> => {

  const response: AxiosResponse<FeatureFlagDto> = await axios.get(
    `${FeatureFlagControllerPath}/feature-flag/${featureFlagName}`,
    { ...config }
  );

  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }

};