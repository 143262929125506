import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useClassName from "utilities/useClassName";
import { StakeMemberDto } from "lib/api/backend/model";
import { GuestFacilitator, StandardFacilitator } from "lib/data/model";

export interface IStakeMemberTypeAheadProps {
  className?: string;
  facilitator: StandardFacilitator | GuestFacilitator;
  stakeMembers: StakeMemberDto[];
  availableStakeMembers: StakeMemberDto[];
  onChange?: (facilitator: StandardFacilitator | GuestFacilitator) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  onClear: () => void;
}

export const StakeMemberTypeAhead: React.VFC<IStakeMemberTypeAheadProps> = (props) => {

  const [className, setPropsClassNames] = useClassName("stakeMemberTypeAheadContainer");
  const [stakeMember, setStakeMember] = useState<StakeMemberDto>();
  const intl = useIntl();
  const missionaryTranslation = intl.formatMessage({ id: "missionary", defaultMessage: "Missionary" });

  useEffect(
    () => {
      if (!stakeMember && props.facilitator instanceof StandardFacilitator) {
        const matchingStakeMember = props.stakeMembers.find(sm => sm?.id === (props.facilitator as StandardFacilitator).cmisId);
        if (matchingStakeMember) {
          selectMember(matchingStakeMember, false);
        }
      } else if (stakeMember && props.facilitator instanceof GuestFacilitator) {
        setStakeMember(undefined);
      }
    },
    [props.facilitator]
  );

  useEffect(
    () => {
      if (props.className) {
        setPropsClassNames(props.className);
      }
      else {
        setPropsClassNames();
      }
    },
    [props.className]
  );

  const handleAutoCompleteChange = (event, newValue: string | StakeMemberDto | null) => {
    if (newValue) {
      if (newValue instanceof StakeMemberDto) {
        selectMember(newValue);
      }
      else {
        selectGuest(newValue);
      }
    } else {
      if (props.onClear) {
        props.onClear();
      }
    }
  };

  const selectMember = (selectedMember: StakeMemberDto, fireOnChange = true) => {
    setStakeMember(selectedMember);
    if (props.onChange && fireOnChange) {
      props.onChange(
        new StandardFacilitator({
          cmisId: selectedMember.id,
          firstName: selectedMember.firstName,
          lastName: selectedMember.lastName,
          gender: "",
          birthDate: selectedMember.birthDate
        })
      );
    }
  };

  const selectGuest = (guestName: string, fireOnChange = true) => {

    //for cases where the user focuses on the input, makes no changes and removes focus
    const memberActuallyChanged = stakeMember?.displayName != guestName;

    if (memberActuallyChanged) {
      setStakeMember(undefined);
      if (guestName !== "" && props.onChange && fireOnChange) {
        props.onChange(
          new GuestFacilitator({
            name: guestName
          })
        );
      }
    }
  };

  const getDisplayName = (member: StakeMemberDto | string) => {
    if (member instanceof StakeMemberDto) {
      return `${member.displayName}${member.isMissionary ? ` (${missionaryTranslation})` : ""}`;
    } else {
      return member.trim();
    }
  };

  return (
    <div className={className}>
      <Autocomplete
        freeSolo={true}
        onChange={handleAutoCompleteChange}
        value={
          stakeMember
          ?? (
            props.facilitator instanceof GuestFacilitator
              ? props.facilitator.name
              : ""
          )
        }
        options={props.availableStakeMembers}
        getOptionLabel={option => getDisplayName(option)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {getDisplayName(option)}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({ id: "facilitator", defaultMessage: "Facilitator" })}
            onBlur={(event) => selectGuest(event.target.value)}
            error={props.error}
            helperText={props.helperText}
          />
        )}
      />
    </div>
  );

};



export default StakeMemberTypeAhead;