import { ReactElement, useEffect, useState } from "react";
import TruncatedParticipantName from "components/truncated-participant-name";
import Card from "components/card/card";
import PrintCertificateButton from "components/print-certificate-button-v3";
import ParticipantActionMenu from "components/participant-action-menu";
import TruncatedEmail from "components/truncated-email";
import StudentStatuses from "constants/student-constants";
import { FormattedMessage } from "react-intl";
import AuthService from "auth/authorize-service";
import { Permissions } from "constants/api-auth-constants";
import Paginator from "components/paginator/paginator";
import { LoadingStatus } from "lib/data/model";
import "./group-participant-list-card-view.scss";
import { FullGroup, QuickRegParticipant } from "lib/models-v2";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ParticipantRegistrationDate from "components/participant-registration-date";
import ParticipantStatus from "components/participant-status";
import { Link } from "react-router-dom";

interface IGroupStudentListCardView {
  group: FullGroup;
  students: QuickRegParticipant[];
  onStudentLoadingChange: (loading: LoadingStatus) => void;
  onSelectionChange: (
    checked: boolean,
    student: QuickRegParticipant,
    accessor: string
  ) => void;
  onAllSelectionChange: (checked: boolean) => void;
  isSelected?: (student: QuickRegParticipant) => boolean;
  updateConsents: () => Promise<void>;
  setSelectedStudents: (value: any) => void;
  removeStudents: () => Promise<void>;
  printCerts: (isParticipation: boolean) => Promise<any>;
  actions: any;
}

export const GroupParticipantListCardView = (
  props: IGroupStudentListCardView
): ReactElement => {
  const [selectAllStudents, setSelectAllStudents] = useState<boolean>(false);
  const [paginatedStudents, setPaginatedStudents] = useState<QuickRegParticipant[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const canPrintCompletionCerts = props.group
    ? AuthService.userCan(
      [Permissions.PrintStudentCompletionCertificatesWrite],
      props.group.stakeUnitNumber
    )
    : false;

  const handlePageChange = (page) => {
    setPaginatedStudents(page.records);
    setPageNumber(page.number);
  };

  const handleChange = (clickedStudent: QuickRegParticipant) => {
    setSelectAllStudents(false);
    const updatedStudents = paginatedStudents.map((participant) => {
      if (participant.id === clickedStudent.id) {
        return {
          ...participant,
          isSelected: !participant.isSelected,
        };
      }
      return participant;
    });
    setPaginatedStudents(updatedStudents);
    const students = updatedStudents.filter(
      (student) => student.isSelected === true
    );
    props.setSelectedStudents(students);
  };

  const makeCard = (participant: QuickRegParticipant) => {
    if (props.group) {
      const cardHead = (
        <div className="classCardHead">
          <div className="leftHead">
            <div className="groupHeading">
              <Checkbox
                checked={participant.isSelected}
                onChange={() => handleChange(participant)}
              />
              <Link to={`/participant/${participant.id}/group/${participant.groupId}`}>
                <TruncatedParticipantName participant={participant} />
              </Link>
            </div>
          </div>
          <div className="rightHead"></div>
        </div>
      );

      return (
        <Card head={cardHead}>
          <div className="column">
            <div className="dataContainer">
              <div className="dataHeading">
                <FormattedMessage
                  id="church_account"
                  defaultMessage="Church Account"
                />
              </div>
              <div className="data access">
                {participant?.cmisId ? <CloseIcon /> : <CheckIcon />}
              </div>
            </div>
            <div className="dataContainer">
              <div className="dataHeading">
                <FormattedMessage id="email" defaultMessage="Email" />
              </div>
              <div className="data email">
                {participant.email && participant.email !== "" ? (
                  <TruncatedEmail email={participant.email} isInsideCard={true} />
                ) : (
                  <>- - - - -</>
                )}
              </div>
            </div>
            <div className="dataContainer">
              <div className="dataHeading">
                <FormattedMessage
                  id="phone_number"
                  defaultMessage="Phone Number"
                />
              </div>
              <div className="data phone">
                {participant?.phoneNumber ?
                  `${participant.dialCode ? `+${participant.dialCode}-` : ""}${participant.phoneNumber}`
                  :
                  "---"}
              </div>
            </div>

            <div className="dataContainer">
              <div className="dataHeading">
                <FormattedMessage id="status" defaultMessage="Status" />
              </div>
              <div className="data status">
                {participant?.status == StudentStatuses.Completed &&
                  ((props.group.hasCompletionCertificate && canPrintCompletionCerts) ||
                    props.group.hasParticipationCertificate) ?
                  <PrintCertificateButton
                    participant={participant}
                    groupId={props.group.id}
                    groupName={props.group.name}
                    stakeUnitNumber={props.group.stakeUnitNumber}
                    hasCompletionCertificate={
                      props.group.hasCompletionCertificate
                    }
                    hasParticipationCertificate={
                      props.group.hasParticipationCertificate
                    }
                  />
                  :
                  <ParticipantStatus status={participant.status} />
                }
              </div>
            </div>

            {participant.createdDate &&
              <div className="dataContainer">
                <div className="dataHeading">
                  <FormattedMessage id="sign_up_date" defaultMessage="Sign Up Date" />
                </div>
                <div className="data signUpDate">
                  <ParticipantRegistrationDate date={participant.createdDate} />
                </div>
              </div>
            }

          </div>
          <div className="column actionContainer">
            <ParticipantActionMenu
              participant={participant}
              stakeUnitNumber={props.group.stakeUnitNumber}
              groupName={props.group.name}
              groupStatus={props.group.status}
              hasCompletionCertificate={props.group.hasCompletionCertificate}
              hasParticipationCertificate={
                props.group.hasParticipationCertificate
              }
              onLoadingChange={props.onStudentLoadingChange}
              borderOnToggle={true}
            />
          </div>
        </Card>
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (props.students.length) {
      props.students.forEach((student) => {
        student.isSelected = false;
      });
    }
  }, [props.students]);

  const handleAllSelection = () => {
    if (paginatedStudents.every((student) => student["isSelected"] === true)) {
      setSelectAllStudents(false);
      paginatedStudents.forEach((paginatedStudent) => {
        paginatedStudent["isSelected"] = false;
      });
    } else {
      setSelectAllStudents(true);
      paginatedStudents.forEach((paginatedStudent) => {
        paginatedStudent["isSelected"] = true;
      });
    }
    const anyStudentSelected = paginatedStudents.some(
      (student) => student.isSelected === true
    );
    setIsSelectAll(anyStudentSelected);
    props.setSelectedStudents(paginatedStudents);
  };

  return (
    <>
      <div className="studentcard-view studentcard-main-view">
        {paginatedStudents && (
          <>
            <div className="select_all">
              <Checkbox
                checked={selectAllStudents}
                onChange={handleAllSelection}
              ></Checkbox>
              <span className="p-0">
                {isSelectAll ? (
                  <FormattedMessage
                    id="deselect_all"
                    defaultMessage="Deselect All"
                  />
                ) : (
                  <FormattedMessage
                    id="select_all"
                    defaultMessage="Select All"
                  />
                )}
              </span>
            </div>
          </>
        )}
        {props.actions}
      </div>
      <div className="groupStudentListCardViewContainer">
        <div className="cardList">
          {paginatedStudents &&
            paginatedStudents.map((student, studentIndex) => {
              return (
                <div className="studentCard" key={`student-${studentIndex}`}>
                  {makeCard(student)}
                </div>
              );
            })}
        </div>
        <Paginator
          dataset={props.students}
          onPageChange={handlePageChange}
          startPage={pageNumber}
        />
      </div>
    </>
  );
};

export default GroupParticipantListCardView;
