import { useContext, useEffect, useState } from "react";
import { FormStatus } from "lib/form/model";
import { FormStatusContext } from "./FormStatusContext";

export const useFormStatus = (): [FormStatus, (value: FormStatus) => void] => {
  const state = useContext(FormStatusContext);

  const [currentFormStatus, setCurrentFormStatus] = useState(state.formStatus);

  useEffect(
    () => {
      setCurrentFormStatus(state.formStatus);
    },
    [state.formStatus]
  );

  const setFormStatus = (value: FormStatus) => {
    if (state.setFormStatus) {
      state.setFormStatus(value);
    }
  };

  return [currentFormStatus, setFormStatus];
};