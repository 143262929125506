//Filters out all courses whose language is not in the passed list
export function filterCourseByLanguage(courses, languageList) {
  if (languageList?.length > 0 && courses?.length > 0) {
    let filteredCourses = courses.filter(course => {
      if (course.languageName) {
        return languageList.includes(course.languageName);
      } else {
        return false;
      }
    });
    return filteredCourses;
  } else {
    return courses;
  }
}


