import { StandardFacilitator } from "lib/data/model";
import FacilitatorDto from "../model/facilitator/FacilitatorDto";
import { IDtoToModelConverter } from "./IDtoConverter";

export class FacilitatorDtoToStandardFacilitatorConverter implements IDtoToModelConverter<FacilitatorDto, StandardFacilitator> {
  public toModel = (dto: FacilitatorDto): StandardFacilitator => {
    const facilitator = new StandardFacilitator({
      id: dto.id ?? 0,
      cmisId: dto.cmisId ?? 0,
      firstName: dto.firstName,
      lastName: dto.lastName,
      gender: dto.gender,
      birthDate: dto.birthDate
    });

    return facilitator;
  };
}