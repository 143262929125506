import { GuestFacilitator } from "lib/data/model";
import { FacilitatorDto } from "../model/facilitator";
import { IModelToDtoConverter } from "./IDtoConverter";

export class GuestFacilitatorToFacilitatorDtoConverter implements IModelToDtoConverter<GuestFacilitator, FacilitatorDto> {
  public toDto = (model: GuestFacilitator): FacilitatorDto => {
    const facilitator = new FacilitatorDto({
      id: model.id,
      firstName: "",
      lastName: "",
      name: model.name,
      displayName: "",
      gender: "",
      birthDate: undefined,
      age: 0,
      cmisId: 0
    });

    return facilitator;
  };
}