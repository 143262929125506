import "./group-list.scss";
import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import Loading from "components/loading/loading";
import AuthService from "auth/authorize-service";
import { Permissions } from "constants/api-auth-constants";
import UserCan from "components/user-can/user-can";
import { redirectToServerSignOut, setCourseSignupSession } from "utilities/navUtils";
import GroupListControls from "./group-list-controls/group-list-controls";
import Layouts from "constants/layout-constants";
import NoticeCreation from "lib/data/model/NoticeCreation";
import GroupListCard from "./group-list-card/group-list-card";
import GroupListTable from "./group-list-table/group-list-table";
import { CourseSignUpBaseUrl } from "routes";
import dispatchers from "datastore/dispatcher";
import { useSelector, useDispatch } from "react-redux";
import { getUserGroupsV2 } from "lib/api/backend/requests/groups";
import type { FullGroup } from "lib/models-v2/LeadersGroupResponse";
import { ServerResponseType } from "lib/api/backend/request-utils";
import { ProviderProgramLanguageDto } from "lib/api/backend/model/provider";
import useGroupStore from "datastore/groups";
import { LoadingStatus } from "lib/data/model";
import AuthorizeService from "auth/authorize-service";
import { MaxUnitNumbersPerFetch } from "constants/group-constants";

function userHasPermissionToAddParticipants(unitNumber: number): boolean {
  const result = AuthService.userCan([Permissions.CourseSignUpWrite], unitNumber);
  return result;
}

export enum ViewNames {
  Table = "List View",
  Card = "Card View"
}

const GroupList = () => {

  const layout = useSelector((state: any) => state.appReducer.layout);
  const reduxDispatch = useDispatch();
  const notify = dispatchers(reduxDispatch).notify as (notice: NoticeCreation) => void;

  const store = useGroupStore();
  const zustandGroups = store.groups;
  const renderedActiveUnit = useRef<number | null>(store.activeUnitNumber);
  const zustandFilteredGroups = store.filteredGroups;
  const isDesktop = layout == Layouts.Desktop;
  const [programLanguages, setProgramLanguages] = useState<ProviderProgramLanguageDto[]>([]);
  const [loading, setLoading] = useState<LoadingStatus>({ status: false, message: "" });
  const [showView, setShowView] = useState(isDesktop ? ViewNames.Table : ViewNames.Card);

  useEffect(() => {
    if (!store.userGroupsFetchCompleted || renderedActiveUnit.current != store.activeUnitNumber) {
      renderedActiveUnit.current = store.activeUnitNumber;
      getGroupsV2();
    }
  }, [store.activeUnitNumber]);

  const getGroupsV2 = async () => {
    setLoading({ status: true, message: <FormattedMessage id="getting_groups" defaultMessage="Getting Groups" /> });

    const activeUnit = (() => {
      if (AuthorizeService?.authorizedUnits && AuthorizeService.authorizedUnits.length) {
        if (store.activeUnitNumber) {
          return AuthorizeService.authorizedUnits.find(unit => unit.unitNumber == store.activeUnitNumber);
        } else if (AuthorizeService.authorizedUnits.length > MaxUnitNumbersPerFetch) {
          store.setActiveUnitNumber(AuthorizeService.authorizedUnits[0].unitNumber);
          return AuthorizeService.authorizedUnits[0];
        }
      }
      return null;
    })();

    const response = await getUserGroupsV2(activeUnit ? [activeUnit.unitNumber] : undefined);

    if (response!.responseType == ServerResponseType.Success) {
      store.setGroups(response!.data.groupsList, true);
      setProgramLanguages(response!.data.programLanguages);
      setLoading({ status: false, message: "" });
    }
    else {
      store.setGroups([]);
      setLoading({ status: false, message: "" });
      notify({ status: "error", message: <FormattedMessage id="get_groups_error" defaultMessage="Could not get groups." /> });
    }
  };

  const courseSignup = (event, group: FullGroup) => {
    if (group && userHasPermissionToAddParticipants(group.stakeUnitNumber)) {
      event.preventDefault();

      setCourseSignupSession(group.providerId, group, `${window.location.origin}${window.location.pathname}`);

      // sign facilitator out automatically
      redirectToServerSignOut({ returnUrl: `${window.location.origin}${CourseSignUpBaseUrl}/${group.id}` });
    }
  };

  const handleFilteredData = (newFilteredGroups: FullGroup[]) => {
    store.setFilteredGroups(newFilteredGroups);
  };

  const buildCardLayout = () => {
    return (
      <div className="group-list-tablet-mobile">
        {zustandFilteredGroups && zustandFilteredGroups.map((group, groupIndex) => {
          return (
            <React.Fragment key={`group-card-${groupIndex}`}>
              <GroupListCard group={group} onCourseSignup={courseSignup} />
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <UserCan perform={[Permissions.GroupIndexRead]} showFail={true}>
      <div className="courseGroupListContainer hasLoader">
        <Loading loading={loading.status} message={loading.message} blackout={true} />
        <GroupListControls isLoading={loading.status} viewValue={showView} viewSetter={setShowView} groups={zustandGroups} programLanguages={programLanguages} onFilter={handleFilteredData} />
        {zustandFilteredGroups.length > 0 ?
          <>
            {
              showView == ViewNames.Table
                && isDesktop
                ? (
                  <div className="group-list-desktop">
                    <GroupListTable groups={zustandFilteredGroups} onCourseSignup={courseSignup} />
                  </div>
                )
                : buildCardLayout()}
          </>
          :
          <>
            {!loading.status &&
              <FormattedMessage id="no_groups" defaultMessage="No groups match your criteria." />
            }
          </>
        }
      </div>
    </UserCan>
  );
};

export default GroupList;
