import React, { ReactElement, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Global } from "@emotion/react";
import Skeleton from "@mui/material/Skeleton";

const drawerBleeding = 56;

const Root = styled("div")(() => ({
  height: "100%",
  backgroundColor: "#fff",
}));

const StyledBox = styled(Box)(() => ({
  backgroundColor: "#fff",
}));

const Puller = styled(Box)(() => ({
  width: 30,
  height: 6,
  backgroundColor: "#e0e0e0",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const Peak = styled("div")(() => ({
  margin: "16px"
}));

interface IMuiSwipablePanel {
  peakContent: ReactElement;
  children: ReactElement | ReactElement[];
  anchor: "bottom" | "left" | "right" | "top" | undefined;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  isLoading?: boolean;
}

export const MuiSwipablePanel = (props: IMuiSwipablePanel): ReactElement => {

  const [drawerOpen, setDrawerOpen] = useState<boolean>(props.open != undefined ? props.open : false);

  useEffect(() => {
    if (props.open != undefined) {
      setDrawerOpen(props.open);
    }
  }, [props.open]);

  const onOpen = () => {
    setDrawerOpen(true);
    if (props.onOpen) {
      props.onOpen();
    }
  };

  const onClose = () => {
    setDrawerOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(75% - ${drawerBleeding}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        anchor={props.anchor}
        open={drawerOpen}
        onClose={onClose}
        onOpen={onOpen}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            backgroundColor: "#fff",
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Peak>
            {props.peakContent}
          </Peak>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          {props.isLoading ?
            <Skeleton variant="rectangular" height="100%" />
            :
            props.children
          }
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
};

export default MuiSwipablePanel;