import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import MapsLoader from "components/maps-loader";
import * as GroupFilters from "utilities/filters/group-filters";
import { GroupAgeTypes, GroupMeetingTypes, GroupStatuses } from "constants/group-constants";
import { getLocaleDateString, getHHMM } from "utilities/time";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ListControlPanel, { ControlInputType, ControlInput, FilterConfig, FilterInputType } from "components/list-control-panel";
import InputAdornment from "@mui/material/InputAdornment";
import PlaceIcon from "@mui/icons-material/Place";
import TextField from "@mui/material/TextField";
import Layouts from "constants/layout-constants";
import SearchIcon from "@mui/icons-material/Search";
import usePreloadStore, { getLanguages } from "datastore/preload";
import { CourseDto, CourseLanguageDto } from "lib/api/backend/model/course";
import useGroupFinderStore from "../group-finder-store";
import GroupFinderView from "../models";
import { Autocomplete } from "@react-google-maps/api";
import { calculateBounds, getBoundsFromGoogleMap, getLocationResultFromCoords } from "utilities/geolocation";
import { GroupDetails } from "../../../lib/models-v2";
import { MapLocation } from "lib/data/model";

export const groupFinderFilterStateStorageName = "groupFinderFilters";

interface IGroupFinderListControls {
  availablePrograms: CourseDto[];
  searchArea: (area?: MapLocation) => void;
}

const GroupFinderListControls: React.FC<IGroupFinderListControls> = (props) => {

  const layout = useSelector((state: any) => state.appReducer.layout);
  const language: CourseLanguageDto = useSelector((state: any) => state.appReducer.language);
  const intl = useIntl();
  const languages = usePreloadStore(getLanguages);
  const [placesApi, setPlacesApi] = useState<google.maps.places.Autocomplete | null>(null);
  const joinNowStore = useGroupFinderStore();
  const { isLoading, availableGroups, currentView, searchItem, currentLocation, defaultMapZoom, defaultSearchRadius, setFilteredResults, setSearchItem, setCurrentLocation, mapInView } = joinNowStore;
  const { availablePrograms: availableCourses, searchArea } = props;

  const availableGroupTypes = availableCourses.filter(c => joinNowStore.availableGroupTypeIds.includes(c.id!));

  useEffect(() => {
    setSearchItem("");
  }, [currentView]);

  const createFilterConfig = (dataset: GroupDetails[], availableCourses: CourseDto[]) => {

    if (!dataset || !availableCourses) {
      return [];
    }

    const groupAgeTypes = {
      youth: {
        displayText: intl.formatMessage({ id: "group_age_type_youth", defaultMessage: "Youth only" }),
        value: GroupAgeTypes.Youth
      },
      adult: {
        displayText: intl.formatMessage({ id: "group_age_type_adult", defaultMessage: "Adult only" }),
        value: GroupAgeTypes.Adult
      },
      combined: {
        displayText: intl.formatMessage({ id: "group_age_type_combined", defaultMessage: "All Ages" }),
        value: GroupAgeTypes.Combined
      }
    };

    const groupMeetingTypes = {
      inPerson: {
        displayText: intl.formatMessage({ id: "meeting_in_person", defaultMessage: "In Person" }),
        value: GroupMeetingTypes.InPerson
      },
      virtual: {
        displayText: intl.formatMessage({ id: "virtual", defaultMessage: "Virtual" }),
        value: GroupMeetingTypes.Virtual
      }
    };

    const groupStatuses = {
      pending: {
        displayText: intl.formatMessage({ id: "pending", defaultMessage: "Pending" }),
        value: GroupStatuses.Pending
      },
      inProgress: {
        displayText: intl.formatMessage({ id: "in_progress", defaultMessage: "In Progress" }),
        value: GroupStatuses.InProgress
      },
      upcoming: {
        displayText: intl.formatMessage({ id: "upcoming", defaultMessage: "Upcoming" }),
        value: GroupStatuses.Upcoming
      },
    };

    const availableLanguages: CourseLanguageDto[] = [];

    let beginDates: any[] = [];
    let startTimes: string[] = [];

    dataset.forEach((group) => {

      if (group.startDate && !beginDates.includes(group.startDate)) {
        beginDates.push(group.startDate);
      }

      if (group.meetingTimes) {
        group.meetingTimes.forEach(meetingTime => {
          const meetingStartString = getHHMM(meetingTime.startTime);
          if (!startTimes.includes(meetingStartString)) {
            startTimes.push(meetingStartString);
          }
        });
      }
      if (group?.languages) {
        group.languages.forEach(groupLanguage => {
          if (!availableLanguages.some(availableLanguage => availableLanguage.id == groupLanguage.id)) {
            const lang = languages.find(lang => lang.id == groupLanguage.id);
            if (lang) {
              availableLanguages.push(lang);
            }
          }
        });
      }

    });

    beginDates = beginDates.map((date) => {
      return {
        value: date,
        displayText: getLocaleDateString(date)
      };
    });

    startTimes = startTimes.sort();

    const newFilterConfig: FilterConfig = [
      {
        filterGroupHeading: intl.formatMessage({ id: "group_status_types", defaultMessage: "Group, Status, and Types" }),
        filters: [
          {
            name: intl.formatMessage({ id: "group_type", defaultMessage: "Group Type" }),
            allPossibleValues: availableGroupTypes,
            filterFunction: GroupFilters.removeGroupByCourse,
            displayField: "name",
            inputType: FilterInputType.TypeAhead,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "group_type", defaultMessage: "Group Type" }),
          },
          {
            name: intl.formatMessage({ id: "status", defaultMessage: "Status" }),
            allPossibleValues: Object.keys(groupStatuses).map(key => groupStatuses[key]),
            displayField: "displayText",
            valueField: "value",
            filterFunction: GroupFilters.removeGroupByStatus,
            inputType: FilterInputType.Checkbox,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "status", defaultMessage: "Status" }),
          },

          {
            name: intl.formatMessage({ id: "age_range", defaultMessage: "Age Range" }),
            allPossibleValues: Object.keys(groupAgeTypes).map(key => groupAgeTypes[key]),
            displayField: "displayText",
            valueField: "value",
            filterFunction: GroupFilters.removeGroupByAgeType,
            inputType: FilterInputType.Checkbox,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "age_range", defaultMessage: "Age Range" }),
          },

          {
            name: intl.formatMessage({ id: "meeting_type", defaultMessage: "Meeting Type" }),
            allPossibleValues: Object.keys(groupMeetingTypes).map(key => groupMeetingTypes[key]),
            filterFunction: GroupFilters.removeGroupByMeetingType,
            displayField: "displayText",
            valueField: "value",
            inputType: FilterInputType.Radio,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "meeting_type", defaultMessage: "Meeting Type" })
          },
        ]
      },
      {
        filterGroupHeading: intl.formatMessage({ id: "day_and_time", defaultMessage: "Date and Time" }),
        filters: [
          {
            name: intl.formatMessage({ id: "start_date", defaultMessage: "Start Date" }),
            allPossibleValues: beginDates,
            filterFunction: GroupFilters.removeGroupByStartDate,
            displayField: "displayText",
            valueField: "value",
            inputType: FilterInputType.Select,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "start_date", defaultMessage: "Start Date" })
          },
          {
            name: intl.formatMessage({ id: "start_time", defaultMessage: "Start Time" }),
            allPossibleValues: startTimes,
            filterFunction: GroupFilters.removeGroupByStartTime,
            inputType: FilterInputType.Select,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "start_time", defaultMessage: "Start Time" })
          }
        ]
      },
      {
        filterGroupHeading: intl.formatMessage({ id: "language", defaultMessage: "Language" }),
        filters: [
          {
            name: intl.formatMessage({ id: "language", defaultMessage: "Language" }),
            allPossibleValues: availableLanguages,
            displayField: "description",
            valueField: "id",
            filterFunction: GroupFilters.removeGroupByGroupLanguageIdKeepMultilingual,
            filterFunctionExtraParam: availableCourses,
            inputType: FilterInputType.TypeAhead,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "language", defaultMessage: "Language" })
          },
        ]
      }
    ];

    return newFilterConfig;
  };

  const [filterConfig, setFilterConfig] = useState(createFilterConfig(availableGroups, availableCourses));

  useEffect(() => {
    const newFilterConfig = createFilterConfig(availableGroups, props.availablePrograms);
    setFilterConfig(newFilterConfig);
  }, [availableGroups, props.availablePrograms, language]);


  const onPlaceChanged = async () => {
    const place = placesApi!.getPlace();

    if (place?.geometry?.location) {

      const center = getLocationResultFromCoords(place.geometry.location);

      if (mapInView) {
        mapInView.panTo(center);
        const newLocation = new MapLocation({
          center,
          zoom: currentLocation ? currentLocation.zoom : defaultMapZoom,
          bounds: getBoundsFromGoogleMap(mapInView)!
        });
        setCurrentLocation(newLocation);
        searchArea(newLocation);
      } else {
        const bounds = calculateBounds(center, defaultSearchRadius);
        const newLocation: MapLocation = {
          center,
          bounds,
          zoom: defaultMapZoom,
        };
        setCurrentLocation(newLocation);
        searchArea(newLocation);
      }

      if (place.formatted_address) {
        setSearchItem(place.formatted_address);
      }
    }
  };

  const customSearch = (
    <MapsLoader>
      <Autocomplete
        onLoad={(places) => setPlacesApi(places)}
        onPlaceChanged={onPlaceChanged}
      >
        <TextField
          id="addressSearch"
          placeholder={intl.formatMessage({ id: "enter_address", defaultMessage: "Enter Address" })}
          value={searchItem}
          onChange={(e) => { setSearchItem(e.target.value); }}
          InputProps={{
            className: "searchInput",
            endAdornment:
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
          }}
        />
      </Autocomplete>
    </MapsLoader>
  );

  const inputs: ControlInput[] = [];

  inputs.push(
    {
      action: (value) => joinNowStore.setView(value),
      type: ControlInputType.Select,
      options: [
        {
          value: GroupFinderView.mapView,
          label: intl.formatMessage({ id: "map_view", defaultMessage: "Map View" }),
          startIcon: PlaceIcon
        },
        {
          value: GroupFinderView.listView,
          label: intl.formatMessage({ id: "list_view", defaultMessage: "List View" }),
          startIcon: ListAltIcon
        }
      ],
      value: joinNowStore.currentView
    }
  );

  return (
    <ListControlPanel
      dataset={availableGroups}
      customSearch={customSearch}
      isLoading={isLoading}
      filterConfig={filterConfig}
      onFilter={(searchResults) => setFilteredResults(searchResults)}
      inputs={inputs}
      showMobileMapView={layout == Layouts.Mobile}
    />
  );
};

export default GroupFinderListControls;