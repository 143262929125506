import { ReactElement, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dispatchers from "datastore/dispatcher";
import nextId from "react-id-generator";
import Alert from "./alert/alert";
import ReactDOM from "react-dom";

const Alerter = (): ReactElement => {

  const componentId = useRef(nextId());
  const elementId = useRef(`app-alert-container-${componentId.current}`);
  const reduxDispatch = useDispatch();
  const dismissAlert = dispatchers(reduxDispatch).dismissAlert as (alertId: string) => void;
  const alerts = useSelector((state: any) => state.appReducer.alerts);

  useEffect(() => {
    return () => {
      const containerToCleanUp = document.getElementById(elementId.current);
      if (containerToCleanUp) {
        containerToCleanUp.remove();
      }
    };
  }, []);

  const onRemove = (alertId: string) => {
    dismissAlert(alertId);
  };

  const alertComponents = (
    <>
      {alerts.map((alert, index) => <Alert key={`alert-${index}`} onRemove={onRemove} alert={{ ...alert, index }} />)}
    </>
  );

  let alertContainer = document.getElementById(elementId.current);

  if (!alertContainer) {
    const alertContainerDiv = document.createElement("div");
    alertContainerDiv.id = elementId.current;
    alertContainer = document.body.appendChild(alertContainerDiv);
  }

  return (
    <>
      {ReactDOM.createPortal(
        alertComponents,
        alertContainer
      )}
    </>
  );
};

export default Alerter;