import dayjs from "dayjs";
import CmisEmailDto from "./CmisEmailDto";
import CmisPhoneDto from "./CmisPhoneDto";

export interface IStakeMemberDtoCreationValues {
  id: number;
  firstName: string;
  lastName: string;
  birthDate: string;
  displayName: string;
  email?: CmisEmailDto;
  phone?: CmisPhoneDto;
  isMissionary?: boolean;
}

export interface IStakeMemberDto {
  id: number;
  firstName: string;
  lastName: string;
  birthDate: Date;
  displayName: string;
  email?: CmisEmailDto;
  phone?: CmisPhoneDto;
  isMissionary?: boolean;
}

export default class StakeMemberDto implements IStakeMemberDto {
  public id: number;
  public firstName: string;
  public lastName: string;
  public birthDate: Date;
  public displayName: string;
  public email?: CmisEmailDto;
  public phone?: CmisPhoneDto;
  public isMissionary?: boolean;

  constructor(creationValues: IStakeMemberDtoCreationValues) {
    this.id = creationValues.id;
    this.firstName = creationValues.firstName;
    this.lastName = creationValues.lastName;
    this.birthDate = dayjs(creationValues.birthDate).toDate();
    this.displayName = creationValues.displayName;
    this.email = creationValues.email ? new CmisEmailDto(creationValues.email) : undefined;
    this.phone = creationValues.phone ? new CmisPhoneDto(creationValues.phone) : undefined;
    this.isMissionary = creationValues.isMissionary ? creationValues.isMissionary : false;
  }

  public export(): IStakeMemberDtoCreationValues {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      birthDate: this.birthDate.toISOString(),
      displayName: this.displayName,
      email: this.email,
      phone: this.phone,
      isMissionary: this.isMissionary
    };
  }

  public clone(): StakeMemberDto {
    return new StakeMemberDto(this.export());
  }
}