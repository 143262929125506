import { ReactElement } from "react";
import { FacilitatorCertificateFailureDto } from "lib/api/backend/model/certificate";
import { FacilitatorPrintDto } from "lib/api/backend/model/facilitator";
import { FormattedMessage } from "react-intl";
import { getAndDownloadFacilitatorCertificates } from "lib/api/backend/requests/certificates";
import { useDispatch } from "react-redux";
import dispatchers from "datastore/dispatcher";
import Alert from "lib/data/model/Alert";
import { CertificateFailureType, CertificateFailureTypeTransLationsKeys } from "constants/certificate-constants";
import type { FullGroup } from "lib/models-v2/LeadersGroupResponse";

interface IFacilitatorCertificatePrintButtonProps {
  group: FullGroup,
}

export const FacilitatorCertificatePrintButtonV2 = (props: IFacilitatorCertificatePrintButtonProps): ReactElement => {

  const reduxDispatch = useDispatch();
  const alert = dispatchers(reduxDispatch).alert as (alert: Alert) => void;

  const handlePrintButtonClick = () => {
    getCertificates();
  };

  const getCertificates = async () => {
    const facilitatorsToPrint: FacilitatorPrintDto = [];

    if (props.group.facilitators) {
      props.group.facilitators.forEach(facilitator => {
        facilitatorsToPrint.push({
          programId: props.group.programId!,
          locationId: props.group.locationId!,
          groupId: props.group.id!,
          facilitatorId: facilitator.id!,
          isNonMemberFacilitator: false
        });
      });
    }

    if (props.group.nonMemberFacilitators) {
      props.group.nonMemberFacilitators.forEach(facilitator => {
        facilitatorsToPrint.push({
          programId: props.group.programId!,
          locationId: props.group.locationId!,
          groupId: props.group.id!,
          facilitatorId: facilitator.id!,
          isNonMemberFacilitator: true
        });
      });
    }

    const failures = await getAndDownloadFacilitatorCertificates(facilitatorsToPrint);

    handleFailures(failures);
  };

  const handleFailures = (failures: FacilitatorCertificateFailureDto[]) => {
    if (failures.length) {
      alert({
        status: "error",
        head: <FormattedMessage id="get_cert_error" defaultMessage="An error occured while retrieving certificates." />,
        message: (
          <>
            <p><FormattedMessage id="not_included_certificates" defaultMessage="The following certificates were not included. This may be because the selected certificate type is not available for the student or group." /></p>
            <ul>
              {failures.map((failure, index) => {
                const facilitator = failure.isNonMemberFacilitator ? props.group.nonMemberFacilitators.find(facilitator => facilitator.id == failure.facilitatorId) : props.group.facilitators.find(facilitator => facilitator.id == failure.facilitatorId);
                return (
                  <li key={index}>
                    <span>{facilitator!.name}</span>
                    {failure.failureReason !== CertificateFailureType.Unknown &&
                      <>
                        <br />
                        <span><FormattedMessage id={CertificateFailureTypeTransLationsKeys[failure.failureReason].key} defaultMessage={CertificateFailureTypeTransLationsKeys[failure.failureReason].defaultMessage} /></span>
                      </>
                    }
                  </li>
                );
              })}
            </ul>
          </>
        )
      });
    }
  };

  if (props.group.hasCompletionCertificate && (props.group.facilitators?.length || props.group.nonMemberFacilitators?.length)) {
    return (
      <div className="facilitatorPrintButtonContainer">
        <button onClick={handlePrintButtonClick}><FormattedMessage id="print_facilitator_certificates" defaultMessage="Print Facilitator Certificates" /></button>
      </div>
    );
  }

  return <></>;

};

export default FacilitatorCertificatePrintButtonV2;