import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import AuthService from "auth/authorize-service";
import OutcomesSurvey, {
  SurveyResultNames,
} from "components/outcomes-survey/outcomes-survey";
import { getFadeAnimationDuration } from "utilities/styles";
import { Permissions } from "constants/api-auth-constants";
import GroupStatuses from "constants/group-constants";
import { AppBaseUrl, GroupDetailsUrl } from "routes";
import { Prompt, NoticeCreation, Alert } from "lib/data/model";
import dispatchers from "datastore/dispatcher";
import { useSelector, useDispatch } from "react-redux";
import {
  cancelGroup as apiCancelGroup,
  concludeGroup as apiConcludeGroup,
} from "lib/api/backend/requests/groups";
import FacilitatorCertificatePrintButton from "components/facilitator-certificate-print-button";
import {
  redirectToServerSignOut,
  setCourseSignupSession,
} from "utilities/navUtils";
import { CourseSignUpBaseUrl } from "routes";
import { GroupDtoToGroupConverter } from "lib/api/backend/converters/GroupDtoToGroupConverter";
import { ListActionMenu } from "components/list-action-menu";
import { ListActionMenuDefaultAction } from "components/list-action-menu/components";
import { AddParticipantsButton } from "components/add-participants-button";
import usePreloadStore, { getLanguages } from "datastore/preload";
import { GroupDto } from "lib/api/backend/model";
import { CourseLanguageDto } from "lib/api/backend/model/course";	
import useGroupStore from "datastore/groups";
import { FullGroup } from "lib/models-v2";
import { useNavigate } from "react-router";
import QRCode from "react-qr-code";

interface IGroupActionMenu {
  group: FullGroup;
  borderOnToggle?: boolean;
  forceRight?: boolean;
  hideCopyLink?: boolean;
  onEnrollmentChange?: () => void;
  onLoadingChange?: (isLoading: boolean) => void;
  onGroupUpdate?: (groupDto: FullGroup) => void;
}

const groupConverter = new GroupDtoToGroupConverter();

export const GroupActionMenu = (props: IGroupActionMenu) => {
  const store = useGroupStore();
  const allLanguages = usePreloadStore(getLanguages);

  const group = props.group;
  const groupName = group.name;
  const locationId = group.locationId;
  const programId = group?.programId;
  const stakeUnitNumber = group.stakeUnitNumber;
  const canCopyGroup = AuthService.userCan([Permissions.NewGroupWrite]);
  const canConcludeGroup = AuthService.userCan(
    [[Permissions.GroupIndexWrite, Permissions.ManageGroupsWrite]],
    stakeUnitNumber
  );
  const canCancelGroup = AuthService.userCan(
    [[Permissions.GroupIndexWrite, Permissions.ManageGroupsWrite]],
    stakeUnitNumber
  );
  const canAddParticipants = AuthService.userCan(
    [Permissions.CourseSignUpWrite],
    stakeUnitNumber
  );
  const [canEditGroup, setCanEditGroup] = useState<boolean>(false);
  const reduxDispatch = useDispatch();
  const prompt = dispatchers(reduxDispatch).prompt as (prompt: Prompt) => void;
  const notify = dispatchers(reduxDispatch).notify as (
    notice: NoticeCreation
  ) => void;
  const alert = dispatchers(reduxDispatch).alert as (notice: Alert) => void;
  const language: CourseLanguageDto = useSelector(
    (state: any) => state.appReducer.language
  );
  const navigate = useNavigate();
  const intl = useIntl();

  const shouldEditGroup = (): boolean => {
    const groupStatus = group.status;
    const userCanEdit = AuthService.userCan(
      [Permissions.EditGroupWrite],
      stakeUnitNumber
    );

    if (
      groupStatus === GroupStatuses.Cancelled ||
      groupStatus === GroupStatuses.Completed
    ) {
      return false;
    }
    return userCanEdit;
  };

  useEffect(() => {
    setCanEditGroup(shouldEditGroup());
  }, [group.status]);

  const courseSignup = (event) => {
    if (group.stakeUnitNumber && canAddParticipants) {
      event.preventDefault();

      setCourseSignupSession(
        group.providerId,
        group,
        `${window.location.origin}${window.location.pathname}`
      );

      // sign facilitator out automatically
      redirectToServerSignOut({
        returnUrl: `${window.location.origin}${CourseSignUpBaseUrl}/${group.id}`,
      });
    }
  };

  const confirmConcludePrompt = () => {
    return new Promise((resolve) => {
      prompt({
        status: "warning",
        head: (
          <FormattedMessage
            id="please_confirm"
            defaultMessage="Please Confirm"
          />
        ),
        message: (
          <FormattedMessage
            id="confirm_conclude_group"
            values={{ groupName: groupName }}
            defaultMessage="Are you sure you want to conclude this group? This cannot be undone."
          />
        ),
        buttons: (
          <>
            <button
              className="primary"
              onClick={() => {
                resolve(true);
              }}
            >
              <FormattedMessage id="continue" defaultMessage="Continue" />
            </button>
            <button
              className="primary hollow"
              onClick={() => {
                resolve(false);
              }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </button>
          </>
        ),
      });
    });
  };

  const confirmCancelPrompt = () => {
    return new Promise((resolve) => {
      prompt({
        status: "warning",
        head: (
          <FormattedMessage
            id="please_confirm"
            defaultMessage="Please Confirm"
          />
        ),
        message: (
          <FormattedMessage
            id="confirm_cancel_group"
            values={{ groupName: groupName }}
            defaultMessage="Are you sure you want to cancel this group? This cannot be undone."
          />
        ),
        buttons: (
          <>
            <button
              className="primary"
              onClick={() => {
                resolve(true);
              }}
            >
              <FormattedMessage id="continue" defaultMessage="Continue" />
            </button>
            <button
              className="primary hollow"
              onClick={() => {
                resolve(false);
              }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </button>
          </>
        ),
      });
    });
  };

  const startOutcomesSurvey = async (concludingGroup = false) => {
    return new Promise((resolve) => {
      if (group.enrollmentCount && group.enrollmentCount > 0) {
        const surveyPrompt = () => {
          prompt({
            status: "info",
            head: (
              <FormattedMessage
                id="outcomes_survey_title"
                defaultMessage="Outcomes Survey"
              />
            ),
            message: (
              <OutcomesSurvey
                locationId={locationId!}
                programId={programId!}
                groupId={group.id!}
                onFinish={resolve}
                concludingGroup={concludingGroup}
                onEnrollmentChange={props.onEnrollmentChange}
              />
            ),
            buttons: [],
          });
        };
        if (concludingGroup) {
          //Animation delay is needed to allow the confirm conclude prompt close animation to finish
          setTimeout(surveyPrompt, getFadeAnimationDuration());
        } else {
          surveyPrompt();
        }
      } else {
        if (concludingGroup) {
          resolve(true);
        } else {
          alert({
            status: "info",
            head: (
              <FormattedMessage
                id="no_participants_head"
                defaultMessage="No participants"
              />
            ),
            message: (
              <FormattedMessage
                id="no_participants_detail"
                defaultMessage="This group does not have any participants."
              />
            ),
          });
        }
      }
    });
  };

  //Use instead zustand groups to conclude a group
  const concludeGroup = async () => {
    if (
      canConcludeGroup &&
      locationId &&
      programId &&
      group.id &&
      group.trackId
    ) {
      try {
        const canContinue = await confirmConcludePrompt();
        if (canContinue) {
          if (props.onLoadingChange) {
            props.onLoadingChange(true);
          }
          const surveyResult = await startOutcomesSurvey(true);
          if (surveyResult !== SurveyResultNames.Cancelled) {
            const response = await apiConcludeGroup(
              locationId,
              programId,
              group.id,
              group.trackId
            );
            if (response) {
              const responseGroup = groupConverter.toModel(response);
              const fullGroup = responseGroup.convertToFullGroup(allLanguages);

              store.editGroup(fullGroup);
              if (props.onGroupUpdate) {
                props.onGroupUpdate(fullGroup);
              }
              notify({
                status: "success",
                message: (
                  <FormattedMessage
                    id="concluded_group"
                    values={{ groupName: groupName }}
                    defaultMessage="Group has been concluded."
                  />
                ),
              });
            } else {
              notify({
                status: "error",
                message: (
                  <FormattedMessage
                    id="conclude_group_error"
                    defaultMessage="An error occured while concluding the group. Try again later."
                  />
                ),
              });
            }
          }
        }
      } catch (e) {
        notify({
          status: "error",
          message: (
            <FormattedMessage
              id="conclude_group_error"
              defaultMessage="An error occured while concluding the group. Try again later."
            />
          ),
        });
      }
      if (props.onLoadingChange) {
        props.onLoadingChange(false);
      }
    }
  };

  //Use zustand instead to cancel a group.
  const cancelGroup = async () => {
    if (
      canCancelGroup &&
      locationId &&
      programId &&
      group.id &&
      group.trackId
    ) {
      try {
        const canContinue = await confirmCancelPrompt();
        if (canContinue) {
          if (props.onLoadingChange) {
            props.onLoadingChange(true);
          }
          const response = await apiCancelGroup(
            locationId,
            programId,
            group.id,
            group.trackId
          );
          if (response) {
            const responseGroup = groupConverter.toModel(response);
            const fullGroup = responseGroup.convertToFullGroup(allLanguages);

            store.editGroup(fullGroup);

            if (props.onGroupUpdate) {
              props.onGroupUpdate(fullGroup);
            }
            notify({
              status: "success",
              message: (
                <FormattedMessage
                  id="cancelled_group"
                  values={{ groupName: groupName }}
                  defaultMessage="Cancelled Group"
                />
              ),
            });
          } else {
            notify({
              status: "error",
              message: (
                <FormattedMessage
                  defaultMessage="An error occured while cancelling the group. Try again later."
                  id="cancel_group_error"
                />
              ),
            });
          }
        }
      } catch (e) {
        notify({
          status: "error",
          message: (
            <FormattedMessage
              defaultMessage="An error occured while cancelling the group. Try again later."
              id="cancel_group_error"
            />
          ),
        });
      }
      if (props.onLoadingChange) {
        props.onLoadingChange(false);
      }
      if (props.onEnrollmentChange) {
        props.onEnrollmentChange();
      }
    }
  };

  const downloadQRImage = () => {
    const svg = document.getElementById("QRCode");

    if (svg !== null) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx!.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = "QRCode";
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }
  };

  const getGroupLink = () => {
    if (!group) {
      return "";
    }

    let link = `${AppBaseUrl}${GroupDetailsUrl}`;
    link = link.replace(":groupId", group.id!.toString());

    return link;
  };

  /****************************
   * Makes a group link and copies it to the clipboard
   */
  const copyGroupLink = () => {
    if (!group) {
      return;
    }

    /*** Create Link */

    const link = getGroupLink();

    // Check that link exists before copying
    if (link !== "") {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(link).then(
          () =>
            notify({
              status: "success",
              message: (
                <FormattedMessage
                  id="link_copied"
                  defaultMessage="Link copied to clipboard"
                />
              ),
            }),
          () =>
            notify({
              status: "info",
              message: (
                <>
                  <FormattedMessage
                    id="link_copy_failed"
                    defaultMessage={
                      "Unable to automatically copy link to the clipboard. Please copy the link below."
                    }
                  />
                  <div>{link}</div>
                </>
              ),
            })
        );
      } else {
        notify({
          status: "info",
          message: (
            <>
              <FormattedMessage
                id="link_copy_failed"
                defaultMessage={
                  "Unable to automatically copy link to the clipboard. Please copy the link below."
                }
              />
              <div>{link}</div>
            </>
          ),
        });
      }
    }
  };

  const goToEditGroup = () => {
    if (canEditGroup) {
      navigate(`/groups/${locationId}/${programId}/${group.id}/details`);
    }
  };

  const goToCopyGroup = () => {
    if (canCopyGroup) {
      navigate(`/groups/${locationId}/${programId}/${group.id}/copy`);
    }
  };

  const defaultAction = useMemo<ListActionMenuDefaultAction | null>(() => {
    switch (group.status) {
      case GroupStatuses.Upcoming:
        if (group.isPending) {
          if (canEditGroup) {
            return {
              label: intl.formatMessage({
                id: "edit_group",
                defaultMessage: "Edit Group",
              }),
              handler: goToEditGroup,
            };
          }
          return null;
        }
        if (!props?.hideCopyLink) {
          return {
            label: intl.formatMessage({
              id: "copy_group_link",
              defaultMessage: "Copy Group Link",
            }),
            handler: copyGroupLink,
          };
        }
        return null;
      case GroupStatuses.InProgress:
        if (!props?.hideCopyLink) {
          return {
            label: intl.formatMessage({
              id: "copy_group_link",
              defaultMessage: "Copy Group Link",
            }),
            handler: copyGroupLink,
          };
        }
        return null;
      case GroupStatuses.Concluded:
        if (canCopyGroup) {
          return {
            label: intl.formatMessage({
              id: "copy_new",
              defaultMessage: "Duplicate",
            }),
            handler: goToCopyGroup,
          };
        }
        return null;
      case GroupStatuses.Cancelled:
        if (canCopyGroup) {
          return {
            label: intl.formatMessage({
              id: "copy_new",
              defaultMessage: "Duplicate",
            }),
            handler: goToCopyGroup,
          };
        }
        return null;
      default:
        return {
          label: `+ ${intl.formatMessage({
            id: "participants",
            defaultMessage: "Participants",
          })}`,
          handler: courseSignup,
        };
    }
  }, [group.status, canEditGroup, language]);

  //TEMPORARY UNTIL MERGING OF 512730
  const getFullGroup = () => {
    if (group instanceof GroupDto) {
      const converter = new GroupDtoToGroupConverter();

      const groupObject = converter.toModel(group!);

      const fullGroup = groupObject.convertToFullGroup(allLanguages);

      return fullGroup;
    }
    return group;
  };

  return (
    <div className="groupActionMenuContainer">
      {group && (
        <ListActionMenu defaultAction={defaultAction}>
          <AddParticipantsButton
            group={group}
            useMenuStyle={true}
            hideOnDisabled={true}
          />
          {!props?.hideCopyLink && (
            <button onClick={copyGroupLink}>
              <FormattedMessage
                id="copy_group_link"
                defaultMessage="Copy Group Link"
              />
            </button>
          )}
          {!props?.hideCopyLink && (
            <>
              <div style={{ display: "none" }}>
                <QRCode
                  id="QRCode"
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={getGroupLink()}
                />
              </div>
              <button onClick={downloadQRImage}>
                <FormattedMessage
                  id="download_qr_code"
                  defaultMessage="Download QR Code"
                />
              </button>
            </>
          )}
          {canEditGroup && (
            <button onClick={goToEditGroup}>
              <FormattedMessage id="edit_group" defaultMessage="Edit Group" />
            </button>
          )}
          {canCopyGroup && (
            <button onClick={goToCopyGroup}>
              <FormattedMessage id="copy_new" defaultMessage="Duplicate" />
            </button>
          )}
          {![GroupStatuses.Completed, GroupStatuses.Cancelled].includes(
            group.status as GroupStatuses
          ) && (
            <>
              {canConcludeGroup && (
                <button data-group={group.id} onClick={() => concludeGroup()}>
                  <FormattedMessage
                    id="conclude_group"
                    defaultMessage="Conclude Group"
                  />
                </button>
              )}
              {canCancelGroup && (
                <button data-group={group.id} onClick={() => cancelGroup()}>
                  <FormattedMessage
                    id="cancel_group"
                    defaultMessage="Cancel Group"
                  />
                </button>
              )}
            </>
          )}
          {group.status == GroupStatuses.Completed && (
            <>
              <button
                disabled={!canConcludeGroup}
                onClick={() => startOutcomesSurvey()}
              >
                <FormattedMessage
                  id="outcomes_survey"
                  defaultMessage="Outcomes Survey"
                />
              </button>
              <FacilitatorCertificatePrintButton group={getFullGroup()} />
            </>
          )}
        </ListActionMenu>
      )}
    </div>
  );
};

export default GroupActionMenu;
