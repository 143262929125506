import dayjs from "dayjs";
import { DayOfWeek } from "lib/data/model/enum";

export interface IGroupTimeCreationDto {
  startTime?: string;
  endTime?: string;
  notes: string;
  dayOfWeek?: number;
  room: string;
  timeZoneId?: number;
}

export default class GroupTimeCreationDto implements GroupTimeCreationDto {

  public startTime?: Date;
  public endTime?: Date;
  public notes: string;
  public dayOfWeek?: DayOfWeek;
  public room: string;
  public timeZoneId?: number;

  constructor(creationValues: IGroupTimeCreationDto) {
    this.startTime = creationValues.startTime ? dayjs(creationValues.startTime).toDate() : undefined;
    this.endTime = creationValues.endTime ? dayjs(creationValues.endTime).toDate() : undefined;
    this.notes = creationValues.notes;
    this.dayOfWeek = creationValues.dayOfWeek ? DayOfWeek.parseValue(creationValues.dayOfWeek) : undefined;
    this.room = creationValues.room;
    this.timeZoneId = creationValues.timeZoneId;
  }

  public export(): IGroupTimeCreationDto {
    return {
      startTime: this.startTime?.toISOString(),
      endTime: this.endTime?.toISOString(),
      notes: this.notes,
      dayOfWeek: this.dayOfWeek,
      room: this.room,
      timeZoneId: this.timeZoneId
    };
  }

  public clone(): GroupTimeCreationDto {
    return new GroupTimeCreationDto(this.export());
  }
}