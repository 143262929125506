import { mapStateToProps, mapDispatchToProps } from "datastore/store";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { helpURL } from "./help.story";

const Help = () => {
  return (
    <div className="helpContainer">
      <a href={helpURL} target="_blank" rel="noreferrer">
        <FormattedMessage id="help" defaultMessage="Help" />
      </a>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);
