import axios from "axios";
import { validateResponse, ServerResult, ServerResponseType } from "../../request-utils";
import config from "../axios.config.json";
import { SiteDto } from "../../model";

export const getSitesForStakeId = async (stakeId: number) => {
  try {
    const response = await axios.get<SiteDto[]>(`/api/sites/unit-sites/${stakeId}`, { ...config });

    const responseType = validateResponse(response.status);

    const result: ServerResult<SiteDto[]> = {
      responseType: responseType,
      data: response.data
    };

    return result;
  }
  catch (error) {
    //Not sure how we want to handle this.
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }

    return {
      responseType: ServerResponseType.Error,
      data: []
    } as ServerResult<SiteDto[]>;
  }
};