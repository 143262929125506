import React, { useState, useRef, ReactElement, useContext } from "react";
import { useStore } from "zustand";
import nextId from "react-id-generator";
import { FormattedMessage } from "react-intl";
import Badge from "components/badge/badge";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { Filter, FilterConfig, FilterInputType } from "../../models";
import { ListControlPanelContext } from "../../list-control-panel-store";
import "./filter-panel-controls.scss";

interface IFilterPanelControls {
  onApplyFilters: (selectedFilters: FilterConfig) => void;
  className?: string;
  onClose?: () => void;
}

export const FilterPanelControls = (props: IFilterPanelControls): ReactElement => {

  const { onApplyFilters } = props;
  const filterStore = useContext(ListControlPanelContext);
  const { filterConfig, userControlFilterConfig, setUserControlFilterConfig } = useStore(filterStore!, s => s);

  const [autoCompleteTextValue, setAutoCompleteTextValue] = useState([]);
  const componentId = useRef(nextId());

  const updateSelectedFilters = (newFilterGroups) => {
    setUserControlFilterConfig(newFilterGroups);
  };

  const resetSelectedFilters = () => {
    setUserControlFilterConfig(filterConfig!);
    if (props.onClose) {
      props.onClose();
    }
  };

  const applySelectedFilters = () => {
    if (onApplyFilters) {
      onApplyFilters(userControlFilterConfig!);
    }
    if (props.onClose) {
      props.onClose();
    }
  };

  const allFilterValuesSelected = (filter: Filter, options?: { exclusive: boolean }) => {
    if (filter) {
      if (options?.exclusive && (filter.activeValues.length >= filter.allPossibleValues.length || filter.activeValues.length == 0)) {
        return true;
      } else if (filter.activeValues.length >= filter.allPossibleValues.length) {
        return true;
      }
    }
    return false;
  };

  const toggleAllFilterValues = (filter: Filter, options?: { exclusive: boolean }) => {
    const newFilterGroups = [...userControlFilterConfig!];
    if (allFilterValuesSelected(filter)) {
      filter.activeValues = [];
      filter.isTouched = true;
      updateSelectedFilters(newFilterGroups);
    } else {
      if (options?.exclusive) {
        filter.activeValues = [];
      } else {
        filter.allPossibleValues.forEach(value => {
          if (!filter.activeValues.includes(value)) {
            filter.activeValues.push(value);
          }
        });
      }
      filter.isTouched = true;
      updateSelectedFilters(newFilterGroups);
    }
  };

  const handleChange = (filter: Filter, value: any, options?: { exclusive: boolean }) => {
    const newFilterGroups = [...userControlFilterConfig!];
    const isActive = filter.activeValues.includes(value);
    if (isActive) {
      const index = filter.activeValues.indexOf(value);
      filter.activeValues.splice(index, 1);
    } else {
      if (options?.exclusive) {
        filter.activeValues = [];
      }
      filter.activeValues.push(value);
    }
    filter.isTouched = true;
    updateSelectedFilters(newFilterGroups);
  };

  const getFilterOptionDisplayText = (filter: Filter, value: any) => {
    return (typeof value == "object" && filter.displayField) ? value[filter.displayField] : value;
  };

  const isFilterOptionActive = (filter: Filter, value: any) => {
    return filter.activeValues.includes(value);
  };

  return (
    <div className={`filterPanelControlsContainer ${props.className ? props.className : ""}`}>
      {userControlFilterConfig &&
        <>
          <h1 className="mainHeading"><FormattedMessage id="narrow_results" defaultMessage="Narrow Results" /></h1>
          <div className="filterContent">
            {userControlFilterConfig.map((filterGroup, filterGroupIndex) => {
              return (
                <React.Fragment key={`filterGroup-${filterGroupIndex}`}>
                  <h2 className="filterGroupHead">{filterGroup.filterGroupHeading}</h2>
                  <div className="filterGroup">
                    {filterGroup.filters.map((filter, filterIndex) => {
                      const filterKey = `${componentId.current}-${filter.name}-${filterIndex}`;
                      const showFullWidth = filter?.fullWidth == true;
                      const showHalfWidth = !showFullWidth && [FilterInputType.Select, FilterInputType.TypeAhead].includes(filter.inputType);
                      return (
                        <div className={`filter ${showHalfWidth ? "halfWidth" : ""} ${showFullWidth ? "fullWidth" : ""}`} key={filterKey}>
                          {(() => {
                            switch (filter.inputType) {
                              case FilterInputType.Checkbox: {
                                return (
                                  <div className="checkboxFilterContainer">
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            id={filterKey}
                                            onChange={() => toggleAllFilterValues(filter)}
                                            checked={allFilterValuesSelected(filter)}
                                          />
                                        }
                                        label={<FormattedMessage id="select_all" defaultMessage="Select All" />}
                                      />

                                      {filter.allPossibleValues.map((value, valueIndex) => {
                                        const displayText = getFilterOptionDisplayText(filter, value);
                                        const keyId = `${componentId.current}-${filter.name}-${displayText}-${valueIndex}`;
                                        return (
                                          <FormControlLabel
                                            key={keyId}
                                            control={
                                              <Checkbox
                                                id={keyId}
                                                onChange={() => handleChange(filter, value)}
                                                checked={isFilterOptionActive(filter, value)}
                                              />
                                            }
                                            label={displayText}
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </div>
                                );
                              }
                              case FilterInputType.Radio: {
                                return (
                                  <div className="radioFilterContainer">
                                    <RadioGroup>
                                      <FormControlLabel
                                        control={
                                          <Radio
                                            id={filterKey}
                                            checked={allFilterValuesSelected(filter, { exclusive: true })}
                                            onChange={() => toggleAllFilterValues(filter, { exclusive: true })}
                                          />
                                        }
                                        label={<FormattedMessage id="select_all" defaultMessage="Select All" />}
                                      />
                                      {filter.allPossibleValues.map((value, valueIndex) => {
                                        const displayText = getFilterOptionDisplayText(filter, value);
                                        const keyId = `${componentId.current}-${filter.name}-${displayText}-${valueIndex}`;
                                        return (
                                          <FormControlLabel
                                            key={keyId}
                                            control={
                                              <Radio
                                                id={keyId}
                                                onChange={() => handleChange(filter, value, { exclusive: true })}
                                                checked={isFilterOptionActive(filter, value)}
                                              />
                                            }
                                            label={displayText}
                                          />
                                        );
                                      })}
                                    </RadioGroup>
                                  </div>
                                );
                              }
                              case FilterInputType.TypeAhead: {
                                return (
                                  <div className="typeAheadContainer">
                                    <div className="inputLabelGroup">
                                      <Autocomplete
                                        freeSolo
                                        options={filter.allPossibleValues.filter(value => !filter.activeValues.includes(value))}
                                        getOptionLabel={(option) => filter?.displayField ? option[filter.displayField] || "" : ""}
                                        value={autoCompleteTextValue}
                                        onChange={(event, newValue) => {
                                          if (newValue != null) {
                                            handleChange(filter, newValue);
                                          }
                                          setAutoCompleteTextValue([]);
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label={filter.name}
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </div>
                                    <div className="badgeContainer">
                                      {filter.activeValues.map((activeValue, activeValueIndex) => {
                                        const displayText = getFilterOptionDisplayText(filter, activeValue);
                                        const key = `${componentId.current}-${filter.name}-badge-${displayText}-${activeValueIndex}`;
                                        return (
                                          <Badge type="filter" key={key}>
                                            <div className="nameClearContainer">
                                              <span className="badgeName">{displayText}</span>
                                              <span className="pillClear" onClick={() => handleChange(filter, activeValue)}>X</span>
                                            </div>
                                          </Badge>
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              }
                              case FilterInputType.Select: {
                                const availableValues = filter.allPossibleValues.filter(value => !filter.activeValues.includes(value));
                                return (
                                  <div className="selectFilterContainer">
                                    <div className="inputLabelGroup">
                                      <TextField
                                        value={""}
                                        label={filter.name}
                                        onChange={(event) => handleChange(filter, availableValues[event.target.value])}
                                        fullWidth
                                        select
                                      >
                                        {availableValues.map((value, valueIndex) => {
                                          const displayText = getFilterOptionDisplayText(filter, value);
                                          const optionKey = `${componentId.current}-${filter.name}-option-${displayText}-${valueIndex}`;
                                          return (
                                            <MenuItem key={optionKey} value={valueIndex}>{displayText}</MenuItem>
                                          );
                                        })}
                                      </TextField>
                                    </div>
                                    <div className="badgeContainer">
                                      {filter.activeValues.map((activeValue, activeValueIndex) => {
                                        const displayText = getFilterOptionDisplayText(filter, activeValue);
                                        const key = `${componentId.current}-${filter.name}-badge-${displayText}-${activeValueIndex}`;
                                        return (
                                          <Badge type="filter" key={key}>
                                            <div className="nameClearContainer">
                                              <span className="badgeName">{displayText}</span>
                                              <span className="pillClear" onClick={() => handleChange(filter, activeValue)}>X</span>
                                            </div>
                                          </Badge>
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              }
                            }
                          })()}
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div className="filterButtonContainer">
            <button className="primary hollow" data-close={true} onClick={resetSelectedFilters}><FormattedMessage id="cancel" defaultMessage="Cancel" /></button>
            <button className="primary" data-close={true} onClick={applySelectedFilters}><FormattedMessage id="apply_filters" defaultMessage="Apply Filters" /></button>
          </div>
        </>
      }
    </div>
  );
};

export default FilterPanelControls;