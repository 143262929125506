import React from "react";
import { CardContent } from "@mui/material";
import MUICard from "@mui/material/Card";
import "./card.scss";

const Card = (props) => {
  
  const {className, head, children} = props;

  return (
    <div className={`cardContainer ${className? className : ""}`}>
      <MUICard variant={props?.variant || "outlined"}>
        {head &&
          <div className="cardHead">
            {head}
          </div>
        }
        <CardContent>
          {children}
        </CardContent>
      </MUICard>
    </div>
  );
};

export default Card;