const StudentStatuses = Object.freeze({
  Enrolled: "Enrolled",
  NotEnrolled: "Not Enrolled",
  Completed: "Completed",
  NeedConsent: "Need Consent"
});

export const StudentStatusesV2 = Object.freeze({
  Enrolled: "Enrolled",
  NotEnrolled: "Not Enrolled",
  NeedsConsent: "Needs Consent",
  NeedConsent: "Need Consent",
  DidNotComplete: "Did Not Complete",
  PrintCertificate: "Print Certificate",
  NeedsReview: "Needs Review",
  Completed: "Completed"
});

export const DaysStudentIsConsideredNew = 5;

export default StudentStatuses;