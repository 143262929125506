import "./signup.scss";
import "intl-tel-input/build/css/intlTelInput.css";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import dispatchers from "datastore/dispatcher";
import PublicGroupDetails from "components/group-details-public/group-details-public";
import Loading from "components/loading/loading";
import { FormattedMessage, useIntl } from "react-intl";
import RegistrationConfirmation from "./registration-confirmation/registration-confirmation";
import { Controller, useForm } from "react-hook-form";
import validator from "validator";
import nextId from "react-id-generator";
import intlTelInput from "intl-tel-input";
import dayjs from "dayjs";
import TermsAndConditions from "components/terms-and-conditions/terms-and-conditions";
import { Link, useLocation } from "react-router-dom";
import { TextField, MenuItem, Checkbox, Button, FormControlLabel, Stack, RadioGroup, Radio } from "@mui/material";
import NoticeCreation from "lib/data/model/NoticeCreation";
import Prompt from "lib/data/model/Prompt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CourseSignUpBaseUrl } from "routes";
import ReCaptcha from "@matt-block/react-recaptcha-v2";
import { getGroupDetails, getParticipantEnrollmentExists } from "lib/api/backend/requests/groups/groups-v2-requests";
import { signUpParticipant } from "lib/api/backend/requests/participant/participant-requests";
import type { GroupDetails } from "lib/models-v2";
import { ServerResponseType } from "lib/api/backend/request-utils";
import { ParticipantSignUpModel } from "lib/api/backend/model/participant/ParticipantSignupModel";
import FeatureFlags from "constants/feature-flags";
import { getFeatureFlagValue } from "lib/api/backend/requests/feature-flags";
import FeatureFlagEnabled from "components/feature-flag-enabled";

enum AgeRanges {
  noneSelected = 0,
  seventeenOrYounger = 1,
  eighteenAtHome = 2,
  eighteenNotHome = 3,
  nineteenOrOlder = 4,
}

interface FormData {
  FirstName: string;
  LastName: string;
  PhoneNumber: string;
  PhoneNumberDialCode: number;
  AllowAutomatedContact: boolean;
  EmailAddress: string;
  AgeRange: AgeRanges;
  AgreeToContactTerms: boolean,
  AgreeToTermsAndConditions: boolean
  IsMember: 1 | 0 | null; //design uses radio input which doesn't handle boolean values well
  IsReturnedMissionary: 1 | 0 | null; //design uses radio input which doesn't handle boolean values well;
  MissionReturnYear: number | null;
}

const FirstNameFieldName = "FirstName";
const LastNameFieldName = "LastName";
const AgeRangeFieldName = "AgeRange";
const EmailAddressFieldName = "EmailAddress";
const PhoneNumberFieldName = "PhoneNumber";
const IsMemberFieldName = "IsMember";
const IsReturnedMissionaryFieldName = "IsReturnedMissionary";
const MissionReturnYearFieldName = "MissionReturnYear";
const AllowAutomatedContactFieldName = "AllowAutomatedContact";
const AgreeToContactTermsFieldName = "AgreeToContactTerms";
const AgreeToTermsAndConditionsName = "AgreeToTermsAndConditions";
const EmptyValues = [null, undefined, ""];

interface ISignUp {
  group?: GroupDetails;
  groupId: number;
  onCancel?: () => void;
  onFinish: () => void;
  onExit: (goToGroupList?: boolean) => void;
  churchAccountMode?: boolean;
}

const Signup = (props: ISignUp) => {

  const { groupId, churchAccountMode } = props;

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    watch,
  } = useForm<FormData>({
    mode: churchAccountMode ? "all" : undefined,
    defaultValues: {
      IsMember: null,
      IsReturnedMissionary: null,
      MissionReturnYear: null
    }
  });


  const reduxDispatch = useDispatch();
  const prompt = dispatchers(reduxDispatch).prompt as (prompt: Prompt) => void;
  const notify = dispatchers(reduxDispatch).notify as (notice: NoticeCreation) => void;
  const dismissAllNotices = dispatchers(reduxDispatch).dismissAllNotices as () => void;

  const isDevelopment = process.env.NODE_ENV === "development" || window.location.host === "quickreg-test.englishconnect.org" || window.location.host === "quickreg-stage.englishconnect.org";
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

  const [submitEnabled, setSubmitEnabled] = useState<boolean>(isDevelopment);
  const location: any = useLocation();
  const [phoneValidator, setPhoneValidator] = useState<any>();
  const [registrationComplete, setRegistrationComplete] = useState<boolean>(false);
  const [group, setGroup] = useState<GroupDetails | null>(props.group || location?.state?.group || null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasEverHadEmailError, setHasEverHadEmailError] = useState<boolean>(false);
  const [showUnderAgeWarning, setShowUnderAgeWarning] = useState<boolean>(false);
  const user = useSelector((state: any) => state.userReducer.user);
  const alreadySaving = useRef<boolean>(false);
  const componentId = useRef<string>(nextId());
  const phoneInputId = `${componentId}-${PhoneNumberFieldName}`;
  const intl = useIntl();
  const isMember = watch(IsMemberFieldName);
  const isReturnedMissionary = watch(IsReturnedMissionaryFieldName);

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (!loading) {
      setupPhoneValidator();
    }
  }, [loading]);

  useEffect(() => {

    const updateFormWithUser = async () => {
      const { firstName, lastName, email, studentPhoneAllowSMS } = user;
      const phoneNumber = user?.studentPhoneNumber || user?.phoneNumber;
      const ageRange = ((): AgeRanges => {
        if (user?.dateOfBirth) {
          const today = dayjs();
          const birthday = dayjs(user.dateOfBirth);
          const age = today.diff(birthday, "year");
          if (age >= 18) {
            return AgeRanges.eighteenNotHome;
          }
          setShowUnderAgeWarning(true);
          return AgeRanges.seventeenOrYounger;
        }
        return AgeRanges.noneSelected;
      })();
      setValue(FirstNameFieldName, firstName ? firstName : "");
      setValue(LastNameFieldName, lastName ? lastName : "");
      setValue(EmailAddressFieldName, email ? email : "");
      setValue(PhoneNumberFieldName, phoneNumber ? phoneNumber : "");
      setValue(AllowAutomatedContactFieldName, phoneNumber ? studentPhoneAllowSMS : false);
      setValue(AgeRangeFieldName, ageRange);
      phoneValidator.setNumber(phoneNumber ? phoneNumber : "");
      const valid = await trigger([FirstNameFieldName, LastNameFieldName, EmailAddressFieldName, PhoneNumberFieldName, AllowAutomatedContactFieldName, AgeRangeFieldName]);
      if (!valid) {
        prompt(
          {
            status: "info",
            head: <FormattedMessage id="incomplete_profile" defaultMessage="Incomplete Profile" />,
            message: <p className="incompleteProfilePrompt"><FormattedMessage id="incomplete_profile_detail" defaultMessage="We were unable to get some required data from your Church Account. You may have to provide us with some information. The information entered here will not be added to your Church Account." /></p>,
            buttons: <button className="primary"><FormattedMessage id="ok" defaultMessage="Ok" /></button>
          }
        );
      }
    };

    if (user && phoneValidator && churchAccountMode) {
      updateFormWithUser();
    }

  }, [user, phoneValidator]);

  const initialize = async () => {
    setLoading(true);

    const isDuplicate = await checkForDuplicateEnrollment();

    if (group == null) {
      await getGroup(false);
    }

    setLoading(false);

    if (isDuplicate === true) {
      prompt(
        {
          status: "warning",
          head: <FormattedMessage id="already_enrolled" defaultMessage="Already Enrolled" />,
          message: <FormattedMessage id="already_enrolled_detail" defaultMessage="The LDS account you have signed in with is already enrolled in this group." />,
          buttons: <button className="primary" onClick={finish}><FormattedMessage id="ok" defaultMessage="Ok" /></button>
        }
      );
    }
  };

  const checkForDuplicateEnrollment = async () => {
    if (group) {
      try {
        const isEnrolled = await getParticipantEnrollmentExists(group.id);
        return isEnrolled;
      } catch (e) {
        notify({ status: "error", message: <FormattedMessage id="get_enrollment_error" defaultMessage="Could not get enrollment status." /> });
      }
    }
  };

  const getGroup = async (showLoading = true) => {
    if (showLoading) {
      setLoading(true);
    }

    if (!group) {
      const groupResponse = await getGroupDetails(groupId);

      if (groupResponse?.responseType !== ServerResponseType.Success) {
        notify({ status: "error", message: <FormattedMessage id="get_group_error" defaultMessage="Could not get group." /> });
        setGroup(null);
      }
      else {
        setGroup(groupResponse.data);
      }
    }

    if (showLoading) {
      setLoading(false);
    }
  };

  const handleSignUpClick = async (formData) => {
    if (!alreadySaving.current) {
      setLoading(true);
      window.scrollTo(0, 0);
      alreadySaving.current = true;
      const phoneNumber = getFormattedPhoneNumber();
      const hasPhoneNumber = (phoneNumber != "" && phoneNumber != null && phoneNumber != undefined);

      const participantSignUpModel: ParticipantSignUpModel = {
        AgreeToTermsAndConditions: formData.AgreeToTermsAndConditions,
        AgreeToContactTerms: formData.AgreeToContactTerms,
        AgeRange: formData.AgeRange,
        EmailAddress: formData.EmailAddress,
        FirstName: formData.FirstName,
        LastName: formData.LastName,
        PhoneNumber: hasPhoneNumber ? phoneNumber : null,
        PhoneNumberDialCode: hasPhoneNumber ? phoneValidator.getSelectedCountryData().dialCode : null,
        AllowAutomatedContact: hasPhoneNumber ? !!(formData.allowAutomatedContact) : false,
        GroupId: group!.id,
      };

      const membershipQuestionsEnabled = (await getFeatureFlagValue(FeatureFlags.QuickRegShowSignUpMembershipQuestions)).enabled;

      if (membershipQuestionsEnabled) {
        participantSignUpModel.IsMember = formData.IsMember == 1;
        if (formData.MissionReturnYear) {
          participantSignUpModel.MissionReturnYear = formData.MissionReturnYear;
        }
      }

      try {
        const enrollmentResult = await (async (): Promise<boolean> => {

          if (!group && !groupId) {
            return false;
          }

          return await signUpParticipant(participantSignUpModel);

        })();
        if (!enrollmentResult) {
          throw "Registration not successful.";
        }
        notify({
          status: "success",
          message:
            <>
              <strong><FormattedMessage id="signup_successful" defaultMessage="Signup Completed : {firstName} {lastName}" values={{ firstName: formData.firstName, lastName: formData.lastName }} /></strong>
              <br />
              <FormattedMessage id="facilitator_reaching_out" defaultMessage="The group facilitator will be reaching out to you through email, text, or by phone." />
            </>
        });
        setLoading(false);
        setRegistrationComplete(true);
        alreadySaving.current = false;
      } catch (e) {
        setLoading(false);
        alreadySaving.current = false;
        notify({ status: "error", message: <FormattedMessage id="signup_error" defaultMessage="An error occured while processing the registration request" /> });
      }
    }
  };

  const validateEmail = async (newEmail): Promise<boolean | string> => {

    const phoneValue = watch(PhoneNumberFieldName);
    //allow an empty email address if phone number is not empty (user can choose to enter either a phone or an email only one is required not both)
    if ((EmptyValues.includes(newEmail)) && !EmptyValues.includes(phoneValue)) {
      return true;
    }

    const validFormat = !!(typeof newEmail == "string" && validator.isEmail(newEmail));
    if (validFormat && churchAccountMode) {

      return true;
    }

    if (!validFormat) {
      setHasEverHadEmailError(true);
    }
    return validFormat;
  };

  const validatePhone = (newPhone): boolean => {
    if (phoneValidator) {

      phoneValidator.setNumber(!newPhone ? "" : newPhone);
      const emailValue = watch(EmailAddressFieldName);

      //allow an empty phone number if email is not empty (user can choose to enter either a phone or an email only one is required not both)
      if ((EmptyValues.includes(newPhone)) && !EmptyValues.includes(emailValue)) {
        return true;
      }

      const hasSymbols = /[a-zA-Z~`!@#$%^&*+=[\]\\';,/{}|\\":<>?]/.test(newPhone);

      if (hasSymbols) return false;

      let valid = phoneValidator.isValidNumber(newPhone);
      if (!valid) {
        const validationErrorType = phoneValidator.getValidationError();
        if (validationErrorType == 0) { //Error Code: 0, Phone number failed Validation, but with an IS_POSSIBLE error, still allowing it through. SEE: https://github.com/jackocnr/intl-tel-input/blob/master/src/js/utils.js#L132
          valid = true;
        }
      }
      return !!(valid);
    }
    return false;
  };

  const setupPhoneValidator = async () => {
    if (!phoneValidator) {
      const phoneInput = document.getElementById(phoneInputId);
      if (phoneInput) {
        await (global as any).window.intlTelInputGlobals.loadUtils("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.min.js");
        const newPhoneValidator = intlTelInput(phoneInput, {
          separateDialCode: true,
        });
        setPhoneValidator(newPhoneValidator);
      }
    }
  };

  const getFormattedPhoneNumber = (): string => {
    let phoneNumber = phoneValidator.getNumber((global as any).window.intlTelInputUtils.numberFormat.NATIONAL);//Global as any used to resolve typescript complaining that intlTelInputUtils is not defined
    phoneNumber = phoneNumber.replace(/\D/g, "");
    return phoneNumber;
  };

  const handleAgeChange = (event, onChange): void => {
    const newValue = event.target.value;
    if (parseInt(newValue) == AgeRanges.seventeenOrYounger) {
      setShowUnderAgeWarning(true);
    } else {
      setShowUnderAgeWarning(false);
    }
    onChange(event);
  };

  const validateAge = (value): string | boolean => {
    if (group?.isYouthOnly && value !== AgeRanges.seventeenOrYounger) {
      return intl.formatMessage({ id: "youth_age_warning", defaultMessage: "Only age 17 or younger can sign up for this group." });
    }
    if (group?.isAdultOnly && value == AgeRanges.seventeenOrYounger) {
      return intl.formatMessage({ id: "adult_age_warning", defaultMessage: "Only age 18 or older can sign up for this group" });
    }
    return true;
  };

  const validateAllowAutomatedContact = (newAllowed: boolean): boolean | string => {
    const phoneValue = watch(PhoneNumberFieldName);
    if (EmptyValues.includes(phoneValue) && newAllowed) { //checked but no phone number entered
      return intl.formatMessage({ id: "automated_contact_need_phone", defaultMessage: "You must enter a phone number to use for automated contact." });
    }
    return true;
  };

  const validateIsMember = (newValue: number | null) => {
    if (newValue == 0 || newValue == 1) {
      return true;
    }
    return false;
  };

  const validateIsMissionary = (newValue: number | null) => {
    if (newValue == 0 || newValue == 1) {
      return true;
    }
    return false;
  };

  const validateMissionReturnYear = (newValue: number | null) => {
    const isReturnedMissionary = watch(IsReturnedMissionaryFieldName);
    if (isReturnedMissionary == 1 && newValue && newValue >= 1900 && newValue <= new Date().getFullYear()) {
      return true;
    }
    else if ((isReturnedMissionary == 0 || isReturnedMissionary == null) && newValue == null) {
      return true;
    }
    return false;
  };

  const handleCancel = (): void => {
    dismissAllNotices();
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleExit = (goToGroupList?: boolean): void => {
    dismissAllNotices();
    if (props.onExit) {
      props.onExit(goToGroupList);
    }
  };

  const finish = (): void => {
    dismissAllNotices();
    if (props.onFinish) {
      props.onFinish();
    }
  };

  return (
    <div className="signupContainer hasLoader">
      <Button className="backButton" startIcon={<ArrowBackIcon />} onClick={handleCancel}>
        <FormattedMessage id="back" defaultMessage="back" />
      </Button>
      {churchAccountMode && !registrationComplete &&
        <h1><FormattedMessage id="sign_up_with_account" defaultMessage="Sign up with a Church Account" /></h1>
      }
      {!churchAccountMode && !registrationComplete &&
        <h1><FormattedMessage id="sign_up_without_account" defaultMessage="Sign up without an account" /></h1>
      }
      <Loading loading={loading} />
      <div className={`${loading ? "noShow" : ""}`}>
        {registrationComplete ?
          <div className="registrationComplete">
            {group &&
              <RegistrationConfirmation group={{ ...group, enrollmentCount: group?.enrollmentCount ? group.enrollmentCount + 1 : 1 }} onSignUpAnother={handleCancel} onExit={handleExit} showUnderAgeWarning={showUnderAgeWarning} />
            }
          </div>
          :
          <>
            {churchAccountMode ?
              <p><FormattedMessage id="signup_church_account_edit" defaultMessage="If any information is incorrect, please correct it in your Church Account. Any changes made here will not save to your Church Account." /></p>
              :
              <p><FormattedMessage id="no_account_warning" defaultMessage="If you sign in without an account, you will not be able to manage your groups." /> <Link to={`${CourseSignUpBaseUrl}/church-account/${groupId}`}><FormattedMessage id="sign_up_with_account" defaultMessage="Sign up with a Church account" /></Link></p>
            }
            <hr />
            <PublicGroupDetails group={group} smallDetails={true} />
            <div className="nameGroup">
              <Controller
                name={FirstNameFieldName}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    onChange={onChange}
                    error={!!error}
                    disabled={!!(!error && churchAccountMode)}
                    value={value || ""}
                    label={<FormattedMessage id="given_name" defaultMessage="First Name" />}
                  />
                )}
              />
              <Controller
                name={LastNameFieldName}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    onChange={onChange}
                    error={!!error}
                    disabled={!!(!error && churchAccountMode)}
                    value={value || ""}
                    label={<FormattedMessage id="surname" defaultMessage="Last Name" />} />
                )}
              />
            </div>
            <div className="inputRow">
              <Controller
                control={control}
                name={AgeRangeFieldName}
                defaultValue={AgeRanges.noneSelected}
                rules={{
                  required: true,
                  min: 1,
                  validate: validateAge
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    onChange={(event) => handleAgeChange(event, onChange)}
                    value={value || 0}
                    error={!!error}
                    disabled={!!(churchAccountMode && user?.dateOfBirth)}
                    label={<FormattedMessage id="age_range" defaultMessage="Age Range" />}
                    helperText={error?.message}
                    select
                  >
                    <MenuItem value={0} disabled><FormattedMessage id="select_range" defaultMessage="Select Age" /></MenuItem>
                    <MenuItem value={AgeRanges.seventeenOrYounger}><FormattedMessage id="seventeen_or_younger" defaultMessage="17 or younger" /></MenuItem>
                    <MenuItem value={AgeRanges.eighteenNotHome}><FormattedMessage id="eighteen_or_older" defaultMessage="18 or older" /></MenuItem>
                  </TextField>
                )}
              />
            </div>
            <div className="inputRow">
              <Controller
                name={EmailAddressFieldName}
                control={control}
                rules={{ validate: validateEmail }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={!!error}
                    onChange={(event) => {
                      onChange(event);
                      trigger(EmailAddressFieldName);
                      trigger(PhoneNumberFieldName);
                    }}
                    value={value || ""}
                    disabled={!!(churchAccountMode && user?.email && !error && !hasEverHadEmailError)}
                    label={<FormattedMessage id="email" defaultMessage="email" />}
                    helperText={error?.message ? error.message : ""}
                  />
                )}
              />
            </div>
            <div className="inputRow">
              <Controller
                name={PhoneNumberFieldName}
                control={control}
                rules={{ validate: validatePhone }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ id: phoneInputId }}
                    error={!!error}
                    onChange={(event) => {
                      onChange(event);
                      trigger(EmailAddressFieldName);
                      trigger(PhoneNumberFieldName);
                      trigger(AllowAutomatedContactFieldName);
                    }}
                    value={value || ""}
                    disabled={false} //Not locking the phone number field, even in church account mode, so users can select the correct country for their phone number
                    //Not getting a dial code from the back end/cmis so we don't really ever know what the correct dial code is, therefore this field can't be disabled. 
                    label={<FormattedMessage id="phone_number" defaultMessage="Phone Number" />}
                  />
                )}
              />

              <div className="automatedContactContainer">
                <Controller
                  name={AllowAutomatedContactFieldName}
                  control={control}
                  rules={{ validate: validateAllowAutomatedContact }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={onChange}
                            checked={value || false}
                          />}
                        label={intl.formatMessage({ id: "allow_automated_messaging", defaultMessage: "Please use for automated messaging" })}
                      />
                      <div className={`errorLabel ${(error) ? "" : "hidden"}`}>&nbsp;{error?.message}</div>
                    </>)
                  }
                />
              </div>

              <FeatureFlagEnabled featureFlagName={FeatureFlags.QuickRegShowSignUpMembershipQuestions}>
                <>
                  <Controller
                    name={IsMemberFieldName}
                    control={control}
                    rules={{ validate: validateIsMember }}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <FormControlLabel
                        className={(error) ? "error" : ""}
                        label={<FormattedMessage id="are_you_a_member" defaultMessage="To help us serve you better, are you a member of The Church of Jesus Christ of Latter-day Saints?" />}
                        labelPlacement={"top"}
                        control={
                          <RadioGroup
                            value={value}
                            name="isMemberControlGroup"
                            row={true}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              if (event.target.value == "0") {
                                setValue(IsReturnedMissionaryFieldName, 0);
                                setValue(MissionReturnYearFieldName, null);
                              }
                              onChange(event);
                            }}
                          >
                            <FormControlLabel value={1} control={<Radio checked={value == 1} />} label={intl.formatMessage({ id: "yes", defaultMessage: "Yes" })} />
                            <FormControlLabel value={0} control={<Radio checked={value == 0} />} label={intl.formatMessage({ id: "no", defaultMessage: "No" })} />
                          </RadioGroup>
                        }
                      />
                    )}
                  />
                  {isMember == 1 &&
                    <>
                      <Controller
                        name={IsReturnedMissionaryFieldName}
                        control={control}
                        rules={{ validate: validateIsMissionary }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <FormControlLabel
                            className={(error) ? "error" : ""}
                            labelPlacement={"top"}
                            label={<FormattedMessage id="are_you_a_returned_missionary" defaultMessage="Are you a recently returned missionary?" />}
                            control={
                              <RadioGroup
                                value={value}
                                name="isMissionaryControlGroup"
                                row={true}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  if (event.target.value == "0") {
                                    setValue(MissionReturnYearFieldName, null);
                                  }
                                  onChange(event);
                                }}
                              >
                                <FormControlLabel value={1} control={<Radio checked={value == 1} />} label={intl.formatMessage({ id: "yes", defaultMessage: "Yes" })} />
                                <FormControlLabel value={0} control={<Radio checked={value == 0} />} label={intl.formatMessage({ id: "no", defaultMessage: "No" })} />
                              </RadioGroup>
                            }
                          />
                        )}
                      />
                      {isReturnedMissionary == 1 &&
                        <Controller
                          name={MissionReturnYearFieldName}
                          control={control}
                          rules={{ validate: validateMissionReturnYear }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FormControlLabel
                              className={(error) ? "error" : ""}
                              labelPlacement={"top"}
                              label={<FormattedMessage id="mission_return_year" defaultMessage="What year did you return home?" />}
                              control={
                                <TextField
                                  onChange={onChange}
                                  error={!!error}
                                  value={value || ""}
                                  type="number"
                                />
                              }

                            />
                          )}
                        />
                      }
                    </>
                  }
                </>
              </FeatureFlagEnabled>
            </div>
            <Controller
              name={AgreeToContactTermsFieldName}
              control={control}
              rules={{ validate: (value) => value === true }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControlLabel
                  className={(error) ? "error" : ""}
                  control={
                    <Checkbox
                      onChange={onChange}
                      checked={value || false}
                    />}
                  label={<FormattedMessage id="contact_agree" defaultMessage="I agree to allow members of the Church of Jesus Christ of Latter-day Saints to contact me about this class." />}
                />
              )}
            />
            <Controller
              name={AgreeToTermsAndConditionsName}
              control={control}
              rules={{
                validate: (value) => {
                  return value === true;
                }
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={onChange}
                        checked={value || false}
                      />}
                    label={
                      <TermsAndConditions
                        className={(!value && error) ? "error" : ""}
                        providerId={group!.providerId}
                        programId={group!.programId}
                        onDecision={(accepted) => {
                          setValue(AgreeToTermsAndConditionsName, accepted, { shouldValidate: true });
                        }}
                      />
                    }
                  />
                </>
              )}
            />
            {
              !isDevelopment && siteKey &&
              <ReCaptcha
                siteKey={siteKey}
                onSuccess={() => setSubmitEnabled(true)}
                onExpire={() => setSubmitEnabled(false)}
                onError={() => setSubmitEnabled(false)}
              />
            }
            <Stack className="buttonRow" direction="row">
              {props?.onCancel &&
                <Button variant="text" onClick={handleCancel}><FormattedMessage id="cancel" defaultMessage="cancel" /></Button>
              }
              <Button variant="contained" onClick={handleSubmit(handleSignUpClick)} disabled={!submitEnabled}><FormattedMessage id="signup" defaultMessage="Sign Up" /></Button>
            </Stack>
          </>
        }
      </div>
    </div>
  );
};

export default Signup;
