import React, { ReactNode, useState, useEffect } from "react";
import "./loading.scss";

interface IProps {
  loading?: boolean;
  blackout?: boolean;
  message?: string | ReactNode;
  small?: boolean;
  tiny?: boolean;
  centered?: boolean;
  fixed?: boolean;
}

const Loading: React.FC<IProps> = (props) => {

  const { blackout, small, tiny, centered, fixed } = props;

  const [isLoading, setIsLoading] = useState(props.loading ?? false);

  useEffect(() => {
    setIsLoading(props.loading ?? false);
  }, [props.loading]);

  const getContainerClassName = () => {
    let className = "loadingContainer";

    if (blackout) {
      className += " blackout";
    }

    if (small) {
      className += " small";
    }

    if (tiny) {
      className += " tiny";
    }

    if (fixed) {
      className += " fixed";
    }

    return className;
  };

  const getMidLevelClassName = () => {
    let className = "loading";

    if (small) {
      className += " small";
    }

    if (tiny) {
      className += " tiny";
    }

    if (centered) {
      className += " centered";
    }

    return className;
  };

  const getMessageClassName = () => {
    let className = "loadingMessage";

    if (small) {
      className += " small";
    }

    return className;
  };

  return (
    <>
      {isLoading &&
        <div className={getContainerClassName()}>
          <div className={getMidLevelClassName()} />
          {props.message &&
            <span className={getMessageClassName()}>{props.message}</span>
          }
        </div>
      }
    </>
  );
};

export default Loading;