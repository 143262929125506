import "./terms-and-conditions.scss";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState, useEffect, MouseEvent } from "react";
import { FormattedMessage } from "react-intl";
import ProgramConditionsDto from "lib/api/backend/model/ProgramConditionsDto";
import dispatchers from "datastore/dispatcher";
import Prompt from "lib/data/model/Prompt";

interface ITermsAndConditions {
  providerId: number;
  programId: number;
  onDecision?: (decision: boolean) => void;
  className?: string;
  tabIndex?: number;
  isAgreement?: boolean;
}

const TermsAndConditions = ({ isAgreement = true, ...props }: ITermsAndConditions) => {

  const [terms, setTerms] = useState<ProgramConditionsDto | null>(null);
  const awaitingDecision = useRef<boolean>(false);
  const languageCode = useSelector((state: any) => state.appReducer.language.tosCountryCode);
  const reduxDispatch = useDispatch();
  const getProviderTermsAndConditions = dispatchers(reduxDispatch).getProviderTermsAndConditions as (providerId: number, programId: number) => any;
  const prompt = dispatchers(reduxDispatch).prompt as (prompt: Prompt) => void;

  useEffect(() => {
    getTerms();
  }, []);

  const getTerms = async () => {
    const terms = await getProviderTermsAndConditions(props.providerId, props.programId);
    setTerms(terms);
  };

  const handleShowTermsClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (!awaitingDecision.current && terms != null) {
      event.preventDefault();
      const message = function () {
        if (terms.url) {
          const url = new URL(terms.url);
          const params = new URLSearchParams(url.search);

          params.set("lang", languageCode);
          params.set("country", "go");
          const iFrameUrl = `${url.href}?${params.toString()}`;
          return (
            <iframe className="tos-frame" src={iFrameUrl} />
          );
        } else {
          return (
            <div className="termsAndConditionsMain">
              {terms.conditions.map(condition => {
                return (
                  <div className="termConditionContainer" key={condition.condition.id}>
                    <h1 className="termConditionName">{condition.condition.name}</h1>
                    <p className="termConditionDescription">{condition.condition.description}</p>
                  </div>);
              })}
            </div>);
        }
      }();

      //If all we want to do is show the terms and agreements, this section won't render.
      const buttonSection = isAgreement && props.onDecision ? (
        <>
          <button onClick={() => onDecision(true)}><FormattedMessage id="accept" defaultMessage="Accept" /></button>
          <button className="primary hollow" onClick={() => onDecision(false)}><FormattedMessage id="decline" defaultMessage="Decline" /></button>
        </>
      ) : (
        <button className="primary hollow" onClick={() => onDecision(false)}><FormattedMessage id="close" defaultMessage="Close" /></button>
      );

      prompt({
        status: "info",
        head: <FormattedMessage id="terms_and_conditions_label" defaultMessage="Terms and Conditions" />,
        message,
        buttons: buttonSection
      });
    }
    awaitingDecision.current = true;
  };

  const onDecision = (accepted: boolean) => {
    awaitingDecision.current = false;
    if (props?.onDecision) {
      props.onDecision(accepted);
    }
  };

  return (
    <div className={`termsAndConditionsContainer ${props.className ? props.className : ""}`}>
      {isAgreement && props.onDecision && <FormattedMessage id="i_agree_to_the" defaultMessage="I agree to the" />}
      <button type="button" tabIndex={props?.tabIndex} className="termsAndConditions" onClick={handleShowTermsClick}>&nbsp;<FormattedMessage id="terms_and_conditions_label" defaultMessage="Terms of Participation." /></button>
    </div>
  );
};

export default TermsAndConditions;