import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import dispatchers from "datastore/dispatcher";
import { getAndDownloadGroupParticipantCertificates } from "lib/api/backend/requests/certificates";
import { Permissions } from "constants/api-auth-constants";
import AuthService from "auth/authorize-service";
import { FormattedMessage } from "react-intl";
import "./print-certificate-button.scss";
import { Alert } from "lib/data/model";
import { CertificateFailureType, CertificateFailureTypeTransLationsKeys } from "constants/certificate-constants";
import { QuickRegParticipant } from "lib/models-v2";
import ParticipantCertificatePrintFailureDto from "lib/api/backend/model/ParticipantCertificatePrintFailureDto";

export interface IPrintCertificateButton {
  participant: QuickRegParticipant;
  groupName: string;
  stakeUnitNumber: number;
  groupId: number;
  hasCompletionCertificate: boolean;
  hasParticipationCertificate: boolean;
}

export const PrintCertificateButton = (props: IPrintCertificateButton) => {

  const { participant, stakeUnitNumber, groupName, groupId, hasCompletionCertificate, hasParticipationCertificate } = props;
  const canManageStudents = AuthService.userCan([Permissions.ManageGroupsWrite], stakeUnitNumber);
  const canPrintCompletionCerts = AuthService.userCan([Permissions.PrintStudentCompletionCertificatesWrite], stakeUnitNumber);
  const [loading, setLoading] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const reduxDispatch = useDispatch();
  const alert = dispatchers(reduxDispatch).alert as (alert: Alert) => void;

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  });

  const handleOutsideClick = () => {
    setShowMenu(false);
  };

  const handleInsideClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handlePrint = async () => {
    const canRequestCompletionCertificate = hasCompletionCertificate && canPrintCompletionCerts;
    const canRequestParticipationCertificate = hasParticipationCertificate;

    if (canRequestCompletionCertificate && canRequestParticipationCertificate) {
      setShowMenu(true);
    }
    else if (canRequestCompletionCertificate) {
      await handlePrintCompletionCert();
    }
    else if (canRequestParticipationCertificate) {
      await handlePrintParticipationCert();
    }
  };

  const handlePrintCompletionCert = async () => {
    setLoading(true);

    const failures = await getAndDownloadGroupParticipantCertificates(groupId!, [participant.id], false);
    if (failures.length > 0) {
      showPrintFailureAlert(failures[0]);
    }

    setLoading(false);
  };

  const handlePrintParticipationCert = async () => {
    setLoading(true);
    const failures = await getAndDownloadGroupParticipantCertificates(groupId!, [participant.id], true);
    if (failures.length > 0) {
      showPrintFailureAlert(failures[0]);
    }
    setLoading(false);
  };


  const showPrintFailureAlert = (failure: ParticipantCertificatePrintFailureDto) => {
    alert({
      status: "error",
      head: <FormattedMessage id="get_cert_error" defaultMessage="An error occured while retrieving certificates." />,
      message: (
        <>
          <p><FormattedMessage id="not_included_certificates" defaultMessage="The following certificates were not included. This may be because the selected certificate type is not available for the student or group." /></p>
          <ul>
            <li>
              <span>{participant?.givenName} {participant?.surname} - {groupName}</span>
              {failure.failureReason !== CertificateFailureType.Unknown &&
                <>
                  <br />
                  <span><FormattedMessage id={CertificateFailureTypeTransLationsKeys[failure.failureReason].key} defaultMessage={CertificateFailureTypeTransLationsKeys[failure.failureReason].defaultMessage} /></span>
                </>
              }
            </li>
          </ul>
        </>
      )
    });
  };

  return (
    <div className="printCertificateButtonContainer" onClick={handleInsideClick}>
      <button disabled={loading} className="listAction hollow" onClick={handlePrint}>
        {
          loading
            ? <FormattedMessage id="getting_cert" defaultMessage="Getting certificate..." />
            : <FormattedMessage id="print_certificate" defaultMessage="Print Certificate" />
        }
      </button>
      {
        showMenu
        && (
          <div className={"certificateMenu"} onClick={() => setShowMenu(false)}>
            <button disabled={!canManageStudents} onClick={handlePrintParticipationCert}>
              <FormattedMessage id="participation_certificate" defaultMessage="Participation Certificate(s)" />
            </button>
            <button disabled={!canManageStudents || !canPrintCompletionCerts} onClick={handlePrintCompletionCert}>
              <FormattedMessage id="completion_certificate" defaultMessage="Completion Certificate(s)" />
            </button>
          </div>
        )
      }
    </div>
  );
};

export default PrintCertificateButton;