import "./capacity-selector.scss";

import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import { useFormStatus } from "lib/form/contexts";
import { FormStatus, SaveState } from "lib/form/model";
import useGroupCreationFormStore from "datastore/groupCreationForm";

const MIN_CAPACITY = 1;
const MAX_CAPACITY = 100000;

const CapacitySelector: React.VFC = () => {
  const [formStatus, setFormStatus] = useFormStatus();

  const formStore = useGroupCreationFormStore();
  const group = formStore.group;
  const maxEnrollment = group?.maxEnrollment;

  const intl = useIntl();

  useEffect(
    () => {
      isCapacityValid(maxEnrollment!);
    },
    [maxEnrollment]
  );

  const handleCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseInt(event.target.value);
    const newMaxEnrollment = Number.isNaN(value) ? undefined : Math.trunc(value);

    formStatus.dirtiness.setDirty(true, "capacity");
    setFormStatus(
      new FormStatus(formStatus)
    );

    if (isCapacityValid(newMaxEnrollment) && newMaxEnrollment !== undefined) {
      formStore.setMaxEnrollment(newMaxEnrollment);
    }
  };

  const isCapacityValid = (capacity?: number) => {
    let isValid = true;

    const capacityTooLowErrorMessage = intl.formatMessage({ id: "capacity_too_low", defaultMessage: "Negative values and zero are not allowed. Please enter a higher value." });
    const capacityTooHighErrorMessage = intl.formatMessage({ id: "capacity_too_high", defaultMessage: "The maximum supported capacity is 2,147,483,647. Please enter a lower value." });

    if (capacity && (capacity < MIN_CAPACITY)) {
      isValid = false;

      formStatus.errors.addMessage(capacityTooLowErrorMessage, "capacity");
    }
    else {
      formStatus.errors.removeMessage(capacityTooLowErrorMessage, "capacity");
    }

    if (capacity && (capacity > MAX_CAPACITY)) {
      isValid = false;

      formStatus.errors.addMessage(capacityTooHighErrorMessage, "capacity");
    }
    else {
      formStatus.errors.removeMessage(capacityTooHighErrorMessage, "capacity");
    }

    formStatus.validity.setValidity(isValid, "capacity");
    setFormStatus(
      new FormStatus(formStatus)
    );

    return isValid;
  };

  ////////////////////
  // Render Helpers //
  ////////////////////

  const capacityHasErrors = (
    !formStatus.validity.isValid("capacity")
    && (
      formStatus.saveState === SaveState.ValidationFailed
      || formStatus.dirtiness.isDirty("capacity")
    )
  );

  ////////////
  // Render //
  ////////////

  return (
    <div className="capacitySelector capacitySelectorContainer">
      <TextField
        label={<FormattedMessage id="max_capacity" defaultMessage="Max capacity" />}
        value={maxEnrollment ?? ""}
        onChange={handleCapacityChange}
        type="text"
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        error={capacityHasErrors}
        helperText={
          capacityHasErrors
            ? formStatus.errors.getMessages("capacity").map(
              (message, index) => (
                <span key={`capacity-input-message-${index}`}>
                  {message}
                </span>
              ))
            : formStatus.messages.getMessages("capacity").map(
              (message, index) => (
                <span key={`capacity-input-message-${index}`}>
                  {message}
                </span>
              ))
        }
      />
    </div>
  );
};

export default CapacitySelector;