import { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { GroupAgeTypes } from "constants/group-constants";
import { FullGroup, QuickRegParticipant } from "lib/models-v2";
import GroupCard from "../move-participant-group-card";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import "./move-participant-selected-group.scss";

interface IMoveParticipantSelectedGroupProps {
  participant: QuickRegParticipant,
  group: FullGroup
  onClear: () => void;
}

export const MoveParticipantSelectedGroup = (props: IMoveParticipantSelectedGroupProps): ReactElement => {

  const intl = useIntl();

  const warnings: string[] = ((): string[] => {
    const newWarnings: string[] = [];
    if (props.group != null) {
      if (props.participant.isYouth && props.group.groupAgeType == GroupAgeTypes.Adult) {
        newWarnings.push(intl.formatMessage({ id: "moving_youth_to_adult_group", defaultMessage: "You are moving a youth participant into an adult group." }));
      }

      if (!props.participant.isYouth && props.group.groupAgeType == GroupAgeTypes.Youth) {
        newWarnings.push(intl.formatMessage({ id: "moving_adult_to_youth_group", defaultMessage: "You are moving an adult participant into a youth group." }));
      }

      if (props.group.maxEnrollment != null && props.group.maxEnrollment <= props.group.enrollmentCount) {
        newWarnings.push(intl.formatMessage({ id: "moving_to_group_at_capacity", defaultMessage: "You are moving a participant into a group that is already at capacity." }));
      }
    }
    return newWarnings;
  })();

  return (
    <div className="moveParticipantSelectedGroupContainer">
      <Button className="backButton" startIcon={<ArrowBackIcon />} onClick={props.onClear}>
        <FormattedMessage id="back" defaultMessage="back" />
      </Button>
      <div className="moveDetails">
        <span className="moveConfirm">
          <FormattedMessage
            id="move_participant_to"
            defaultMessage={"Move {givenName} {surname} to:"}
            values={{ givenName: props.participant.givenName, surname: props.participant.surname }}
          />
        </span>
        <GroupCard group={props.group} />
      </div>
      {warnings.map((warning, index) => <p key={`warning-${index}`} className="warning">{warning}</p>)}
    </div>
  );
};

export default MoveParticipantSelectedGroup;