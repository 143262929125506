import { ReactElement, useEffect, useState } from "react";
import { FullGroup } from "lib/models-v2";
import { LoadingStatus, NoticeCreation } from "lib/data/model";
import useGroupStore from "datastore/groups";
import { useDispatch } from "react-redux";
import dispatchers from "datastore/dispatcher";
import { ServerResponseType } from "lib/api/backend/request-utils";
import { getUserGroupsV2 } from "lib/api/backend/requests/groups";
import { FormattedMessage } from "react-intl";
import GroupStatuses from "constants/group-constants";
import MoveParticipantGroupCard from "../move-participant-group-card";
import "./move-participant-group-selector.scss";

interface IMoveParticipantGroupSelectorProps {
  participantGroupId: number;
  onSelect: (group: FullGroup) => void;
  onLoadingChange: (status: LoadingStatus) => void;
}

export const MoveParticipantGroupSelector = (props: IMoveParticipantGroupSelectorProps): ReactElement => {

  const store = useGroupStore();
  const reduxDispatch = useDispatch();
  const notify = dispatchers(reduxDispatch).notify as (notice: NoticeCreation) => void;
  const [availableGroups, setAvailableGroups] = useState<FullGroup[]>([]);

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = async () => {
    if (!store.userGroupsFetchCompleted) {
      props.onLoadingChange({ status: true, message: <FormattedMessage id="getting_groups" defaultMessage="Getting Groups" /> });
      const response = await getUserGroupsV2();
      if (response!.responseType == ServerResponseType.Success) {
        store.setGroups(response!.data.groupsList, true);
        filterToAvailableGroups(response!.data.groupsList);
        props.onLoadingChange({ status: false, message: "" });
      }
      else {
        store.setGroups([]);
        props.onLoadingChange({ status: false, message: "" });
        notify({ status: "error", message: <FormattedMessage id="get_groups_error" defaultMessage="Could not get groups." /> });
      }
    } else if (store.groups) {
      filterToAvailableGroups(store.groups);
    }
  };

  const filterToAvailableGroups = (groups: FullGroup[]) => {
    const newAvailableGroups = groups.filter((group) => {
      if (group.status != GroupStatuses.InProgress && group.status != GroupStatuses.Upcoming) {
        return false;
      }
      if (group.id == props.participantGroupId) {
        return false;
      }
      return true;
    });
    setAvailableGroups(newAvailableGroups);
  };

  return (
    <div className="moveParticipantGroupSelectorContainer">
      {store.userGroupsFetchCompleted && availableGroups.length == 0 &&
        <p><FormattedMessage id="no_groups_available" defaultMessage={"No groups available."} /></p>
      }
      {availableGroups.map((group, index) => {
        return (
          <div key={`${index}-${group.id}`} className="selectableGroup" onClick={() => props.onSelect(group)}>
            <MoveParticipantGroupCard group={group} />
          </div>
        );
      })}
    </div>
  );
};

export default MoveParticipantGroupSelector;