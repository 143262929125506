import AuthResult from "./auth-result";

export interface IAuthMessage {
  status: AuthResult,
  message?: string,
  state?: any,
}

export default class AuthMessage {

  status: AuthResult;
  message?: string;
  state?: any;

  constructor(creationValues: IAuthMessage) {
    this.status = creationValues.status;
    this.message = creationValues.message;
    this.state = creationValues.state;
  }

}
