import { ReactElement, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dispatchers from "datastore/dispatcher";
import nextId from "react-id-generator";
import Prompt from "./prompt/prompt";
import ReactDOM from "react-dom";

const Prompter = (): ReactElement => {

  const componentId = useRef(nextId());
  const elementId = useRef(`app-prompt-container-${componentId.current}`);
  const reduxDispatch = useDispatch();
  const dismissPrompt = dispatchers(reduxDispatch).dismissPrompt as (promptId: string) => void;
  const prompts = useSelector((state: any) => state.appReducer.prompts);

  useEffect(() => {
    return () => {
      const containerToCleanUp = document.getElementById(elementId.current);
      if (containerToCleanUp) {
        containerToCleanUp.remove();
      }
    };
  }, []);

  const onRemove = (promptId: string) => {
    dismissPrompt(promptId);
  };

  const promptComponents = (
    <>
      {prompts.map((prompt, index) => <Prompt key={`prompt-${index}`} onRemove={onRemove} alert={{ ...alert, index }} prompt={prompt} />)}
    </>
  );

  let promptContainer = document.getElementById(elementId.current);

  if (promptContainer && promptContainer.nextSibling) { //if prompt container is not the last child of body, remove and append so it becomes the last child
    promptContainer.remove();
    document.body.appendChild(promptContainer);
  } else if (!promptContainer) {
    const newPromptContainerDiv = document.createElement("div");
    newPromptContainerDiv.id = elementId.current;
    newPromptContainerDiv.style.position = "relative";
    newPromptContainerDiv.style.zIndex = "1301"; //one higher than the MUI modal z-index;
    promptContainer = document.body.appendChild(newPromptContainerDiv);
  }

  return (
    <>
      {ReactDOM.createPortal(
        promptComponents,
        promptContainer
      )}
    </>
  );
};

export default Prompter;