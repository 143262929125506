import Permission from "./permission";
import Role from "./role";
import Provider from "./provider";

export interface IUser {
  employeeId: number;
  accountId: number;
  cmisId: number
  employeeStatusCode: string;
  firstName: string;
  middleName: string;
  lastName: string;
  prefferedName: string | null;
  displayName: string;
  membershipUnitNumber: number;
  stakeMembershipUnitNumber: number;
  areaUnitNumber: number;
  missionUnitNumber: number;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  studentPhoneNumber: string;
  studentPhoneAllowSMS: boolean;
  roles: Role[];
  permissions: Permission[];
  isHomeOfficeAdmin: boolean;
  availableProviders: Provider[];
  currentProvider: Provider;
  providerId: number;
}

export default class User {

  employeeId: number;
  accountId: number;
  cmisId: number;
  employeeStatusCode: string;
  firstName: string;
  middleName: string;
  lastName: string;
  prefferedName: string | null;
  displayName: string;
  membershipUnitNumber: number;
  stakeMembershipUnitNumber: number;
  areaUnitNumber: number;
  missionUnitNumber: number;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  studentPhoneNumber: string;
  studentPhoneAllowSMS: boolean;
  roles: Role[];
  permissions: Permission[];
  isHomeOfficeAdmin: boolean;
  availableProviders: Provider[];
  currentProvider: Provider;
  providerId: number;

  constructor(creationValues: IUser) {
    this.employeeId = creationValues.employeeId;
    this.accountId = creationValues.accountId;
    this.cmisId = creationValues.cmisId;
    this.employeeStatusCode = creationValues.employeeStatusCode;
    this.firstName = creationValues.firstName;
    this.middleName = creationValues.middleName;
    this.lastName = creationValues.lastName;
    this.prefferedName = creationValues.prefferedName;
    this.displayName = creationValues.displayName;
    this.membershipUnitNumber = creationValues.membershipUnitNumber;
    this.stakeMembershipUnitNumber = creationValues.stakeMembershipUnitNumber;
    this.areaUnitNumber = creationValues.areaUnitNumber;
    this.missionUnitNumber = creationValues.missionUnitNumber;
    this.dateOfBirth = creationValues.dateOfBirth;
    this.email = creationValues.email;
    this.phoneNumber = creationValues.phoneNumber;
    this.studentPhoneNumber = creationValues.studentPhoneNumber;
    this.studentPhoneAllowSMS = creationValues.studentPhoneAllowSMS;
    this.roles = creationValues.roles;
    this.permissions = creationValues.permissions;
    this.isHomeOfficeAdmin = creationValues.isHomeOfficeAdmin;
    this.availableProviders = creationValues.availableProviders;
    this.currentProvider = creationValues.currentProvider;
    this.providerId = creationValues.providerId;
  }

}

