import { getLocaleDateString } from "utilities/time";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { DaysStudentIsConsideredNew } from "constants/student-constants";
import { dateIsLessThanDaysOld } from "utilities/time";
import "./participant-registration-date.scss";

interface IParticipantRegistrationDateProps {
  date: Date | string;
  showNewIcon?: boolean;
}

export const ParticipantRegistrationDate = (props: IParticipantRegistrationDateProps) => {

  const registrationDate = props.date instanceof Date ? props.date : new Date(props.date);
  const showIcon = typeof props.showNewIcon != "undefined" ? props.showNewIcon : false;
  const isNew = dateIsLessThanDaysOld(registrationDate, DaysStudentIsConsideredNew);

  return (
    <time dateTime={registrationDate.toISOString()} className={`participantRegistrationDate${isNew ? " new" : ""}`}>
      {getLocaleDateString(registrationDate)}
      {showIcon && isNew &&
        <FiberNewIcon className={"newIcon"} />
      }
    </time>
  );
};

export default ParticipantRegistrationDate;