import "./course-signup.scss";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import dispatchers from "datastore/dispatcher";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { getCourseSignupSession, redirectToServerSignOut } from "../../utilities/navUtils";
import Card from "@mui/material/Card";
import Loading from "components/loading/loading";
import GroupHasRoomV2 from "components/group-has-room-v2/group-has-room-v2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NoticeCreation from "lib/data/model/NoticeCreation";
import { JoinNowBaseUrl, CourseSignUpBaseUrl } from "routes";
import { SignOutReason } from "constants/api-auth-constants";
import { getGroupDetails } from "lib/api/backend/requests/groups/groups-v2-requests";
import { ServerResponseType } from "lib/api/backend/request-utils";
import { GroupDetails } from "lib/models-v2";

type RouteParams = {
  providerId: string,
  locationId: string,
  programId: string,
  groupId: string,
  trackId: string,
  termId: string
}

const CourseSignup = () => {

  const reduxDispatch = useDispatch();
  const isAuthenticated = useSelector((state: any) => state.userReducer.isAuthenticated);
  const notify = dispatchers(reduxDispatch).notify as (notice: NoticeCreation) => void;
  const [group, setGroup] = useState<GroupDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const courseSignupSession = getCourseSignupSession();
  const navigate = useNavigate();
  const params = useParams<RouteParams>();
  const groupId = courseSignupSession.groupId || params.groupId;

  useEffect(() => {
    const getGroup = async () => {
      setLoading(true);
      if (groupId) {
        
        const groupResponse = await getGroupDetails(parseInt(groupId));

        if(groupResponse?.responseType == ServerResponseType.Success) {
          setGroup(groupResponse.data);
        }

        if(groupResponse?.responseType == ServerResponseType.Error) {
          notify({ status: "error", message: <FormattedMessage id="get_group_error" defaultMessage="Could not get group." /> });
          setGroup(null);
        }
            
        setLoading(false);
      }
    };

    if (group == null) {
      getGroup();
    }
  }, []);


  useEffect(() => {
    if (isAuthenticated) {
      redirectToServerSignOut({ returnUrl: location.href, reason: SignOutReason.SignUpSessionActive });
    }
  }, [isAuthenticated]);

  const onExit = (): void => {
    const returnUrl = courseSignupSession.enrollOriginUrl;
    if (isAuthenticated && returnUrl) {
      redirectToServerSignOut({ returnUrl });
    } else if (!isAuthenticated) {
      if (returnUrl) {
        navigate(new URL(returnUrl).pathname);
      } else {
        navigate(JoinNowBaseUrl);
      }
    } else {
      redirectToServerSignOut({ returnUrl: location.origin });
    }
  };

  return (
    <div className="courseSignupContainer hasLoader">
      <Loading loading={loading} blackout={true} />
      {!loading &&
        <>
          <Button className="backButton" startIcon={<ArrowBackIcon />} onClick={onExit}>
            <FormattedMessage id="back" defaultMessage="back" />
          </Button>
          <Card>
            <GroupHasRoomV2 group={group!}>
              <h1>
                <FormattedMessage id="signup" defaultMessage="Sign Up" />
              </h1>
              <p>
                <FormattedMessage id="signup_church_account" defaultMessage="Sign up with your Church Account" />
              </p>
              <Stack direction="row">
                <Button variant="text" onClick={onExit}><FormattedMessage id="cancel" defaultMessage="Cancel" /></Button>
                <Link
                  to={`${CourseSignUpBaseUrl}/church-account/${groupId}`}
                  state={{ group }}
                >
                  <Button variant="contained"><FormattedMessage id="next" defaultMessage="Next" /></Button>
                </Link>
              </Stack>
              <hr />
              <Link
                to={`${CourseSignUpBaseUrl}/email-phone/${groupId}`}
                state={{ group }}
              >
                <FormattedMessage id="signup_no_account" defaultMessage="Sign up without an account" />
              </Link>
            </GroupHasRoomV2>
          </Card>
        </>
      }
    </div>
  );
};

export default CourseSignup;
