import React from "react";
import { getHHMM, getLocaleDateString, getWeekdayName } from "utilities/time";
import Badge from "components/badge/badge";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { GroupDetailsUrl } from "routes";
import { setCourseSignupSession } from "utilities/navUtils";
import { GroupDetails } from "lib/models-v2";

interface IProps {
  groups: GroupDetails[];
}

const MapInfoWindow: React.VFC<IProps> = (props) => {
  const intl = useIntl();
  const groups = props.groups;
  const navigate = useNavigate();

  const virtualTranslatedString = intl.formatMessage({
    id: "virtual",
    defaultMessage: "Virtual",
  });
  const pendingTranslatedString = intl.formatMessage({
    id: "pending",
    defaultMessage: "Pending",
  });
  const youthOnlyTranslatedString = intl.formatMessage({
    id: "group_age_type_youth",
    defaultMessage: "Youth Only",
  });
  const adultOnlyTranslatedString = intl.formatMessage({
    id: "group_age_type_adult",
    defaultMessage: "Adult Only",
  });

  const goToDetails = (group: GroupDetails) => {
    setCourseSignupSession(
      group.providerId,
      group,
      `${window.location.origin}${window.location.pathname}`,
      `${window.location.origin}${window.location.pathname}`
    );
    let link = GroupDetailsUrl;
    link = link.replace(":locationId", group.locationId!.toString());
    link = link.replace(":programId", group.programId!.toString());
    link = link.replace(":groupId", group.id!.toString());
    navigate(link);
  };

  const renderGroupInfo = (group: GroupDetails) => {
    return (
      <div className="infoWindowGroup small-group-search-itemtitle" key={`info-window-group-${group.id}`}>
        <h4
          className="infoTitle"
          style={{
            margin: "2px",
            fontSize: "16px",
            lineHeight: "29px",
          }}
          data-group={group.id}
          onClick={() => goToDetails(group)}
        >
          {group.programName}
        </h4>
        <div className="marker-badge-container">
          {group.isVirtual && <Badge pill>{virtualTranslatedString}</Badge>}
          {group.isYouthOnly && (
            <Badge pill type="ageGroup">
              {youthOnlyTranslatedString}
            </Badge>
          )}
          {group.isAdultOnly && (
            <Badge pill type="ageGroup">
              {adultOnlyTranslatedString}
            </Badge>
          )}
        </div>
        {group.isPending && pendingTranslatedString}
        {group.startDate && (
          <div className="data">
            <time dateTime={group.startDate}>
              {getLocaleDateString(group.startDate)}
            </time>
            <span> - </span>
            {group.endDate ? (
              <time dateTime={group.endDate}>
                {getLocaleDateString(group.endDate)}
              </time>
            ) : (
              "Open"
            )}
          </div>
        )}
        {group.meetingTimes && (
          <div className="data">
            <>
              {group.meetingTimes.map((day, meetingTimeNum) => {
                return (
                  <div key={meetingTimeNum}>
                    {intl.formatMessage({
                      id: getWeekdayName(day.dayOfWeek).toLowerCase(),
                      defaultMessage: getWeekdayName(day.dayOfWeek),
                    })}{" "}
                    {getHHMM(day.startTime)}
                    {day.endTime != null && " - " + getHHMM(day.endTime)}
                  </div>
                );
              })}
            </>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="group-map-info-itemcontent">
      <div className="small-group-search-itemdetails">
        <address className="groupAddress">
          {groups[0].address1},{" "}
          {groups[0].address2 && `${groups[0].address2}, `} {groups[0].city}{" "}
          {groups[0].state ? groups[0].state : groups[0].country}
        </address>
        {groups.map((g) => {
          return renderGroupInfo(g);
        })}
      </div>
    </div>
  );
};

export default MapInfoWindow;