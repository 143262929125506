import dayjs from "dayjs";
import { convertHHMMToCurrentDateUTC } from "utilities/time";

export interface IMeetingTimeDtoCreationValues {
  id?: number;
  startTime?: string;
  endTime?: string;
  dayOfWeek?: number;
  timeZoneName?: string;
}

export interface IMeetingTimeDto {
  id?: number;
  startTime?: Date;
  endTime?: Date;
  dayOfWeek?: number;
  timeZoneName?: string;
}

export default class MeetingTimeDto implements IMeetingTimeDto {

  public id?: number;
  public startTime?: Date;
  public endTime?: Date;
  public dayOfWeek?: number;
  public timeZoneName?: string;

  constructor(creationValues?: IMeetingTimeDtoCreationValues) {
    this.id = creationValues?.id;
    this.startTime = creationValues?.startTime ? dayjs(creationValues.startTime).toDate() : undefined;
    this.endTime = creationValues?.endTime ? dayjs(creationValues.endTime).toDate() : undefined;
    this.dayOfWeek = creationValues?.dayOfWeek;
    this.timeZoneName = creationValues?.timeZoneName;
  }

  public export(): IMeetingTimeDtoCreationValues {
    return {
      id: this.id,
      startTime: this.startTime ? convertHHMMToCurrentDateUTC(dayjs(this.startTime).format("HH:mm")) ?? undefined : undefined,
      endTime: this.endTime ? convertHHMMToCurrentDateUTC(dayjs(this.endTime).format("HH:mm")) ?? undefined : undefined,
      dayOfWeek: this.dayOfWeek,
      timeZoneName: this.timeZoneName
    };
  }

  public clone(): MeetingTimeDto {
    return new MeetingTimeDto(this.export());
  }
}