import { Group, GuestFacilitator, StandardFacilitator } from "lib/data/model";
import { AgeType, LocationType } from "lib/data/model/enum";
import GroupCreationDto from "../model/GroupCreationDto";
import { AddressDtoToAddressCreationDtoConverter } from "./AddressDtoToAddressCreationDtoConverter";
import { GuestFacilitatorToNonMemberFacilitatorCreationDtoConverter } from "./GuestFacilitatorToNonMemberFacilitatorCreationDtoConverter";
import { IModelToDtoConverter } from "./IDtoConverter";
import { MeetingTimeDtoToGroupTimeCreationDto } from "./MeetingTimeDtoToGroupTimeCreationDto";
import { StandardFacilitatorToFacilitatorCreationDtoConverter } from "./StandardFacilitatorToFacilitatorCreationDtoConverter";

export class GroupToGroupCreationDtoConverter implements IModelToDtoConverter<Group, GroupCreationDto> {
  public toDto = (model: Group): GroupCreationDto => {
    const location = model.location!;
    const course = model.course!;
    const ageType = model.groupAgeType === AgeType.Combined ? AgeType[model.groupAgeType] : `${AgeType[model.groupAgeType]} only`;

    const addressConverter = new AddressDtoToAddressCreationDtoConverter();
    const meetingTimeConverter = new MeetingTimeDtoToGroupTimeCreationDto();
    const standardFacilitatorConverter = new StandardFacilitatorToFacilitatorCreationDtoConverter();
    const guestFacilitatorConverter = new GuestFacilitatorToNonMemberFacilitatorCreationDtoConverter();

    return new GroupCreationDto({
      facilityId: location.propertyId,
      customAddressCreationModel: addressConverter.toDto(location.address!),
      isVirtual: location.type === LocationType.Virtual,
      isHidden: model.isHidden,
      isCustom: location.type === LocationType.Other,
      isPending: model.isPending,
      providerId: model.providerId,
      programId: course.id,
      groupAgeType: ageType,
      programName: course.name,
      startDate: model.startDate?.toISOString(),
      endDate: model.endDate?.toISOString(),
      hasAcceptedYouthTerms: model.hasAcceptedYouthTerms,
      meetingTimes: model.meetingTimes.map(meetingTimeConverter.toDto).map(gt => gt.export()),
      facilitators: model.facilitators
        .filter(f => f instanceof StandardFacilitator)
        .map(f => f as StandardFacilitator)
        .map(standardFacilitatorConverter.toDto)
        .map(sf => sf.export()),
      nonMemberFacilitators: model.facilitators
        .filter(f => f instanceof GuestFacilitator)
        .map(f => f as GuestFacilitator)
        .map(guestFacilitatorConverter.toDto)
        .map(nmf => nmf.export()),
      maxEnrollment: model.maxEnrollment,
      stakeUnitNumber: location.stakeUnitNumber,
      coordinates: model.location!.coordinates!,
      languageIds: model.groupLanguages ? model.groupLanguages.map(lang => lang.languageId) : []
    });
  };
}