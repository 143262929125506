import React from "react";
import { FormattedMessage } from "react-intl";
import "./group-finder-group-details.scss";

interface IProps {
  courseDescription: string;
}

const GroupFinderGroupDetails = (props: IProps) => {
  return (
    <div className="groupFinderGroupDetailsContainer">
      <div className="itemDetails">
        {props.courseDescription &&
          <>
            <div className="label"><FormattedMessage id="description" defaultMessage="Description" /></div>
            <div className="courseInfo courseDescription">
              {props.courseDescription}
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default GroupFinderGroupDetails;