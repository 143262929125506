import dayjs, { Dayjs } from "dayjs";
import { StudentDto } from "lib/api/backend/model";
import { QuickRegParticipant } from "lib/models-v2";
import { getLocaleDateString } from "utilities/time";

export function removeByOutsideDateRange(data, startDate: Dayjs | null, endDate: Dayjs | null): boolean {
  const startDateComp = startDate ? startDate.toDate() : null;
  const endDateComp = endDate ? endDate.toDate() : null;

  if (startDate && endDate) {
    return data.filter((student) => {
      const studentDateComp = dayjs(student.modifiedDate).toDate();
      return studentDateComp >= startDateComp! && studentDateComp <= endDateComp!;
    });
  }
  else if (startDate && !endDate) {
    return data.filter((student) => {
      const studentDateComp = dayjs(student.modifiedDate).toDate();
      return studentDateComp >= startDateComp!;
    });
  }
  else {
    return data.filter((student) => {
      const studentDateComp = dayjs(student.modifiedDate).toDate();
      return studentDateComp <= endDateComp!;
    });
  }
}

export function filterParticipantByCreatedDate(data: QuickRegParticipant[], allowedDates: Date[]) {
  if (data.length < 1 || allowedDates.length < 1) {
    return data;
  }
  const dateStrings = allowedDates.map(date => getLocaleDateString(date));
  return data.filter((participant) => dateStrings.includes(getLocaleDateString(participant.createdDate)));
}

export function filterStudentByDate(data: StudentDto[], allowedDates: Date[]) {
  if (data.length < 1 || allowedDates.length < 1) {
    return data;
  }
  const dateStrings = allowedDates.map(date => getLocaleDateString(date));
  return data.filter((student) => dateStrings.includes(getLocaleDateString(student.modifiedDate)));
}

export function filterStudentByStatus(data, allowedValues) {
  if (allowedValues.length < 1) {
    return data;
  }
  return data.filter((student) => allowedValues.includes(student.status));
}