//Translations
import English from "assets/translations/english.json";
import Russian from "assets/translations/russian.json";
import Ukrainian from "assets/translations/ukrainian.json";
import Spanish from "assets/translations/spanish.json";
import Portuguese from "assets/translations/portuguese.json";
import French from "assets/translations/french.json";
import Japanese from "assets/translations/japanese.json";
import Korean from "assets/translations/korean.json";
import Italian from "assets/translations/italian.json";
import ChineseTraditional from "assets/translations/chinese-traditional.json";
import ChineseSimplified from "assets/translations/chinese-simplified.json";
import Cebuano from "assets/translations/cebuano.json";
import Tagalog from "assets/translations/tagalog.json";
import German from "assets/translations/german.json";
import Mongolian from "assets/translations/mongolian.json";
import Samoan from "assets/translations/samoan.json";
import Tongan from "assets/translations/tongan.json";
import Hungarian from "assets/translations/hungarian.json";
import Czech from "assets/translations/czech.json";
import Romanian from "assets/translations/romanian.json";
import Slovak from "assets/translations/slovak.json";
import Lithuanian from "assets/translations/lithuanian.json";
import Albanian from "assets/translations/albanian.json";
import Armenian from "assets/translations/armenian.json";
import Bulgarian from "assets/translations/bulgarian.json";
import Cambodian from "assets/translations/cambodian.json";
import Croatian from "assets/translations/croatian.json";
import Danish from "assets/translations/danish.json";
import Dutch from "assets/translations/dutch.json";
import Estonian from "assets/translations/estonian.json";
import Fijian from "assets/translations/fijian.json";
import Finnish from "assets/translations/finnish.json";
import Indonesian from "assets/translations/indonesian.json";
import Latvian from "assets/translations/latvian.json";
import Malay from "assets/translations/malay.json";
import Norwegian from "assets/translations/norwegian.json";
import Polish from "assets/translations/polish.json";
import Serbian from "assets/translations/serbian.json";
import Swedish from "assets/translations/swedish.json";
import Tahitian from "assets/translations/tahitian.json";
import Thai from "assets/translations/thai.json";
import Vietnamese from "assets/translations/vietnamese.json";
import Kekchi from "assets/translations/kekchi.json";
import Greek from "assets/translations/greek.json";
import Marshallese from "assets/translations/marshallese.json";

//dayjs Locales
import dayjsEnglish from "dayjs/locale/en";
import dayjsRussian from "dayjs/locale/ru";
import dayjsUkrainian from "dayjs/locale/uk";
import dayjsSpanish from "dayjs/locale/es";
import dayjsPortuguese from "dayjs/locale/pt";
import dayjsFrench from "dayjs/locale/fr";
import dayjsJapanese from "dayjs/locale/ja";
import dayjsKorean from "dayjs/locale/ko";
import dayjsItalian from "dayjs/locale/it";
import dayjsChinese from "dayjs/locale/zh";
import dayjsTagalog from "dayjs/locale/tl-ph";
import dayjsGerman from "dayjs/locale/de";
import dayjsMongolian from "dayjs/locale/mn";
import dayjsHungarian from "dayjs/locale/hu";
import dayjsCzech from "dayjs/locale/cs";
import dayjsRomanian from "dayjs/locale/ro";
import dayjsSlovak from "dayjs/locale/sk";
import dayjsLithuanian from "dayjs/locale/lt";
import dayjsAlbanian from "dayjs/locale/sq";
import dayjsArmenian from "dayjs/locale/hy-am";
import dayjsBulgarian from "dayjs/locale/bg";
import dayjsCambodian from "dayjs/locale/km";
import dayjsCroatian from "dayjs/locale/hr";
import dayjsDanish from "dayjs/locale/da";
import dayjsDutch from "dayjs/locale/nl";
import dayjsEstonian from "dayjs/locale/et";
import dayjsFinnish from "dayjs/locale/fi";
import dayjsIndonesian from "dayjs/locale/id";
import dayjsLatvian from "dayjs/locale/lv";
import dayjsMalay from "dayjs/locale/ms";
import dayjsNorwegian from "dayjs/locale/nn";
import dayjsPolish from "dayjs/locale/pl";
import dayjsSerbian from "dayjs/locale/sr";
import dayjsSwedish from "dayjs/locale/sv";
import dayjsThai from "dayjs/locale/th";
import dayjsVietnamese from "dayjs/locale/vi";
import dayjsGreek from "dayjs/locale/el";

import CebuanoHelp from "assets/help-pdfs/cebuano-help.pdf";
import ChineseTraditionalHelp from "assets/help-pdfs/chinese-traditional-help.pdf";
import EnglishHelp from "assets/help-pdfs/english-help.pdf";
import FrenchHelp from "assets/help-pdfs/french-help.pdf";
import GermanHelp from "assets/help-pdfs/german-help.pdf";
import ItalianHelp from "assets/help-pdfs/italian-help.pdf";
import JapaneseHelp from "assets/help-pdfs/japanese-help.pdf";
import KoreanHelp from "assets/help-pdfs/korean-help.pdf";
import MongolianHelp from "assets/help-pdfs/mongolian-help.pdf";
import PortugueseHelp from "assets/help-pdfs/portuguese-help.pdf";
import RomaninanHelp from "assets/help-pdfs/romanian-help.pdf";
import RussianHelp from "assets/help-pdfs/russian-help.pdf";
import SamoanHelp from "assets/help-pdfs/samoan-help.pdf";
import SlovakHelp from "assets/help-pdfs/slovak-help.pdf";
import SpanishHelp from "assets/help-pdfs/spanish-help.pdf";
import TagalogHelp from "assets/help-pdfs/tagalog-help.pdf";
import TonganHelp from "assets/help-pdfs/tongan-help.pdf";
import UkrainianHelp from "assets/help-pdfs/ukrainian-help.pdf";
import LithuanHelp from "assets/help-pdfs/lithuanian-help.pdf";
import AlbanianHelp from "assets/help-pdfs/albanian-help.pdf";
import ArmenianHelp from "assets/help-pdfs/armenian-help.pdf";
import BulgarianHelp from "assets/help-pdfs/bulgarian-help.pdf";
import CambodianHelp from "assets/help-pdfs/cambodian-help.pdf";
import CroatianHelp from "assets/help-pdfs/croatian-help.pdf";
import DanishHelp from "assets/help-pdfs/danish-help.pdf";
import DutchHelp from "assets/help-pdfs/dutch-help.pdf";
import EstonianHelp from "assets/help-pdfs/estonian-help.pdf";
import FijianHelp from "assets/help-pdfs/fijian-help.pdf";
import FinnishHelp from "assets/help-pdfs/finnish-help.pdf";
import IndonesianHelp from "assets/help-pdfs/indonesian-help.pdf";
import LatvianHelp from "assets/help-pdfs/latvian-help.pdf";
import MalayHelp from "assets/help-pdfs/malay-help.pdf";
import NorwegianHelp from "assets/help-pdfs/norwegian-help.pdf";
import PolishHelp from "assets/help-pdfs/polish-help.pdf";
import SerbianHelp from "assets/help-pdfs/serbian-help.pdf";
import SwedishHelp from "assets/help-pdfs/swedish-help.pdf";
import TahitianHelp from "assets/help-pdfs/tahitian-help.pdf";
import ThaiHelp from "assets/help-pdfs/thai-help.pdf";
import VietnameseHelp from "assets/help-pdfs/vietnamese-help.pdf";
import CzechHelp from "assets/help-pdfs/czech-help.pdf";
import MarshalleseHelp from "assets/help-pdfs/marshallese-help.pdf";

const dayjsEnglishCustom = {
  ...dayjsEnglish,
  formats: {
    LT: "hh:mm A",
    LTS: "hh:mm:ss A",
    L: "MM/DD/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY h:mm A",
    LLLL: "dddd, D MMMM YYYY h:mm A"
  },
};

const defaultDayJsLocale = dayjsEnglishCustom;

export const LanguageStorageName = "language";

export const SupportedLanguages = Object.freeze([
  Object.freeze({
    englishName: "English",
    nativeName: "English",
    translations: English,
    localeCodes: ["en"],
    tosCountryCode: "eng",
    HelpFile: EnglishHelp,
    dayjsLocale: defaultDayJsLocale,
    dayjsCode: "en",
  }),
  Object.freeze({
    englishName: "Russian",
    nativeName: "русский",
    translations: Russian,
    localeCodes: ["ru"],
    tosCountryCode: "rus",
    HelpFile: RussianHelp,
    dayjsLocale: dayjsRussian,
    dayjsCode: "ru",
  }),
  Object.freeze({
    englishName: "Ukrainian",
    nativeName: "українська",
    translations: Ukrainian,
    localeCodes: ["uk"],
    tosCountryCode: "ukr",
    HelpFile: UkrainianHelp,
    dayjsLocale: dayjsUkrainian,
    dayjsCode: "uk",
  }),
  Object.freeze({
    englishName: "Spanish",
    nativeName: "Español",
    translations: Spanish,
    localeCodes: ["es"],
    tosCountryCode: "spa",
    HelpFile: SpanishHelp,
    dayjsLocale: dayjsSpanish,
    dayjsCode: "es"
  }),
  Object.freeze({
    englishName: "Portuguese",
    nativeName: "Português",
    translations: Portuguese,
    localeCodes: ["pt"],
    tosCountryCode: "por",
    HelpFile: PortugueseHelp,
    dayjsLocale: dayjsPortuguese,
    dayjsCode: "pt"
  }),
  Object.freeze({
    englishName: "French",
    nativeName: "Français",
    translations: French,
    localeCodes: ["fr"],
    tosCountryCode: "fra",
    HelpFile: FrenchHelp,
    dayjsLocale: dayjsFrench,
    dayjsCode: "fr"
  }),
  Object.freeze({
    englishName: "Japanese",
    nativeName: "日本語",
    translations: Japanese,
    localeCodes: ["ja"],
    tosCountryCode: "jpn",
    HelpFile: JapaneseHelp,
    dayjsLocale: dayjsJapanese,
    dayjsCode: "ja"
  }),
  Object.freeze({
    englishName: "Korean",
    nativeName: "한국어",
    translations: Korean,
    localeCodes: ["ko"],
    tosCountryCode: "kor",
    HelpFile: KoreanHelp,
    dayjsLocale: dayjsKorean,
    dayjsCode: "ko"
  }),
  Object.freeze({
    englishName: "Italian",
    nativeName: "Italiano",
    translations: Italian,
    localeCodes: ["it"],
    tosCountryCode: "ita",
    HelpFile: ItalianHelp,
    dayjsLocale: dayjsItalian,
    dayjsCode: "it"
  }),
  Object.freeze({
    englishName: "Chinese Traditional",
    nativeName: "繁體中文",
    translations: ChineseTraditional,
    localeCodes: ["zh-Hant"],
    tosCountryCode: "zho",
    HelpFile: ChineseTraditionalHelp,
    dayjsLocale: dayjsChinese,
    dayjsCode: "zh"
  }),
  Object.freeze({
    englishName: "Chinese Simplified",
    nativeName: "简体中文",
    translations: ChineseSimplified,
    localeCodes: ["zh", "zh-Hans"],
    tosCountryCode: "zho",
    HelpFile: null,
    dayjsLocale: dayjsChinese,
    dayjsCode: "zh"
  }),
  Object.freeze({
    englishName: "Cebuano",
    nativeName: "Cebuano",
    translations: Cebuano,
    localeCodes: ["ceb"],
    tosCountryCode: "ceb",
    HelpFile: CebuanoHelp,
    dayjsLocale: defaultDayJsLocale,
    dayjsCode: "en"
  }),
  Object.freeze({
    englishName: "Tagalog",
    nativeName: "Tagalog",
    translations: Tagalog,
    localeCodes: ["tl", "fil"],
    tosCountryCode: "tgl",
    HelpFile: TagalogHelp,
    dayjsLocale: dayjsTagalog,
    dayjsCode: "tl"
  }),
  Object.freeze({
    englishName: "German",
    nativeName: "Deutsch",
    translations: German,
    localeCodes: ["de"],
    tosCountryCode: "deu",
    HelpFile: GermanHelp,
    dayjsLocale: dayjsGerman,
    dayjsCode: "de"
  }),
  Object.freeze({
    englishName: "Mongolian",
    nativeName: "Монгол",
    translations: Mongolian,
    localeCodes: ["mn"],
    tosCountryCode: "mon",
    HelpFile: MongolianHelp,
    dayjsLocale: dayjsMongolian,
    dayjsCode: "mn"
  }),
  Object.freeze({
    englishName: "Samoan",
    nativeName: "Gagana Samoa",
    translations: Samoan,
    localeCodes: ["sm"],
    tosCountryCode: "smo",
    HelpFile: SamoanHelp,
    dayjsLocale: defaultDayJsLocale,
    dayjsCode: "en"
  }),
  Object.freeze({
    englishName: "Tongan",
    nativeName: "Faka-tonga",
    translations: Tongan,
    localeCodes: ["to"],
    tosCountryCode: "ton",
    HelpFile: TonganHelp,
    dayjsLocale: defaultDayJsLocale,
    dayjsCode: "en"
  }),
  Object.freeze({
    englishName: "Hungarian",
    nativeName: "Magyar",
    translations: Hungarian,
    localeCodes: ["hu"],
    tosCountryCode: "hun",
    HelpFile: null,
    dayjsLocale: dayjsHungarian,
    dayjsCode: "hu"
  }),
  Object.freeze({
    englishName: "Czech",
    nativeName: "čeština",
    translations: Czech,
    localeCodes: ["cs"],
    tosCountryCode: "ces",
    HelpFile: CzechHelp,
    dayjsLocale: dayjsCzech,
    dayjsCode: "cs"
  }),
  Object.freeze({
    englishName: "Romanian",
    nativeName: "Română",
    translations: Romanian,
    localeCodes: ["ro"],
    tosCountryCode: "ron",
    HelpFile: RomaninanHelp,
    dayjsLocale: dayjsRomanian,
    dayjsCode: "ro"
  }),
  Object.freeze({
    englishName: "Slovak",
    nativeName: "Slovenčina",
    translations: Slovak,
    localeCodes: ["sk"],
    tosCountryCode: "slk",
    HelpFile: SlovakHelp,
    dayjsLocale: dayjsSlovak,
    dayjsCode: "sk"
  }),
  Object.freeze({
    englishName: "Lithuanian",
    nativeName: "Lietuvių",
    translations: Lithuanian,
    localeCodes: ["lt"],
    tosCountryCode: "lit",
    HelpFile: LithuanHelp,
    dayjsLocale: dayjsLithuanian,
    dayjsCode: "lt"
  }),
  Object.freeze({
    englishName: "Albanian",
    nativeName: "Albanian",
    translations: Albanian,
    localeCodes: ["sq", "sq-AL", "sq-MK", "sq-XK"],
    tosCountryCode: "sq",
    HelpFile: AlbanianHelp,
    dayjsLocale: dayjsAlbanian,
    dayjsCode: "sq"
  }),
  Object.freeze({
    englishName: "Armenian",
    nativeName: "Հայերեն",
    translations: Armenian,
    localeCodes: ["hy", "hy-AM"],
    tosCountryCode: "hy",
    HelpFile: ArmenianHelp,
    dayjsLocale: dayjsArmenian,
    dayjsCode: "hy-am"
  }),
  Object.freeze({
    englishName: "Bulgarian",
    nativeName: "Български",
    translations: Bulgarian,
    localeCodes: ["bg, bg-BG"],
    tosCountryCode: "bg",
    HelpFile: BulgarianHelp,
    dayjsLocale: dayjsBulgarian,
    dayjsCode: dayjsBulgarian
  }),
  Object.freeze({
    englishName: "Cambodian",
    nativeName: "ភាសាខ្មែរ",
    translations: Cambodian,
    localeCodes: ["km"],
    tosCountryCode: "km",
    HelpFile: CambodianHelp,
    dayjsLocale: dayjsCambodian,
    dayjsCode: "km"
  }),
  Object.freeze({
    englishName: "Croation",
    nativeName: "Hrvatski",
    translations: Croatian,
    localeCodes: ["hr, hr-HR, hr-BA"],
    tosCountryCode: "hr",
    HelpFile: CroatianHelp,
    dayjsLocale: dayjsCroatian,
    dayjsCode: "hr",
  }),
  Object.freeze({
    englishName: "Danish",
    nativeName: "Dansk",
    translations: Danish,
    localeCodes: ["da", "da-DK", "da-GL"],
    tosCountryCode: "da",
    HelpFile: DanishHelp,
    dayjsLocale: dayjsDanish,
    dayjsCode: "da"
  }),
  Object.freeze({
    englishName: "Dutch",
    nativeName: "Nederlands",
    translations: Dutch,
    localeCodes: ["nl", "nl-AW", "nl-BE", "nl-CW", "nl-NL", "nl-SR", "nl-BQ", "nl-SX"],
    tosCountryCode: "nl",
    HelpFile: DutchHelp,
    dayjsLocale: dayjsDutch,
    dayjsCode: "nl"
  }),
  Object.freeze({
    englishName: "Estonian",
    nativeName: "Eesti",
    translations: Estonian,
    localeCodes: ["et", "et-EE"],
    tosCountryCode: "et",
    HelpFile: EstonianHelp,
    dayjsLocale: dayjsEstonian,
    dayjsCode: "et"
  }),
  Object.freeze({
    englishName: "Fijian",
    nativeName: "Viti",
    translations: Fijian,
    localeCodes: ["fj"],
    tosCountryCode: "fj",
    HelpFile: FijianHelp,
    dayjsLocale: defaultDayJsLocale,
    dayjsCode: "fj"
  }),
  Object.freeze({
    englishName: "Finnish",
    nativeName: "Suomi",
    translations: Finnish,
    localeCodes: ["fi", "fi-FI"],
    tosCountryCode: "fi",
    HelpFile: FinnishHelp,
    dayjsLocale: dayjsFinnish,
    dayjsCode: "fi"
  }),
  Object.freeze({
    englishName: "Indonesian",
    nativeName: "Indonesia",
    translations: Indonesian,
    localeCodes: ["id", "id-ID"],
    tosCountryCode: "id",
    HelpFile: IndonesianHelp,
    dayjsLocale: dayjsIndonesian,
    dayjsCode: "id"
  }),
  Object.freeze({
    englishName: "Latvian",
    nativeName: "Latvių",
    translations: Latvian,
    localeCodes: ["lv", "lv-LV"],
    tosCountryCode: "lv",
    HelpFile: LatvianHelp,
    dayjsLocale: dayjsLatvian,
    dayjsCode: "lv"
  }),
  Object.freeze({
    englishName: "Malay",
    nativeName: "Bahasa Melayu",
    translations: Malay,
    localeCodes: ["ms"],
    tosCountryCode: "ms",
    HelpFile: MalayHelp,
    dayjsLocale: dayjsMalay,
    dayjsCode: "ms"
  }),
  Object.freeze({
    englishName: "Norwegian",
    nativeName: "Norsk",
    translations: Norwegian,
    localeCodes: ["nn", "nb", "nn-NO", "nb-NO", "nb-SJ"],
    tosCountryCode: "nn",
    HelpFile: NorwegianHelp,
    dayjsLocale: dayjsNorwegian,
    dayjsCode: "nn"
  }),
  Object.freeze({
    englishName: "Polish",
    nativeName: "Polski",
    translations: Polish,
    localeCodes: ["pl", "pl-PL"],
    tosCountryCode: "pl",
    HelpFile: PolishHelp,
    dayjsLocale: dayjsPolish,
    dayjsCode: "pl"
  }),
  Object.freeze({
    englishName: "Serbian",
    nativeName: "Srpski",
    translations: Serbian,
    localeCodes: ["sr", "sr-Latn", "sr-Cyrl"],
    tosCountryCode: "sr",
    HelpFile: SerbianHelp,
    dayjsLocale: dayjsSerbian,
    dayjsCode: "sr"
  }),
  Object.freeze({
    englishName: "Swedish",
    nativeName: "Svenska",
    translations: Swedish,
    localeCodes: ["sv", "sv-fi", "sv-SE", "sv-AX"],
    tosCountryCode: "sv",
    HelpFile: SwedishHelp,
    dayjsLocale: dayjsSwedish,
    dayjsCode: "sv"
  }),
  Object.freeze({
    englishName: "Tahitian",
    nativeName: "Tahiti",
    translations: Tahitian,
    localeCodes: ["ty"],
    tosCountryCode: "ty",
    HelpFile: TahitianHelp,
    dayjsLocale: defaultDayJsLocale,
    dayjsCode: "ty"
  }),
  Object.freeze({
    englishName: "Thai",
    nativeName: "ไทย",
    translations: Thai,
    localeCodes: ["th", "th-TH"],
    tosCountryCode: "th",
    HelpFile: ThaiHelp,
    dayjsLocale: dayjsThai,
    dayjsCode: "th"
  }),
  Object.freeze({
    englishName: "Vietnamese",
    nativeName: "Việt Nam",
    translations: Vietnamese,
    localeCodes: ["vi", "vi-VN"],
    tosCountryCode: "vi",
    HelpFile: VietnameseHelp,
    dayjsLocale: dayjsVietnamese,
    dayjsCode: "vi"
  }),
  Object.freeze({
    englishName: "Kekchi",
    nativeName: "Qʼeqchiʼ",
    translations: Kekchi,
    localeCodes: ["kek"],
    tosCountryCode: "kek",
    HelpFile: EnglishHelp,
    dayjsLocale: defaultDayJsLocale,
    dayjsCode: "kek"
  }),
  Object.freeze({
    englishName: "Greek",
    nativeName: "Eλληνικά",
    translations: Greek,
    localeCodes: ["el"],
    tosCountryCode: "el",
    HelpFile: EnglishHelp,
    dayjsLocale: dayjsGreek,
    dayjsCode: "el"
  }),
  Object.freeze({
    englishName: "Marshallese",
    nativeName: "Kajin M̧ajeļ",
    translations: Marshallese,
    localeCodes: ["mh", "mah"],
    tosCountryCode: "mh",
    HelpFile: MarshalleseHelp,
    dayjsLocale: defaultDayJsLocale,
    dayjsCode: "mh"
  })
]);

export const DefaultLanguage = Object.freeze({ ...SupportedLanguages[0], code: "en-us" });