import { useState, useEffect } from "react";
import MapsLoader from "components/maps-loader";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { sortGroupsByLocationDistance, getMapLocationFromGoogleMap, LocationGroup } from "utilities/geolocation";
import MuiSwipeablePanel from "components/mui-swipeable-panel";
import Loading from "components/loading/loading";
import GroupFinderCard from "../group-finder-card/group-finder-card";
import { FormattedMessage } from "react-intl";
import useGroupFinderStore from "../group-finder-store";
import GroupFinderView from "../models";
import { MapLocation } from "lib/data/model";
import { useIntl } from "react-intl";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MobileLocationGroupList from "./mobile-location-group-list";
import Button from "@mui/material/Button";
import "./group-finder-mobile-view.scss";
import { GroupDetails } from "lib/models-v2";

const MapOptions = {
  disableDefaultUI: true,
  clickableIcons: false,
  zoomControl: true,
  gestureHandling: "greedy"
};

interface IGroupFinderMobileView {
  searchArea: (area?: MapLocation) => Promise<void>
  courseSignup: (group: GroupDetails) => void;
}

export const GroupFinderMobileView = (props: IGroupFinderMobileView): JSX.Element => {

  const joinNowStore = useGroupFinderStore();
  const intl = useIntl();
  const { isLoading, filteredGroups, availableGroups, currentView, setView, currentLocation, setCurrentLocation, minMapZoom, maxMapZoom, setMapInView } = joinNowStore;
  const locationGroups = filteredGroups.length && currentLocation ? sortGroupsByLocationDistance(filteredGroups, currentLocation.center) : [];
  const [activeLocation, setActiveLocation] = useState<LocationGroup | undefined>(undefined);
  const [mapApi, setMapApi] = useState<google.maps.Map | null>(null);
  const [showSearchAreaButton, setShowSearchAreaButton] = useState<boolean>(false);

  useEffect(() => {

    const hideFooter = () => {
      //hide the footer while in mobile view
      const footer = document.querySelector<HTMLElement>("platform-footer");
      //the footer isn't always available when this runs, so we run it periodically, once its loaded we don't run this anymore
      //another option would be to setup a mutation observer
      if (footer) {
        footer.style.display = "none";
      } else {
        setTimeout(hideFooter, 200);
      }
    };

    //DocumentHeight must be captured with JS, can't rely on using 100vh in many mobile browsers
    //https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    };

    window.addEventListener("resize", documentHeight);

    //For some reason sometimes safari loads with the header scrolled scrolled out of screen
    window.scrollTo(0, 0);

    documentHeight();
    hideFooter();

    return function cleanup() {
      const footer = document.querySelector<HTMLElement>("platform-footer");
      if (footer) {
        footer.style.display = "";
      }
      window.removeEventListener("resize", documentHeight);
    };
  }, []);

  useEffect(() => {
    setMapInView(mapApi);
  }, [mapApi]);

  const restoreZoom = () => {
    if (mapApi && currentLocation?.zoom) {
      mapApi.setZoom(currentLocation.zoom);
    }
  };

  const handleSearchAreaClick = () => {
    if (mapApi) {
      clearActiveLocation();
      props.searchArea();
    }
  };

  const displaySearchAreaButton = () => {
    setShowSearchAreaButton(true);
  };

  const handleMapLoaderReady = () => {
    restoreZoom();
  };

  const handleMapMove = () => {
    if (mapApi) {
      displaySearchAreaButton();
      const newLocation = getMapLocationFromGoogleMap(mapApi);
      if (newLocation && !isLoading) {
        setCurrentLocation(newLocation);
      }
    } else {
      setShowSearchAreaButton(false);
    }
  };

  const handleMapLoad = (map: google.maps.Map) => {
    map.setOptions({
      maxZoom: maxMapZoom,
      minZoom: minMapZoom
    });
    setMapApi(map);
  };

  const handlePanelClose = () => {
    setView(GroupFinderView.mapView);
    clearActiveLocation();
  };

  const handleBackToGroups = () => {
    setView(GroupFinderView.listView);
    clearActiveLocation();
  };

  const clearActiveLocation = () => {
    setActiveLocation(undefined);
  };

  return (
    <div className="groupFinderMobileViewContainer">
      {isLoading &&
        <div className="loaderContainer">
          <Loading loading={true} message={intl.formatMessage({ id: "getting_groups", defaultMessage: "Getting Groups" })} />
        </div>
      }
      <div className="mapContainer">
        {currentLocation &&
          <MapsLoader
            onReady={handleMapLoaderReady}
          >
            <GoogleMap
              center={currentLocation?.center}
              mapContainerClassName={"group-finder-mobile-map"}
              zoom={currentLocation?.zoom}
              onClick={clearActiveLocation}
              onDragEnd={handleMapMove}
              onZoomChanged={handleMapMove}
              options={MapOptions}
              onLoad={handleMapLoad}
            >

              {locationGroups.map((locationGroup, index) => {
                const coords = {
                  lat: locationGroup.latitude,
                  lng: locationGroup.longitude
                };
                return (
                  <Marker key={index} position={coords} onClick={() => setActiveLocation(locationGroup)} />
                );
              })}

              {!isLoading && showSearchAreaButton &&
                <div className="search-this-area">
                  <button
                    className="search-area-button"
                    onClick={handleSearchAreaClick}
                  >
                    <FormattedMessage
                      id="search_area"
                      defaultMessage="Search This Area"
                    />
                  </button>
                </div>
              }
            </GoogleMap>
          </MapsLoader>
        }
        {!currentLocation && !isLoading &&
          <p className="no-location"><FormattedMessage id="enter_search_location" defaultMessage="Enter a search location to see results." /></p>
        }
      </div>
      {!isLoading &&
        <MuiSwipeablePanel
          anchor="bottom"
          peakContent={
            !isLoading && availableGroups.length > 0 ?
              <>
                {activeLocation != undefined ?
                  <Button className="backButton" startIcon={<ArrowBackIcon />} onClick={handleBackToGroups}>
                    <FormattedMessage id="back" defaultMessage="back" />
                  </Button>
                  :
                  <p className="resultCount">{`${filteredGroups.length} / ${availableGroups.length}`} <FormattedMessage id="groups" defaultMessage="Groups" /></p>
                }
              </>
              :
              <p>
                {!currentLocation ?
                  <FormattedMessage id="enter_search_location" defaultMessage="Enter a search location to see results." />
                  :
                  <FormattedMessage id="groups_not_found_search_message" defaultMessage="No groups found in your search area."></FormattedMessage>
                }
              </p>
          }
          isLoading={isLoading}
          open={(currentView == GroupFinderView.listView) || activeLocation != undefined}
          onClose={handlePanelClose}
          onOpen={() => setView(GroupFinderView.listView)}
        >
          {activeLocation != undefined ?
            <MobileLocationGroupList location={activeLocation} />
            :
            <>
              {filteredGroups.map((group, index) => <GroupFinderCard group={group} key={`${index}-${group.id}`} onSelect={() => props.courseSignup(group)} />)}
            </>
          }
        </MuiSwipeablePanel>
      }
    </div >
  );
};

export default GroupFinderMobileView;