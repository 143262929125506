import React from "react";
import Card from "components/card/card";
import { Link } from "react-router-dom";
import AddIcon from "assets/images/icons/add.svg";
import AuthService from "auth/authorize-service";
import { Permissions } from "constants/api-auth-constants";
import { FormattedMessage } from "react-intl";
import "./course-list-card.scss";

const CourseListCard = (props) => {

  const { course } = props;
  const canCreateNewGroup = AuthService.userCan([Permissions.NewGroupWrite]);

  return (
    <div className="courseListCardContainer">
      <Card
        head={
          <div className="cardHeader">
            <div className="cardTitle">{course.name}</div>
            <Link to={canCreateNewGroup ? { pathname: "/groups/add", state: { defaultCourse: { providerId: course.providerId, courseId: course.id } } } : null}><button disabled={!canCreateNewGroup} className="primary"><span className="buttonIcon"><img src={AddIcon} alt="Addition Symbol" /></span><FormattedMessage id="group" defaultMessage="Group" /></button></Link>
          </div>}
      >
        <p className="description">
          {course.description}
        </p>
      </Card>
    </div>
  );
};

export default CourseListCard;