import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk, { ThunkDispatch } from "redux-thunk";
import mapDispatchToProps from "datastore/dispatcher";
import userReducer from "datastore/user";
import appReducer from "datastore/app";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import store from "./store";

const reducers = combineReducers({ appReducer, userReducer });

export type RootState = ReturnType<typeof reducers>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
type AppAction = ReturnType<typeof store.dispatch>;
export type AppDispatch = ThunkDispatch<RootState, any, AppAction>;
//Might not be needed.
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const appStore = createStore((reducers), applyMiddleware(thunk));

export const mapStateToProps = (state) => {
  return {
    store: Object.assign({}, state.appReducer, state.userReducer)
  };
};

export { mapDispatchToProps };

export default appStore;