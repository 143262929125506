import ReactDOM from "react-dom";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import { Provider } from "react-redux";
import appStore from "datastore/store";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={appStore}>
    <App />
  </Provider>,
  rootElement
);

unregister();
