import { Group } from "lib/data/model";
import { ICourseOutcomeDto, CourseOutcomeDto } from "./outcomes";
import { IFacilitatorDtoCreationValues, FacilitatorDto } from "./facilitator";
import MeetingTimeDto, { IMeetingTimeDtoCreationValues } from "./MeetingTimeDto";
import { IProviderProgramLanguageDto, ProviderProgramLanguageDto } from "./provider";
import StudentDto, { IStudentDtoCreationValues } from "./StudentDto";
import WsrsOutcomeDto, { IWsrsOutcomeDto } from "./WsrsOutcomeDto";
import GroupLanguageDto, { IGroupLanguageDto, IGroupLanguageDtoCreationValues } from "./GroupLanguageDto";
import type { FullGroup, GroupDetails } from "lib/models-v2/LeadersGroupResponse";

export interface IGroupDtoCreationValues {
  id?: number;
  name: string;
  locationId?: number;
  courseId?: number;
  courseName: string;
  courseDescription: string;
  courseOutcomes?: ICourseOutcomeDto[];
  numberOfLessons: number;
  lessonTime: number;
  providerId?: number;
  programId?: number;
  programDescription: string;
  programLanguages: IProviderProgramLanguageDto[];
  termId?: number;
  trackId?: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  latitude?: number;
  longitude?: number;
  startDate?: string;
  endDate?: string;
  meetingTimes: IMeetingTimeDtoCreationValues[];
  facilitators: IFacilitatorDtoCreationValues[];
  nonMemberFacilitators: IFacilitatorDtoCreationValues[];
  students: IStudentDtoCreationValues[];
  enrollmentCount?: number;
  maxEnrollment?: number;
  status: string;
  duration: number;
  propId?: number;
  isVirtual: boolean;
  isYouthOnly: boolean;
  isAdultOnly: boolean;
  groupAgeType: string;
  isHidden?: boolean;
  isCustom: boolean;
  isPending: boolean;
  hasAcceptedYouthTerms: boolean;
  hasParticipationCertificate: boolean;
  hasCompletionCertificate: boolean;
  wsrsOutcomes: IWsrsOutcomeDto[];
  stakeUnitNumber?: number;
  groupLanguages: IGroupLanguageDtoCreationValues[];
}

export interface IGroupDto {
  id?: number;
  name: string;
  locationId?: number;
  courseId?: number;
  courseName: string;
  courseDescription: string;
  courseOutcomes?: ICourseOutcomeDto[];
  numberOfLessons: number;
  lessonTime: number;
  providerId?: number;
  programId?: number;
  programDescription: string;
  programLanguages: IProviderProgramLanguageDto[];
  termId?: number;
  trackId?: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  latitude?: number;
  longitude?: number;
  startDate?: string;
  endDate?: string;
  meetingTimes: MeetingTimeDto[];
  facilitators: FacilitatorDto[];
  nonMemberFacilitators: FacilitatorDto[];
  students: StudentDto[];
  enrollmentCount?: number;
  maxEnrollment?: number;
  status: string;
  duration: number;
  propId?: number;
  isVirtual: boolean;
  isYouthOnly: boolean;
  isAdultOnly: boolean;
  groupAgeType: string;
  isHidden?: boolean;
  isCustom: boolean;
  isPending: boolean;
  hasAcceptedYouthTerms: boolean;
  hasParticipationCertificate: boolean;
  hasCompletionCertificate: boolean;
  wsrsOutcomes: IWsrsOutcomeDto[];
  stakeUnitNumber?: number;
  groupLanguages: IGroupLanguageDto[];
}
export default class GroupDto implements IGroupDto {
  public id?: number;
  public name: string;
  public locationId?: number;
  public courseId?: number;
  public courseName: string;
  public courseDescription: string;
  public courseOutcomes?: CourseOutcomeDto[];
  public numberOfLessons: number;
  public lessonTime: number;
  public providerId?: number;
  public programId?: number;
  public programDescription: string;
  public programLanguages: ProviderProgramLanguageDto[];
  public termId?: number;
  public trackId?: number;
  public address1: string;
  public address2: string;
  public city: string;
  public state: string;
  public country: string;
  public postalCode: string;
  public latitude?: number;
  public longitude?: number;
  public startDate?: string;
  public endDate?: string;
  public meetingTimes: MeetingTimeDto[];
  public facilitators: FacilitatorDto[];
  public nonMemberFacilitators: FacilitatorDto[];
  public students: StudentDto[];
  public enrollmentCount?: number;
  public maxEnrollment?: number;
  public status: string;
  public duration: number;
  public propId?: number;
  public isVirtual: boolean;
  public isYouthOnly: boolean;
  public isAdultOnly: boolean;
  public groupAgeType: string;
  public isHidden?: boolean;
  public isCustom: boolean;
  public isPending: boolean;
  public hasAcceptedYouthTerms: boolean;
  public hasParticipationCertificate: boolean;
  public hasCompletionCertificate: boolean;
  public wsrsOutcomes: WsrsOutcomeDto[];
  public stakeUnitNumber?: number;
  public groupLanguages: GroupLanguageDto[];

  constructor(creationValues: IGroupDtoCreationValues) {
    this.id = creationValues.id;
    this.name = creationValues.name;
    this.locationId = creationValues.locationId;
    this.courseId = creationValues.courseId;
    this.courseName = creationValues.courseName;
    this.courseDescription = creationValues.courseDescription;
    this.courseOutcomes = creationValues.courseOutcomes ? creationValues.courseOutcomes.map(co => new CourseOutcomeDto(co)) : [];
    this.numberOfLessons = creationValues.numberOfLessons;
    this.lessonTime = creationValues.lessonTime;
    this.providerId = creationValues.providerId;
    this.programId = creationValues.programId;
    this.programDescription = creationValues.programDescription;
    this.programLanguages = creationValues.programLanguages ? creationValues.programLanguages.map(pl => new ProviderProgramLanguageDto(pl)) : [];
    this.termId = creationValues.termId;
    this.trackId = creationValues.trackId;
    this.address1 = creationValues.address1;
    this.address2 = creationValues.address2;
    this.city = creationValues.city;
    this.state = creationValues.state;
    this.country = creationValues.country;
    this.postalCode = creationValues.postalCode;
    this.latitude = creationValues.latitude;
    this.longitude = creationValues.longitude;
    this.startDate = creationValues.startDate;
    this.endDate = creationValues.endDate;
    this.meetingTimes = creationValues.meetingTimes ? creationValues.meetingTimes.map(mt => new MeetingTimeDto(mt)) : [];
    this.facilitators = creationValues.facilitators ? creationValues.facilitators.map(f => new FacilitatorDto(f)) : [];
    this.nonMemberFacilitators = creationValues.nonMemberFacilitators ? creationValues.nonMemberFacilitators.map(f => new FacilitatorDto(f)) : [];
    this.students = creationValues.students ? creationValues.students.map(s => new StudentDto(s)) : [];
    this.enrollmentCount = creationValues.enrollmentCount;
    this.maxEnrollment = creationValues.maxEnrollment;
    this.status = creationValues.status;
    this.duration = creationValues.duration;
    this.propId = creationValues.propId;
    this.isVirtual = creationValues.isVirtual;
    this.isYouthOnly = creationValues.isYouthOnly;
    this.isAdultOnly = creationValues.isAdultOnly;
    this.groupAgeType = creationValues.groupAgeType;
    this.isHidden = creationValues.isHidden;
    this.isCustom = creationValues.isCustom;
    this.isPending = creationValues.isPending;
    this.hasAcceptedYouthTerms = creationValues.hasAcceptedYouthTerms;
    this.hasParticipationCertificate = creationValues.hasParticipationCertificate;
    this.hasCompletionCertificate = creationValues.hasCompletionCertificate;
    this.wsrsOutcomes = creationValues.wsrsOutcomes ? creationValues.wsrsOutcomes.map(wo => new WsrsOutcomeDto(wo)) : [];
    this.stakeUnitNumber = creationValues.stakeUnitNumber;
    this.groupLanguages = creationValues.groupLanguages ? creationValues.groupLanguages.map(groupLangDto => new GroupLanguageDto(groupLangDto)) : [];
  }

  public export(): IGroupDtoCreationValues {
    return {
      id: this.id,
      name: this.name,
      locationId: this.locationId,
      courseId: this.courseId,
      courseName: this.courseName,
      courseDescription: this.courseDescription,
      courseOutcomes: this.courseOutcomes?.map(co => co.export()),
      numberOfLessons: this.numberOfLessons,
      lessonTime: this.lessonTime,
      providerId: this.providerId,
      programId: this.programId,
      programDescription: this.programDescription,
      programLanguages: this.programLanguages?.map(pl => pl.export()),
      termId: this.termId,
      trackId: this.trackId,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      country: this.country,
      postalCode: this.postalCode,
      latitude: this.latitude,
      longitude: this.longitude,
      startDate: this.startDate,
      endDate: this.endDate,
      meetingTimes: this.meetingTimes.map(mt => mt.export()),
      facilitators: this.facilitators.map(f => f.export()),
      nonMemberFacilitators: this.nonMemberFacilitators.map(nmf => nmf.export()),
      students: this.students.map(s => s.export()),
      enrollmentCount: this.enrollmentCount,
      maxEnrollment: this.maxEnrollment,
      status: this.status,
      duration: this.duration,
      propId: this.propId,
      isVirtual: this.isVirtual,
      isYouthOnly: this.isYouthOnly,
      isAdultOnly: this.isAdultOnly,
      groupAgeType: this.groupAgeType,
      isHidden: this.isHidden,
      isCustom: this.isCustom,
      isPending: this.isPending,
      hasAcceptedYouthTerms: this.hasAcceptedYouthTerms,
      hasParticipationCertificate: this.hasParticipationCertificate,
      hasCompletionCertificate: this.hasCompletionCertificate,
      wsrsOutcomes: this.wsrsOutcomes?.map(wo => wo.export()),
      stakeUnitNumber: this.stakeUnitNumber,
      groupLanguages: this.groupLanguages.map(grouplangDto => grouplangDto.export())
    };
  }

  public clone(): GroupDto {
    return new GroupDto(this.export());
  }
}

export const isGroupPastEndDate = (group: GroupDto | Group | GroupDetails | FullGroup) => {
  if (!group.endDate)
    return false;

  const endDate = new Date(group.endDate);
  const today = new Date();

  if (today > endDate)
    return true;

  return false;
};