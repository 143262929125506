export interface INonMemberFacilitatorCreationDto {
  id?: number;
  groupId?: number;
  name: string;
}

export default class NonMemberFacilitatorCreationDto implements INonMemberFacilitatorCreationDto {

  public id?: number;
  public groupId?: number;
  public name: string;

  constructor(creationValues: INonMemberFacilitatorCreationDto) {
    this.id = creationValues.id;
    this.groupId = creationValues.groupId;
    this.name = creationValues.name;
  }

  public export(): INonMemberFacilitatorCreationDto {
    return {
      id: this.id,
      groupId: this.groupId,
      name: this.name
    };
  }

  public clone(): NonMemberFacilitatorCreationDto {
    return new NonMemberFacilitatorCreationDto(this.export());
  }
}