export interface ILatLng {
  lat: number;
  lng: number;
}

export class LatLng implements ILatLng {
  public lat: number;
  public lng: number;

  constructor(creationValues: ILatLng) {
    this.lat = creationValues.lat;
    this.lng = creationValues.lng;
  }

  public static MapFromGoogleLatLng(latLng: google.maps.LatLng) {
    return new LatLng({
      lat: latLng.lat(),
      lng: latLng.lng()
    });
  }

}

export default LatLng;