import { useState, useEffect, useRef } from "react";
import * as StudentFilters from "utilities/filters/student-filters";
import StudentStatuses from "constants/student-constants";
import Layouts from "constants/layout-constants";
import { ViewNames } from "../../models";
import { FormattedMessage, useIntl } from "react-intl";
import { StudentEnrollmentV2 } from "lib/data/model";
import { useSelector } from "react-redux";
import { ListControlPanel, FilterConfig, FilterInputType } from "components/list-control-panel";
import { ParticipantGroupDto } from "lib/api/backend/model/participant/ParticipantGroupDto";

type AvailableProgram = {
  id: number,
  name: string,
}

interface IParticipantListControls {
  participants: ParticipantGroupDto[]
  onFilter: (filteredStudentEnrollments: ParticipantGroupDto[]) => void;
  viewSetter: (view: ViewNames) => void;
  viewValue: ViewNames;
}

export const ParticipantListControls = (props: IParticipantListControls) => {

  const { participants, onFilter, viewSetter, viewValue } = props;
  const layout = useSelector((state: any) => state.appReducer.layout);
  const language = useSelector((state: any) => state.appReducer.language);
  const intl = useIntl();
  const isLoading = participants?.length <= 0;
  const availablePrograms = useRef<AvailableProgram[]>([]);
  const searchFields = (enrollments: StudentEnrollmentV2[]) => {
    return enrollments.map(enrollment => {
      return {
        firstName: enrollment.firstName,
        lastName: enrollment.lastName,
        course: {
          date: enrollment.course.date,
          name: enrollment.course.name,
          status: enrollment.course.status,
        }

      };
    });
  };

  const buildConfig = (): FilterConfig => {

    participants.forEach(participant => {
      const alreadyExists = availablePrograms.current.some(availableProgram => availableProgram.id == participant.programId);
      if (!alreadyExists) {
        availablePrograms.current.push({
          id: participant.programId,
          name: participant.programName
        });
      }
    });

    const newFilterConfig = [
      {
        filterGroupHeading: intl.formatMessage({ id: "group", defaultMessage: "Group" }),
        filters: [
          {
            name: intl.formatMessage({ id: "group_type", defaultMessage: "Group Type" }),
            allPossibleValues: availablePrograms.current,
            filterFunction: StudentFilters.removeStudentByCourse,
            displayField: "name",
            valueField: "id",
            inputType: FilterInputType.Checkbox,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "group_type", defaultMessage: "Group Type" }),
          }
        ]
      },
      {
        filterGroupHeading: intl.formatMessage({ id: "account_type", defaultMessage: "Account Type" }),
        filters: [
          {
            name: intl.formatMessage({ id: "account_type", defaultMessage: "Account Type" }),
            allPossibleValues: [
              {
                displayText: intl.formatMessage({ id: "church_account", defaultMessage: "Church Account" }),
                value: "Church Account"
              },
              {
                displayText: intl.formatMessage({ id: "email_phone", defaultMessage: "Email/Phone" }),
                value: "Email/Phone"
              }
            ],
            displayField: "displayText",
            valueField: "value",
            filterFunction: StudentFilters.removeStudentByAccountType,
            inputType: FilterInputType.Radio,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "account_type", defaultMessage: "Account Type" }),
          }
        ]
      },
      {
        filterGroupHeading: intl.formatMessage({ id: "status", defaultMessage: "Status" }),
        filters: [
          {
            name: intl.formatMessage({ id: "status", defaultMessage: "Status" }),
            allPossibleValues: Object.keys(StudentStatuses).map(key => {
              const studentStatus = StudentStatuses[key];
              return {
                displayText: intl.formatMessage({ id: studentStatus.toLowerCase().replace(" ", "_"), defaultMessage: studentStatus }),
                value: studentStatus
              };
            }),
            displayField: "displayText",
            valueField: "value",
            filterFunction: StudentFilters.removeStudentByStatus,
            inputType: FilterInputType.Checkbox,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "status", defaultMessage: "Status" }),
          }
        ]
      }
    ];

    return newFilterConfig;

  };
  const [filterConfig, setFilterConfig] = useState<FilterConfig>(buildConfig());

  useEffect(() => {
    const newConfig = buildConfig();
    setFilterConfig(newConfig);
  }, [language]);

  const inputs: any[] = [];

  if (layout == Layouts.Desktop) {
    inputs.push(
      {
        text: <FormattedMessage id="add_group" defaultMessage="View" />,
        action: viewSetter,
        type: "select",
        options: [
          {
            value: ViewNames.Table,
            label: intl.formatMessage({ id: "list_view", defaultMessage: "List View" })
          },
          {
            value: ViewNames.Card,
            label: intl.formatMessage({ id: "card_view", defaultMessage: "Card View" })
          }
        ],
        value: viewValue
      }
    );
  }

  return (
    <ListControlPanel dataset={participants} isLoading={isLoading} filterConfig={filterConfig} onFilter={onFilter} inputs={inputs} searchFields={searchFields} />
  );
};

export default ParticipantListControls;