import "./outcomes-survey.scss";
import { useState, useEffect, useRef, ReactElement, ChangeEvent } from "react";
import AuthService from "auth/authorize-service";
import { Permissions } from "constants/api-auth-constants";
import UserCan from "components/user-can/user-can";
import { FormattedMessage } from "react-intl";
import nextId from "react-id-generator";
import Loading from "components/loading/loading";
import Card from "components/card/card";
import { useIntl } from "react-intl";
import { ParticipantOutcome, Outcome, SurveyQuestion } from "./models";
import { CourseOutcomeCreationUpdateDto } from "lib/api/backend/model/outcomes";
import { getFullGroup, saveParticipantOutcomes } from "lib/api/backend/requests/groups";
import dispatchers from "datastore/dispatcher";
import { useDispatch, useSelector } from "react-redux";
import { NoticeCreation, Prompt } from "lib/data/model";
import Layouts from "constants/layout-constants";
import InfoHoverBox, { InfoHoverStatus } from "components/info-hover-box/info-hover-box";
import TruncatedStudentName from "components/truncated-student-name";
import { ServerResponseType } from "lib/api/backend/request-utils";
import { FullGroup, QuickRegParticipant } from "lib/models-v2";

export const SurveyResultNames = Object.freeze({
  Finished: "finished",
  Cancelled: "cancelled",
  Skipped: "skipped"
});

interface IOutcomesSurveyProps {
  locationId: number;
  programId: number;
  groupId: number;
  concludingGroup?: boolean;
  onFinish?: (result: string) => void;
  onEnrollmentChange?: () => void;
}

const OutcomesSurvey = (props: IOutcomesSurveyProps): ReactElement => {

  const [group, setGroup] = useState<FullGroup | null>();
  const [participantOutcomes, setParticipantOutcomes] = useState<ParticipantOutcome[]>([]);
  const intl = useIntl();
  const reduxDispatch = useDispatch();
  const notify = dispatchers(reduxDispatch).notify as (notice: NoticeCreation) => void;
  const prompt = dispatchers(reduxDispatch).prompt as (prompt: Prompt) => void;
  const layout = useSelector((state: any) => state.appReducer.layout);
  const surveyQuestions = useRef<readonly SurveyQuestion[]>(Object.freeze([
    {
      name: "startedOrImprovedBusiness",
      prompt: intl.formatMessage({ id: "outcome_business", defaultMessage: "Started or improved a business?" })
    },
    {
      name: "startedEduProgram",
      prompt: intl.formatMessage({ id: "outcome_started_edu", defaultMessage: "Started an educational program?" })
    },
    {
      name: "newOrBetterJob",
      prompt: intl.formatMessage({ id: "outcome_job", defaultMessage: "Got a new or better job?" })
    },
    {
      name: "startedOrImprovedBudget",
      prompt: intl.formatMessage({ id: "outcome_budget", defaultMessage: "Started or improved a budget?" })
    },
    {
      name: "earnedCertificate",
      prompt: intl.formatMessage({ id: "outcome_earned_cert", defaultMessage: "Earned a certificate?" }),
      disableOnEditIfYes: true,
      warning: intl.formatMessage({ id: "cannot_remove_cert", defaultMessage: "Once a certificate is marked as earned, it cannot be changed. This action cannot be undone." })
    }
  ]));
  const canConcludeGroup = AuthService.userCan([Permissions.GroupIndexWrite]);
  const promptCloseButtonId = useRef(nextId());
  const [loading, setLoading] = useState<{ isLoading: boolean, message: string }>({ isLoading: canConcludeGroup, message: intl.formatMessage({ id: "loading_outcomes", defaultMessage: "Loading Outcome Survey" }) });

  useEffect(() => {
    getGroupWithProtectedData();
  }, []);

  const getGroupWithProtectedData = async () => {
    if (canConcludeGroup) {
      setLoading({ isLoading: true, message: intl.formatMessage({ id: "loading_outcomes", defaultMessage: "Loading Outcome Survey" }) });
      if (props?.locationId && props?.programId && props?.groupId && group == null) {
        try {
          const getGroupResponse = await getFullGroup(props.groupId);

          if (getGroupResponse?.responseType != ServerResponseType.Success) {
            throw new Error();
          }
          const newGroup = getGroupResponse!.data;
          const newParticipantOutcomes: ParticipantOutcome[] = [];
          newGroup.quickRegParticipants.forEach(participant => {
            newParticipantOutcomes.push(getExistingStudentOutcomes(newGroup, participant));
          });
          if (newParticipantOutcomes.length < 1 && props?.concludingGroup) {
            setLoading({ isLoading: true, message: intl.formatMessage({ id: "no_participants_for_outcomes", defaultMessage: "No participants eligible for survey" }) });
            close(SurveyResultNames.Skipped);
          } else {
            setGroup(newGroup);
            setParticipantOutcomes(newParticipantOutcomes);
            setLoading({ isLoading: false, message: "" });
          }
        } catch (e) {
          notify({ status: "error", message: <FormattedMessage id="get_group_error" defaultMessage="Could not get group." /> });
          setGroup(null);
          setLoading({ isLoading: false, message: "" });
        }
      }
    }
  };

  const getExistingStudentOutcomes = (newGroup: FullGroup, participant: QuickRegParticipant): ParticipantOutcome => {
    const studentOutcomeSurvey = (() => {
      if (newGroup.wsrsOutcomes == null) {
        return null;
      }

      return newGroup.wsrsOutcomes.find(outcome => outcome.participantId == participant.id);

    })();

    const outcomes: Outcome[] = [];

    if (!studentOutcomeSurvey) {
      surveyQuestions.current.forEach(question => {
        outcomes.push({
          questionName: question.name,
          response: null,
          originalValue: null
        });
      });
    } else {
      surveyQuestions.current.forEach(question => {
        const response = studentOutcomeSurvey![question.name];
        outcomes.push({
          questionName: question.name,
          response,
          originalValue: response
        });
      });
    }
    return {
      participant,
      outcomes
    };
  };

  const handleResponse = (studentOutcomeIndex: number, questionName: string, response: boolean | null, event: ChangeEvent<HTMLInputElement>) => {
    response = ((event.target as HTMLInputElement).checked) ? response : null;
    const newStudentOutcomes = [...participantOutcomes];
    const foundOutcomeIndex = newStudentOutcomes[studentOutcomeIndex].outcomes.findIndex(outcome => outcome.questionName == questionName);
    if (foundOutcomeIndex !== -1) {
      newStudentOutcomes[studentOutcomeIndex].outcomes[foundOutcomeIndex].response = response;
      setParticipantOutcomes(newStudentOutcomes);
    }
  };

  const buildOutcomeDtos = () => {

    const participantsToSave: CourseOutcomeCreationUpdateDto[] = [];

    participantOutcomes.forEach((participantOutcome) => {
      const participant = participantOutcome.participant;
      const creationValues = {
        participantId: participant.id,
        currentStatus: participant.status,
      };

      participantOutcome.outcomes.forEach(outcome => {
        creationValues[outcome.questionName] = outcome.response;
      });
      participantsToSave.push(creationValues as CourseOutcomeCreationUpdateDto);

    });

    return participantsToSave;
  };

  const saveResponses = async () => {
    if (canConcludeGroup) {
      if (!loading.isLoading) {
        const awardingNewCertificate = participantOutcomes.find(studentOutcome => {
          return studentOutcome.outcomes.find(outcome => outcome.questionName == "earnedCertificate" && outcome.originalValue !== true && outcome.response === true);
        });

        if (awardingNewCertificate) {
          const canContinue = await showAwardCertificateWarning();
          if (!canContinue) {
            return;
          }
        }

        setLoading({ isLoading: true, message: intl.formatMessage({ id: "saving_outcomes", defaultMessage: "Saving Outcomes" }) });
        const outcomeDtos = buildOutcomeDtos();
        const result = await saveParticipantOutcomes(group!.id!, outcomeDtos);
        if (result) {
          notify({ status: "success", message: <FormattedMessage id="outcomes_saved" defaultMessage="Outcomes saved." /> });
          if (props.onEnrollmentChange) {
            props.onEnrollmentChange();
          }
          close(SurveyResultNames.Finished);
        } else {
          notify({ status: "error", message: <FormattedMessage id="outcomes_save_error" defaultMessage="An error occured while attempting to save outcomes." /> });
        }
        setLoading({ isLoading: false, message: "" });
      }
    }
  };

  const showAwardCertificateWarning = async () => {
    return new Promise((resolve) => {
      prompt({
        status: "warning",
        head: (
          <FormattedMessage
            id="please_confirm"
            defaultMessage="Please Confirm"
          />
        ),
        message: (
          <FormattedMessage
            id="cannot_remove_cert"
            defaultMessage="Once a certificate is marked as earned, it cannot be changed. This action cannot be undone."
          />
        ),
        buttons: (
          <>
            <button
              className="primary"
              onClick={() => {
                resolve(true);
              }}
            >
              <FormattedMessage id="continue" defaultMessage="Continue" />
            </button>
            <button
              className="primary hollow"
              onClick={() => {
                resolve(false);
              }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </button>
          </>
        ),
      });
    });
  };

  const cancel = () => {
    close(SurveyResultNames.Cancelled);
  };

  const close = (result: string) => {
    if (props.onFinish) {
      props.onFinish(result);
    }
    document.getElementById(promptCloseButtonId.current)?.click(); //weird way of firing a click event that will be caught in the prompt and make it close
  };

  const buildTableLayout = () => {
    return (
      <div className="surveyTable">
        <div className="surveyRow head">
          <div className="column student">
            <FormattedMessage id="name" defaultMessage="Name" />
          </div>
          {surveyQuestions.current.map((question) => {
            return (
              <div className="column question" key={question.name}>
                {question.prompt}
                {question?.warning &&
                  <InfoHoverBox status={InfoHoverStatus.warning} disableOverflowScroll={true}>
                    <p>{question.warning}</p>
                  </InfoHoverBox>
                }
              </div>
            );
          })}
        </div>
        {participantOutcomes.map((participantOutcome, participantOutcomeIndex) => {
          const { outcomes, participant } = participantOutcome;
          return (
            <div className="surveyRow" key={participant.id}>
              <div className="column student">
                <TruncatedStudentName maxLength={26} student={participantOutcome.participant} />
              </div>
              {surveyQuestions.current.map(question => {
                const outcomeIndex = outcomes.findIndex(outcome => outcome.questionName == question.name);
                const outcome = outcomes[outcomeIndex];
                const yesInputId = nextId();
                const noInputId = nextId();
                const disabled = !!(question?.disableOnEditIfYes && outcome!.originalValue == true);
                return (
                  <div className="surveyColumn response" key={`${participant.id}-${question.name}`}>
                    <label htmlFor={yesInputId}><FormattedMessage id="yes" defaultMessage="Yes" /></label>
                    <input id={yesInputId} type="checkbox" disabled={disabled} className="surveyResponseInput" checked={outcome.response === true} onChange={(event) => handleResponse(participantOutcomeIndex, question.name, true, event)} />
                    <label htmlFor={noInputId}><FormattedMessage id="no" defaultMessage="No" /></label>
                    <input id={noInputId} type="checkbox" disabled={disabled} className="surveyResponseInput" checked={outcome.response === false} onChange={(event) => handleResponse(participantOutcomeIndex, question.name, false, event)} />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const buildCardLayout = () => {
    return (
      <div className="studentList">
        {participantOutcomes.map((participantOutcome, participantOutcomeIndex) => {
          const { outcomes, participant } = participantOutcome;
          return (
            <Card head={<TruncatedStudentName maxLength={26} student={participant} />} key={`${participantOutcomeIndex}-${participant.id}`}>
              {surveyQuestions.current.map((question, questionIndex) => {
                const outcomeIndex = outcomes.findIndex(outcome => outcome.questionName == question.name);
                const outcome = outcomes[outcomeIndex];
                const yesInputId = nextId();
                const noInputId = nextId();
                const disabled = !!(question?.disableOnEditIfYes && outcome!.originalValue == true);
                return (
                  <div className="questionContainer" key={`${participantOutcomeIndex}-${questionIndex} `}>
                    <div className="question">
                      {question.prompt}
                      {question?.warning &&
                        <InfoHoverBox status={InfoHoverStatus.warning} disableOverflowScroll={true}>
                          <p>{question.warning}</p>
                        </InfoHoverBox>
                      }
                    </div>
                    <div className="responseContainer">
                      <label htmlFor={yesInputId}><FormattedMessage id="yes" defaultMessage="Yes" /></label>
                      <input id={yesInputId} type="checkbox" disabled={disabled} className="surveyResponseInput" checked={outcome.response === true} onChange={(event) => handleResponse(participantOutcomeIndex, question.name, true, event)} />
                      <label htmlFor={noInputId}><FormattedMessage id="no" defaultMessage="No" /></label>
                      <input id={noInputId} type="checkbox" disabled={disabled} className="surveyResponseInput" checked={outcome.response === false} onChange={(event) => handleResponse(participantOutcomeIndex, question.name, false, event)} />
                    </div>
                  </div>
                );
              })}
            </Card>
          );
        })}
      </div>
    );
  };

  return (
    <UserCan perform={[[Permissions.GroupIndexWrite, Permissions.ManageGroupsWrite]]} showFail={true}>
      <div className="outcomesSurveyContainer hasLoader">
        <button id={promptCloseButtonId.current} data-close-on-click={true} className="hide" />
        {loading.isLoading ?
          <Loading loading={loading.isLoading} message={loading.message} />
          :
          <>
            <div className="surveyControls">
              {props?.concludingGroup ?
                <>
                  <button onClick={saveResponses}><FormattedMessage id="save_conclude" defaultMessage="Save and Conclude Group" /></button>
                  <button className="primary hollow" onClick={cancel}><FormattedMessage id="cancel" defaultMessage="Cancel" /></button>
                </>
                :
                <>
                  <button onClick={saveResponses}><FormattedMessage id="save" defaultMessage="Save" /></button>
                  <button className="primary hollow" onClick={cancel}><FormattedMessage id="cancel" defaultMessage="Cancel" /></button>
                </>
              }
            </div>
            {group?.enrollmentCount && group.enrollmentCount > 0 ?
              <>
                {(layout == Layouts.Desktop) ? buildTableLayout() : buildCardLayout()}
              </>
              :
              <p><FormattedMessage id="no_participants" defaultMessage="No participants in group" /></p>
            }
            <div className="surveyControls">
              {props?.concludingGroup ?
                <>
                  <button onClick={saveResponses}><FormattedMessage id="save_conclude" defaultMessage="Save and Conclude Group" /></button>
                  <button className="primary hollow" onClick={cancel}><FormattedMessage id="cancel" defaultMessage="Cancel" /></button>
                </>
                :
                <>
                  <button onClick={saveResponses}><FormattedMessage id="save" defaultMessage="Save" /></button>
                  <button className="primary hollow" onClick={cancel}><FormattedMessage id="cancel" defaultMessage="Cancel" /></button>
                </>
              }
            </div>
          </>
        }
      </div >
    </UserCan>
  );
};

export default OutcomesSurvey;