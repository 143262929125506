import "./capacity-selector.scss";

import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import useClassName from "utilities/useClassName";
import { useFormStatus } from "lib/form/contexts";
import { FormStatus, SaveState } from "lib/form/model";
import { Group } from "lib/data/model";
import { useGroup } from "lib/data/contexts";

const MIN_CAPACITY = 1;
const MAX_CAPACITY = 100000;

interface ICapacitySelectorProps {
  className?: string;
  capacity?: number;
}

const CapacitySelector: React.VFC<ICapacitySelectorProps> = (props) => {
  const [className, setPropsClassNames] = useClassName("capacitySelectorContainer");
  const [formStatus, setFormStatus] = useFormStatus();
  const [group, setGroup] = useGroup();

  const [capacity, setCapacity] = useState<number>();

  const intl = useIntl();

  useEffect(
    () => {
      if (props.className) {
        setPropsClassNames(props.className);
      }
      else {
        setPropsClassNames();
      }
    },
    [props.className]
  );

  useEffect(
    () => {
      isCapacityValid(props.capacity);
      setCapacity(props.capacity);
    },
    [props.capacity]
  );

  const handleCapacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseInt(event.target.value);
    const newCapacity = Number.isNaN(value) ? undefined : Math.trunc(value);

    setCapacity(newCapacity);

    formStatus.dirtiness.setDirty(true, "capacity");
    setFormStatus(
      new FormStatus(formStatus)
    );

    if (isCapacityValid(newCapacity)) {
      setGroup(
        new Group({
          ...group!,
          maxEnrollment: newCapacity
        })
      );
    }
  };

  const isCapacityValid = (capacity?: number) => {
    let isValid = true;

    const capacityTooLowErrorMessage = intl.formatMessage({ id: "capacity_too_low", defaultMessage: "Negative values and zero are not allowed. Please enter a higher value." });
    const capacityTooHighErrorMessage = intl.formatMessage({ id: "capacity_too_high", defaultMessage: "The maximum supported capacity is 2,147,483,647. Please enter a lower value." });

    if (capacity && (capacity < MIN_CAPACITY)) {
      isValid = false;

      formStatus.errors.addMessage(capacityTooLowErrorMessage, "capacity");
    }
    else {
      formStatus.errors.removeMessage(capacityTooLowErrorMessage, "capacity");
    }

    if (capacity && (capacity > MAX_CAPACITY)) {
      isValid = false;

      formStatus.errors.addMessage(capacityTooHighErrorMessage, "capacity");
    }
    else {
      formStatus.errors.removeMessage(capacityTooHighErrorMessage, "capacity");
    }

    formStatus.validity.setValidity(isValid, "capacity");
    setFormStatus(
      new FormStatus(formStatus)
    );

    return isValid;
  };

  ////////////////////
  // Render Helpers //
  ////////////////////

  const capacityHasErrors = (
    !formStatus.validity.isValid("capacity")
    && (
      formStatus.saveState === SaveState.ValidationFailed
      || formStatus.dirtiness.isDirty("capacity")
    )
  );

  ////////////
  // Render //
  ////////////

  return (
    <div className={className}>
      <TextField
        label={<FormattedMessage id="max_capacity" defaultMessage="Max capacity" />}
        value={capacity ?? ""}
        onChange={handleCapacityChange}
        type="text"
        inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        error={capacityHasErrors}
        helperText={
          capacityHasErrors
            ? formStatus.errors.getMessages("capacity").map(
              (message, index) => (
                <span key={`capacity-input-message-${index}`}>
                  {message}
                </span>
              ))
            : formStatus.messages.getMessages("capacity").map(
              (message, index) => (
                <span key={`capacity-input-message-${index}`}>
                  {message}
                </span>
              ))
        }
      />
    </div>
  );
};

export default CapacitySelector;