import React, { ReactNode, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Loading from "components/loading/loading";
import GroupStatuses from "constants/group-constants";
import { useAppDispatch } from "datastore/store";
import { isGroupPastEndDate } from "lib/api/backend/model";
import dispatchers from "datastore/dispatcher";
import { FullGroup, GroupDetails } from "lib/models-v2";
import { getGroupDetails } from "lib/api/backend/requests/groups";
import { ServerResponseType } from "lib/api/backend/request-utils";

interface IGroupHasRoomProps {
  group?: GroupDetails;
  groupId?: number;
  children: ReactNode;
  onGroupLoaded?: (group: GroupDetails) => void;
}

const GroupHasRoomV2: React.FC<IGroupHasRoomProps> = (props) => {
  const dispatch = useAppDispatch();
  const notify = dispatchers(dispatch).notify;

  const [group, setGroup] = useState<FullGroup | GroupDetails | null>(props.group || null);
  const [loading, setLoading] = useState<boolean>(false);
  const unallowedStatuses = [GroupStatuses.Completed, GroupStatuses.Concluded, GroupStatuses.Cancelled];
  const groupHasRoom = doesGroupHaveRoom(group);

  useEffect(() => {
    if (group == null) {
      getGroup();
    }
  }, []);

  const getGroup = async () => {
    
    setLoading(true);

    if (props.groupId) {
      const groupDetailsResponse = await getGroupDetails(props.groupId!);

      if(groupDetailsResponse?.responseType !== ServerResponseType.Success) {
        notify({ status: "error", message: <FormattedMessage id="get_group_error" defaultMessage="Could not get group." /> });
        setGroup(null);
      }
      else {
        const groupDetails = groupDetailsResponse?.data as GroupDetails;

        setGroup(groupDetails); 
        props.onGroupLoaded && props.onGroupLoaded(groupDetails);
      }
    }

    setLoading(false);
  };

  const hasGroupEnded = () => {
    if (!group)
      return false;

    return isGroupPastEndDate(group);
  };

  const shouldDisplayChildren = () => {
    return groupHasRoom && !unallowedStatuses.includes(group!.status as GroupStatuses) && !hasGroupEnded();
  };

  return (
    <div className={!props?.group ? "hasLoader" : ""}>
      <Loading loading={loading} />
      {!loading && group &&
        <>
          {shouldDisplayChildren() &&
            <>{props.children}</>
          }
          {!groupHasRoom &&
            <p className="danger"><FormattedMessage id="max_enrollment_error" defaultMessage="This group has already accepted the maximum amount of participants and is no longer accepting enrollments." /></p>
          }
          {unallowedStatuses.includes(group.status as GroupStatuses) &&
            <p className="danger"><FormattedMessage id="group_unavailable" defaultMessage="This group is not available for enrollment." /></p>
          }
          {hasGroupEnded() &&
            <p className="danger"><FormattedMessage id="group_past_end_date" defaultMessage="This group is not accepting new enrollments now." /></p>
          }
        </>
      }
    </div>
  );
};

export default GroupHasRoomV2;

export function doesGroupHaveRoom(group: FullGroup | GroupDetails | null): boolean {

  if (group) {
    return (group.maxEnrollment == null || group.maxEnrollment == 0 || group.enrollmentCount < group.maxEnrollment);
  }

  return false;
}