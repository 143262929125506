import React, { useState, useEffect, ReactElement } from "react";
import "./status-box.scss";
import WarningIcon from "assets/images/icons/warning.svg";
import ErrorIcon from "assets/images/icons/error.svg";
import SuccessIcon from "assets/images/icons/success.svg";
import InfoIcon from "assets/images/icons/info.svg";


export enum StatusBoxStatus {
  error = "error",
  success = "success",
  warning = "warning",
  info = "info",
  none = "none"
}

interface IStatusBoxProps {
  head?: ReactElement;
  status?: "error" | "success" | "warning" | "info" | "none" | StatusBoxStatus;
  children?: ReactElement;
  className?: string;
  onClick?: () => void;
  disableOverflowScroll?: boolean;
}

export const StatusBox = (props: IStatusBoxProps): ReactElement => {

  const [height, setHeight] = useState<string>("0px");
  const container = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (container.current) {
      setHeight(container.current.scrollHeight + "px");
    }
  }, []);

  let icon;

  switch (props.status) {
    case StatusBoxStatus.error:
      icon = ErrorIcon;
      break;
    case StatusBoxStatus.success:
      icon = SuccessIcon;
      break;
    case StatusBoxStatus.warning:
      icon = WarningIcon;
      break;
    default:
      icon = InfoIcon;
  }

  return (
    <div style={{ height: height }} className={`statusBoxContainer ${(props.status) ? props.status : "info"} ${props.className} ${props.disableOverflowScroll ? "no-scroll" : ""}`} onClick={props.onClick ? () => props.onClick : undefined} ref={container}>
      <div className="statusBoxHead">
        {props.status !== StatusBoxStatus.none &&
          <img src={icon} className={`statuBoxIcon ${!props?.head ? "noHead" : ""}`} />
        }
        {props?.head &&
          <span className="headText">{props.head}</span>
        }
      </div>
      <div className="message">
        {props.children}
      </div>
    </div>
  );
};

export default StatusBox;