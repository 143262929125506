import "./row.scss";
import { useEffect, useState } from "react";
import { Checkbox, TableCell } from "@mui/material";
import useClassName from "utilities/useClassName";

const Row = (props) => {

  const [className, setPropClassName] = useClassName("cell", "body-cell");

  useEffect(
    () => {
      setPropClassName(props.className ?? "");
    },
    [props.className]
  );

  const [isChecked, setIsChecked] = useState(false);

  useEffect(
    () => {
      const shouldBeChecked = typeof props.checked == "boolean" ? props.checked : props.checked(props.record);
      if (shouldBeChecked !== isChecked) {
        setIsChecked(shouldBeChecked);
      }
    },
    [props.checked]
  );

  const handleCheckboxChange = (event, checked) => {
    if (checked !== isChecked) {
      setIsChecked(checked);

      if (props.onCheck) {
        props.onCheck(checked, props.record, props.record[props.accessor]);
      }
    }
  };

  return (
    <TableCell
      className={className}
      align={props.align ?? "left"}
    >
      <Checkbox
        checked={isChecked}
        onChange={handleCheckboxChange}
      />

      {
        props.render
          ? props.accessor
            ? props.render(props.record[props.accessor], props.record)
            : props.render(props.record)
          : null
      }
    </TableCell>
  );
};

export default Row;