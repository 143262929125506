export interface ICmisPhoneDto {
  internationalFormat: boolean;
  number: string;
}

export default class CmisPhoneDto implements ICmisPhoneDto {
  public internationalFormat: boolean;
  public number: string;

  constructor(creationValues: ICmisPhoneDto | CmisPhoneDto) {
    this.internationalFormat = creationValues.internationalFormat;
    this.number = creationValues.number;
  }

  public export(): ICmisPhoneDto {
    return {
      internationalFormat: this.internationalFormat,
      number: this.number
    };
  }

  public clone(): CmisPhoneDto {
    return new CmisPhoneDto(this.export());
  }
}