import { useRef, useState, useEffect, ReactElement } from "react";
import FilterPanel from "./filter-panel";
import { ControlInput, FilterConfig } from "./models";
import { InputPanel } from "./input-panel";
import { ListControlPanelStore, createListControlPanelStore, ListControlPanelProvider } from "./list-control-panel-store";
import "./list-control-panel.scss";
import { useStore } from "zustand";

interface IListControlPanel {
  dataset: any[];
  filterConfig: FilterConfig;
  onFilter: (filterResults: any[]) => void;
  isLoading: boolean,
  showSearch?: boolean,
  customSearch?: ReactElement,
  inputs: ControlInput[]
  showMobileMapView?: boolean;
  searchFields?: string[] | ((dataset: any[]) => any[]);
}

export const ListControlPanel = (props: IListControlPanel): ReactElement => {

  const { onFilter, isLoading, customSearch, inputs, searchFields } = props;
  const showSearch = props.showSearch != undefined ? props.showSearch : true;
  const showMobileMapView = props.showMobileMapView != undefined ? props.showMobileMapView : false;
  const [dataset, setDataset] = useState(props.dataset);
  const filterStore = useRef<ListControlPanelStore>(createListControlPanelStore());
  const { filterConfig, setFilterConfig, setIsLoading } = useStore(filterStore.current!, s => s);

  useEffect(() => {
    setDataset(props.dataset);
  }, [props.dataset]);

  useEffect(() => {
    setFilterConfig(props.filterConfig);
  }, [props.filterConfig]);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  return (
    <div className="listControlPanelContainer">
      <ListControlPanelProvider value={filterStore.current}>
        {filterConfig != undefined &&
          <>
            <FilterPanel
              dataset={dataset}
              onFilter={onFilter}
              customSearch={customSearch}
              showSearch={showSearch}
              showMobileMapView={showMobileMapView}
              inputs={inputs}
              searchFields={searchFields}
            />
            {!showMobileMapView &&
              <InputPanel
                isLoading={isLoading}
                inputs={inputs}
              />
            }
          </>
        }
      </ListControlPanelProvider>
    </div>
  );
};

export default ListControlPanel;