import React, { useEffect, useState } from "react";
import { Group } from "lib/data/model";
import { GroupContext } from "./GroupContext";

interface ProviderProps<T> {
  value?: T;
  onChange?: (newValue: T) => void;
}

export const GroupProvider: React.FC<ProviderProps<Group>> = (props) => {
  const [group, setGroup] = useState(props.value);

  useEffect(
    () => {
      if (props.value) {
        handleGroup(props.value);
      }
      else {
        const resetGroup = () => {
          setGroup(undefined);
        };

        return resetGroup();
      }
    },
    [props.value]
  );

  const handleGroup = (newValue: Group) => {
    setGroup(newValue);

    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  return (
    <GroupContext.Provider
      value={{
        group: group,
        setGroup: handleGroup
      }}
    >
      {props.children}
    </GroupContext.Provider>
  );
};