import { ReactElement, useState, useEffect } from "react";
import useFeatureFlagStore from "./feature-flag-store";
import { FeatureFlagDto } from "lib/api/backend/model";

interface IFeatureFlagEnabledProps {
  featureFlagName: string;
  children?: ReactElement;
  else?: ReactElement;
}

export const FeatureFlagEnabled = (props: IFeatureFlagEnabledProps): ReactElement => {

  const featureFlagStore = useFeatureFlagStore();
  const [featureFlag, setFeatureFlag] = useState<FeatureFlagDto | null>(null);

  useEffect(() => {
    getFeatureFlag();
  }, [featureFlagStore.featureFlags]);

  const getFeatureFlag = async () => {
    const newFlag = await featureFlagStore.getFeatureFlag(props.featureFlagName);
    setFeatureFlag(newFlag || null);
  };

  return (
    <>
      {featureFlag === null &&
        <></>
      }
      {featureFlag?.enabled === true &&
        <>{props.children}</>
      }
      {featureFlag?.enabled === false && props.else &&
        <>{props.else}</>
      }
    </>
  );
};

export default FeatureFlagEnabled;