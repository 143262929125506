import React, { useState, useEffect, useRef } from "react";
import {getGrowAnimationDuration} from "utilities/styles";
import UpCaret from "assets/images/icons/up-caret.svg";
import nextId from "react-id-generator";
import "./drawer.scss";

const Drawer = (props) => {
  
  const [open, setOpen] = useState(props.open || false);
  const [height, setHeight] = useState(open ? "auto" : "0px");
  const [fullHeight, setFullHeight] = useState("0px");
  const [transitioning, setTransitioning] = useState(false);
  const animationDuration = getGrowAnimationDuration();
  const animationTimeout = useRef(null);
  const componentId = useRef(nextId());
  const contentId = `${componentId.current}-content`;
  const observer = useRef();
  const openCloseClassName =  open ? "open" : "closed";
  const transitioningClassName = transitioning ? "transitioning" : "";
  
  useEffect(()=>{

    //Setup a mutation observer to recalculate height when the content changes
    const observerConfig = {childList: true, subtree: true };
    const contentNode = document.getElementById(contentId);
    observer.current = new MutationObserver(handleMutation);
    observer.current.observe(contentNode, observerConfig);

    const newFullheight = computeFullHeight();
    setFullHeight(newFullheight);
    if(open){
      setHeight(newFullheight);
    }

    return () => {
      observer.current.disconnect();
    };

  },[]); 

  const handleMutation = (mutationsList) => {
    const domTreeChanged = mutationsList.some(mutation => mutation.type === "childList");
    let elem = document.getElementById(componentId.current); //scope is lost here, can't accurately rely on open and transition state being accurate, fallback to just reading the dom
    if(domTreeChanged && (elem.classList.contains("open") || elem.classList.contains("transitioning")) ){
      const newFullheight = computeFullHeight();
      setFullHeight(newFullheight);
      setHeight(newFullheight);
    }
  };

  useEffect(()=>{
    clearTimeout(animationTimeout.current);
    const newFullheight = computeFullHeight();
    setFullHeight(newFullheight);
    if(!props.open){
      setTransitioning(false);
      setHeight("0px");
      setOpen(false);
    } else {
      setHeight(newFullheight);
      setTransitioning(true);
      animationTimeout.current = setTimeout(()=>{
        setTransitioning(false);
        setOpen(true);
      }, animationDuration);
    }
  },[props]);

  const computeFullHeight = () => {
    const contentElem = document.getElementById(contentId);
    const clone = contentElem.cloneNode(true);
    clone.style.height = "auto";
    clone.style.visibility = "hidden";
    contentElem.insertAdjacentElement("beforebegin", clone);
    const newFullheight = clone.offsetHeight + "px";
    contentElem.parentElement.removeChild(clone);
    return newFullheight;
  };

  const toggle = () => {
    clearTimeout(animationTimeout.current);
    if(open){
      setTransitioning(false);
      setOpen(false);
      setHeight("0px");
      fireOnToggle(false);
    } else {
      setHeight(fullHeight);
      setTransitioning(true);
      fireOnToggle(true);
      animationTimeout.current = setTimeout(()=>{
        setTransitioning(false);
        setOpen(true);
      }, animationDuration);
    }
  };

  const fireOnToggle = (openStatus) => {
    if(props.onToggle){
      props.onToggle(openStatus);
    }
  };


  
  return(
    <div id={componentId.current} className={`drawerContainer ${openCloseClassName} ${transitioningClassName}`}>
      <div className={`summaryContainer ${openCloseClassName} ${(props?.allowUserToggle == false) ? null : "toggle"} ${transitioningClassName}`} onClick={(props?.allowUserToggle == false) ? null : toggle}>
        <img src={UpCaret} className={`drawerIcon ${openCloseClassName} ${props.iconRight?"right":""} ${transitioningClassName}`} />
        <span className={`summaryText ${openCloseClassName} ${transitioningClassName}`}>{props.summary}</span>
      </div>
      <div id={contentId} style={{height}} className={`detailContent ${openCloseClassName} ${transitioningClassName}`}>
        {props.children}
      </div>
    </div>
  );

};

export default Drawer;