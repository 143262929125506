import AuthorizeService from "auth/authorize-service";
export const USER_FOUND = "USER_FOUND";
export const LOADING_USER = "LOADING_USER";
export const LOAD_USER_ERROR = "LOAD_USER_ERROR";
export const LOGIN_REQUIRED = "LOGIN_REQUIRED";
export const LOGIN_COMPLETE = "LOGIN_COMPLETE";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_COMPLETE = "LOGOUT_COMPLETE";
export const AUTHENTICATION_CHANGED = "AUTHENTICATION_CHANGED";

export const UserActionTypes = {
  USER_FOUND: USER_FOUND,
  LOADING_USER: LOADING_USER,
  LOAD_USER_ERROR: LOAD_USER_ERROR,
  LOGIN_REQUIRED: LOGIN_REQUIRED,
  LOGIN_COMPLETE: LOGIN_COMPLETE,
  LOGIN_ERROR: LOGIN_ERROR,
  LOGOUT_COMPLETE: LOGOUT_COMPLETE,
  AUTHENTICATION_CHANGED: AUTHENTICATION_CHANGED
};

export function userFound(user) {
  return {
    type: USER_FOUND,
    payload: user
  };
}

export function loadingUser() {
  return {
    type: LOADING_USER
  };
}

export function loadUserError(message) {
  return {
    type: LOAD_USER_ERROR,
    payload: message
  };
}

export function loginRequired() {
  return {
    type: LOGIN_REQUIRED
  };
}

export function loginComplete() {
  return {
    type: LOGIN_COMPLETE
  };
}

export function loginError(message) {
  return {
    type: LOGIN_COMPLETE,
    payload: message
  };
}

export function logoutComplete() {
  return {
    type: LOGOUT_COMPLETE
  };
}

export function authenticationChanged(isAuthenticated) {
  return {
    type: AUTHENTICATION_CHANGED,
    payload: isAuthenticated
  };
}

export const initialState = {
  isAuthenticated: false,
  loginRequired: false,
  isLoading: true,
  user: null,
  message: ""
};

function userReducer(state = initialState, action) {
  switch(action.type) {
    case USER_FOUND:
      if (action.payload) {
        AuthorizeService.setUser(action.payload);
        return { ...initialState, isLoading: false, user: action.payload, isAuthenticated: true, loginRequired: false };
      } else {
        return { ...initialState, isLoading: false, user: null, isAuthenticated: false, loginRequired: true };
      }

    case LOADING_USER:
      return { ...initialState, loginRequired: false };

    case LOAD_USER_ERROR:
      return { ...initialState, isLoading: false };

    case LOGIN_REQUIRED:
      return { ...state, isAuthenticated: false, loginRequired: true };

    case LOGIN_COMPLETE:
      return { ...state, isAuthenticated: true, loginRequired: false };

    case LOGIN_ERROR:
      return { ...state, message: action.payload, isAuthenticated: true, loginRequired: false };

    case LOGOUT_COMPLETE:
      return { ...initialState, user: null, isLoading: false, isAuthenticated: false, loginRequired: true };

    case AUTHENTICATION_CHANGED: {
      let loading = {};
      if(!action.payload){
        loading = {isLoading: false};
      }
      return { ...state, isAuthenticated: action.payload.authenticated, loginRequired: !action.payload, ...loading};
    }

    default:
      return state;
  }
}

export default userReducer;