import { useState, useEffect, ReactElement } from "react";
import AuthorizeService from "auth/authorize-service";
import AvatarIcon from "assets/images/icons/avatar.svg";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import "./user-menu.scss";

const UserMenu = (): ReactElement => {

  const user = useSelector((state: any) => state.userReducer.user);
  const [showUserMenu, setShowUserMenu] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const toggleUserMenu = (event: React.MouseEvent<HTMLImageElement>) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setShowUserMenu(!showUserMenu);
  };

  const handleInsideUserMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setShowUserMenu(false);
  };

  const handleOutsideClick = () => {
    setShowUserMenu(false);
  };

  const signOut = (e) => {
    e.preventDefault();
    AuthorizeService.signOut();
  };

  return (
    <div className="userMenuContainer">
      {user?.displayName &&
        <>
          <span className="personName">{user?.displayName}</span>
          <button className="icon avatarIcon">
            <img src={AvatarIcon} alt="Avatar Symbol" onClick={toggleUserMenu} />
          </button>
          {showUserMenu &&
            <div className="userMenu" onClick={handleInsideUserMenuClick}>
              <div className="menuPersonName">{user?.displayName}</div>
              <a onClick={signOut}><FormattedMessage id="sign_out" defaultMessage="Sign Out" /></a>
            </div>
          }
        </>
      }
    </div>
  );
};

export default UserMenu;