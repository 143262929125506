import ParticipantStatus from "components/participant-status";
import Table from "components/table/table";
import Column from "components/table/column/column";
import CheckboxColumn from "components/table/checkbox-column/checkbox-column";
import SortableColumn from "components/table/sortable-column/sortable-column";
import { LoadingStatus } from "lib/data/model";
import { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import TruncatedParticipantName from "components/truncated-participant-name";
import { ParticipantGroupDto } from "lib/api/backend/model/participant/ParticipantGroupDto";
import ParticipantListActionMenu from "../participant-list-action-menu/participant-list-action-menu";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import "./participant-list-table.scss";

export interface IParticipantListTableProps {
  participants: ParticipantGroupDto[];
  selectedParticipants: ParticipantGroupDto[];
  canPrintCompletionCerts: boolean;
  canManageStudents: boolean;
  onSelectChange: (checked: boolean, enrollment: ParticipantGroupDto) => void;
  onSelectAllChange: (checked: boolean) => void;
  isSelected: (enrollment: ParticipantGroupDto) => boolean
  onStudentLoadingChange: (loading: LoadingStatus) => void;
  updateConsents: () => void;
  removeStudents: () => void;
  printParticipationCertificates: () => void;
  printCompletionCertificates: () => void;
}

export const ParticipantListTable = (props: IParticipantListTableProps): ReactElement => {

  const intl = useIntl();
  const tableActions = (
    <>
      <button disabled={!props.canManageStudents || !props.selectedParticipants || !props.selectedParticipants.length} onClick={props.updateConsents}><FormattedMessage id="consent_received" defaultMessage="Consent Form Received" /></button>
      <button disabled={!props.canManageStudents || !props.selectedParticipants || !props.selectedParticipants.length} onClick={props.removeStudents}><FormattedMessage id="remove_participants" defaultMessage="Remove Participants" /></button>
      <span className="menuGroupHead"><FormattedMessage id="print" defaultMessage="Print" /></span>
      <button disabled={!props.canManageStudents || !props.selectedParticipants || !props.selectedParticipants.length} onClick={props.printParticipationCertificates}><FormattedMessage id="participation_certificates" defaultMessage="Participation Certificate(s)" /></button>
      <button disabled={!props.canManageStudents || !props.selectedParticipants || !props.selectedParticipants.length || !props.canPrintCompletionCerts} onClick={props.printCompletionCertificates}><FormattedMessage id="completion_certificates" defaultMessage="Completion Certificate(s)" /></button>
    </>
  );

  return (
    <>
      {props.participants?.length &&
        <div className="studentListTableContainer">
          <Table rows={props.participants} className="studentListTable" id="studentListTable">
            <CheckboxColumn>
              <CheckboxColumn.Header onCheck={props.onSelectAllChange} />
              <CheckboxColumn.Row onCheck={props.onSelectChange} isSelected={props.isSelected} />
            </CheckboxColumn>
            <SortableColumn>
              <SortableColumn.Header name={intl.formatMessage({ id: "name", defaultMessage: "name" })} />
              <SortableColumn.Row
                render={(participant: ParticipantGroupDto) => (
                  <Link to={`/participant/${participant.id}/group/${participant.groupId}`}>
                    <TruncatedParticipantName participant={participant} />
                  </Link>
                )}
                sort={(a: ParticipantGroupDto, b: ParticipantGroupDto) => `${a.surname}, ${a.givenName}` > `${b.surname}, ${b.givenName}` ? 1 : -1}
              />
            </SortableColumn>
            <SortableColumn>
              <SortableColumn.Header
                name={intl.formatMessage({
                  id: "church_account",
                  defaultMessage: "Church Account",
                })}
              />
              <SortableColumn.Row
                render={(student: ParticipantGroupDto) => {
                  return student.cmisId ? <CheckIcon /> : <CloseIcon />;
                }}
                sort={(a: ParticipantGroupDto, b: ParticipantGroupDto) =>
                  a.cmisId && !b.cmisId ? 1 : -1
                }
              ></SortableColumn.Row>
            </SortableColumn>
            <SortableColumn>
              <SortableColumn.Header name={intl.formatMessage({ id: "group_type", defaultMessage: "Group Type" })} />
              <SortableColumn.Row
                render={(participant: ParticipantGroupDto) => (
                  <Link to={`/groups/${participant.groupId}`}>{participant.programName}</Link>
                )}
                sort={(a: ParticipantGroupDto, b: ParticipantGroupDto) => a.programName > b.programName ? 1 : -1}
              />
            </SortableColumn>
            <SortableColumn>
              <SortableColumn.Header name={intl.formatMessage({ id: "status", defaultMessage: "Status" })} />
              <SortableColumn.Row
                render={(participant: ParticipantGroupDto) => (
                  <ParticipantStatus status={participant.status} />
                )}
                sort={(a: ParticipantGroupDto, b: ParticipantGroupDto) => a.groupStatus > b.groupStatus ? 1 : -1}
              />
            </SortableColumn>
            <Column>
              <Column.Header actions={tableActions} />
              <Column.Row
                render={(participant: ParticipantGroupDto) => (
                  <ParticipantListActionMenu
                    forceRight={true}
                    participant={participant}
                    onLoadingChange={props.onStudentLoadingChange}
                  />
                )}
              />
            </Column>
          </Table>
        </div>
      }
    </>
  );
};

export default ParticipantListTable;