import { getLocaleDateString } from "utilities/time";

export default function searchDataSet(dataset, valueToFind, fieldsToSearch) {
  if (valueToFind !== "") {
    let dataToSearch = dataset;
    if (Array.isArray(fieldsToSearch)) {
      dataToSearch = dataset.map((originalRecord) => {
        const newRecord = {};
        fieldsToSearch.forEach(fieldName => {
          newRecord[fieldName] = originalRecord[fieldName];
        });
        return newRecord;
      });
    } else if (typeof fieldsToSearch === "function") {
      dataToSearch = fieldsToSearch(dataset);
    }
    valueToFind = valueToFind.toLowerCase();
    const searchResults = [];
    dataset.forEach((record, index) => {
      if (recursiveObjectSearch(valueToFind, dataToSearch[index])) {
        searchResults.push(record);
      }
    });
    return searchResults;
  }
  return [];
}

function recursiveObjectSearch(valueToFind, objectToSearch) {
  const keys = Object.keys(objectToSearch);
  for (let i = 0; i < keys.length; i++) {

    if (typeof objectToSearch[keys[i]] !== "undefined" && objectToSearch[keys[i]] !== null) {

      //If array search through the array
      if (Array.isArray(objectToSearch[keys[i]])) {
        if (recursiveArraySearch(valueToFind, objectToSearch[keys[i]])) {
          return true;
        }
      }

      //if generic object
      if (typeof objectToSearch[keys[i]] == "object" && Object.keys(objectToSearch[keys[i]]).length > 0) {
        if (recursiveObjectSearch(valueToFind, objectToSearch[keys[i]])) {
          return true;
        }
      }

      //if equal to the valueToFind return true
      if (objectToSearch == valueToFind) {
        return true;
      }

      //if a date object
      if (objectToSearch[keys[i]] instanceof Date) {
        let datestring = getLocaleDateString(objectToSearch[keys[i]]);
        if (datestring.toLowerCase().indexOf(valueToFind) !== -1) {
          return true;
        }
      }

      //If a string
      if (typeof objectToSearch[keys[i]] == "string") {
        const lowerCaseTestValue = objectToSearch[keys[i]].toLowerCase();
        if (lowerCaseTestValue.indexOf(valueToFind) !== -1) {
          return true;
        }
      }

      //If a number
      if (typeof objectToSearch[keys[i]] == "number") {
        if (objectToSearch[keys[i]].toString().indexOf(valueToFind) !== -1) {
          return true;
        }
      }
    }
  }
  return false;
}

function recursiveArraySearch(valueToFind, arrayToSearch) {
  if (arrayToSearch.length > 0) {
    for (let i = 0; i < arrayToSearch.length; i++) {
      if (Array.isArray(arrayToSearch[i])) {
        return recursiveArraySearch(valueToFind, arrayToSearch[i]);
      } else {
        return recursiveObjectSearch(valueToFind, arrayToSearch[i]);
      }
    }
  } else {
    return false;
  }
}
