import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import Help from "components/help/help";

interface IProps {
  canAccess: {
    courseCatalog: boolean;
    groupsPage: boolean;
    studentsPage: boolean;
  };
  isAuthenticated: boolean;
  mobileNavClick: () => void;
  hasAccess: boolean;
}

const SubHeaderOptions: React.VFC<IProps> = (props: IProps) => {
  const { isAuthenticated, canAccess, hasAccess, mobileNavClick } = props;

  const getPageClassName = (pageValue: boolean, isActive: boolean) => {
    return `${!pageValue ? "disabled" : ""} ${(isActive && pageValue) ? "active" : ""}`.trim();
  };

  if (isAuthenticated && hasAccess) {
    return (
      <>
        <NavLink className={({ isActive }) => getPageClassName(canAccess.groupsPage, isActive)} onClick={mobileNavClick} to={canAccess.groupsPage ? "/groups" : ""}>
          <FormattedMessage id="groups" defaultMessage="Groups" />
        </NavLink>
        <NavLink className={({ isActive }) => getPageClassName(canAccess.studentsPage, isActive)} onClick={mobileNavClick} to={canAccess.studentsPage ? "/participants" : ""}>
          <FormattedMessage id="participants" defaultMessage="Participants" />
        </NavLink>
        <NavLink className={({ isActive }) => getPageClassName(canAccess.courseCatalog, isActive)} onClick={mobileNavClick} to={canAccess.courseCatalog ? "/group-types" : ""}>
          <FormattedMessage id="general_catalog" defaultMessage="General Catalog" />
        </NavLink>
        <Help />
      </>
    );
  }

  return (
    <>
      <NavLink className={({ isActive }) => isActive ? "active" : ""} onClick={mobileNavClick} to="/join-now">
        <FormattedMessage id="groups" defaultMessage="Groups" />
      </NavLink>
    </>
  );
};

export default SubHeaderOptions;