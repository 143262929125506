import "./group-creation-manager.scss";

import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Loading from "components/loading/loading";
import GroupLanguageSelector from "./components/group-language-selector";
import LocationSelector from "./components/location-selector";
import GroupAgeTypeSelector from "./components/group-age-type-selector";
import StartEndDateSelector from "./components/start-end-date-selector";
import WeekdayHourSelector from "./components/weekday-hour-selector";
import CapacitySelector from "./components/capacity-selector";
import OptionsSelector from "./components/options-selector";
import FacilitatorSelector from "./components/facilitator-selector";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Permissions } from "constants/api-auth-constants";
import UserCan from "components/user-can/user-can";
import useClassName from "utilities/useClassName";
import { FormStatusProvider } from "lib/form/contexts";
import { FormStatus, RecordType, SaveState } from "lib/form/model";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import dispatchers from "datastore/dispatcher";
import useGroupCreationFormStore from "datastore/groupCreationForm";
import useGroupStore from "datastore/groups";
import { createGroup } from "lib/api/backend/requests/groups/groups-v2-requests";
import type { GroupCreationDto } from "lib/api/backend/model/group/GroupCreationDto";
import { AgeType } from "lib/data/model/enum";

enum SaveResults {
  Success,
  Error
}

interface GroupManagerProps {
  className?: string;
  copy?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
}

const GroupCreationManager: React.VFC<GroupManagerProps> = (props) => {
  const [className, addPropClassNames] = useClassName(
    "groupManagerContainer",
    "hasLoader"
  );
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const store = useGroupStore();
  const groupFormStore = useGroupCreationFormStore();
  const group = groupFormStore.group;

  const [formStatus, setFormStatus] = useState<FormStatus>(new FormStatus());

  const [isLoading, setLoading] = useState<boolean>(false);
  const [showYouthTermsDialog, setShowYouthTermsDialog] = useState(false);
  const [shouldSave, setShouldSave] = useState<boolean>(false);
  const notify = dispatchers(reduxDispatch).notify;

  const intl = useIntl();

  useEffect(() => {
    addPropClassNames(props.className ?? "");
  }, [props.className]);

  useEffect(() => {

    const handleGroup = async () => {
      setLoading(true);

      setFormStatus(
        new FormStatus({
          ...formStatus,
          recordType: RecordType.New,
        })
      );

      setLoading(false);
    };

    handleGroup();
  }, []);

  useEffect(() => {
    const trySaving = async () => {
      if (shouldSave) {
        await handleSave();
      }
    };

    trySaving();
  }, [shouldSave]);

  const attemptSave = async (group: GroupCreationDto) => {
    try {
      const createdGroup = await createGroup(group);

      store.addGroup(createdGroup);

      return SaveResults.Success;
    }
    catch (e) {
      console.error(e);
      notify({
        status: "error",
        message: intl.formatMessage({
          id: "group_save_error",
          defaultMessage:
            "An error occurred while attempting to save the group.",
        }),
      });
      setLoading(false);

      formStatus.errors.addMessage(
        intl.formatMessage({
          id: "group_save_error",
          defaultMessage:
            "An error occurred while attempting to save the group.",
        })
      );

      setFormStatus(
        new FormStatus({
          ...formStatus,
          saveState: SaveState.Error,
        })
      );

      return SaveResults.Error;
    }
  };

  const handleSave = async () => {

    if (!group) {
      setFormStatus(
        new FormStatus({
          ...formStatus,
          saveState: SaveState.Error,
        })
      );
      return;
    }

    if (!formStatus.validity.isValid()) {
      const invalids = formStatus.validity.getInvalid();
      //make sure youth terms isn't the only thing invalid
      if (!(invalids.size == 1 && invalids.has("youth.terms"))) {
        setFormStatus(
          new FormStatus({
            ...formStatus,
            saveState: SaveState.ValidationFailed,
          })
        );
        return;
      }
    }

    const youthTermsValid = isYouthTermsAcceptanceValid(group.hasAcceptedYouthTerms);

    if (!youthTermsValid) {
      setShowYouthTermsDialog(true);
      return;
    }

    //If we got here, the group will be saved.
    setFormStatus(
      new FormStatus({
        ...formStatus,
        saveState: SaveState.Saving,
      })
    );

    setLoading(true);

    const result = await attemptSave(group);

    if (result === SaveResults.Success) {
      setFormStatus(
        new FormStatus({
          ...formStatus,
          saveState: SaveState.Complete,
        })
      );

      if (props.onSave) {
        groupFormStore.dropGroup();
        setFormStatus(new FormStatus());
        props.onSave();
      }
      else {
        navigate("/groups");
      }

      setLoading(false);
      notify({
        status: "success",
        message: intl.formatMessage({
          id: "group_saved",
          defaultMessage: "Group Saved",
        }),
      });

      setShouldSave(false);
    }
  };

  const isYouthTermsAcceptanceValid = (areAccepted: boolean, overrideFormStatus?: FormStatus) => {
    let isValid = true;
    if (group?.groupAgeTypeId !== AgeType.Adult && !areAccepted) {
      isValid = false;
    }
    const myFormStatus = overrideFormStatus || formStatus;
    myFormStatus.validity.setValidity(isValid, "youth", "terms");
    setFormStatus(myFormStatus);
    return isValid;
  };

  const handleYouthTermsAcceptedChange = (areAccepted: boolean) => {
    setShowYouthTermsDialog(false);

    if (isYouthTermsAcceptanceValid(areAccepted)) {

      groupFormStore.setHasAcceptedYouthTerms(areAccepted);
      setShouldSave(true);
    }
  };

  const handleCancel = () => {
    if (props.onCancel) {
      groupFormStore.dropGroup();
      setFormStatus(new FormStatus());
      props.onCancel();
    } else {
      navigate("/groups");
    }
  };

  const defaultYouthTerms = "As a leader, I can allow youth to participate in groups with the following:\n\n1. there are two facilitators; and\n2. they have parental permission (this is already a requirement for anyone under 18).\n\nIn addition, no facilitator should text/email/contact a minor without another person on the chain.";

  ////////////////////
  // --- RENDER --- //
  ////////////////////

  return (
    <UserCan
      perform={
        formStatus.recordType === RecordType.Existing
          ? [Permissions.EditGroupWrite]
          : [Permissions.NewGroupWrite]
      }
      unitNumber={
        formStatus.recordType === RecordType.Existing
          ? group!.stakeUnitNumber
          : null
      }
      showFail={true}
    >
      <FormStatusProvider value={formStatus}>
        <div className={className}>
          <Loading loading={isLoading} centered={true} />
          <GroupLanguageSelector className="courseSelector" />
          <hr />
            
          {group?.programId && (
            <>
              <LocationSelector />

              <hr />
              {group.location && group.location.address1 && (
                <>
                  <GroupAgeTypeSelector />
                    
                  <StartEndDateSelector />
                    
                  <WeekdayHourSelector />
                    
                  <FacilitatorSelector />

                  <CapacitySelector />
                  <OptionsSelector />
                </>
              )}

            </>
          )}
            
          <div className="bottomRow">
            <div className="validationErrors">
              {!formStatus.validity.isValid() &&
                formStatus.errors.getMessages().length > 0 && // Unkeyed Messages Only
                Array.from(formStatus.errors.getMessages()).map(
                  (error, index) => (
                    <p className="error" key={index}>
                      {error}
                    </p>
                  )
                )}
            </div>

            <Stack spacing={2} direction="row">
              <Button variant="text" onClick={handleCancel}>
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button variant="contained" onClick={() => handleSave()}>
                <FormattedMessage id="save" defaultMessage="Save" />
              </Button>
            </Stack>
          </div>

          <button className="closePopUpButton"></button>
        </div>
        <Dialog className="terms-dialog" open={showYouthTermsDialog}>
          <DialogTitle>
            <div className="terms-dialog-title">
              <InfoIcon fontSize="medium" />
              <h4>
                <FormattedMessage
                  id="youth_group_rules"
                  defaultMessage="Youth Group Rules"
                />
              </h4>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="terms-dialog-content">
              <FormattedMessage
                id="youth_terms"
                defaultMessage={defaultYouthTerms}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => handleYouthTermsAcceptedChange(false)}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              variant="contained"
              onClick={() => handleYouthTermsAcceptedChange(true)}
            >
              <FormattedMessage id="agree" defaultMessage="Agree" />
            </Button>
          </DialogActions>
        </Dialog>
      </FormStatusProvider>
    </UserCan>
  );
};

export default GroupCreationManager;
