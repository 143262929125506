import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as GroupFilters from "utilities/filters/group-filters";
import { GroupAgeTypes, GroupMeetingTypes, GroupStatuses } from "constants/group-constants";
import { getLocaleDateString, getHHMM, getWeekdayName } from "utilities/time";
import ListControlPanel from "components/list-control-panel/list-control-panel";
import { FormattedMessage, useIntl } from "react-intl";
import { ViewNames } from "../group-list";
import Layouts from "constants/layout-constants";
import { CourseLanguageDto } from "lib/api/backend/model/course";
import { ControlInput, ControlInputType, FilterConfig, FilterInputType } from "components/list-control-panel";
import type { FullGroup } from "lib/models-v2/LeadersGroupResponse";
import { ProviderProgramLanguageDto } from "lib/api/backend/model/provider";

interface IGroupListControls {
  onFilter: (groups: FullGroup[]) => void;
  viewSetter: (view: ViewNames) => void;
  viewValue: ViewNames;
  groups: FullGroup[];
  programLanguages: ProviderProgramLanguageDto[];
  isLoading: boolean;
}

const GroupListControls = (props: IGroupListControls) => {

  const { groups, onFilter, viewSetter, viewValue, isLoading } = props;
  const layout = useSelector((state: any) => state.appReducer.layout);
  const language = useSelector((state: any) => state.appReducer.language);
  const intl = useIntl();

  const buildConfig = (): FilterConfig => {

    const groupAgeTypes = {
      youth: {
        displayText: intl.formatMessage({ id: "group_age_type_youth", defaultMessage: "Youth only" }),
        value: GroupAgeTypes.Youth
      },
      adult: {
        displayText: intl.formatMessage({ id: "group_age_type_adult", defaultMessage: "Adult only" }),
        value: GroupAgeTypes.Adult
      },
      combined: {
        displayText: intl.formatMessage({ id: "group_age_type_combined", defaultMessage: "All Ages" }),
        value: GroupAgeTypes.Combined
      }
    };

    const groupMeetingTypes = {
      inPerson: {
        displayText: intl.formatMessage({ id: "meeting_in_person", defaultMessage: "In Person" }),
        value: GroupMeetingTypes.InPerson
      },
      virtual: {
        displayText: intl.formatMessage({ id: "virtual", defaultMessage: "Virtual" }),
        value: GroupMeetingTypes.Virtual
      }
    };

    const groupStatuses = {
      pending: {
        displayText: intl.formatMessage({ id: "pending", defaultMessage: "Pending" }),
        value: GroupStatuses.Pending
      },
      inProgress: {
        displayText: intl.formatMessage({ id: "in_progress", defaultMessage: "In Progress" }),
        value: GroupStatuses.InProgress
      },
      upcoming: {
        displayText: intl.formatMessage({ id: "upcoming", defaultMessage: "Upcoming" }),
        value: GroupStatuses.Upcoming
      },
      cancelled: {
        displayText: intl.formatMessage({ id: "cancelled", defaultMessage: "Cancelled" }),
        value: GroupStatuses.Cancelled
      },
      completed: {
        displayText: intl.formatMessage({ id: "completed", defaultMessage: "Completed" }),
        value: GroupStatuses.Completed
      }
    };

    const availablePrograms: { id: number, name: string, providerId: number, langName: string }[] = [];
    const availableStartDates: { value: string, displayText: string }[] = [];
    const availableStartTimes: string[] = [];
    const availableLanguages: CourseLanguageDto[] = [];

    if (groups.length > 0) {
      groups.forEach(group => {
        const langExistsInArray = availableLanguages.some(x => x.id == group.language.id);

        if (!langExistsInArray) {
          availableLanguages.push(new CourseLanguageDto({
            id: group.language.id,
            description: group.language.description,
            name: group.language.name
          }));
        }

        if (!availablePrograms.some(availableProgram => availableProgram.id == group.programId)) {
          availablePrograms.push({
            id: group.programId,
            name: group.programName,
            providerId: group.providerId,
            langName: group!.language.name
          });
        }

        if (group.startDate && !availableStartDates.some(startDate => startDate.value == group.startDate)) {
          availableStartDates.push({
            value: group.startDate,
            displayText: getLocaleDateString(group.startDate)!
          });
        }

        if (group.meetingTimes) {
          group.meetingTimes.forEach(meetingTime => {
            const meetingStartString = getHHMM(meetingTime.startTime);
            if (!availableStartTimes.includes(meetingStartString)) {
              availableStartTimes.push(meetingStartString);
            }
          });
        }
      });

      availablePrograms.sort((a, b) => {
        if (a.providerId != b.providerId) {
          return a.providerId > b.providerId ? 1 : -1;
        }

        if (a.langName != b.langName) {
          return a.langName.localeCompare(b.langName);
        }

        return a.name.localeCompare(b.name);
      });

      availableStartDates.sort((a, b) => new Date(a.value).getTime() - new Date(b.value).getTime());
      availableStartTimes.sort();
    }

    const newFilterConfig: FilterConfig = [
      {
        filterGroupHeading: intl.formatMessage({ id: "group_type", defaultMessage: "Group Type" }),
        filters: [{
          name: intl.formatMessage({ id: "search_groups", defaultMessage: "Search Groups" }),
          allPossibleValues: availablePrograms,
          filterFunction: GroupFilters.removeGroupByCourse,
          displayField: "name",
          inputType: FilterInputType.TypeAhead,
          activeValues: [],
          badgeLabel: intl.formatMessage({ id: "group_type", defaultMessage: "Group" }),
          fullWidth: true
        }],
      },
      {
        filterGroupHeading: intl.formatMessage({ id: "status_and_types", defaultMessage: "Status and Types" }),
        filters: [
          {
            name: intl.formatMessage({ id: "status", defaultMessage: "Status" }),
            allPossibleValues: Object.keys(groupStatuses).map(key => groupStatuses[key]),
            displayField: "displayText",
            valueField: "value",
            filterFunction: GroupFilters.removeGroupByStatus,
            inputType: FilterInputType.Checkbox,
            activeValues: [groupStatuses.pending, groupStatuses.inProgress, groupStatuses.upcoming],
            badgeLabel: intl.formatMessage({ id: "status", defaultMessage: "Status" }),
          },
          {
            name: intl.formatMessage({ id: "age_range", defaultMessage: "Age Range" }),
            allPossibleValues: Object.keys(groupAgeTypes).map(key => groupAgeTypes[key]),
            displayField: "displayText",
            valueField: "value",
            filterFunction: GroupFilters.removeGroupByAgeType,
            inputType: FilterInputType.Checkbox,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "age_range", defaultMessage: "Age Range" }),
          },
          {
            name: intl.formatMessage({ id: "meeting_type", defaultMessage: "Meeting Type" }),
            allPossibleValues: Object.keys(groupMeetingTypes).map(key => groupMeetingTypes[key]),
            filterFunction: GroupFilters.removeGroupByMeetingType,
            displayField: "displayText",
            valueField: "value",
            inputType: FilterInputType.Radio,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "meeting_type", defaultMessage: "Meeting Type" })
          },
        ]
      },
      {
        filterGroupHeading: intl.formatMessage({ id: "day_and_time", defaultMessage: "Date and Time" }),
        filters: [
          {
            name: intl.formatMessage({ id: "start_date", defaultMessage: "Start date" }),
            allPossibleValues: availableStartDates,
            filterFunction: GroupFilters.removeGroupByStartDate,
            displayField: "displayText",
            valueField: "value",
            inputType: FilterInputType.Select,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "start_date", defaultMessage: "Start date" })
          },
          {
            name: intl.formatMessage({ id: "start_time", defaultMessage: "Start time" }),
            allPossibleValues: availableStartTimes,
            filterFunction: GroupFilters.removeGroupByStartTime,
            inputType: FilterInputType.Select,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "start_time", defaultMessage: "Start time" })
          }
        ]
      },
      {
        filterGroupHeading: intl.formatMessage({ id: "language", defaultMessage: "Language" }),
        filters: [
          {
            name: intl.formatMessage({ id: "language", defaultMessage: "Language" }),
            allPossibleValues: availableLanguages,
            displayField: "description",
            valueField: "id",
            filterFunction: GroupFilters.removeGroupByGroupLanguageIdKeepMultilingualV2,
            inputType: FilterInputType.TypeAhead,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "language", defaultMessage: "Language" })
          },
        ]
      }
    ];

    return newFilterConfig;
  };

  const [filterConfig, setFilterConfig] = useState(buildConfig());
  const inputs: ControlInput[] = [];
  const searchFields = function (groups: FullGroup[]) {
    return groups.map(group => {
      return {
        name: group.name,
        facilitators: group.facilitators?.map(facilitator => {
          return {
            firstName: facilitator.firstName,
            lastName: facilitator.lastName,
            name: facilitator.name
          };
        }),
        nonMemberFacilitators: group.nonMemberFacilitators?.map((nonMemberFacilitator) => {
          return {
            firstName: nonMemberFacilitator.firstName,
            lastName: nonMemberFacilitator.lastName
          };
        }),
        language: group.language,
        address1: group.address1,
        address2: group.address2,
        city: group.city,
        country: group.country,
        startDate: group.startDate,
        endDate: group.endDate,
        meetingTimes: group.meetingTimes.map((meetingTime) => {
          const weekday = getWeekdayName(meetingTime.dayOfWeek);
          return {
            dayOfWeek: intl.formatMessage({ id: weekday.toLowerCase(), defaultMessage: weekday }),
            startTime: meetingTime.startTime ? getHHMM(meetingTime.startTime) : null,
            endTime: meetingTime.endTime ? getHHMM(meetingTime.endTime) : null,
          };
        }),
        status: group.status,
        enrollmentCount: group.enrollmentCount,
        maxEnrollment: group.maxEnrollment,
      };
    });
  };

  useEffect(() => {
    const newConfig = buildConfig();
    setFilterConfig(newConfig);
  }, [groups, language]);

  if (layout == Layouts.Desktop) {
    inputs.push(
      {
        text: <FormattedMessage id="add_group" defaultMessage="View" />,
        action: viewSetter,
        type: ControlInputType.Select,
        options: [
          {
            value: ViewNames.Table,
            label: intl.formatMessage({ id: "list_view", defaultMessage: "List View" })
          },
          {
            value: ViewNames.Card,
            label: intl.formatMessage({ id: "card_view", defaultMessage: "Card View" })
          }
        ],
        value: viewValue
      }
    );
  }

  return (
    <ListControlPanel dataset={groups} isLoading={isLoading} filterConfig={filterConfig} onFilter={onFilter} inputs={inputs} searchFields={searchFields} />
  );
};

export default GroupListControls;