import CourseDto, { ICourseDto } from "./CourseDto";
import ProviderDto, { IProviderDto } from "../provider/ProviderDto";

export interface ICourseProviderGroupDto {
  provider: IProviderDto;
  courses: ICourseDto[];
}

export default class CourseProviderGroupDto implements ICourseProviderGroupDto {
  public readonly provider: ProviderDto;
  public readonly courses: CourseDto[];

  constructor(creationValues: ICourseProviderGroupDto | CourseProviderGroupDto) {
    this.provider = new ProviderDto(creationValues.provider);
    this.courses = creationValues.courses.map(c => new CourseDto(c));
  }

  public export(): ICourseProviderGroupDto {
    return {
      provider: this.provider.export(),
      courses: this.courses.map(c => c.export())
    };
  }

  public clone(): CourseProviderGroupDto {
    return new CourseProviderGroupDto(this.export());
  }

  public static mapToCourses(providerGroupList: ICourseProviderGroupDto[]): CourseDto[] {
    const courses = providerGroupList.map(cpg => new CourseProviderGroupDto(cpg))
      .map(p => p.courses)
      .flat();

    return courses;
  }
}