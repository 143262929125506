import appStore from "datastore/store";
import { AppActionTypes } from "datastore/app";
import Prompt from "lib/data/model/Prompt";

export function prompt(newPrompt: Prompt): void {
  const state = appStore.getState();
  const newPrompts = [...state.appReducer.prompts, newPrompt];
  appStore.dispatch({ type: AppActionTypes.UPDATE_PROMPTS, payload: newPrompts });
}

export default prompt;