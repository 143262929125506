import "./facilitator-selector.scss";

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import Loading from "components/loading/loading";
import InfoHoverBox, { InfoHoverStatus } from "components/info-hover-box/info-hover-box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { StakeMemberTypeAhead } from "./components";
import useClassName from "utilities/useClassName";
import * as StakeMembersApi from "lib/api/backend/requests/stake";
import StakeMemberDto from "lib/api/backend/model/StakeMemberDto";
import { useFormStatus } from "lib/form/contexts";
import { FormStatus, SaveState } from "lib/form/model";
import { ServerResponseType } from "lib/api/backend/request-utils";
import Requests from "api/requests";
import useGroupCreationFormStore from "datastore/groupCreationForm";
import { FacilitatorCreationDto } from "lib/api/backend/model/group/GroupCreationDto";

const FacilitatorSelectorErrorKey = "facilitators";

interface IFacilitatorSelectorProps {
  className?: string;
}

const FacilitatorSelector: React.VFC<IFacilitatorSelectorProps> = (props) => {
  const [className, setPropsClassNames, addClassNames, removeClassNames] = useClassName("facilitatorListContainer");

  const groupFormStore = useGroupCreationFormStore();
  const group = groupFormStore.group!; //When this component is displayed, groups will never be null.
  const stakeUnitNumber = group.stakeUnitNumber;
  const facilitators = group.facilitators;

  const language = useSelector((state: any) => state.appReducer.language);
  const [formStatus, setFormStatus] = useFormStatus();

  const [isRetryVisible, setIsRetryVisible] = useState<boolean>(false);
  const [potentialFacilitators, setPotentialFacilitators] = useState<StakeMemberDto[]>([]);
  const [availablePotentialFacilitators, setAvailablePotentialFacilitators] = useState<StakeMemberDto[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [checkingChurchAccount, setCheckingChurchAccount] = useState<boolean>(false);
  const intl = useIntl();

  useEffect(() => {
    if (facilitators.length <= 0) {
      addClassNames("right");
    } else {
      removeClassNames("right");
    }
    areFacilitatorsValid(facilitators);
  }, [facilitators]);

  useEffect(() => {
    groupFormStore.dropFacilitatorList();
    getMembersRequest();
  }, [stakeUnitNumber]);

  useEffect(() => {
    if (props.className) {
      setPropsClassNames(props.className);
    } else {
      setPropsClassNames();
    }
  }, [props.className]);

  useEffect(() => {
    areFacilitatorsValid(facilitators);
  }, [language]);

  const isGuestFacilitatorValid = (recordKey: number, facilitator: FacilitatorCreationDto) => {

    let isValid = true;

    const invalidNameErrorMessage = intl.formatMessage({
      id: "facilitator_has_invalid_name",
      defaultMessage: "Facilitator must have a name.",
    });

    const duplicateFacilitatorErrorMessage = intl.formatMessage({
      id: "facilitator_is_duplicate",
      defaultMessage: "This facilitator is a duplicate.",
    });

    formStatus.errors.removeMessage(
      invalidNameErrorMessage,
      FacilitatorSelectorErrorKey,
      `${recordKey}`
    );

    formStatus.errors.removeMessage(
      duplicateFacilitatorErrorMessage,
      FacilitatorSelectorErrorKey,
      `${recordKey}`
    );

    const facilitatorName = facilitator.name!.trim();

    if (!facilitatorName) {
      isValid = false;

      formStatus.errors.addMessage(
        invalidNameErrorMessage,
        FacilitatorSelectorErrorKey,
        `${recordKey}`
      );
    }

    const hasDuplicates = ((): boolean => {
      const otherFacilitators = [
        ...facilitators.slice(0, recordKey),
        ...facilitators.slice(recordKey + 1),
      ];

      const duplicateFacilitator = otherFacilitators.find((otherFacilitator) => {
        const otherFacilitatorName = otherFacilitator.name!.toLowerCase();

        return otherFacilitatorName.trim().toLowerCase() === facilitatorName.toLowerCase();
      });

      return duplicateFacilitator !== undefined;
    })();


    if (isValid && hasDuplicates) {
      isValid = false;
      formStatus.errors.addMessage(
        duplicateFacilitatorErrorMessage,
        FacilitatorSelectorErrorKey,
        `${recordKey}`
      );
    }

    formStatus.validity.setValidity(isValid, FacilitatorSelectorErrorKey, `${recordKey}`);

    return isValid;
  };

  const isMemberFacilitatorValid = (
    recordKey: number,
    facilitator: FacilitatorCreationDto
  ) => {
    let isValid = true;

    const duplicateFacilitatorErrorMessage = intl.formatMessage({
      id: "facilitator_is_duplicate",
      defaultMessage: "This facilitator is a duplicate.",
    });

    formStatus.errors.removeMessage(
      duplicateFacilitatorErrorMessage,
      FacilitatorSelectorErrorKey,
      `${recordKey}`
    );

    const hasDuplicates = ((): boolean => {
      const otherFacilitators = [
        ...facilitators.slice(0, recordKey),
        ...facilitators.slice(recordKey + 1),
      ];

      const duplicateFacilitator = otherFacilitators.find((otherFacilitator) => {
        return otherFacilitator?.cmisId === facilitator.cmisId;
      });

      return duplicateFacilitator !== undefined;
    })();

    if (hasDuplicates) {
      isValid = false;
      formStatus.errors.addMessage(
        duplicateFacilitatorErrorMessage,
        FacilitatorSelectorErrorKey,
        `${recordKey}`
      );
    }

    formStatus.validity.setValidity(isValid, FacilitatorSelectorErrorKey, `${recordKey}`);

    return isValid;
  };

  const isFacilitatorValid = (recordKey: number, facilitator: FacilitatorCreationDto) => {
    let isValid = true;

    const facilitatorIsGuestMessage = intl.formatMessage({
      id: "facilitator_is_guest",
      defaultMessage:
        "This facilitator does not have a Church Account with a membership record number, and will not be able to see group information.",
    });

    const isMember = facilitator.hasChurchAccount;

    if (isMember) {
      formStatus.messages.removeMessage(
        facilitatorIsGuestMessage,
        FacilitatorSelectorErrorKey,
        `${recordKey}`
      );

      isValid = isMemberFacilitatorValid(recordKey, facilitator);
    } 
    else {
      isValid = isGuestFacilitatorValid(recordKey, facilitator);

      if (isValid) {
        formStatus.messages.addMessage(
          facilitatorIsGuestMessage,
          FacilitatorSelectorErrorKey,
          `${recordKey}`
        );
      }
    }

    setFormStatus(new FormStatus(formStatus));

    return isValid;
  };

  const getMembersRequest = async () => {
    setLoading(true);
    setIsRetryVisible(false);

    if (stakeUnitNumber) {

      const stakeMembers = await (async () => {
        const response = await StakeMembersApi.get(stakeUnitNumber);
        if (response.responseType === ServerResponseType.Success) {
          setPotentialFacilitators([...response.data]);

          const newAvailableStakeMembers = [...response.data];
          group?.facilitators.forEach(facilitator => {
            if (facilitator && facilitator.id) {
              const newAvailableStakeMembersIndex = newAvailableStakeMembers.findIndex(newFacilitator => newFacilitator.id == facilitator.id);
              if (newAvailableStakeMembersIndex != -1) {
                newAvailableStakeMembers.splice(newAvailableStakeMembersIndex, 1);
              }
            }
          });
          return newAvailableStakeMembers;
        }
        return [];
      })();

      if (stakeMembers.length == 0) {
        setIsRetryVisible(true);
        return;
      }

      const newPotentialFacilitators: StakeMemberDto[] = [...stakeMembers];

      group.facilitators.forEach(facilitator => {
        if (facilitator && facilitator.id) {
          const newPotentialFacilitatorIndex = newPotentialFacilitators.findIndex(newFacilitator => newFacilitator.id == facilitator.id);
          if (newPotentialFacilitatorIndex != -1) {
            newPotentialFacilitators.splice(newPotentialFacilitatorIndex, 1);
          }
        }
      });
      setAvailablePotentialFacilitators(newPotentialFacilitators);
    }
    setLoading(false);
  };

  const handleFacilitatorAdd = () => {
    groupFormStore.addEmptyFacilitator();
  };

  const handleFacilitatorClear = (recordKey: number, facilitator: FacilitatorCreationDto) => {
    if (facilitator) {
      makeFacilitatorAvailable(facilitator);
      formStatus.errors.clearMessages(
        FacilitatorSelectorErrorKey,
        `${recordKey}`
      );
      formStatus.messages.clearMessages(
        FacilitatorSelectorErrorKey,
        `${recordKey}`
      );
      formStatus.dirtiness.reset(FacilitatorSelectorErrorKey, `${recordKey}`);
      formStatus.validity.reset(FacilitatorSelectorErrorKey, `${recordKey}`);

      setFormStatus(new FormStatus(formStatus));
      groupFormStore.dropFacilitatorAtIndex(recordKey);
    }
  };

  const handleFacilitatorRemove = (recordKey: number, facilitator: FacilitatorCreationDto) => {
    if (facilitator) {
      makeFacilitatorAvailable(facilitator);

      groupFormStore.dropFacilitatorAtIndex(recordKey);

      const oldFacilitatorsLastIndex =
        facilitators.length > 0 ? facilitators.length - 1 : 0;

      formStatus.errors.clearMessages(
        FacilitatorSelectorErrorKey,
        `${oldFacilitatorsLastIndex}`
      );
      formStatus.messages.clearMessages(
        FacilitatorSelectorErrorKey,
        `${oldFacilitatorsLastIndex}`
      );
      formStatus.dirtiness.reset(FacilitatorSelectorErrorKey, `${oldFacilitatorsLastIndex}`);
      formStatus.validity.reset(FacilitatorSelectorErrorKey, `${oldFacilitatorsLastIndex}`);

      setFormStatus(new FormStatus(formStatus));
    }
  };

  const handleFacilitatorChange = async (recordKey: number, newFacilitator: FacilitatorCreationDto) => {
    const oldFacilitator = facilitators[recordKey]!;

    const standardFacilitatorIsGuestWarningMessage = intl.formatMessage({
      id: "facilitator_is_guest",
      defaultMessage: "This facilitator does not have a Church Account with a membership record number, and will not be able to see group information.",
    });

    formStatus.messages.removeMessage(
      standardFacilitatorIsGuestWarningMessage,
      FacilitatorSelectorErrorKey,
      `${recordKey}`
    );

    makeFacilitatorAvailable(oldFacilitator);

    if(newFacilitator.cmisId) {

      makeFacilitatorUnavailable(newFacilitator);
      setCheckingChurchAccount(true);
      const hasAccount = await Requests.getStakeMemberHasChurchAccount(
        newFacilitator.cmisId
      );

      if (!hasAccount) {
        groupFormStore.setFacilitatorAtIndex({
          name: `${newFacilitator.firstName?.trim()} ${newFacilitator.lastName?.trim()}`.trim(),
          hasChurchAccount: false
        } as FacilitatorCreationDto, recordKey);

        formStatus.messages.addMessage(
          standardFacilitatorIsGuestWarningMessage,
          FacilitatorSelectorErrorKey,
          `${recordKey}`
        );
      } else {
        groupFormStore.setFacilitatorAtIndex({
          name: `${newFacilitator.firstName?.trim()} ${newFacilitator.lastName?.trim()}`.trim(),
          id: newFacilitator?.id,
          cmisId: newFacilitator.cmisId,
          firstName: newFacilitator.firstName,
          middleName: newFacilitator.middleName,
          lastName: newFacilitator.lastName,
          birthDate: newFacilitator.birthDate,
          gender: newFacilitator.gender,
          hasChurchAccount: true

        } as FacilitatorCreationDto, recordKey);
      }

      setCheckingChurchAccount(false);
    }
    else {
      groupFormStore.setFacilitatorAtIndex({
        name: newFacilitator.name,
        hasChurchAccount: false
      } as FacilitatorCreationDto, recordKey);
    }

    formStatus.dirtiness.setDirty(true, FacilitatorSelectorErrorKey, `${recordKey}`);

    setFormStatus(new FormStatus(formStatus));

  };

  const areFacilitatorsValid = (facilitators: (FacilitatorCreationDto | null)[]) => {

    clearValidationMessages();

    // (Re-)validate all facilitators
    facilitators.forEach((facilitator, index) => {
      if (facilitator) {
        isFacilitatorValid(index, facilitator);
      }
    });

    let areValid = true;

    const noStandardFacilitatorsErrorMessage = intl.formatMessage({
      id: "need-member-facilitator-explanation",
      defaultMessage:
        "Only facilitators with a Church Account and membership record number can access the group roster. At least one Church member facilitator must be present at all group meetings.",
    });

    if (
      facilitators.length > 0 &&
      !facilitators.find((f) => f?.cmisId)
    ) {
      areValid = false;

      formStatus.errors.addMessage(
        noStandardFacilitatorsErrorMessage,
        FacilitatorSelectorErrorKey
      );
      formStatus.validity.setValidity(areValid, FacilitatorSelectorErrorKey);
    } else {
      formStatus.errors.removeMessage(
        noStandardFacilitatorsErrorMessage,
        FacilitatorSelectorErrorKey
      );
      formStatus.validity.setValidity(areValid, FacilitatorSelectorErrorKey);
    }

    setFormStatus(new FormStatus(formStatus));

    return areValid;
  };

  const clearValidationMessages = () => {
    facilitators.forEach((_, index) => {
      formStatus.errors.clearMessages(FacilitatorSelectorErrorKey, `${index}`);
    });
  };

  const makeFacilitatorUnavailable = (facilitator: FacilitatorCreationDto): void => {
    const isStandard = facilitator.hasChurchAccount;
    const availableStakeMemberIndex = isStandard ?
      availablePotentialFacilitators.findIndex((stakeMember) => stakeMember.id === facilitator.cmisId)
      :
      availablePotentialFacilitators.findIndex((stakeMember) => stakeMember.id === facilitator.id);
    if (availableStakeMemberIndex !== -1) {
      const newAvailableStakeMembers = [...availablePotentialFacilitators];
      newAvailableStakeMembers.splice(availableStakeMemberIndex, 1);
      setAvailablePotentialFacilitators(newAvailableStakeMembers);
    }
  };

  const makeFacilitatorAvailable = (facilitator: FacilitatorCreationDto): void => {
    const isStandard = facilitator.hasChurchAccount;
    const availableStakeMemberIndex = isStandard ?
      availablePotentialFacilitators.findIndex((stakeMember) => stakeMember.id === facilitator.cmisId)
      :
      availablePotentialFacilitators.findIndex((stakeMember) => stakeMember.id === facilitator.id);
    if (availableStakeMemberIndex == -1) {
      const stakeMemberIndex = isStandard ?
        potentialFacilitators.findIndex((stakeMember) => stakeMember.id == facilitator.cmisId)
        :
        potentialFacilitators.findIndex((stakeMember) => stakeMember.id == facilitator.id);
      const facilitatorToMakeAvailable = stakeMemberIndex != -1 ? potentialFacilitators[stakeMemberIndex] : undefined;
      if (facilitatorToMakeAvailable) {
        const newAvailableStakeMembers = [...availablePotentialFacilitators];
        newAvailableStakeMembers.splice(stakeMemberIndex, 0, facilitatorToMakeAvailable);
        setAvailablePotentialFacilitators(newAvailableStakeMembers);
      }
    }
  };

  ////////////////////
  // Render Helpers //
  ////////////////////

  const facilitatorHasErrors = (recordKey: number) => {

    return !formStatus.validity.isValid(FacilitatorSelectorErrorKey, `${recordKey}`) &&
    (formStatus.saveState === SaveState.ValidationFailed ||
      formStatus.dirtiness.isDirty(FacilitatorSelectorErrorKey, `${recordKey}`));
  };

  ////////////
  // Render //
  ////////////
  if (!group?.stakeUnitNumber) {
    return <></>;
  }

  if (group?.stakeUnitNumber && isLoading) {
    return (
      <>
        <hr />
        <div className={className}>
          <Loading
            loading={isLoading}
            small={true}
            message={intl.formatMessage({
              id: "getting_stake_members",
              defaultMessage: "Loading Stake Members",
            })}
          />
        </div>
        <hr />
      </>
    );
  }

  return (
    <>
      <hr />
      <div className={className}>
        <>
          {facilitators.map(
            (facilitator, index, facilitators) =>
              (() => {
                return true;
              })() && facilitator && (
                <div
                  className="facilitatorFieldContainer"
                  key={`facilitator-${index}`}
                >
                  <div className="typeAheadRemoveContainer">
                    <StakeMemberTypeAhead
                      availableStakeMembers={availablePotentialFacilitators}
                      stakeMembers={potentialFacilitators}
                      onChange={(changed) =>
                        handleFacilitatorChange(index, changed)
                      }
                      onClear={() => handleFacilitatorClear(index, facilitator)}
                      error={facilitatorHasErrors(index)}
                      helperText={
                        facilitatorHasErrors(index)
                          ? formStatus.errors
                            .getMessages(FacilitatorSelectorErrorKey, `${index}`)
                            .map((message, index) => (
                              <span
                                key={`facilitator-picker-autocomplete-message-${index}`}
                              >
                                {message}
                              </span>
                            ))
                          : formStatus.messages
                            .getMessages(FacilitatorSelectorErrorKey, `${index}`)
                            .map((message, index) => (
                              <span
                                key={`facilitator-picker-autocomplete-message-${index}`}
                              >
                                {message}
                              </span>
                            ))
                      }
                    />
                    <div className="removeButtonContainer">
                      <IconButton
                        className="removeField"
                        onClick={() => handleFacilitatorRemove(index, facilitator)}
                        disabled={checkingChurchAccount}
                      >
                        <RemoveCircleOutlineIcon className="removeIcon" />
                        <FormattedMessage id="remove" defaultMessage="Remove" />
                      </IconButton>
                    </div>
                  </div>

                  {(index === facilitators.length - 1 || facilitators.slice(index + 1).every((f) => !f)) &&
                    (
                      <div className="addFieldButton">
                        {!formStatus.validity.isValid(FacilitatorSelectorErrorKey) &&
                          (formStatus.saveState === SaveState.ValidationFailed ||
                            formStatus.dirtiness.isDirty(FacilitatorSelectorErrorKey)) &&
                          (
                            <InfoHoverBox status={InfoHoverStatus.Error}>
                              <>
                                {formStatus.errors
                                  .getMessages(FacilitatorSelectorErrorKey)
                                  .map((message, index) => (
                                    <div
                                      key={`facilitator-picker-add-button-message-${index}`}
                                    >
                                      {message}
                                    </div>
                                  ))}
                              </>
                            </InfoHoverBox>
                          )}

                        <Button
                          variant="outlined"
                          onClick={handleFacilitatorAdd}
                          disabled={checkingChurchAccount}
                        >
                          <FormattedMessage
                            id="add_facilitator"
                            defaultMessage="Add Facilitator"
                          />
                        </Button>
                      </div>
                    )}
                </div>
              )
          )}

          <div className="buttonSection">
            {(facilitators.length <= 0 || facilitators.every((f) => !f)) && (
              <Button
                className="addFieldButton"
                variant="outlined"
                onClick={handleFacilitatorAdd}
                disabled={isRetryVisible}
              >
                <FormattedMessage
                  id="add_facilitator"
                  defaultMessage="Add Facilitator"
                />
              </Button>
            )}

            {isRetryVisible && (
              <div className="retrySection">
                <Button
                  className="retryButton"
                  variant="outlined"
                  onClick={getMembersRequest}
                >
                  <FormattedMessage
                    id="retry"
                    defaultMessage="Retry"
                  />
                </Button>
                <span>
                  <FormattedMessage
                    id="retry"
                    defaultMessage="The service failed to get members for your stake. Please click retry to try again."
                  />
                </span>
              </div>
            )}
          </div>
        </>
      </div>
      <hr />
    </>
  );
};

export default FacilitatorSelector;