import { MeetingTimeDto, StudentDto, StudentEnrollmentDto } from "lib/api/backend/model";
import { StudentEnrollment, StudentEnrollmentV2 } from "lib/data/model";

export function sortStudentsByLastName<T extends StudentEnrollment | StudentEnrollmentDto | StudentDto | StudentEnrollmentV2>(students: T[]): T[] {
  if (!Array.isArray(students) || students.length < 1) {
    return [];
  }
  return students.sort((a, b) => `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`));
}

export function sortGroupMeetingTimes(meetingTimes: MeetingTimeDto[]) {
  if (!meetingTimes?.length || meetingTimes.length < 1) {
    return [];
  }
  const groupedByDay: {
    dayOfWeek: number,
    meetingTimes: MeetingTimeDto[]
  }[] = [];
  let sortedMeetingTimes: MeetingTimeDto[] = [];

  meetingTimes.forEach(meetingTime => {
    const foundSortDay = groupedByDay.find(day => day.dayOfWeek == meetingTime.dayOfWeek);
    if (foundSortDay) {
      foundSortDay.meetingTimes.push(meetingTime);
    } else {
      groupedByDay.push({
        dayOfWeek: meetingTime.dayOfWeek!,
        meetingTimes: [meetingTime]
      });
    }
  });

  groupedByDay.sort((a, b) => a.dayOfWeek - b.dayOfWeek);
  groupedByDay.forEach(dayGroup => {
    dayGroup.meetingTimes.sort((a, b) => new Date(a.startTime!).getTime() - new Date(b.startTime!).getTime());
    sortedMeetingTimes = sortedMeetingTimes.concat(dayGroup.meetingTimes);
  });
  return sortedMeetingTimes;
}
