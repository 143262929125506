import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

const Unauthorized: React.FC = () => {
  const user = useSelector((state: any) => state.userReducer.user);
  return (
    <>
      <h5 className="error"><FormattedMessage id="unauthorized" defaultMessage="Unauthorized" /></h5>
      <section>
        <p className="error"><FormattedMessage id="unauthorized_explanation" defaultMessage="Sorry, it appears that you are not authorized to perform the requested action." /></p>
        {user.accessIssues != null &&
          <p className="error">Access Issue(s): {user.accessIssues}</p>
        }
      </section>
    </>
  );
};

export default Unauthorized;
