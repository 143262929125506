import "./group-details.scss";
import { useIntl, FormattedMessage } from "react-intl";
import { useParams, useNavigate } from "react-router-dom";
import GroupStatus from "components/group-status/group-status";
import GroupStatuses from "constants/group-constants";
import { getLocaleDateString, getWeekdayName, getHHMM } from "utilities/time";
import TermsAndConditions from "components/terms-and-conditions/terms-and-conditions";
import { ConsentFormUrlSelector } from "components/consent-form-url-selector";
import { setCourseSignupSession, getCourseSignupSession } from "utilities/navUtils";
import { CourseSignUpBaseUrl, JoinNowBaseUrl } from "routes";
import { Permissions } from "constants/api-auth-constants";
import UserCan from "components/user-can/user-can";
import { GroupParticipantList } from "components/group-participant-list";
import { useEffect, useState } from "react";
import { ServerResponseType } from "lib/api/backend/request-utils";
import { useDispatch, useSelector } from "react-redux";
import dispatchers from "datastore/dispatcher";
import { getFullGroup, getGroupDetails } from "lib/api/backend/requests/groups";
import { NoticeCreation } from "lib/data/model";
import Loading from "components/loading/loading";
import { FullGroup, GroupDetails } from "lib/models-v2";
import AuthorizeService from "auth/authorize-service";
import useGroupStore from "datastore/groups";

const GroupDetailsPage: React.VFC = () => {

  const { groupId } = useParams();
  const navigate = useNavigate();
  const groupsStore = useGroupStore();
  const [group, setGroup] = useState<GroupDetails | FullGroup>();
  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const reduxDispatch = useDispatch();
  const notify = dispatchers(reduxDispatch).notify as (notice: NoticeCreation) => void;
  const user = useSelector((state: any) => state.userReducer.user);

  useEffect(() => {
    fetchGroupDetails();
  }, [groupId]);

  useEffect(() => {
    const isFullGroup = (group as FullGroup)?.quickRegParticipants !== undefined;
    const checkIfNeedsFullGroup = async () => {
      if (user && group && !isFullGroup) {
        const shouldGetFullGroup = AuthorizeService.userCan([Permissions.ManageGroupsRead], group.stakeUnitNumber);
        if (shouldGetFullGroup) {
          const fullGroup = await fetchFullGroup();
          if (fullGroup) {
            setGroup(fullGroup);
          }
        }
      }
    };
    checkIfNeedsFullGroup();
  }, [group, user]);

  const fetchGroupDetails = async (): Promise<void> => {
    setLoading(true);
    if (groupId && (!group || group.id != Number(groupId))) {
      const groupDetailsResponse = await getGroupDetails(Number(groupId));
      if (groupDetailsResponse?.responseType == ServerResponseType.Error) {
        notify({ status: "error", message: <FormattedMessage id="get_group_error" defaultMessage="Could not get group." /> });
        return;
      }
      if (groupDetailsResponse?.responseType == ServerResponseType.Success) {
        setGroup(groupDetailsResponse.data);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const fetchFullGroup = async () => {
    if (groupId) {
      const storedGroup = groupsStore.getGroup(Number(groupId));
      if (storedGroup) {
        return storedGroup;
      }
      const fullGroupResponse = await getFullGroup(Number(groupId));
      if (fullGroupResponse?.responseType == ServerResponseType.Error) {
        notify({ status: "error", message: <FormattedMessage id="get_group_error" defaultMessage="Could not get group." /> });
        return null;
      }
      if (fullGroupResponse?.responseType == ServerResponseType.Success) {
        groupsStore.editGroup(fullGroupResponse.data);
        return fullGroupResponse.data;
      }
    }
    return null;
  };

  const getGroupAgeRange = () => {
    if (!group?.isAdultOnly && !group?.isYouthOnly)
      return intl.formatMessage({ id: "group_age_type_combined", defaultMessage: "All Ages" });

    if (group?.isAdultOnly)
      return intl.formatMessage({ id: "group_age_type_adult", defaultMessage: "Adult only, group only for adults" });

    if (group.isYouthOnly)
      return intl.formatMessage({ id: "group_age_type_youth", defaultMessage: "Youth only, group for youth" });
  };

  const getGroupLanguage = () => {
    const language = group?.language;

    if (!language) {
      return intl.formatMessage({ id: "multilingual", defaultMessage: "Multilingual" });
    }

    return language?.description;
  };

  const getParticipants = () => {
    const groupObject = group!;

    const totalParticipants = groupObject.enrollmentCount ? groupObject.enrollmentCount : 0;

    if (!groupObject.maxEnrollment) {
      return totalParticipants.toString();
    }

    return `${totalParticipants}/${groupObject.maxEnrollment}`;
  };

  const getLocation = () => {
    if (!group?.isVirtual) {
      return (<>{group!.address1}, {group!.city}, {group!.state}, {group!.country}</>);
    }

    return (
      <div className="virtual-badge">
        <FormattedMessage id="virtual" defaultMessage="Virtual" />
      </div>
    );
  };

  const goToSignUp = () => {
    if (group) {
      setCourseSignupSession(
        group.providerId,
        group,
        `${window.location.origin}${window.location.pathname}`,
      );
      const signUpUrl = `${CourseSignUpBaseUrl}/${group.id}`;
      navigate(signUpUrl);
    }
  };

  const back = () => {
    const detailsOrigin = getCourseSignupSession().groupDetailsOriginUrl;
    if (detailsOrigin) {
      try {
        navigate(new URL(detailsOrigin).pathname);
      } catch (error) {
        navigate(detailsOrigin);
      }
    } else {
      navigate(JoinNowBaseUrl);
    }
  };

  const isDisabled = () => {
    if (!group)
      return false;

    const pastEndDate = group.endDate ? new Date() > new Date(group.endDate) : false;
    return group.status == GroupStatuses.Completed || group.status == GroupStatuses.Cancelled || group.status == GroupStatuses.Concluded || pastEndDate;
  };

  const signUpDisabled = isDisabled();
  const hasParticipants = (group && (group as FullGroup)?.quickRegParticipants);

  return (
    <div className="group-details-page hasLoader">
      <Loading loading={loading} />
      {group && (
        <>
          <div className="go-back-section">
            <span className="go-back-link" onClick={back}>
              <FormattedMessage id="back_to_group" defaultMessage="Back to Groups" />
            </span>
          </div>
          <div className="group-header flex">
            <div className="flex">
              <h3 className="group-type-name">{group!.programName}</h3>
              <div className="vertical-center status-pill">
                <GroupStatus
                  status={
                    group!.isPending ? GroupStatuses.Pending : group!.status
                  }
                />
              </div>
            </div>
          </div>
          <div className="details-grid">
            <div className="start-end-date">
              <span className="grid-header">
                <FormattedMessage id="start_end_date" defaultMessage="Start/End Date" />
              </span>
              {group.startDate &&
                <div>
                  <time dateTime={group.startDate}>
                    {getLocaleDateString(group.startDate)}
                  </time>
                  <span> - </span>
                  {group.endDate ? (
                    <time dateTime={group.endDate}>
                      {getLocaleDateString(group.endDate)}
                    </time>
                  ) : (
                    <span>
                      <FormattedMessage
                        id="open_schedule"
                        defaultMessage="Open"
                      />
                    </span>
                  )}
                </div>
              }
            </div>
            <div className="meeting-day-time">
              <span className="grid-header">
                <FormattedMessage id="meeting_day_time" defaultMessage="Meeting Day/Time" />
              </span>
              {group.meetingTimes && (
                <div className="data">
                  {group.meetingTimes.map((day, index) => {
                    return (
                      <div key={`${group.id}-day-${index}`}>
                        {`${intl.formatMessage({
                          id: getWeekdayName(day.dayOfWeek).toLowerCase(),
                          defaultMessage: getWeekdayName(day.dayOfWeek),
                        })} ${getHHMM(day.startTime)}`}
                        {day.endTime != null && " - " + getHHMM(day.endTime)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="location">
              <span className="grid-header">
                <FormattedMessage id="location" defaultMessage="Location" />
              </span>
              <div className="addressCaption">
                {getLocation()}
              </div>
            </div>
            <div className="language">
              <span className="grid-header">
                <FormattedMessage id="language" defaultMessage="Language" />
              </span>
              <div>
                {getGroupLanguage()}
              </div>
            </div>
            <div className="ages">
              <span className="grid-header">
                <FormattedMessage id="age_range" defaultMessage="Age Range" />
              </span>
              <div>
                {getGroupAgeRange()}
              </div>
            </div>
            <div className="participants">
              <span className="grid-header">
                <FormattedMessage id="participants" defaultMessage="Participants" />
              </span>
              <div>
                {getParticipants()}
              </div>
            </div>
          </div>
          <div className="descriptionSection">
            <p className="grid-header">
              <FormattedMessage id="description" defaultMessage="Description" />
            </p>
            <p>{group!.programDescription}</p>
          </div>
          <div className="participationTermsLink">
            <TermsAndConditions
              providerId={group.providerId ? group.providerId : 0}
              programId={group.programId ? group.programId : 0}
            />
          </div>
          <div>
            <button onClick={goToSignUp} disabled={signUpDisabled}>
              <FormattedMessage id="sign_up" defaultMessage="Sign Up" />
            </button>
            {signUpDisabled &&
              <p className="error"><FormattedMessage id="group_unavailable" defaultMessage={"This group is not available for enrollment."} /></p>
            }
          </div>
          {!group.isAdultOnly &&
            <ConsentFormUrlSelector providerId={group.providerId!} programId={group.programId!} />
          }
          <UserCan perform={[Permissions.ManageGroupsRead]} unitNumber={group.stakeUnitNumber}>
            {hasParticipants &&
              <GroupParticipantList group={group as FullGroup} />
            }
          </UserCan>
        </>

      )}
    </div>
  );
};

export default GroupDetailsPage;
