import { GuestFacilitator } from "lib/data/model";
import NonMemberFacilitatorCreationDto from "../model/NonMemberFacilitatorCreationDto";
import { IModelToDtoConverter } from "./IDtoConverter";

export class GuestFacilitatorToNonMemberFacilitatorCreationDtoConverter implements IModelToDtoConverter<GuestFacilitator, NonMemberFacilitatorCreationDto> {
  public toDto = (model: GuestFacilitator): NonMemberFacilitatorCreationDto => {
    const facilitator = new NonMemberFacilitatorCreationDto({
      id: model.id,
      name: model.name
    });

    return facilitator;
  };
}