import { Group, GuestFacilitator, Location, StandardFacilitator } from "lib/data/model";
import { AgeType, LocationType } from "lib/data/model/enum";
import { CourseDto } from "../model/course";
import AddressDto from "../model/AddressDto";
import GeoCoordinateDto from "../model/GeoCoordinateDto";
import GroupDto from "../model/GroupDto";
import { FacilitatorDtoToGuestFacilitatorConverter } from "./FacilitatorDtoToGuestFacilitatorConverter";
import { FacilitatorDtoToStandardFacilitatorConverter } from "./FacilitatorDtoToStandardFacilitatorConverter";
import { IDtoToModelConverter } from "./IDtoConverter";

export class GroupDtoToGroupConverter implements IDtoToModelConverter<GroupDto, Group> {
  public toModel = (dto: GroupDto): Group => {
    //////////////
    // Age Type //
    //////////////

    let groupAgeType: AgeType;

    if (dto.groupAgeType && typeof dto.groupAgeType !== "string") {
      groupAgeType = dto.groupAgeType;
    }
    else {
      if (dto.isYouthOnly != undefined && dto.isYouthOnly != null && dto.isYouthOnly) {
        groupAgeType = AgeType.Youth;
      }
      else if (dto.isAdultOnly != undefined && dto.isAdultOnly != null && dto.isAdultOnly) {
        groupAgeType = AgeType.Adult;
      }
      else {
        groupAgeType = AgeType.Combined;
      }
    }

    //////////////
    // Location //
    //////////////

    let address: AddressDto | undefined;
    let coordinates: GeoCoordinateDto | undefined;

    if (dto.address1 || dto.address2 || dto.city || dto.state || dto.postalCode || dto.country) {
      address = new AddressDto({
        address1: dto.address1,
        address2: dto.address2,
        city: dto.city,
        state: dto.state,
        postalCode: dto.postalCode,
        country: dto.country
      });
    }

    if (dto.latitude && dto.longitude) {
      coordinates = new GeoCoordinateDto({
        latitude: dto.latitude!,
        longitude: dto.longitude!
      });
    }

    let locationType: LocationType;

    if (dto.isVirtual != undefined && dto.isVirtual != null && dto.isVirtual) {
      locationType = LocationType.Virtual;
    }
    else if (dto.isCustom != undefined && dto.isCustom != null && dto.isCustom) {
      locationType = LocationType.Other;
    }
    else {
      locationType = LocationType.ChurchOwned;
    }

    const location = new Location({
      id: dto.locationId,
      propertyId: dto.propId,
      stakeUnitNumber: dto.stakeUnitNumber!,
      type: locationType,
      address: address,
      coordinates: coordinates
    });

    //////////////////
    // Facilitators //
    //////////////////

    const standardFacilitatorConverter = new FacilitatorDtoToStandardFacilitatorConverter();
    const guestFacilitatorConverter = new FacilitatorDtoToGuestFacilitatorConverter();

    let facilitators: (StandardFacilitator | GuestFacilitator)[] = [];

    if (dto.facilitators?.map) {
      facilitators = facilitators.concat(dto.facilitators.map(f => standardFacilitatorConverter.toModel(f)));
    }

    if (dto.nonMemberFacilitators?.map) {
      facilitators = facilitators.concat(dto.nonMemberFacilitators.map(f => guestFacilitatorConverter.toModel(f)));
    }

    /////////////
    // Convert //
    /////////////

    return new Group({
      id: dto.id,
      name: dto.name,
      location: location,
      course: new CourseDto({
        id: dto.courseId,
        name: dto.courseName,
        description: dto.courseDescription,
        providerId: dto.providerId,
        trackTemplates: []
      }),
      courseOutcomes: dto.courseOutcomes ?? [],
      numberOfLessons: dto.numberOfLessons,
      lessonTime: dto.lessonTime,
      providerId: dto.providerId,
      programLanguages: dto.programLanguages,
      programId: dto.programId,
      termId: dto.termId,
      trackId: dto.trackId,
      startDate: dto?.startDate,
      endDate: dto?.endDate,
      meetingTimes: dto.meetingTimes,
      facilitators: facilitators,
      students: dto.students,
      enrollmentCount: dto.enrollmentCount,
      maxEnrollment: dto.maxEnrollment,
      status: dto.status,
      duration: dto.duration,
      groupAgeType: groupAgeType,
      isHidden: dto.isHidden ?? false,
      isPending: dto.isPending,
      hasAcceptedYouthTerms: dto.hasAcceptedYouthTerms,
      hasParticipationCertificate: dto.hasParticipationCertificate,
      hasCompletionCertificate: dto.hasCompletionCertificate,
      wsrsOutcomes: dto.wsrsOutcomes,
      stakeUnitNumber: dto.stakeUnitNumber,
      groupLanguages: dto.groupLanguages
    });
  };
}