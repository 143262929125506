import React, { ReactElement, useContext } from "react";
import { Dialog, DialogContent } from "@mui/material";
import FilterPanelControls from "../filter-panel-controls";
import { FilterConfig } from "../../models";
import { ListControlPanelContext } from "../../list-control-panel-store";
import "./filter-panel-modal.scss";
import { useStore } from "zustand";

interface IFilterPanelModal {
  onClose?: () => void;
  onApplyFilters: (filterConfig: FilterConfig) => void;
}

export const FilterPanelModal = (props: IFilterPanelModal): ReactElement => {

  const filterStore = useContext(ListControlPanelContext);
  const { isFilterModalOpen, setIsFilterModalOpen } = useStore(filterStore!, s => s);

  const handleClose = () => {
    setIsFilterModalOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div className="filterPanelModalContainer">
      <Dialog
        className="filter-dialog"
        fullWidth={true}
        maxWidth="md"
        open={isFilterModalOpen}
        onClose={handleClose}
      >
        <DialogContent>
          <FilterPanelControls
            onApplyFilters={props.onApplyFilters}
            onClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );

};

export default FilterPanelModal;