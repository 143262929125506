import { useState, ReactElement } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import LoadingStatus from "components/loading/loading";
import {
  GroupParticipantListLoadingStatus,
  GroupParticipantListViewNames,
} from "./models";
import AuthService from "auth/authorize-service";
import { Permissions } from "constants/api-auth-constants";
import UserCan from "components/user-can/user-can";
import GroupStatuses, {
  GroupStatusesAllowedToPrint,
} from "constants/group-constants";
import StudentStatusesV2 from "constants/student-constants";
import { getAndDownloadGroupParticipantCertificates } from "lib/api/backend/requests/certificates";
import dispatchers from "datastore/dispatcher";
import Layouts from "constants/layout-constants";
import { Alert, NoticeCreation, Prompt } from "lib/data/model";
import {
  CertificateFailureType,
  CertificateFailureTypeTransLationsKeys,
} from "constants/certificate-constants";
import GroupParticipantListTable from "./components/group-participant-list-table";
import GroupParticipantListCardView from "./components/group-participant-list-card-view";
import GroupParticipantListControls from "./components/group-participant-list-controls";
import type { FullGroup, QuickRegParticipant } from "lib/models-v2/LeadersGroupResponse";
import { dropParticipants, recieveConsents } from "lib/api/backend/requests/participant";
import { MenuItem, Select } from "@mui/material";
import ParticipantCertificateFailureDto from "lib/api/backend/model/participant/ParticipantCertificateFailureDto";
import useGroupStore from "datastore/groups";

interface IGroupStudentList {
  group: FullGroup;
}

export const GroupParticipantList = (props: IGroupStudentList): ReactElement => {
  const group = props.group;
  const groupStore = useGroupStore();
  const layout = useSelector((state: any) => state.appReducer.layout);
  const isDesktop = layout == Layouts.Desktop;
  const [view, setView] = useState<GroupParticipantListViewNames>(
    isDesktop ? GroupParticipantListViewNames.Table : GroupParticipantListViewNames.Card
  );
  const [filteredParticipants, setFilteredParticipants] = useState<QuickRegParticipant[]>([]);
  const [selectedParticipants, setSelectedParticipants] = useState<QuickRegParticipant[]>([]);
  const [loading, setLoading] = useState<GroupParticipantListLoadingStatus>({
    status: false,
    message: "",
  });
  const canManageParticipants = group
    ? AuthService.userCan(
      [Permissions.ManageGroupsWrite],
      group.stakeUnitNumber
    )
    : false;
  const canPrintCompletionCerts = group
    ? AuthService.userCan(
      [Permissions.PrintStudentCompletionCertificatesWrite],
      group.stakeUnitNumber
    )
    : false;
  const reduxDispatch = useDispatch();
  const notify = dispatchers(reduxDispatch).notify as (
    notice: NoticeCreation
  ) => void;
  const prompt = dispatchers(reduxDispatch).prompt as (prompt: Prompt) => void;
  const alert = dispatchers(reduxDispatch).alert as (alert: Alert) => void;

  const selectStudent = (studentId: number) => {
    const student = filteredParticipants.find(
      (student) => student.id == studentId
    );
    if (student) {
      const newStudents = [...selectedParticipants];
      newStudents.push(student);
      if (newStudents.length == filteredParticipants.length) {
        const selectAllElem =
          document.querySelector<HTMLInputElement>(".selectAll");
        if (selectAllElem) {
          selectAllElem.checked = true;
        }
      }
      setSelectedParticipants(newStudents);
    }
  };

  const unselectStudent = (studentId) => {
    const newStudents = [...selectedParticipants];
    const index = newStudents.findIndex(
      (student) => student.id == studentId
    );
    if (index > -1) {
      newStudents.splice(index, 1);
    }
    setSelectedParticipants(newStudents);
  };

  const resetSelected = () => {
    if (selectedParticipants.length > 0) {
      setSelectedParticipants([]);
      document
        .querySelectorAll<HTMLInputElement>(".studentSelector")
        .forEach((element) => (element.checked = false));
      const selectAllElem =
        document.querySelector<HTMLInputElement>(".selectAll");
      if (selectAllElem) {
        selectAllElem.checked = false;
      }
    }
  };

  const selectAll = () => {
    setSelectedParticipants(filteredParticipants);
    document
      .querySelectorAll<HTMLInputElement>(".studentSelector")
      .forEach((element) => (element.checked = true));
    const selectAllElem =
      document.querySelector<HTMLInputElement>(".selectAll");
    if (selectAllElem) {
      selectAllElem.checked = true;
    }
  };

  const removeParticipants = async () => {
    if (canManageParticipants && group) {
      if (selectedParticipants?.length < 1) {
        nothingSelectedAlert();
      } else {
        const shouldContinue = await confirmDeletePrompt();
        if (shouldContinue) {
          setLoading({
            status: true,
            message: (
              <FormattedMessage
                id="removing_participants"
                defaultMessage="Removing Participants"
              />
            ),
          });
          const participantIds: number[] = [];
          selectedParticipants.forEach((selectedParticipant) => {
            participantIds.push(selectedParticipant.id!);
          });
          try {
            if (participantIds.length > 0) {
              const result = await dropParticipants(participantIds);
              if (!result) {
                throw new Error;
              }
              const updatedGroup = { ...group };
              updatedGroup.quickRegParticipants.forEach(participant => {
                if (participantIds.includes(participant.id)) {
                  participant.status = StudentStatusesV2.NotEnrolled;
                }
              });
              groupStore.editGroup(updatedGroup);
            }
            setLoading({ status: false, message: "" });
          } catch (e) {
            setLoading({ status: false, message: "" });
            notify({
              status: "error",
              message: (
                <FormattedMessage
                  id="enrollment_update_error"
                  defaultMessage="An error occurred while attempting to update the enrollment."
                />
              ),
            });
          }
          resetSelected();
        }
      }
    }
  };

  const confirmDeletePrompt = () => {
    return new Promise((resolve) => {
      prompt({
        status: "warning",
        head: (
          <FormattedMessage
            id="please_confirm"
            defaultMessage="Please Confirm"
          />
        ),
        message: (
          <FormattedMessage
            id="confirm_remove_participants"
            defaultMessage="Are you sure you want to remove participants? This cannot be undone"
          />
        ),
        buttons: (
          <>
            <button
              onClick={() => {
                resolve(true);
              }}
            >
              <FormattedMessage id="continue" defaultMessage="Continue" />
            </button>
            <button
              onClick={() => {
                resolve(false);
              }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </button>
          </>
        ),
      });
    });
  };

  const updateConsents = async () => {
    if (canManageParticipants && group) {
      if (selectedParticipants?.length < 1) {
        nothingSelectedAlert();
      } else {
        const participantsThatNeedUpdate = await verifyNeedConsent(
          selectedParticipants
        );
        if (participantsThatNeedUpdate.length > 0) {
          setLoading({
            status: true,
            message: (
              <FormattedMessage
                id="receiving_consents"
                defaultMessage="Recording Consent Forms as received"
              />
            ),
          });
          const participantIds = participantsThatNeedUpdate.map((participant) => participant.id);
          try {
            if (participantIds.length > 0) {
              const result = await recieveConsents(participantIds);
              if (!result) {
                throw result;
              }
              const updatedGroup = { ...group };
              updatedGroup.quickRegParticipants.forEach((groupParticipant) => {
                if (participantIds.includes(groupParticipant.id)) {
                  groupParticipant.consentFormSubmitted = true;
                  groupParticipant.status = StudentStatusesV2.Enrolled;
                }
              });
              groupStore.editGroup(updatedGroup);
            }
            setLoading({ status: false, message: "" });
          } catch (e) {
            setLoading({ status: false, message: "" });
            notify({
              status: "error",
              message: (
                <FormattedMessage
                  id="enrollment_update_error"
                  defaultMessage="An error occurred while attempting to update the enrollment."
                />
              ),
            });
          }
          resetSelected();
        }
      }
    }
  };

  const verifyNeedConsent = async (students: QuickRegParticipant[]) => {
    const dontNeedConsent = students.filter(
      (student) => student.status !== StudentStatusesV2.NeedConsent
    );
    if (dontNeedConsent.length > 0) {
      const consentsNeeded = students.filter(
        (student) => student.status == StudentStatusesV2.NeedConsent
      );
      return new Promise<QuickRegParticipant[]>((resolve) => {
        prompt({
          status: "warning",
          head: (
            <FormattedMessage
              id="consent_not_needed"
              defaultMessage="Parental consent not needed"
            />
          ),
          message: (
            <>
              <p>
                <FormattedMessage
                  id="consent_not_needed_explanation"
                  defaultMessage="The following participants are selected but are not marked as needing consent. They will be skipped."
                />
              </p>
              <ul>
                {dontNeedConsent.map((student, index) => (
                  <li key={index}>
                    {student.givenName} {student.surname}
                  </li>
                ))}
              </ul>
            </>
          ),
          buttons: (
            <>
              <button
                className="primary"
                onClick={() => {
                  resolve(consentsNeeded);
                }}
              >
                <FormattedMessage id="Continue" defaultMessage="Continue" />
              </button>
              <button
                className="primary hollow"
                onClick={() => {
                  resolve([]);
                }}
              >
                <FormattedMessage id="Cancel" defaultMessage="Cancel" />
              </button>
            </>
          ),
        });
      });
    }

    return students;
  };

  const printCerts = async (isParticipation = false) => {
    if (selectedParticipants?.length < 1) {
      nothingSelectedAlert();
    } else if (group) {
      setLoading({
        status: true,
        message: (
          <FormattedMessage
            id="getting_cert"
            defaultMessage="Getting certificates..."
          />
        ),
      });

      const participantIds = selectedParticipants.map((student) => student.id);

      const failures = await getAndDownloadGroupParticipantCertificates(
        group.id!,
        participantIds,
        isParticipation
      );

      if (failures.length > 0) {
        showCertFailures(failures, selectedParticipants);
      }

      setLoading({ status: false, message: "" });
    }
  };

  const nothingSelectedAlert = () => {
    alert({
      status: "error",
      head: (
        <FormattedMessage
          id="nothing_selected"
          defaultMessage="Nothing Selected"
        />
      ),
      message: (
        <FormattedMessage
          id="nothing_selected_explanation"
          defaultMessage="You have not selected anything to perform this action on."
        />
      ),
    });
  };

  const showCertFailures = (
    failures: ParticipantCertificateFailureDto[],
    selectedParticipants: QuickRegParticipant[]
  ) => {
    alert({
      status: "error",
      head: (
        <FormattedMessage
          id="get_cert_error"
          defaultMessage="An error occured while retrieving certificates."
        />
      ),
      message: (
        <>
          <p>
            <FormattedMessage
              id="not_included_certificates"
              defaultMessage="The following certificates were not included. This may be because the selected certificate type is not available for the student or group."
            />
          </p>
          <ul>
            {failures.map((failure, index) => {
              const participant = selectedParticipants.find(
                (participant) => participant.id == failure.participantId
              );
              return (
                <li key={index}>
                  <span>
                    {participant?.givenName} {participant?.surname}
                  </span>
                  {failure.failureReason !== CertificateFailureType.Unknown && (
                    <>
                      <br />
                      <span>
                        <FormattedMessage
                          id={
                            CertificateFailureTypeTransLationsKeys[
                              failure.failureReason
                            ].key
                          }
                          defaultMessage={
                            CertificateFailureTypeTransLationsKeys[
                              failure.failureReason
                            ].defaultMessage
                          }
                        />
                      </span>
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      ),
    });
  };

  const handleFilter = (filteredData: QuickRegParticipant[]) => {
    resetSelected();
    setFilteredParticipants(filteredData);
  };

  const handleParticipantLoadingChange = (loading) => {
    setLoading(loading);
  };

  const handleSelectionChange = (checked: boolean, participant: QuickRegParticipant) => {
    if (checked) {
      selectStudent(participant.id!);
    } else {
      unselectStudent(participant.id);
    }
  };

  const handleSelectAllChange = (checked: boolean) => {
    if (checked) {
      selectAll();
    } else {
      resetSelected();
    }
  };

  const handleParticipantChange = (participant: QuickRegParticipant) => {
    if (participant.groupId !== group.id) {
      const filteredParticipantIndex = filteredParticipants.findIndex(filteredParticipant => filteredParticipant.id == participant.id);
      if (filteredParticipantIndex != -1) {
        const newFilteredParticipants = [...filteredParticipants];
        newFilteredParticipants.splice(filteredParticipantIndex, 1);
        setFilteredParticipants(newFilteredParticipants);
      }
    }
  };

  return (
    <div className="groupStudentListContainer hasLoader">
      <LoadingStatus loading={loading.status} message={loading.message} />
      {!loading.status && group && (
        <UserCan
          perform={[Permissions.ManageGroupsRead]}
          unitNumber={group.stakeUnitNumber}
        >
          <GroupParticipantListControls
            participants={group.quickRegParticipants}
            onFilter={handleFilter}
            isLoading={loading.status}
            viewSetter={setView}
            viewValue={view}
          />
          {filteredParticipants.length > 0 ? (
            <>
              {isDesktop && view == GroupParticipantListViewNames.Table ? (
                <GroupParticipantListTable
                  filteredGroupStudents={filteredParticipants}
                  group={group}
                  onSelectionChange={handleSelectionChange}
                  onAllSelectionChange={handleSelectAllChange}
                  onParticipantChange={handleParticipantChange}
                  actions={
                    <>
                      <button
                        disabled={
                          !canManageParticipants ||
                          !selectedParticipants ||
                          !selectedParticipants.length
                        }
                        onClick={updateConsents}
                      >
                        <FormattedMessage
                          id="consent_received"
                          defaultMessage="Consent Form Received"
                        />
                      </button>
                      <button
                        disabled={
                          !canManageParticipants ||
                          !selectedParticipants ||
                          !selectedParticipants.length
                        }
                        onClick={removeParticipants}
                      >
                        <FormattedMessage
                          id="remove_participants"
                          defaultMessage="Remove Participants"
                        />
                      </button>
                      {(group.hasParticipationCertificate || (group.hasCompletionCertificate && group.status == GroupStatuses.Completed)) &&
                        <span className="menuGroupHead">
                          <FormattedMessage id="print" defaultMessage="Print" />
                        </span>
                      }
                      {group.hasParticipationCertificate && GroupStatusesAllowedToPrint.includes(group.status) &&
                        <button
                          disabled={
                            !canManageParticipants ||
                            !selectedParticipants ||
                            !selectedParticipants.length
                          }
                          onClick={() => printCerts(true)}
                        >
                          <FormattedMessage
                            id="participation_certificates"
                            defaultMessage="Participation Certificate(s)"
                          />
                        </button>
                      }
                      {group.hasCompletionCertificate && group.status == GroupStatuses.Completed &&
                        <button
                          disabled={
                            !canManageParticipants ||
                            !selectedParticipants ||
                            !selectedParticipants.length ||
                            !canPrintCompletionCerts
                          }
                          onClick={() => printCerts(false)}
                        >
                          <FormattedMessage
                            id="completion_certificates"
                            defaultMessage="Completion Certifcate(s)"
                          />
                        </button>
                      }
                    </>
                  }
                />
              ) : (
                <GroupParticipantListCardView
                  students={filteredParticipants}
                  group={group}
                  onStudentLoadingChange={handleParticipantLoadingChange}
                  onSelectionChange={handleSelectionChange}
                  onAllSelectionChange={handleSelectAllChange}
                  updateConsents={updateConsents}
                  removeStudents={removeParticipants}
                  printCerts={printCerts}
                  setSelectedStudents={setSelectedParticipants}
                  actions={
                    <>
                      <Select displayEmpty placeholder="Action">
                        <MenuItem disabled>
                          <FormattedMessage
                            id="actions"
                            defaultMessage="Actions"
                          />
                        </MenuItem>

                        <MenuItem
                          disabled={
                            !canManageParticipants ||
                            !selectedParticipants ||
                            !selectedParticipants.length
                          }
                          value="consent_received"
                          onClick={updateConsents}
                        >
                          <FormattedMessage
                            id="consent_received"
                            defaultMessage="Consent Form Received"
                          />
                        </MenuItem>

                        <MenuItem
                          disabled={
                            !canManageParticipants ||
                            !selectedParticipants ||
                            !selectedParticipants.length
                          }
                          value="remove_participants"
                          onClick={removeParticipants}
                        >
                          <FormattedMessage
                            id="remove_participants"
                            defaultMessage="Remove Participants"
                          />
                        </MenuItem>

                        {(group.hasParticipationCertificate || (group.hasCompletionCertificate && group.status == GroupStatuses.Completed)) &&
                          <MenuItem>
                            <span className="menuGroupHead">
                              <FormattedMessage
                                id="print"
                                defaultMessage="Print"
                              />
                            </span>
                          </MenuItem>
                        }

                        {group.hasParticipationCertificate && GroupStatusesAllowedToPrint.includes(group.status) &&
                          <MenuItem
                            disabled={
                              !canManageParticipants ||
                              !selectedParticipants ||
                              !selectedParticipants.length
                            }
                            value="participation_certificates"
                            onClick={() => printCerts(true)}
                          >
                            <FormattedMessage
                              id="participation_certificates"
                              defaultMessage="Participation Certificate(s)"
                            />
                          </MenuItem>
                        }

                        {group.hasCompletionCertificate && group.status == GroupStatuses.Completed &&
                          <MenuItem
                            disabled={
                              !canManageParticipants ||
                              !selectedParticipants ||
                              !selectedParticipants.length ||
                              !canPrintCompletionCerts
                            }
                            value="completion_certificates"
                            onClick={() => printCerts(false)}
                          >
                            <FormattedMessage
                              id="completion_certificates"
                              defaultMessage="Completion Certifcate(s)"
                            />
                          </MenuItem>
                        }
                      </Select>
                    </>
                  }
                />
              )}
            </>
          ) : (
            <FormattedMessage
              id="no_participants"
              defaultMessage="No participants match your criteria."
            />
          )}
        </UserCan>
      )}
    </div>
  );
};

export default GroupParticipantList;
