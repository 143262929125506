export enum SaveState {
  None,
  Saving,
  ValidationFailed,
  Error,
  Complete
}

export enum RecordType {
  New,
  Existing
}

export default class FormStatus {

  public readonly recordType: RecordType;
  public readonly saveState: SaveState;
  public readonly dirtiness: DirtinessManager;
  public readonly validity: ValidityManager;
  public readonly errors: MessageManager;
  public readonly warnings: MessageManager;
  public readonly messages: MessageManager;

  constructor(formStatus?: FormStatus) {
    this.recordType = formStatus?.recordType ?? RecordType.New;
    this.saveState = formStatus?.saveState ?? SaveState.None;
    this.dirtiness = formStatus?.dirtiness ? formStatus.dirtiness : new DirtinessManager();
    this.validity = formStatus?.validity ? formStatus.validity : new ValidityManager();
    this.errors = formStatus?.errors ? formStatus.errors : new MessageManager();
    this.warnings = formStatus?.warnings ? formStatus.warnings : new MessageManager();
    this.messages = formStatus?.messages ? formStatus.messages : new MessageManager();
  }
}

export class DirtinessManager {

  private keyedDirtiness: Map<string, boolean> = new Map<string, boolean>();

  public isDirty(...keys: string[]): boolean {
    if (keys.length > 0) {
      const compoundKey = keys.join(".");

      return this.keyedDirtiness.get(compoundKey) ?? false;
    }
    else {
      const dirtiness = Array.from(this.keyedDirtiness.values());

      if (dirtiness.length <= 0) {
        return false;
      }

      return dirtiness.some(v => v === true);
    }
  }

  public setDirty(isDirty: boolean, ...keys: string[]): void {
    if (keys.length > 0) {
      const compoundKey = keys.join(".");

      this.keyedDirtiness.set(compoundKey, isDirty);
    }
  }

  public reset(...keys: string[]): void {
    if (keys.length > 0) {
      const compoundKey = keys.join(".");

      this.keyedDirtiness.delete(compoundKey);
    }
    else {
      this.keyedDirtiness.clear();
    }
  }
}

export class ValidityManager {

  private unkeyedValidity?: boolean;
  private keyedValidity: Map<string, boolean> = new Map<string, boolean>();

  public isValid(...keys: string[]): boolean {
    if (keys.length > 0) {
      const compoundKey = keys.join(".");

      return this.keyedValidity.get(compoundKey) ?? true;
    }
    else {
      if (this.unkeyedValidity === true) {
        return true;
      }
      else if (this.unkeyedValidity === false) {
        return false;
      }

      const validity = Array.from(this.keyedValidity.values());

      if (validity.length <= 0) {
        return true;
      }

      return validity.every(v => v === true);
    }
  }

  public setValidity(isValid: boolean, ...keys: string[]): void {
    if (keys.length > 0) {
      const compoundKey = keys.join(".");

      this.keyedValidity.set(compoundKey, isValid);

      if (!isValid && this.unkeyedValidity) {
        this.unkeyedValidity = undefined;
      }
    }
    else {
      this.unkeyedValidity = isValid;
    }
  }

  public getValidity(keys?: string[]): Map<string, boolean> {
    let map: Map<string, boolean> = new Map();
    if (keys && keys.length > 1) {
      keys.forEach(key => {
        if (this.keyedValidity.has(key)) {
          const value = this.keyedValidity.get(key);
          map.set(key, value!);
        }
      });
    } else {
      map = this.keyedValidity;
    }
    return map;
  }

  public getInvalid(): Map<string, boolean> {
    const invalidMap: Map<string, boolean> = new Map();
    this.keyedValidity.forEach((value, key) => {
      if (!value) {
        invalidMap.set(key, value);
      }
    });
    return invalidMap;
  }

  public reset(...keys: string[]): void {
    if (keys.length > 0) {
      const compoundKey = keys.join(".");

      this.keyedValidity.delete(compoundKey);
    }
    else {
      this.unkeyedValidity = undefined;
      this.keyedValidity.clear();
    }
  }
}

export class MessageManager {

  private unkeyedMessages: Set<string> = new Set<string>();
  private keyedMessages: Map<string, Set<string>> = new Map<string, Set<string>>();

  public getMessages(...keys: string[]): string[] {
    if (keys.length > 0) {
      const compoundKey = keys.join(".");

      if (this.keyedMessages.has(compoundKey)) {
        return Array.from(this.keyedMessages.get(compoundKey)!);
      }
      else {
        return [];
      }
    }
    else {
      const messages = new Set<string>();

      this.unkeyedMessages.forEach(m => messages.add(m));

      return Array.from(messages);
    }
  }

  public addMessage(message: string, ...keys: string[]): void {
    if (keys.length > 0) {
      const compoundKey = keys.join(".");

      if (this.keyedMessages.has(compoundKey)) {
        this.keyedMessages.get(compoundKey)!.add(message);
      }
      else {
        const keyedMessageSet = new Set<string>();

        keyedMessageSet.add(message);

        this.keyedMessages.set(compoundKey, keyedMessageSet);
      }
    }
    else {
      this.unkeyedMessages.add(message);
    }
  }

  public removeMessage(message: string, ...keys: string[]): void {
    if (keys.length > 0) {
      const compoundKey = keys.join(".");

      if (this.keyedMessages.has(compoundKey)) {
        this.keyedMessages.get(compoundKey)!.delete(message);
      }
    }
    else {
      this.unkeyedMessages.delete(message);

      this.keyedMessages.forEach(km => km.delete(message));
    }
  }

  public clearMessages(...keys: string[]): void {
    if (keys.length > 0) {
      const compoundKey = keys.join(".");

      if (this.keyedMessages.has(compoundKey)) {
        this.keyedMessages.delete(compoundKey);
      }
    }
    else {
      this.unkeyedMessages.clear();
      this.keyedMessages.clear();
    }
  }
}