import GroupTimeCreationDto from "../model/GroupTimeCreationDto";
import MeetingTimeDto from "../model/MeetingTimeDto";
import { IDtoToDtoConverter } from "./IDtoConverter";

export class MeetingTimeDtoToGroupTimeCreationDto implements IDtoToDtoConverter<MeetingTimeDto, GroupTimeCreationDto> {
  public toDto = (dto: MeetingTimeDto): GroupTimeCreationDto => {
    const time = new GroupTimeCreationDto({
      dayOfWeek: dto.dayOfWeek,
      startTime: dto.startTime ? dto.startTime.toISOString() : undefined,
      endTime: dto.endTime ? dto.endTime.toISOString() : undefined,
      notes: "",
      room: ""
    });

    return time;
  };
}