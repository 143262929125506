import React from "react";
import { Outlet } from "react-router-dom";
import AppFrame from "components/app-frame/app-frame";


const UnprotectedRoute = () => {

  return (
    <AppFrame showProtectedNav={false}>
      <Outlet />
    </AppFrame>
  );
};

export default UnprotectedRoute;