//Axios includes a "[]"" at then end of each array parameter, 
//this causes the array to not be recieved correctly in the back end.
//This serializer will format array params in a way the backend expects
//can be used by passing it to the customSerializer property in the request config
export const customParamSerializer = (params) => {

  const keys = Object.keys(params);

  let queryString = "";

  keys.forEach((key) => {

    if (Array.isArray(params[key])) {
      params[key].forEach(arrayMember => queryString += `${key}=${encodeURIComponent(arrayMember)}&`);
    } else {
      queryString += `${key}=${encodeURIComponent(params[key])}&`;
    }

  });

  return queryString ? queryString.slice(0, -1) : queryString;
};