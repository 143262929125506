import create from "zustand";
import type { FullGroup } from "lib/models-v2/LeadersGroupResponse";

export type GroupStore = {
  activeUnitNumber: number | null;
  groups: FullGroup[]
  filteredGroups: FullGroup[],
  userGroupsFetchCompleted: boolean;
  setGroups: (groups: FullGroup[], userGroupsFetchCompleted?: boolean) => void;
  setFilteredGroups: (groups: FullGroup[]) => void;
  addGroup: (group: FullGroup) => void;
  getGroup: (groupId: number) => FullGroup | null;
  editGroup: (group: FullGroup) => void;
  hasGroup: (groupId: number) => boolean;
  setUserGroupsFetchCompleted: (fetched: boolean) => void;
  setActiveUnitNumber: (unitNumber: number) => void;
};

const useGroupStore = create<GroupStore>(
  (set, get): GroupStore => ({
    activeUnitNumber: null,
    groups: [],
    filteredGroups: [],
    userGroupsFetchCompleted: false,
    setGroups: (groups: FullGroup[], userGroupsFetchCompleted?: boolean) => {
      if (userGroupsFetchCompleted != undefined && userGroupsFetchCompleted != null) {
        set(() => ({
          groups,
          userGroupsFetchCompleted
        }));
      } else {
        set(() => ({
          groups
        }));
      }
    },
    setFilteredGroups: (groups: FullGroup[]) =>
      set(() => ({
        filteredGroups: groups
      })),
    addGroup: (group: FullGroup) =>
      set((state) => {
        return {
          groups: [...state.groups, group],
        };
      }),
    getGroup: (groupId: number) => {
      return get().groups.find(group => group.id == groupId) || null;
    },
    editGroup: (group: FullGroup) =>
      set((state) => {
        const groupIndex = state.groups.findIndex((g) => g.id === group.id);
        if (groupIndex != -1) {
          state.groups[groupIndex] = group;
        } else {
          state.groups.push(group);
        }
        return {
          groups: [...state.groups],
        };
      }),
    hasGroup: (groupId: number) => {
      const hasGroup = get().groups.some(group => group.id == groupId);
      return hasGroup;
    },
    setUserGroupsFetchCompleted: (fetched: boolean) =>
      set(() => ({ userGroupsFetchCompleted: fetched })),
    setActiveUnitNumber: (unitNumber: number | null) =>
      set(() => ({ activeUnitNumber: unitNumber })),
  })
);

export default useGroupStore;