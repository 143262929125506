import "./group-finder-list-table.scss";

import { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { getHHMM, getLocaleDateString, getWeekdayName } from "utilities/time";
import GroupStatus from "components/group-status/group-status";
import GroupHasRoomV2 from "components/group-has-room-v2/group-has-room-v2";
import GroupStatuses from "constants/group-constants";
import Table from "components/table/table";
import SortableColumn from "components/table/sortable-column/sortable-column";
import Column from "components/table/column/column";
import Pagination from "components/table/pagination/pagination";
import { Button } from "@mui/material";
import usePreloadStore, { getGroupTypesAsList, getLanguages } from "datastore/preload";
import Badge from "components/badge/badge";
import { Link } from "react-router-dom";
import { sortGroupMeetingTimes } from "utilities/sortings";

const GroupFinderListTable = (props) => {

  const [groups, setGroups] = useState([]);
  const intl = useIntl();
  const allLanguages = usePreloadStore(getLanguages);

  const groupTypes = usePreloadStore(getGroupTypesAsList);

  useEffect(
    () => {
      const newGroups = props.groups.map(group => ({
        ...group,
        language: groupTypes?.find(course => course.id == group.courseId)?.languageName,
        meetingTimes: sortGroupMeetingTimes(group.meetingTimes)
      }));
      setGroups(newGroups ?? []);
    },
    [props.groups]
  );

  const handleCourseSignup = (event, record) => {
    if (props.onCourseSignup) {
      props.onCourseSignup(record);
    }
  };

  return (
    <div className="group-finder-list-table-wrapper">
      <Table rows={groups} className="group-finder-list-table">
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "group_type", defaultMessage: "Group Type" })} />
          <SortableColumn.Row
            accessor="programName"
            render={(programName, group) => (
              <div className="group-name">
                <Link to={`/group-details/${group.locationId}/${group.programId}/${group.id}`}>
                  {programName}
                </Link>
              </div>
            )}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "language", defaultMessage: "Language" })} />
          <SortableColumn.Row
            accessor="groupLanguages"
            render={(languages, group) => (
              <div className="group-languages">
                {languages && languages.length > 0 ?
                  languages.map(
                    (language, index) => {
                      let groupLanguage = allLanguages.find(lang => lang.id == language.languageId)?.description;
                      return (
                        <div className="group-language" key={`${group.id}-language-${index}`}>
                          <span>{groupLanguage ? groupLanguage : <span><FormattedMessage id="multilingual" defaultMessage="Multilingual" /></span>}</span>
                        </div>
                      );
                    }
                  )
                  :
                  <span><FormattedMessage id="multilingual" defaultMessage="Multilingual" /></span>
                }
              </div>
            )}
            sort={(a, b) => {
              const aTemp = Array.from(a ?? []).sort();
              const bTemp = Array.from(b ?? []).sort();

              const aZerothLanguageId = aTemp[0]?.languageId ?? 0;
              const bZerothLanguageId = bTemp[0]?.languageId ?? 0;

              const aDescription = aZerothLanguageId != 0 ? allLanguages.find(lang => lang.id == aZerothLanguageId)?.description ?? "Multilingual" : "Multilingual";
              const bDescription = bZerothLanguageId != 0 ? allLanguages.find(lang => lang.id == bZerothLanguageId)?.description ?? "Multilingual" : "Multilingual";

              return aDescription.localeCompare(bDescription);
            }}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "ages", defaultMessage: "Ages" })} />
          <SortableColumn.Row
            render={(record) => (
              <div className="group-class-types">
                {(() => {
                  if(record.isAdultOnly){
                    return (<span>{intl.formatMessage({ id: "group_age_type_adult", defaultMessage: "Adult only" })}</span>);
                  } else if(record.isYouthOnly) {
                    return (<span>{intl.formatMessage({ id: "group_age_type_youth", defaultMessage: "Youth only" })}</span>);
                  } else {
                    return (<span>{intl.formatMessage({ id: "group_age_type_combined", defaultMessage: "Combined" })}</span>);
                  }
                })()}
              </div>
            )}
            sort={(a, b) => {
              const getAgeTypeTranslation = (group) => {
                if (group.isYouthOnly) {
                  return intl.formatMessage({ id: "group_age_type_youth", defaultMessage: "Youth only" });
                }
                if (group.isAdultOnly) {
                  return intl.formatMessage({ id: "group_age_type_adult", defaultMessage: "Adult only" });
                }
                return intl.formatMessage({ id: "group_age_type_combined", defaultMessage: "All Ages" });
              };

              const aTemp = getAgeTypeTranslation(a);

              const bTemp = getAgeTypeTranslation(b);

              return aTemp.localeCompare(bTemp);
            }}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "location", defaultMessage: "Location" })} />
          <SortableColumn.Row
            render={(record) => (
              record.isPending
                ? (
                  <div className="group-address" translate="no">
                    <span>{intl.formatMessage({ id: "pending", defaultMessage: "Pending" })}</span>
                  </div>
                )
                : (
                  <>
                    <div className="group-address" translate="no">
                      <div className="group-address-line"><span>{record.address1}</span></div>
                      <div className="group-address-line"><span>{record.address2}</span></div>
                      <div className="group-address-line"><span>{record.city}, {record.state} {record.postalCode}</span></div>
                      <div className="group-address-line"><span>{record.country}</span></div>
                    </div>
                    {
                      record.isVirtual
                      && (
                        <Badge type="virtual"><FormattedMessage id="virtual" defaultMessage="Virtual" /></Badge>
                      )
                    }
                  </>
                )
            )}
            sort={(a, b) => {
              const firstLineComparison = (a.address1 ?? "").localeCompare(b.address1 ?? "");
              const secondLineComparison = (a.address2 ?? "").localeCompare(b.address2 ?? "");
              const thirdLineComparison = (a.city ?? a.state ?? a.postalCode ?? "").localeCompare(b.city ?? b.state ?? b.postalCode ?? "");
              const fourthLineComparison = (a.country ?? "").localeCompare(b.country ?? "");

              if (!firstLineComparison) {
                if (!secondLineComparison) {
                  if (!thirdLineComparison) {
                    return fourthLineComparison;
                  }

                  return thirdLineComparison;
                }

                return secondLineComparison;
              }

              return firstLineComparison;
            }}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "day_and_time", defaultMessage: "Day and Time" })} />
          <SortableColumn.Row
            render={(record) => (
              record.isPending
                ? (
                  <div className="group-dates-and-times">
                    <span>{intl.formatMessage({ id: "pending", defaultMessage: "Pending" })}</span>
                  </div>
                )
                : (
                  record.startDate
                  && (
                    <div className="group-dates-and-times">
                      <div className="group-dates">
                        <time dateTime={record.startDate}>{getLocaleDateString(record.startDate)}</time>
                        <span>-</span>
                        {
                          record.endDate
                            ? <time dateTime={record.endDate}>{getLocaleDateString(record.endDate)}</time>
                            : <span><FormattedMessage id="open" defaultMessage="Open" /></span>
                        }
                      </div>

                      <div className="group-meeting-times">
                        {
                          record.meetingTimes
                          && (
                            record.meetingTimes.map((day, index) => {
                              const weekday = getWeekdayName(day.dayOfWeek);

                              const formattedWeekday = intl.formatMessage({ id: weekday.toLowerCase(), defaultMessage: weekday });
                              let formattedStartTime = getHHMM(day.startDate);
                              let formattedEndTime;
                              let formattedTimeSpacer;

                              if (day.endDate) {
                                formattedTimeSpacer = "-";
                                formattedEndTime = getHHMM(day.endDate);
                              }

                              // Spaces are inserted after non-empty and not-last spans by CSS
                              return (
                                <div className="group-meeting-time" key={`${record.id}-meeting-time-${index}`}>
                                  <span>{formattedWeekday}</span>
                                  <span>{formattedStartTime}</span>
                                  <span>{formattedTimeSpacer}</span>
                                  <span>{formattedEndTime}</span>
                                </div>
                              );
                            })
                          )
                        }
                      </div>
                    </div>
                  )
                )
            )}
            sort={(a, b) => {
              const aStartDate = a.startDate ? new Date(getLocaleDateString(a.startDate)) : new Date(-8640000000000000);
              const bStartDate = b.startDate ? new Date(getLocaleDateString(b.startDate)) : new Date(-8640000000000000);

              const aEndDate = a.endDate ? new Date(getLocaleDateString(a.endDate)) : new Date(-8640000000000000);
              const bEndDate = b.endDate ? new Date(getLocaleDateString(b.endDate)) : new Date(-8640000000000000);

              const startDateComparison = aStartDate > bStartDate ? 1 : (aStartDate < bStartDate ? -1 : 0);
              const endDateComparison = aEndDate > bEndDate ? 1 : (aEndDate < bEndDate ? -1 : 0);

              if (!startDateComparison) {
                if (!endDateComparison) {
                  const aMeetingTime = a.meetingTimes && Array.isArray(a.meetingTimes) && a.meetingTimes.length > 0 ? a.meetingTimes[0] : null;
                  const bMeetingTime = b.meetingTimes && Array.isArray(b.meetingTimes) && b.meetingTimes.length > 0 ? b.meetingTimes[0] : null;

                  if (!aMeetingTime && !bMeetingTime) {
                    return 0;
                  }
                  else if (!aMeetingTime && bMeetingTime) {
                    return 1;
                  }
                  else if (aMeetingTime && !bMeetingTime) {
                    return -1;
                  }

                  const aWeekday = aMeetingTime.dayOfWeek ? aMeetingTime.dayOfWeek : 0;
                  const bWeekday = bMeetingTime.dayOfWeek ? bMeetingTime.dayOfWeek : 0;

                  const aStartTime = aMeetingTime.startTime ? getHHMM(aMeetingTime.startDate) : "";
                  const bStartTime = bMeetingTime.startTime ? getHHMM(bMeetingTime.startDate) : "";

                  const aEndTime = aMeetingTime.endTime ? getHHMM(aMeetingTime.endDate) : "";
                  const bEndTime = bMeetingTime.endTime ? getHHMM(bMeetingTime.endDate) : "";

                  const weekdayComparison = aWeekday - bWeekday;
                  const startTimeComparison = aStartTime.localeCompare(bStartTime);
                  const endTimeComparison = aEndTime.localeCompare(bEndTime);

                  if (!weekdayComparison) {
                    if (!startTimeComparison) {
                      return endTimeComparison;
                    }

                    return startTimeComparison;
                  }

                  return weekdayComparison;
                }

                return endDateComparison;
              }

              return startDateComparison;
            }}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "status", defaultMessage: "Status" })} />
          <SortableColumn.Row
            render={(record) => {
              const status = record.isPending ? GroupStatuses.Pending : record.status;

              return (
                <div className="group-status">
                  <GroupStatus status={status} uppercase={false} />
                </div>
              );
            }}
            sort={(a, b) => {
              const getStatusTranslation = (group) => {
                if (group.isPending) {
                  return intl.formatMessage({ id: "pending", defaultMessage: "Pending" });
                }
                switch (group.status) {
                  case GroupStatuses.Completed:
                    return intl.formatMessage({ id: "completed", defaultMessage: "Completed" });
                  case GroupStatuses.Concluded:
                    return intl.formatMessage({ id: "completed", defaultMessage: "Completed" });
                  case GroupStatuses.Cancelled:
                    return intl.formatMessage({ id: "cancelled", defaultMessage: "Cancelled" });
                  case GroupStatuses.InProgress:
                    return intl.formatMessage({ id: "in_progress", defaultMessage: "In Progress" });
                  case GroupStatuses.Upcoming:
                    return intl.formatMessage({ id: "upcoming", defaultMessage: "Upcoming" });
                }
                return "";
              };

              const aTemp = getStatusTranslation(a);

              const bTemp = getStatusTranslation(b);

              return aTemp.localeCompare(bTemp);
            }}
          />
        </SortableColumn>
        <Column>
          <Column.Row
            render={(record) => (
              <div className="group-actions">
                <GroupHasRoomV2 group={record}>
                  <Button
                    variant="outlined"
                    onClick={(event) => handleCourseSignup(event, record)}
                  >
                    <FormattedMessage id="signup" defaultMessage="Sign Up" />
                  </Button>
                </GroupHasRoomV2>
              </div>
            )}
          />
        </Column>
        <Pagination perPageOptions={[{ label: "All", value: -1 }, 2, 5, 10]} />
      </Table>
    </div>
  );
};

export default GroupFinderListTable;