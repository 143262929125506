import API from "api/requests";
import getLanguage, { setStoredLanguage } from "utilities/language";
import { AppActionTypes } from "../datastore/app";
import { UserActionTypes, userFound } from "../datastore/user";
import nextId from "react-id-generator";

const updateFromAPI = async (dispatch, apiFunction, parameters, dispatchType) => {
  try {
    let response = await apiFunction(...parameters);
    if (response instanceof Error) {
      throw response;
    } else if (dispatchType) {
      dispatch({
        type: dispatchType,
        payload: response
      });
    }
    return response;
  } catch (error) {
    return error;
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkAuthenticated: async (shouldDispatch = true) => {
      if (shouldDispatch) {
        return await updateFromAPI(dispatch, API.checkAuthenticated, [], UserActionTypes.AUTHENTICATION_CHANGED);
      } else {
        return await updateFromAPI(dispatch, API.checkAuthenticated, []);
      }
    },
    getUser: async () => {
      dispatch(getCurrentUser());
    },
    validateLogin: (login) => {
      return updateFromAPI(dispatch, API.validateLogin, [login]);
    },
    updateLayout: async (layout) => {
      dispatch({
        type: AppActionTypes.UPDATE_LAYOUT,
        payload: layout
      });
    },
    getUserGroups: async () => {
      return updateFromAPI(dispatch, API.getUserGroups, [], AppActionTypes.UPDATE_GROUPS);
    },
    getProviderGroups: async (providerId, mapBounds, mapCenter, courseIds, token, excludeCompletedCancelled, excludeFull, locationsLimit) => {
      return updateFromAPI(dispatch, API.getProviderGroups, [providerId, mapBounds, mapCenter, courseIds, token, excludeCompletedCancelled, excludeFull, locationsLimit]);
    },
    updateCourses: async () => {
      return updateFromAPI(dispatch, API.getCourses, [], AppActionTypes.UPDATE_COURSES);
    },
    getProviderCourses: async (providerId) => {
      return updateFromAPI(dispatch, API.getProviderCourses, [providerId]);
    },
    getFacilitators: async (providerId, stakeUnitNumber) => {
      return updateFromAPI(dispatch, API.getFacilitators, [providerId, stakeUnitNumber], AppActionTypes.UPDATE_FACILITATORS);
    },
    getStakeMembers: async (stakeUnitNumber) => {
      let stakeMembers = await updateFromAPI(dispatch, API.getStakeMembers, [stakeUnitNumber]);
      dispatch({
        type: AppActionTypes.UPDATE_STAKE_MEMBERS,
        payload: { stakeUnitNumber, stakeMembers }
      });
      return stakeMembers;
    },
    getStakeMemberHasChurchAccount: async (cmisId) => {
      return await updateFromAPI(dispatch, API.getStakeMemberHasChurchAccount, [cmisId]);
    },
    getGroupWithProtectedData: async (locationId, programId, groupId, includeOutcomes = false) => {
      return updateFromAPI(dispatch, API.getGroupWithProtectedData, [locationId, programId, groupId, includeOutcomes]);
    },
    saveOutcomes: async (locationId, programId, groupId, outcomes) => {
      return updateFromAPI(dispatch, API.saveOutcomes, [locationId, programId, groupId, outcomes]);
    },
    getGroup: async (locationId, programId, groupId) => {
      const newGroupDataResult = updateFromAPI(dispatch, API.getGroup, [locationId, programId, groupId]);
      if (newGroupDataResult) {
        dispatch({
          type: AppActionTypes.EDIT_GROUP,
          payload: newGroupDataResult
        });
      }
      return newGroupDataResult;
    },
    getEnrollmentExists: async (locationId, programId, groupId) => {
      return updateFromAPI(dispatch, API.getEnrollmentExists, [locationId, programId, groupId]);
    },
    getSites: async (providerId) => {
      return updateFromAPI(dispatch, API.getSites, [providerId]);
    },
    createGroup: async (groupData) => {
      const saveGroupResult = await updateFromAPI(dispatch, API.createGroup, [groupData]);
      if (saveGroupResult) {
        const newGroupDetails = await updateFromAPI(dispatch, API.getGroup, [saveGroupResult.locationId, groupData.programId, saveGroupResult.id]);
        if (newGroupDetails) {
          dispatch({
            type: AppActionTypes.CREATE_GROUP,
            payload: newGroupDetails
          });
        }
      }
      return saveGroupResult;
    },
    registerLimitedStudent: async (groupId, providerId, registrationData) => {
      return updateFromAPI(dispatch, API.registerLimitedStudent, [groupId, providerId, registrationData]);
    },
    registerStudent: async (providerId, locationId, programId, groupId, trackId, termId, registrationData) => {
      return updateFromAPI(dispatch, API.registerStudent, [providerId, locationId, programId, groupId, trackId, termId, registrationData]);
    },
    updateGroup: async (locationId, programId, groupId, groupData) => {
      const updateGroupResult = await updateFromAPI(dispatch, API.updateGroup, [locationId, programId, groupId, groupData]);
      const editedGroupDetails = await updateFromAPI(dispatch, API.getGroup, [updateGroupResult.locationId, groupData.programId, updateGroupResult.id]);
      if (updateGroupResult) {
        dispatch({
          type: AppActionTypes.EDIT_GROUP,
          payload: editedGroupDetails
        });
      }
      return updateGroupResult;
    },
    cancelGroup: async (locationId, programId, groupId, trackId) => {
      const newGroupDataResult = updateFromAPI(dispatch, API.cancelGroup, [locationId, programId, groupId, trackId]);
      if (newGroupDataResult) {
        dispatch({
          type: AppActionTypes.EDIT_GROUP,
          payload: newGroupDataResult
        });
      }
      return newGroupDataResult;
    },
    concludeGroup: async (locationId, programId, groupId, trackId) => {
      const newGroupDataResult = updateFromAPI(dispatch, API.concludeGroup, [locationId, programId, groupId, trackId]);
      if (newGroupDataResult) {
        dispatch({
          type: AppActionTypes.EDIT_GROUP,
          payload: newGroupDataResult
        });
      }
      return newGroupDataResult;
    },
    completeStudents: async (locationId, programId, groupId, studentIds) => {
      return updateFromAPI(dispatch, API.completeStudents, [locationId, programId, groupId, studentIds]);
    },
    dropStudents: async (locationId, programId, groupId, studentIds) => {
      return updateFromAPI(dispatch, API.dropStudents, [locationId, programId, groupId, studentIds]);
    },
    completeLimitedStudents: async (locationId, programId, groupId, studentIds) => {
      return updateFromAPI(dispatch, API.completeLimitedStudents, [locationId, programId, groupId, studentIds]);
    },
    dropLimitedStudents: async (groupId, studentIds) => {
      return updateFromAPI(dispatch, API.dropLimitedStudents, [groupId, studentIds]);
    },
    updateConsents: async (locationId, programId, groupId, studentIds) => {
      return updateFromAPI(dispatch, API.updateConsents, [locationId, programId, groupId, studentIds]);
    },
    updateLimitedConsents: async (groupId, studentIds) => {
      return updateFromAPI(dispatch, API.updateLimitedConsents, [groupId, studentIds]);
    },
    notify: (notification) => {
      dispatch(addNotification(notification));
    },
    dismissNotice: (noticeId) => {
      dispatch(removeNotification(noticeId));
    },
    dismissAllNotices: () => {
      dispatch({
        type: AppActionTypes.UPDATE_NOTIFICATIONS,
        payload: []
      });
    },
    alert: (alert) => {
      dispatch(addAlert(alert));
    },
    dismissAlert: (index) => {
      dispatch(removeAlert(index));
    },
    prompt: (prompt) => {
      dispatch(addPrompt(prompt));
    },
    dismissPrompt: (index) => {
      dispatch(removePrompt(index));
    },
    updateProviders: () => {
      return updateFromAPI(dispatch, API.getProviders, [], AppActionTypes.UPDATE_PROVIDERS);
    },
    updateStudents: () => {
      return updateFromAPI(dispatch, API.getStudents, [], AppActionTypes.UPDATE_STUDENTS);
    },
    updateLanguage: (localeCode) => {
      const payload = getLanguage(localeCode);
      setStoredLanguage(localeCode);
      dispatch({
        type: AppActionTypes.UPDATE_LANGUAGE,
        payload
      });
    },
    getProviderTermsAndConditions: (providerId, programId) => {
      return updateFromAPI(dispatch, API.getProviderTermsAndConditions, [providerId, programId]);
    },
    updateEnrollments: (enrollments) => {
      dispatch({
        type: AppActionTypes.UPDATE_ENROLLMENTS,
        payload: enrollments
      });
    },
    editEnrollmentStatuses: (enrollmentsToUpdate) => { //array of {studentId: number, groupId: number, status: string, isLimited: boolean}
      dispatch({
        type: AppActionTypes.EDIT_ENROLLMENT_STATUSES,
        payload: enrollmentsToUpdate
      });
    },
    editGroup: (group) => { //should be a GroupDto
      dispatch({
        type: AppActionTypes.EDIT_GROUP,
        payload: group
      });
    },
    updateParticipants: (updatedParticipants) => {
      dispatch({
        type: AppActionTypes.UPDATE_PARTICIPANTS,
        payload: updatedParticipants
      });
    },
  };
};

export function getCurrentUser() {
  return async (dispatch, getState) => {
    const userState = getState().userReducer;
    let user;
    if (userState.isAuthenticated) {
      const result = await updateFromAPI(dispatch, API.getUser, []);
      if (result instanceof Error) {
        user = {
          signOnError: true
        };
      } else {
        user = result;
      }
    }
    dispatch(userFound(user));
  };
}

function addNotification(notification) {
  notification.id = nextId();
  return (dispatch) => {
    dispatch({
      type: AppActionTypes.UPDATE_NOTIFICATIONS,
      //For now only allow the most recent notification
      //payload: [...getState().appReducer.notifications, notification]
      payload: [notification]
    });
  };
}

function removeNotification(noticeId) {
  return (dispatch, getState) => {
    const newNotifications = [...getState().appReducer.notifications];
    const index = newNotifications.findIndex(x => x.id == noticeId);
    newNotifications.splice(index, 1);
    dispatch({
      type: AppActionTypes.UPDATE_NOTIFICATIONS,
      payload: newNotifications
    });
  };
}

function addAlert(alert) {
  alert.id = nextId();
  return (dispatch, getState) => {
    dispatch({
      type: AppActionTypes.UPDATE_ALERTS,
      payload: [...getState().appReducer.alerts, alert]
    });
  };
}

function removeAlert(alertId) {
  return (dispatch, getState) => {
    const newAlerts = [...getState().appReducer.alerts];
    const index = newAlerts.findIndex(x => x.id == alertId);
    newAlerts.splice(index, 1);
    dispatch({
      type: AppActionTypes.UPDATE_ALERTS,
      payload: newAlerts
    });
  };
}

function addPrompt(prompt) {
  return (dispatch, getState) => {
    prompt.id = nextId();
    dispatch({
      type: AppActionTypes.UPDATE_PROMPTS,
      payload: [...getState().appReducer.prompts, prompt]
    });
  };
}

function removePrompt(promptId) {
  return (dispatch, getState) => {
    const newPrompts = [...getState().appReducer.prompts];
    const index = newPrompts.findIndex(x => x.id == promptId);
    newPrompts.splice(index, 1);
    dispatch({
      type: AppActionTypes.UPDATE_PROMPTS,
      payload: newPrompts
    });
  };
}

export default mapDispatchToProps;