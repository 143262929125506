export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6
}

// eslint-disable-next-line no-redeclare
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DayOfWeek {
  export function toString(value: DayOfWeek): string {
    return DayOfWeek[value];
  }

  export function parseValue(value: number): DayOfWeek {
    const values = Object.values(DayOfWeek).filter(v => !isNaN(Number(v))) as DayOfWeek[];

    if (!values.includes(value)) {
      throw new Error(`Invalid DayOfWeek value: ${value}`);
    }

    return value;
  }

  export function parseName(name: string): DayOfWeek {
    const keys = Object.keys(DayOfWeek).filter(v => isNaN(Number(v)));
    const values = Object.values(DayOfWeek).filter(v => !isNaN(Number(v))) as DayOfWeek[];

    const index = keys.indexOf(name);

    if (index === -1) {
      throw new Error(`Invalid DayOfWeek name: ${name}`);
    }

    return values[index];
  }
}