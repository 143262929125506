import dayjs from "dayjs";

export interface IFacilitatorCreationDtoCreationValues {
  id: number;
  firstName: string;
  lastName: string;
  birthDate?: string;
  gender: string;
}

export interface IFacilitatorCreationDto {
  id: number;
  firstName: string;
  lastName: string;
  birthDate?: Date;
  gender: string;
}

export default class FacilitatorCreationDto implements IFacilitatorCreationDto {

  public id: number;
  public firstName: string;
  public lastName: string;
  public birthDate?: Date;
  public gender: string;

  constructor(creationValues: IFacilitatorCreationDtoCreationValues) {
    this.id = creationValues.id;
    this.firstName = creationValues.firstName;
    this.lastName = creationValues.lastName;
    this.birthDate = creationValues.birthDate ? dayjs(creationValues.birthDate).toDate() : undefined;
    this.gender = creationValues.gender;
  }

  public export(): IFacilitatorCreationDtoCreationValues {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      birthDate: this.birthDate?.toISOString(),
      gender: this.gender
    };
  }

  public clone(): FacilitatorCreationDto {
    return new FacilitatorCreationDto(this.export());
  }
}