import "./edit-participant-form.scss";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import validator from "validator";
import { useIntl, FormattedMessage } from "react-intl";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import { getParticipantGroupDetails, updateParticipant } from "lib/api/backend/requests/participant";
import Loading from "components/loading/loading";
import GroupStatus from "components/group-status/group-status";
import GroupStatuses, { GroupAgeTypes } from "constants/group-constants";
import { getLocaleDateString, getWeekdayName, getHHMM } from "utilities/time";
import { styled } from "@mui/material/styles";
import TermsAndConditions from "components/terms-and-conditions/terms-and-conditions";
import { GroupDetails } from "lib/models-v2";
import { useNavigate } from "react-router-dom";
import { ParticipantDto } from "lib/api/backend/model/participant/ParticipantDto";
import { ParticipantUpdateDto } from "lib/api/backend/model/participant/ParticipantUpdateDto";
import PhoneInput from "components/phone-input";
import { useDispatch } from "react-redux";
import dispatchers from "datastore/dispatcher";
import { Button, MenuItem } from "@mui/material";
import { NoticeCreation } from "lib/data/model";
import useGroupStore from "datastore/groups";
import { useFeatureFlagStore } from "components/feature-flag-enabled";
import FeatureFlags from "constants/feature-flags";

const NoBorderTextField = styled(TextField)<TextFieldProps>(() => ({
  ".MuiInput-root:before": {
    borderBottom: "0px",
    content: "''",
  },
  ".MuiInput-input": {
    width: "100%",
    overflowWrap: "break-word",
    background: "transparent",
  },
}));

interface ITranslationObject {
  id: string;
  default: string;
}

const givenNameValidationError = "giveName";
const surnameValidationError = "surname";
const ageRangeValidationError = "ageRange";
const emailValidationError = "email";
const phoneValidationError = "phone";
const missingContactValidationError = "missingContact";

interface ITextFieldSelectorProps {
  value?: string | number;
  label: ITranslationObject;
  className: string;
  error?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  select?: boolean
  children?: ReactNode;
  disabled?: boolean
}

const ParticipantTextField = (props: ITextFieldSelectorProps) => {
  const { className, value, label, error, onChange, select, children, disabled } = props;

  if (value != undefined) {
    return (
      <NoBorderTextField
        className={className}
        label={
          <FormattedMessage id={label.id} defaultMessage={label.default} />
        }
        value={value ?? ""}
        variant="standard"
        multiline={true}
        onChange={onChange}
        error={error != undefined ? error : false}
        select={select === true}
        disabled={disabled}
      >
        {children}
      </NoBorderTextField>
    );
  }

  return (
    <TextField
      className={className}
      label={<FormattedMessage id={label.id} defaultMessage={label.default} />}
      value={value ?? ""}
      variant="outlined"
      onChange={onChange}
      error={error != undefined ? error : false}
      select={select === true}
      disabled={disabled}
    >
      {children}
    </TextField>
  );
};

const EditParticipants: React.VFC = () => {
  const { studentId } = useParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const store = useGroupStore();
  const [group, setGroup] = useState<GroupDetails | null>();
  const [participant, setParticipant] = useState<ParticipantDto | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [phoneValid, setPhoneValid] = useState<boolean>(true);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [participantUpdateDto, setParticipantUpdateDto] = useState<ParticipantUpdateDto>({});
  const [editFFEnabled, setEditFFEnabled] = useState<boolean>(false);
  const originalParticipantRef = useRef<ParticipantDto | null>();
  const reduxDispatch = useDispatch();
  const notify = dispatchers(reduxDispatch).notify as (notice: NoticeCreation) => void;
  const hasFormErrors = formErrors.length > 0;
  const hasChanges = Object.keys(participantUpdateDto).length > 0;
  const featureFlagStore = useFeatureFlagStore();


  useEffect(() => {
    const getParticipantAndGroup = async () => {
      const studentNumber = parseInt(studentId!);
      const response = await getParticipantGroupDetails(studentNumber);
      if (response != null) {
        setParticipant(response.participant);
        originalParticipantRef.current = { ...response.participant };
        setGroup(response.groupDetails);
      }
      setIsLoading(false);
    };

    const getEditFeatureEnabled = async () => {
      const editFeatureFlag = await featureFlagStore.getFeatureFlag(FeatureFlags.QuickRegEditParticipants);
      setEditFFEnabled(editFeatureFlag?.enabled ?? false);
    };

    getParticipantAndGroup();
    getEditFeatureEnabled();
  }, []);

  useEffect(() => {
    validate();
    setParticipantUpdateDto(buildParticipantUpdateDto());
  }, [participant]);

  const getGroupAgeRange = () => {
    if (!group?.isAdultOnly && !group?.isYouthOnly)
      return intl.formatMessage({
        id: "group_age_type_combined",
        defaultMessage: "All Ages",
      });

    if (group?.isAdultOnly)
      return intl.formatMessage({
        id: "group_age_type_adult",
        defaultMessage: "Adult only, group only for adults",
      });

    if (group.isYouthOnly)
      return intl.formatMessage({
        id: "group_age_type_youth",
        defaultMessage: "Youth only, group for youth",
      });
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handlePhoneChange = (phoneChange) => {
    if (participant) {
      const newParticipant = { ...participant };
      newParticipant.phoneNumber = phoneChange.phoneNumber;
      newParticipant.dialCode = phoneChange.dialCode;
      setParticipant(newParticipant);
      setPhoneValid(phoneChange.valid);
    }
  };

  const validate = (): boolean => {
    if (participant) {

      const validationErrors: string[] = [];

      if (validator.isEmpty(participant.givenName)) {
        validationErrors.push(givenNameValidationError);
      }

      if (validator.isEmpty(participant.surname)) {
        validationErrors.push(surnameValidationError);
      }

      if (participant.email && !validator.isEmail(participant?.email)) {
        validationErrors.push(emailValidationError);
      }

      if (participant.phoneNumber && !phoneValid) {
        validationErrors.push(phoneValidationError);
      }

      if (
        (validationErrors.includes[emailValidationError] && validationErrors.includes[phoneValidationError]) ||
        (!participant.phoneNumber && !participant.email)
      ) {
        validationErrors.push(missingContactValidationError);
      }

      setFormErrors(validationErrors);
      return validationErrors.length == 0;
    }

    return false;
  };

  const buildParticipantUpdateDto = (): ParticipantUpdateDto => {
    const updateParticipantDto: ParticipantUpdateDto = {};
    const originalParticipant = originalParticipantRef.current;
    if (participant != null && originalParticipant != null) {
      const originalParticipant = originalParticipantRef.current;
      if (participant.givenName !== originalParticipant?.givenName) {
        updateParticipantDto.givenName = participant?.givenName;
      }
      if (participant.surname !== originalParticipant?.surname) {
        updateParticipantDto.surname = participant?.surname;
      }
      if (participant.isYouth !== originalParticipant?.isYouth) {
        updateParticipantDto.isYouth = participant.isYouth;
      }
      if (participant.email != originalParticipant?.email) {
        updateParticipantDto.email = participant.email;
      }
      if (participant.phoneNumber != originalParticipant?.phoneNumber) {
        updateParticipantDto.phoneNumber = participant.phoneNumber;
      }
      if (participant.dialCode != originalParticipant?.dialCode) {
        updateParticipantDto.dialCode = participant.dialCode;
      }
    }
    return updateParticipantDto;
  };

  const handleSave = async () => {
    if (participant != null && hasChanges && !hasFormErrors) {
      setIsLoading(true);
      try {
        const updateResult = await updateParticipant(participant.id, participantUpdateDto);
        if (updateResult && typeof updateResult != "boolean") {
          const groupToUpdate = store.getGroup(group!.id);
          if (groupToUpdate) {
            const participantIndex = groupToUpdate.quickRegParticipants.findIndex(groupParticipant => groupParticipant.id == participant.id);
            if (participantIndex != -1) {
              groupToUpdate.quickRegParticipants[participantIndex] = {
                ...groupToUpdate.quickRegParticipants[participantIndex],
                ...updateResult.participant
              };
              store.editGroup(groupToUpdate);
            }
          }
          notify({ status: "success", message: intl.formatMessage({ id: "changes_saved", defaultMessage: "Changes Saved" }) });
        } else {
          notify({ status: "error", message: intl.formatMessage({ id: "changes_not_saved", defaultMessage: "An error occured while saving changes" }) });
        }
      } catch {
        notify({ status: "error", message: intl.formatMessage({ id: "changes_not_saved", defaultMessage: "An error occured while saving changes" }) });
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="go-back-section">
        <span className="go-back-link" onClick={handleBackButtonClick}>
          <FormattedMessage id="back" defaultMessage="Back" />
        </span>
      </div>

      <div className="edit-participant-container hasLoader">
        <Loading loading={isLoading} />
        <div>
          <ParticipantTextField
            className="column-input"
            label={{ id: "given_name", default: "Given Name" }}
            value={participant?.givenName}
            disabled={!editFFEnabled}
            onChange={(event) => setParticipant({ ...participant, givenName: event.target.value } as ParticipantDto)}
            error={formErrors.includes(givenNameValidationError)}
          />
          <ParticipantTextField
            className="column-input"
            label={{ id: "surname", default: "Surname" }}
            value={participant?.surname}
            disabled={!editFFEnabled}
            onChange={(event) => setParticipant({ ...participant, surname: event.target.value } as ParticipantDto)}
            error={formErrors.includes(surnameValidationError)}
          />
          <ParticipantTextField
            className="column-input"
            label={{ id: "age_range", default: "Age Range" }}
            value={participant?.isYouth ? 1 : 0}
            disabled={group?.groupAgeType !== GroupAgeTypes.Combined || !editFFEnabled}
            onChange={(event) => setParticipant({ ...participant, isYouth: !!(event.target.value) } as ParticipantDto)}
            error={formErrors.includes(ageRangeValidationError)}
            select={true}
          >
            <MenuItem disabled><FormattedMessage id="select_range" defaultMessage="Select Age" /></MenuItem>
            <MenuItem value={1}><FormattedMessage id="seventeen_or_younger" defaultMessage="17 or younger" /></MenuItem>
            <MenuItem value={0}><FormattedMessage id="eighteen_or_older" defaultMessage="18 or older" /></MenuItem>
          </ParticipantTextField>
        </div>
        <ParticipantTextField
          className="single-line-input"
          label={{ id: "email", default: "Email" }}
          value={participant?.email as string}
          disabled={!editFFEnabled}
          onChange={(event) => setParticipant({ ...participant, email: event.target.value } as ParticipantDto)}
          error={formErrors.includes(emailValidationError) || formErrors.includes(missingContactValidationError)}
        />
        <PhoneInput
          dialCode={participant?.dialCode}
          phoneNumber={participant?.phoneNumber}
          onChange={handlePhoneChange}
          disabled={!editFFEnabled}
          error={formErrors.includes(phoneValidationError) || formErrors.includes(missingContactValidationError)}
        />
        {editFFEnabled &&
          <div className="saveContainer">
            <Button variant="contained" disabled={hasFormErrors || !hasChanges || isLoading} onClick={() => handleSave()}>
              <FormattedMessage id="save" defaultMessage="Save" />
            </Button>
          </div>
        }
        <hr />
        {group && (
          <div
            className="group-card"
            style={{
              backgroundColor: "#EDEDEC",
              padding: "20px 50px 20px 50px",
              border: "1px solid #D1D1CE",
            }}
          >
            <div className="group-header">
              <h3 className="group-type-name">{group.programName}</h3>
              <div className="group-status-container">
                <GroupStatus
                  status={
                    group.isPending ? GroupStatuses.Pending : group.status
                  }
                />
              </div>
            </div>
            <small className="addressCaption">
              {group.address1}, {group.city}, {group.state}, {group.country}
            </small>
            <div className="scheduleLanguageSection">
              {!group.isPending && group.startDate && (
                <div className="schedule-section">
                  <time dateTime={group.startDate}>
                    {getLocaleDateString(group.startDate)}
                  </time>
                  <span> - </span>
                  {group.endDate ? (
                    <time dateTime={group.endDate}>
                      {getLocaleDateString(group.endDate)}
                    </time>
                  ) : (
                    <span>
                      <FormattedMessage
                        id="open_schedule"
                        defaultMessage="Open"
                      />
                    </span>
                  )}
                  ,
                  {group.meetingTimes && (
                    <div className="data">
                      {group.meetingTimes.map((day, index) => {
                        return (
                          <div key={`${group.id}-day-${index}`}>
                            {`${intl.formatMessage({
                              id: getWeekdayName(day.dayOfWeek).toLowerCase(),
                              defaultMessage: getWeekdayName(day.dayOfWeek),
                            })} ${getHHMM(day.startTime)}`}
                            {day.endTime != null &&
                              " - " + getHHMM(day.endTime)}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
              <span>
                {group.language.description}, {getGroupAgeRange()},{" "}
                {group.isHidden ? "Not public" : "Public"}
              </span>
            </div>
            <div className="descriptionSection">
              <p>Description:</p>
              <p>{group.programDescription}</p>
              <span className="participationTermsLink">
                <TermsAndConditions
                  providerId={group.providerId ? group.providerId : 0}
                  programId={group.programId ? group.programId : 0}
                />
              </span>
            </div>
          </div>
        )}
        <hr />
      </div>
    </>
  );
};

export default EditParticipants;
