import "assets/styles/theme.scss";

import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { mapStateToProps, mapDispatchToProps } from "datastore/store";
import { MapsLoaderProvider } from "components/maps-loader";
import { connect } from "react-redux";
import getLayout from "utilities/styles";
import { IntlProvider } from "react-intl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider as MUILocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { getQuickRegPreloadValues } from "lib/api/backend/requests/quickreg";
import usePreloadStore from "datastore/preload";
import Routes from "./routes";
import { ImpersonationProvider } from "contexts";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { ServerResponseType } from "lib/api/backend/request-utils";

dayjs.extend(updateLocale);

const BaseUrl = process.env.REACT_APP_URL ? process.env.REACT_APP_URL : "/";

const App: React.VFC<any> = (props) => {

  const { checkAuthenticated, getUser, updateLayout, updateProviders, store: { language } } = props;
  const store = usePreloadStore((state) => state);

  useEffect(() => {
    const getPreloadValues = async () => {
      const preload = await getQuickRegPreloadValues();

      if (preload && preload.responseType == ServerResponseType.Success) {
        store.setPreload(preload.data);
      }
    };

    getPreloadValues();
  }, []);

  useEffect(() => {
    const checkAuth = async () => {

      const isAuthenticated = await checkAuthenticated();

      if (isAuthenticated) {
        await getUser();
      }
    };
    checkAuth();
  }, [checkAuthenticated, getUser]);

  useEffect(() => {
    const onResize = () => {
      updateLayout(getLayout());
    };
    updateProviders();
    onResize();
    window.addEventListener("load", onResize);
    window.addEventListener("resize", onResize);
    return () => {
      // cleanup
      window.removeEventListener("load", onResize);
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(
    () => {
      dayjs.updateLocale(
        language.dayjsCode,
        language.dayjsLocale
      );
    },
    [language]
  );

  return (
    <ImpersonationProvider>
      <IntlProvider locale={language.code} messages={language.translations}>
        <MUILocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language.dayjsCode}>
          <MapsLoaderProvider language={language.localeCodes[0]}>
            <Router basename={BaseUrl}>
              <Routes />
            </Router>
          </ MapsLoaderProvider>
        </MUILocalizationProvider>
      </IntlProvider>
    </ImpersonationProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
