import axios, { AxiosResponse } from "axios";
import config from "../axios.config.json";
import { Group, MapBounds, LatLng } from "lib/data/model";
import { GroupDtoToGroupConverter } from "../../converters/GroupDtoToGroupConverter";
import { GroupDto, IGroupDtoCreationValues } from "lib/api/backend/model";
import { CourseOutcomeCreationUpdateDto } from "lib/api/backend/model/outcomes";
import { ProviderGroupDto } from "lib/api/backend/model/provider";
import { GroupToGroupCreationDtoConverter } from "../../converters/GroupToGroupCreationDtoConverter";
import { ApiUrl } from "constants/api-auth-constants";
import { customParamSerializer } from "../custom-serializer";

export const groupControllerPath = `${ApiUrl}groups/`;

export const getUserGroups = async (): Promise<GroupDto[]> => {
  const response: AxiosResponse<IGroupDtoCreationValues[]> = await axios.get(
    `${groupControllerPath}user-groups`,
    { ...config }
  );

  if (response.status >= 200 && response.status < 300) {
    return response.data.map(creationValues => new GroupDto(creationValues));
  }

  throw new Error(`${response.status} - ${response.statusText}`);

};

export const getGroup = async (locationId: number, programId: number, groupId: number): Promise<GroupDto> => {

  const response: AxiosResponse<IGroupDtoCreationValues> = await axios.get(
    `${groupControllerPath}location/${locationId}/program/${programId}/group/${groupId}/group`,
    { ...config }
  );

  if (response.status >= 200 && response.status < 300) {
    return new GroupDto(response.data);
  }

  throw new Error(`${response.status} - ${response.statusText}`);

};

export const getGroupWithProtectedData = async (locationId: number, programId: number, groupId: number, includeOutcomes = false): Promise<GroupDto> => {
  const response: AxiosResponse<IGroupDtoCreationValues> = await axios.get(
    `${groupControllerPath}location/${locationId}/program/${programId}/group/${groupId}/group-protected-info?includeOutcomes=${includeOutcomes ? "true" : "false"}`,
    { ...config }
  );

  if (response.status >= 200 && response.status < 300) {
    return new GroupDto(response.data);
  }

  throw new Error(`${response.status} - ${response.statusText}`);
};

export const create = async (group: Group): Promise<Group> => {
  const groupConverter = new GroupToGroupCreationDtoConverter();
  const response: AxiosResponse<IGroupDtoCreationValues> = await axios.post(
    `${groupControllerPath}group`,
    groupConverter.toDto(group),
    { ...config }
  );

  if (response.status >= 200 && response.status < 300) {
    const dtoConverter = new GroupDtoToGroupConverter();
    const newGroupDto = await getGroupWithProtectedData(response.data.locationId!, group.course!.id!, response.data.id!);
    return dtoConverter.toModel(newGroupDto);
  }

  throw new Error(`${response.status} - ${response.statusText}`);
};

export const update = async (locationId: number, programId: number, groupId: number, group: Group): Promise<Group> => {
  const groupConverter = new GroupToGroupCreationDtoConverter();
  const dto = groupConverter.toDto(group);

  //throw new Error;
  const response: AxiosResponse<IGroupDtoCreationValues> = await axios.put(
    `${groupControllerPath}location/${locationId}/program/${programId}/group/${groupId}/group`,
    dto,
    { ...config }
  );

  if (response.status >= 200 && response.status < 300) {
    const dtoConverter = new GroupDtoToGroupConverter();
    const newGroupDto = await getGroupWithProtectedData(response.data.locationId!, group.course!.id!, groupId);
    return dtoConverter.toModel(newGroupDto);
  }

  throw new Error(`${response.status} - ${response.statusText}`);
};

export const searchAreaGroups = async (
  mapBounds: MapBounds,
  mapLocation: LatLng,
  abortSignal: AbortSignal,
  excludeCompletedCancelled: boolean,
  excludeFull: boolean,
  limitToParentPrograms?: number[],
  limitToProviderIds?: number[],
  limitToCourseIds?: number[],
): Promise<ProviderGroupDto> => {

  const params: any = {
    northLatitude: mapBounds.neLat,
    eastLongitude: mapBounds.neLng,
    southLatitude: mapBounds.swLat,
    westLongitude: mapBounds.swLng,
    mapCenterLatitude: mapLocation.lat,
    mapCenterLongitude: mapLocation.lng,
    excludeCompletedCancelled,
    excludeFull,
  };

  if (limitToParentPrograms && limitToParentPrograms.length) {
    params.parentProgramIds = limitToParentPrograms;
  }

  if (limitToProviderIds && limitToProviderIds.length) {
    params.providerIds = limitToProviderIds;
  }

  if (limitToCourseIds && limitToCourseIds.length) {
    params.courseIds = limitToCourseIds;
  }

  const response: AxiosResponse<ProviderGroupDto> = await axios.get(
    `${groupControllerPath}area/groups`,
    {
      ...config,
      signal: abortSignal,
      params,
      paramsSerializer: customParamSerializer //using custom serializer so arrays don't get sent with [] in every query param
    },

  );

  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }

  throw new Error(`${response.status} - ${response.statusText}`);
};

export const dropParticipants = async (groupId: number, participantIds: number[]): Promise<number[]> => {
  const response: AxiosResponse<number[]> = await axios.put(
    `${groupControllerPath}group/${groupId}/drop-participants`,
    participantIds,
    { ...config },
  );

  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }
};


//Deprecated
export const dropStudents = async (locationId: number, programId: number, groupId: number, studentIds: number[]): Promise<number[]> => {

  const response: AxiosResponse<number[]> = await axios.put(
    `${groupControllerPath}location/${locationId}/program/${programId}/group/${groupId}/drop-students`,
    studentIds,
    { ...config },
  );

  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }

};

//Deprecated
export const dropLimitedStudents = async (groupId: number, limitedStudentIds: number[]): Promise<number[]> => {

  const response: AxiosResponse<number[]> = await axios.put(
    `${groupControllerPath}group/${groupId}/drop-limited-students`,
    limitedStudentIds,
    { ...config },
  );

  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }

};

export const updateParticipantConsents = async (groupId: number, participantIds: number[]): Promise<number[]> => {
  const response: AxiosResponse<number[]> = await axios.put(
    `${groupControllerPath}group/${groupId}/update-participant-consents`,
    participantIds,
    { ...config },
  );

  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }
};


//Deprecated
export const updateConsents = async (locationId: number, programId: number, groupId: number, studentIds: number[]): Promise<number[]> => {

  const response: AxiosResponse<number[]> = await axios.put(
    `${groupControllerPath}location/${locationId}/program/${programId}/group/${groupId}/update-consents`,
    studentIds,
    { ...config },
  );

  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }

};

export const updateLimitedConsents = async (groupId: number, limitedStudentIds: number[]): Promise<number[]> => {

  const response: AxiosResponse<number[]> = await axios.put(
    `${groupControllerPath}group/${groupId}/update-limited-consents`,
    limitedStudentIds,
    { ...config },
  );

  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }

};

export const concludeGroup = async (locationId: number, programId: number, groupId: number, trackId: number): Promise<GroupDto> => {

  const response: AxiosResponse<IGroupDtoCreationValues> = await axios.put(
    `${groupControllerPath}location/${locationId}/program/${programId}/group/${groupId}/track/${trackId}/conclude-group`,
    { ...config },
  );

  if (response.status >= 200 && response.status < 300) {
    return new GroupDto(response.data);
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }

};

export const cancelGroup = async (locationId: number, programId: number, groupId: number, trackId: number): Promise<GroupDto> => {

  const response: AxiosResponse<IGroupDtoCreationValues> = await axios.put(
    `${groupControllerPath}location/${locationId}/program/${programId}/group/${groupId}/track/${trackId}/cancel-group`,
    { ...config },
  );

  if (response.status >= 200 && response.status < 300) {
    return new GroupDto(response.data);
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }

};

export const saveOutcomes = async (locationId: number, programId: number, groupId: number, outcomes: CourseOutcomeCreationUpdateDto[]): Promise<boolean> => {

  const response: AxiosResponse<boolean> = await axios.post(
    `${groupControllerPath}location/${locationId}/program/${programId}/group/${groupId}/wsrs-outcome-surveys`,
    outcomes,
    { ...config },
  );

  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }

};

export const CheckForDuplicateGroups = async (group: Group, isUpdate = false): Promise<GroupDto[]> => {

  const groupConverter = new GroupToGroupCreationDtoConverter();

  const response: AxiosResponse<IGroupDtoCreationValues[]> = await axios.post(
    `${groupControllerPath}is-duplicate-group${isUpdate ? `?editGroupId=${group.id}` : ""}`,
    groupConverter.toDto(group),
    { ...config }
  );

  if (response.status >= 200 && response.status < 300) {
    return response.data.map(creationValues => new GroupDto(creationValues));
  }

  throw new Error(`${response.status} - ${response.statusText}`);

};