import AddressCreationDto from "../model/AddressCreationDto";
import AddressDto from "../model/AddressDto";
import { IDtoToDtoConverter } from "./IDtoConverter";

export class AddressDtoToAddressCreationDtoConverter implements IDtoToDtoConverter<AddressDto, AddressCreationDto> {
  public toDto = (dto: AddressDto): AddressCreationDto => {
    const address = new AddressCreationDto({
      address1: dto.address1,
      address2: dto.address2,
      city: dto.city,
      state: dto.state,
      postalCode: dto.postalCode,
      country: dto.country
    });

    return address;
  };
}