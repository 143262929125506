import { TableSort } from "../models";

const SORT_KEY_SUFFIX = "-sort-setting";

export function getSortFromSession(id: string | number): TableSort | null {
  if (id) {
    const rawSortValue = sessionStorage.getItem(`${id}${SORT_KEY_SUFFIX}`);
    if (rawSortValue) {
      try {
        const newSort = JSON.parse(rawSortValue) as TableSort;
        if (newSort?.columnIndex > -1 && (newSort.direction == "asc" || newSort.direction == "desc")) {
          return {
            ...newSort,
            applied: false
          };
        }
      }
      catch {
        return null;
      }
    }
    return null;
  }
  return null;
}

export function saveSortToSession(id: string | number, sort: TableSort) {
  if (id && sort) {
    const sortJson = JSON.stringify(sort);
    sessionStorage.setItem(`${id}${SORT_KEY_SUFFIX}`, sortJson);
  }
}