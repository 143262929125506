import { FormattedMessage } from "react-intl";
import GroupStatuses from "constants/group-constants";
import "./group-status.scss";

interface IGroupStatus {
  status: GroupStatuses | string,
  uppercase?: boolean,
}

const GroupStatus = (props: IGroupStatus) => {
  const displayUppercase = props.uppercase ? true : false;
  return (
    <div className="groupStatusContainer">
      {props.status &&
        <div className={`group-status-itemstatus ${props.status.toLowerCase()}`} style={displayUppercase ? { "textTransform": "uppercase" } : {}}>
          {(() => {
            switch (props.status) {
              case GroupStatuses.InProgress: return <FormattedMessage id="in_progress" defaultMessage="In Progress" />;
              case GroupStatuses.Upcoming: return <FormattedMessage id="upcoming" defaultMessage="Upcoming" />;
              case GroupStatuses.Completed: return <FormattedMessage id="completed" defaultMessage="Completed" />;
              case GroupStatuses.Cancelled: return <FormattedMessage id="cancelled" defaultMessage="Cancelled" />;
              case GroupStatuses.Pending: return <FormattedMessage id="pending" defaultMessage="Pending" />;
              default: return props.status;
            }
          })()}
        </div>
      }
    </div>
  );
};

export default GroupStatus;