export interface IAddressCreationDto {
  address1: string;
  address2?: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
  unitNumber?: number;
}

export default class AddressCreationDto implements IAddressCreationDto {
  public address1: string;
  public address2?: string;
  public city: string;
  public country: string;
  public postalCode: string;
  public state: string;
  public unitNumber?: number;

  constructor(creationValues: IAddressCreationDto) {
    this.address1 = creationValues.address1;
    this.address2 = creationValues.address2;
    this.city = creationValues.city;
    this.country = creationValues.country;
    this.postalCode = creationValues.postalCode;
    this.state = creationValues.state;
    this.unitNumber = creationValues.unitNumber;
  }

  public export(): IAddressCreationDto {
    return {
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      country: this.country,
      postalCode: this.postalCode,
      state: this.state,
      unitNumber: this.unitNumber
    };
  }

  public clone(): AddressCreationDto {
    return new AddressCreationDto(this.export());
  }
}