import axios, { AxiosResponse } from "axios";
import config from "./requests/axios.config.json";

export type ServerResult<T> = {
  responseType: ServerResponseType;
  data: T
};

export enum ServerResponseType {
  Success,
  InvalidRequest,
  Unauthorized,
  Error
}

export const validateResponse = (statusCode: number) => {
  if (statusCode >= 200 && statusCode < 300) {
    return ServerResponseType.Success;
  }

  if (statusCode === 400) {
    return ServerResponseType.InvalidRequest;
  }

  if (statusCode === 401 || statusCode === 403) {
    return ServerResponseType.Unauthorized;
  }

  return ServerResponseType.Error;
};

export async function get<T>(url: string) {
  try {
    const response: AxiosResponse<T> = await axios.get(
      url,
      { ...config }
    );

    const responseType = validateResponse(response.status);

    if (responseType == ServerResponseType.Success) {
      return response.data != undefined && response != null ? response.data : true;
    }

    return false;
  }
  catch (e) {
    return false;
  }

}

export async function post<T>(url: string, payload: any) {
  try {

    const response: AxiosResponse<T> = await axios.post(
      url,
      payload,
      { ...config }
    );

    const responseType = validateResponse(response.status);

    if (responseType == ServerResponseType.Success) {
      return response.data != undefined && response != null ? response.data : true;
    }

    return false;
  }
  catch (e) {
    return false;
  }
}

export async function put<T>(url: string, payload: any) {
  try {
    const response: AxiosResponse<T> = await axios.put(
      url,
      payload,
      { ...config }
    );

    const responseType = validateResponse(response.status);

    if (responseType == ServerResponseType.Success) {
      const result = response.data != undefined && response != null ? response.data : true;
      return result;
    }

    return false;
  }
  catch (e) {
    return false;
  }
}