import dayjs from "dayjs";

export interface IStudentDtoCreationValues {
  enrollmentId?: number;
  studentId?: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  status: string;
  modifiedDate: string;
  isLimitedStudent: boolean;
  isOlderThanEighteen: boolean;
  dialCode: string;
  createdDate: Date;
}

export interface IStudentDto {
  enrollmentId?: number;
  studentId?: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  status: string;
  modifiedDate: Date;
  isLimitedStudent: boolean;
  isOlderThanEighteen: boolean;
  dialCode: string;
}

export default class StudentDto implements IStudentDto {

  public enrollmentId?: number;
  public studentId?: number;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public status: string;
  public modifiedDate: Date;
  public isLimitedStudent: boolean;
  public isOlderThanEighteen: boolean;
  public dialCode: string;
  public createdDate: Date;

  constructor(creationValues: IStudentDtoCreationValues) {
    this.enrollmentId = creationValues.enrollmentId;
    this.studentId = creationValues.studentId;
    this.firstName = creationValues.firstName;
    this.lastName = creationValues.lastName;
    this.email = creationValues.email;
    this.phone = creationValues.phone;
    this.status = creationValues.status;
    this.modifiedDate = creationValues.modifiedDate ? dayjs(creationValues.modifiedDate).toDate() : dayjs().toDate();
    this.isLimitedStudent = creationValues.isLimitedStudent ?? false;
    this.isOlderThanEighteen = creationValues.isOlderThanEighteen;
    this.dialCode = creationValues.dialCode;
    this.createdDate = creationValues.createdDate;
  }

  public export(): IStudentDtoCreationValues {
    return {
      enrollmentId: this.enrollmentId,
      studentId: this.studentId,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      status: this.status,
      modifiedDate: this.modifiedDate.toISOString(),
      isLimitedStudent: this.isLimitedStudent,
      isOlderThanEighteen: this.isOlderThanEighteen,
      dialCode: this.dialCode,
      createdDate: this.createdDate
    };
  }

  public clone(): StudentDto {
    return new StudentDto(this.export());
  }
}