import { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import ParticipantList from "components/participant-list/participant-list";

export const Participants = (): ReactElement => {

  return (
    <div className="studentListContainer">
      <h1><FormattedMessage id="participants" defaultMessage="Participants" /></h1>
      <ParticipantList />
    </div>
  );
};

export default Participants;