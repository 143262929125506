import { useContext, useEffect, useState } from "react";
import { Group } from "lib/data/model";
import { GroupContext } from "./GroupContext";

export const useGroup = (): [Group | undefined, (value: Group) => void] => {
  const state = useContext(GroupContext);

  const [currentGroup, setCurrentGroup] = useState(state?.group);

  useEffect(
    () => {
      setCurrentGroup(state?.group);
    },
    [state?.group]
  );

  const setGroup = (value: Group) => {
    if (state?.setGroup) {
      state.setGroup(value);
    }
  };

  return [currentGroup, setGroup];
};