export default class Cookies {

  static getCookieValueByKey(key: string): string | null {

    let cookieValue: string | null = null;
    let rawCookie = document.cookie.split(";").find(item => {
      return item.trim().indexOf(key + "=") == 0;
    });

    if (rawCookie) {

      rawCookie = decodeURIComponent(rawCookie);
      rawCookie = rawCookie.replace(key + "=", "");

      try {
        cookieValue = JSON.parse(rawCookie);
      } catch (error) {
        cookieValue = rawCookie;
      }

    } else {
      return null;
    }

    return cookieValue;
  }

  static createCookie(name: string, value: string, msUntilExpire: number): void {
    const expirationDate = msUntilExpire > 0 ? new Date((Date.now() + msUntilExpire)) : new Date();
    document.cookie = name + "=" + value + "; expires=" + expirationDate.toUTCString() + "; path=/";
  }

  static updateCookie(name: string, value: string, msUntilExpire: number): void {
    Cookies.createCookie(name, value, msUntilExpire);
  }

  static deleteCookie(name: string): void {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }

  static clearAllLocalCookies(): void {
    const allCookies = document.cookie.split(";");
    allCookies.forEach(cookie => {
      const name = cookie.substr(0, cookie.indexOf("="));
      this.deleteCookie(name);
    });
  }

  static cookieExists(cookieName: string): boolean {
    return document.cookie.split(";").some(cookie => {
      return cookie.trim().indexOf(cookieName + "=") == 0;
    });
  }

}