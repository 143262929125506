import { LatLng, MapBounds } from "lib/data/model";

export interface IMapLocation {
  center: LatLng;
  bounds: MapBounds;
  zoom: number;
}

export class MapLocation implements IMapLocation {

  public center: LatLng;
  public bounds: MapBounds;
  public zoom: number;

  constructor(creationValues: IMapLocation) {
    this.center = creationValues.center;
    this.bounds = creationValues.bounds;
    this.zoom = creationValues.zoom;
  }

}

export default MapLocation;