import dayjs from "dayjs";
import appStore from "datastore/store";

let selectedLanguage = appStore.getState().appReducer.language;

function handleStoreChange() {
  const newState = appStore.getState();
  selectedLanguage = newState.appReducer.language;
}

appStore.subscribe(handleStoreChange);

export function convert24To12(time) {
  if (typeof time == "string" && !!time) {
    let timeString = time;
    const tIdx = timeString.lastIndexOf("T");
    if (tIdx > -1) {
      // strip off date part
      timeString = timeString.substr(tIdx + 1);
    }
    const timeParts = timeString.split(":").map(x => parseInt(x));
    let hour = timeParts[0];
    const minute = timeParts[1];
    const meridian = (hour >= 12) ? "PM" : "AM";
    if (hour >= 13) {
      hour -= 12;
    } else if (hour == 0) {
      hour = 12;
    }
    return `${hour}:${minute} ${meridian}`;
  }
}

function convert12To24(time): string | null {
  if (isHourMinuteString(time)) {
    const split = time.split(":");
    let hour = split[0];

    const temp = split[1].split(" ");
    const minute = temp[0];
    let meridian = temp[1];

    if (meridian) {
      meridian = meridian.toLowerCase();
      if (meridian == "pm" && hour < 12) {
        hour = parseInt(hour) + 12;
      } else if (meridian == "am" && hour == 12) {
        hour = "00";
      }
    }
    return `${hour}:${minute}`;
  }
  return null;
}

export function convertHHMMToCurrentDateUTC(hhmm) {

  if (!hhmm)
    return null;

  if (!isHourMinuteString(hhmm))
    return null;

  const newDate = new Date();
  const split = convert12To24(hhmm)?.split(":").map(x => parseInt(x));

  if (split)
    newDate.setHours(split[0], split[1], 0, 0);

  return newDate.toISOString();
}

function validateHoursMinutes(hours: number, minutes: number) {
  if (isNaN(hours) || isNaN(minutes) || hours > 24 || hours < 0 || minutes > 60 || minutes < 0)
    return false;

  return true;
}

export function isHourMinuteString(stringToTest: string) {
  if (stringToTest === "")
    return false;

  if (stringToTest.indexOf(":") === -1)
    return false;

  const splitResult = stringToTest.split(":");

  if (!splitResult)
    return false;

  const hours = parseInt(splitResult[0]);
  const minutes = parseInt(splitResult[1]);

  return validateHoursMinutes(hours, minutes);
}

export function getTimeDuration(startTime, endTime) {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const duration = endDate.getTime() - startDate.getTime();
  return duration;
}

export function getDurationWeeks(startTime, endTime) {
  const durationDays = getDurationDays(startTime, endTime);
  return durationDays / 7;
}

export function getDurationDays(startTime, endTime) {
  const duration = getTimeDuration(startTime, endTime);
  return duration / 86400000; // 1000 * 60 * 60 * 24 = 86400000; 
}

export function getYYYYMMDD(timeStamp) {
  if (timeStamp) {
    if (typeof timeStamp == "string") {
      timeStamp = timeStamp?.includes("T") ? timeStamp : `${timeStamp}T00:00:00`;
    }
    const date = new Date(timeStamp);
    let month: string | number = date.getMonth() + 1; //month is zero indexed adding 1 to get the correct representation
    let day: string | number = date.getDate();
    let year = "" + date.getFullYear();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    while (year.length < 4) {
      year = "0" + year;
    }
    return `${year}-${month}-${day}`;
  }
  return null;
}

export function getHHMM(timeStamp, hour12 = true, locale = selectedLanguage.localeCodes[0]) {
  const dateString = new Date(timeStamp).toLocaleTimeString(locale, { hour: "2-digit", minute: "2-digit", hour12 });
  const parts = dateString.split(":");
  if (parts[0] == "24") {
    parts[0] = "00";
  }
  return parts.join(":");
}

export function getLocaleDateString(time: string | Date, language = selectedLanguage) {
  if (time instanceof Date) {
    time = dayjs(time).format("YYYY-MM-DD");
  }
  if (time) {
    if (!time.includes("T")) {
      time = `${time}T00:00:00`;
    }
    return dayjs(time).locale(selectedLanguage.dayjsLocale).format(language.dayjsLocale.formats.LL);
  }
}

export function dateToYearMonthString(timeStamp, locale = selectedLanguage.localeCodes[0]) {
  return new Date(timeStamp).toLocaleDateString(locale, { month: "long", year: "numeric" });
}

export function getWeekdayName(weekDayIndex) {
  const index = parseInt(weekDayIndex);
  if (Number.isInteger(index) && index >= 0 && index <= 6) {
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return dayNames[index];
  }
  return "";
}

export function isValidWeekday(day) {
  let valid = true;
  if (isNaN(parseInt(day)) || day < 0 || day > 6) {
    valid = false;
  }
  return valid;
}

export function breakUpTimeString(timeString) {
  if (isHourMinuteString(timeString)) {
    const split = timeString.split(":");
    const hour = split[0];
    const minute = split[1];
    const seconds = split[2];
    return {
      hour,
      minute,
      seconds
    };
  }
  return {
    hour: null,
    minute: null,
    seconds: null
  };
}

export function getDaysInMonth(year, month) {
  return new Date(year, month + 1, 0).getDate();
}

export function getStartWeekday(month, year) {
  return new Date(year, month).getDay();
}

export function getTodayYYYYMMDD() {
  return getYYYYMMDD(new Date().toISOString());
}

export function addDays(date, days) {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
}

export function dateIsLessThanDaysOld(date: Date | string, numberofDays: number) {
  const testDate = date instanceof Date ? date : new Date(date);

  const cutOffDate = (() => {
    const day = new Date();
    day.setDate(day.getDate() - numberofDays);
    return day;
  })();

  return testDate >= cutOffDate;
}