import Card from "components/card/card";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import ParticipantListActionMenu from "../participant-list-action-menu/participant-list-action-menu";
import ParticipantStatus from "components/participant-status";
import { LoadingStatus } from "lib/data/model";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { ParticipantGroupDto } from "lib/api/backend/model/participant/ParticipantGroupDto";
import "./participant-list-card.scss";
import TruncatedParticipantName from "components/truncated-participant-name";

interface IParticipantListCard {
  participant: ParticipantGroupDto;
  onParticipantLoadingChange: (loading: LoadingStatus) => void;
}

export const ParticipantListCard = (props: IParticipantListCard) => {

  const { participant, onParticipantLoadingChange } = props;

  const cardHead = (
    <div className="classCardHead">
      <div className="leftHead">
        <div className="groupHeading">
          <Link to={`/participant/${participant.id}/group/${participant.groupId}`}>
            <TruncatedParticipantName participant={participant} />
          </Link>
        </div>
      </div>
      <div className="rightHead">
      </div>
    </div>
  );

  return (
    <div className="studentListCardContainer">
      <Card head={cardHead}>
        <div className="dataContainer">
          <div className="dataHeading">
            <FormattedMessage
              id="church_account"
              defaultMessage="Church Account"
            />
          </div>
          <div className="data access">
            {participant?.cmisId ? <CloseIcon /> : <CheckIcon />}
          </div>
        </div>

        <div className="dataContainer">
          <div className="dataHeading"><FormattedMessage id="group_type" defaultMessage="Group Type" /></div>
          <div className="data">
            <Link to={`/groups/${participant.groupId}`}>{participant.programName}</Link>
          </div>
        </div>

        <div className="dataContainer">
          <div className="dataHeading"><FormattedMessage id="status" defaultMessage="Status" /></div>
          <div className="data status">
            <ParticipantStatus status={participant.status} />
          </div>
        </div>

        <div className="actionContainer">
          <ParticipantListActionMenu
            participant={participant}
            borderOnToggle={true}
            onLoadingChange={onParticipantLoadingChange}
          />
        </div>
      </Card>
    </div>
  );
};

export default ParticipantListCard;