export function downloadFile(blob: Blob, fileName: string) {
  const myNav: any = window?.navigator;
  if (myNav && myNav?.msSaveOrOpenBlob) {
    myNav.msSaveOrOpenBlob(blob);
    return;
  }
  const data = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = data;
  link.download = fileName;
  setTimeout(() => {
    link.click();
    window.URL.revokeObjectURL(data);
  }, 100);
}

export default downloadFile;