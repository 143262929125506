import { createStore } from "zustand";
import { createContext } from "react";
import MapsLoaderState from "./maps-loader-state";


const DEFAULT_STATE = Object.freeze({
  ready: false,
  mapLanguage: "",
} as MapsLoaderState);

interface MapLoaderStoreProps {
  language: string;
  onLoad?: () => void;
}

export const createMapLoaderStore = (props: MapLoaderStoreProps) => {

  return createStore<MapsLoaderState>()(
    (set) => {
      return {
        ...DEFAULT_STATE,
        mapLanguage: props.language,
        setReady: (ready: boolean) => set((state) => ({ ...state, ready: ready })),
        setMapLanguage: (mapLanguage: string) => set((state) => ({ ...state, mapLanguage: mapLanguage }))
      };
    }
  );
};

type MapsLoaderStore = ReturnType<typeof createMapLoaderStore>;

export const MapsLoaderContext = createContext<MapsLoaderStore | null>(null);