import { ApiUrl } from "constants/api-auth-constants";
import AuthorizeService from "auth/authorize-service";
export default class API {

  static apiUrl = ApiUrl;

  static createCancelToken() {
    var cancelfn;
    var promiseobj = new Promise(resolve => {
      cancelfn = () => {
        resolve();
      };
    });
    return { promise: promiseobj, cancel: cancelfn };
  }

  static serviceRequestPromise(method, url, data, token, preAuthorized = false) {
    return new Promise((resolve, reject) => {
      if (!preAuthorized && !AuthorizeService.checkRawRequest(method, url)) {
        return reject({
          status: 403,
          statusText: "Forbidden"
        });
      }
      const xhr = new XMLHttpRequest();
      xhr.open(method, url, true);
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      if (token) {
        token.promise.then(() => {
          if (xhr && xhr.readyState != 4) {
            xhr.abort();
          }
        });
      }
      xhr.onload = () => {
        if ((xhr.status >= 200 && xhr.status < 300) && xhr.readyState == 4) {
          resolve(xhr.responseText != undefined && xhr.responseText != null && xhr.responseText != "" && xhr.responseText.length > 0 ? xhr.responseText : "");
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = () => {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };
      if (data) {
        xhr.send(JSON.stringify(data));
      } else {
        xhr.send();
      }
    });
  }

  static makeURL(endpoint) {
    return API.apiUrl + endpoint;
  }

  static makeQueryParams(keyValues) {
    let stringifiedParams = "";
    if (keyValues) {
      stringifiedParams += "?" + Object.keys(keyValues).map((key) => {
        if (Array.isArray(keyValues[key])) {
          return keyValues[key].map(values => key + "=" + encodeURIComponent(values)).join("&");
        } else {
          return key + "=" + encodeURIComponent(keyValues[key]);
        }
      }).join("&");
    }
    return stringifiedParams;
  }

  static async get(url, params, token, preAuthorized = false) {
    url = (params) ? url + this.makeQueryParams(params) : url;
    return await this.serviceRequestPromise("get", API.makeURL(url), null, token, preAuthorized);
  }

  static async post(url, data, token, preAuthorized = false) {
    return await this.serviceRequestPromise("post", API.makeURL(url), data, token, preAuthorized);
  }

  static async put(url, data, token, preAuthorized = false) {
    return await this.serviceRequestPromise("put", API.makeURL(url), data, token, preAuthorized);
  }

  static async delete(url, data, token, preAuthorized = false) {
    return await this.serviceRequestPromise("delete", API.makeURL(url), data, token, preAuthorized);
  }

  static async parseResponse(method, url, data, token, preAuthorized = false) {
    let parsedData = null;
    let response = null;
    try {
      switch (method) {
        case "get":
          response = await API.get(url, data, token, preAuthorized);
          break;
        case "post":
          response = await API.post(url, data, token, preAuthorized);
          break;
        case "put":
          response = await API.put(url, data, token, preAuthorized);
          break;
        case "delete":
          response = await API.delete(url, data, token, preAuthorized);
          break;
      }

    } catch (error) {
      if (error?.status == 403 || error?.status == 401) {
        throw new Error("Unauthorized");
      } else {
        response = false;
      }
    }

    if (response) {
      try {
        parsedData = JSON.parse(response);
      } catch (error) {
        return error;
      }
    } else {
      return false;
    }

    return parsedData;
  }

  static downloadRequestPromise(method, url, data, token, preAuthorized = false) {
    return new Promise((resolve, reject) => {
      if (!preAuthorized && !AuthorizeService.checkRawRequest(method, url)) {
        return reject({
          status: 403,
          statusText: "Forbidden"
        });
      }
      const xhr = new XMLHttpRequest();
      xhr.open(method, url, true);
      xhr.responseType = "blob";
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      if (token) {
        token.promise.then(() => {
          if (xhr && xhr.readyState != 4) {
            xhr.abort();
          }
        });
      }
      xhr.onload = () => {
        if ((xhr.status >= 200 && xhr.status < 300) && xhr.readyState == 4) {
          resolve(new Blob([xhr.response], { type: xhr.getResponseHeader("content-type") }));
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = () => {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        });
      };
      if (data) {
        xhr.send(JSON.stringify(data));
      } else {
        xhr.send();
      }
    });
  }

  static async downloadResponse(method, endpoint, data, token, preAuthorized = false) {
    let response = null;
    try {
      switch (method) {
        case "get": {
          let url = (data) ? endpoint + this.makeQueryParams(data) : endpoint;
          response = await this.downloadRequestPromise("get", API.makeURL(url), null, token, preAuthorized);
          break;
        }
        case "post":
          response = await this.downloadRequestPromise("post", API.makeURL(endpoint), data, token, preAuthorized);
          break;
        case "put":
          response = await this.downloadRequestPromise("put", API.makeURL(endpoint), data, token, preAuthorized);
          break;
        case "delete":
          response = await this.downloadRequestPromise("delete", API.makeURL(endpoint), data, token, preAuthorized);
          break;
      }

    } catch (error) {
      if (error?.status == 403) {
        throw new Error("Unauthorized");
      } else {
        response = false;
      }
    }

    if (response) {
      return response;
    } else {
      return false;
    }
  }

  static async authorizeAndParseResponse(endpoint, data, token) {
    if (AuthorizeService.authorizeEndpoint(endpoint)) {
      return API.parseResponse(endpoint.method, endpoint.path, data, token, true);
    } else {
      throw new Error("Unauthorized");
    }
  }

  static async authorizeAndDownload(endpoint, data, token) {
    if (AuthorizeService.authorizeEndpoint(endpoint)) {
      return API.downloadResponse(endpoint.method, endpoint.path, data, token, true);
    } else {
      throw new Error("Unauthorized");
    }
  }

}