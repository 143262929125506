import dayjs from "dayjs";

export interface IFacilitatorDtoCreationValues {
  id?: number;
  name: string;
  gender: string;
  firstName: string;
  lastName: string;
  birthDate?: string;
  age: number;
  displayName: string;
  cmisId: number;
}

export interface IFacilitatorDto {
  id?: number;
  name: string;
  gender: string;
  firstName: string;
  lastName: string;
  birthDate?: Date;
  age: number;
  displayName: string;
  cmisId: number;
}

export default class FacilitatorDto implements IFacilitatorDto {

  public id?: number;
  public name: string;
  public gender: string;
  public firstName: string;
  public lastName: string;
  public birthDate?: Date;
  public age: number;
  public displayName: string;
  public cmisId: number;

  constructor(creationValues: IFacilitatorDtoCreationValues) {
    this.id = creationValues.id;
    this.name = creationValues.name;
    this.gender = creationValues.gender;
    this.firstName = creationValues.firstName;
    this.lastName = creationValues.lastName;
    this.birthDate = creationValues.birthDate ? dayjs(creationValues.birthDate).toDate() : undefined;
    this.age = creationValues.age;
    this.displayName = creationValues.displayName;
    this.cmisId = creationValues.cmisId;
  }

  public export(): IFacilitatorDtoCreationValues {
    return {
      id: this.id,
      name: this.name,
      gender: this.gender,
      firstName: this.firstName,
      lastName: this.lastName,
      birthDate: this.birthDate?.toISOString(),
      age: this.age,
      displayName: this.displayName,
      cmisId: this.cmisId
    };
  }

  public clone(): FacilitatorDto {
    return new FacilitatorDto(this.export());
  }
}