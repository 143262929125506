import React, { useEffect, useState } from "react";
import StatusBox from "components/status-box/status-box";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getPositionOrDefault,
  GeolocationStatus,
  calculateBounds,
} from "utilities/geolocation";
import useGroupFinderStore from "./group-finder-store";
import GroupFinderView from "./models";
import usePreloadStore, {
  PreloadStore,
  getProviders,
  getGroupTypesByProviders,
} from "datastore/preload";
import dispatchers from "datastore/dispatcher";
import usePrevious from "utilities/usePrevious";
import getValueFromQueryStringKey from "utilities/query-string";
import { CourseDto } from "lib/api/backend/model/course";
import { getAreaGroups, getNearestGroups } from "lib/api/backend/requests/groups";
import GroupFinderMapView from "./group-finder-map-view/group-finder-map-view";
import GroupFinderListControls from "./group-finder-list-controls/group-finder-list-controls";
import GroupFinderListView from "./group-finder-list-view/group-finder-list-view";
import { useNavigate } from "react-router";
import { setCourseSignupSession } from "../../utilities/navUtils";
import { CourseSignUpBaseUrl } from "routes";
import { useDispatch, useSelector } from "react-redux";
import Layouts from "constants/layout-constants";
import GroupFinderMobileView from "./group-finder-mobile-view";
import { MapLocation } from "lib/data/model";
import "./group-finder.scss";
import { GroupDetails } from "lib/models-v2";
import { NoticeCreation } from "lib/data/model";

interface IGroupFinder {
  limitToParentPrograms?: number[];
  limitToProviders?: number[];
  limitToPrograms?: number[];
}

const GroupFinderV2: React.VFC<IGroupFinder> = (props: IGroupFinder) => {

  const joinNowStore = useGroupFinderStore();
  const navigate = useNavigate();
  const layout = useSelector((state: any) => state.appReducer.layout);
  const reduxDispatch = useDispatch();
  const dismissAllNotices = dispatchers(reduxDispatch).dismissAllNotices as () => void;
  const notify = dispatchers(reduxDispatch).notify as (notice: NoticeCreation) => void;

  const { defaultSearchRadius, defaultMapZoom, currentView, currentLocation, setCurrentLocation } = joinNowStore;
  const [showTopBar, setShowTopBar] = useState<boolean>(false);
  const [isReadyForFirstSearch, setIsReadyForFirstSearch] = useState<boolean>(false);
  const [availablePrograms, setAvailablePrograms] = useState<CourseDto[]>([]);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const prevAbortController = usePrevious<AbortController | null>(abortController);

  const intl = useIntl();
  const preLoadStore = usePreloadStore((store) => ({
    groupTypes: store.groupTypes,
    languages: store.languages,
  }));

  const getMapCoordinates = async (): Promise<MapLocation | undefined> => {
    const userLocation = await getPositionOrDefault();

    switch (userLocation.status) {
      case GeolocationStatus.PermissionDenied: {
        notify({
          status: "warning",
          message: intl.formatMessage({
            id: "location_permission_denied",
            defaultMessage:
              "We were unable to get your location. This may be because Location Services are off or we don't have permission to use your location.",
          }),
        });
        return;
      }
      case GeolocationStatus.LocationUnavailable: {
        notify({
          status: "warning",
          message: intl.formatMessage({
            id: "location_unavailable",
            defaultMessage: "We were unable to get your location.",
          }),
        });
        return;
      }
    }

    if (userLocation.location) {
      const center = {
        lat: userLocation.location.lat,
        lng: userLocation.location.lng
      };

      return {
        center,
        bounds: calculateBounds(center, defaultSearchRadius),
        zoom: defaultMapZoom
      };
    }

  };

  const courseSignup = (group: GroupDetails) => {
    if (group.providerId && group.locationId) {
      setCourseSignupSession(
        group.providerId,
        group,
        `${window.location.origin}${window.location.pathname}`
      );
      navigate(
        `${CourseSignUpBaseUrl}/${group.id}`
      );
    }
  };

  useEffect(() => {

    const getMapCoords = async () => {
      const mapCoords = await getMapCoordinates();
      if (mapCoords) {
        setCurrentLocation(mapCoords);
      }
    };

    if (!currentLocation) {
      getMapCoords();
    }

  }, []);

  useEffect(() => {
    if (prevAbortController) {
      prevAbortController.abort();
    }
  }, [abortController]);

  useEffect(() => {
    if (preLoadStore.languages.length > 0) {
      setShowTopBar(true);
    }

    if (preLoadStore.groupTypes.length > 0 && currentLocation) {
      setIsReadyForFirstSearch(true);
    }
  }, [preLoadStore]);

  useEffect(() => {
    if (isReadyForFirstSearch && currentLocation) {
      searchLocation();
    }
  }, [isReadyForFirstSearch]);

  useEffect(() => {
    if (preLoadStore.groupTypes.length > 0) {
      const providerIds = props.limitToProviders && props.limitToProviders.length > 0 ? props.limitToProviders : getProviders(preLoadStore as PreloadStore).map((x) => x.id!);
      const newAvailablePrograms = getGroupTypesByProviders(preLoadStore as PreloadStore, providerIds);
      setAvailablePrograms(newAvailablePrograms);
    }
  }, [preLoadStore.groupTypes]);

  const searchLocation = async (area?: MapLocation) => {
    const searchArea = area ? area : currentLocation;
    if (searchArea) {
      joinNowStore.setResults([]);

      joinNowStore.setIsLoading(true);
      dismissAllNotices();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);

      try {
        const result = (await getAreaGroups(
          searchArea.bounds,
          newAbortController.signal,
          true,
          true,
          props.limitToParentPrograms,
          props.limitToProviders,
          props.limitToPrograms
        ))?.list;

        joinNowStore.setResults(result ? result : []);

        if (!result || result.length < 1) {
          findNearestGroups();
        }

      } catch (e: any) {
        //Probabbly cancelled on purpose, ignoring cancelled exceptions
        //axios spells cancelled with only one L
        if (e?.code && e.code == "ERR_CANCELED") {
          return;
        }
        console.error(e);
        notify({
          status: "error",
          message: intl.formatMessage({
            id: "get_groups_error",
            defaultMessage: "Could not get groups.",
          }),
        });
        joinNowStore.setIsLoading(false);
      }
    }
  };

  const findNearestGroups = async (location?: MapLocation) => {
    const searchLocation = location ? location : currentLocation;
    if (searchLocation) {
      joinNowStore.setResults([]);

      joinNowStore.setIsLoading(true);
      dismissAllNotices();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);

      try {
        const nearestResult = (await getNearestGroups(
          searchLocation.center.lat,
          searchLocation.center.lng,
          newAbortController.signal,
          true,
          true,
          props.limitToParentPrograms,
          props.limitToProviders,
          props.limitToPrograms,
          1 //Only getting the first group, then will search the area around that group.
        ))?.list;

        const areaResults = (await getAreaGroups(
          calculateBounds({ lat: nearestResult![0].latitude, lng: nearestResult![0].longitude }, defaultSearchRadius),
          newAbortController.signal,
          true,
          true,
          props.limitToParentPrograms,
          props.limitToProviders,
          props.limitToPrograms
        ))?.list;

        joinNowStore.setResults(areaResults ? areaResults : []);

        const newBounds = new google.maps.LatLngBounds();
        areaResults?.forEach(group => {
          newBounds.extend({
            lat: group.latitude,
            lng: group.longitude
          });
        });
        const boundsCenter = newBounds.getCenter();
        const newCenter = {
          lat: boundsCenter.lat(),
          lng: boundsCenter.lng()
        };
        const newLocation = {
          center: newCenter,
          bounds: {
            swLat: newBounds.getSouthWest().lat(),
            swLng: newBounds.getSouthWest().lng(),
            neLat: newBounds.getNorthEast().lat(),
            neLng: newBounds.getNorthEast().lng(),
          },
          zoom: defaultMapZoom
        };
        setCurrentLocation(newLocation);
        showExpandedMapNotice();

      } catch (e: any) {
        //Probabbly cancelled on purpose, ignoring cancelled exceptions
        //axios spells cancelled with only one L
        if (e?.code && e.code == "ERR_CANCELED") {
          return;
        }
        console.error(e);
        notify({
          status: "error",
          message: intl.formatMessage({
            id: "get_groups_error",
            defaultMessage: "Could not get groups.",
          }),
        });
        joinNowStore.setIsLoading(false);
      }
    }
  };

  const showExpandedMapNotice = (): void => {
    notify({
      status: "success",
      message: (
        <FormattedMessage
          id="no_locations_map_expanded"
          defaultMessage="There were no nearby locations, we have expanded the map to show you the nearest location."
        />
      )
    });
  };

  return (
    <section>
      {getValueFromQueryStringKey("showEligibilityError") == "1" && (
        <StatusBox
          status="warning"
          head={
            <FormattedMessage
              id="select_another_group"
              defaultMessage="Please select another group"
            />
          }
        >
          <FormattedMessage
            id="not_eligible_group"
            defaultMessage="You are not eligible for the selected group. Please choose a group below."
          />
        </StatusBox>
      )}
      <div className="group-finder-container">
        {showTopBar &&
          <div className="group-finder-filter-header">
            <GroupFinderListControls
              availablePrograms={availablePrograms}
              searchArea={searchLocation}
            />
          </div>
        }
        {layout != Layouts.Mobile ?
          <>
            {currentView === GroupFinderView.mapView && (
              <GroupFinderMapView
                courseSignup={courseSignup}
                searchArea={searchLocation}
              />
            )}
            {currentView === GroupFinderView.listView && (
              <GroupFinderListView
                courses={availablePrograms}
                onSelect={courseSignup}
              />
            )}
          </>
          :
          <GroupFinderMobileView
            courseSignup={courseSignup}
            searchArea={searchLocation}
          />
        }
      </div>
    </section>
  );
};

export default GroupFinderV2;
