import "./group-finder-list-view.scss";

import React from "react";
import { mapStateToProps, mapDispatchToProps } from "datastore/store";
import { connect } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import Loading from "components/loading/loading";
import Layouts from "constants/layout-constants";
import GroupFinderCard from "../group-finder-card/group-finder-card";
import GroupFinderListTable from "./group-finder-list-table/group-finder-list-table";
import { CourseDto } from "lib/api/backend/model/course";
import useGroupFinderStore from "../group-finder-store";
import { GroupDetails } from "lib/models-v2";

interface IProps {
  store: any;
  courses: CourseDto[];
  onSelect: (group: GroupDetails) => void;
}

const GroupFinderListView: React.VFC<IProps> = (props: IProps) => {
  const { store: { layout } } = props;
  const intl = useIntl();

  const joinNowStore = useGroupFinderStore();
  const { isLoading, filteredGroups, currentLocation } = joinNowStore;

  const handleCourseSignup = (group) => {
    if (props.onSelect) {
      props.onSelect(group);
    }
  };

  return (
    <div className="groupFinderListViewContainer hasLoader">
      <Loading loading={isLoading} message={intl.formatMessage({ id: "getting_groups", defaultMessage: "Getting Groups" })} blackout={true} />
      {currentLocation && !isLoading ?
        <>
          {
            layout == Layouts.Desktop
            && <GroupFinderListTable groups={filteredGroups} onCourseSignup={handleCourseSignup} />
          }

          {
            (layout == Layouts.Tablet || layout == Layouts.Mobile) &&
            <>
              {filteredGroups && filteredGroups.map((group, group_idx) => {
                return (
                  <GroupFinderCard key={`group-finder-card-${group_idx}`} group={group} onSelect={() => handleCourseSignup(group)} />
                );
              })}
            </>
          }

          {
            (!filteredGroups || (filteredGroups && filteredGroups.length === 0)) && !isLoading &&
            <div className="small-group-search-message-box">
              <FormattedMessage id="groups_not_found_search_message" defaultMessage="No groups found in your search area."></FormattedMessage>
            </div>
          }
        </>
        :
        <div className="no-location-container">
          <p className="no-location"><FormattedMessage id="enter_search_location" defaultMessage="Enter a search location to see results." /></p>
        </div>
      }
    </div >
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupFinderListView);