import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "../datastore/store";
import * as Users from "../datastore/user";
import authService from "./authorize-service";
import { AuthResult } from "./models";
import { navigateToReturnUrl, getReturnUrl } from "../utilities/navUtils";
import Loading from "components/loading/loading";
import { useIntl } from "react-intl";
import { withRouter } from "utilities/withRouter";

const LoginCallback = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {

    const processSignIn = async (url) => {

      const result = await authService.completeSignIn(url);

      switch (result.status) {
        case AuthResult.Success:
          dispatch(Users.loginComplete());
          navigateToReturnUrl(result.state.returnUrl);
          break;
        case AuthResult.Fail:
          dispatch(Users.loginError(result.message));
          navigateToReturnUrl(getReturnUrl());
          break;
        default:
          throw new Error(`Invalid authentication result status '${result.status}'.`);
      }
    };

    processSignIn(window.location.href);

  }, []);

  return <Loading loading={true} message={intl.formatMessage({ id: "logging_in", defaultMessage: "Logging In" })} />;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginCallback));