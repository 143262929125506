import { ReactElement, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import AuthService from "auth/authorize-service";
import { Permissions } from "constants/api-auth-constants";
import { StudentStatusesV2 } from "constants/student-constants";
import dispatchers from "datastore/dispatcher";
import { getAndDownloadGroupParticipantCertificates } from "lib/api/backend/requests/certificates";
import { Alert, LoadingStatus, NoticeCreation, Prompt } from "lib/data/model";
import { CertificateFailureType, CertificateFailureTypeTransLationsKeys } from "constants/certificate-constants";
import { GroupStatusesAllowedToPrint } from "constants/group-constants";
import ListActionMenu, { ListActionMenuDefaultAction } from "components/list-action-menu";
import useGroupStore from "datastore/groups";
import { ParticipantGroupDto } from "lib/api/backend/model/participant/ParticipantGroupDto";
import ParticipantCertificateFailureDto from "lib/api/backend/model/participant/ParticipantCertificateFailureDto";
import { dropParticipants, recieveConsents } from "lib/api/backend/requests/participant";
import MoveParticipantDialog, { MoveParticipantToggleButton } from "components/move-participant-dialog";

export interface IParticipantActionMenu {
  participant: ParticipantGroupDto;
  forceRight?: boolean;
  borderOnToggle?: boolean;
  onParticipantChange?: (updatedParticipant: ParticipantGroupDto) => void;
  onLoadingChange?: (loading: LoadingStatus) => void;
  alignRight?: boolean;
}

export const ParticipantListActionMenu = (props: IParticipantActionMenu): ReactElement => {

  const { participant, onParticipantChange, onLoadingChange } = props;

  const groupStore = useGroupStore();
  const canManageStudents = AuthService.userCan([Permissions.ManageGroupsWrite], participant.groupStakeUnitNumber);
  const canPrintCompletionCerts = AuthService.userCan([Permissions.PrintStudentCompletionCertificatesWrite], participant.groupStakeUnitNumber);
  const participantStatus = participant.status;
  const reduxDispatch = useDispatch();
  const prompt = dispatchers(reduxDispatch).prompt as (prompt: Prompt) => void;
  const notify = dispatchers(reduxDispatch).notify as (notice: NoticeCreation) => void;
  const alert = dispatchers(reduxDispatch).alert as (notice: Alert) => void;
  const navigate = useNavigate();
  const intl = useIntl();
  const [showMoveDialog, setShowMoveDialog] = useState<boolean>(false);

  const removeStudent = async () => {
    if (canManageStudents) {
      const shouldContinue = await confirmRemovePrompt();
      if (shouldContinue) {
        if (participant) {
          try {
            updateParentLoading({ status: true, message: <FormattedMessage id="removing_participant" defaultMessage="Removing Participant" /> });
            const result = dropParticipants([participant.id]);
            if (result instanceof Error) {
              throw result;
            }
            updateEnrollmentStatus(StudentStatusesV2.NotEnrolled);
            updateParentLoading({ status: false, message: "" });
          } catch (error) {
            console.error(error);
            notify({ status: "error", message: <FormattedMessage id="enrollment_update_error" defaultMessage="An error occurred while attempting to update the enrollment." /> });
            updateParentLoading({ status: false, message: "" });
          }
        } else {
          alert({ status: "error", head: <FormattedMessage id="could_not_find_participant" defaultMessage="Could not find participant." />, message: "" });
        }
      }
    }
  };

  const confirmRemovePrompt = () => {
    return new Promise((resolve) => {
      prompt(
        {
          status: "warning",
          head: <FormattedMessage id="please_confirm" defaultMessage="Please Confirm" />,
          message: <FormattedMessage id="confirm_remove_participants" defaultMessage="Are you sure you want to remove participants? This cannot be undone" />,
          buttons: <><button onClick={() => { resolve(true); }}><FormattedMessage id="continue" defaultMessage="Continue" /></button><button onClick={() => { resolve(false); }}><FormattedMessage id="cancel" defaultMessage="Cancel" /></button></>
        }
      );
    });
  };

  const updateConsent = async () => {
    if (canManageStudents) {
      let result;
      if (participant && participantStatus == StudentStatusesV2.NeedsConsent) {
        try {
          updateParentLoading({ status: true, message: <FormattedMessage id="receiving_consent" defaultMessage="Recording Consent Form as received" /> });

          result = await recieveConsents([participant.id]);

          if (!result) {
            throw result;
          }
          updateEnrollmentStatus(StudentStatusesV2.Enrolled);
          updateParentLoading({ status: false, message: "" });

        } catch (error) {
          notify({ status: "error", message: <FormattedMessage id="enrollment_update_error" defaultMessage="An error occurred while attempting to update the enrollment." /> });
          updateParentLoading({ status: false, message: "" });
        }
      } else {
        alert({ status: "error", head: <FormattedMessage id="could_not_find_participant" defaultMessage="Could not find participant." />, message: "" });
      }
    }
  };

  const handlePrintParticipationCert = async (isParticipation: boolean) => {
    if (!canManageStudents) {
      return;
    }
    if (isParticipation && !participant.programParticipationCertificateId) {
      return;
    }
    if (!isParticipation && (!participant.programCompletionCertificateId || participantStatus != StudentStatusesV2.Completed)) {
      return;
    }
    updateParentLoading({ status: true, message: <FormattedMessage id="getting_cert" defaultMessage="Getting certificate..." /> });
    if (participant?.groupId && participant.id) {
      const failures = await getAndDownloadGroupParticipantCertificates(participant.groupId, [participant.id], isParticipation);
      if (failures.length > 0) {
        showPrintFailureAlert(failures[0]);
      }
    }
    updateParentLoading({ status: false, message: "" });
  };

  const updateParentLoading = (loading: LoadingStatus) => {
    if (onLoadingChange) {
      onLoadingChange(loading);
    }
  };

  const updateEnrollmentStatus = (status: string) => {
    if (status) {
      const groupToUpdate = groupStore.getGroup(participant.groupId!);
      if (groupToUpdate) {
        const participantIndex = groupToUpdate.quickRegParticipants.findIndex(groupParticipant => groupParticipant.id == participant.id);
        if (participantIndex) {
          groupToUpdate.quickRegParticipants[participantIndex].status = status;
          groupStore.editGroup(groupToUpdate);
        }
      }
      if (onParticipantChange) {
        onParticipantChange({ ...participant, status });
      }
    }
  };

  const showPrintFailureAlert = (failure: ParticipantCertificateFailureDto) => {
    alert({
      status: "error",
      head: <FormattedMessage id="get_cert_error" defaultMessage="An error occured while retrieving certificates." />,
      message: (
        <>
          <p><FormattedMessage id="not_included_certificates" defaultMessage="The following certificates were not included. This may be because the selected certificate type is not available for the student or group." /></p>
          <ul>
            <li>
              <span>{participant.givenName} {participant.surname} - {participant.programName}</span>
              {failure.failureReason !== CertificateFailureType.Unknown &&
                <>
                  <br />
                  <span><FormattedMessage id={CertificateFailureTypeTransLationsKeys[failure.failureReason].key} defaultMessage={CertificateFailureTypeTransLationsKeys[failure.failureReason].defaultMessage} /></span>
                </>
              }
            </li>
          </ul>
        </>
      )
    });
  };

  const goToParticipantDetails = () => {
    navigate(`/participant/${participant.id}/group/${participant.groupId}`,
      { state: { returnUrl: location.pathname } });
  };

  const defaultMenuAction = ((): ListActionMenuDefaultAction => {
    const defaultAction = {
      label: intl.formatMessage({ id: "edit_participant", defaultMessage: "Edit Participant" }),
      handler: goToParticipantDetails
    };
    switch (participantStatus) {
      case StudentStatusesV2.NeedsConsent:
        return {
          label: intl.formatMessage({ id: "consent_received", defaultMessage: "Consent Form Received" }),
          handler: updateConsent
        };
      case StudentStatusesV2.PrintCertificate:
        if (participant!.programParticipationCertificateId && GroupStatusesAllowedToPrint.includes(participant!.groupStatus) && canPrintCompletionCerts) {
          return {
            label: intl.formatMessage({ id: "print_certificate", defaultMessage: "print_certificate" }),
            handler: updateConsent
          };
        }
        return defaultAction;
      default:
        return defaultAction;
    }
  })();

  return (
    <>
      <ListActionMenu defaultAction={defaultMenuAction} alignRight={props.alignRight}>
        {participant &&
          <>
            {participantStatus == StudentStatusesV2.NeedsConsent && canManageStudents &&
              <button onClick={updateConsent}>
                <FormattedMessage id="consent_received" defaultMessage="Consent Form Received" />
              </button>
            }

            {participantStatus != StudentStatusesV2.NotEnrolled && canManageStudents &&
              <button onClick={removeStudent}>
                <FormattedMessage id="remove_participant" defaultMessage="Remove Participant" />
              </button>
            }

            <Link
              to={`/participant/${participant.id}/group/${participant.groupId}`}
              state={{ returnUrl: location.pathname }}
            >
              <button onClick={goToParticipantDetails}>
                <FormattedMessage id="edit_participant" defaultMessage="Edit Participant" />
              </button>
            </Link>

            <MoveParticipantToggleButton onToggle={() => setShowMoveDialog(!showMoveDialog)} />

            {((participant.programCompletionCertificateId || participant.programParticipationCertificateId) && participantStatus !== StudentStatusesV2.NotEnrolled && GroupStatusesAllowedToPrint.includes(participant!.groupStatus)) ?
              <>

                {(participant.programParticipationCertificateId || (participant.programCompletionCertificateId && (participantStatus == StudentStatusesV2.Enrolled || participantStatus == StudentStatusesV2.Completed))) &&
                  <span className="menuGroupHead"><FormattedMessage id="print" defaultMessage="Print" /></span>
                }

                {participant.programParticipationCertificateId && canManageStudents &&
                  <button onClick={() => handlePrintParticipationCert(true)}>
                    <FormattedMessage id="participation_certificate" defaultMessage="Participation Certificate(s)" />
                  </button>
                }

                {((participantStatus == StudentStatusesV2.Enrolled || participantStatus == StudentStatusesV2.Completed) && participant.programCompletionCertificateId) && canPrintCompletionCerts &&
                  <button onClick={() => handlePrintParticipationCert(false)}>
                    <FormattedMessage id="completion_certificate" defaultMessage="Completion Certifcate(s)" />
                  </button>
                }
              </>
              :
              <>
                <p><FormattedMessage id="no_print" defaultMessage="Nothing Available to Print" /></p>
              </>
            }
          </>
        }
      </ListActionMenu>
      <MoveParticipantDialog participant={participant} show={showMoveDialog} onClose={() => setShowMoveDialog(false)} />
    </>
  );
};

export default ParticipantListActionMenu;