import "./weekday-hour-selector.scss";

import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  Button,
  IconButton,
  MenuItem,
  TextField
} from "@mui/material";
import {
  Clear as ClearIcon,
  RemoveCircleOutline as RemoveIcon
} from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import useClassName from "utilities/useClassName";
import { Group } from "lib/data/model";
import { DayOfWeek } from "lib/data/model/enum";
import { useFormStatus } from "lib/form/contexts";
import { useGroup } from "lib/data/contexts";
import { FormStatus, SaveState } from "lib/form/model";
import { MeetingTimeDto } from "lib/api/backend/model";
import { MobileTimePicker } from "@mui/x-date-pickers";

const WeekdayHourSelectorErrorKeys = Object.freeze({
  meetingTime: "times",
  weekday: "weekday",
  startTime: "startTime",
  endTime: "endTime"
});

interface IWeekdayHourSelectorProps {
  className?: string;
  meetingTimes?: MeetingTimeDto[];
}

const WeekdayHourSelector: React.VFC<IWeekdayHourSelectorProps> = (props) => {
  const [className, setPropsClassNames] = useClassName("WeekdayHour-SelectorContainer");
  const language = useSelector((state: any) => state.appReducer.language);
  const displayLanguage = useRef(language);
  const [formStatus, setFormStatus] = useFormStatus();
  const [group, setGroup] = useGroup();

  const [meetingTimes, setMeetingTimes] = useState<(MeetingTimeDto | undefined)[]>([]);

  const intl = useIntl();

  useEffect(
    () => {
      if (props.className) {
        setPropsClassNames(props.className);
      }
      else {
        setPropsClassNames();
      }
    },
    [props.className]
  );

  useEffect(
    () => {
      const validateMeetingTimes = (meetingTimesToValidate: (MeetingTimeDto | undefined)[]) => {
        meetingTimesToValidate.forEach((mt, index) => {
          isWeekdayValid(index, mt?.dayOfWeek, meetingTimesToValidate!);
          isStartTimeValid(index, mt?.startTime ? dayjs(mt.startTime) : undefined, meetingTimesToValidate!);
          isEndTimeValid(index, mt?.endTime ? dayjs(mt.endTime) : undefined, meetingTimesToValidate!);
        });
        areMeetingTimesValid(meetingTimesToValidate);
      };

      if (props.meetingTimes) {
        validateMeetingTimes(props.meetingTimes);
        setMeetingTimes(props.meetingTimes);
      }
      else {
        validateMeetingTimes(meetingTimes);
      }
    },
    [props.meetingTimes, group?.isPending]
  );

  useEffect(() => {
    if (displayLanguage.current != language) {
      validateForNewLanguage();
    }
  }, [language]);

  const validateForNewLanguage = () => {
    const clearValidationMessages = () => {
      meetingTimes.forEach((_, index) => {
        formStatus.errors.clearMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${index}`, WeekdayHourSelectorErrorKeys.weekday);
        formStatus.errors.clearMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${index}`, WeekdayHourSelectorErrorKeys.startTime);
        formStatus.errors.clearMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${index}`, WeekdayHourSelectorErrorKeys.endTime);
      });
      formStatus.errors.clearMessages(WeekdayHourSelectorErrorKeys.meetingTime);
    };

    const validateMeetingTimes = () => {
      meetingTimes.forEach((mt, index) => {
        isWeekdayValid(index, mt?.dayOfWeek, meetingTimes);
        isStartTimeValid(index, mt?.startTime ? dayjs(mt.startTime) : undefined, meetingTimes);
        isEndTimeValid(index, mt?.endTime ? dayjs(mt.endTime) : undefined, meetingTimes);
      });
      areMeetingTimesValid(meetingTimes);
    };

    clearValidationMessages();
    validateMeetingTimes();

    setFormStatus(new FormStatus(formStatus));
  };

  const handleStartTimeChange = (recordKey: number, newStartTime: Date | undefined, meetingTimes: (MeetingTimeDto | undefined)[]) => {
    const meetingTime = meetingTimes.at(recordKey);

    if (meetingTime) {
      const dayjsStartTime = newStartTime ? dayjs(newStartTime) : undefined;

      meetingTime.startTime = dayjsStartTime?.toDate();

      setMeetingTimes([...meetingTimes]);

      formStatus.dirtiness.setDirty(true, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.startTime);

      setFormStatus(
        new FormStatus(formStatus)
      );
      if (isStartTimeValid(recordKey, dayjsStartTime, meetingTimes) && areMeetingTimesValid(meetingTimes)) {
        setGroup(
          new Group({
            ...group!,
            meetingTimes: meetingTimes.filter(mt => !!mt) as MeetingTimeDto[]
          })
        );
      }
    }
  };

  const isStartTimeValid = (recordKey: number, startTime: Dayjs | undefined, meetingTimes: (MeetingTimeDto | undefined)[]) => {
    let isValid = true;

    const timeRequiredErrorMessage = intl.formatMessage({ id: "required", defaultMessage: "Required" });

    if (!startTime) {
      isValid = false;

      formStatus.errors.addMessage(timeRequiredErrorMessage, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.startTime);
    }
    else {
      formStatus.errors.removeMessage(timeRequiredErrorMessage, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.startTime);
    }

    const meetingTime = meetingTimes.at(recordKey);

    if (!meetingTime) {
      isValid = false; // Should there be an error message if/when this occurs?
    }
    else {
      const startTimeAfterEndTimeErrorMessage = intl.formatMessage({ id: "invalid_gathering_time", defaultMessage: "Please make sure the start time is before the end time." });

      if (startTime && meetingTime.endTime && !startTime.isBefore(dayjs(meetingTime.endTime))) {
        isValid = false;

        formStatus.errors.addMessage(startTimeAfterEndTimeErrorMessage, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.startTime);
      }
      else {
        formStatus.errors.removeMessage(startTimeAfterEndTimeErrorMessage, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.startTime);
      }
    }

    formStatus.validity.setValidity(isValid, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.startTime);

    setFormStatus(
      new FormStatus(formStatus)
    );

    return isValid;
  };

  const handleEndTimeChange = (recordKey: number, newEndTime: Date | undefined, meetingTimes: (MeetingTimeDto | undefined)[]) => {
    const meetingTime = meetingTimes.at(recordKey);

    if (meetingTime) {
      const dayjsEndTime = newEndTime ? dayjs(newEndTime) : undefined;

      meetingTime.endTime = dayjsEndTime?.toDate();

      setMeetingTimes([...meetingTimes]);

      formStatus.dirtiness.setDirty(true, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.endTime);

      setFormStatus(
        new FormStatus(formStatus)
      );
      if (isEndTimeValid(recordKey, dayjsEndTime, meetingTimes) && areMeetingTimesValid(meetingTimes)) {
        setGroup(
          new Group({
            ...group!,
            meetingTimes: meetingTimes.filter(mt => !!mt) as MeetingTimeDto[]
          })
        );
      }
    }
  };

  const isEndTimeValid = (recordKey: number, endTime: Dayjs | undefined, meetingTimes: (MeetingTimeDto | undefined)[]) => {
    let isValid = true;

    const meetingTime = meetingTimes.at(recordKey);

    if (!meetingTime) {
      isValid = false; // Should there be an error message if/when this occurs?
    }
    else {
      const startTimeAfterEndTimeErrorMessage = intl.formatMessage({ id: "invalid_gathering_time", defaultMessage: "Please make sure the start time is before the end time." });

      if (meetingTime.startTime && endTime && !dayjs(meetingTime.startTime).isBefore(endTime)) {
        isValid = false;

        formStatus.errors.addMessage(startTimeAfterEndTimeErrorMessage, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.endTime);
      }
      else {
        formStatus.errors.removeMessage(startTimeAfterEndTimeErrorMessage, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.endTime);
      }
    }

    formStatus.validity.setValidity(isValid, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.endTime);

    setFormStatus(
      new FormStatus(formStatus)
    );

    return isValid;
  };

  const handleWeekdayChange = (recordKey: number, newWeekday?: DayOfWeek) => {
    const meetingTime = meetingTimes.at(recordKey);

    if (meetingTime) {
      meetingTime.dayOfWeek = newWeekday;

      setMeetingTimes([...meetingTimes]);

      formStatus.dirtiness.setDirty(true, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.weekday);

      setFormStatus(
        new FormStatus(formStatus)
      );
      if (isWeekdayValid(recordKey, newWeekday, meetingTimes) && areMeetingTimesValid(meetingTimes)) {
        setGroup(
          new Group({
            ...group!,
            meetingTimes: meetingTimes.filter(mt => !!mt) as MeetingTimeDto[]
          })
        );
      }
    }
  };

  const isWeekdayValid = (recordKey: number, weekday: DayOfWeek | undefined, meetingTimes: (MeetingTimeDto | undefined)[]) => {
    let isValid = true;

    const meetingTime = meetingTimes.at(recordKey);

    if (!meetingTime) {
      isValid = false; // Should there be an error message if/when this occurs?
    }
    else {
      const weekdayRequiredErrorMessage = intl.formatMessage({ id: "required", defaultMessage: "Required" });

      if (weekday === undefined) {
        isValid = false;

        formStatus.errors.addMessage(weekdayRequiredErrorMessage, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.weekday);
      }
      else {
        formStatus.errors.removeMessage(weekdayRequiredErrorMessage, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.weekday);
      }
    }

    formStatus.validity.setValidity(isValid, WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.weekday);

    setFormStatus(
      new FormStatus(formStatus)
    );

    return isValid;
  };

  const areMeetingTimesValid = (meetingTimes: (MeetingTimeDto | undefined)[]) => {
    let areValid = true;

    const meetingOverlapErrorMessage = intl.formatMessage({ id: "meeting_overlap_error", defaultMessage: "Please make sure all meetings happen at different times or different days." });

    for (let i = 0; i < meetingTimes.length; i++) {
      formStatus.errors.removeMessage(meetingOverlapErrorMessage, WeekdayHourSelectorErrorKeys.meetingTime, `${i}`);
      formStatus.validity.setValidity(areValid, WeekdayHourSelectorErrorKeys.meetingTime, `${i}`);
    }

    for (let i = 0; i < meetingTimes.length - 1; i++) {
      const a = meetingTimes[i];

      for (let j = i + 1; j < meetingTimes.length; j++) {
        const b = meetingTimes[j];

        if (!!a && !!b) {
          const aWeekday = a.dayOfWeek;
          const bWeekday = b.dayOfWeek;

          if (aWeekday && bWeekday && aWeekday === bWeekday) {
            const aStartTime = a.startTime ? dayjs(a.startTime) : undefined;
            const aEndTime = a.endTime ? dayjs(a.endTime) : undefined;

            const bStartTime = b.startTime ? dayjs(b.startTime) : undefined;
            const bEndTime = b.endTime ? dayjs(b.endTime) : undefined;

            if ((!aStartTime || !bEndTime || aStartTime.isBefore(bEndTime)) && (!aEndTime || !bStartTime || aEndTime.isAfter(bStartTime))) {
              areValid = false;

              formStatus.dirtiness.setDirty(true, WeekdayHourSelectorErrorKeys.meetingTime, `${i}`);
              formStatus.dirtiness.setDirty(true, WeekdayHourSelectorErrorKeys.meetingTime, `${j}`);
              formStatus.errors.addMessage(meetingOverlapErrorMessage, WeekdayHourSelectorErrorKeys.meetingTime, `${i}`);
              formStatus.errors.addMessage(meetingOverlapErrorMessage, WeekdayHourSelectorErrorKeys.meetingTime, `${j}`);
              formStatus.validity.setValidity(areValid, WeekdayHourSelectorErrorKeys.meetingTime, `${i}`);
              formStatus.validity.setValidity(areValid, WeekdayHourSelectorErrorKeys.meetingTime, `${j}`);
            }
          }
        }
      }
    }

    const meetingTimeRequiredErrorMessage = intl.formatMessage({ id: "meeting_time_required", defaultMessage: "At least one meeting day and time is required." });

    if (!group?.isPending && meetingTimes.filter(mt => !!mt).length <= 0) {
      areValid = false;
      formStatus.errors.addMessage(meetingTimeRequiredErrorMessage, WeekdayHourSelectorErrorKeys.meetingTime);
    }
    else {
      formStatus.errors.removeMessage(meetingTimeRequiredErrorMessage, WeekdayHourSelectorErrorKeys.meetingTime);
    }

    formStatus.validity.setValidity(areValid, WeekdayHourSelectorErrorKeys.meetingTime);

    setFormStatus(
      new FormStatus(formStatus)
    );

    return areValid;
  };

  const handleMeetingTimeAddition = () => {
    const newMeetingTime = new MeetingTimeDto();
    meetingTimes.push(newMeetingTime);

    setMeetingTimes([
      ...meetingTimes
    ]);

    isWeekdayValid(meetingTimes.length - 1, newMeetingTime.dayOfWeek, meetingTimes);
    isStartTimeValid(meetingTimes.length - 1, newMeetingTime.startTime ? dayjs(newMeetingTime.startTime) : undefined, meetingTimes);
    isEndTimeValid(meetingTimes.length - 1, newMeetingTime.endTime ? dayjs(newMeetingTime.endTime) : undefined, meetingTimes);
    areMeetingTimesValid(meetingTimes);
  };

  const handleMeetingTimeRemoval = (recordKey: number) => {

    if (meetingTimes.at(recordKey)) {

      const newMeetingTimes = [...meetingTimes];
      newMeetingTimes.splice(recordKey, 1);
      const oldMeetingTimesLastIndex = meetingTimes.length > 0 ? meetingTimes.length - 1 : 0;
      setMeetingTimes(newMeetingTimes);

      formStatus.errors.clearMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${oldMeetingTimesLastIndex}.`);
      formStatus.messages.clearMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${oldMeetingTimesLastIndex}.weekday`);
      formStatus.dirtiness.reset(WeekdayHourSelectorErrorKeys.meetingTime, `${oldMeetingTimesLastIndex}.weekday`);
      formStatus.validity.reset(WeekdayHourSelectorErrorKeys.meetingTime, `${oldMeetingTimesLastIndex}.weekday`);

      formStatus.errors.clearMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${oldMeetingTimesLastIndex}.startTime`);
      formStatus.messages.clearMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${oldMeetingTimesLastIndex}.startTime`);
      formStatus.dirtiness.reset(WeekdayHourSelectorErrorKeys.meetingTime, `${oldMeetingTimesLastIndex}.startTime`);
      formStatus.validity.reset(WeekdayHourSelectorErrorKeys.meetingTime, `${oldMeetingTimesLastIndex}.startTime`);

      formStatus.errors.clearMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${oldMeetingTimesLastIndex}.endTime`);
      formStatus.messages.clearMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${oldMeetingTimesLastIndex}.endTime`);
      formStatus.dirtiness.reset(WeekdayHourSelectorErrorKeys.meetingTime, `${oldMeetingTimesLastIndex}.endTime`);
      formStatus.validity.reset(WeekdayHourSelectorErrorKeys.meetingTime, `${oldMeetingTimesLastIndex}.endTime`);

      setGroup(
        new Group({
          ...group!,
          meetingTimes: newMeetingTimes.filter(mt => !!mt) as MeetingTimeDto[]
        })
      );

    }
  };

  ////////////////////
  // Render Helpers //
  ////////////////////

  const weekdayHasErrors = (recordKey: number) => (
    !formStatus.validity.isValid(WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.weekday)
    && (
      formStatus.saveState === SaveState.ValidationFailed
      || formStatus.dirtiness.isDirty(WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.weekday)
    )
  );

  const startTimeHasErrors = (recordKey: number) => (
    !formStatus.validity.isValid(WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.startTime)
    && (
      formStatus.saveState === SaveState.ValidationFailed
      || formStatus.dirtiness.isDirty(WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.startTime)
    )
  );

  const endTimeHasErrors = (recordKey: number) => (
    !formStatus.validity.isValid(WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.endTime)
    && (
      formStatus.saveState === SaveState.ValidationFailed
      || formStatus.dirtiness.isDirty(WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`, WeekdayHourSelectorErrorKeys.endTime)
    )
  );

  const meetingTimeHasErrors = (recordKey: number) => {
    const result = !formStatus.validity.isValid(WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`)
      && (
        formStatus.saveState === SaveState.ValidationFailed
        || formStatus.dirtiness.isDirty(WeekdayHourSelectorErrorKeys.meetingTime, `${recordKey}`)
      );
    return result;
  };

  const meetingTimesHaveErrors = () => (
    !formStatus.validity.isValid(WeekdayHourSelectorErrorKeys.meetingTime)
    && formStatus.saveState === SaveState.ValidationFailed
  );

  ////////////
  // Render //
  ////////////

  return (
    <div className={className}>
      <div className="WeekdayHour-Selector">
        {
          meetingTimes.length > 0
          && (
            <div className="WeekdayHour-SelectorRows">
              {
                meetingTimes.map((time, index) => {
                  if (time) {
                    const meetingHasError = meetingTimeHasErrors(index);
                    return (
                      <div className="inputRow" key={`meeting-time-${index}`}>
                        <div className="inputGroup">
                          <div className="weekdayContainer">
                            <TextField
                              select={true} // <Select />
                              className="daySelector"
                              label={intl.formatMessage({ id: "meeting_day", defaultMessage: "Meeting Day" })}
                              value={time.dayOfWeek ?? -1}
                              onChange={event => handleWeekdayChange(index, Number(event.target.value) !== -1 ? Number(event.target.value) as DayOfWeek : undefined)}
                              error={weekdayHasErrors(index) || meetingHasError}
                              helperText={
                                weekdayHasErrors(index)
                                  ? formStatus.errors.getMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${index}`, WeekdayHourSelectorErrorKeys.weekday).map(
                                    (message, messageIndex) => (
                                      <span key={`meeting-time-${index}-weekday-picker-message-${messageIndex}`}>
                                        {message}
                                      </span>
                                    ))
                                  : formStatus.messages.getMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${index}`, WeekdayHourSelectorErrorKeys.weekday).map(
                                    (message, messageIndex) => (
                                      <span key={`meeting-time-${index}-weekday-picker-message-${messageIndex}`}>
                                        {message}
                                      </span>
                                    ))
                              }
                            >
                              <MenuItem disabled={true} value={-1}>{intl.formatMessage({ id: "select_weekday", defaultMessage: "Select a Weekday" })}</MenuItem>
                              <MenuItem value={DayOfWeek.Sunday}>{intl.formatMessage({ id: "sunday", defaultMessage: "Sunday" })}</MenuItem>
                              <MenuItem value={DayOfWeek.Monday}>{intl.formatMessage({ id: "monday", defaultMessage: "Monday" })}</MenuItem>
                              <MenuItem value={DayOfWeek.Tuesday}>{intl.formatMessage({ id: "tuesday", defaultMessage: "Tuesday" })}</MenuItem>
                              <MenuItem value={DayOfWeek.Wednesday}>{intl.formatMessage({ id: "wednesday", defaultMessage: "Wednesday" })}</MenuItem>
                              <MenuItem value={DayOfWeek.Thursday}>{intl.formatMessage({ id: "thursday", defaultMessage: "Thursday" })}</MenuItem>
                              <MenuItem value={DayOfWeek.Friday}>{intl.formatMessage({ id: "friday", defaultMessage: "Friday" })}</MenuItem>
                              <MenuItem value={DayOfWeek.Saturday}>{intl.formatMessage({ id: "saturday", defaultMessage: "Saturday" })}</MenuItem>
                            </TextField>
                          </div>

                          <div className="startTimeContainer">
                            <MobileTimePicker
                              label={<FormattedMessage id="start_time" defaultMessage="Start time" />}
                              inputFormat="LT"
                              value={time.startTime ?? null}
                              onChange={(date) => handleStartTimeChange(index, date ?? undefined, meetingTimes)}
                              ignoreInvalidInputs={true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    endAdornment: <IconButton onClick={() => handleStartTimeChange(index, undefined, meetingTimes)}><ClearIcon /></IconButton>
                                  }}
                                  error={startTimeHasErrors(index) || meetingHasError}
                                  helperText={
                                    startTimeHasErrors(index)
                                      ? formStatus.errors.getMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${index}`, WeekdayHourSelectorErrorKeys.startTime).map(
                                        (message, messageIndex) => (
                                          <span key={`meeting-time-${index}-start-time-picker-message-${messageIndex}`}>
                                            {message}
                                          </span>
                                        ))
                                      : formStatus.messages.getMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${index}`, WeekdayHourSelectorErrorKeys.startTime).map(
                                        (message, messageIndex) => (
                                          <span key={`meeting-time-${index}-start-time-picker-message-${messageIndex}`}>
                                            {message}
                                          </span>
                                        ))
                                  }
                                />
                              )}
                            />
                          </div>

                          <div className="endTimeContainer">
                            <MobileTimePicker
                              label={<FormattedMessage id="end_time" defaultMessage="End time" />}
                              inputFormat="LT"
                              value={time.endTime ?? null}
                              onChange={(date) => handleEndTimeChange(index, date ?? undefined, meetingTimes)}
                              ignoreInvalidInputs={true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    endAdornment: <IconButton onClick={() => handleEndTimeChange(index, undefined, meetingTimes)}><ClearIcon /></IconButton>
                                  }}
                                  error={endTimeHasErrors(index) || meetingHasError}
                                  helperText={
                                    endTimeHasErrors(index)
                                      ? formStatus.errors.getMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${index}`, WeekdayHourSelectorErrorKeys.endTime).map(
                                        (message, messageIndex) => (
                                          <span key={`meeting-time-${index}-end-time-picker-message-${messageIndex}`}>
                                            {message}
                                          </span>
                                        ))
                                      : formStatus.messages.getMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${index}`, WeekdayHourSelectorErrorKeys.endTime).map(
                                        (message, messageIndex) => (
                                          <span key={`meeting-time-${index}-end-time-picker-message-${messageIndex}`}>
                                            {message}
                                          </span>
                                        ))
                                  }
                                />
                              )}
                            />
                          </div>

                          <div className="removeButtonContainer">
                            <IconButton className="removeRow" onClick={() => handleMeetingTimeRemoval(index)}>
                              <RemoveIcon className="removeIcon" />
                              <FormattedMessage id="remove" defaultMessage="Remove" />
                            </IconButton>
                          </div>
                        </div>

                        {
                          meetingTimeHasErrors(index)
                          && (
                            <div className="validationErrors">
                              {
                                formStatus.errors.getMessages(WeekdayHourSelectorErrorKeys.meetingTime, `${index}`)
                                  .map(
                                    (error, messageIndex) => (
                                      <div className="error" key={`meeting-time-${index}-message-${messageIndex}`}>{error}</div>
                                    )
                                  )
                              }
                            </div>
                          )
                        }
                      </div>
                    );
                  }
                })
              }
            </div>
          )
        }

        <Button className="addFieldButton" variant="outlined" onClick={handleMeetingTimeAddition}>
          <FormattedMessage id="add_day" defaultMessage="Add Day" />
        </Button>
      </div>

      {
        meetingTimesHaveErrors()
        && (
          <div className="validationErrors">
            {
              formStatus.errors.getMessages(WeekdayHourSelectorErrorKeys.meetingTime)
                .map(
                  (error, messageIndex) => (
                    <div className="error" key={`meeting-times-message-${messageIndex}`}>{error}</div>
                  )
                )
            }
          </div>
        )
      }
    </div >
  );
};

export default WeekdayHourSelector;