import "./group-finder-map-view.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import MapsLoader from "components/maps-loader";
import GroupFinderMapViewList from "../group-finder-map-view-list/group-finder-map-view-list";
import GroupInfoWindowContent from "../group-finder-map-info-window/group-finder-map-info-window";
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { sortGroupsByLocationDistance, getMapLocationFromGoogleMap } from "utilities/geolocation";
import { FormattedMessage } from "react-intl";
import Loading from "components/loading/loading";
import useGroupFinderStore from "../group-finder-store";
import Collapse from "@mui/material/Collapse";
import ListRoundedIcon from "@mui/icons-material/ListRounded";
import useScreen, { ScreenSizes } from "utilities/hooks/useScreen";
import { MapLocation } from "lib/data/model";
import { GroupDetails } from "lib/models-v2";

const options: google.maps.MapOptions = {
  disableDefaultUI: true,
  clickableIcons: false,
  zoomControl: true
};

interface IProps {
  courseSignup: (group: GroupDetails) => void;
  searchArea: (area?: MapLocation) => void;
}

const GroupFinderMapView: React.VFC<IProps> = (props: IProps) => {

  const screen = useScreen();

  const joinNowStore = useGroupFinderStore();
  const { isLoading, filteredGroups, currentLocation, minMapZoom, maxMapZoom, setCurrentLocation, setMapInView } = joinNowStore;
  const [mapApi, setMapApi] = useState<google.maps.Map | null>(null);
  const [showSearchAreaButton, setShowSearchAreaButton] = useState<boolean>(false);
  const [isListOpen, setListOpen] = useState<boolean>(screen === ScreenSizes.Desktop && !!currentLocation); //Set true or false depending on tablet vs desktop
  const [activeLocationWindowId, setActiveLocationWindowId] = useState<number | null>(null);

  const sortedLocationGroups = useMemo<any[]>(() => {
    if (currentLocation && filteredGroups.length > 0) {

      const sortedLocationGroupsArray = sortGroupsByLocationDistance(
        filteredGroups,
        currentLocation.center
      );
      return sortedLocationGroupsArray;
    }

    return [];
  }, [filteredGroups, currentLocation]);

  useEffect(() => {
    setMapInView(mapApi);
  }, [mapApi]);

  useEffect(() => {
    setListOpen(screen === ScreenSizes.Desktop && !!currentLocation);
  }, [screen, currentLocation, isLoading]);

  const onMapClick = useCallback((e: google.maps.MapMouseEvent) => {
    if (e.latLng && mapApi) {
      mapApi.panTo(e.latLng);
    }
    setShowSearchAreaButton(true);
  }, []);

  const handleMapLoad = (map: google.maps.Map) => {
    map.setOptions({
      maxZoom: maxMapZoom,
      minZoom: minMapZoom
    });

    setMapApi(map);
  };

  const handleSearchAreaClick = () => {
    setActiveLocationWindowId(null);
    props.searchArea();
    setShowSearchAreaButton(false);
  };

  const handleAreaChange = () => {
    setShowSearchAreaButton(true);
    const location = getMapLocationFromGoogleMap(mapApi!);
    if (location) {
      setCurrentLocation(location);
    }
  };

  return (
    <div className="group-finder-small-container">
      <Collapse
        orientation="horizontal"
        in={isListOpen}
      >
        <div className="group-finder-side-container">
          <GroupFinderMapViewList
            groups={filteredGroups}
            isLoading={isLoading}
            haveLocation={!!currentLocation}
            onSelect={(idx: number) => props.courseSignup(filteredGroups[idx])}
          />
        </div>
      </Collapse>
      <div className={`group-finder-mapcontainer hasLoader ${isListOpen ? "" : "side-closed"} `}>
        <Loading loading={isLoading} blackout={true} />
        <div className="show-hide-area">
          <button
            className="show-hide-button"
            onClick={() => { setListOpen(!isListOpen); }}
          >
            <ListRoundedIcon fontSize="large" />
          </button>
        </div>
        <div className="search-this-area">
          <button
            className="search-area-button"
            onClick={handleSearchAreaClick}
            style={{ display: showSearchAreaButton ? "block" : "none" }}
          >
            <FormattedMessage
              id="search_area"
              defaultMessage="Search This Area"
            />
          </button>
        </div>
        {currentLocation &&
          <MapsLoader>
            <GoogleMap
              center={currentLocation?.center}
              mapContainerClassName={"group-finder-map"}
              zoom={currentLocation?.zoom}
              options={options}
              onLoad={handleMapLoad}
              onDragEnd={handleAreaChange}
              onZoomChanged={handleAreaChange}
              onClick={onMapClick}
            >
              {sortedLocationGroups.map((locGroup: any) => {
                const coords = {
                  lat: locGroup.latitude,
                  lng: locGroup.longitude,
                };

                const locationId = locGroup.locationIds[0];

                return (
                  <Marker
                    key={locationId}
                    position={coords}
                    onClick={() => {
                      setActiveLocationWindowId(locationId);
                    }}
                  >
                    {coords && activeLocationWindowId === locationId && (
                      <InfoWindow
                        position={coords}
                        onCloseClick={() => setActiveLocationWindowId(null)}
                      >
                        <GroupInfoWindowContent groups={locGroup.groups} />
                      </InfoWindow>
                    )}
                  </Marker>
                );
              })}
            </GoogleMap>
          </MapsLoader>
        }
        {!currentLocation && !isLoading &&
          <p className="no-location"><FormattedMessage id="enter_search_location" defaultMessage="Enter a search location to see results." /></p>
        }
      </div>
    </div>
  );
};

export default GroupFinderMapView;
