import "./manage-group-details.scss";

import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams as useRouteParams } from "react-router-dom";
import GroupManager from "components/group-manager";

type RouteParams = {
  locationId: string,
  programId: string,
  groupId: string
}

const ManageGroupDetails: React.VFC = () => {

  const { groupId, locationId, programId } = useRouteParams<RouteParams>();

  return (
    <div className="manageGroupPageContainer">
      <div className="pageHead">
        <h1><FormattedMessage id="edit_group" defaultMessage="Edit Group" /></h1>
      </div>

      <GroupManager
        ids={{
          groupId: Number.parseInt(groupId ?? ""),
          locationId: Number.parseInt(locationId ?? ""),
          programId: Number.parseInt(programId ?? "")
        }}
      />
    </div>
  );
};

export default ManageGroupDetails;
