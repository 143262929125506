export interface IProviderLanguageDto {
  id?: number;
  name: string;
  description: string;
  codes: string[];
  activeFlg?: boolean;
}

export default class ProviderLanguageDto implements ProviderLanguageDto {

  public id?: number;
  public name: string;
  public description: string;
  public codes: string[];
  public activeFlg?: boolean;

  constructor(creationValues: IProviderLanguageDto) {
    this.id = creationValues.id;
    this.name = creationValues.name;
    this.description = creationValues.description;
    this.codes = creationValues.codes;
    this.activeFlg = creationValues.activeFlg;
  }

  public export(): IProviderLanguageDto {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      codes: this.codes,
      activeFlg: this.activeFlg
    };
  }

  public clone(): ProviderLanguageDto {
    return new ProviderLanguageDto(this.export());
  }
}