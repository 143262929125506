import { GroupMeetingTypes, GroupStatuses } from "constants/group-constants";
import { GroupDetails } from "lib/models-v2";
import { getHHMM } from "utilities/time";

export function removeGroupByOutsideDateRange(data, startDate, endDate) {
  if (!data?.filter) {
    return data;
  }
  let filteredData;
  if (endDate) {
    filteredData = data.filter((row) => {
      const rowStartDate = new Date(row.startDate);
      return (rowStartDate >= startDate && rowStartDate <= endDate);
    });
  } else {
    filteredData = data.filter((row) => new Date(row.startDate) >= startDate);
  }
  return filteredData;
}

export function removeGroupByCourseLanguageEnglishName(groups, englishNames, courses) {
  if (!groups || !englishNames || !courses) {
    return [];
  } else {
    const filteredGroups = groups.filter(group => {
      const groupCourse = courses.find(course => course.id == group.programId);

      if (groupCourse.languageName == "Multilingual")
        return true;

      if (groupCourse && englishNames.includes(groupCourse.languageName))
        return true;

      return false;
    });
    return filteredGroups;
  }
}

export function removeGroupByProgramLanguageName(groups, englishNames) {
  if (!groups || !englishNames) {
    return [];
  } else {
    const filteredGroups = groups.filter(group => {
      if (group.programLanguages && group.programLanguages.some((programLanguage => englishNames.includes(programLanguage.lang.name)))) {
        return true;
      }
      return false;
    });
    return filteredGroups;
  }
}

export function removeGroupByGroupLanguageIdKeepMultilingualV2(groups, languageIds) {
  if (!groups) {
    return [];
  } else if (!languageIds) {
    return groups;
  } else {
    const filteredGroups = groups.filter(group => {
      const langId = group.language.id;
      //keep multlingual groups (groups with no language set)
      if (group.language === null) {
        return true;
      }

      if (languageIds.some(languageId => languageId == langId)) {
        return true;
      }

      return false;
    });
    return filteredGroups;
  }
}


export function removeGroupByGroupLanguageIdKeepMultilingual(groups, languageIds) {
  if (!groups) {
    return [];
  } else if (!languageIds) {
    return groups;
  } else {
    const filteredGroups = groups.filter(group => {
      //keep multlingual groups (groups with no language set)
      if (group.groupLanguages == null || group.groupLanguages.length < 1) {
        return true;
      }
      if (group.groupLanguages.some(groupLanguage => languageIds.some(languageId => languageId == groupLanguage.languageId))) {
        return true;
      }
      return false;
    });
    return filteredGroups;
  }
}

export function removeGroupByStartDate(groups, startDates) {
  if (!groups || !startDates) {
    return [];
  } else {
    const filteredGroups = groups.filter(group => startDates.includes(group.startDate));
    return filteredGroups;
  }
}

export function removeGroupByStartTime(groups, startTimes) {
  if (!groups || !startTimes) {
    return [];
  } else {
    const filteredGroups = groups.filter(group => {
      if (group.meetingTimes) {
        return group.meetingTimes.some(meetingTime => {
          return startTimes.includes(getHHMM(meetingTime.startTime));
        });
      }
      return false;
    });
    return filteredGroups;
  }
}

export function removeGroupByCourse(groups, courses) {
  if (!groups || !courses) {
    return [];
  } else {
    const filteredGroups = groups.filter(group => courses.some(course => course.id == group.programId));
    return filteredGroups;
  }
}

export function removeGroupDetailsByAgeRange(groups: GroupDetails[], ageRanges: string[]) {
  if (!groups || !ageRanges) {
    return [];
  }

  const filteredGroups = groups.filter(group => ageRanges.includes(group.groupAgeType));
  return filteredGroups;
}

export function removeGroupByAgeType(groups, ageTypes) {
  if (!groups || !ageTypes) {
    return [];
  } else {
    const filteredGroups = groups.filter(group => ageTypes.includes(group.groupAgeType));
    return filteredGroups;
  }
}

export function removeGroupByStatus(groups, statuses) {
  if (!groups || !statuses) {
    return [];
  } else {
    const filteredGroups = groups.filter(group => {
      if (group.isPending) {
        return statuses.includes(GroupStatuses.Pending);
      }

      return statuses.includes(group.status);
    });

    return filteredGroups;
  }
}

export function removeGroupByMeetingType(groups, meetingTypes) {
  if (!groups || !meetingTypes) {
    return [];
  } else {
    const filteredGroups = groups.filter(group => {
      if (group.isVirtual && meetingTypes.includes(GroupMeetingTypes.Virtual)) {
        return true;
      }
      if (!group.isVirtual && meetingTypes.includes(GroupMeetingTypes.InPerson)) {
        return true;
      }
      return false;
    });
    return filteredGroups;
  }
}

export function removeGroupByFieldValues(data, field, removalValues) {
  if (!data?.filter) {
    return data;
  }
  const filteredData = data.filter((row) => {
    return !removalValues.includes(row[field]);
  });
  return filteredData;
}