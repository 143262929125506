import axios from "axios";
import { validateResponse, ServerResult, ServerResponseType } from "../../request-utils";
import config from "../axios.config.json";
import { ApiUrl } from "constants/api-auth-constants";
import { CourseDto, CourseLanguageDto, CourseProviderGroupDto } from "lib/api/backend/model/course";

export const getLanguagesWithAvailableCourses = async () => {
  try {
    const response = await axios.get<CourseLanguageDto[]>(`${ApiUrl}courses/languages`, { ...config });

    const responseType = validateResponse(response.status);

    const result: ServerResult<CourseLanguageDto[]> = {
      responseType: responseType,
      data: response.data
    };

    return result;
  }
  catch (error) {
    //Not sure how we want to handle this.
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }
  }
};

export const GetCoursesByLanguage = async (language: string) => {
  try {
    const response = await axios.get<CourseProviderGroupDto[]>(`${ApiUrl}courses/${language}/courses`, { ...config });

    const responseType = validateResponse(response.status);

    const result: ServerResult<CourseProviderGroupDto[]> = {
      responseType: responseType,
      data: response.data
    };

    return result;
  }
  catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }

    const result: ServerResult<CourseProviderGroupDto[]> = {
      responseType: ServerResponseType.Error,
      data: []
    };

    return result;
  }
};

export const getProviderCourses = async (providerId: number): Promise<CourseDto[]> => {

  const response = await axios.get<CourseDto[]>(
    `${ApiUrl}courses/provider/${providerId}/courses`,
    { ...config }
  );

  if (response.status == 204) { //204 = no content
    return [];
  }

  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }

  throw new Error(`${response.status} - ${response.statusText}`);
};
