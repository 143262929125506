import { ReactElement } from "react";
import { FullGroup } from "lib/models-v2";
import { FormattedMessage } from "react-intl";
import { getLocaleDateString } from "utilities/time";
import GroupStatus from "components/group-status/group-status";
import { GroupAgeTypes } from "constants/group-constants";
import Badge from "components/badge/badge";
import "./move-participant-group-card.scss";

interface IMoveParticipantGroupCardProps {
  group: FullGroup
}

export const MoveParticipantGroupCard = (props: IMoveParticipantGroupCardProps): ReactElement => {

  const { group } = props;

  return (
    <div className="moveParticipantGroupCardContainer">
      <div className="groupHead">
        <span>{group.programName}</span>
      </div>
      <div className="groupSubhead">
        <GroupStatus status={group.status} />
        {(() => {
          switch (group.groupAgeType) {
            case GroupAgeTypes.Combined: {
              return (<Badge pill type="ageGroup"><FormattedMessage id="group_age_type_combined" defaultMessage="Combined" /></Badge>);
            }
            case GroupAgeTypes.Youth: {
              return (<Badge pill type="ageGroup"><FormattedMessage id="group_age_type_youth" defaultMessage="Youth only" /></Badge>);
            }
            case GroupAgeTypes.Adult: {
              return (<Badge pill type="ageGroup"><FormattedMessage id="group_age_type_adult" defaultMessage="Adult only" /></Badge>);
            }
          }
        })()}
      </div>
      {group.facilitators && group.facilitators.length &&
        <div className="groupInfo">
          <div className="infoContainer">
            {group.facilitators.map((facilitator) => <div key={`facilitator-${facilitator.id}`} className="info">{facilitator.firstName} {facilitator.lastName}</div>)}
          </div>
        </div>
      }
      <div className="groupInfo">
        <div className="infoContainer">
          <span className="infoHead">
            <FormattedMessage id="start_date" defaultMessage="Start Date" />
          </span>
          <span className="info">
            {getLocaleDateString(group.startDate!)}
          </span>
        </div>
        <div className="infoContainer">
          <span className="infoHead">
            <FormattedMessage id="enrolled" defaultMessage="Enrolled" />
          </span>
          <span className="info">
            {group.enrollmentCount ? group.enrollmentCount : 0}{group.maxEnrollment ? `/${group.maxEnrollment}` : ""}
          </span>
        </div>
        <div className="infoContainer">
          <span className="info">
            <address>
              {group?.address1}, {(group.address2) && `${group.address2}, `}{group.city} {group.state} {group.postalCode}
            </address>
          </span>
        </div>
      </div>
    </div>
  );
};

export default MoveParticipantGroupCard;