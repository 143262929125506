import "./email-phone.scss";
import React, { useState } from "react";
import { connect } from "react-redux";
import { mapStateToProps, mapDispatchToProps } from "datastore/store";
import { useParams, useNavigate } from "react-router-dom";
import { redirectToServerSignOut, getCourseSignupSession, navigateToReturnUrl } from "utilities/navUtils";
import Signup from "components/signup/signup";
import GroupHasRoomV2 from "components/group-has-room-v2/group-has-room-v2";
import { withRouter } from "utilities/withRouter";
import { JoinNowBaseUrl, CourseSignUpBaseUrl } from "routes";
import { GroupDetails } from "lib/models-v2";

type RouteParams = {
  providerId: string | undefined,
  locationId: string | undefined,
  programId: string | undefined,
  groupId: string | undefined,
  trackId: string | undefined,
  termId: string | undefined
}

interface EmailPhoneAccountSignupProps {
  store: any,
}

//TODO: Call group details if getting here directly from URL
const EmailPhoneAccountSignup = (props: EmailPhoneAccountSignupProps) => {

  const navigate = useNavigate();
  const courseSignupSession = getCourseSignupSession();
  const [group, setGroup] = useState<GroupDetails>();

  const { isAuthenticated } = props.store;
  const routeParams = useParams<RouteParams>();
  const groupId: number = routeParams.groupId ? parseInt(routeParams.groupId) : 0;

  const onExit = (goToGroupList?: boolean): void => {

    const enrollOrigin = courseSignupSession.enrollOriginUrl;

    if (goToGroupList) {
      const returnUrl = courseSignupSession.groupDetailsOriginUrl ? courseSignupSession.groupDetailsOriginUrl : enrollOrigin;
      if (returnUrl) {
        if (isAuthenticated) {
          redirectToServerSignOut({ returnUrl });
        } else {
          try {
            navigate(new URL(returnUrl).pathname);
          } catch {
            navigate(returnUrl);
          }
        }
      }
    } else {
      if (isAuthenticated && enrollOrigin) {
        redirectToServerSignOut({ returnUrl: enrollOrigin });
      } else if (!isAuthenticated && enrollOrigin) {
        if (enrollOrigin.includes(JoinNowBaseUrl)) {
          navigate(new URL(enrollOrigin).pathname);
        } else {
          navigateToReturnUrl(location.origin);
        }
      } else {
        redirectToServerSignOut({ returnUrl: location.origin });
      }
    }
  };

  const finished = () => {
    const redirectUrl = courseSignupSession.enrollOriginUrl || `${location.origin}${CourseSignUpBaseUrl}/${groupId}`;
    const enrollOriginUrl = new URL(redirectUrl);
    if (enrollOriginUrl.pathname.includes(JoinNowBaseUrl)) {
      navigate(enrollOriginUrl.pathname);
    } else {
      navigate(`${CourseSignUpBaseUrl}/${groupId}`);
    }
  };

  const cancelled = () => {
    navigate(`${CourseSignUpBaseUrl}/${groupId}`);
  };

  return (
    <div className="emailPhoneSignupPageContainer hasLoader">
      <GroupHasRoomV2 group={group} groupId={groupId} onGroupLoaded={(groupDetails) => { setGroup(groupDetails); }}>
        {group && 
          <Signup 
            group={group}  
            groupId={groupId} 
            onCancel={cancelled} 
            onFinish={finished} 
            onExit={onExit} 
          />
        }
      </GroupHasRoomV2>
    </div>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailPhoneAccountSignup));
