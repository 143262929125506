import { useEffect, useState } from "react";

type useClassNameReturnType = [ // Tuple
  string,
  (...propsClassNames: string[]) => void,
  (...addedClassNames: string[]) => void,
  (...removedClassNames: string[]) => void,
  (...resetClassNames: string[]) => void
];

export default function useClassName(...classNames: string[]): useClassNameReturnType {
  const [className, setClassName] = useState<string>("");
  const [externalClasses, setExternalClasses] = useState<string[]>([]);
  const [initialClasses, setInitialClasses] = useState<string[]>([]);
  const [addedClasses, setAddedClasses] = useState<string[]>([]);
  const [removedClasses, setRemovedClasses] = useState<string[]>([]);

  useEffect(
    () => {
      const classNameSet = new Set<string>();

      for (const initialClassName of classNames) {
        const splitClassNames = initialClassName.split(" ");

        for (const splitClassName of splitClassNames) {
          classNameSet.add(splitClassName);
        }
      }

      setInitialClasses(Array.from(classNameSet));
    },
    []
  );

  useEffect(
    () => {
      const classNameSet = new Set<string>();

      for (const initialClassName of initialClasses) {
        classNameSet.add(initialClassName);
      }

      for (const externalClassName of externalClasses) {
        classNameSet.add(externalClassName);
      }

      for (const addedClassName of addedClasses) {
        classNameSet.add(addedClassName);
      }

      for (const removedClassName of removedClasses) {
        classNameSet.delete(removedClassName);
      }

      setClassName(Array.from(classNameSet).join(" ").trim());
    },
    [initialClasses, externalClasses, addedClasses, removedClasses]
  );

  const addClasses = (...classNames: string[]) => {
    const addedClassNameSet = new Set<string>(addedClasses);
    const removedClassNameSet = new Set<string>(removedClasses);

    for (const addedClassName of classNames) {
      const splitClassNames = addedClassName.split(" ");

      for (const splitClassName of splitClassNames) {
        const trimmedClassName = splitClassName.trim();

        addedClassNameSet.add(trimmedClassName);
        removedClassNameSet.delete(trimmedClassName);
      }
    }

    setAddedClasses(Array.from(addedClassNameSet));
    setRemovedClasses(Array.from(removedClassNameSet));
  };

  const removeClasses = (...classNames: string[]) => {
    const removedClassNameSet = new Set<string>(removedClasses);

    for (const removedClassName of classNames) {
      const splitClassNames = removedClassName.split(" ");

      for (const splitClassName of splitClassNames) {
        removedClassNameSet.add(splitClassName.trim());
      }
    }

    setRemovedClasses(Array.from(removedClassNameSet));
  };

  const resetClasses = (...classNames: string[]) => {
    const resetClassNameSet = new Set<string>();

    for (const resetClassName of classNames) {
      const splitClassNames = resetClassName.split(" ");

      for (const splitClassName of splitClassNames) {
        resetClassNameSet.add(splitClassName.trim());
      }
    }

    setInitialClasses(Array.from(resetClassNameSet));
    setAddedClasses([]);
    setRemovedClasses([]);
  };

  const setPropsClasses = (...classNames: string[]) => {
    const propsClassNameSet = new Set<string>();

    for (const externalClassName of classNames) {
      const splitClassNames = externalClassName.split(" ");

      for (const splitClassName of splitClassNames) {
        propsClassNameSet.add(splitClassName.trim());
      }
    }

    setExternalClasses(Array.from(propsClassNameSet));
  };

  return [className, setPropsClasses, addClasses, removeClasses, resetClasses];
}
