
import create from "zustand";
import { getTranslation } from "utilities/language";
import { IQuickRegPreloadDto } from "lib/api/backend/model";
import { CourseProviderGroupDto, CourseLanguageDto } from "lib/api/backend/model/course";

export type PreloadStore = {
  languages: CourseLanguageDto[]
  groupTypes: CourseProviderGroupDto[],
  setPreload: (preloadDto: IQuickRegPreloadDto) => void;
}

export const getLanguages = (state: PreloadStore) => {
  return state.languages;
};

export const getLanguagesWithMultilingual = (state: PreloadStore) => {

  const anyLanguageText = getTranslation("multilingual", "Multilingual");

  const anyLanguageOption = new CourseLanguageDto(
    {
      id: 0,
      name: anyLanguageText,
      description: anyLanguageText
    }
  );
  const languages = [...state.languages];

  const multiLingualIndex = languages.findIndex(language => language.name == "Multilingual");
  if (multiLingualIndex != -1) {
    languages[multiLingualIndex] = anyLanguageOption;
  } else {
    languages.push(anyLanguageOption);
  }

  return languages;
};

export const getGroupTypesAsList = (state: PreloadStore) => {
  return state.groupTypes.map(x => x.courses).flat();
};

export const getGroupTypesByProviders = (state: PreloadStore, providerIds: number[]) => {
  return state.groupTypes.map(x => x.courses).flat().filter(x => providerIds.includes(x.providerId!));
};

export const getProviders = (state: PreloadStore) => {
  return state.groupTypes.map(x => x.provider);
};

const usePreloadStore = create<PreloadStore>(
  (set): PreloadStore => ({
    languages: [],
    groupTypes: [],
    setPreload: (preload: IQuickRegPreloadDto) =>
      set(() => ({
        languages: preload.languages,
        groupTypes: preload.groupTypes
      }))

  })
);

export default usePreloadStore;