import React, { useState, useEffect, useRef } from "react";
import "./alert.scss";
import { getFadeAnimationDuration } from "utilities/styles";
import StatusBox from "components/status-box/status-box";
import { FormattedMessage } from "react-intl";

const Alert = (props) => {

  const durationInSeconds = props.alert.timeout;
  const isAside = props.alert.aside;
  const [removing, setRemoving] = useState(false);
  const alertId = props.alert.id;
  const lastFocusedElement = useRef(document.activeElement);

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);
    document.getElementById(`${alertId}-cancel`).focus();
    if (durationInSeconds) {
      setTimeout(() => { close(); }, durationInSeconds * 1000);
    }
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  });

  const handleOutsideClick = (event) => {
    if (event?.target?.id == alertId) {
      close();
    }
  };

  const handleInsideClick = (event) => {
    //stopping propogation of click events to the outside, prevents the alert from closing when the click inside it
    event.stopPropagation();
  };

  const close = () => {
    if (props.onRemove) {
      setRemoving(true);
      setTimeout(() => {
        props.onRemove(alertId);
        lastFocusedElement.current.focus();
      }, getFadeAnimationDuration() - 100); // subtracting 100ms to account for the possible margin of error that occurs with setTimeout
      //                                      causing time between the animation finishing and the element being removed
      //                                      without that the element may flash on the screen again after fading away.
    }
  };

  return (
    <div id={alertId} className={`alertContainer${isAside ? "Aside" : ""} ${props.alert.status} ${(removing) ? "removing" : ""}`} onClick={handleOutsideClick}>
      <StatusBox status={props.alert.status} head={props.alert.head} onClick={handleInsideClick}>
        {props.alert.message}
        <button id={`${alertId}-cancel`} className="cancel" onClick={close}><FormattedMessage id="close" defaultMessage="Close" /></button>
      </StatusBox>
    </div>
  );
};

export default Alert;