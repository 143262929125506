import { GuestFacilitator } from "lib/data/model";
import FacilitatorDto from "../model/facilitator/FacilitatorDto";
import { IDtoToModelConverter } from "./IDtoConverter";

export class FacilitatorDtoToGuestFacilitatorConverter implements IDtoToModelConverter<FacilitatorDto, GuestFacilitator> {
  public toModel = (dto: FacilitatorDto): GuestFacilitator => {
    const facilitator = new GuestFacilitator({
      id: dto.id,
      name: dto.name
    });

    return facilitator;
  };
}