import "./checkbox-column.scss";

import { ReactNode, useEffect, useState } from "react";
import Header from "./header/header";
import Row from "./row/row";

const allowedHeaderChildren = [Header];
const allowedRowChildren = [Row];

interface ICheckboxColumnProps {
  children?: ReactNode | ReactNode[] | null
}

const CheckboxColumn = (props: ICheckboxColumnProps): JSX.Element => {

  const [headerCell, setHeaderCell] = useState<JSX.Element | null>(null);
  const [bodyCell, setBodyCell] = useState<JSX.Element | null>(null);

  useEffect(
    () => {
      const processChildren = (children) => {
        if (!children) {
          setHeaderCell(null);
          setBodyCell(null);
        }
        else if (Array.isArray(children)) {
          setHeaderCell(children.filter(i => allowedHeaderChildren.includes(i.type))?.shift() ?? null);
          setBodyCell(children.filter(i => allowedRowChildren.includes(i.type))?.shift() ?? null);
        }
        else if (allowedHeaderChildren.includes(children.type)) {
          setHeaderCell(children);
          setBodyCell(null);
        }
        else if (allowedRowChildren.includes(children.type)) {
          setHeaderCell(null);
          setBodyCell(children);
        }
        else {
          setHeaderCell(null);
          setBodyCell(null);
        }
      };

      processChildren(props.children);
    },
    [props.children]
  );

  const elements: JSX.Element[] = [];

  if (headerCell != null) {
    elements.push(headerCell);
  }

  if (bodyCell != null) {
    elements.push(bodyCell);
  }

  return (<>{elements}</>);

};

CheckboxColumn.Header = Header;
CheckboxColumn.Row = Row;

export default CheckboxColumn;