import "./add-group.scss";

import React from "react";
import GroupManager from "components/group-manager";
import { FormattedMessage } from "react-intl";

const AddGroup: React.VFC = () => {
  return (
    <div className="addGroupPageContainer">
      <div className="pageHead">
        <h1><FormattedMessage id="new_group" defaultMessage="New Group" /></h1>
      </div>
      <section>
        <GroupManager />
      </section>
    </div>
  );
};

export default AddGroup;
