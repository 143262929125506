import React, { useEffect, useState, useRef, useMemo } from "react";
import nextId from "react-id-generator";
import { LocationTypes } from "constants/location-constants";
import { FormattedMessage, useIntl } from "react-intl";
import { Autocomplete, TextField, MenuItem, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import useClassName from "utilities/useClassName";
import { LocationType } from "lib/data/model/enum";
import { Unit } from "../../model";
import * as GeoCoder from "utilities/geocoder";
import { useFormStatus } from "lib/form/contexts";
import { SaveState } from "lib/form/model";
import { IAddressDto, SiteDto } from "lib/api/backend/model";
import { Autocomplete as PlacesAutocomplete } from "@react-google-maps/api";
import MapsLoader from "components/maps-loader";
import "./location-input-panel.scss";
import { LocationCreationDto } from "lib/api/backend/model/group/GroupCreationDto";
import useGroupCreationFormStore from "datastore/groupCreationForm";

interface ILocationPanelInputProps {
  className?: string;
  sites: SiteDto[];
  units: Unit[];
  onLocationChange: (location: LocationCreationDto) => void;
  onLocationTypeChange: (locationType: LocationCreationDto) => void;
}

const LocationInputPanel: React.VFC<ILocationPanelInputProps> = (props) => {
  const [className, setPropsClassName] = useClassName("locationInputPanelContainer");
  const [formStatus] = useFormStatus();

  const [componentId] = useState(nextId());
  const [customGmapACInputId] = useState(`${componentId}-${LocationTypes.custom}-gmap-ac`);
  const [unitSelectInputId] = useState(`${componentId}-${LocationTypes.custom}-unit-select`);
  const placesApi = useRef<google.maps.places.Autocomplete>();

  const formStore = useGroupCreationFormStore();
  const group = formStore.group;
  const location = group?.location;

  const fieldOptions = useMemo<string[]>(() => {
    return [
      "formatted_address",
      "address_component",
      "geometry",
      "name"
    ];
  }, []);

  const [customAddress, setCustomAddress] = useState<string>();
  const [sites, setSites] = useState<SiteDto[]>([]);

  const intl = useIntl();

  useEffect(
    () => {
      if (props.className) {
        setPropsClassName(props.className);
      }
    },
    [props.className]
  );

  useEffect(
    () => {
      setSites(props.sites);
    },
    [props.sites]
  );

  useEffect(
    () => { 
      if (location && location.locationType === LocationType.Other) {
        const address = [
          location.address1 ?? "",
          location.address2 ? ` ${location.address2}` : "",
          location.city ? `, ${location.city}` : "",
          location.state ? `, ${location.state}` : "",
          location.country ? `, ${location.country}` : ""
        ].join("");

        setCustomAddress(address ?? customAddress);
      }
      else {
        setCustomAddress(undefined);
      }
    },
    [location]
  );

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>, type: LocationType) => {
    if (event.target.checked) {
      const newLocation = {
        unitNumber: location?.unitNumber,
        locationType: type
      } as LocationCreationDto;

      formStore.setLocation(newLocation);

      props.onLocationTypeChange(newLocation);
    }
  };

  const handleSiteChange = (site: SiteDto | null) => {
    if (site) {
      const newLocation = {
        ...location,
        address1: site.address.line1,
        address2: site.address.line2,
        city: site.address.city,
        state: site.address.state,
        postalCode: site.address.postalCode,
        country: site.address.country,
        propId: site.id,
        unitNumber: location?.unitNumber,
        latitude: site.coordinates.latitude,
        longitude: site.coordinates.longitude,
      } as LocationCreationDto;

      formStore.setLocation(newLocation);

      props.onLocationChange(newLocation);
    }
  };

  const handleLocationChange = (changedLocation: LocationCreationDto | null) => {
    if (changedLocation) {

      const stakeUnitNumber = (() => {

        if (changedLocation.unitNumber) {
          return changedLocation.unitNumber;
        }

        if (!changedLocation.unitNumber && location?.locationType === LocationType.Other && props.units.length > 1) {
          return undefined;
        }

        return props.units.length > 0 ? props.units[0].areaUnitNumber : undefined;

      })();

      const newLocation = {
        ...location,
        address1: changedLocation.address1,
        address2: changedLocation.address2,
        city: changedLocation.city,
        state: changedLocation.state,
        postalCode: changedLocation.postalCode,
        country: changedLocation.country,
        propId: changedLocation.propId,
        unitNumber: stakeUnitNumber,
        latitude: changedLocation.latitude,
        longitude: changedLocation.longitude,
      } as LocationCreationDto;

      formStore.setLocation(newLocation);
    }
  };

  const handleUnitChange = (newUnitNumber: number) => {
    const newSelectedUnit = props.units.find(unit => unit.areaUnitNumber === newUnitNumber);

    if (newSelectedUnit) {
      const newLocation = {
        ...location,
        propId: null,
        unitNumber: newSelectedUnit.areaUnitNumber
      } as LocationCreationDto;
      formStatus.dirtiness.setDirty(true, "unitNumber");

      formStore.setLocation(newLocation);
    }
  };

  const onPlaceChanged = () => {
    const place = placesApi!.current?.getPlace();

    if (place?.geometry) {

      const address = GeoCoder.getAddressCreationModel(place.address_components) as IAddressDto;

      const chosenLocation = {
        ...location,
        propId: null,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        country: address.country,
        latitude: place.geometry.location!.lat(),
        longitude: place.geometry.location!.lng(),
      } as LocationCreationDto;

      handleLocationChange(chosenLocation);

      setCustomAddress(place.formatted_address ?? "");

      props.onLocationChange(chosenLocation);
    }
  };

  ////////////////////
  // Render Helpers //
  ////////////////////

  const locationHasErrors = (
    !formStatus.validity.isValid("location")
    && (
      formStatus.saveState === SaveState.ValidationFailed
      || formStatus.dirtiness.isDirty("location")
    )
  );

  ////////////
  // Render //
  ////////////
  return (
    <div className={className}>

      {
        props.units.length > 1
        && (
          <>
            <p><FormattedMessage id="select_unit_instruction" defaultMessage="Please select the church unit for this group." /></p>
            <TextField
              select={true}
              id={unitSelectInputId}
              value={location?.unitNumber || ""}
              label={intl.formatMessage({ id: "select_unit_placeholder", defaultMessage: "Select a Unit" })}
              onChange={(event) => handleUnitChange(Number(event.target.value))}
            >
              {
                props.units.map((unit, index) => (
                  <MenuItem key={`custom-unit-${index}`} value={unit.areaUnitNumber}>{unit.areaUnitName}</MenuItem>
                ))
              }
            </TextField>
          </>
        )
      }

      {
        props.units.length == 1
        && (
          <span>{props.units[0].areaUnitName}</span>
        )
      }

      <RadioGroup>
        <FormControlLabel
          control={
            <Radio
              checked={location?.locationType === LocationType.ChurchOwned}
              onChange={(event) => handleTypeChange(event, LocationType.ChurchOwned)}
            />
          }
          label={<FormattedMessage id="church_owned" defaultMessage="Church-owned property" />}
        />

        <FormControlLabel
          control={
            <Radio
              checked={location?.locationType === LocationType.Virtual}
              onChange={(event) => handleTypeChange(event, LocationType.Virtual)}
            />
          }
          label={<FormattedMessage id="virtual" defaultMessage="Virtual" />}
        />

        <FormControlLabel
          control={
            <Radio
              checked={location?.locationType === LocationType.Other}
              onChange={(event) => handleTypeChange(event, LocationType.Other)}
            />
          }
          label={<FormattedMessage id="other" defaultMessage="Other" />}
        />
      </RadioGroup>

      <div className="inputErrorGroup">
        {
          location?.locationType === LocationType.ChurchOwned
          && (
            <div className="locationPicker">
              {
                sites?.length > 0
                && (
                  <>
                    <Autocomplete
                      options={sites}
                      renderOption={(props, option) =>
                        <li {...props} key={option.id}>
                          {option.name ?? intl.formatMessage({ id: "unknown", defaultMessage: "Unknown" })}
                        </li>
                      }
                      getOptionLabel={(option) => option.name ?? intl.formatMessage({ id: "unknown", defaultMessage: "Unknown" })}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      value={sites.find(s => s.id === location.propId) ?? null}
                      onChange={(event, value) => handleSiteChange(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={<FormattedMessage id="address" defaultMessage="Address" />}
                          error={locationHasErrors}
                          helperText={
                            locationHasErrors
                              ? formStatus.errors.getMessages("location").map(
                                (message, index) => (
                                  <span key={`location-picker-autocomplete-message-${index}`}>
                                    {message}
                                  </span>
                                ))
                              : formStatus.messages.getMessages("location").map(
                                (message, index) => (
                                  <span key={`location-picker-autocomplete-message-${index}`}>
                                    {message}
                                  </span>
                                ))
                          }
                        />
                      )}
                    />
                  </>
                )
              }

              {
                sites.length <= 0
                && (
                  <p><FormattedMessage id="no_authorized_locations" defaultMessage="Could not find any authorized locations." /></p>
                )
              }
            </div>
          )
        }

        {
          location?.locationType === LocationType.Virtual
          && (
            <div className="locationPicker">
              <Autocomplete
                options={sites}
                renderOption={(props, option) =>
                  <li {...props} key={option.id}>
                    {option.name ?? intl.formatMessage({ id: "unknown", defaultMessage: "Unknown" })}
                  </li>
                }
                getOptionLabel={(option) => option.name ?? intl.formatMessage({ id: "unknown", defaultMessage: "Unknown" })}
                value={sites.find(s => s.id === location.propId) ?? null}
                onChange={(event, value) => handleSiteChange(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<FormattedMessage id="address" defaultMessage="Address" />}
                    error={locationHasErrors}
                    helperText={
                      locationHasErrors
                        ? formStatus.errors.getMessages("location").map(
                          (message, index) => (
                            <span key={`location-picker-autocomplete-message-${index}`}>
                              {message}
                            </span>
                          ))
                        : formStatus.messages.getMessages("location").map(
                          (message, index) => (
                            <span key={`location-picker-autocomplete-message-${index}`}>
                              {message}
                            </span>
                          ))
                    }
                  />
                )}
              />

              <p><FormattedMessage id="group_is_virtual" defaultMessage="Choose an address associated with your church unit. A leader or facilitator will need to provide the video conference information to the participants." /></p>
            </div>
          )
        }

        {
          location?.locationType === LocationType.Other
          && (
            <div className="locationPicker">
              <MapsLoader>
                <PlacesAutocomplete
                  onLoad={(autocomplete) => { placesApi.current = autocomplete; }}
                  onPlaceChanged={onPlaceChanged}
                  fields={fieldOptions}
                >
                  <>
                    <TextField
                      id={customGmapACInputId}
                      label={<FormattedMessage id="address" defaultMessage="Address" />}
                      value={customAddress ?? ""}
                      onChange={(event) => setCustomAddress(event.target.value)}
                      error={locationHasErrors && formStatus.dirtiness.isDirty("unitNumber")}
                      helperText={
                        locationHasErrors && formStatus.dirtiness.isDirty("unitNumber")
                          ? formStatus.errors.getMessages("location").map(
                            (message, index) => (
                              <span key={`location-picker-autocomplete-message-${index}`}>
                                {message}
                              </span>
                            ))
                          : formStatus.messages.getMessages("location").map(
                            (message, index) => (
                              <span key={`location-picker-autocomplete-message-${index}`}>
                                {message}
                              </span>
                            ))
                      }
                    />
                    <p><FormattedMessage id="public_address_warning" defaultMessage="This address will display publicly. For safety, only choose public locations, not private households." /></p>
                  </>
                </PlacesAutocomplete>
              </MapsLoader>

              {
                props.units.length > 1
                && (
                  <>
                    <p><FormattedMessage id="select_unit_instruction" defaultMessage="Please select the church unit for this group." /></p>

                    <TextField
                      select={true}
                      id={unitSelectInputId}
                      value={location?.unitNumber || ""}
                      label={intl.formatMessage({ id: "select_unit_placeholder", defaultMessage: "Select a Unit" })}
                      onChange={(event) => handleUnitChange(Number(event.target.value))}
                    >
                      {
                        props.units.map((unit, index) => (
                          <MenuItem key={`custom-unit-${index}`} value={unit.areaUnitNumber}>{unit.areaUnitName}</MenuItem>
                        ))
                      }
                    </TextField>
                  </>
                )
              }
            </div>
          )
        }

      </div>

    </div>
  );
};

export default LocationInputPanel;
