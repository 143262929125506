import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const location = useLocation();
    const match = { params: useParams() };
    const navigate = useNavigate();
    const history = {
      back: () => navigate(-1),
      goBack: () => navigate(-1),
      location,
      push: (url, state) => navigate(url, { state }),
      replace: (url, state) => navigate(url, { replace: true, state }),
    };
    return (
      <Component
        location={location}
        match={match}
        navigate={navigate}
        history={history}
        {...props}
      />
    );
  };

  return Wrapper;
};