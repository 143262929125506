import "./participant-list.scss";
import { useState, useEffect, useRef } from "react";
import { ParticipantListCardList, ParticipantListTable, ParticipantListControls } from "./components";
import Loading from "components/loading/loading";
import { FormattedMessage } from "react-intl";
import Paginator from "components/paginator/paginator";
import AuthService from "auth/authorize-service";
import { Permission, Permissions } from "constants/api-auth-constants";
import UserCan from "components/user-can/user-can";
import { StudentStatusesV2 } from "constants/student-constants";
import Layouts from "constants/layout-constants";
import { useSelector, useDispatch } from "react-redux";
import { LoadingStatus } from "lib/data/model";
import { getAndDownloadBulkParticipantCertificates } from "lib/api/backend/requests/certificates";
import dispatchers from "datastore/dispatcher";
import { CertificateFailureType, CertificateFailureTypeTransLationsKeys } from "constants/certificate-constants";
import { ViewNames } from "./models";
import useGroupStore from "datastore/groups";
import { getUserGroupsV2 } from "lib/api/backend/requests/groups";
import { ServerResponseType } from "lib/api/backend/request-utils";
import { FullGroup } from "lib/models-v2";
import { ParticipantGroupDto } from "lib/api/backend/model/participant/ParticipantGroupDto";
import { BulkParticipantCertificatePrintModel } from "lib/api/backend/model/certificate/requests/BulkParticipantCertificatePrintModel";
import ParticipantCertificateFailureDto from "lib/api/backend/model/participant/ParticipantCertificateFailureDto";
import { dropParticipants, recieveConsents } from "lib/api/backend/requests/participant";
import AuthorizeService from "auth/authorize-service";
import { MaxUnitNumbersPerFetch } from "constants/group-constants";

export const ParticipantList = () => {

  const store = useGroupStore();
  const renderedActiveUnit = useRef<number | null>(store.activeUnitNumber);
  const layout = useSelector((state: any) => state.appReducer.layout);
  const [participants, setParticipants] = useState<ParticipantGroupDto[]>([]);
  const [filteredParticipants, setFilteredParticipants] = useState<ParticipantGroupDto[]>([]);
  const [paginatedParticipants, setPaginatedParticipants] = useState<ParticipantGroupDto[]>([]);
  const [selectedParticipants, setSelectedParticipants] = useState<ParticipantGroupDto[]>([]);
  const [loading, setLoading] = useState<LoadingStatus>({ status: false, message: "" });
  const [showView, setShowView] = useState<ViewNames>(layout == Layouts.Desktop ? ViewNames.Table : ViewNames.Card);
  const canManageParticipants = AuthService.userCan([Permissions.ManageGroupsWrite]);
  const canPrintCompletionCerts = AuthService.userCan([Permissions.PrintStudentCompletionCertificatesWrite]);
  const reduxDispatch = useDispatch();
  const prompt = dispatchers(reduxDispatch).prompt;
  const notify = dispatchers(reduxDispatch).notify;
  const alert = dispatchers(reduxDispatch).alert;

  useEffect(() => {
    if (!store.userGroupsFetchCompleted || renderedActiveUnit.current != store.activeUnitNumber) {
      renderedActiveUnit.current = store.activeUnitNumber;
      getGroups();
    }
  }, [store.activeUnitNumber]);

  useEffect(() => {
    const newParticipants = buildEnrollments(store.groups); //sortStudentsByLastName //TODO: Regain sort
    setParticipants(newParticipants);
  }, [store.groups]);

  const getGroups = async () => {
    try {
      setLoading({ status: true, message: <FormattedMessage id="getting_participants" defaultMessage="Getting Participants" /> });

      const activeUnit = (() => {
        if (AuthorizeService?.authorizedUnits && AuthorizeService.authorizedUnits.length) {
          if (store.activeUnitNumber) {
            return AuthorizeService.authorizedUnits.find(unit => unit.unitNumber == store.activeUnitNumber);
          } else if (AuthorizeService.authorizedUnits.length > MaxUnitNumbersPerFetch) {
            store.setActiveUnitNumber(AuthorizeService.authorizedUnits[0].unitNumber);
            return AuthorizeService.authorizedUnits[0];
          }
        }
        return null;
      })();

      if (activeUnit && store.activeUnitNumber != activeUnit.unitNumber) {
        store.setActiveUnitNumber(activeUnit.unitNumber);
      }

      const response = await getUserGroupsV2(activeUnit ? [activeUnit.unitNumber] : undefined);

      if (response!.responseType == ServerResponseType.Success) {
        store.setGroups(response!.data.groupsList, true);
        setLoading({ status: false, message: "" });
      }
      else {
        store.setGroups([]);
        setLoading({ status: false, message: "" });
        notify({ status: "error", message: <FormattedMessage id="get_participants_error" defaultMessage="Could not get participants." /> });
      }
      setLoading({ status: false, message: "" });
    } catch (e) {
      notify({ status: "error", message: <FormattedMessage id="get_participants_error" defaultMessage="Could not get participants." /> });
      setParticipants([]);
      setLoading({ status: false, message: "" });
    }

  };

  const buildEnrollments = (groups: FullGroup[]): ParticipantGroupDto[] => {
    const enrollments: ParticipantGroupDto[] = [];
    groups.forEach(group => {
      group.quickRegParticipants.forEach(participant => {
        enrollments.push({
          id: participant.id,
          groupId: group.id,
          cmisId: participant.cmisId,
          churchAccountId: null, //Need to implement it into QuickRegParticipant type.
          status: participant.status,
          pathEnrollStatusId: participant.pathEnrollStatusId,
          preferredName: participant.preferredName,
          givenName: participant.givenName,
          surname: participant.surname,
          email: participant.email,
          dialCode: participant.dialCode,
          phoneNumber: participant.phoneNumber,
          allowSMS: participant.allowSMS,
          birthDate: participant.birthDate,
          termsAcceptedDate: participant.termsAcceptedDate,
          consentFormSubmitted: participant.consentFormSubmitted,
          removedDate: participant.removedDate,
          completedDate: participant.completedDate,
          isYouth: participant.isYouth,
          isSelected: participant.isSelected,
          programId: group.programId,
          programName: group.programName,
          programCompletionCertificateId: group.completionCertificateId,
          programParticipationCertificateId: group.participationCertificateId,
          groupStakeUnitNumber: group.stakeUnitNumber,
          groupStatus: group.status,
          createdDate: participant.createdDate,
          isMember: participant.isMember
        } as ParticipantGroupDto);
      });
    });

    return enrollments;
  };

  const handleFilteredStudents = (filteredParticipants: ParticipantGroupDto[]) => {
    resetSelected();
    setFilteredParticipants(filteredParticipants);
  };

  const onSelectAllChange = (checked: boolean) => {
    if (checked) {
      selectAll();
    } else {
      resetSelected();
    }
  };

  const onSelectChange = (checked: boolean, participant: ParticipantGroupDto) => {
    if (checked) {
      selectParticipant(participant);
    } else {
      unselectParticipant(participant);
      const checkboxes = document.querySelector(".selectAll") as HTMLInputElement;
      if (checkboxes) {
        checkboxes.checked = false;
      }
    }
  };

  const selectParticipant = (participant: ParticipantGroupDto) => {
    if (participant) {
      const newSelectedStudents = [...selectedParticipants];
      newSelectedStudents.push(participant);
      setSelectedParticipants(newSelectedStudents);
    }
  };

  const unselectParticipant = (participant: ParticipantGroupDto) => {
    const newStudents = [...selectedParticipants];
    const index = newStudents.findIndex(entry => entry.id == participant.id && entry.programId == participant.programId);
    if (index > -1) {
      newStudents.splice(index, 1);
    }
    setSelectedParticipants(newStudents);
  };

  const resetSelected = () => {
    if (selectedParticipants.length > 0) {
      setSelectedParticipants([]);
    }
  };

  const selectAll = () => {
    setSelectedParticipants([...paginatedParticipants]);
  };

  const enrollmentIsSelected = (participant: ParticipantGroupDto) => {
    return selectedParticipants.some(entry => entry.id == participant.id && entry.programId == participant.programId);
  };

  const nothingSelectedAlert = () => {
    alert(
      {
        status: "error",
        head: <FormattedMessage id="nothing_selected" defaultMessage="Nothing Selected" />,
        message: <FormattedMessage id="nothing_selected_explanation" defaultMessage="You have not selected anything to perform this action on." />
      }
    );
  };

  const removeParticipants = async () => {
    const shouldContinue = await confirmDeletePrompt();
    if (!shouldContinue) {
      return;
    }
    if (canManageParticipants) {
      if (selectedParticipants?.length < 1) {
        nothingSelectedAlert();
        return;
      }
      const authorizedParticipants = await authorizeStudents([Permissions.ManageGroupsWrite]);
      if (authorizedParticipants?.length > 0) {
        setLoading({ status: true, message: <FormattedMessage id="removing_participants" defaultMessage="Removing Participants" /> });
        const participantIds = authorizedParticipants.map((ap) => ap.id);
        try {
          const result = await dropParticipants(participantIds);
          if (!result) {
            throw result;
          }
          const newGroups = store.groups;
          authorizedParticipants.forEach((authorizedParticipant) => {
            const groupToUpdate = newGroups.find(group => group.id == authorizedParticipant.groupId);
            if (groupToUpdate) {
              const participantToUpdate = groupToUpdate.quickRegParticipants.find(groupParticipant => groupParticipant.id == authorizedParticipant.id);
              if (participantToUpdate) {
                participantToUpdate.status = StudentStatusesV2.NotEnrolled;
              }
              store.editGroup(groupToUpdate);
            }
          });
        } catch (e) {
          notify({ status: "error", message: <FormattedMessage id="enrollment_update_error" defaultMessage="An error occurred while attempting to update the enrollment." /> });
        } finally {
          setLoading({ status: false, message: "" });
        }
      }
    }
  };

  const confirmDeletePrompt = () => {
    return new Promise((resolve) => {
      prompt({
        status: "warning",
        head: (
          <FormattedMessage
            id="please_confirm"
            defaultMessage="Please Confirm"
          />
        ),
        message: (
          <FormattedMessage
            id="confirm_remove_participants"
            defaultMessage="Are you sure you want to remove participants? This cannot be undone"
          />
        ),
        buttons: (
          <>
            <button
              onClick={() => {
                resolve(true);
              }}
            >
              <FormattedMessage id="continue" defaultMessage="Continue" />
            </button>
            <button
              onClick={() => {
                resolve(false);
              }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </button>
          </>
        ),
      });
    });
  };

  const updateConsents = async () => {
    if (canManageParticipants) {
      if (selectedParticipants?.length < 1) {
        nothingSelectedAlert();
        return;
      }
      const authorizedEnrollments = await authorizeStudents([Permissions.ManageGroupsWrite]);
      const participantsNeedingConsent = await verifyNeedConsent(authorizedEnrollments);
      if (participantsNeedingConsent?.length > 0) {
        setLoading({ status: true, message: <FormattedMessage id="receiving_consents" defaultMessage="Recording Consent Forms as received" /> });
        const participantIds = participantsNeedingConsent.map(pnc => pnc.id);
        try {
          const result = recieveConsents(participantIds);
          if (!result) {
            throw result;
          }
          const newGroups = store.groups;
          participantsNeedingConsent.forEach(participant => {
            const groupToUpdate = newGroups.find(group => group.id == participant.groupId);
            if (groupToUpdate) {
              const participantToUpdate = groupToUpdate!.quickRegParticipants.find(groupParticipant => groupParticipant.id == participant.id);
              if (participantToUpdate) {
                participantToUpdate.status = StudentStatusesV2.Enrolled;
              }
              store.editGroup(groupToUpdate);
            }
          });
        } catch (e) {
          notify({ status: "error", message: <FormattedMessage id="enrollment_update_error" defaultMessage="An error occurred while attempting to update the enrollment." /> });
        } finally {
          setLoading({ status: false, message: "" });
        }
      }
    }
  };


  const printCertificates = async (isParticipation: boolean) => {
    if (canManageParticipants) {
      if (selectedParticipants.length > 0) {
        setLoading({ status: true, message: <FormattedMessage id="getting_cert" defaultMessage="Getting certificates..." /> });

        const bulkCertificates = selectedParticipants.map(participant => {
          return {
            participantId: participant.id!,
            groupId: participant.groupId,
            isParticipation
          } as BulkParticipantCertificatePrintModel;
        });

        const failures = await getAndDownloadBulkParticipantCertificates(bulkCertificates);

        if (failures.length > 0) {
          showPrintFailures(failures, selectedParticipants);
        }

        setLoading({ status: false, message: "" });
        resetSelected();
      }
    }
  };


  const onParticipantLoadingChange = (loading: LoadingStatus) => {
    setLoading(loading);
  };

  const handlePageChange = (page) => {
    setPaginatedParticipants(page.records);
  };

  const authorizeStudents = async (permissions: Permission[]): Promise<ParticipantGroupDto[]> => {

    if (selectedParticipants.length < 1) {
      nothingSelectedAlert();
      return [];
    }

    const unauthorizedStudents = selectedParticipants.filter(participant => {
      return !AuthService.userCan(permissions, participant.groupStakeUnitNumber);
    });

    if (unauthorizedStudents.length == selectedParticipants.length) {
      unauthorizedPrompt();
      return [];
    }

    if (unauthorizedStudents.length > 0) {
      const shouldContinue = await unauthorizedPrompt(unauthorizedStudents);
      if (shouldContinue) {
        return selectedParticipants.filter((participant) => AuthService.userCan(permissions, participant.groupStakeUnitNumber));
      } else {
        return [];
      }
    }

    return selectedParticipants;

  };

  const unauthorizedPrompt = (unauthorizedStudents: ParticipantGroupDto[] = []) => {
    if (unauthorizedStudents?.length) {
      return new Promise((resolve) => {
        prompt(
          {
            status: "error",
            head: <FormattedMessage id="unauthorized" defaultMessage="Unauthorized" />,
            message: (
              <>
                <FormattedMessage id="unauthorized_participants" defaultMessage="You are not authorized to perform this action on the following participants:" />
                {unauthorizedStudents.map(entry => <p key={`unauth-${entry.id}`}>{entry.givenName} {entry.surname} - {entry.programName}</p>)}
              </>
            ),
            buttons: <><button onClick={() => { resolve(true); }}><FormattedMessage id="continue_without_participants" defaultMessage="Continue without these participants" /></button><button onClick={() => { resolve(false); }}><FormattedMessage id="cancel" defaultMessage="Cancel" /></button></>
          }
        );
      });
    } else {
      alert(
        {
          status: "error",
          head: <FormattedMessage id="unauthorized" defaultMessage="Unauthorized" />,
          message: <FormattedMessage id="unauthorized_all_participants" defaultMessage="You are not authorized to perform that action on any of the selected participants" />
        }
      );
    }
  };

  const showPrintFailures = (failures: ParticipantCertificateFailureDto[], students: ParticipantGroupDto[]) => {
    alert({
      status: "error",
      head: <FormattedMessage id="get_cert_error" defaultMessage="An error occured while retrieving certificates." />,
      message: (
        <>
          <p><FormattedMessage id="not_included_certificates" defaultMessage="The following certificates were not included. This may be because the selected certificate type is not available for the student or group." /></p>
          <ul>
            {failures.map((failure, index) => {
              const participant = students.find(participant => participant.id == failure.participantId && participant.groupId == failure.groupId);
              return (
                <li key={index}>
                  <span>{participant?.givenName} {participant?.surname} - {participant?.programName}</span>
                  {failure.failureReason !== CertificateFailureType.Unknown &&
                    <>
                      <br />
                      <span><FormattedMessage id={CertificateFailureTypeTransLationsKeys[failure.failureReason].key} defaultMessage={CertificateFailureTypeTransLationsKeys[failure.failureReason].defaultMessage} /></span>
                    </>
                  }
                </li>
              );
            })}
          </ul>
        </>
      )
    });
  };

  const verifyNeedConsent = async (participants: ParticipantGroupDto[]) => {
    const dontNeedConsent = participants.filter(participant => {
      if (participant?.status !== StudentStatusesV2.NeedConsent) {
        return true;
      }
    });

    if (dontNeedConsent.length > 0) {
      const consentsNeeded = participants.filter(participant => {
        if (participant?.status == StudentStatusesV2.NeedConsent) {
          return true;
        }
      });
      return new Promise<ParticipantGroupDto[]>((resolve) => {
        prompt({
          status: "warning",
          head: <FormattedMessage id="consent_not_needed" defaultMessage="Parental consent not needed" />,
          message: (
            <>
              <p><FormattedMessage id="consent_not_needed_explanation" defaultMessage="The following participants are selected but are not marked as needing consent. They will be skipped." /></p>
              <ul>
                {dontNeedConsent.map((studentEnrollment, index) => (
                  <li key={index}>
                    {studentEnrollment?.givenName} {studentEnrollment?.surname} - {studentEnrollment?.programName}
                  </li>
                ))}
              </ul>
            </>
          ),
          buttons: (
            <>
              <button
                className="primary"
                onClick={() => {
                  resolve(consentsNeeded);
                }}
              >
                <FormattedMessage id="Continue" defaultMessage="Continue" />
              </button>
              <button
                className="primary hollow"
                onClick={() => {
                  resolve([]);
                }}
              >
                <FormattedMessage id="Cancel" defaultMessage="Cancel" />
              </button>
            </>
          ),
        });
      });
    }

    return participants;

  };

  return (
    <UserCan perform={[Permissions.StudentsRead]} showFail={true}>
      <div className="studentListContainer hasLoader">
        <Loading loading={loading.status} message={loading.message} />
        <div className="controlsContainer">
          <ParticipantListControls participants={participants} viewSetter={setShowView} onFilter={handleFilteredStudents} viewValue={showView} />
        </div>
        {filteredParticipants.length > 0 ?
          <>
            {showView == ViewNames.Table && layout == Layouts.Desktop ?
              <ParticipantListTable
                participants={paginatedParticipants}
                selectedParticipants={selectedParticipants}
                canPrintCompletionCerts={canPrintCompletionCerts}
                canManageStudents={canManageParticipants}
                onSelectChange={onSelectChange}
                onSelectAllChange={onSelectAllChange}
                isSelected={enrollmentIsSelected}
                onStudentLoadingChange={onParticipantLoadingChange}
                updateConsents={updateConsents}
                removeStudents={removeParticipants}
                printParticipationCertificates={() => printCertificates(true)}
                printCompletionCertificates={() => printCertificates(false)}
              />
              :
              <ParticipantListCardList
                participants={paginatedParticipants}
                onParticipantLoadingChange={onParticipantLoadingChange}
              />
            }
          </>
          :
          <>
            {!loading.status &&
              <FormattedMessage id="no_participants" defaultMessage="No participants match your criteria." />
            }
          </>
        }
        <div className={loading.status || filteredParticipants.length < 1 ? "hidden" : ""}>
          <Paginator dataset={filteredParticipants} onPageChange={handlePageChange} />
        </div>
      </div>
    </UserCan>
  );

};

export default ParticipantList;
