import { StandardFacilitator } from "lib/data/model";
import FacilitatorCreationDto from "../model/FacilitatorCreationDto";
import { IModelToDtoConverter } from "./IDtoConverter";

export class StandardFacilitatorToFacilitatorCreationDtoConverter implements IModelToDtoConverter<StandardFacilitator, FacilitatorCreationDto> {
  public toDto = (model: StandardFacilitator): FacilitatorCreationDto => {
    const facilitator = new FacilitatorCreationDto({
      id: model.cmisId,
      firstName: model.firstName,
      lastName: model.lastName,
      gender: model.gender,
      birthDate: model.birthDate?.toISOString()
    });

    return facilitator;
  };
}