import { StudentEnrollmentCourseDto } from "lib/api/backend/model";

export interface IStudentEnrollmentCourse {
  id: number;
  groupId: number;
  programId: number;
  providerId: number;
  locationId: number;
  stakeUnitNumber: number;
  date?: Date;
  hasCompletionCertificate: boolean;
  hasParticipationCertificate: boolean;
  name: string;
  status: string;
}

export class StudentEnrollmentCourse implements IStudentEnrollmentCourse {
  public id: number;
  public groupId: number;
  public programId: number;
  public providerId: number;
  public locationId: number;
  public stakeUnitNumber: number;
  public date?: Date;
  public hasCompletionCertificate: boolean;
  public hasParticipationCertificate: boolean;
  public name: string;
  public status: string;

  constructor(creationValues: IStudentEnrollmentCourse | StudentEnrollmentCourseDto) {
    this.id = creationValues.id;
    this.groupId = creationValues.groupId;
    this.programId = creationValues.programId;
    this.providerId = creationValues.providerId;
    this.locationId = creationValues.locationId;
    this.stakeUnitNumber = creationValues.stakeUnitNumber;
    if (creationValues.date instanceof Date) {
      this.date = creationValues.date;
    } else if (typeof creationValues.date == "string") {
      this.date = new Date(creationValues.date);
    }
    this.hasCompletionCertificate = creationValues.hasCompletionCertificate;
    this.hasParticipationCertificate = creationValues.hasParticipationCertificate;
    this.name = creationValues.name;
    this.status = creationValues.status;
  }
}

export default StudentEnrollmentCourse;