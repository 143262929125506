import axios, { AxiosResponse } from "axios";
import config from "../axios.config.json";
import { ApiUrl } from "constants/api-auth-constants";
import { customParamSerializer } from "../custom-serializer";
import { FacilitatorPrintDto } from "lib/api/backend/model/facilitator";
import { BulkPrintCertificateDto, FacilitatorCertificateDto } from "lib/api/backend/model/certificate";
import CertificateDto from "../../model/certificate/CertificateDto";
import base64ToPdfBlob from "utilities/base64ToPdfBlob";
import downloadFile from "utilities/download-file";
import { BulkParticipantCertificatePrintModel } from "lib/api/backend/model/certificate/requests/BulkParticipantCertificatePrintModel";
import { ParticipantCertificateDto } from "lib/api/backend/model/participant/ParticipantCertificateDto";

export const certificateControllerPath = `${ApiUrl}certificates/`;

export const getAndDownloadBulkParticipantCertificates = async (certificates: BulkParticipantCertificatePrintModel[]) => {

  const response: AxiosResponse<ParticipantCertificateDto> = await axios.post(
    `${certificateControllerPath}bulk-participant-certificates`,
    certificates,
    {
      ...config,
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      },
      responseType: "json",
    },
  );

  if (response.status >= 200 && response.status < 300) {
    if (response.data.pdfFileBase64) {
      const pdfBlob = base64ToPdfBlob(response.data.pdfFileBase64);
      if (pdfBlob != null) {
        downloadFile(pdfBlob, "certificates.pdf");
      }
    }
    return response.data.failures;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }
};

export const getAndDownloadGroupParticipantCertificates = async (groupId: number, participantIds: number[], isParticipation = false) => {

  const response: AxiosResponse<ParticipantCertificateDto> = await axios.get(
    `${certificateControllerPath}group/${groupId}/certificates`,
    {
      params: {
        participantIds,
        isParticipation
      },
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      },
      responseType: "json",
      paramsSerializer: customParamSerializer //using custom serializer so arrays don't get sent with [] in every query param
    }
  );

  if (response.status >= 200 && response.status < 300) {
    if (response.data.pdfFileBase64) {
      const pdfBlob = base64ToPdfBlob(response.data.pdfFileBase64);
      if (pdfBlob != null) {
        downloadFile(pdfBlob, isParticipation ? "Participation Certificates -" + groupId + ".pdf" : "Completion Certificates -" + groupId + ".pdf");
      }
    }
    return response.data.failures;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }
};


///Deprecated, should remove after transition to participants is complete.
export const getAndDownloadGroupCertificates = async (locationId: number, programId: number, groupId: number, studentIds: number[], limitedStudentIds: number[], isParticipation = false) => {

  const response: AxiosResponse<CertificateDto> = await axios.get(
    `${certificateControllerPath}location/${locationId}/program/${programId}/group/${groupId}/combined-certificates`,
    {
      ...config,
      params: {
        studentIds,
        limitedStudentIds,
        isParticipation
      },
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      },
      responseType: "json",
      paramsSerializer: customParamSerializer //using custom serializer so arrays don't get sent with [] in every query param
    }
  );

  if (response.status >= 200 && response.status < 300) {
    if (response.data.pdfFileBase64) {
      const pdfBlob = base64ToPdfBlob(response.data.pdfFileBase64);
      if (pdfBlob != null) {
        downloadFile(pdfBlob, isParticipation ? "Participation Certificates -" + programId + ".pdf" : "Completion Certificates -" + programId + ".pdf");
      }
    }
    return response.data.failures;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }
};

///Deprecated, should remove after transition to participants is complete.
export const getAndDownloadBulkCertificates = async (certificates: BulkPrintCertificateDto) => {

  const response: AxiosResponse<CertificateDto> = await axios.post(
    `${certificateControllerPath}bulk-combined-certificates`,
    certificates,
    {
      ...config,
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      },
      responseType: "json",
    },
  );

  if (response.status >= 200 && response.status < 300) {
    if (response.data.pdfFileBase64) {
      const pdfBlob = base64ToPdfBlob(response.data.pdfFileBase64);
      if (pdfBlob != null) {
        downloadFile(pdfBlob, "certificates.pdf");
      }
    }
    return response.data.failures;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }
};


export const getAndDownloadFacilitatorCertificates = async (certificates: FacilitatorPrintDto) => {

  const response: AxiosResponse<FacilitatorCertificateDto> = await axios.post(
    `${certificateControllerPath}facilitator-certificates`,
    certificates,
    {
      ...config,
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      },
      responseType: "json",
    },
  );

  if (response.status >= 200 && response.status < 300) {
    if (response.data.pdfFileBase64) {
      const pdfBlob = base64ToPdfBlob(response.data.pdfFileBase64);
      if (pdfBlob != null) {
        downloadFile(pdfBlob, "certificates.pdf");
      }
    }
    return response.data.failures;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }
};