import "./row.scss";

import React, { useEffect } from "react";
import { TableCell } from "@mui/material";
import useClassName from "utilities/useClassName";

const Row = (props) => {

  const [className, setPropClassName] = useClassName("cell", "body-cell");

  useEffect(
    () => {
      setPropClassName(props.className ?? "");
    },
    [props.className]
  );

  return (
    <TableCell
      className={className}
      align={props.align ?? "left"}
    >
      {
        props.render
          ? props.accessor
            ? props.render(props.record[props.accessor], props.record)
            : props.render(props.record)
          : <span>{props.record[props.accessor]}</span>
      }
    </TableCell>
  );
};

export default Row;