export interface ICmisEmailDto {
  address: string;
}

export default class CmisEmailDto implements ICmisEmailDto {
  public address: string;

  constructor(creationValues: ICmisEmailDto | CmisEmailDto) {
    this.address = creationValues.address;
  }

  public export(): ICmisEmailDto {
    return {
      address: this.address
    };
  }

  public clone(): CmisEmailDto {
    return new CmisEmailDto(this.export());
  }
}