import React, { ReactElement } from "react";
import { LoadingStatus } from "lib/data/model";
import { ParticipantListCard } from "..";
import { ParticipantGroupDto } from "lib/api/backend/model/participant/ParticipantGroupDto";
import "./participant-list-card-list.scss";

export interface IParticipantListCardListProps {
  participants: ParticipantGroupDto[];
  onParticipantLoadingChange: (loading: LoadingStatus) => void;
}

export const ParticipantListCardList = (props: IParticipantListCardListProps): ReactElement => {
  return (
    <div className="studentListCardListContainer">
      {props.participants && props.participants.map((participant, index) => {
        return (
          <React.Fragment key={`student-${index}`}>
            <ParticipantListCard
              participant={participant}
              onParticipantLoadingChange={props.onParticipantLoadingChange}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ParticipantListCardList;