import "./style.scss";
import React from "react";
import Button from "@mui/material/Button";
import { useImpersonation } from "contexts/impersonation";
import AuthorizeService from "auth/authorize-service";
interface User {
  firstName: string;
  lastName: string;
  employeeId: number;
}

export interface ImpersonationBarProps {
  user: User;
}

const ImpersonationBar: React.VFC<ImpersonationBarProps> = (props) => {

  const [impersonation] = useImpersonation();

  return (
    props.user && impersonation.isImpersonating
      ? (
        <div className="impersonation-bar">
          <h5 className="color-white text-shadow mr16 inline-block">Impersonating: {props.user.lastName}, {props.user.firstName} - ID: {props.user.employeeId}</h5>
          <Button onClick={() => AuthorizeService.endImpersonation()} variant="contained" color="error" id="btn-end">End Impersonation</Button>
        </div>
      )
      : null
  );
};

export default ImpersonationBar;