import "./location-map-panel.scss";
import { SiteDto } from "lib/api/backend/model";
import { Group, Location } from "lib/data/model";
import MapsLoader from "components/maps-loader";
import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { useMemo, useRef, useCallback, useEffect } from "react";
import { SiteAddressDtoToAddressDtoConverter } from "lib/api/backend/converters/SiteAddressDtoToAddressDtoConverter";
import { useGroup } from "lib/data/contexts";
import { LocationType } from "lib/data/model/enum";


interface ILocationMapPanelProps {
  sites: SiteDto[];
  location?: Location;
  onLocationChange: (location: Location, overrideGroup?: Group) => void;
}

type MapOptions = google.maps.MapOptions;

function latLngIsNumber(lat: any, lng: any) {
  return (typeof lat == "number" && typeof lng == "number");
}

const LocationMapPanel: React.VFC<ILocationMapPanelProps> = (props) => {

  useEffect(() => {
    const bounds = new google.maps.LatLngBounds();
    if (props.sites && props.sites.length > 0) {
      props.sites.forEach((site) => {
        if (latLngIsNumber(site.coordinates.latitude, site.coordinates.longitude)) {
          bounds.extend({ lat: site.coordinates.latitude, lng: site.coordinates.longitude });
        }
      });
      const center = bounds.getCenter();
      fitMapToMarker({ lat: center.lat(), lng: center.lng()});
    }
  }, [props.sites]);

  const [group] = useGroup();
  const { sites } = props;
  const mapApi = useRef<google.maps.Map>();


  const options = useMemo<MapOptions>(() => {
    return {
      disableDefaultUI: true,
      clickableIcons: false,
    };
  }, []);

  //By the time the map is loaded, there should always be sites available.
  const onLoad = useCallback((map: google.maps.Map) => {
    mapApi.current = map;

    const bounds = new google.maps.LatLngBounds();

    sites.forEach((site) => {
      if (latLngIsNumber(site.coordinates.latitude, site.coordinates.longitude)) {
        const siteCoords = site.coordinates;
        const gMapCoords = new google.maps.LatLng({
          lat: siteCoords.latitude,
          lng: siteCoords.longitude
        });

        bounds.extend(gMapCoords);
      }
    });

    if (props.location && props.location.coordinates) {
      if (latLngIsNumber(props.location.coordinates.latitude, props.location.coordinates.longitude)) {
        const coords = props.location.coordinates;
        const gMapCoords = new google.maps.LatLng({
          lat: coords.latitude,
          lng: coords.longitude
        });
        bounds.extend(gMapCoords);
      }
    }

    map.fitBounds(bounds);
  }, []);

  const fitMapToMarker = (coords: google.maps.LatLngLiteral) => {
    if (mapApi.current && latLngIsNumber(coords.lat, coords.lng)) {
      mapApi.current.panTo(coords);
      mapApi.current.setZoom(15);
    }
  };

  const onOtherMarkerClick = () => {
    if (props.location) {
      props.onLocationChange(props.location, group);
    }
  };

  const onMarkerClick = (site: SiteDto) => {
    const location = props.location;
    const type = !location || location.type === LocationType.Other ? LocationType.ChurchOwned : location.type;

    props.onLocationChange(
      new Location({
        type: type,
        propertyId: site.id,
        stakeUnitNumber: location?.stakeUnitNumber,
        name: site.name,
        address: new SiteAddressDtoToAddressDtoConverter().toDto(site.address),
        coordinates: site.coordinates
      }),
      group
    );

    if (latLngIsNumber(site.coordinates.latitude, site.coordinates.longitude)) {
      const coords = site.coordinates;
      const gCoords = { lat: coords.latitude, lng: coords.longitude };
      fitMapToMarker(gCoords);
    }
  };

  const renderLocation = () => {

    if (!props.location) {
      return null;
    }

    if (!props.location.coordinates) {
      return null;
    }

    if (!latLngIsNumber(props.location.coordinates.latitude, props.location.coordinates.longitude)) {
      return null;
    }

    const coords = props.location.coordinates;
    const gCoords = { lat: coords.latitude, lng: coords.longitude };

    return (
      <Marker position={gCoords} onClick={onOtherMarkerClick}></Marker>
    );
  };

  const sitesToMarkOnMap = sites.filter((site) => latLngIsNumber(site.coordinates.latitude, site.coordinates.longitude));

  return (
    <MapsLoader>
      <GoogleMap
        mapContainerClassName={"googleMapContainer"}
        options={options}
        onLoad={onLoad}>
        {sitesToMarkOnMap.map((site) => {
          const coords = site.coordinates;
          return (
            <Marker
              key={site.id}
              position={{ lat: coords.latitude, lng: coords.longitude }}
              onClick={() => { onMarkerClick(site); }}
              title={site.name}
            >
            </Marker>
          );
        })}
        {renderLocation()}
      </GoogleMap>
    </MapsLoader>
  );
};

export default LocationMapPanel;