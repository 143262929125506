import "./group-age-type-selector.scss";

import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AgeType } from "lib/data/model/enum";
import useClassName from "utilities/useClassName";
import { useFormStatus } from "lib/form/contexts";
import { RecordType } from "lib/form/model";
import { useGroup } from "lib/data/contexts";
import { Group } from "lib/data/model";

interface IGroupAgeTypeSelectorProps {
  className?: string;
  ageType?: AgeType;
}

const GroupAgeTypeSelector: React.VFC<IGroupAgeTypeSelectorProps> = (props) => {
  const [className, setPropsClassNames] = useClassName("groupAgeTypeSelectorContainer");
  const [
    youthPermissionClassName,
    _,
    addYouthPermissionClassNames,
    removeYouthPermissionClassNames
  ] = useClassName("youthPermissionWarn");

  const [formStatus] = useFormStatus();
  const [group, setGroup] = useGroup();

  const [ageType, setAgeType] = useState(AgeType.Combined);

  useEffect(
    () => {
      if (props.className) {
        setPropsClassNames(props.className);
      }
      else {
        setPropsClassNames();
      }
    },
    [props.className]
  );

  useEffect(
    () => {
      if (ageType === AgeType.Adult) {
        addYouthPermissionClassNames("hidden");
      }
      else {
        removeYouthPermissionClassNames("hidden");
      }
    },
    [ageType]
  );

  useEffect(
    () => {
      if (props.ageType) {
        setAgeType(props.ageType);
      }
    },
    [props.ageType]
  );

  const handleAgeTypeChange = (newAgeType: AgeType) => {
    setAgeType(newAgeType);
    setGroup(
      new Group({
        ...group!,
        groupAgeType: newAgeType
      })
    );
  };

  return (
    <div className={className}>
      <RadioGroup row>
        <FormControlLabel
          label={<FormattedMessage id="group_age_type_combined" defaultMessage="Combined" />}
          control={
            <Radio
              checked={ageType === AgeType.Combined}
              onChange={() => handleAgeTypeChange(AgeType.Combined)}
              disabled={formStatus.recordType === RecordType.Existing}
            />
          }
        />

        <FormControlLabel
          label={<FormattedMessage id="group_age_type_youth" defaultMessage="Youth only" />}
          control={
            <Radio
              checked={ageType === AgeType.Youth}
              onChange={() => handleAgeTypeChange(AgeType.Youth)}
              disabled={formStatus.recordType === RecordType.Existing}
            />
          }
        />

        <FormControlLabel
          label={<FormattedMessage id="group_age_type_adult" defaultMessage="Adult only" />}
          control={
            <Radio
              checked={ageType === AgeType.Adult}
              onChange={() => handleAgeTypeChange(AgeType.Adult)}
              disabled={formStatus.recordType === RecordType.Existing}
            />
          }
        />
      </RadioGroup>

      <span className={youthPermissionClassName}><FormattedMessage id="create_youth_group_warning" defaultMessage="All youth must submit a parental permission form provided." /></span>
    </div>
  );
};

export default GroupAgeTypeSelector;