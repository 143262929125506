export interface ITrackTemplateDto {
  id?: number;
  providerProgramId: number;
  termTypeId?: number;
}

export default class TrackTemplateDto implements ITrackTemplateDto {
  public readonly id?: number;
  public readonly providerProgramId: number;
  public readonly termTypeId?: number;

  constructor(creationValues: ITrackTemplateDto) {
    this.id = creationValues.id;
    this.providerProgramId = creationValues.providerProgramId;
    this.termTypeId = creationValues.termTypeId;
  }

  public export(): ITrackTemplateDto {
    return {
      id: this.id,
      providerProgramId: this.providerProgramId,
      termTypeId: this.termTypeId
    };
  }

  public clone(): TrackTemplateDto {
    return new TrackTemplateDto(this.export());
  }
}