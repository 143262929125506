import React, { ReactElement, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import AuthService from "auth/authorize-service";
import { Permissions } from "constants/api-auth-constants";
import StudentStatuses from "constants/student-constants";
import dispatchers from "datastore/dispatcher";
import { getAndDownloadGroupParticipantCertificates } from "lib/api/backend/requests/certificates";
import { Alert, LoadingStatus, NoticeCreation, Prompt } from "lib/data/model";
import { CertificateFailureType, CertificateFailureTypeTransLationsKeys } from "constants/certificate-constants";
import { GroupStatusesAllowedToPrint } from "constants/group-constants";
import ListActionMenu, { ListActionMenuDefaultAction } from "components/list-action-menu";
import useGroupStore from "datastore/groups";
import { QuickRegParticipant } from "lib/models-v2";
import ParticipantCertificateFailureDto from "lib/api/backend/model/participant/ParticipantCertificateFailureDto";
import { dropParticipants, recieveConsents } from "lib/api/backend/requests/participant";
import MoveParticipantDialog, { MoveParticipantToggleButton } from "components/move-participant-dialog";

export interface IParticipantActionMenu {
  stakeUnitNumber: number;
  groupName: string
  hasParticipationCertificate: boolean;
  hasCompletionCertificate: boolean;
  groupStatus: string;
  participant: QuickRegParticipant;
  forceRight?: boolean;
  borderOnToggle?: boolean;
  onLoadingChange?: (loading: LoadingStatus) => void;
  onParticipantChange?: (updatedParticipant: QuickRegParticipant) => void;
  alignRight?: boolean;
}

export const ParticipantActionMenu = (props: IParticipantActionMenu): ReactElement => {

  const { groupStatus, groupName, stakeUnitNumber, participant: participant, hasCompletionCertificate, hasParticipationCertificate, onLoadingChange } = props;
  const groupStore = useGroupStore();
  const canManageStudents = AuthService.userCan([Permissions.ManageGroupsWrite], stakeUnitNumber);
  const canPrintCompletionCerts = AuthService.userCan([Permissions.PrintStudentCompletionCertificatesWrite], stakeUnitNumber);
  const studentCourseStatus = participant.status;
  const reduxDispatch = useDispatch();
  const prompt = dispatchers(reduxDispatch).prompt as (prompt: Prompt) => void;
  const notify = dispatchers(reduxDispatch).notify as (notice: NoticeCreation) => void;
  const alert = dispatchers(reduxDispatch).alert as (notice: Alert) => void;
  const navigate = useNavigate();
  const intl = useIntl();
  const [showMoveDialog, setShowMoveDialog] = useState<boolean>(false);

  const removeParticipant = async () => {
    if (canManageStudents) {
      const shouldContinue = await confirmRemovePrompt();
      if (shouldContinue) {
        if (participant) {
          try {
            updateParentLoading({ status: true, message: <FormattedMessage id="removing_participant" defaultMessage="Removing Participant" /> });
            const result = await dropParticipants([participant.id]);
            if (!result) {
              throw new Error;
            }
            updateEnrollmentStatus(StudentStatuses.NotEnrolled);
            updateParentLoading({ status: false, message: "" });
          } catch (error) {
            console.error(error);
            notify({ status: "error", message: <FormattedMessage id="enrollment_update_error" defaultMessage="An error occurred while attempting to update the enrollment." /> });
            updateParentLoading({ status: false, message: "" });
          }
        } else {
          alert({ status: "error", head: <FormattedMessage id="could_not_find_participant" defaultMessage="Could not find participant." />, message: "" });
        }
      }
    }
  };

  const confirmRemovePrompt = () => {
    return new Promise((resolve) => {
      prompt(
        {
          status: "warning",
          head: <FormattedMessage id="please_confirm" defaultMessage="Please Confirm" />,
          message: <FormattedMessage id="confirm_remove_participants" defaultMessage="Are you sure you want to remove participants? This cannot be undone" />,
          buttons: <><button onClick={() => { resolve(true); }}><FormattedMessage id="continue" defaultMessage="Continue" /></button><button onClick={() => { resolve(false); }}><FormattedMessage id="cancel" defaultMessage="Cancel" /></button></>
        }
      );
    });
  };

  const updateConsent = async () => {
    if (canManageStudents) {
      if (participant && studentCourseStatus == StudentStatuses.NeedConsent) {
        try {
          updateParentLoading({ status: true, message: <FormattedMessage id="receiving_consent" defaultMessage="Recording Consent Form as received" /> });
          const result = recieveConsents([participant.id]);
          if (!result) {
            throw result;
          }
          updateEnrollmentStatus(StudentStatuses.Enrolled);
          updateParentLoading({ status: false, message: "" });
        } catch (error) {
          notify({ status: "error", message: <FormattedMessage id="enrollment_update_error" defaultMessage="An error occurred while attempting to update the enrollment." /> });
          updateParentLoading({ status: false, message: "" });
        }
      } else {
        alert({ status: "error", head: <FormattedMessage id="could_not_find_participant" defaultMessage="Could not find participant." />, message: "" });
      }
    }
  };

  const handlePrintCert = async (isParticipation: boolean) => {
    if (canManageStudents && (isParticipation && hasParticipationCertificate) || (!isParticipation && hasCompletionCertificate)) {
      updateParentLoading({ status: true, message: <FormattedMessage id="getting_cert" defaultMessage="Getting certificate..." /> });
      if (participant && participant.groupId) {
        const failures = await getAndDownloadGroupParticipantCertificates(participant.groupId, [participant.id], isParticipation);
        if (failures.length > 0) {
          showPrintFailureAlert(failures[0]);
        }
      }
      updateParentLoading({ status: false, message: "" });
    }
  };

  const updateParentLoading = (loading: LoadingStatus) => {
    if (onLoadingChange) {
      onLoadingChange(loading);
    }
  };

  const updateEnrollmentStatus = (status: string) => {
    if (status) {
      const newParticipant = { ...participant, status };
      const groupToUpdate = groupStore.getGroup(participant.groupId!);
      if (groupToUpdate) {
        const studentIndex = groupToUpdate.quickRegParticipants.findIndex(groupStudent => groupStudent.id == participant.id);
        if (studentIndex != -1) {
          groupToUpdate.quickRegParticipants[studentIndex].status = status;
          groupStore.editGroup(groupToUpdate);
        }
      }
      if (props.onParticipantChange) {
        props.onParticipantChange(newParticipant);
      }
    }
  };

  const showPrintFailureAlert = (failure: ParticipantCertificateFailureDto) => {
    alert({
      status: "error",
      head: <FormattedMessage id="get_cert_error" defaultMessage="An error occured while retrieving certificates." />,
      message: (
        <>
          <p><FormattedMessage id="not_included_certificates" defaultMessage="The following certificates were not included. This may be because the selected certificate type is not available for the student or group." /></p>
          <ul>
            <li>
              <span>{participant?.givenName} {participant?.surname} - {groupName}</span>
              {failure.failureReason !== CertificateFailureType.Unknown &&
                <>
                  <br />
                  <span><FormattedMessage id={CertificateFailureTypeTransLationsKeys[failure.failureReason].key} defaultMessage={CertificateFailureTypeTransLationsKeys[failure.failureReason].defaultMessage} /></span>
                </>
              }
            </li>
          </ul>
        </>
      )
    });
  };

  const goToParticipantDetails = () => {
    navigate(`/participant/${participant.id}/group/${participant.groupId}`,
      { state: { returnUrl: location.pathname } });
  };

  const defaultMenuAction = ((): ListActionMenuDefaultAction => {
    const defaultAction = {
      label: intl.formatMessage({ id: "edit_participant", defaultMessage: "Edit Participant" }),
      handler: goToParticipantDetails
    };
    switch (studentCourseStatus) {
      case StudentStatuses.NeedConsent:
        return {
          label: intl.formatMessage({ id: "consent_received", defaultMessage: "Consent Form Received" }),
          handler: updateConsent
        };
      case StudentStatuses.Completed:
        if (hasCompletionCertificate && GroupStatusesAllowedToPrint.includes(groupStatus) && canPrintCompletionCerts) {
          return {
            label: intl.formatMessage({ id: "consent_received", defaultMessage: "Consent Form Received" }),
            handler: updateConsent
          };
        }
        return defaultAction;
      default:
        return defaultAction;
    }
  })();

  return (
    <>
      <ListActionMenu defaultAction={defaultMenuAction} alignRight={props.alignRight}>
        {participant &&
          <>
            {studentCourseStatus == StudentStatuses.NeedConsent && canManageStudents &&
              <button onClick={updateConsent}>
                <FormattedMessage id="consent_received" defaultMessage="Consent Form Received" />
              </button>
            }

            {studentCourseStatus != StudentStatuses.NotEnrolled && canManageStudents &&
              <button onClick={removeParticipant}>
                <FormattedMessage id="remove_participant" defaultMessage="Remove Participant" />
              </button>
            }

            <Link
              to={`/participant/${participant.id}/group/${participant.groupId}`}
              state={{ returnUrl: location.pathname }}
            >
              <button onClick={goToParticipantDetails}>
                <FormattedMessage id="edit_participant" defaultMessage="Edit Participant" />
              </button>
            </Link>

            <MoveParticipantToggleButton onToggle={() => setShowMoveDialog(!showMoveDialog)} />

            {((hasCompletionCertificate || hasParticipationCertificate) && studentCourseStatus !== StudentStatuses.NotEnrolled && GroupStatusesAllowedToPrint.includes(groupStatus)) ?
              <>

                {(hasParticipationCertificate || (hasCompletionCertificate && studentCourseStatus == StudentStatuses.Completed)) &&
                  <span className="menuGroupHead"><FormattedMessage id="print" defaultMessage="Print" /></span>
                }

                {hasParticipationCertificate && canManageStudents &&
                  <button onClick={() => handlePrintCert(true)}>
                    <FormattedMessage id="participation_certificate" defaultMessage="Participation Certificate(s)" />
                  </button>
                }

                {(studentCourseStatus == StudentStatuses.Completed && hasCompletionCertificate) && canPrintCompletionCerts &&
                  <button onClick={() => handlePrintCert(false)}>
                    <FormattedMessage id="completion_certificate" defaultMessage="Completion Certifcate(s)" />
                  </button>
                }

              </>
              :
              <>
                <p><FormattedMessage id="no_print" defaultMessage="Nothing Available to Print" /></p>
              </>
            }
          </>
        }
      </ListActionMenu>
      <MoveParticipantDialog participant={participant} show={showMoveDialog} onClose={() => setShowMoveDialog(false)} onChange={props.onParticipantChange} />
    </>
  );
};

export default ParticipantActionMenu;