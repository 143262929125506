import { FormattedMessage } from "react-intl";
import { StudentStatusesV2 } from "constants/student-constants";
import "./participant-status.scss";

interface IStudentStatusProps {
  status: string;
  uppercase?: boolean;
}

export const ParticipantStatus = (props: IStudentStatusProps) => {
  const displayUppercase = props.uppercase || false;

  return (
    <>
      {props.status &&
        <span className={`participant-status-itemstatus ${props.status.toLowerCase().replaceAll(" ", "-")}`} style={displayUppercase ? { "textTransform": "uppercase" } : {}}>
          {(() => {
            switch (props.status) {
              case StudentStatusesV2.Enrolled: return <FormattedMessage id="enrolled" defaultMessage="Enrolled" />;
              case StudentStatusesV2.NotEnrolled: return <FormattedMessage id="not_enrolled" defaultMessage="Not Enrolled" />;
              case StudentStatusesV2.NeedsConsent: return <FormattedMessage id="need_consent" defaultMessage="Need Consent" />;
              case StudentStatusesV2.PrintCertificate: return <FormattedMessage id="completed" defaultMessage="Completed" />;
              case StudentStatusesV2.DidNotComplete: return <FormattedMessage id="did_not_complete" defaultMessage="Did Not Complete" />;
              case StudentStatusesV2.NeedsReview: return <FormattedMessage id="needs_review" defaultMessage="Needs Review" />;
              case StudentStatusesV2.Completed: return <FormattedMessage id="completed" defaultMessage="complted" />;
              default: return props.status;
            }
          })()}
        </span>
      }
    </>
  );
};

export default ParticipantStatus;