import { ReactElement } from "react";
import DownTriangle from "assets/images/icons/down-triangle.svg";
import { ListActionMenuDefaultAction } from "./models";
import "./list-action-menu-toggle.scss";

interface IListActionMenuProps {
  onToggle: () => void;
  open: boolean;
  defaultAction: ListActionMenuDefaultAction | null;
  alignRight?: boolean;
}

export const ListActionMenuToggle = (
  props: IListActionMenuProps
): ReactElement => {
  return (
    <div
      className={`listActionMenuToggleContainer ${
        props?.alignRight ?? "alignRight"
      }`}
    >
      {props.defaultAction != null && (
        <button
          className="menuControlbutton defaultMenuAction"
          onClick={props.defaultAction.handler}
        >
          {props.defaultAction.label}
        </button>
      )}
      <button
        className={`menuControlbutton toggle ${
          props.defaultAction == null ? "leftBorder" : ""
        }`}
        onClick={props.onToggle}
      >
        <img
          className={`toggleIcon ${props.open ? "rotatedUp" : ""}`}
          src={DownTriangle}
          alt="menu toggle icon"
        />
      </button>
    </div>
  );
};

export default ListActionMenuToggle;
