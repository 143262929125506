import dayjs from "dayjs";
import { StandardFacilitator } from "lib/data/model";
import { FacilitatorDto } from "../model/facilitator";
import { IModelToDtoConverter } from "./IDtoConverter";

export class StandardFacilitatorToFacilitatorDtoConverter implements IModelToDtoConverter<StandardFacilitator, FacilitatorDto> {
  public toDto = (model: StandardFacilitator): FacilitatorDto => {
    const facilitator = new FacilitatorDto({
      id: model.id,
      firstName: model.firstName,
      lastName: model.lastName,
      name: `${model.firstName} ${model.lastName}`.trim(),
      displayName: `${model.firstName} ${model.lastName}`.trim(),
      gender: model.gender,
      birthDate: model.birthDate?.toISOString(),
      age: model.birthDate ? dayjs().diff(dayjs(model.birthDate), "years", false) : 0,
      cmisId: model.cmisId
    });

    return facilitator;
  };
}