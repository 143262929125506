import React, { useState, useEffect } from "react";
import "./notification.scss";
import { getFadeAnimationDuration } from "utilities/styles";
import WarningIcon from "assets/images/icons/warning.svg";
import ErrorIcon from "assets/images/icons/error.svg";
import SuccessIcon from "assets/images/icons/success.svg";
import Cross from "assets/images/icons/cross.svg";
import InfoIcon from "assets/images/icons/info.svg";
import { inView } from "utilities/styles";
import { Notice } from "lib/data/model";

interface INotification {
  notice: Notice;
  autoClear?: false;
  onRemove?: (noticeId: string) => void;
}

const Notification = (props: INotification) => {

  const headerElement = document.querySelector("header");
  const durationInSeconds = 5;
  const [removing, setRemoving] = useState(false);
  const [headerInView, setHeaderInView] = useState(headerElement ? inView(headerElement) : true);

  useEffect(() => {
    if (props.autoClear) {
      setTimeout(() => {
        close();
      }, durationInSeconds * 1000);
    }
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const onScroll = () => {
    setHeaderInView(headerElement ? inView(headerElement) : true);
  };

  let icon;
  switch (props.notice.status) {
    case "error":
      icon = ErrorIcon;
      break;
    case "success":
      icon = SuccessIcon;
      break;
    case "warning":
      icon = WarningIcon;
      break;
    default:
      icon = InfoIcon;
  }

  const close = () => {
    if (props.onRemove) {
      setRemoving(true);
      setTimeout(() => {
        props.onRemove!(props.notice.id);
      }, getFadeAnimationDuration() - 100); // subtracting 100ms to account for the possible margin of error that occurs with setTimeout
      //                                      causing time between the animation finishing and the element being removed
      //                                      without that the element may flash on the screen again after fading away.
    }
  };

  return (
    <div className={`notifcationContainer ${props.notice.status} ${(removing) ? "removing" : ""} ${(!headerInView) ? "headerNotInView" : ""}`}>
      <img src={Cross} className="closeIcon" onClick={close} />
      <div className="notificationContent">
        <img src={icon} className="notificationStatusIcon" />
        <span className="message">
          {props.notice.message}
        </span>
      </div>
    </div>
  );
};

export default Notification;