import "./group-list-table.scss";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import AuthService from "auth/authorize-service";
import Badge from "components/badge/badge";
import GroupActionMenu from "components/group-action-menu/group-action-menu";
import GroupStatus from "components/group-status/group-status";
import Column from "components/table/column/column";
import Pagination from "components/table/pagination/pagination";
import SortableColumn from "components/table/sortable-column/sortable-column";
import Table from "components/table/table";
import { Permissions } from "constants/api-auth-constants";
import GroupStatuses, { GroupAgeTypes } from "constants/group-constants";
import { getHHMM, getLocaleDateString, getWeekdayName } from "utilities/time";
import { FacilitatorTypes } from "constants/facilitator-constants";
import { sortGroupMeetingTimes } from "utilities/sortings";

const canUserManageGroups = () => AuthService.userCan([Permissions.ManageGroupsWrite]);

const GroupListTable = (props) => {

  const [groups, setGroups] = useState([]);
  const [perPageCount, setPerPageCount] = useState(25);
  const intl = useIntl();

  useEffect(
    () => {
      const newGroups = props.groups || [];
      if (newGroups.length) {
        newGroups.forEach(group => {
          group.combinedfacilitators = [];
          if (group.facilitators?.length) {
            group.facilitators.forEach(facilitator => {
              group.combinedfacilitators.push(generateFacilitator(facilitator, FacilitatorTypes.Full));
            });
          }
          if (group.nonMemberFacilitators?.length) {
            group.nonMemberFacilitators.forEach(facilitator => {
              group.combinedfacilitators.push(generateFacilitator(facilitator, FacilitatorTypes.Guest));
            });
          }
          group.meetingTimes = sortGroupMeetingTimes(group.meetingTimes);
        });
      }
      setGroups(newGroups ?? []);
    },
    [props.groups]
  );

  const generateFacilitator = (facilitator, type) => {
    return {
      name: facilitator.name,
      type
    };
  };

  const displayEnrollmentCount = (enrollmentCount, maxEnrollment) => {

    if(enrollmentCount === null) {
      return "--/--";
    }

    if(maxEnrollment === null || maxEnrollment <= 0) {
      return `${enrollmentCount}`;
    }

    return `${enrollmentCount}/${maxEnrollment}`;
  };

  return (
    <div className="group-list-table-wrapper">
      <Table rows={groups} className="group-list-table" id={"group-list-table"}>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "group_type", defaultMessage: "Group Type" })} />
          <SortableColumn.Row
            accessor="programName"
            render={(name, group) => (
              <div className="group-name">
                {
                  canUserManageGroups()
                    ? <MuiLink component={Link} to={`/groups/${group.id}`}>{name}</MuiLink>
                    : <span>{name}</span>
                }
              </div>
            )}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "facilitator", defaultMessage: "Facilitator" })} />
          <SortableColumn.Row
            accessor="facilitators"
            render={(facilitators, group) => (
              <div className="group-facilitators">
                {facilitators?.length > 0 &&  facilitators?.map((facilitator, index) => 
                  <div className="group-facilitator" key={`${group.id}-facilitator-${index}`}>
                    <span>{facilitator.name}</span>
                  </div>
                )}
                {group.nonMemberFacilitators?.length > 0 && group.nonMemberFacilitators?.map((nmFacilitator, index) =>
                  <div className="group-facilitator" key={`${group.id}-nmFacilitator-${index}`}>
                    <span>{nmFacilitator.name}</span>
                  </div>
                )}
                {facilitators?.length < 1 && group.nonMemberFacilitators?.length < 1 && 
                  <div className="group-facilitator">
                    <span>
                      <FormattedMessage id="unassigned" defaultMessage="Unassigned" />
                    </span>
                  </div>
                }
              </div>
            )}
            sort={(a, b) => {
              const aTemp = Array.from(a ?? []).sort();
              const bTemp = Array.from(b ?? []).sort();

              const aZerothName = aTemp[0]?.name ?? intl.formatMessage({ id: "unassigned", defaultMessage: "Unassigned" });
              const bZerothName = bTemp[0]?.name ?? intl.formatMessage({ id: "unassigned", defaultMessage: "Unassigned" });

              return aZerothName.localeCompare(bZerothName);
            }}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "language", defaultMessage: "Language" })} />
          <SortableColumn.Row
            accessor="language"
            render={(language, group) => (
              <div className="group-languages">
                <div className="group-language" key={`${group.id}-language`}>
                  <span>{language ? language.description : <FormattedMessage id="multilingual" defaultMessage="Multilingual" />}</span>
                </div>
              </div>
            )}
            sort={(a, b) => {
              const aDescription = a.description ?? "Multilingual";
              const bDescription = b.description ?? "Multilingual";
              
              return aDescription.localeCompare(bDescription);
            }}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "ages", defaultMessage: "Ages" })} />
          <SortableColumn.Row
            render={(record) => { 
              return (
                <div className="group-class-types">
                  {(() => {
                    switch (record.groupAgeType) {
                      case GroupAgeTypes.Combined: {
                        return (<span>{intl.formatMessage({ id: "group_age_type_combined", defaultMessage: "Combined" })}</span>);
                      }
                      case GroupAgeTypes.Youth: {
                        return (<span>{intl.formatMessage({ id: "group_age_type_youth", defaultMessage: "Youth only" })}</span>);
                      }
                      case GroupAgeTypes.Adult: {
                        return (<span>{intl.formatMessage({ id: "group_age_type_adult", defaultMessage: "Adult only" })}</span>);
                      }
                    }
                  })()}
                </div>
              );
            }}
            sort={(a, b) => {
              const getAgeTypeTranslation = (group) => {
                if (group.isYouthOnly) {
                  return intl.formatMessage({ id: "group_age_type_youth", defaultMessage: "Youth only" });
                }
                if (group.isAdultOnly) {
                  return intl.formatMessage({ id: "group_age_type_adult", defaultMessage: "Adult only" });
                }
                return intl.formatMessage({ id: "group_age_type_combined", defaultMessage: "All Ages" });
              };

              const aTemp = getAgeTypeTranslation(a);

              const bTemp = getAgeTypeTranslation(b);

              return aTemp.localeCompare(bTemp);
            }}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "location", defaultMessage: "Location" })} />
          <SortableColumn.Row
            render={(record) => (
              <>
                <div className="group-address" translate="no">
                  <div className="group-address-line"><span>{record.address1}</span></div>
                  <div className="group-address-line"><span>{record.address2}</span></div>
                  <div className="group-address-line"><span>{record.city}, {record.state} {record.postalCode}</span></div>
                  <div className="group-address-line"><span>{record.country}</span></div>
                </div>
                {
                  record.isVirtual
                  && (
                    <Badge type="virtual"><FormattedMessage id="virtual" defaultMessage="Virtual" /></Badge>
                  )
                }
              </>
            )}
            sort={(a, b) => {
              const firstLineComparison = (a.address1 ?? "").localeCompare(b.address1 ?? "");
              const secondLineComparison = (a.address2 ?? "").localeCompare(b.address2 ?? "");
              const thirdLineComparison = (a.city ?? a.state ?? a.postalCode ?? "").localeCompare(b.city ?? b.state ?? b.postalCode ?? "");
              const fourthLineComparison = (a.country ?? "").localeCompare(b.country ?? "");

              if (!firstLineComparison) {
                if (!secondLineComparison) {
                  if (!thirdLineComparison) {
                    return fourthLineComparison;
                  }

                  return thirdLineComparison;
                }

                return secondLineComparison;
              }

              return firstLineComparison;
            }}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "day_and_time", defaultMessage: "Day and Time" })} />
          <SortableColumn.Row
            render={(record) => (
              <div className="group-dates-and-times">
                <div className="group-dates">
                  {record?.startDate &&
                    <>
                      <time dateTime={record.startDate}>{getLocaleDateString(record.startDate)}</time>
                      <span> - </span>
                    </>
                  }
                  {(record?.startDate && !record?.endDate) &&
                    <span><FormattedMessage id="open" defaultMessage="Open" /></span>
                  }
                  {(record?.endDate && record?.startDate) &&
                    <time dateTime={record.endDate}>{getLocaleDateString(record.endDate)}</time>
                  }
                  {(record?.endDate && !record?.startDate) &&
                    <>
                      <span><FormattedMessage id="end_date" defaultMessage="End date" /> </span>
                      <time dateTime={record.endDate}>{getLocaleDateString(record.endDate)}</time>
                    </>
                  }
                </div>

                <div className="group-meeting-times">
                  {
                    record.meetingTimes
                    && (
                      record.meetingTimes.map((day, index) => {
                        const weekday = getWeekdayName(day.dayOfWeek);

                        const formattedWeekday = intl.formatMessage({ id: weekday.toLowerCase(), defaultMessage: weekday });
                        let formattedStartTime = getHHMM(day.startTime);
                        let formattedEndTime;
                        let formattedTimeSpacer;

                        if (day.endTime) {
                          formattedTimeSpacer = "-";
                          formattedEndTime = getHHMM(day.endTime);
                        }

                        // Spaces are inserted after non-empty and not-last spans by CSS
                        return (
                          <div className="group-meeting-time" key={`${record.id}-meeting-time-${index}`}>
                            <span>{formattedWeekday}</span>
                            <span>{formattedStartTime}</span>
                            <span>{formattedTimeSpacer}</span>
                            <span>{formattedEndTime}</span>
                          </div>
                        );
                      })
                    )
                  }
                </div>
              </div>
            )}
            sort={(a, b) => {
              const aStartDate = a.startDate ? new Date(getLocaleDateString(a.startDate)) : new Date(-8640000000000000);
              const bStartDate = b.startDate ? new Date(getLocaleDateString(b.startDate)) : new Date(-8640000000000000);

              const aEndDate = a.endDate ? new Date(getLocaleDateString(a.endDate)) : new Date(-8640000000000000);
              const bEndDate = b.endDate ? new Date(getLocaleDateString(b.endDate)) : new Date(-8640000000000000);

              const startDateComparison = aStartDate > bStartDate ? 1 : (aStartDate < bStartDate ? -1 : 0);
              const endDateComparison = aEndDate > bEndDate ? 1 : (aEndDate < bEndDate ? -1 : 0);

              if (!startDateComparison) {
                if (!endDateComparison) {
                  const aMeetingTime = a.meetingTimes && Array.isArray(a.meetingTimes) && a.meetingTimes.length > 0 ? a.meetingTimes[0] : null;
                  const bMeetingTime = b.meetingTimes && Array.isArray(b.meetingTimes) && b.meetingTimes.length > 0 ? b.meetingTimes[0] : null;

                  if (!aMeetingTime && !bMeetingTime) {
                    return 0;
                  }
                  else if (!aMeetingTime && bMeetingTime) {
                    return 1;
                  }
                  else if (aMeetingTime && !bMeetingTime) {
                    return -1;
                  }

                  const aWeekday = aMeetingTime.dayOfWeek ? aMeetingTime.dayOfWeek : 0;
                  const bWeekday = bMeetingTime.dayOfWeek ? bMeetingTime.dayOfWeek : 0;

                  const aStartTime = aMeetingTime.startTime ? getHHMM(aMeetingTime.startTime) : "";
                  const bStartTime = bMeetingTime.startTime ? getHHMM(bMeetingTime.startTime) : "";

                  const aEndTime = aMeetingTime.endTime ? getHHMM(aMeetingTime.endTime) : "";
                  const bEndTime = bMeetingTime.endTime ? getHHMM(bMeetingTime.endTime) : "";

                  const weekdayComparison = aWeekday - bWeekday;
                  const startTimeComparison = aStartTime.localeCompare(bStartTime);
                  const endTimeComparison = aEndTime.localeCompare(bEndTime);

                  if (!weekdayComparison) {
                    if (!startTimeComparison) {
                      return endTimeComparison;
                    }

                    return startTimeComparison;
                  }

                  return weekdayComparison;
                }

                return endDateComparison;
              }

              return startDateComparison;
            }}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "status", defaultMessage: "Status" })} />
          <SortableColumn.Row
            render={(record) => {
              const status = record.isPending ? GroupStatuses.Pending : record.status;

              return (
                <div className="group-status">
                  <GroupStatus status={status} uppercase={false} />
                </div>
              );
            }}
            sort={(a, b) => {

              const getStatusTranslation = (group) => {
                if (group.isPending) {
                  return intl.formatMessage({ id: "pending", defaultMessage: "Pending" });
                }
                switch (group.status) {
                  case GroupStatuses.Completed:
                    return intl.formatMessage({ id: "completed", defaultMessage: "Completed" });
                  case GroupStatuses.Concluded:
                    return intl.formatMessage({ id: "completed", defaultMessage: "Completed" });
                  case GroupStatuses.Cancelled:
                    return intl.formatMessage({ id: "cancelled", defaultMessage: "Cancelled" });
                  case GroupStatuses.InProgress:
                    return intl.formatMessage({ id: "in_progress", defaultMessage: "In Progress" });
                  case GroupStatuses.Upcoming:
                    return intl.formatMessage({ id: "upcoming", defaultMessage: "Upcoming" });
                }
                return "";
              };

              const aTemp = getStatusTranslation(a);

              const bTemp = getStatusTranslation(b);

              return aTemp.localeCompare(bTemp);
            }}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "enrolled", defaultMessage: "Enrolled" })} />
          <SortableColumn.Row
            accessor="enrollmentCount"
            render={(value, record) => {
              return (
                <div className="group-enrollment">
                  {displayEnrollmentCount(value, record.maxEnrollment)}
                </div>
              );
            }}
            sort={(a, b) => { 

              let aTemp = a;
              let bTemp = b;

              if(aTemp === null) 
                aTemp = -1;  
                
              if(bTemp === null) 
                bTemp = -1; 
              
              return aTemp - bTemp; 
            }}
          />
        </SortableColumn>
        <Column>
          <Column.Row
            render={(record) => (
              <div className="group-actions">
                <GroupActionMenu group={record} forceRight={true} />
              </div>
            )}
          />
        </Column>
        <Pagination perPageCount={perPageCount} recordCount={groups.length} onChange={(currentPage, pageCount) => { setPerPageCount(pageCount); }} perPageOptions={[{ label: "All", value: -1 }, 5, 10, 25, 50]} />
      </Table>
    </div>
  );
};

export default GroupListTable;
