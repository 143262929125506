import React, { useEffect, useState } from "react";
import { useLocation, Outlet } from "react-router-dom";

const ScrollFix = () => {

  const location = useLocation();

  const [previousLocation, setPreviousLocation] = useState(location);

  useEffect(
    () => {
      if (location !== previousLocation) {
        window.scrollTo(0, 0);

        setPreviousLocation(location);
      }
    },
    [location]
  );

  return <Outlet />;
};

export default ScrollFix;