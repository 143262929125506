import React, { ReactElement, useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import "./list-action-menu-popover.scss";

interface IListActionMenuList {
  open: boolean;
  onToggle: () => void;
  children?: React.ReactNode;
}

export const ListActionMenuPopover = (props: IListActionMenuList): ReactElement => {

  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const anchorRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    if (anchorRef.current) {
      setAnchor(anchorRef.current);
    }
  }, []);

  return (
    <div ref={anchorRef} className="listActionMenuPopoverContainer">
      <Popover
        classes={{ paper: "listActionMenuPopover" }}
        open={props.open && !!anchor}
        anchorEl={anchor}
        onClose={props.onToggle}
        onClick={props.onToggle}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {props.children}
      </Popover>
    </div>
  );
};

export default ListActionMenuPopover;