import "./start-end-date-selector.scss";

import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import dayjs, { Dayjs } from "dayjs";
import { useFormStatus } from "lib/form/contexts";
import { FormStatus, SaveState } from "lib/form/model";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { IconButton } from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import useGroupCreationFormStore from "datastore/groupCreationForm";

const today = dayjs().startOf("day");

const StartEndDateSelectorErrorKeys = Object.freeze({
  dates: "dates",
  startDate: "startDate",
  endDate: "endDate",
});


const StartEndDateSelector: React.VFC = () => {
  const language = useSelector((state: any) => state.appReducer.language);

  const [formStatus, setFormStatus] = useFormStatus();

  const formStore = useGroupCreationFormStore();
  const startDate = formStore.group?.startDate ? dayjs(formStore.group.startDate) : undefined;
  const endDate = formStore.group?.endDate ? dayjs(formStore.group.endDate) : undefined;
  const isPending = formStore.group?.isPending;

  const intl = useIntl();

  useEffect(
    () => {
      isStartDateValid(startDate);
      isEndDateValid(endDate);
    },
    [isPending]
  );

  useEffect(() => {
    validateForNewLanguage();
  }, [language]);

  const validateForNewLanguage = () => {
    const clearValidationMessages = () => {
      formStatus.errors.clearMessages(StartEndDateSelectorErrorKeys.dates);
      formStatus.errors.clearMessages(StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.startDate);
      formStatus.errors.clearMessages(StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.endDate);
    };

    const validateMeetingTimes = () => {
      isStartDateValid(startDate);
      isEndDateValid(endDate);
    };

    clearValidationMessages();
    validateMeetingTimes();

    setFormStatus(new FormStatus(formStatus));
  };

  const handleStartDateChange = (newStartDate: Dayjs | null) => {
    formStatus.dirtiness.setDirty(true, StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.startDate);

    setFormStatus(
      new FormStatus(formStatus)
    );

    if(newStartDate === null){
      formStore.setStartDate(null);
    }

    if (isStartDateValid(newStartDate ?? undefined) && isEndDateValid(endDate ?? undefined)) {
      if(newStartDate !== null) {
        formStore.setStartDate(newStartDate.toDate());
      }
    }
  };

  const isStartDateValid = (newStartDate?: Dayjs) => {

    let isValid = true;

    const dateRequiredErrorMessage = intl.formatMessage({ id: "required", defaultMessage: "Required" });

    if (!isPending && !newStartDate) {

      isValid = false;

      formStatus.errors.addMessage(dateRequiredErrorMessage, StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.startDate);
    }
    else {
      formStatus.errors.removeMessage(dateRequiredErrorMessage, StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.startDate);
    }

    formStatus.validity.setValidity(isValid, StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.startDate);

    setFormStatus(
      new FormStatus(formStatus)
    );

    return isValid;
  };

  const handleEndDateChange = (newEndDate: Dayjs | null) => {

    formStatus.dirtiness.setDirty(true, StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.endDate);

    setFormStatus(
      new FormStatus(formStatus)
    );

    if (isEndDateValid(newEndDate ?? undefined)) {
      if(newEndDate === null) {
        formStore.setEndDate(null);
      }
      else {
        formStore.setEndDate(newEndDate!.toDate());
      }
    }
  };

  const isEndDateValid = (newEndDate?: Dayjs) => {
    let isValid = true;

    const startDateAfterEndDateErrorMessage = intl.formatMessage({ id: "invalid_end_date", defaultMessage: "End date must be after start date." });

    if (startDate && newEndDate && startDate.isAfter(newEndDate)) {
      isValid = false;
      formStatus.errors.addMessage(startDateAfterEndDateErrorMessage, StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.endDate);
    }
    else {
      formStatus.errors.removeMessage(startDateAfterEndDateErrorMessage, StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.endDate);
    }

    formStatus.validity.setValidity(isValid, StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.endDate);

    setFormStatus(
      new FormStatus(formStatus)
    );

    return isValid;
  };

  ////////////////////
  // Render Helpers //
  ////////////////////

  const startDateDisabled = startDate && startDate.isBefore(today) && isPending;

  const startDateHasErrors = (
    !formStatus.validity.isValid(StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.startDate)
    && (
      formStatus.saveState === SaveState.ValidationFailed
      || formStatus.dirtiness.isDirty(StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.startDate)
    )
  );

  const startDateHelperText = startDateHasErrors
    ? formStatus.errors.getMessages(StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.startDate).map(
      (message, index) => (
        <span key={`start-date-picker-message-${index}`}>
          {message}
        </span>
      ))
    : formStatus.messages.getMessages(StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.startDate).map(
      (message, index) => (
        <span key={`start-date-picker-message-${index}`}>
          {message}
        </span>
      ));

  const endDateHasErrors = (
    !formStatus.validity.isValid(StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.endDate)
    && (
      formStatus.saveState === SaveState.ValidationFailed
      || formStatus.dirtiness.isDirty(StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.endDate)
    )
  );

  const endDateHelperText = endDateHasErrors
    ? formStatus.errors.getMessages(StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.endDate).map(
      (message, index) => (
        <span key={`end-date-picker-message-${index}`}>
          {message}
        </span>
      ))
    : formStatus.messages.getMessages(StartEndDateSelectorErrorKeys.dates, StartEndDateSelectorErrorKeys.endDate).map(
      (message, index) => (
        <span key={`end-date-picker-message-${index}`}>
          {message}
        </span>
      ));

  ////////////
  // Render //
  ////////////

  return (
    <div className="startEndDateSelectorContainer startEndSelector">
      <MobileDatePicker
        label={<FormattedMessage id="start_date" defaultMessage="Start Date" />}
        inputFormat="LL"
        disableMaskedInput={true} // 'LL' uses month's entire name instead of numbers. Mask is not dynamically generated.  Use default ('L') or no mask.
        value={startDate ?? null}
        closeOnSelect={true}
        onChange={handleStartDateChange}
        ignoreInvalidInputs={true}
        disabled={startDateDisabled}
        renderInput={(params) => (
          <TextField
            disabled={startDateDisabled}
            {...params}
            InputProps={{
              endAdornment: <IconButton onClick={() => handleStartDateChange(null)}><ClearIcon /></IconButton>
            }}
            error={startDateHasErrors}
            helperText={startDateHelperText}
          />
        )}
      />

      <MobileDatePicker
        label={<FormattedMessage id="end_date" defaultMessage="End date" />}
        inputFormat="LL"
        closeOnSelect={true}
        disableMaskedInput={true} // 'LL' uses month's entire name instead of numbers. Mask is not dynamically generated.  Use default ('L') or no mask.
        value={endDate ?? null}
        minDate={startDate}
        defaultCalendarMonth={startDate}
        onChange={handleEndDateChange}
        ignoreInvalidInputs={true}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              endAdornment: <IconButton onClick={() => handleEndDateChange(null)}><ClearIcon /></IconButton>
            }}
            error={endDateHasErrors}
            helperText={endDateHelperText}
          />
        )}
      />

      < div className="durationContainer">
        <span className="durationHead"><FormattedMessage id="duration" defaultMessage="Duration" /></span>

        <span className="durationContent">
          {
            endDate
              ? <FormattedMessage id="set_schedule" defaultMessage="Set Schedule" />
              : <FormattedMessage id="open" defaultMessage="Open" />
          }
        </span>
      </div>
    </div>
  );
};

export default StartEndDateSelector;