import Layouts from "constants/layout-constants";

export default function getLayout(): Layouts {

  const themeStyles = getComputedStyle(document.documentElement);
  const desktopBreakpoint = stripPx(themeStyles.getPropertyValue("--desktopBreakpoint"));
  const mobileBreakpoint = stripPx(themeStyles.getPropertyValue("--mobileBreakpoint"));
  let layout;

  if (window.innerWidth >= desktopBreakpoint) {
    layout = Layouts.Desktop;
  } else if ((window.innerWidth < desktopBreakpoint) && (window.innerWidth > mobileBreakpoint)) {
    layout = Layouts.Tablet;
  } else {
    layout = Layouts.Mobile;
  }

  return layout;
}

export function getFadeAnimationDuration(): number {
  return parseInt(stripMs(getThemeValue("fadeAnimationDuration")));
}

export function getGrowAnimationDuration(): number {
  return parseInt(stripMs(getThemeValue("growAnimationDuration")));
}

export function setTheme(keyValuePairs): void {
  const root = document.documentElement;
  Object.keys(keyValuePairs).forEach((key) => {
    root.style.setProperty(`--${key}`, keyValuePairs[key]);
  });
}

export function getThemeValue(value: string): string {
  const themeStyles = getComputedStyle(document.documentElement);
  return themeStyles.getPropertyValue("--" + value).trim();
}

export function getBreakpoints(): Breakpoints {
  const themeStyles = getComputedStyle(document.documentElement);
  const desktopBreakpoint = stripPx(themeStyles.getPropertyValue("--desktopBreakpoint"));
  const mobileBreakpoint = stripPx(themeStyles.getPropertyValue("--mobileBreakpoint"));

  return {
    desktopBreakpoint,
    mobileBreakpoint
  };

}

function stripPx(string: string): number {
  return parseInt(string.replace("px", "").trim());
}

function stripMs(string: string): string {
  return string.replace("ms", "");
}

export function inView(element: HTMLElement): boolean {
  const rect = element.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
}

interface Breakpoints {
  desktopBreakpoint: number,
  mobileBreakpoint: number
}