import React, { createContext, useContext, useEffect, useRef, useState } from "react";

interface Impersonation {
  isImpersonating: boolean;
}

interface ImpersonationState {
  impersonation: Impersonation,
  setImpersonation: (value: Impersonation) => void;
}

const defaultState: ImpersonationState = {
  impersonation: {
    isImpersonating: false
  },
  setImpersonation: (value) => void (value)
};

const Context = createContext<ImpersonationState>(defaultState);

interface ProviderProps<T> {
  value?: T;
}

export const ImpersonationProvider: React.FC<ProviderProps<Impersonation>> = (props) => {
  const [impersonation, setImpersonation] = useState(props.value ?? defaultState.impersonation);
  const [cookie, setCookie] = useState(document.cookie);
  const [isManuallyModified, setIsManuallyModified] = useState(false);

  const cookieTimer = useRef<NodeJS.Timer>();

  useEffect(
    () => {
      if (props.value) {
        handleImpersonation(props.value);
      }
      else {
        const resetImpersonation = () => {
          cookieTimer.current = setInterval(
            () => {
              if (document.cookie !== cookie) {
                setCookie(document.cookie);
              }
            },
            5000
          );

          setImpersonation(defaultState.impersonation);
          setIsManuallyModified(false);

          return () => {
            if (cookieTimer.current) {
              clearInterval(cookieTimer.current);
            }
          };
        };

        return resetImpersonation();
      }
    },
    [props.value]
  );

  useEffect(
    () => {
      if (isManuallyModified && cookieTimer.current) {
        clearInterval(cookieTimer.current);
      }
    },
    [isManuallyModified]
  );

  useEffect(
    () => {
      const impersonationCookie = cookie.match(/^(?:.*;)?\s*(Impersonation\s*=\s*[^;]+)(?:.*)?$/);

      const newImpersonation = {
        isImpersonating: impersonationCookie !== null
      };

      if (!isManuallyModified) {
        setImpersonation(newImpersonation);
      }
    },
    [cookie]
  );

  const handleImpersonation = (value: Impersonation) => {
    setImpersonation(value);
    setIsManuallyModified(true);
  };

  return (
    <Context.Provider
      value={{
        impersonation: impersonation,
        setImpersonation: handleImpersonation
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export const useImpersonation = (): [Impersonation, (value: Impersonation) => void] => {
  const state = useContext(Context);

  const setImpersonation = (value: Impersonation) => {
    if (state.setImpersonation) {
      state.setImpersonation(value);
    }
  };

  return [state.impersonation, setImpersonation];
};