export enum CertificateFailureType {
  Unknown = 1,
  NotAwarded = 2,
  GroupCertificateNotAvailableOrEmpty = 3,
}

export const CertificateFailureTypeTransLationsKeys = Object.freeze({
  1: {
    key: "certificate_failure_unknown",
    defaultMessage: "An unknown error occured while trying to get the certificate."
  },
  2: {
    key: "certificate_not_awarded",
    defaultMessage: "Certificate not yet awarded. Completion certificates may only be printed for completed groups and participants."
  },
  3: {
    key: "certificate_unavailable_empty",
    defaultMessage: "Certificate unavailable or empty. Certificate could not be generated because the group certificate is not correctly configured."
  }
});


