import React from "react";
import { FormattedMessage } from "react-intl";
import { redirectToServerSignOut } from "utilities/navUtils";

const SignInError: React.FC = () => {
  return (
    <>
      <h5 className="error"><FormattedMessage id="sign_in_error" defaultMessage="Sign In Error" /></h5>
      <section>
        <p className="error"><FormattedMessage id="sign_in_error_explanation" defaultMessage="You are signed in, but QuickReg was unable retrieve all the necessary account information." /></p>
        <button className="primary" onClick={redirectToServerSignOut}><FormattedMessage id="sign_out" defaultMessage="Sign Out" /></button>
      </section>
    </>
  );
};

export default SignInError;
