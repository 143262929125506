import "./header.scss";
import { useEffect } from "react";
import { TableCell } from "@mui/material";
import { ListActionMenu } from "components/list-action-menu";
import useClassName from "utilities/useClassName";

const Header = (props) => {

  const [className, setPropClassName] = useClassName("cell", "header-cell");

  useEffect(
    () => {
      setPropClassName(props.className ?? "");
    },
    [props.className]
  );

  return (
    <TableCell
      className={className}
      align={props.align ?? "left"}
    >
      <span>{props.name}</span>
      {props.actions &&
        <div className="actionsMenu">
          <ListActionMenu actions={props.actions}>
            {props.actions}
          </ListActionMenu>
        </div>
      }
    </TableCell>
  );
};

export default Header;