import "./location-map-panel.scss";
import { SiteDto } from "lib/api/backend/model";
import MapsLoader from "components/maps-loader";
import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { useMemo, useRef, useCallback, useEffect } from "react";
import { LocationType } from "lib/data/model/enum";
import { LocationCreationDto } from "lib/api/backend/model/group/GroupCreationDto";
import useGroupCreationFormStore from "datastore/groupCreationForm";


interface ILocationMapPanelProps {
  sites: SiteDto[];
  onLocationChange: (location: LocationCreationDto) => void;
}

type MapOptions = google.maps.MapOptions;

function latLngIsNumber(lat: any, lng: any) {
  return (typeof lat == "number" && typeof lng == "number");
}

const LocationMapPanel: React.VFC<ILocationMapPanelProps> = (props) => {

  const formStore = useGroupCreationFormStore();
  const group = formStore.group;

  useEffect(() => {
    const bounds = new google.maps.LatLngBounds();
    if (props.sites && props.sites.length > 0) {
      props.sites.forEach((site) => {
        if (latLngIsNumber(site.coordinates.latitude, site.coordinates.longitude)) {
          bounds.extend({ lat: site.coordinates.latitude, lng: site.coordinates.longitude });
        }
      });
      const center = bounds.getCenter();
      fitMapToMarker({ lat: center.lat(), lng: center.lng()});
    }
  }, [props.sites]);

  const { sites } = props;
  const mapApi = useRef<google.maps.Map>();


  const options = useMemo<MapOptions>(() => {
    return {
      disableDefaultUI: true,
      clickableIcons: false,
    };
  }, []);

  //By the time the map is loaded, there should always be sites available.
  const onLoad = useCallback((map: google.maps.Map) => {
    mapApi.current = map;

    const bounds = new google.maps.LatLngBounds();

    sites.forEach((site) => {
      if (latLngIsNumber(site.coordinates.latitude, site.coordinates.longitude)) {
        const siteCoords = site.coordinates;
        const gMapCoords = new google.maps.LatLng({
          lat: siteCoords.latitude,
          lng: siteCoords.longitude
        });

        bounds.extend(gMapCoords);
      }
    });

    map.fitBounds(bounds);
  }, []);

  const fitMapToMarker = (coords: google.maps.LatLngLiteral) => {
    if (mapApi.current && latLngIsNumber(coords.lat, coords.lng)) {
      mapApi.current.panTo(coords);
      mapApi.current.setZoom(15);
    }
  };

  const onMarkerClick = (site: SiteDto) => {
    const location = group?.location;
    const type = !location || location.locationType === LocationType.Other ? LocationType.ChurchOwned : location.locationType;

    if (latLngIsNumber(site.coordinates.latitude, site.coordinates.longitude)) {
      const coords = site.coordinates;
      const gCoords = { lat: coords.latitude, lng: coords.longitude };
      fitMapToMarker(gCoords);
    }

    const newLocation = {
      address1: site.address.line1,
      address2: site.address.line2!,
      city: site.address.city,
      state: site.address.state,
      country: site.address.country,
      propId: site.id,
      latitude: site.coordinates.latitude,
      longitude: site.coordinates.longitude,
      postalCode: site.address.postalCode,
      unitNumber: site.stakeUnitNumber,
      locationType: type,
    };

    props.onLocationChange(newLocation);
    formStore.setLocation(newLocation);
  };

  const sitesToMarkOnMap = sites.filter((site) => latLngIsNumber(site.coordinates.latitude, site.coordinates.longitude));

  return (
    <MapsLoader>
      <GoogleMap
        mapContainerClassName={"googleMapContainer"}
        options={options}
        onLoad={onLoad}>
        {sitesToMarkOnMap.map((site) => {
          const coords = site.coordinates;
          return (
            <Marker
              key={site.id}
              position={{ lat: coords.latitude, lng: coords.longitude }}
              onClick={() => { onMarkerClick(site); }}
              title={site.name}
            >
            </Marker>
          );
        })}
      </GoogleMap>
    </MapsLoader>
  );
};

export default LocationMapPanel;