import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ListControlPanel from "components/list-control-panel/list-control-panel";
import { FormattedMessage, useIntl } from "react-intl";
import Layouts from "constants/layout-constants";
import { ControlInput, ControlInputType, FilterConfig, FilterInputType } from "components/list-control-panel";
import { GroupParticipantListViewNames } from "components/group-participant-list/models";
import StudentStatuses from "constants/student-constants";
import * as StudentFilters from "utilities/filters/group-student-filters";
import { QuickRegParticipant } from "lib/models-v2";
import { getLocaleDateString } from "utilities/time";

interface IGroupStudentListControls {
  onFilter: (students: QuickRegParticipant[]) => void;
  viewSetter: (view: GroupParticipantListViewNames) => void;
  viewValue: GroupParticipantListViewNames;
  participants: QuickRegParticipant[];
  isLoading: boolean;
}

export const GroupParticipantListControls = (props: IGroupStudentListControls) => {

  const { onFilter, viewSetter, viewValue, isLoading } = props;
  const layout = useSelector((state: any) => state.appReducer.layout);
  const language = useSelector((state: any) => state.appReducer.language);
  const [participants, setParticipants] = useState<QuickRegParticipant[]>(props.participants);
  const intl = useIntl();

  const buildConfig = (): FilterConfig => {

    const availableStatuses: { value: string, displayText: string }[] = [];
    const availableDates: { value: Date, displayText: string }[] = [];

    participants.forEach((participant) => {
      if (!availableStatuses.some(status => status.value == participant.status)) {
        switch (participant.status) {
          case StudentStatuses.NeedConsent:
            availableStatuses.push({
              displayText: intl.formatMessage({ id: "need_consent", defaultMessage: "Need Consent" }),
              value: StudentStatuses.NeedConsent
            });
            break;
          case StudentStatuses.NotEnrolled:
            availableStatuses.push({
              displayText: intl.formatMessage({ id: "not_enrolled", defaultMessage: "Not Enrolled" }),
              value: StudentStatuses.NotEnrolled
            });
            break;
          case StudentStatuses.Enrolled:
            availableStatuses.push({
              displayText: intl.formatMessage({ id: "enrolled", defaultMessage: "Enrolled" }),
              value: StudentStatuses.Enrolled
            });
            break;
          case StudentStatuses.Completed:
            availableStatuses.push({
              displayText: intl.formatMessage({ id: "completed", defaultMessage: "Completed" }),
              value: StudentStatuses.Completed
            });
            break;
        }
      }
      if (participant.createdDate) {
        const dateToAdd = typeof participant.createdDate == "string" ? new Date(participant.createdDate) : participant.createdDate;
        const dateString = getLocaleDateString(dateToAdd);
        if (!availableDates.some(date => date.displayText == dateString)) {
          availableDates.push({ value: dateToAdd, displayText: dateString! });
        }
      }
    });

    availableStatuses.sort((a, b) => (a.displayText > b.displayText ? 1 : -1));
    availableDates.sort((a, b) => a.value.getTime() - b.value.getTime());

    const newFilterConfig: FilterConfig = [
      {
        filterGroupHeading: intl.formatMessage({ id: "filters", defaultMessage: "Filters" }),
        filters: [
          {
            name: intl.formatMessage({ id: "status", defaultMessage: "Status" }),
            allPossibleValues: availableStatuses,
            displayField: "displayText",
            valueField: "value",
            filterFunction: StudentFilters.filterStudentByStatus,
            inputType: FilterInputType.Select,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "status", defaultMessage: "Status" }),
          },
          {
            name: intl.formatMessage({ id: "sign_up_date", defaultMessage: "Sign Up Date" }),
            allPossibleValues: availableDates,
            displayField: "displayText",
            valueField: "value",
            filterFunction: StudentFilters.filterParticipantByCreatedDate,
            inputType: FilterInputType.Select,
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "date", defaultMessage: "date" }),
          }
        ]
      },
    ];

    return newFilterConfig;

  };
  const [filterConfig, setFilterConfig] = useState(buildConfig());
  const inputs: ControlInput[] = [];
  const searchFields = function (students: QuickRegParticipant[]) {
    return students.map(student => {
      return {
        enrollmentStatus: student.status,
        email: student.email,
        phoneNumber: student.phoneNumber,
        givenName: student.givenName,
        surname: student.surname
      };
    });
  };

  useEffect(() => {
    setParticipants(props.participants);
  }, [props.participants]);

  useEffect(() => {
    const newConfig = buildConfig();
    setFilterConfig(newConfig);
  }, [participants, language]);

  if (layout == Layouts.Desktop) {
    inputs.push(
      {
        text: <FormattedMessage id="add_group" defaultMessage="View" />,
        action: viewSetter,
        type: ControlInputType.Select,
        options: [
          {
            value: GroupParticipantListViewNames.Table,
            label: intl.formatMessage({ id: "list_view", defaultMessage: "List View" })
          },
          {
            value: GroupParticipantListViewNames.Card,
            label: intl.formatMessage({ id: "card_view", defaultMessage: "Card View" })
          }
        ],
        value: viewValue
      }
    );
  }

  return (
    <ListControlPanel dataset={participants} isLoading={isLoading} filterConfig={filterConfig} onFilter={onFilter} inputs={inputs} searchFields={searchFields} />
  );
};

export default GroupParticipantListControls;