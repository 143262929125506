export enum GroupStatuses {
  Completed = "Completed",
  Cancelled = "Cancelled",
  InProgress = "InProgress",
  Upcoming = "Upcoming",
  Pending = "Pending",
  Concluded = "Concluded"
}

export enum GroupAgeTypes {
  Combined = "Combined",
  Youth = "Youth only",
  Adult = "Adult only"
}

export enum GroupMeetingTypes {
  InPerson = "inPerson",
  Virtual = "virtual"
}

export const MaxUnitNumbersPerFetch = 1;
export const GroupStatusesAllowedToPrint = Object.freeze([GroupStatuses.InProgress.toString(), GroupStatuses.Concluded.toString(), GroupStatuses.Completed.toString()]);
export const GroupStatusesAllowedToSignUp = Object.freeze([GroupStatuses.InProgress.toString(), GroupStatuses.Upcoming.toString()]);
export default GroupStatuses;