import { useMemo } from "react";
import "./truncated-email.scss";

const defaultMaxLength = 50;
const defaultTruncatedPartsLength = 17;

export function getDisplayEmailParts(email: string): { emailUser: string, domainWithSeperator: string } {
  if (email && typeof email == "string") {
    const seperatorIndex = email.lastIndexOf("@");
    const domainWithSeperator = seperatorIndex != -1 ? email.slice(seperatorIndex) : "";
    const emailUser = seperatorIndex != -1 ? email.slice(0, seperatorIndex) : email;
    return {
      emailUser,
      domainWithSeperator
    };
  }
  return {
    emailUser: "",
    domainWithSeperator: ""
  };
}

interface ITruncatedEmailProps {
  email: string;
  isInsideCard?: boolean;
  maxLength?: number;
  truncatedPartsLength?: number;
}

export const TruncatedEmail = (props: ITruncatedEmailProps) => {

  const emailParts = useMemo(() => {
    return getDisplayEmailParts(props.email);
  }, [props.email]);

  const maxLength = (props.maxLength == undefined) ? defaultMaxLength : props.maxLength;
  const truncatedPartsLength = (props.truncatedPartsLength == undefined) ? defaultTruncatedPartsLength : props.truncatedPartsLength;
  const emailTooLong = (props.email && typeof props.email == "string") ? props.email.length > maxLength : false;
  const emailUserShouldBeTruncated = emailTooLong && emailParts.emailUser.length > truncatedPartsLength;
  const domainShouldBeTruncated = emailTooLong && emailParts.domainWithSeperator.length > truncatedPartsLength;
  const emailUserWidth = emailUserShouldBeTruncated ? `${truncatedPartsLength}ch` : "auto";
  const domainWidth = domainShouldBeTruncated ? `${truncatedPartsLength}ch` : "auto";

  return (
    <div className="truncatedEmailContainer">
      <a target="_blank" rel="noreferrer" href={`mailto:${props.email}`}>
        <span className={props.isInsideCard ? "emailUserNameCard" : "emailUserName"} style={{ width: emailUserWidth }}>{emailParts.emailUser}</span><span className={props.isInsideCard ? "emailDomainCard" : "emailDomain"} style={{ width: domainWidth }}>{emailParts.domainWithSeperator}</span>
      </a>
    </div>
  );
};

export default TruncatedEmail;