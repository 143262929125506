import { useEffect, useRef, useState } from "react";
import intlTelInput from "intl-tel-input";
import nextId from "react-id-generator";
import "intl-tel-input/build/css/intlTelInput.css";
import "./phone-input.scss";
import { FormattedMessage } from "react-intl";

interface IPhoneInputProps {
  dialCode?: string;
  phoneNumber?: string;
  ready?: boolean;
  onChange?: (change: { dialCode: string, phoneNumber: string, valid: boolean }) => void;
  error?: boolean;
  disabled?: boolean;
}

export const PhoneInput = (props: IPhoneInputProps) => {
  const componentId = useRef<string>(nextId());
  const phoneInputId = useRef<string>(`${componentId.current}-phone`);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const disabled = props.disabled === true;
  const [phoneValidator, setPhoneValidator] = useState<any>();

  useEffect(() => {
    if (props.ready == undefined || props.ready) {
      setupPhoneValidator();
    }
  }, [props.ready]);

  useEffect(() => {
    if (props.dialCode && phoneValidator) {
      const country = phoneValidator.countries.find(country => country.dialCode == props.dialCode);
      if (country) {
        phoneValidator.setCountry(country.iso2);
      }
    }
  }, [props.dialCode]);

  useEffect(() => {
    if (phoneValidator) {
      const phoneInput = inputRef.current;
      if (phoneInput) {
        phoneInput.addEventListener("countrychange", (event) => handleChange(event));
      }
    }
  }, [phoneValidator]);

  const setupPhoneValidator = async () => {
    if (phoneValidator) {
      phoneValidator.destroy();
    }
    const phoneInput = inputRef.current;
    if (phoneInput) {
      phoneInput.addEventListener("change", handleChange);
      await (global as any).window.intlTelInputGlobals.loadUtils("https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.min.js");
      const newPhoneValidator = intlTelInput(phoneInput, {
        separateDialCode: true,
        showSelectedDialCode: true,
      });
      if (props.dialCode) {
        const country = newPhoneValidator.countries.find(country => country.dialCode == props.dialCode);
        if (country) {
          newPhoneValidator.setCountry(country.iso2);
        }
      }
      setPhoneValidator(newPhoneValidator);
    }
  };

  const validatePhone = (newPhone: string): boolean => {
    if (phoneValidator) {
      let valid = phoneValidator.isValidNumber(newPhone);
      if (!valid) {
        const validationErrorType = phoneValidator.getValidationError();
        if (validationErrorType == 0) { //Error Code: 0, Phone number failed Validation, but with an IS_POSSIBLE error, still allowing it through. SEE: https://github.com/jackocnr/intl-tel-input/blob/master/src/js/utils.js#L132
          valid = true;
        }
      }
      return !!(valid);
    }
    return false;
  };

  const handleChange = (event) => {
    if (props.onChange && phoneValidator && !disabled) {
      const change = {
        phoneNumber: event.target.value,
        dialCode: phoneValidator.getSelectedCountryData().dialCode,
        valid: validatePhone(event)
      };
      props.onChange(change);
    }
  };

  return (
    <div className={"phoneInputContainer"}>
      <label className={`phoneLabel${props?.error ? " error" : ""}`} htmlFor={phoneInputId.current}><FormattedMessage id="phone" defaultMessage="Phone" /></label>
      <input ref={inputRef} type="text" disabled={disabled} id={phoneInputId.current} className={`phoneInput${props?.error ? " error" : ""}`} value={props.phoneNumber ?? ""} onChange={handleChange} />
    </div>
  );

};

export default PhoneInput;