export interface ICourseOutcomeDto {
  id?: number;
  providerProgramId?: number;
  infoTypeId?: number;
  content: string;
}

export default class CourseOutcomeDto implements ICourseOutcomeDto {

  public id?: number;
  public providerProgramId?: number;
  public infoTypeId?: number;
  public content: string;

  constructor(creationValues: ICourseOutcomeDto) {
    this.id = creationValues.id;
    this.providerProgramId = creationValues.providerProgramId;
    this.infoTypeId = creationValues.infoTypeId;
    this.content = creationValues.content;
  }

  public export(): ICourseOutcomeDto {
    return {
      id: this.id,
      providerProgramId: this.providerProgramId,
      infoTypeId: this.infoTypeId,
      content: this.content
    };
  }

  public clone(): CourseOutcomeDto {
    return new CourseOutcomeDto(this.export());
  }
}