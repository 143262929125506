import "./location-selector.scss";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Loading from "components/loading/loading";
import LocationInputPanel from "./components/location-input-panel";
import LocationMapPanel from "./components/location-map-panel";
import { Permissions } from "constants/api-auth-constants";
import { AuthorizeService } from "auth/authorize-service";
import { useFormStatus } from "lib/form/contexts";
import { FormStatus } from "lib/form/model";
import { IUnit, Unit } from "./model";
import { SiteDto } from "lib/api/backend/model";
import { LocationType } from "lib/data/model/enum";
import useGroupCreationFormStore from "datastore/groupCreationForm";
import { LocationCreationDto } from "lib/api/backend/model/group/GroupCreationDto";
import dispatchers from "datastore/dispatcher";
import { useDispatch } from "react-redux";
import { getSitesForStakeId } from "lib/api/backend/requests/sites/site-requests";
import { ServerResponseType } from "lib/api/backend/request-utils";


interface ILocationSelectorProps {
  className?: string;
}

const LocationSelector: React.VFC<ILocationSelectorProps> = () => {
  const [formStatus, setFormStatus] = useFormStatus();

  const [isLoading, setLoading] = useState(false);
  const [authorizedUnits, setAuthorizedUnits] = useState<Unit[]>([]);
  const [authorizedSites, setAuthorizedSites] = useState<SiteDto[]>([]);
  const [selectedUnitNumber, setSelectedUnitNumber] = useState<number | null>(null);

  const reduxDispatch = useDispatch();

  const notify = dispatchers(reduxDispatch).notify;

  const store = useGroupCreationFormStore();
  const group = store.group;

  const intl = useIntl();

  useEffect(
    () => {
      const getAuthorizedUnits = () => {
        const allowedUnits: IUnit[] = AuthorizeService.getAllowedUnits();
        const units = allowedUnits.map(au => new Unit(au));
        setAuthorizedUnits(units);
        
        store.setLocation({
          locationType: LocationType.ChurchOwned,
          unitNumber: group?.stakeUnitNumber || units[0].areaUnitNumber,
        } as LocationCreationDto);
        
        //Start the form as invalid, but with no errors.
        formStatus.validity.setValidity(false, "location");

        setFormStatus(
          new FormStatus(formStatus)
        );
      };

      getAuthorizedUnits();
    }, []);

  useEffect(() => {

    const unitNumber = group?.location?.unitNumber;

    if(authorizedUnits.length > 0 && selectedUnitNumber === null) {
      const firstUnit = authorizedUnits[0];
      if(firstUnit) {
        setSelectedUnitNumber(firstUnit.areaUnitNumber);
        return;
      }
    }
    
    if(unitNumber) {
      setSelectedUnitNumber(unitNumber);
    }
  }, [group?.location?.unitNumber]);

  useEffect(() => {
    const getStakeSites = async () => {
      setLoading(true);

      const sitesResult = await getSitesForStakeId(selectedUnitNumber as number);

      if(sitesResult.responseType === ServerResponseType.Error) {
        throw new Error("Error getting sites for stake.");
      }

      const sites = sitesResult.data;
      const authorizedSites = sites.filter((loc) => AuthorizeService.userCan([Permissions.NewGroupWrite], loc.stakeUnitNumber as any));

      setAuthorizedSites(authorizedSites);
      setLoading(false);
    };

    if(selectedUnitNumber) {
      getStakeSites();
    }
  }, [selectedUnitNumber]);

  const handleLocationTypeChange = (newLocation: LocationCreationDto) => {
    const typeChange = newLocation.locationType != store.group?.location?.locationType;
    store.setLocation(newLocation);

    if (typeChange) {
      formStatus.dirtiness.setDirty(false, "location");
    } else {
      formStatus.dirtiness.setDirty(true, "location");
    }

    setFormStatus(
      new FormStatus(formStatus)
    );

    if (isLocationComplete(newLocation)) {
      store.setLocation(newLocation);
    }
  };

  const validateLocationSelection = (newLocation: LocationCreationDto) => {
    store.setLocation(newLocation);

    if(!newLocation.latitude && !newLocation.longitude) {
      notify({
        status: "error",
        message: intl.formatMessage({
          id: "group_invalid_location_coordinates",
          defaultMessage:
            "Your selected location has no valid coordinates in church records. If you want your group to be searchable, you should select a custom location instead.",
        }),
      });
    }

    formStatus.dirtiness.setDirty(true, "location");
    
    setFormStatus(
      new FormStatus(formStatus)
    );

    if (isLocationComplete(newLocation)) {
      store.setLocation(newLocation);
    }
  };

  const isLocationComplete = (location?: LocationCreationDto) => {
    let isValid = true;

    const noLocationErrorMessage = intl.formatMessage({ id: "please_select_location", defaultMessage: "Please select a location to continue." });

    if (location === undefined || !location.unitNumber || location.locationType === null || !location.country) {
      isValid = false;
      formStatus.errors.addMessage(noLocationErrorMessage, "location");
    }
    else {
      formStatus.errors.removeMessage(noLocationErrorMessage, "location");
    }

    formStatus.validity.setValidity(isValid, "location");

    setFormStatus(
      new FormStatus(formStatus)
    );

    return isValid;
  };

  return (
    <div className="locationSelectorContainer locationSelector hasLoader">
      {isLoading ?
        <Loading loading={isLoading} message={intl.formatMessage({ id: "getting_locations", defaultMessage: "Getting location information." })} />
        :
        <>
          <LocationInputPanel
            sites={authorizedSites.filter(site => site.stakeUnitNumber == group?.location?.unitNumber)}
            units={authorizedUnits}
            onLocationChange={validateLocationSelection}
            onLocationTypeChange={handleLocationTypeChange}
          />
          <LocationMapPanel
            sites={authorizedSites.filter(site => site.stakeUnitNumber == group?.location?.unitNumber)}
            onLocationChange={validateLocationSelection}
          />
        </>
      }
    </div>
  );
};

export default LocationSelector;