import "./header.scss";

import React, { useEffect, useState } from "react";
import { Checkbox, TableCell } from "@mui/material";
import useClassName from "utilities/useClassName";

const Header = (props) => {

  const [className, setPropClassName] = useClassName("cell", "header-cell");

  useEffect(
    () => {
      setPropClassName(props.className ?? "");
    },
    [props.className]
  );

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event, checked) => {
    if (checked !== isChecked) {
      setIsChecked(checked);
      if (props.onCheck) {
        props.onCheck(checked);
      }
    }
  };

  return (
    <TableCell
      className={className}
      align={props.align ?? "left"}
    >
      <Checkbox
        checked={isChecked}
        onChange={handleCheckboxChange}
      />

      {props.name}
    </TableCell>
  );
};

export default Header;