import React, { ReactNode } from "react";
import { mapStateToProps, mapDispatchToProps } from "datastore/store";
import { connect } from "react-redux";
import Header from "components/header/header";
import Alerter from "components/alerter/alerter";
import Loading from "components/loading/loading";
import { FormattedMessage } from "react-intl";
import Prompter from "components/prompter/prompter";
import "./app-frame.scss";
import { withRouter } from "utilities/withRouter";

interface IProps {
  store: any;
  showProtectedNav: boolean;
  children: ReactNode;
}

const AppFrame: React.FC<IProps> = (props: IProps) => {
  return (
    <>
      <Header showProtectedNav={props.showProtectedNav} />
      <Loading loading={props.store.isLoading} message={<FormattedMessage id="loading" defaultMessage="Loading" />} fixed={true} />
      <main className="mainBody">
        {props.children}
      </main>
      <Prompter />
      <Alerter />
    </>
  );

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppFrame));