import axios, { AxiosResponse } from "axios";
import config from "../axios.config.json";
import { validateResponse, ServerResult, ServerResponseType } from "../../request-utils";
import StakeMemberDto, { IStakeMemberDtoCreationValues } from "../../model/StakeMemberDto";
import { ApiUrl } from "constants/api-auth-constants";

export const get = async (unitNumber: number) => {
  const response = await membersGetRequest(unitNumber);

  if (response.responseType === ServerResponseType.Success) {
    return response;
  }

  //Perform a retry, return the result regardless.
  const retryResponse = await membersGetRequest(unitNumber);

  return retryResponse;
};

const membersGetRequest = async (unitNumber: number) => {
  try {
    const response: AxiosResponse<IStakeMemberDtoCreationValues[]> = await axios.get(
      `${ApiUrl}stake/${unitNumber}/members`,
      { ...config }
    );

    const responseType = validateResponse(response.status);

    if (responseType === ServerResponseType.Success) {
      const stakeMemberList = response.data.map(i => new StakeMemberDto(i));

      const result: ServerResult<StakeMemberDto[]> = {
        responseType: responseType,
        data: stakeMemberList
      };

      return result;
    }
  }
  catch (e: any) {
    if (axios.isAxiosError(e)) {

      const responseType: ServerResponseType = validateResponse(parseInt(e.status!));

      const result: ServerResult<StakeMemberDto[]> = {
        responseType: responseType,
        data: []
      };

      return result;
    }
  }

  //If we get here, it's definitely an error.
  return {
    responseType: ServerResponseType.Error,
    data: []
  } as ServerResult<StakeMemberDto[]>;
};
