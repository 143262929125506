import API from "api/requests";
import { getStateFromUrl } from "utilities/navUtils";
import {
  AllowedEmployeeStatusCodes,
  EndPoints,
  Permissions,
  ApplicationPaths,
  AuthBroadcastChannelName,
  AuthBroadCastMessages,
  SignOutReason,
  ImpersonationCookieName,
} from "constants/api-auth-constants";
import prompt from "utilities/prompt";
import { getTranslation } from "utilities/language";
import Cookies from "utilities/cookies";
import { User, AuthStatus, AuthMessage, AuthResult } from "./models";
const apiPrefix = process.env.REACT_APP_API_URL;
export class AuthorizeService {

  private static user: User | null = null;
  private static authStatus: AuthStatus | null = null;
  private static sessionTimeoutId: number | null = null;
  private static willExpireRedirectTimeoutId: number | null = null;
  private static sessionTimeout = 1380000; //23 minutes (default/temp value), changes to session length provided by the backend in getAuthStatus().
  private static expiredSessionRedirectDelay = 7000; //7 seconds, when an expired session is detected the determines how long the warning popup will show before redirecting to sign
  private static timeoutWarningBefore = 180000; // 3 mins, amount of time before session expires that we show the "session will expire prompt"
  private static warningDisplayTime = 120000; //2 minutes, the amount of time to display the warning before auto logging out. This should be less then timeoutWarningBefore
  private static channelContextId = Math.random();
  private static showingWillExpirePrompt = false;
  private static promptStayButtonId = `${AuthorizeService.channelContextId}-${Date.now()}-logout-button`;
  private static channel = ((): BroadcastChannel | null => {
    if (typeof BroadcastChannel != "undefined") {
      const newChannel = new BroadcastChannel(AuthBroadcastChannelName);
      newChannel.addEventListener("message", AuthorizeService.handleAuthBroadcast);
      return newChannel;
    }
    return null;
  })();
  public static authorizedUnits: { unitName: string, unitNumber: number }[] | null;

  static async getAuthStatus(): Promise<false | AuthStatus> {
    try {
      if (AuthorizeService.willExpireRedirectTimeoutId) {
        clearTimeout(AuthorizeService.willExpireRedirectTimeoutId);
      }
      const status = new AuthStatus(await API.checkAuthenticated());
      AuthorizeService.authStatus = status;
      if (AuthorizeService.authStatus.authenticated) {
        AuthorizeService.sessionTimeout = status.sessionTimeoutLengthMs;
        AuthorizeService.resetSessionTimer(); //Cancel any exsting auth check and reset with the new timeout value;
      }
      return status;
    } catch (error) {
      return false;
    }
  }

  static resetSessionTimer(): void {
    if (AuthorizeService.sessionTimeoutId) {
      clearTimeout(AuthorizeService.sessionTimeoutId);
    }
    if (AuthorizeService.showingWillExpirePrompt) {
      const stayButton = document.getElementById(AuthorizeService.promptStayButtonId);
      if (stayButton) {
        stayButton.click();
      }
    }
    const promptDisplayTime = AuthorizeService.sessionTimeout - AuthorizeService.timeoutWarningBefore;
    AuthorizeService.sessionTimeoutId = window.setTimeout(() => {
      AuthorizeService.sendSignOutImminentMessage();
      AuthorizeService.showWillExpirePrompt();
    }, promptDisplayTime);
  }

  static setUser(user: User): User {
    AuthorizeService.getAuthStatus();
    AuthorizeService.user = user;
    AuthorizeService.sendSignInMessage();
    AuthorizeService.resetSessionTimer();
    if (Cookies.cookieExists(ImpersonationCookieName)) {
      AuthorizeService.broadcastMessage(AuthBroadCastMessages.ImpersonationStart, user.employeeId);
    }
    AuthorizeService.authorizedUnits = (() => {
      const unitNumbers: { unitName: string, unitNumber: number }[] = [];
      user.roles.forEach(role => {
        if (!unitNumbers.some(unit => unit.unitNumber == role.areaUnitNumber)) {
          unitNumbers.push({
            unitName: role.areaUnitName,
            unitNumber: role.areaUnitNumber
          });
        }
      });
      return unitNumbers;
    })();
    return user;
  }

  static async getUser(): Promise<User | null> {
    if (AuthorizeService.user) {
      return AuthorizeService.user;
    }
    try {
      const user = new User(await API.getUser());
      AuthorizeService.setUser(user);
      return user;
    } catch (error) {
      return null;
    }
  }

  static async completeSignIn(url: string) {
    try {
      const authStatus = await AuthorizeService.getAuthStatus();
      if (authStatus && authStatus.authenticated) {
        await AuthorizeService.getUser();     // sets user in store
      }
      return AuthorizeService.success({ returnUrl: getStateFromUrl(url) });
    } catch (error) {
      return AuthorizeService.error("There was an error signing in.");
    }
  }

  static async checkRawRequest(method: string, url: string): Promise<boolean> {
    const keys = Object.keys(EndPoints);
    const matchingKey = keys.find((key) => {
      return (url.startsWith(API.makeURL(EndPoints[key].path)) &&
        EndPoints[key].method.toLowerCase() == method.toLowerCase());
    });

    if (!matchingKey) {
      return true; //endpoint doesn't require authorization
    }
    return AuthorizeService.authorizeEndpoint(EndPoints[matchingKey]);
  }

  static async authorizeEndpoint(endpoint): Promise<boolean> {
    try {
      let myAuthStatus = AuthorizeService.authStatus;
      if (myAuthStatus == null) {
        const authResult = await AuthorizeService.getAuthStatus();
        if (authResult) {
          myAuthStatus = new AuthStatus(authResult);
        }
      }
      if (!myAuthStatus?.authenticated) {
        AuthorizeService.showExpiredPrompt();
        return false;
      }
      AuthorizeService.resetSessionTimer();
      return AuthorizeService.userCan(endpoint.requiredPermissions);
    } catch (error) {
      AuthorizeService.signOut();
      return false;
    }

  }

  static userCan(permissionsRequired: any[], areaUnitNumber: number | null = null) {

    if (!permissionsRequired || permissionsRequired.length < 1) {
      return true;
    }

    if (!AuthorizeService.user) {
      return false;
    }

    if (!AuthorizeService.userAllowed()) {
      return false;
    }

    let testPermissions = AuthorizeService.user.permissions;

    if (areaUnitNumber != null) {
      const unitRoles = AuthorizeService.user.roles.filter(role => role.areaUnitNumber == areaUnitNumber);
      if (!unitRoles) {
        return false;
      } else {
        testPermissions = [];
        unitRoles.forEach(role => testPermissions = [...testPermissions, ...role.permissions]);
      }
    }

    try {
      return permissionsRequired.some(requiredPermission => {
        if (Array.isArray(requiredPermission)) {
          const hasAtLeastOne = requiredPermission.some((atLeastPermission) => {
            const nameMatches = testPermissions.filter(userperm => userperm.permissionName == atLeastPermission.name);
            if (nameMatches.some(match => match.isActive && !(match.isRead && atLeastPermission.write))) {
              return true;
            }
            return false;
          });
          return hasAtLeastOne;
        } else {
          const nameMatches = testPermissions.filter(userperm => userperm.permissionName == requiredPermission.name);
          if (nameMatches.some(match => match.isActive && !(match.isRead && requiredPermission.write))) {
            return true;
          } else {
            return false;
          }
        }
      });
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  static userAllowed(): boolean {
    if (AuthorizeService.user?.employeeStatusCode && AuthorizeService.user?.permissions) {
      const allPermissionNames = Object.keys(Permissions).map(key => Permissions[key].name);
      const hasAnyPermission = AuthorizeService.user.permissions.some(permission => allPermissionNames.includes(permission.permissionName));
      return AllowedEmployeeStatusCodes.includes(AuthorizeService.user.employeeStatusCode) || hasAnyPermission;
    } else {
      return false;
    }
  }

  //Returns the units for which a user is allowed to create groups;
  static getAllowedUnits() {
    if (AuthorizeService.user) {
      const rolesWithCreatePerms = AuthorizeService.user.roles.filter(role => {
        return AuthorizeService.userCan([Permissions.NewGroupWrite], role.areaUnitNumber);
      });
      const uniqueUnits: any[] = [];
      rolesWithCreatePerms.forEach(role => {
        if (role.areaUnitNumber && !uniqueUnits.some((testRole: any) => testRole.areaUnitNumber == role.areaUnitNumber)) {
          uniqueUnits.push({
            siteId: role.siteId,
            areaUnitNumber: role.areaUnitNumber,
            areaUnitName: role.areaUnitName
          });
        }
      });
      return uniqueUnits;
    }
    return [];
  }

  static sendSignInMessage() {
    AuthorizeService.broadcastMessage(AuthBroadCastMessages.SignedIn, AuthorizeService.user?.employeeId);
  }

  static sendStayMessage() {
    AuthorizeService.broadcastMessage(AuthBroadCastMessages.StaySignedIn);
  }

  static sendSignOutImminentMessage() {
    AuthorizeService.broadcastMessage(AuthBroadCastMessages.SignOutImminent);
  }

  static sendSignOutMessage(reason?: SignOutReason) {
    AuthorizeService.broadcastMessage(AuthBroadCastMessages.SignOut, reason);
  }

  static sendImpersonationStart() {
    AuthorizeService.broadcastMessage(AuthBroadCastMessages.ImpersonationStart, AuthorizeService.user?.employeeId);
  }

  static sendImpersonationEnd() {
    AuthorizeService.broadcastMessage(AuthBroadCastMessages.ImpersonationEnd);
  }

  static broadcastMessage(message: string, payload: any = null): void {
    if (AuthorizeService.channel) {
      AuthorizeService.channel.postMessage({
        contextId: AuthorizeService.channelContextId,
        message: message,
        payload
      });
    }
  }

  private static handleAuthBroadcast(event: MessageEvent): void {
    if (event.data.contextId != AuthorizeService.channelContextId && event.data?.message) {//don't react to own broadcasts or empty broadcasts
      switch (event.data.message) {
        case AuthBroadCastMessages.SignedIn:
          if (event.data.payload && event.data.payload != AuthorizeService.user?.employeeId) {
            location.reload();
          } else {
            AuthorizeService.resetSessionTimer();
          }
          break;
        case AuthBroadCastMessages.SignOutImminent:
          if (AuthorizeService.willExpireRedirectTimeoutId && !AuthorizeService.showingWillExpirePrompt) {
            clearTimeout(AuthorizeService.willExpireRedirectTimeoutId);
          }
          AuthorizeService.showWillExpirePrompt();
          break;
        case AuthBroadCastMessages.StaySignedIn:
          if (AuthorizeService.showingWillExpirePrompt) {
            const stayButton = document.getElementById(AuthorizeService.promptStayButtonId);
            if (stayButton) {
              stayButton.click();
            }
          }
          break;
        case AuthBroadCastMessages.SignOut:
          if (event.data.payload == SignOutReason.SignUpSessionActive) {
            window.alert(getTranslation("admin_unavailable_during_sign_up", "Access to QuickReg admin is unavailable while a sign up session is in open in the same browser."));
          }
          AuthorizeService.signOut(false);
          break;
        case AuthBroadCastMessages.ImpersonationStart:
          if (event.data.payload && event.data.payload != AuthorizeService.user?.employeeId) {
            //Not impersonating the same user
            location.reload();
          }
          break;
        case AuthBroadCastMessages.ImpersonationEnd:
          AuthorizeService.endImpersonation(false);
          break;
      }
    }
  }

  static error(message: string): AuthMessage {
    return new AuthMessage({ status: AuthResult.Fail, message });
  }

  static success(state): AuthMessage {
    return new AuthMessage({ status: AuthResult.Success, state });
  }

  static showWillExpirePrompt(): void {
    if (!AuthorizeService.showingWillExpirePrompt) {
      AuthorizeService.showingWillExpirePrompt = true;
      prompt({
        status: "warning",
        message: <>
          <p>{getTranslation("session_will_expire_warning", "Your login session will expire shortly and you will be redirected to login.")}</p>
        </>,
        buttons:
          <>
            {/* <Timer countfromMs={AuthorizeService.warningDisplayTime} /> Kept for future troubleshooting if needed*/}
            <button className="primary" id={AuthorizeService.promptStayButtonId} onClick={() => {
              AuthorizeService.showingWillExpirePrompt = false;
              AuthorizeService.getAuthStatus();
              AuthorizeService.sendStayMessage();
            }}>{getTranslation("stay_signed_in", "Stay Signed In")}</button>
            <button className="primary" onClick={() => AuthorizeService.signOut()}> {getTranslation("redirect_now", "Redirect Now")}</button>
          </>
      });
      AuthorizeService.willExpireRedirectTimeoutId = window.setTimeout(() => {
        AuthorizeService.signOut();
      }, AuthorizeService.warningDisplayTime);
    }
  }

  static showExpiredPrompt(): void {
    prompt({
      status: "warning",
      message: getTranslation("session_expired_warning", "Your login session has expired and you will be redirected to login."),
      buttons: <button className="primary" onClick={() => AuthorizeService.signOut()}>{getTranslation("redirect_now", "Redirect Now")}</button>
    });
    window.setTimeout(() => {
      AuthorizeService.signOut();
    }, AuthorizeService.expiredSessionRedirectDelay);
  }

  static endImpersonation(broadcast = true) {
    if (broadcast) {
      AuthorizeService.sendImpersonationEnd();
    }
    window.location.assign("/?impersonate=false");
  }

  static signOut(broadcastMessage = true) {
    if (broadcastMessage) {
      AuthorizeService.sendSignOutMessage();
    }
    if (Cookies.cookieExists(ImpersonationCookieName)) {
      Cookies.deleteCookie(ImpersonationCookieName);
      window.location.replace(`${apiPrefix}${ApplicationPaths.SignOutPath}?returnUrl=${encodeURI(`${apiPrefix}${ApplicationPaths.SignOutPath}`)}`);
    } else {
      window.location.replace(`${apiPrefix}${ApplicationPaths.SignOutPath}?returnUrl=${encodeURI(window.location.href)}`);
    }
  }
}

export default AuthorizeService;

