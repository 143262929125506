import axios, { AxiosResponse } from "axios";
import config from "../axios.config.json";
import { ProviderProgramConsentFormUrlDto } from "lib/api/backend/model/provider";
import { ApiUrl } from "constants/api-auth-constants";

const ProviderControllerPath = `${ApiUrl}providers/`;

export const getProviderProgramConsentFormUrls = async (providerId: number, providerProgramId: number): Promise<ProviderProgramConsentFormUrlDto[]> => {

  const response: AxiosResponse<ProviderProgramConsentFormUrlDto[]> = await axios.get(
    `${ProviderControllerPath}consent-urls/provider/${providerId}/provider-program/${providerProgramId}`,
    { ...config }
  );

  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }

};
