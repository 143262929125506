import React, { ReactElement } from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import { ControlInputType, ControlInput } from "../models";
import "./input-panel.scss";


interface IInputPanel {
  isLoading: boolean;
  inputs: ControlInput[];
  className?: string;
}

export const InputPanel = (props: IInputPanel): ReactElement => {

  const { isLoading, inputs, className } = props;

  return (
    <div className={`inputPanelContainer ${className ? className : ""}`}>
      {!isLoading &&
        <>
          {inputs.map((input, index) => {
            return (
              <React.Fragment key={index}>
                {(() => {
                  switch (input.type) {
                    case ControlInputType.Select:
                      return (
                        <FormControl sx={{ m: 1, minWidth: 120 }} variant="standard">
                          <Select
                            disableUnderline
                            value={input.value}
                            onChange={(event) => input.action(event.target.value)}
                            autoWidth
                          >
                            {input.options && input.options.map((option, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={(option?.value) ? option.value : option}
                                >
                                  {option.startIcon &&
                                    <option.startIcon />
                                  }
                                  {(option?.label) ? option.label : option}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      );
                    case ControlInputType.Button:
                      return (
                        <>
                          {input.startIcon ?
                            <Button variant={input.muiVariant} startIcon={input.startIcon}>{input.text}</Button>
                            :
                            <Button variant={input.muiVariant} onClick={input.action}>{input.text}</Button>
                          }
                        </>
                      );
                    case ControlInputType.IconButton:
                      return (
                        <IconButton className={`${input.active ? "active" : ""}`} onClick={input.action}>
                          <input.icon />
                        </IconButton>
                      );
                  }
                })()}
              </React.Fragment>
            );
          })}
        </>
      }
    </div>
  );

};

export default InputPanel;