import { ReactElement } from "react";
import { Route, Routes as Switch } from "react-router-dom";
import AuthService from "auth/authorize-service";
import ProtectedRoute from "auth/protected-route";
import UnprotectedRoute from "auth/unprotected-route";
import LoginCallback from "auth/LoginCallback";
import ScrollFix from "components/scroll-fix/scroll-fix";
import { ApplicationPaths, Permissions } from "constants/api-auth-constants";
import Error404 from "error-handlers/error-404/error-404";
import SignInError from "error-handlers/sign-in-error/sign-in-error";
import Unauthorized from "error-handlers/unauthorized/unauthorized";
import ChurchAccountSignup from "pages/course-signup/church-account/church-account";
import CourseCatalog from "pages/course-catalog/course-catalog";
import CourseSignup from "pages/course-signup/course-signup";
import EmailPhoneCourseSignup from "pages/course-signup/email-phone/email-phone";
import ManageGroupStudents from "pages/manage-group-participants/manage-group-participants";
import EditParticipant from "pages/edit-participant/edit-participant";
import GroupDetailsPage from "pages/group-details/group-details";
import Participants from "pages/participants/participants";
import AddGroup from "pages/add-group";
import CopyGroup from "pages/copy-group";
import Groups from "pages/groups";
import JoinNow from "pages/join-now";
import ManageGroupDetails from "pages/manage-group-details";
import SignOut from "pages/sign-out/sign-out";
import { withRouter } from "utilities/withRouter";

export const AppBaseUrl = `${location.protocol}//${location.hostname}${location.port ? `:${location.port}` : ""}`;
export const JoinNowBaseUrl = "/join-now";
export const CourseSignUpBaseUrl = "/group-signup";
export const GroupDetailsUrl = "/group-details/:groupId";
export const SignOutPageUrl = "/sign-out";

const Routes = (props): ReactElement => {
  const homePage: ReactElement = (() => {
    if (props?.store?.user?.signOnError === true) {
      return <SignInError />;
    } else if (AuthService.userCan([Permissions.GroupIndexRead])) {
      return <Groups />;
    } else if (AuthService.userCan([Permissions.StudentsRead])) {
      return <Participants />;
    } else if (AuthService.userCan([Permissions.CourseCatalogRead])) {
      return <CourseCatalog />;
    } else {
      return <Unauthorized />;
    }
  })();

  return (
    <Switch>
      <Route
        path={ApplicationPaths.SignInCallback}
        element={<LoginCallback />}
      />
      <Route element={<ScrollFix />}>
        <Route element={<UnprotectedRoute />}>
          {/*KEPT HERE FOR LEGACY REASONS, REMOVE 6 MONTHS FROM TODAY (10/06/2023)*/}
          <Route
            path={`${CourseSignUpBaseUrl}/email-phone/:providerId/:locationId/:programId/:groupId/:trackId/:termId`}
            element={<EmailPhoneCourseSignup />}
          />
          {/*KEPT HERE FOR LEGACY REASONS, REMOVE 6 MONTHS FROM TODAY (10/06/2023)*/}
          <Route
            path={`${CourseSignUpBaseUrl}/:providerId/:locationId/:programId/:groupId/:trackId/:termId`}
            element={<CourseSignup />}
          />
          {/*NEW ROUTES FOR SIGNUP */}
          <Route
            path={`${CourseSignUpBaseUrl}/email-phone/:groupId`}
            element={<EmailPhoneCourseSignup />}
          />
          <Route
            path={`${CourseSignUpBaseUrl}/:groupId`}
            element={<CourseSignup />}
          />
          {/*NEW ROUTES FOR SIGNUP END */}
          <Route path={`${CourseSignUpBaseUrl}`} element={<CourseSignup />} />
          <Route path={JoinNowBaseUrl} element={<JoinNow />} />
          <Route path={GroupDetailsUrl} element={<GroupDetailsPage />} />
          <Route path={SignOutPageUrl} element={<SignOut />} />
          {/*KEPT HERE FOR LEGACY REASONS, REMOVE 6 MONTHS FROM TODAY (10/06/2023)*/}
          <Route
            path={"group-details/:locationId/:programId/:groupId"}
            element={<GroupDetailsPage />}
          />
        </Route>
        <Route element={<ProtectedRoute showProtectedNav={false} />}>
          {/*NEW ROUTE FOR SIGNUP */}
          <Route
            path={`${CourseSignUpBaseUrl}/church-account/:groupId`}
            element={<ChurchAccountSignup />}
          />
          {/*KEPT HERE FOR LEGACY REASONS, REMOVE 6 MONTHS FROM TODAY (10/06/2023)*/}
          <Route
            path={`${CourseSignUpBaseUrl}/church-account/:providerId/:locationId/:programId/:groupId/:trackId/:termId`}
            element={<ChurchAccountSignup />}
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={homePage} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/groups/add" element={<AddGroup />} />
          <Route path="/groups/:groupId" element={<ManageGroupStudents />} />
          {/*KEPT HERE FOR LEGACY REASONS, REMOVE 6 MONTHS FROM TODAY (10/06/2023)*/}
          <Route
            path="/groups/:locationId/:programId/:groupId/"
            element={<ManageGroupStudents />}
          />
          <Route
            path="/groups/:locationId/:programId/:groupId/details"
            element={<ManageGroupDetails />}
          />
          <Route
            path="/groups/:locationId/:programId/:groupId/copy"
            element={<CopyGroup />}
          />
          <Route path="/group-types" element={<CourseCatalog />} />
          <Route path="/participants" element={<Participants />} />
          <Route
            path="/participant/:studentId/group/:groupId"
            element={<EditParticipant />}
          />
        </Route>
        <Route element={<UnprotectedRoute />}>
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/sign-in-error" element={<SignInError />} />
          <Route element={<Error404 />} />
        </Route>
      </Route>
    </Switch>
  );
};

export default withRouter(Routes);
