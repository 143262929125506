export interface IUnit {
  siteId: number;
  areaUnitNumber: number;
  areaUnitName: string;
}

export default class Unit implements IUnit {
  public siteId: number;
  public areaUnitNumber: number;
  public areaUnitName: string;

  constructor(unit: IUnit) {
    this.siteId = unit.siteId;
    this.areaUnitNumber = unit.areaUnitNumber;
    this.areaUnitName = unit.areaUnitName;
  }
}