import React from "react";
import Chip from "@mui/material/Chip";
import "./badge.scss";

interface IBadge {
  pill?: boolean,
  type?: string,
  className?: string,
  children?: React.ReactNode;
}

const Badge = (props: IBadge) => {
  return (
    <div className={`badgeContainer ${props?.className ? props.className : ""}`}>
      <Chip size="small" className={`badge ${props.pill ? "pill" : ""} ${props.type}`} label={props.children} />
    </div>
  );
};

export default Badge;
