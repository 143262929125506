
import { createStore } from "zustand";
import { createContext } from "react";
import { FilterConfig } from "../models";
import { cloneDeep } from "lodash";

export type ListControlPanelType = {

  isFilterModalOpen: boolean;
  setIsFilterModalOpen: (open: boolean) => void;

  filterConfig?: FilterConfig;
  setFilterConfig: (config: FilterConfig) => void;

  userControlFilterConfig?: FilterConfig;
  setUserControlFilterConfig: (config: FilterConfig) => void;

  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;

};

export const createListControlPanelStore = () => {

  const newStore = createStore<ListControlPanelType>()(
    (set): ListControlPanelType => {
      return {

        isFilterModalOpen: false,
        setIsFilterModalOpen: (open: boolean) => set(() => ({ isFilterModalOpen: open })),

        filterConfig: undefined,
        setFilterConfig: (config: FilterConfig) => set(() => {
          const newUserControlConfig = Object.freeze(cloneDeep(config)) as FilterConfig;
          return {
            userControlFilterConfig: newUserControlConfig,
            filterConfig: config
          };
        }),

        userControlFilterConfig: undefined,
        setUserControlFilterConfig: (config: FilterConfig) => set(() => ({ userControlFilterConfig: config })),

        isLoading: true,
        setIsLoading: (loading: boolean) => set(() => ({ isLoading: loading })),
      };
    }
  );
  return newStore;
};

export type ListControlPanelStore = ReturnType<typeof createListControlPanelStore>;

export const ListControlPanelContext = createContext<ListControlPanelStore | null>(null);

export const ListControlPanelProvider = ListControlPanelContext.Provider;

export default createListControlPanelStore;