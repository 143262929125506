import axios, { AxiosResponse } from "axios";
import config from "../axios.config.json";
import { ApiUrl } from "constants/api-auth-constants";
import { validateResponse, ServerResult, ServerResponseType, post, get } from "../../request-utils";
import { PaginatedResponse, FullGroup, GroupDetails, LeadersGroupReponse } from "lib/models-v2";
import { MapBounds } from "lib/data/model";
import { customParamSerializer } from "../custom-serializer";
import { CourseOutcomeCreationUpdateDto } from "../../model/outcomes";
import { GroupCreationDto } from "../../model/group/GroupCreationDto";

const groupControllerPath = `${ApiUrl}groups-v2/`;

export const signupForGroup = async (groupId: number) => {
  try {
    const response: AxiosResponse<GroupDetails> = await axios.get(
      `${groupControllerPath}group-details/${groupId}`,
      { ...config }
    );

    const responseType = validateResponse(response.status);

    const result: ServerResult<boolean> = {
      responseType: responseType,
      data: true
    };

    return result;
  }
  catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }

    return {
      responseType: ServerResponseType.Error,
      data: null
    } as ServerResult<boolean | null>;
  }
};

export const getGroupDetails = async (groupId: number) => {
  try {
    const response: AxiosResponse<GroupDetails> = await axios.get(
      `${groupControllerPath}group-details/${groupId}`,
      { ...config }
    );

    const responseType = validateResponse(response.status);

    const result: ServerResult<GroupDetails> = {
      responseType: responseType,
      data: response.data
    };

    return result;
  }
  catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }
  }
};

export const getFullGroup = async (groupId: number) => {
  try {
    const response: AxiosResponse<FullGroup> = await axios.get(
      `${groupControllerPath}full-group/${groupId}`,
      { ...config }
    );

    const responseType = validateResponse(response.status);

    const result: ServerResult<FullGroup> = {
      responseType: responseType,
      data: response.data
    };

    return result;
  }
  catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }
  }
};

export const getUserGroupsV2 = async (unitNumbers?: number[]) => {
  try {
    let path = `${groupControllerPath}user-groups`;

    if (unitNumbers && unitNumbers.length > 0) {
      unitNumbers.forEach((unitNumber, index) => {
        if (index === 0) {
          path += `?unitNumbers=${unitNumber}`;
        } else {
          path += `&unitNumbers=${unitNumber}`;
        }
      });
    }

    const response: AxiosResponse<LeadersGroupReponse> = await axios.get(
      path,
      { ...config }
    );

    const responseType = validateResponse(response.status);

    const result: ServerResult<LeadersGroupReponse> = {
      responseType: responseType,
      data: response.data
    };

    return result;
  }
  catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }
  }
};

export const getAreaGroups = async (
  mapBounds: MapBounds,
  abortSignal: AbortSignal,
  excludeCompletedCancelled = true,
  excludeFull = true,
  limitToParentPrograms?: number[],
  limitToProviderIds?: number[],
  limitToProgramIds?: number[],
  pageSize = 100,
  page = 1,
) => {

  try {

    const params: any = {
      PageNumber: page,
      PageSize: pageSize,
      northLatitude: mapBounds.neLat,
      eastLongitude: mapBounds.neLng,
      southLatitude: mapBounds.swLat,
      westLongitude: mapBounds.swLng,
      excludeCompletedCancelled,
      excludeFull,
    };

    if (limitToParentPrograms && limitToParentPrograms.length) {
      params.parentProgramIds = limitToParentPrograms;
    }

    if (limitToProviderIds && limitToProviderIds.length) {
      params.providerIds = limitToProviderIds;
    }

    if (limitToProgramIds && limitToProgramIds.length) {
      params.courseIds = limitToProgramIds;
    }

    const response: AxiosResponse<PaginatedResponse<GroupDetails[]>> = await axios.get(
      `${groupControllerPath}area-groups`,
      {
        ...config,
        signal: abortSignal,
        params,
        paramsSerializer: customParamSerializer //using custom serializer so arrays don't get sent with [] in every query param
      },

    );

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      throw new Error(`${response.status} - ${response.statusText}`);
    }
  }
  catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }
  }
};

export const getNearestGroups = async (
  latitude: number,
  longitude: number,
  abortSignal: AbortSignal,
  excludeCompletedCancelled = true,
  excludeFull = true,
  limitToParentPrograms?: number[],
  limitToProviderIds?: number[],
  limitToProgramIds?: number[],
  pageSize = 100,
  page = 1,
) => {

  try {

    const params: any = {
      PageNumber: page,
      PageSize: pageSize,
      latitude,
      longitude,
      excludeCompletedCancelled,
      excludeFull,
    };

    if (limitToParentPrograms && limitToParentPrograms.length) {
      params.parentProgramIds = limitToParentPrograms;
    }

    if (limitToProviderIds && limitToProviderIds.length) {
      params.providerIds = limitToProviderIds;
    }

    if (limitToProgramIds && limitToProgramIds.length) {
      params.courseIds = limitToProgramIds;
    }

    const response: AxiosResponse<PaginatedResponse<GroupDetails[]>> = await axios.get(
      `${groupControllerPath}nearest-groups`,
      {
        ...config,
        signal: abortSignal,
        params,
        paramsSerializer: customParamSerializer
      },

    );

    if (response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      throw new Error(`${response.status} - ${response.statusText}`);
    }
  }
  catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }
  }
};

export const createGroup = async (group: GroupCreationDto): Promise<FullGroup> => {
  const response: AxiosResponse<FullGroup> = await axios.post(
    `${groupControllerPath}group`,
    group,
    { ...config }
  );

  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    throw new Error(`${response.status} - ${response.statusText}`);
  }
};

export const saveParticipantOutcomes = async (groupId: number, outcomes: CourseOutcomeCreationUpdateDto[]): Promise<boolean> => {
  return await post<boolean>(`${groupControllerPath}wsrs-outcome-surveys/${groupId}`, outcomes);
};

export const getParticipantEnrollmentExists = async (groupId: number): Promise<boolean> => {
  return await get<boolean>(`${groupControllerPath}participant-enrollment-exists/${groupId}`);
};