export interface IAddressDto {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
}

export default class AddressDto implements IAddressDto {
  public address1: string;
  public address2?: string;
  public city: string;
  public state: string;
  public country: string;
  public postalCode: string;

  constructor(creationValues: IAddressDto) {
    this.address1 = creationValues.address1;
    this.address2 = creationValues.address2;
    this.city = creationValues.city;
    this.state = creationValues.state;
    this.country = creationValues.country;
    this.postalCode = creationValues.postalCode;
  }

  public export(): IAddressDto {
    return {
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      country: this.country,
      postalCode: this.postalCode,
      state: this.state
    };
  }

  public clone(): AddressDto {
    return new AddressDto(this.export());
  }
}