import { ReactElement } from "react";
import { StudentDto } from "lib/api/backend/model";
import "./truncated-student-name.scss";
import { StudentEnrollment, StudentEnrollmentV2 } from "lib/data/model";
import { QuickRegParticipant } from "lib/models-v2";

const defaultMaxLength = 96;
const defaultTruncatedPartsLength = 17;

export interface ITruncatedStudentNameProps {
  student: StudentDto | StudentEnrollment | StudentEnrollmentV2 | QuickRegParticipant;
  maxLength?: number;
  truncatedPartsLength?: number;
}

export const TruncatedStudentName = (props: ITruncatedStudentNameProps): ReactElement => {

  const firstName = props.student instanceof StudentDto || props.student instanceof StudentEnrollmentV2 || props.student instanceof StudentEnrollment ? props.student?.firstName : props.student.givenName;
  const lastName = props.student instanceof StudentDto || props.student instanceof StudentEnrollmentV2 || props.student instanceof StudentEnrollment ? props.student?.lastName : props.student.surname;

  const maxLength = (props.maxLength == undefined) ? defaultMaxLength : props.maxLength;
  const truncatedPartsLength = (props.truncatedPartsLength == undefined) ? defaultTruncatedPartsLength : props.truncatedPartsLength;
  const fullName = `${lastName}, ${firstName}`;
  const fullNameTooLong = fullName.length > maxLength;
  const lastNameShouldBeTruncated = fullNameTooLong && lastName.length > truncatedPartsLength;
  const firstNameShouldBeTruncated = fullNameTooLong && firstName.length > truncatedPartsLength;
  const lastNameWidth = lastNameShouldBeTruncated ? `${truncatedPartsLength}ch` : "auto";
  const firstNameWidth = firstNameShouldBeTruncated ? `${truncatedPartsLength}ch` : "auto";

  return (
    <span className="truncatedStudentNameContainer" title={fullName}>
      <span className="namePart" style={{ width: lastNameWidth }}>{lastName},&nbsp;</span>
      <span dir="rtl" className="namePart" style={{ width: firstNameWidth }}>{firstName}</span>
    </span>
  );
};

export default TruncatedStudentName;