import { ReactElement } from "react";
import { LocationGroup } from "utilities/geolocation";
import { useIntl, FormattedMessage } from "react-intl";
import "./mobile-location-group-list.scss";
import Badge from "components/badge/badge";
import { getHHMM, getLocaleDateString, getWeekdayName } from "utilities/time";
import { setCourseSignupSession } from "utilities/navUtils";
import { useNavigate } from "react-router";
import { FullGroup } from "lib/models-v2";

type ProgramGroup = {
  programId: number;
  programName: string;
  groups: FullGroup[];
}

interface IMobileLocationGroupList {
  location: LocationGroup;
}

export const MobileLocationGroupList = (props: IMobileLocationGroupList): ReactElement => {

  const { location: { groups } } = props;
  const groupsSortedByProgram: ProgramGroup[] = [];
  const intl = useIntl();
  const navigate = useNavigate();

  groups.forEach(group => {
    const existingProgramGroup = groupsSortedByProgram.find(programGroup => programGroup.programId == group.programId);
    if (existingProgramGroup) {
      existingProgramGroup.groups.push(group);
    } else {
      groupsSortedByProgram.push({
        programId: group.programId!,
        programName: group.courseName,
        groups: [group]
      });
    }
  });

  const goToGroupDetails = (group: FullGroup) => {
    setCourseSignupSession(
      group.providerId,
      group,
      `${window.location.origin}${window.location.pathname}`,
      `${window.location.origin}${window.location.pathname}`
    );
    navigate(`/group-details/${group.locationId}/${group.programId}/${group.id}`);
  };

  return (
    <div className="mobileLocationGroupListContainer">
      <address className="locationAddress">
        {groups[0].address1}{groups[0].address2 && `, ${groups[0].address2}`}{", " + groups[0].city}{groups[0].state ? ", " + groups[0].state : ", " + groups[0].country}
      </address>
      {groupsSortedByProgram && groupsSortedByProgram.map((programGroup, index) => {
        return (
          <div className="program" key={index}>
            <div className="programName">{programGroup.programName}:</div>
            {programGroup.groups.map(group =>
              <div className="group" key={group.id}>
                <div className="groupRow">
                  <div className="groupColumn">
                    <div className="badges">
                      {group.isPending && intl.formatMessage({ id: "pending", defaultMessage: "Pending" })}
                      {group.isVirtual && <Badge type="virtual">{intl.formatMessage({ id: "virtual", defaultMessage: "Virtual" })}</Badge>}
                    </div>
                    {group.startDate && (
                      <div className="data">
                        <time dateTime={group.startDate}>
                          {getLocaleDateString(group.startDate)}
                        </time>
                        <span> - </span>
                        {group.endDate ? (
                          <time dateTime={group.endDate}>
                            {getLocaleDateString(group.endDate)}
                          </time>
                        ) : (
                          intl.formatMessage({ id: "open", defaultMessage: "Open" })
                        )}
                        {group.meetingTimes && group.meetingTimes.map((day, meetingTimeNum) => {
                          return (
                            <div key={meetingTimeNum}>
                              {intl.formatMessage({
                                id: getWeekdayName(day.dayOfWeek).toLowerCase(),
                                defaultMessage: getWeekdayName(day.dayOfWeek),
                              })}{" "}
                              {getHHMM(day.startTime)}
                              {day.endTime != null && " - " + getHHMM(day.endTime)}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className="groupColumn">
                    {group.isYouthOnly && intl.formatMessage({ id: "group_age_type_youth", defaultMessage: "Youth Only" })}
                    {group.isAdultOnly && intl.formatMessage({ id: "group_age_type_adult", defaultMessage: "Adult Only" })}
                    {!group.isAdultOnly && !group.isYouthOnly && intl.formatMessage({ id: "combined", defaultMessage: "Combined" })}
                  </div>
                  <div className="groupColumn">
                    <button className="signUp" onClick={() => goToGroupDetails(group)}><FormattedMessage id="signup" defaultMessage="Sign Up" /></button>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );

};

export default MobileLocationGroupList;