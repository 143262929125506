import StudentEnrollmentCourse from "./StudentEnrollmentCourse";
import { StudentEnrollmentDto } from "lib/api/backend/model";

export interface IStudentEnrollment {
  courses: StudentEnrollmentCourse[];
  email?: string;
  enrollmentId: number;
  firstName: string;
  isLimitedStudent: boolean;
  lastName: string;
  phone?: string;
  studentId: number;
}

export class StudentEnrollment implements IStudentEnrollment {
  public courses: StudentEnrollmentCourse[];
  public email?: string;
  public enrollmentId: number;
  public firstName: string;
  public isLimitedStudent: boolean;
  public lastName: string;
  public phone?: string;
  public studentId: number;

  constructor(creationValues: IStudentEnrollment | StudentEnrollmentDto) {
    if (creationValues instanceof StudentEnrollmentDto) {
      this.courses = creationValues.courses.map(studentEnrollmentCourseDto => new StudentEnrollmentCourse(studentEnrollmentCourseDto));
    } else {
      this.courses = creationValues.courses;
    }
    this.email = creationValues.email;
    this.enrollmentId = creationValues.enrollmentId;
    this.firstName = creationValues.firstName;
    this.isLimitedStudent = creationValues.isLimitedStudent;
    this.lastName = creationValues.lastName;
    this.phone = creationValues.phone;
    this.studentId = creationValues.studentId;
  }

}

export default StudentEnrollment;
