import { QueryParameterNames, ApplicationPaths } from "constants/api-auth-constants";
import AuthorizeService from "auth/authorize-service";

const apiPrefix = process.env.REACT_APP_API_URL;

export const SessionStorageKeys = {
  locationId: "group-signup-locationId",
  programId: "group-signup-programId",
  groupId: "group-signup-groupId",
  trackId: "group-signup-trackId",
  termId: "group-signup-termId",
  providerId: "group-signup-providerId",
  enrollOriginUrl: "enroll-origin-url",
  groupDetailsOriginUrl: "group-details-origin-url"
};

export async function backgroundLogout() {
  return new Promise((resolve, reject) => {
    const logoutFrame = document.createElement("iframe");
    logoutFrame.src = `${window.location.origin}${apiPrefix}${ApplicationPaths.SignOutPath}`;
    logoutFrame.ariaHidden = "true";
    logoutFrame.style.display = "none";
    logoutFrame.addEventListener("load", () => {
      resolve();
    });
    logoutFrame.addEventListener("error", () => {
      reject();
    });
    document.body.appendChild(logoutFrame);
  });
}

export function getReturnUrl(state) {
  const params = new URLSearchParams(window.location.search);
  const fromQuery = params.get(QueryParameterNames.ReturnUrl);
  if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
    // This is an extra check to prevent open redirects.
    throw new Error("Invalid return url. The return url needs to have the same origin as the current page.");
  }
  return (state && state.returnUrl) || fromQuery || `${window.location.href}`;
}

export function redirectToApiAuthorizationPath(apiAuthorizationPath) {
  const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
  // It's important that we do a replace here so that when the user hits the back arrow on the
  // browser he gets sent back to where it was on the app instead of to an endpoint on this
  // component.
  window.location.replace(redirectUrl);
}

export function redirectToServerSignIn() {
  redirectToApiAuthorizationPath(`${apiPrefix}${ApplicationPaths.SignInPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.SignInCallback)}&state=${getReturnUrl()}`);
}

export async function redirectToServerSignOut(state) {
  const returnUrl = (state && state.returnUrl && state.returnUrl.length) ? state.returnUrl : getReturnUrl();
  AuthorizeService.sendSignOutMessage(state?.reason);
  await redirectToApiAuthorizationPath(`${apiPrefix}${ApplicationPaths.SignOutPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`);
}

export function navigateToReturnUrl(returnUrl) {
  // It's important that we do a replace here so that we remove the callback uri with the
  // fragment containing the tokens from the browser history.
  window.location.replace(returnUrl);
}

export function getStateFromUrl(urlString) {
  const url = new URL(urlString);
  const params = new URLSearchParams(url.search);
  const fromQuery = params.get(QueryParameterNames.State);
  if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
    // This is an extra check to prevent open redirects.
    throw new Error("Invalid return url. The return url needs to have the same origin as the current page.");
  }
  return fromQuery;
}

export function getQueryString(key) {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const fromQuery = params.get(key);
  return fromQuery;
}

export function setCourseSignupSession(providerId, group, enrollOriginUrl, groupDetailsOriginUrl = "") {
  sessionStorage.setItem(SessionStorageKeys.providerId, providerId);
  sessionStorage.setItem(SessionStorageKeys.locationId, group.locationId);
  sessionStorage.setItem(SessionStorageKeys.programId, group.programId);
  sessionStorage.setItem(SessionStorageKeys.groupId, group.id | group.groupId);
  sessionStorage.setItem(SessionStorageKeys.trackId, group.trackId);
  sessionStorage.setItem(SessionStorageKeys.termId, group.termId);
  sessionStorage.setItem(SessionStorageKeys.enrollOriginUrl, enrollOriginUrl);
  if (groupDetailsOriginUrl) {
    sessionStorage.setItem(SessionStorageKeys.groupDetailsOriginUrl, groupDetailsOriginUrl);
  }
}

export function getCourseSignupSession() {
  return {
    providerId: sessionStorage.getItem(SessionStorageKeys.providerId),
    locationId: sessionStorage.getItem(SessionStorageKeys.locationId),
    programId: sessionStorage.getItem(SessionStorageKeys.programId),
    groupId: sessionStorage.getItem(SessionStorageKeys.groupId),
    trackId: sessionStorage.getItem(SessionStorageKeys.trackId),
    termId: sessionStorage.getItem(SessionStorageKeys.termId),
    enrollOriginUrl: sessionStorage.getItem(SessionStorageKeys.enrollOriginUrl),
    groupDetailsOriginUrl: sessionStorage.getItem(SessionStorageKeys.groupDetailsOriginUrl)
  };
}

export function resetCourseSignupSession() {
  sessionStorage.removeItem(SessionStorageKeys.providerId);
  sessionStorage.removeItem(SessionStorageKeys.locationId);
  sessionStorage.removeItem(SessionStorageKeys.programId);
  sessionStorage.removeItem(SessionStorageKeys.groupId);
  sessionStorage.removeItem(SessionStorageKeys.trackId);
  sessionStorage.removeItem(SessionStorageKeys.termId);
  sessionStorage.removeItem(SessionStorageKeys.enrollOriginUrl);
  sessionStorage.removeItem(SessionStorageKeys.groupDetailsOriginUrl);
}