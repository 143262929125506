import axios, { AxiosResponse } from "axios";
import config from "../axios.config.json";
import { ApiUrl } from "constants/api-auth-constants";
import { ServerResponseType, validateResponse } from "../../request-utils";
import { ParticipantSignUpModel } from "lib/api/backend/model/participant/ParticipantSignupModel";
import { ParticipantDto } from "../../model/participant/ParticipantDto";
import ParticipantGroupDetailsDto from "../../model/participant/ParticipantGroupDetailsDto";
import { ParticipantUpdateDto } from "../../model/participant/ParticipantUpdateDto";
import { put } from "../../request-utils";

export const participantControllerPath = `${ApiUrl}participants/`;

export const signUpParticipant = async (signupModel: ParticipantSignUpModel) => {
  try {
    const response: AxiosResponse<ParticipantDto> = await axios.post(
      `${participantControllerPath}`,
      signupModel,
      { ...config }
    );

    const responseType = validateResponse(response.status);

    return responseType === ServerResponseType.Success;
  }
  catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }

    return false;
  }
};

export const getParticipant = async (participantId: number) => {
  try {
    const response: AxiosResponse<ParticipantDto> = await axios.get(
      `${participantControllerPath}${participantId}`,
      { ...config }
    );

    const responseType = validateResponse(response.status);

    if (responseType === ServerResponseType.Success) {
      return response.data;
    }
    return null;
  }
  catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }

    return null;
  }
};

export const getParticipantGroupDetails = async (participantId: number) => {
  try {
    const response: AxiosResponse<ParticipantGroupDetailsDto> = await axios.get(
      `${participantControllerPath}participant-group/${participantId}`,
      { ...config }
    );

    const responseType = validateResponse(response.status);

    if (responseType === ServerResponseType.Success) {
      return response.data;
    }
    return null;
  }
  catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }

    return null;
  }
};

export const dropParticipants = async (participantIds: number[]) => {
  try {
    const response: AxiosResponse = await axios.put(
      `${participantControllerPath}drop-participants`,
      participantIds,
      { ...config }
    );

    const responseType = validateResponse(response.status);

    return (responseType === ServerResponseType.Success);

  }
  catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }

    return false;
  }
};

export const recieveConsents = async (participantIds: number[]) => {
  try {
    const response: AxiosResponse = await axios.put(
      `${participantControllerPath}update-participant-consent`,
      participantIds,
      { ...config }
    );

    const responseType = validateResponse(response.status);

    return (responseType === ServerResponseType.Success);

  }
  catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }

    return false;
  }
};

export const updateParticipant = async (participantId: number, participantUpdateDto: ParticipantUpdateDto) => {
  return await put<ParticipantGroupDetailsDto>(`${participantControllerPath}update-participant/${participantId}`, participantUpdateDto);
};