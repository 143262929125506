import { ReactElement } from "react";
import AuthorizeService from "auth/authorize-service";
import { Permissions } from "constants/api-auth-constants";
import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { GroupStatusesAllowedToSignUp } from "constants/group-constants";
import { GroupDto, isGroupPastEndDate } from "lib/api/backend/model";
import { redirectToServerSignOut, setCourseSignupSession, backgroundLogout } from "utilities/navUtils";
import { CourseSignUpBaseUrl } from "routes";
import { useImpersonation } from "contexts/impersonation";
import type { FullGroup } from "lib/models-v2/LeadersGroupResponse";
import "./add-participants-button.scss";

interface IAddParticipantsButtonProps {
  group: FullGroup | GroupDto;
  useMenuStyle?: boolean;
  hideOnDisabled?: boolean;
}

export const AddParticipantsButton = (props: IAddParticipantsButtonProps): ReactElement => {

  const [impersonation] = useImpersonation();

  const canAddParticipants = () => {

    if (!AuthorizeService.userCan([Permissions.CourseSignUpWrite], props.group.stakeUnitNumber)) {
      return false;
    }

    if (props.group.maxEnrollment! > 0 && props.group.enrollmentCount! >= props.group.maxEnrollment!) {
      return false;
    }

    if (!GroupStatusesAllowedToSignUp.includes(props.group.status)) {
      return false;
    }

    if (isGroupPastEndDate(props.group)) {
      return false;
    }

    return true;

  };

  const disabled = !canAddParticipants();

  const courseSignup = async () => {
    if (impersonation.isImpersonating) {
      await backgroundLogout();
      setCourseSignupSession(props.group.providerId, props.group, `${window.location.origin}${window.location.pathname}`);
      window.location.replace(`${window.location.origin}${CourseSignUpBaseUrl}/${props.group.id}`);
    } else {
      setCourseSignupSession(props.group.providerId, props.group, `${window.location.origin}${window.location.pathname}`);
      redirectToServerSignOut({ returnUrl: `${window.location.origin}${CourseSignUpBaseUrl}/${props.group.id}` });
    }
  };

  return (
    <div className="addParticipantsButtonContainer">
      {!(props?.hideOnDisabled && disabled) && props.group &&
        <>
          {props?.useMenuStyle ?
            <button disabled={disabled} onClick={() => courseSignup()}>
              <span className="buttonPlainTextIcon menuStyle">+</span> <FormattedMessage id="participants" defaultMessage="Participants" />
            </button>
            :
            <Button
              variant="outlined"
              onClick={() => courseSignup()}
              disabled={disabled}
            >
              <span className="buttonPlainTextIcon">+</span> <FormattedMessage id="participants" defaultMessage="Participants" />
            </Button>
          }
        </>
      }
    </div>
  );

};

export default AddParticipantsButton;