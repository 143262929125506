import { useState } from "react";
import { useEventListener, useIsomorphicLayoutEffect } from "usehooks-ts";
import { getBreakpoints } from "utilities/styles";

const cssBreakpoints = getBreakpoints();
const desktopBreakpoint = cssBreakpoints.desktopBreakpoint;
const mobileBreakpoint = cssBreakpoints.mobileBreakpoint;

export enum ScreenSizes {
  Desktop,
  Tablet,
  Mobile
}

function useScreen() {
  const getScreen = () => {
    if (typeof window !== "undefined" && window.screen) {
      return window.screen;
    }
    return undefined;
  };

  const [screen, setScreen] = useState<Screen | undefined>(getScreen());

  function handleSize() {
    setScreen(getScreen());
  }

  useEventListener("resize", handleSize);

  // Set size at the first client-side load
  useIsomorphicLayoutEffect(() => {
    handleSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!screen) {
    return null;
  }

  if (screen.width < mobileBreakpoint) {
    return ScreenSizes.Mobile;
  }
  else if (screen.width > mobileBreakpoint && screen.width < desktopBreakpoint) {
    return ScreenSizes.Tablet;
  }
  else {
    return ScreenSizes.Desktop;
  }
}

export default useScreen;