import { SupportedLanguages, DefaultLanguage, LanguageStorageName } from "constants/language-constants";
import appStore from "datastore/store";

export default function getLanguage(localeCode) {

  let languageCode = localeCode;

  if (!languageCode) {
    const storedLanguage = getStoredLanguage();
    if (storedLanguage) {
      languageCode = storedLanguage;
    } else {
      languageCode = getBrowserLanguage();
      const dashIndex = languageCode.lastIndexOf("-");
      languageCode = dashIndex > 0 ? languageCode.substring(0, dashIndex) : languageCode;
    }
  }

  const language = SupportedLanguages.find(language => {
    return language.localeCodes.includes(languageCode);
  });

  if (typeof language !== "undefined") {
    return { ...language, code: languageCode };
  } else {
    return DefaultLanguage;
  }

}

export function getBrowserLanguage(ignoreVariant = false) {

  const browserLocaleCode = navigator.language || navigator.browserLanguage || navigator.systemLanguage || navigator.userLanguage;

  //Encountered an issue where safari does not correctly capitalize locale codes. 
  //This causes some validator functions that rely on strictly correct locale codes to error. Reffering to validator.isMobilePhone() used in signup step 1
  //So we correct the locale code format manually
  const localeCodePieces = browserLocaleCode.split("-");
  let correctedLocaleCode = browserLocaleCode;

  if (localeCodePieces.length == 3) {
    let languageCode = localeCodePieces[0].toLowerCase();
    let languageVariant = localeCodePieces[1].charAt(0).toUpperCase() + localeCodePieces[1].slice(1);
    let countryCode = localeCodePieces[2].toUpperCase();
    if (ignoreVariant) {
      correctedLocaleCode = `${languageCode}-${countryCode}`;
    } else {
      correctedLocaleCode = `${languageCode}-${languageVariant}-${countryCode}`;
    }
  } else if (localeCodePieces.length == 2) {
    let languageCode = localeCodePieces[0].toLowerCase();
    let countryCode = localeCodePieces[1].toUpperCase();
    correctedLocaleCode = `${languageCode}-${countryCode}`;
  }

  return correctedLocaleCode;
}

export function setStoredLanguage(languageLocaleCode) {
  localStorage.setItem(LanguageStorageName, languageLocaleCode);
}

export function getStoredLanguage() {
  return localStorage.getItem(LanguageStorageName);
}

export function getLangName(locale) {
  const supportedLanguage = SupportedLanguages.find(language => language = language.localeCodes.includes(locale));
  if (supportedLanguage) {
    return `${supportedLanguage.nativeName} (${supportedLanguage.englishName})`;
  } else {
    return "";
  }
}

//only use this when outside react, otherwise please use useIntl or FormattedMessage
export function getTranslation(id, defaultMessage) {

  const state = appStore.getState();
  const translations = state.appReducer.language.translations;

  if (translations[id]) {
    return translations[id];
  } else if (defaultMessage) {
    return defaultMessage;
  }
  return "";
}
