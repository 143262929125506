import { ReactElement } from "react";
import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import FeatureFlagEnabled from "components/feature-flag-enabled";
import "./move-participant-toggle-button.scss";
import FeatureFlags from "constants/feature-flags";

interface IMoveParticipantToggleButtonProps {
  onToggle: () => void
}

export const MoveParticipantToggleButton = (props: IMoveParticipantToggleButtonProps): ReactElement => {

  return (
    <FeatureFlagEnabled featureFlagName={FeatureFlags.QuickRegMoveParticipants}>
      <div className="moveParticipantToggleButtonContainer">
        <Button onClick={props.onToggle}><FormattedMessage id="move_participant" defaultMessage="Move Participant" /></Button>
      </div>
    </FeatureFlagEnabled>
  );
};

export default MoveParticipantToggleButton;