import "./copy-group.scss";

import React from "react";
import { Link, useParams as useRouteParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import GroupManager from "components/group-manager";

type RouteParams = {
  locationId: string,
  programId: string,
  groupId: string
}

const CopyGroup: React.VFC = () => {

  const { groupId, locationId, programId } = useRouteParams<RouteParams>();

  return (
    <div className="manageGroupPageContainer">
      <div className="pageHead">
        <h1><FormattedMessage id="copy_group" defaultMessage="Copy Group" /></h1>

        <Link to="/groups">
          <button className="primary hollow">
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </button>
        </Link>
      </div>

      <section>
        <GroupManager
          ids={{
            groupId: Number.parseInt(groupId ?? ""),
            locationId: Number.parseInt(locationId ?? ""),
            programId: Number.parseInt(programId ?? "")
          }}
          copy={true} />
      </section>
    </div>
  );
};

export default CopyGroup;
