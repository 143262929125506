//TODO: Remove this file once the change to the new group response object is complete everywhere in the app.

import { AgeType } from "lib/data/model/enum";
import GuestFacilitator from "./GuestFacilitator";
import Location from "./Location";
import StandardFacilitator from "./StandardFacilitator";
import { CourseDto } from "lib/api/backend/model/course";
import { CourseOutcomeDto } from "lib/api/backend/model/outcomes";
import { ProviderProgramLanguageDto } from "lib/api/backend/model/provider";
import {
  MeetingTimeDto,
  StudentDto,
  WsrsOutcomeDto
} from "lib/api/backend/model";
import GroupLanguageDto from "lib/api/backend/model/GroupLanguageDto";
import CourseLanguageDto from "lib/api/backend/model/course/CourseLanguageDto";
import type { Facilitator, FullGroup, MeetingTime, Language, Student } from "lib/models-v2/LeadersGroupResponse";

interface IGroupCreationValues {
  id?: number;
  name: string;
  location?: Location;
  course?: CourseDto;
  courseOutcomes: CourseOutcomeDto[];
  numberOfLessons: number;
  lessonTime: number;
  providerId?: number;
  programLanguages: ProviderProgramLanguageDto[];
  programId?: number;
  termId?: number;
  trackId?: number;
  startDate?: Date | string;
  endDate?: Date | string;
  meetingTimes: MeetingTimeDto[];
  facilitators: (StandardFacilitator | GuestFacilitator)[];
  students: StudentDto[];
  enrollmentCount?: number;
  maxEnrollment?: number;
  status: string;
  duration: number;
  groupAgeType: AgeType;
  isHidden: boolean;
  isPending: boolean;
  hasAcceptedYouthTerms: boolean;
  hasParticipationCertificate: boolean;
  hasCompletionCertificate: boolean;
  wsrsOutcomes: WsrsOutcomeDto[];
  stakeUnitNumber?: number;
  groupLanguages?: GroupLanguageDto[];
}

export default class Group {
  public id?: number;
  public name: string;
  public location?: Location;
  public course?: CourseDto;
  public courseOutcomes: CourseOutcomeDto[];
  public numberOfLessons: number;
  public lessonTime: number;
  public providerId?: number;
  public programLanguages: ProviderProgramLanguageDto[];
  public programId?: number;
  public termId?: number;
  public trackId?: number;
  public startDate?: Date;
  public endDate?: Date;
  public meetingTimes: MeetingTimeDto[];
  public facilitators: (StandardFacilitator | GuestFacilitator)[];
  public students: StudentDto[];
  public enrollmentCount?: number;
  public maxEnrollment?: number;
  public status: string;
  public duration: number;
  public groupAgeType: AgeType;
  public isHidden: boolean;
  public isPending: boolean;
  public hasAcceptedYouthTerms: boolean;
  public hasParticipationCertificate: boolean;
  public hasCompletionCertificate: boolean;
  public wsrsOutcomes: WsrsOutcomeDto[];
  public stakeUnitNumber?: number;
  public groupLanguages?: GroupLanguageDto[];

  constructor(creationValues: IGroupCreationValues) {
    this.id = creationValues.id;
    this.name = creationValues.name;
    this.location = creationValues.location;
    this.course = creationValues.course;
    this.courseOutcomes = creationValues.courseOutcomes;
    this.numberOfLessons = creationValues.numberOfLessons ?? 0;
    this.lessonTime = creationValues.lessonTime ?? 0;
    this.providerId = creationValues.providerId;
    this.programLanguages = creationValues.programLanguages;
    this.programId = creationValues.programId;
    this.termId = creationValues.termId;
    this.trackId = creationValues.trackId;

    if (creationValues.startDate instanceof Date) {
      this.startDate = creationValues.startDate;
    } else if (creationValues.startDate) {
      this.startDate = new Date(creationValues.startDate);
    } else {
      this.startDate = undefined;
    }

    if (creationValues.endDate instanceof Date) {
      this.endDate = creationValues.endDate;
    } else if (creationValues.endDate) {
      this.endDate = new Date(creationValues.endDate);
    } else {
      creationValues.endDate = undefined;
    }

    this.meetingTimes = creationValues.meetingTimes;
    this.facilitators = creationValues.facilitators;
    this.students = creationValues.students;
    this.enrollmentCount = creationValues.enrollmentCount;
    this.maxEnrollment = creationValues.maxEnrollment;
    this.status = creationValues.status;
    this.duration = creationValues.duration;
    this.groupAgeType = creationValues.groupAgeType;
    this.isHidden = creationValues.isHidden;
    this.isPending = creationValues.isPending;
    this.hasAcceptedYouthTerms = creationValues.hasAcceptedYouthTerms;
    this.hasParticipationCertificate = creationValues.hasParticipationCertificate;
    this.hasCompletionCertificate = creationValues.hasCompletionCertificate;
    this.wsrsOutcomes = creationValues.wsrsOutcomes;
    this.stakeUnitNumber = creationValues.stakeUnitNumber;
    this.groupLanguages = creationValues.groupLanguages;
  }

  public isGroupPastEndDate(): boolean {
    if (!this.endDate)
      return true;

    const endDate = new Date(this.endDate);
    const today = new Date();

    if (today > endDate)
      return true;

    return false;
  }

  //THE FOLLOWING CODE EXISTS TEMPORARILY WHILE THE CHANGE TO THE NEW GROUP RESPONSE OBJECT IS IN PROGRESS EVERYWHERE IN THE APP.
  //ONCE THE CHANGE IS COMPLETE, THIS CODE WILL NOT BE NEEDED.

  private convertLanguageDtoToV2(languageDto: GroupLanguageDto, allLanguages: CourseLanguageDto[]): Language {
    if (languageDto === undefined) {
      return {
        id: 0,
        name: "Multilingual",
        description: "Multilingual",
        code: "",
      } as Language;
    }

    const groupLanguage = allLanguages.filter(x => x.id === languageDto.languageId)[0];

    const language: Language = {
      id: groupLanguage.id,
      name: groupLanguage.name,
      description: groupLanguage.description,
      code: "",
    };

    return language;
  }


  private convertFacilitatorDtoToV2(facilitatorList: Array<(StandardFacilitator | GuestFacilitator)>): [Facilitator[], Facilitator[]] {
    const memberFacilitators = facilitatorList.filter(f => f instanceof StandardFacilitator).map(
      facilitator => {
        const f = facilitator as StandardFacilitator;

        return {
          id: f.id || 0,
          name: `${f.firstName} ${f.lastName}`,
          gender: f.gender,
          firstName: f.firstName,
          lastName: f.lastName,
          cmisId: f.cmisId
        };
      }
    );

    const nonMemberFacilitators = facilitatorList.filter(f => f instanceof GuestFacilitator).map(
      facilitator => {
        const f = facilitator as GuestFacilitator;

        return {
          id: f.id || 0,
          name: f.name
        };
      }
    );

    return [memberFacilitators, nonMemberFacilitators];
  }

  private convertMeetingTimeDtoToV2(meetingTimeDto: MeetingTimeDto): MeetingTime {
    const meetingTime: MeetingTime = {
      id: meetingTimeDto.id || 0,
      dayOfWeek: meetingTimeDto.dayOfWeek || 0,
      startTime: meetingTimeDto.startTime?.toISOString() || "",
      endTime: meetingTimeDto.endTime?.toISOString() || "",
      timeZoneName: meetingTimeDto.timeZoneName || "",
    };

    return meetingTime;

  }

  private getAgeType() {
    if (this.groupAgeType === AgeType.Youth) {
      return "Youth only";
    }

    if (this.groupAgeType === AgeType.Adult) {
      return "Adult only";
    }

    return "Combined";
  }

  private getIsVirtual() {
    if (!this.location) {
      return true;
    }

    if (this.location.type === 0) {
      return false;
    }

    if (this.location.type === 1) {
      return true;
    }

    return true;
  }

  public convertToFullGroup(allLanguages: CourseLanguageDto[]): FullGroup {
    const [facilitators, nonMemberFacilitators] = this.convertFacilitatorDtoToV2(this.facilitators);
    const meetingTimes = this.meetingTimes.map(meetingTimeDto => this.convertMeetingTimeDtoToV2(meetingTimeDto));
    const language = this.convertLanguageDtoToV2(this.groupLanguages![0], allLanguages);

    const group: FullGroup = {
      id: this.id || 0,
      name: this.name,
      locationId: this.location?.id || 0,
      lessonTime: this.lessonTime,
      providerId: this.providerId || 0,
      programId: this.programId || 0,
      termId: this.termId || 0,
      trackId: this.trackId || 0,
      stakeUnitNumber: this.stakeUnitNumber || 0,
      programName: this.course?.name || "",
      programDescription: this.course?.description || "",
      latitude: this.location?.coordinates?.latitude || 0,
      longitude: this.location?.coordinates?.longitude || 0,
      address1: this.location?.address?.address1 || "",
      address2: this.location?.address?.address2 || "",
      city: this.location?.address?.city || "",
      state: this.location?.address?.state || "",
      country: this.location?.address?.country || "",
      postalCode: this.location?.address?.postalCode || "",
      startDate: this.startDate?.toISOString() || "",
      endDate: this.endDate?.toISOString() || "",
      enrollmentCount: this.enrollmentCount || 0,
      maxEnrollment: this.maxEnrollment || null,
      status: this.status,
      isVirtual: this.getIsVirtual(),
      isYouthOnly: this.groupAgeType === AgeType.Youth,
      isAdultOnly: this.groupAgeType === AgeType.Adult,
      groupAgeType: this.getAgeType(),
      isHidden: this.isHidden || null,
      isPending: this.isPending,
      hasParticipationCertificate: this.hasParticipationCertificate,
      hasCompletionCertificate: this.hasCompletionCertificate,
      hasAcceptedYouthTerms: this.hasAcceptedYouthTerms,
      duration: this.duration,
      meetingTimes: meetingTimes,
      facilitators: facilitators,
      nonMemberFacilitators: nonMemberFacilitators,
      students: this.students.map(student => ({
        enrollmentId: student.enrollmentId,
        studentId: student.studentId,
        firstName: student.firstName,
        lastName: student.lastName,
        email: student.email,
        phone: student.phone,
        status: student.status,
        isLimitedStudent: student.isLimitedStudent,
        isOlderThanEighteen: student.isOlderThanEighteen
      }) as Student),
      quickRegParticipants: [],
      wsrsOutcomes: this.wsrsOutcomes,
      language: language
    };

    return group;
  }
}