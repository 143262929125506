import "./duplicates-modal.scss";
import { ReactElement } from "react";
import { GroupDto } from "lib/api/backend/model";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import GroupDetails from "components/group-details/group-details";

interface IDuplicatesModalProps {
  duplicateGroups: GroupDto[];
  open: boolean;
  editing: boolean;
  onDecision: (shouldContinue: boolean) => void;
}

export const DuplicatesModal = (props: IDuplicatesModalProps): ReactElement => {

  return (
    <Dialog className="duplicatesModalContainer" open={props.open}>
      <DialogTitle>
        <div className="duplicateGroupModalHead">
          <InfoIcon className="infoIcon" fontSize="medium" />
          <span className="title">
            <FormattedMessage
              id="possible_duplicate_group"
              defaultMessage="Possible Duplicate Group"
            />
          </span>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="duplicatesModalContent">
          <FormattedMessage
            id="may_be_duplicate_with"
            defaultMessage="This group may be duplicate with:"
          />
          {props.duplicateGroups.map((duplicateGroup, index) => <GroupDetails key={index} group={duplicateGroup} smallDetails={true} hideFacilitators={true} />)}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className="cancelButton"
          onClick={() => props.onDecision(false)}
        >
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          variant="contained"
          onClick={() => props.onDecision(true)}
        >
          <FormattedMessage id="save" defaultMessage="Save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicatesModal;