import { FeatureFlagDto } from "lib/api/backend/model";
import { getFeatureFlagValue } from "lib/api/backend/requests/feature-flags";
import create from "zustand";

export type FeatureFlagStore = {
  featureFlags: FeatureFlagDto[],
  fetching: string[];
  addFeatureFlag: (newFeatureFlags: FeatureFlagDto) => void,
  getFeatureFlag: (featureFlagName: string) => Promise<FeatureFlagDto | null>,
  removeFeatureFlag: (featureFlagName: string) => void,
  addIsFetching: (featureFlagName: string) => void,
  isAlreadyFetching: (featureFlagName: string) => boolean;
  removeIsFetching: (featureFlagName: string) => void;
};

export const useFeatureFlagStore = create<FeatureFlagStore>(
  (set, get): FeatureFlagStore => ({
    featureFlags: [],
    fetching: [],
    addFeatureFlag: (newFeatureFlag: FeatureFlagDto) => set((store) => {
      const existingFeatureIndex = store.featureFlags.findIndex(ff => ff.name == newFeatureFlag.name);
      if (existingFeatureIndex == -1) {
        return { featureFlags: [...store.featureFlags, newFeatureFlag] };
      } else {
        const newFeatureFlags = store.featureFlags;
        newFeatureFlags[existingFeatureIndex].enabled = newFeatureFlag.enabled;
        return { featureFlags: [] };
      }
    }),
    getFeatureFlag: async (featureFlagName: string) => {
      const existingFeatureFlag = get().featureFlags.find(ff => ff.name == featureFlagName) || null;
      if (existingFeatureFlag) {
        return existingFeatureFlag;
      } else if (!get().isAlreadyFetching(featureFlagName)) {
        get().addIsFetching(featureFlagName);
        const newFeatureFlag = await getFeatureFlagValue(featureFlagName);
        if (newFeatureFlag) {
          set({ featureFlags: [...get().featureFlags, newFeatureFlag] });
          get().removeIsFetching(featureFlagName);
          return newFeatureFlag;
        }
        get().removeIsFetching(featureFlagName);
      }
      return null;
    },
    removeFeatureFlag: (featureFlagName: string) => set((store) => {
      const existingFeatureIndex = store.featureFlags.findIndex(ff => ff.name == featureFlagName);
      if (existingFeatureIndex == -1) {
        const newFeatureFlags = store.featureFlags;
        newFeatureFlags.splice(existingFeatureIndex, 1);
        return { featureFlags: [...newFeatureFlags] };
      } else {
        return { featureFlags: store.featureFlags };
      }
    }),
    addIsFetching: (featureFlagName: string) => {
      const currentFetching = get().fetching;
      if (!currentFetching.some(nameBeingFetched => nameBeingFetched == featureFlagName)) {
        set({ fetching: [...currentFetching, featureFlagName] });
      }
    },
    isAlreadyFetching: (featureFlagName: string) => {
      const currentFetching = get().fetching;
      return currentFetching.some(nameBeingFetched => nameBeingFetched == featureFlagName);
    },
    removeIsFetching: (featureFlagName: string) => {
      const newFetching = get().fetching;
      const indexToRemove = newFetching.findIndex(nameBeingFetched => nameBeingFetched == featureFlagName);
      if (indexToRemove != -1) {
        newFetching.splice(indexToRemove, 1);
        set({ fetching: newFetching });
      }
    }
  })
);

export default useFeatureFlagStore;