import dayjs from "dayjs";

export default class StandardFacilitator {

  public id?: number;
  public cmisId: number;
  public gender: string;
  public firstName: string;
  public lastName: string;
  public birthDate?: Date;

  constructor(facilitatorData: StandardFacilitator) {
    this.id = facilitatorData.id;
    this.cmisId = facilitatorData.cmisId;
    this.gender = facilitatorData.gender;
    this.firstName = facilitatorData.firstName;
    this.lastName = facilitatorData.lastName;
    this.birthDate = facilitatorData.birthDate ? dayjs(facilitatorData.birthDate).toDate() : undefined;
  }

}