import { ReactElement, useState } from "react";
import { ListActionMenuDefaultAction, ListActionMenuPopover, ListActionMenuToggle } from "./components";

interface IListActionMenu {
  defaultAction?: ListActionMenuDefaultAction | null
  onToggle?: (open: boolean) => void
  open?: boolean
  children?: React.ReactNode;
  alignRight?: boolean
}

export const ListActionMenu = (props: IListActionMenu): ReactElement => {

  const [open, setOpen] = useState<boolean>(!!props?.open);

  const toggleOpen = () => {
    setOpen(!open);
    if (props.onToggle) {
      props.onToggle(!open);
    }
  };

  return (
    <div className="listActionMenuContainer" >
      <ListActionMenuToggle onToggle={toggleOpen} open={open} defaultAction={props.defaultAction ? props.defaultAction : null} alignRight={props.alignRight} />
      <ListActionMenuPopover onToggle={toggleOpen} open={open}>
        {props.children}
      </ListActionMenuPopover>
    </div>
  );

};

export default ListActionMenu;