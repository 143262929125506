import React from "react";
import Help from "./help";

export default { title: "Help"};

export const helpURL =  "https://rise.articulate.com/share/2SdrHFpdW109NMP2cwantgM4bkDiKEtV";

export const HelpDefaultStory = () => {

  return (
    <Help/>
  );

};