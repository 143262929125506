import CourseLanguageDto, { ICourseLanguageDto } from "../course/CourseLanguageDto";
import ProviderLanguageDto, { IProviderLanguageDto } from "./ProviderLanguageDto";

export interface IProviderProgramLanguageDto {
  id?: number;
  providerProgramId?: number;
  langId?: number;
  lang: IProviderLanguageDto;
}

export default class ProviderProgramLanguageDto implements ProviderProgramLanguageDto {

  public id?: number;
  public providerProgramId?: number;
  public langId?: number;
  public lang: ProviderLanguageDto;

  constructor(creationValues: IProviderProgramLanguageDto) {
    this.id = creationValues.id;
    this.providerProgramId = creationValues.providerProgramId;
    this.langId = creationValues.langId;
    this.lang = new ProviderLanguageDto(creationValues.lang);
  }

  public export(): IProviderProgramLanguageDto {
    return {
      id: this.id,
      providerProgramId: this.providerProgramId,
      langId: this.langId,
      lang: this.lang.export()
    };
  }

  public clone(): ProviderProgramLanguageDto {
    return new ProviderProgramLanguageDto(this.export());
  }

  static mapToCourseLanguageDto(lang: IProviderProgramLanguageDto): CourseLanguageDto {
    const language = {
      id: lang.lang.id,
      name: lang.lang.name,
      description: lang.lang.description
    } as ICourseLanguageDto;

    return new CourseLanguageDto(language);
  }
}