import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import dispatchers from "datastore/dispatcher";
import Notification from "./notifcation/notification";
import { Notice } from "lib/data/model";

const Notifier = (): ReactElement => {

  const notifications = useSelector((state: any) => state.appReducer.notifications) as Notice[];
  const reduxDispatch = useDispatch();
  const dismissNotice = dispatchers(reduxDispatch).dismissNotice as (noticeId: string) => void;

  const onRemove = (noticeId: string) => {
    dismissNotice(noticeId);
  };

  return (
    <>
      {notifications.map((notice, index) => <Notification key={`notice-${index}`} onRemove={onRemove} notice={notice} />)}
    </>
  );
};

export default Notifier;