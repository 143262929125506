/*****************************
 * Parameters: components (GeoCoderAddressComponent)
 * Returns: AddressCreationModel
*/
export function getAddressCreationModel( components )
{
  let model = {};
  let streetNumber;
  let streetAddress;

  components.forEach( component => {
    component.types.forEach( type => {
      switch(type) {
        case "street_number": 
          streetNumber = component.long_name;
          break;

        case "street_address":
        case "route":
          streetAddress = component.long_name;
          break;

        case "premise":
        case "sub-premise":
        case "plus_code":
          model["address2"] = component.long_name;
          break;

        case "locality":
        case "sublocality":
        case "sublocality_level_1":    
        case "sublocality_level_2":    
        case "sublocality_level_3":    
        case "sublocality_level_4":    
          model["city"] = component.long_name;
          break;

        case "administrative_area_level_1":
        case "administrative_area_level_2":
        case "administrative_area_level_3":
        case "administrative_area_level_4":
        case "administrative_area_level_5":
          model["state"] = component.long_name;
          break;

        case "postal_code":
          model["postalCode"] = component.long_name;
          break;

        case "country":
          model["country"] = component.long_name;
          break;
      }
    });
  });
  model["address1"] = [streetNumber, streetAddress].join(" ").trim();
  return model;
}