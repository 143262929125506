import { computeDestinationPoint } from "geolib";
import { MapBounds, LatLng, MapLocation } from "lib/data/model";
import { GroupDto } from "lib/api/backend/model";
import { FullGroup, GroupDetails } from "lib/models-v2";

export const getPlaceBounds = (geometry: google.maps.places.PlaceGeometry, searchRadius: number) => {
  let placeBounds: MapBounds;

  if (geometry.viewport) {
    const ne = geometry.viewport?.getNorthEast();
    const sw = geometry.viewport?.getSouthWest();

    placeBounds = {
      swLat: sw!.lat(),
      swLng: sw!.lng(),
      neLat: ne!.lat(),
      neLng: ne!.lng()
    };
  }
  else {
    placeBounds = calculateBounds(
      {
        lat: geometry.location!.lat(),
        lng: geometry.location!.lng(),
      },
      searchRadius
    );
  }

  return placeBounds;
};

export const getPosition = () => {
  return new Promise<GeolocationPosition>((res, rej) => {
    navigator.geolocation.getCurrentPosition(res, rej);
  });
};

export enum GeolocationStatus {
  Success,
  PermissionDenied,
  LocationUnavailable
}

export type LocationResult = {
  status: GeolocationStatus,
  location?: LatLng
};

export const getLocationResultFromCoords = (center: google.maps.LatLng) => {
  return new LatLng({
    lat: center.lat(),
    lng: center.lng(),
  });
};

export const getPositionOrDefault = async (): Promise<LocationResult> => {
  try {
    const position = await getPosition();

    const centerPoint = new LatLng({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    });

    const result: LocationResult = {
      status: GeolocationStatus.Success,
      location: centerPoint
    };

    return result;
  }
  catch (e) {
    if (e instanceof window.GeolocationPositionError && e.code == 1) {
      return {
        status: GeolocationStatus.PermissionDenied
      };
    }

    return {
      status: GeolocationStatus.LocationUnavailable
    };
  }
};

export const getWorldMapCenter = (): MapLocation => {
  const worldCenter = new LatLng({ lat: 0, lng: 0 });
  const worldBounds = new MapBounds({
    neLat: 85,
    neLng: -180,
    swLat: -85,
    swLng: 180,
  });

  const k = 10.0;
  const newMapBounds = new MapBounds({
    neLat: worldBounds.neLat - k,
    neLng: worldBounds.neLng - k,
    swLat: worldBounds.swLat + k,
    swLng: worldBounds.swLng + k,
  });

  return {
    center: worldCenter,
    bounds: newMapBounds,
    zoom: 1.5,
  };
};

export const calculateDistance = (initialLat: number, initialLong: number, finalLat: number, finalLong: number) => {
  const R = 6378.1; // km (Earth radius)
  const dLat = toRadians(finalLat - initialLat);
  const dLon = toRadians(finalLong - initialLong);
  initialLat = toRadians(initialLat);
  finalLat = toRadians(finalLat);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(initialLat) * Math.cos(finalLat);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // distance in km
};

export const toRadians = (deg: number) => {
  return deg * (Math.PI / 180);
};

export const toDegrees = (rad: number) => {
  return rad * (180 / Math.PI);
};

/**
 * @param latLngInDeg array of arrays with latitude and longtitude
 *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
 *   [longtitude2] ...] or useful to find the center of a lat/lng bounding box
 *
 * @return array with the center latitude longtitude pairs in degrees.
 */
export const getLatLngCenter = (latLngInDegr) => {
  const LATIDX = 0;
  const LNGIDX = 1;
  let sumX = 0;
  let sumY = 0;
  let sumZ = 0;

  for (let i = 0; i < latLngInDegr.length; i++) {
    const lat = toRadians(latLngInDegr[i][LATIDX]);
    const lng = toRadians(latLngInDegr[i][LNGIDX]);
    // sum of cartesian coordinates
    sumX += Math.cos(lat) * Math.cos(lng);
    sumY += Math.cos(lat) * Math.sin(lng);
    sumZ += Math.sin(lat);
  }

  const avgX = sumX / latLngInDegr.length;
  const avgY = sumY / latLngInDegr.length;
  const avgZ = sumZ / latLngInDegr.length;

  // convert average x, y, z coordinate to latitude and longtitude
  const avgLng = Math.atan2(avgY, avgX);
  const hyp = Math.sqrt(avgX * avgX + avgY * avgY);
  const avgLat = Math.atan2(avgZ, hyp);

  return ([toDegrees(avgLat), toDegrees(avgLng)]);
};

export const calculateBounds = (centerPoint: LatLng, radiusInMeters: number) => {
  const ne = computeDestinationPoint({ latitude: centerPoint.lat, longitude: centerPoint.lng }, radiusInMeters, 45);
  const sw = computeDestinationPoint({ latitude: centerPoint.lat, longitude: centerPoint.lng }, radiusInMeters, 225);

  return {
    swLat: sw.latitude,
    swLng: sw.longitude,
    neLat: ne.latitude,
    neLng: ne.longitude
  };
};

export function calculateOuterboundsOfLocations(locations) {
  const latitudeSort = locations.sort((locationA, locationB) => locationB.latitude - locationA.latitude);

  const longitudeSort = locations.sort((locationA, locationB) => locationB.longitude - locationA.longitude);

  const northernMost = latitudeSort[0].latitude;
  const southernMost = latitudeSort[latitudeSort.length - 1].latitude;
  const easternMost = longitudeSort[0].longitude;
  const westernMost = longitudeSort[longitudeSort.length - 1].longitude;

  return { south: southernMost, west: westernMost, north: northernMost, east: easternMost };
}

export type LocationGroup = {
  locationIds: number[],
  latitude: number,
  longitude: number,
  distanceFromCenter: number,
  groups: GroupDto[] | FullGroup[]
}

export function sortGroupsByLocationDistance(groups: GroupDto[] | FullGroup[] | GroupDetails[], mapLocation: LatLng): LocationGroup[] {
  const locations: LocationGroup[] = [];

  groups.forEach(group => {
    //some locations have the same lat/lng.
    //in order not to have multiple map pins in the same location and overlapping/inaccesible, we consider locations that have a different id but the same lat/lng as the same location
    if (group.locationId != undefined) {
      const activeLocation = locations.find(location => (location.locationIds.includes[group.locationId as number]) || (location.latitude == group.latitude && location.longitude == group.longitude));
      if (!activeLocation) {
        locations.push({
          locationIds: [group.locationId],
          latitude: group.latitude ? group.latitude : 0,
          longitude: group.longitude ? group.longitude : 0,
          distanceFromCenter: calculateDistance(mapLocation.lat, mapLocation.lng, group.latitude ? group.latitude : 0, group.longitude ? group.longitude : 0),
          groups: [group]
        });
      } else {
        activeLocation.groups.push(group);
        if (!activeLocation.locationIds.includes(group.locationId)) {
          activeLocation.locationIds.push(group.locationId);
        }
      }
    }
  });

  locations.sort((locationA, locationB) => locationA.distanceFromCenter - locationB.distanceFromCenter);

  return locations;
}

export function getMapLocationFromGoogleMap(map: google.maps.Map): MapLocation | undefined {
  if (map) {
    const center = map.getCenter();
    const zoom = map.getZoom();
    const bounds = getBoundsFromGoogleMap(map);
    if (center && bounds && zoom) {
      return new MapLocation({
        center: {
          lat: center.lat(),
          lng: center.lng()
        },
        bounds,
        zoom
      });
    }
  }
}

export function getBoundsFromGoogleMap(map: google.maps.Map): MapBounds | undefined {
  if (map) {
    const bounds = map.getBounds();
    if (bounds) {
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();
      return {
        neLat: northEast.lat(),
        neLng: northEast.lng(),
        swLat: southWest.lat(),
        swLng: southWest.lng()
      };
    }
  }
}