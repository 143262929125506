import axios from "axios";
import { validateResponse, ServerResult } from "../../request-utils";
import config from "../axios.config.json";
import QuickRegPreloadDto from "../../model/QuickRegPreloadDto";

export const getQuickRegPreloadValues = async () => {
  try {
    const response = await axios.get<QuickRegPreloadDto>("/api/sites/preload", { ...config });

    const responseType = validateResponse(response.status);

    const result: ServerResult<QuickRegPreloadDto> = {
      responseType: responseType,
      data: response.data
    };

    return result;
  }
  catch (error) {
    //Not sure how we want to handle this.
    if (axios.isAxiosError(error) && error.response) {
      console.error(`${error.response.status} - ${error.response.statusText}`);
    }
  }
};