import "./manage-group-participants.scss";
import { useEffect, ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useParams as useRouteParams } from "react-router-dom";
import GroupParticipantEditor from "components/group-participant-editor/group-participant-editor";
import { resetCourseSignupSession } from "../../utilities/navUtils";

const ManageGroupParticipants = (): ReactElement => {

  const { groupId } = useRouteParams();

  useEffect(() => {
    resetCourseSignupSession();
  }, []);

  return (
    <div className="manageGroupPageContainer">
      <div className="pageHead">
        <h1><FormattedMessage id="manage_group" defaultMessage="Manage Group" /></h1>
        <Link to="/groups"><button className="primary hollow"><FormattedMessage id="cancel" defaultMessage="Cancel" /></button></Link>
      </div>

      {groupId &&
        <GroupParticipantEditor groupId={parseInt(groupId)} />
      }
    </div>
  );
};

export default ManageGroupParticipants;
