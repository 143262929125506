import "./pagination.scss";

import React, { useEffect, useState } from "react";
import { MenuItem, Pagination as MuiPagination, Select } from "@mui/material";
import useClassName from "utilities/useClassName";
import { FormattedMessage, useIntl } from "react-intl";

const defaultState = {
  currentPage: 1,
  pageCount: 1,
  perPageCount: -1,
  perPageOptions: [{ label: "All", value: -1 }, 5, 10, 25, 50, 100],
  recordCount: 0
};

interface IPerPageOptions {
  label: string;
  value: number;
}

interface IProps {
  pageCount?: number;
  perPageCount?: number;
  currentPage?: number;
  recordCount?: number;
  perPageOptions: Array<IPerPageOptions | number>;
  onChange: (currentPage: number, perPage: number) => void;
}

const Pagination = (props: IProps) => {

  const [className] = useClassName("pagination");
  const intl = useIntl();

  const [currentPage, setCurrentPage] = useState(defaultState.currentPage);
  const [pageCount, setPageCount] = useState(defaultState.pageCount);
  const [perPageCount, setPerPageCount] = useState(defaultState.perPageCount);
  const [perPageOptions, setPerPageOptions] = useState(defaultState.perPageOptions);
  const [recordCount, setRecordCount] = useState(defaultState.recordCount);

  useEffect(
    () => {
      if (props.pageCount && props.pageCount != pageCount) {
        const newPageCount = props.pageCount;

        if (newPageCount > 0) {
          setPageCount(newPageCount);
        }
      }
    },
    [props.pageCount]
  );

  useEffect(
    () => {
      if (props.currentPage && props.currentPage != currentPage) {
        const newCurrentPage = props.currentPage;

        if (newCurrentPage > 0) {
          setCurrentPage(newCurrentPage);
        }
      }
    },
    [props.currentPage]
  );

  useEffect(
    () => {
      let newPerPageOptions;

      if (props.perPageOptions && Array.isArray(props.perPageOptions) && props.perPageOptions.length > 0) {
        newPerPageOptions = props.perPageOptions;
      }
      else {
        newPerPageOptions = defaultState.perPageOptions;
      }

      if (props.perPageCount && props.perPageCount != perPageCount) {
        const newPerPageCount = props.perPageCount;

        if (newPerPageCount > 0 || newPerPageCount === -1) {
          if (newPerPageOptions === defaultState.perPageOptions) {
            newPerPageOptions = [newPerPageCount];
          }

          setPerPageCount(newPerPageCount);
        }
      }

      setPerPageOptions(newPerPageOptions);
    },
    [props.perPageCount, props.perPageOptions]
  );

  useEffect(
    () => {
      if (props.recordCount && props.recordCount != recordCount) {
        const newRecordCount = props.recordCount;

        if (newRecordCount >= 0) {
          setRecordCount(newRecordCount);
        }
      }
    },
    [props.recordCount]
  );

  const handleCurrentPageChange = (event, newCurrentPage) => {
    setCurrentPage(newCurrentPage);

    if (props.onChange) {
      props.onChange(newCurrentPage, perPageCount);
    }
  };

  const handlePerPageCountChange = (event) => {
    const newPerPageCount = event.target.value;

    setPerPageCount(newPerPageCount);

    if (props.onChange) {
      props.onChange(currentPage, newPerPageCount);
    }
  };

  const displayPageRecordCounts = () => {
    let firstRecord = 0;
    let expectedLastRecord = 0;

    if (recordCount > 0) {
      firstRecord = ((currentPage - 1) * perPageCount) + 1;

      if (perPageCount > 0) {
        expectedLastRecord = currentPage * perPageCount;
      }
      else {
        expectedLastRecord = recordCount;
      }
    }

    const actualLastRecord = expectedLastRecord > recordCount ? recordCount : expectedLastRecord;

    const translated = intl.formatMessage({ id: "of", defaultMessage: "of" });

    return `${firstRecord}-${actualLastRecord} ${translated} ${recordCount}`;
  };

  return (
    <div className={className}>
      <MuiPagination
        showFirstButton={true}
        hidePrevButton={false}
        hideNextButton={false}
        showLastButton={true}
        count={pageCount}
        page={currentPage}
        onChange={handleCurrentPageChange}
      />
      <div className="per-page-selector">
        <label><FormattedMessage id="per_page" defaultMessage="Per Page" />:</label>
        <Select
          variant="standard"
          value={perPageCount}
          onChange={handlePerPageCountChange}
        >
          {
            perPageOptions.map(
              (option, index) => (
                typeof option === "number"
                  ? <MenuItem key={`pagination-option-${index}-${option}`} value={option}>{option}</MenuItem>
                  : <MenuItem key={`pagination-option-${index}-${option.value}`} value={option.value}>{option.label}</MenuItem>
              )
            )
          }
        </Select>
      </div>
      <div className="per-page-display">
        <label>{displayPageRecordCounts()}</label>
      </div>
    </div>
  );
};

export default Pagination;