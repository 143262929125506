import PublicGroupDetails from "components/group-details-public/group-details-public";
import { FormattedMessage } from "react-intl";
import { Button, Stack } from "@mui/material";
import { ConsentFormUrlSelector } from "components/consent-form-url-selector";

interface IRegistrationConfirmation {
  onSignUpAnother: () => void,
  onExit: (goToGroupList?: boolean) => void;
  group: any,
  showUnderAgeWarning: boolean
}

const RegistrationConfirmation = (props: IRegistrationConfirmation) => {

  const { onSignUpAnother, group, } = props;

  const handleSignUpAnother = (): void => {
    if (onSignUpAnother) {
      onSignUpAnother();
    }
  };

  return (
    <div className="registrationConfirmationContainer">
      <h1><FormattedMessage id="congratulations" defaultMessage="Congratulations!" /></h1>
      <p><FormattedMessage id="signup_success" defaultMessage="You have successfully signed up" /></p>
      <PublicGroupDetails group={group} smallDetails={true} />
      {props.showUnderAgeWarning &&
        <ConsentFormUrlSelector providerId={group.providerId} programId={group.programId} showWarning={true} />
      }
      <Stack className="buttonRow" direction="row" sx={{ flexWrap: "wrap" }} spacing={0}>
        <Button variant="text" onClick={handleSignUpAnother}><FormattedMessage id="signup_another_person" defaultMessage="Sign up another person for this group" /></Button>
        <Button variant="contained" onClick={() => props.onExit(true)}><FormattedMessage id="back_to_group_list" defaultMessage="Back to Group List" /></Button>
      </Stack>
    </div>
  );
};

export default RegistrationConfirmation;