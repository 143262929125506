export interface ICourseLanguageDto {
  id: number;
  name: string;
  description: string;
}

export default class CourseLanguageDto implements ICourseLanguageDto {

  public id: number;
  public name: string;
  public description: string;

  constructor(creationValues: ICourseLanguageDto) {
    this.id = creationValues.id;
    this.name = creationValues.name;
    this.description = creationValues.description;
  }

  public export(): ICourseLanguageDto {
    return {
      id: this.id,
      name: this.name,
      description: this.description
    };
  }

  public clone(): CourseLanguageDto {
    return new CourseLanguageDto(this.export());
  }


}