import "./group-language-selector.scss";
import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import Loading from "components/loading/loading";
import useGroupCreationFormStore from "datastore/groupCreationForm";
import { useFormStatus } from "lib/form/contexts";
import { FormStatus, SaveState, RecordType } from "lib/form/model";
import { GetCoursesByLanguage } from "lib/api/backend/requests/courses";
import { CourseDto, CourseProviderGroupDto, CourseLanguageDto } from "lib/api/backend/model/course";
import { ServerResponseType } from "lib/api/backend/request-utils";
import usePreloadStore, { getLanguagesWithMultilingual, getGroupTypesAsList } from "datastore/preload";

interface ILanguageSelectorProps {
  className?: string;
}

const GroupLanguageSelector: React.FC<ILanguageSelectorProps> = (props) => {
  const formStore = useGroupCreationFormStore();

  const group = formStore.group;

  const [formStatus, setFormStatus] = useFormStatus();
  let lang: CourseLanguageDto | null = null;
  const languages = usePreloadStore(getLanguagesWithMultilingual);
  const groupTypes = usePreloadStore(getGroupTypesAsList);

  const getSelectedGroupType = () => {
    if (group) {
      return groupTypes.filter(x => x.id == group.programId)[0];
    }

    return null;
  };

  const [selectedCourse, setSelectedCourse] = useState<CourseDto | null>(getSelectedGroupType());

  if (group && group.languageId) {
    lang = languages.filter(x => x.id == group.languageId)[0];
  }

  const intl = useIntl();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [languageList, setLanguageList] = useState<CourseLanguageDto[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<CourseLanguageDto | null>(lang);
  const [courses, setCourses] = useState<CourseDto[] | null>(null);

  useEffect(() => {
    if (languageList.length == 0) {
      setLanguageList(languages);
    }
  }, []);

  useEffect(() => {
    //Call function to get courses.
    const getCourses = async () => {
      setLoading(true);
      const courseProviders = await GetCoursesByLanguage(selectedLanguage!.name);

      if (courseProviders!.responseType === ServerResponseType.Success) {
        const courses = CourseProviderGroupDto.mapToCourses(courseProviders.data);

        setCourses(courses);
      }

      setLoading(false);
    };

    if (selectedLanguage) {
      getCourses();
    }
  }, [selectedLanguage]);

  const handleLanguageChange = (event, newLang: any) => {
    const lang = newLang as CourseLanguageDto;
    setSelectedCourse(null);
    formStore.dropProgramAndLanguage();
    setSelectedLanguage(lang);
    formStore.setLanguage(lang.id!);
  };

  const handleCourseChange = (event, newCourse: CourseDto | null) => {
    setSelectedCourse(newCourse ?? null);

    formStatus.dirtiness.setDirty(true, "course");

    setFormStatus(
      new FormStatus(formStatus)
    );

    if (isCourseValid(newCourse ?? undefined)) {
      formStore.setProgram(newCourse!.providerId!, newCourse!.id!, newCourse!.name!);
    }
    else {
      formStore.dropProgramAndLanguage();
    }
  };

  const isCourseValid = (course?: CourseDto) => {
    let isValid = true;

    const invalidCourseErrorMessage = intl.formatMessage({ id: "invalid_course_selection", defaultMessage: "Invalid course selection" });

    if (!course || !course.id) {
      isValid = false;
      formStatus.errors.addMessage(invalidCourseErrorMessage, "course");
    }
    else {
      formStatus.errors.removeMessage(invalidCourseErrorMessage, "course");
    }

    formStatus.validity.setValidity(isValid, "course");

    setFormStatus(
      new FormStatus(formStatus)
    );

    return isValid;
  };

  const courseHasErrors = (
    !formStatus.validity.isValid("course")
    && (
      formStatus.saveState === SaveState.ValidationFailed
      || formStatus.dirtiness.isDirty("course")
    )
  );

  const renderLanguageAutoComplete = () => {
    return (
      <div className="languageSelector">
        {!isLoading && (
          <Autocomplete
            getOptionLabel={(option) => option.description}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={languageList}
            disableClearable={selectedLanguage != null}
            disabled={formStatus.recordType === RecordType.Existing}
            value={selectedLanguage}
            onChange={handleLanguageChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <FormattedMessage id="language_select" defaultMessage="Select a Language" />
                }
              />
            )}
          />)
        }
      </div>
    );
  };

  const hasLanguageBeenSet = group?.languageId !== null && group?.languageId !== undefined;

  const renderCourseAutoComplete = () => {
    return (
      <>
        {!isLoading && hasLanguageBeenSet &&
          <Autocomplete
            onChange={handleCourseChange}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={courses ?? []}
            disableClearable={selectedCourse != null}
            disabled={formStatus.recordType === RecordType.Existing}
            value={selectedCourse}
            renderInput={(params) => (
              <TextField
                {...params}
                label={<FormattedMessage id="group_type" defaultMessage="Group Type" />}
                error={courseHasErrors}
                helperText={
                  courseHasErrors
                    ? formStatus.errors.getMessages("course").map(
                      (message, index) => (
                        <span key={`course-picker-autocomplete-message-${index}`}>
                          {message}
                        </span>
                      ))
                    : formStatus.messages.getMessages("course").map(
                      (message, index) => (
                        <span key={`course-picker-autocomplete-message-${index}`}>
                          {message}
                        </span>
                      ))
                }
              />)
            }
          />
        }
      </>
    );
  };

  return (
    <div className={`courseLanguageSelectorContainer ${props.className}`}>
      <Loading loading={isLoading} small={true} />
      {!isLoading && !group?.programId &&
        <p className="helperText"><FormattedMessage id="select_group_type" defaultMessage="Please select the type of group you want to set up." /></p>
      }
      <div className="selectContainer">
        {renderLanguageAutoComplete()}
        {renderCourseAutoComplete()}
      </div>
    </div>
  );
};

export default GroupLanguageSelector;
