import React, { useEffect, useState } from "react";
import { FormStatus } from "lib/form/model";
import { defaultState, FormStatusContext } from "./FormStatusContext";

interface ProviderProps<T> {
  value?: T;
  onChange?: (newValue: T) => void;
}

export const FormStatusProvider: React.FC<ProviderProps<FormStatus>> = (props) => {
  const [formStatus, setFormStatus] = useState(props.value ?? defaultState.formStatus);

  useEffect(
    () => {
      if (props.value) {
        handleFormStatus(props.value);
      }
      else {
        const resetFormStatus = () => {
          setFormStatus(defaultState.formStatus);
        };

        return resetFormStatus();
      }
    },
    [props.value]
  );

  const handleFormStatus = (newValue: FormStatus) => {
    setFormStatus(newValue);

    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  return (
    <FormStatusContext.Provider
      value={{
        formStatus: formStatus,
        setFormStatus: handleFormStatus
      }}
    >
      {props.children}
    </FormStatusContext.Provider>
  );
};