import { ReactElement, useState, useRef } from "react";
import StatusBox from "components/status-box/status-box";
import WarningIcon from "assets/images/icons/warning.svg";
import ErrorIcon from "assets/images/icons/error.svg";
import SuccessIcon from "assets/images/icons/success.svg";
import InfoIcon from "assets/images/icons/info.svg";
import { useSelector } from "react-redux";
import Layouts from "constants/layout-constants";
import nextId from "react-id-generator";

import "./info-hover-box.scss";

export enum InfoHoverStatus {
  Error = "error",
  Success = "success",
  warning = "warning",
  info = "info"
}

interface IInfoHoverBox {
  status?: InfoHoverStatus,
  head?: ReactElement,
  children?: ReactElement
  disableOverflowScroll?: boolean;
}

const InfoHoverBox = (props: IInfoHoverBox) => {

  const [show, setShow] = useState<boolean>(false);
  const componentId = useRef<string>(nextId());
  const layout = useSelector((state: any) => state.appReducer.layout);
  let icon: string;

  switch (props.status) {
    case InfoHoverStatus.Error:
      icon = ErrorIcon;
      break;
    case InfoHoverStatus.Success:
      icon = SuccessIcon;
      break;
    case InfoHoverStatus.warning:
      icon = WarningIcon;
      break;
    default:
      icon = InfoIcon;
  }

  const handleClick = (): void => {
    setShow(!show);
  };

  const handleMouseEnter = (): void => {
    setShow(true);
  };

  const handleMouseLeave = (): void => {
    setShow(false);
  };

  const menuAnchorXSide = ((): string => {
    if (layout == Layouts.Mobile) {
      return "Ycenter";
    }
    const screenXCenter = window.innerWidth / 2;
    const centerBias = 25; //if the distance to the y axis is less than this, the hover box is centered over the toggle
    const element = document.getElementById(`${componentId.current}-hover-toggle`);
    if (!element) {
      return "";
    }
    const toggleLocation = element.getBoundingClientRect();
    const toggleXCenter = toggleLocation.left + (element.offsetWidth / 2);

    if (Math.abs(toggleXCenter - screenXCenter) < centerBias) {
      return "xCenter";
    }

    if (toggleXCenter < screenXCenter) {
      return "right";
    }
    return "left";
  })();

  const menuAnchorYSide = ((): string => {
    const screenYCenter = window.innerHeight / 2;
    const element = document.getElementById(`${componentId.current}-hover-toggle`);
    if (!element) {
      return "";
    }
    const toggleLocation = element.getBoundingClientRect();
    const toggleYCenter = (toggleLocation.bottom - toggleLocation.top) / 2 + toggleLocation.top;
    if (toggleYCenter < screenYCenter) {
      return "bottom";
    }
    return "top";
  })();

  return (
    <div className="infoHoverBoxContainer">
      <button className="icon">
        <img className="iconHoverToggle" src={icon}
          onClick={handleClick}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleMouseEnter}
          id={`${componentId.current}-hover-toggle`}
        />
      </button>
      {show &&
        <StatusBox status={props.status} head={props.head} className={`${(show) ? "show" : "hide"} ${menuAnchorXSide} ${menuAnchorYSide}`} disableOverflowScroll={props.disableOverflowScroll}>
          {props.children}
        </StatusBox>
      }
    </div>
  );

};

export default InfoHoverBox;