import "./group-manager.scss";

import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Loading from "components/loading/loading";
import GroupLanguageSelector from "./components/group-language-selector";
import LocationSelector from "./components/location-selector";
import GroupAgeTypeSelector from "./components/group-age-type-selector";
import StartEndDateSelector from "./components/start-end-date-selector";
import WeekdayHourSelector from "./components/weekday-hour-selector";
import FacilitatorSelector from "./components/facilitator-selector";
import CapacitySelector from "./components/capacity-selector";
import OptionsSelector from "./components/options-selector";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Permissions } from "constants/api-auth-constants";
import UserCan from "components/user-can/user-can";
import { GroupProvider } from "lib/data/contexts";
import { Group } from "lib/data/model";
import useClassName from "utilities/useClassName";
import { FormStatusProvider } from "lib/form/contexts";
import { FormStatus, RecordType, SaveState } from "lib/form/model";
import { useNavigate } from "react-router-dom";
import { AgeType } from "lib/data/model/enum";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";
import * as GroupsApi from "lib/api/backend/requests/groups";
import { useDispatch } from "react-redux";
import { GroupDtoToGroupConverter } from "lib/api/backend/converters/GroupDtoToGroupConverter";
import dispatchers from "datastore/dispatcher";
import { DuplicatesModal } from "./components/duplicates-modal";
import { GroupDto } from "lib/api/backend/model";
import { getFeatureFlagValue } from "lib/api/backend/requests/feature-flags";
import FeatureFlags from "constants/feature-flags";
import useGroupStore from "datastore/groups";
import usePreloadStore, { getLanguages } from "datastore/preload";

export const getEmptyGroup = () =>
  new Group({
    courseOutcomes: [],
    duration: 0,
    facilitators: [],
    groupAgeType: AgeType.Combined,
    hasCompletionCertificate: false,
    hasParticipationCertificate: false,
    isPending: false,
    isHidden: false,
    hasAcceptedYouthTerms: false,
    lessonTime: 0,
    meetingTimes: [],
    name: "",
    numberOfLessons: 0,
    programLanguages: [],
    status: "",
    students: [],
    wsrsOutcomes: [],
    groupLanguages: []
  });

enum SaveResults {
  Success,
  Error
}

interface GroupManagerProps {
  className?: string;
  ids?: {
    locationId: number;
    programId: number;
    groupId: number;
  };
  copy?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
}

const GroupManager: React.VFC<GroupManagerProps> = (props) => {
  const [className, addPropClassNames] = useClassName(
    "groupManagerContainer",
    "hasLoader"
  );
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const store = useGroupStore();
  const allLanguages = usePreloadStore(getLanguages);

  const [formStatus, setFormStatus] = useState<FormStatus>(new FormStatus());

  const [group, setGroup] = useState<Group>();
  const [duplicateGroups, setDuplicateGroups] = useState<GroupDto[]>([]);

  const [providerId, setProviderId] = useState<number>();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [showYouthTermsDialog, setShowYouthTermsDialog] = useState(false);
  const shouldUpdate = props.ids && !props.copy;
  const [shouldSave, setShouldSave] = useState<boolean>(false);
  const notify = dispatchers(reduxDispatch).notify;

  const intl = useIntl();

  useEffect(() => {
    addPropClassNames(props.className ?? "");
  }, [props.className]);

  useEffect(() => {

    const handleGroup = async () => {
      setLoading(true);

      if (props.ids) {
        const existingGroupDto = await GroupsApi.getGroupWithProtectedData(
          props.ids!.locationId,
          props.ids!.programId,
          props.ids!.groupId
        );

        //TODO: THIS CONVERTER WILL NOT BE NECESSARY WHEN THE ENDPOINTS ARE CHANGED TO RETURN THE FULL GROUP TYPE INSTEAD
        //group and setGroup should be of type FullGroup when this happens.
        const converter = new GroupDtoToGroupConverter();

        setGroup(converter.toModel(existingGroupDto));
        setFormStatus(
          new FormStatus({
            ...formStatus,
            recordType: RecordType.Existing,
          })
        );
      } else {
        setGroup(getEmptyGroup());
        setFormStatus(
          new FormStatus({
            ...formStatus,
            recordType: RecordType.New,
          })
        );
      }

      setLoading(false);
    };

    handleGroup();
  }, []);

  useEffect(() => {
    const trySaving = async () => {
      if (shouldSave) {
        await handleSave();
      }
    };

    trySaving();
  }, [shouldSave]);

  const handleCourseChange = (newProviderId: number) => {
    if (newProviderId !== providerId) {
      setProviderId(providerId);
    }
  };

  const handleYouthTermsAcceptedChange = (areAccepted: boolean) => {
    setShowYouthTermsDialog(false);

    if (isYouthTermsAcceptanceValid(areAccepted)) {
      setGroup(
        new Group({
          ...group!,
          hasAcceptedYouthTerms: areAccepted,
        })
      );
      setShouldSave(true);
    }
  };

  const isYouthTermsAcceptanceValid = (areAccepted: boolean, overrideFormStatus?: FormStatus) => {
    let isValid = true;
    if (group?.groupAgeType !== AgeType.Adult && !areAccepted) {
      isValid = false;
    }
    const myFormStatus = overrideFormStatus || formStatus;
    myFormStatus.validity.setValidity(isValid, "youth", "terms");
    setFormStatus(myFormStatus);
    return isValid;
  };

  const attemptSave = async (group: Group) => {
    try {
      const shouldUpdate = props.ids && !props.copy;

      if (shouldUpdate) {
        const updatedGroup = await GroupsApi.update(
          props.ids!.locationId,
          props.ids!.programId,
          props.ids!.groupId,
          group
        );

        const v2Group = updatedGroup.convertToFullGroup(allLanguages);

        store.editGroup(v2Group);

        return SaveResults.Success;
      }

      const createdGroup: Group = await GroupsApi.create(group);

      //Convert Group to FullGroup
      const v2Group = createdGroup.convertToFullGroup(allLanguages);
      store.addGroup(v2Group);

      return SaveResults.Success;
    }
    catch (e) {
      console.error(e);
      notify({
        status: "error",
        message: intl.formatMessage({
          id: "group_save_error",
          defaultMessage:
            "An error occurred while attempting to save the group.",
        }),
      });
      setLoading(false);

      formStatus.errors.addMessage(
        intl.formatMessage({
          id: "group_save_error",
          defaultMessage:
            "An error occurred while attempting to save the group.",
        })
      );

      setFormStatus(
        new FormStatus({
          ...formStatus,
          saveState: SaveState.Error,
        })
      );

      return SaveResults.Error;
    }
  };

  const handleSave = async (skipDuplicateCheck = false) => {

    if (!group) {
      setFormStatus(
        new FormStatus({
          ...formStatus,
          saveState: SaveState.Error,
        })
      );
      return;
    }

    if (!formStatus.validity.isValid()) {
      const invalids = formStatus.validity.getInvalid();
      //make sure youth terms isn't the only thing invalid
      if (!(invalids.size == 1 && invalids.has("youth.terms"))) {
        setFormStatus(
          new FormStatus({
            ...formStatus,
            saveState: SaveState.ValidationFailed,
          })
        );
        return;
      }
    }

    const youthTermsValid = isYouthTermsAcceptanceValid(group.hasAcceptedYouthTerms);

    if (!youthTermsValid) {
      setShowYouthTermsDialog(true);
      return;
    }

    setFormStatus(
      new FormStatus({
        ...formStatus,
        saveState: SaveState.Saving,
      })
    );
    setLoading(true);

    if (!group.isPending) {
      const duplicateCheckFeatureFlag = await getFeatureFlagValue(FeatureFlags.DuplicateGroupCheck);
      if (duplicateCheckFeatureFlag.enabled && !skipDuplicateCheck) {
        const duplicates = await GroupsApi.CheckForDuplicateGroups(group, shouldUpdate);
        if (duplicates?.length > 0) {
          setFormStatus(
            new FormStatus({
              ...formStatus,
              saveState: SaveState.None,
            })
          );
          setLoading(false);
          setDuplicateGroups(duplicates);
          return;
        }
      }
    }

    const result = await attemptSave(group);

    if (result === SaveResults.Success) {
      setFormStatus(
        new FormStatus({
          ...formStatus,
          saveState: SaveState.Complete,
        })
      );

      if (props.onSave) {
        setGroup(getEmptyGroup());
        setFormStatus(new FormStatus());
        props.onSave();
      }
      else {
        navigate("/groups");
      }

      setLoading(false);
      notify({
        status: "success",
        message: intl.formatMessage({
          id: "group_saved",
          defaultMessage: "Group Saved",
        }),
      });

      setShouldSave(false);
    }
  };

  const handleDuplicateDecision = (shouldContinue: boolean) => {
    setDuplicateGroups([]);
    if (shouldContinue) {
      handleSave(true);
    }
  };

  const handleCancel = () => {
    if (props.onCancel) {
      setGroup(getEmptyGroup());
      setFormStatus(new FormStatus());
      props.onCancel();
    } else {
      navigate("/groups");
    }
  };

  const defaultYouthTerms = "As a leader, I can allow youth to participate in groups with the following:\n\n1. there are two facilitators; and\n2. they have parental permission (this is already a requirement for anyone under 18).\n\nIn addition, no facilitator should text/email/contact a minor without another person on the chain.";

  ////////////////////
  // --- RENDER --- //
  ////////////////////
  if (!group) {
    return null;
  }

  return (
    <UserCan
      perform={
        formStatus.recordType === RecordType.Existing
          ? [Permissions.EditGroupWrite]
          : [Permissions.NewGroupWrite]
      }
      unitNumber={
        formStatus.recordType === RecordType.Existing
          ? group.stakeUnitNumber
          : null
      }
      showFail={true}
    >
      <FormStatusProvider value={formStatus}>
        <GroupProvider value={group} onChange={setGroup}>
          <div className={className}>
            <Loading loading={isLoading} centered={true} />
            <GroupLanguageSelector
              className="courseSelector"
              onChange={handleCourseChange}
            />
            <hr />
            {group?.course && (
              <>
                <LocationSelector
                  className="locationSelector"
                  location={group.location}
                />

                <hr />
                {/*Anything below shouldn't display until a location is selected*/}
                {group?.location && group.location.address && (
                  <>
                    <GroupAgeTypeSelector ageType={group.groupAgeType} />
                    <StartEndDateSelector
                      className="startEndSelector"
                      startDate={group.startDate}
                      endDate={group.endDate}
                    />
                    <WeekdayHourSelector
                      className="weekdayHourSelector"
                      meetingTimes={group.meetingTimes}
                    />

                    <FacilitatorSelector facilitators={group.facilitators} />

                    <CapacitySelector
                      className="capacitySelector"
                      capacity={group.maxEnrollment}
                    />
                    <OptionsSelector
                      isPending={group.isPending}
                      isHidden={group.isHidden}
                    />
                  </>
                )}
              </>
            )}
            <div className="bottomRow">
              <div className="validationErrors">
                {!formStatus.validity.isValid() &&
                  formStatus.errors.getMessages().length > 0 && // Unkeyed Messages Only
                  Array.from(formStatus.errors.getMessages()).map(
                    (error, index) => (
                      <p className="error" key={index}>
                        {error}
                      </p>
                    )
                  )}
              </div>

              <Stack spacing={2} direction="row">
                <Button variant="text" onClick={handleCancel}>
                  <FormattedMessage id="cancel" defaultMessage="Cancel" />
                </Button>
                <Button variant="contained" onClick={() => handleSave()}>
                  <FormattedMessage id="save" defaultMessage="Save" />
                </Button>
              </Stack>
            </div>

            <button className="closePopUpButton"></button>
          </div>
          <DuplicatesModal duplicateGroups={duplicateGroups} open={duplicateGroups?.length > 0} onDecision={handleDuplicateDecision} editing={!!(props.ids && !props.copy)} />
          <Dialog className="terms-dialog" open={showYouthTermsDialog}>
            <DialogTitle>
              <div className="terms-dialog-title">
                <InfoIcon fontSize="medium" />
                <h4>
                  <FormattedMessage
                    id="youth_group_rules"
                    defaultMessage="Youth Group Rules"
                  />
                </h4>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="terms-dialog-content">
                <FormattedMessage
                  id="youth_terms"
                  defaultMessage={defaultYouthTerms}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() => handleYouthTermsAcceptedChange(false)}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                variant="contained"
                onClick={() => handleYouthTermsAcceptedChange(true)}
              >
                <FormattedMessage id="agree" defaultMessage="Agree" />
              </Button>
            </DialogActions>
          </Dialog>
        </GroupProvider>
      </FormStatusProvider>
    </UserCan>
  );
};

export default GroupManager;
