import { ReactElement, useContext, useEffect } from "react";
import Loading from "components/loading/loading";
import { useStore } from "zustand";
import { MapsLoaderContext } from "./store";
import usePrevious from "utilities/usePrevious";

interface IMapsLoader {
  children: ReactElement | ReactElement[];
  onReady?: () => void;
}

export const MapsLoader = (props: IMapsLoader): ReactElement => {

  const mapsLoaderStore = useContext(MapsLoaderContext);
  const ready = useStore(mapsLoaderStore!, s => s.ready);
  const oldReady = usePrevious(ready);

  useEffect(() => {
    if (props.onReady && ready && !oldReady) {
      props.onReady();
    }
  }, [ready]);

  return ready ?
    (
      <>
        {props.children}
      </>
    )
    :
    (
      <div className="mapsLoader">
        <Loading loading={true} />
      </div>
    );

};

export default MapsLoader;