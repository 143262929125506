
import { ReactElement } from "react";
import { redirectToServerSignOut } from "utilities/navUtils";

const SignOut = (): ReactElement => {

  redirectToServerSignOut({ returnUrl: window.location.origin });

  return (
    <div className="signOutPageContainer">
    </div>
  );
};

export default SignOut;