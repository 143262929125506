import "./header.scss";

import React, { useEffect, useState } from "react";
import { TableCell, TableSortLabel } from "@mui/material";
import useClassName from "utilities/useClassName";

const Header = (props) => {

  const [className, setPropClassName] = useClassName("cell header-cell sortable-header-cell");

  useEffect(
    () => {
      setPropClassName(props.className ?? "");
    },
    [props.className]
  );

  const [isSorted, setIsSorted] = useState(false);
  const [sortDirection, setSortDirection] = useState(props.sortDirection || "asc");

  useEffect(
    () => {
      const handleSortingChange = (isSorted) => {
        setIsSorted(isSorted);
        setSortDirection(props.sortDirection || "asc");
      };

      handleSortingChange(props.isSorted);
    },
    [props.isSorted]
  );

  const handleClick = () => {
    const newDirection = sortDirection == "asc" ? "desc" : "asc";

    setIsSorted(true);
    setSortDirection(newDirection);

    if (props.onClick) {
      props.onClick(newDirection);
    }
  };

  return (
    <TableCell
      className={className}
      align={props.align ?? "left"}
    >
      <div
        className="clickable-header-cell"
        onClick={handleClick}
      >
        <span>{props.name}</span>
        <TableSortLabel
          active={isSorted && sortDirection !== null}
          direction={sortDirection || "asc"}
        />
      </div>
    </TableCell>
  );
};

export default Header;