import StudentEnrollmentCourseDto from "./StudentEnrollmentCourseDto";

export interface IStudentEnrollmentDto {
  courses: StudentEnrollmentCourseDto[]
  enrollmentId: number;
  studentId: number;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  isLimitedStudent: boolean;
  isOlderThanEighteen: boolean;
}

export default class StudentEnrollmentDto implements IStudentEnrollmentDto {

  public courses: StudentEnrollmentCourseDto[];
  public enrollmentId: number;
  public studentId: number;
  public firstName: string;
  public lastName: string;
  public email?: string;
  public phone?: string;
  public isLimitedStudent: boolean;
  public isOlderThanEighteen: boolean;

  constructor(creationValues: IStudentEnrollmentDto) {
    this.courses = creationValues.courses;
    this.enrollmentId = creationValues.enrollmentId;
    this.studentId = creationValues.studentId;
    this.firstName = creationValues.firstName;
    this.lastName = creationValues.lastName;
    this.email = creationValues.email;
    this.phone = creationValues.phone;
    this.isLimitedStudent = creationValues.isLimitedStudent ?? false;
    this.isOlderThanEighteen = creationValues.isOlderThanEighteen;
  }

  public export(): IStudentEnrollmentDto {
    return {
      courses: this.courses,
      enrollmentId: this.enrollmentId,
      studentId: this.studentId,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      isLimitedStudent: this.isLimitedStudent,
      isOlderThanEighteen: this.isOlderThanEighteen
    };
  }

  public clone(): StudentEnrollmentDto {
    return new StudentEnrollmentDto(this.export());
  }
}