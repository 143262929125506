import React from "react";
import {mapStateToProps, mapDispatchToProps} from "datastore/store";
import {connect} from "react-redux";
import Unauthorized from "error-handlers/unauthorized/unauthorized";
import { Navigate } from "react-router-dom";
import AuthorizeService from "auth/authorize-service";

const UserCan = (props) => {

  const { perform, showFail, redirectOnFail } = props;
  const unitNumber = props.unitNumber || null;
  const can = perform ? AuthorizeService.userCan(perform, unitNumber) : false;
  const redirect = (redirectOnFail && !can ? "/unauthorized" : false);

  return(
    <>
      {redirect &&
        <Navigate to={redirect} />
      }
      {can &&
        <>
          {props.children}
        </>
      }
      {!can && showFail &&
        <Unauthorized />
      }
    </>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(UserCan);