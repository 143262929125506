import { ReactElement } from "react";
import { Button } from "@mui/material";
import { FormattedMessage } from "react-intl";
import "./move-participant-form-controls.scss";

interface IMoveParticipantFormControlsProps {
  saveDisabled: boolean,
  onSave: () => void,
  onCancel: () => void;
}

export const MoveParticipantFormControls = (props: IMoveParticipantFormControlsProps): ReactElement => {
  return (
    <div className="moveParticipantFormControlsContainer">
      <Button variant="contained" disabled={props.saveDisabled} onClick={props.onSave}><FormattedMessage id="save" defaultMessage="Save" /></Button>
      <Button onClick={props.onCancel}><FormattedMessage id="cancel" defaultMessage="Cancel" /></Button>
    </div>
  );
};

export default MoveParticipantFormControls;