import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import Loading from "components/loading/loading";
import InfoHoverBox, { InfoHoverStatus } from "components/info-hover-box/info-hover-box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { StakeMemberTypeAhead } from "./components";
import useClassName from "utilities/useClassName";
import { Group, GuestFacilitator, StandardFacilitator } from "lib/data/model";
import * as StakeMembersApi from "lib/api/backend/requests/stake";
import StakeMemberDto from "lib/api/backend/model/StakeMemberDto";
import { useFormStatus } from "lib/form/contexts";
import { useGroup } from "lib/data/contexts";
import { FormStatus, SaveState } from "lib/form/model";
import { ServerResponseType } from "lib/api/backend/request-utils";
import Requests from "api/requests";
import "./facilitator-selector.scss";

const FacilitatorSelectorErrorKey = "facilitators";
interface IFacilitatorSelectorProps {
  className?: string;
  facilitators?: (StandardFacilitator | GuestFacilitator)[];
}

type FacilitatorOptions = StandardFacilitator | GuestFacilitator | undefined;

const FacilitatorSelector: React.VFC<IFacilitatorSelectorProps> = (props) => {
  const [className, setPropsClassNames, addClassNames, removeClassNames] = useClassName("facilitatorListContainer");
  const language = useSelector((state: any) => state.appReducer.language);
  const [formStatus, setFormStatus] = useFormStatus();
  const [group, setGroup] = useGroup();

  const [unitNumber, setUnitNumber] = useState<number>();
  const [isRetryVisible, setIsRetryVisible] = useState<boolean>(false);
  const [facilitators, setFacilitators] = useState<FacilitatorOptions[]>([]);
  const [potentialFacilitators, setPotentialFacilitators] = useState<StakeMemberDto[]>([]);
  const [availablePotentialFacilitators, setAvailablePotentialFacilitators] = useState<StakeMemberDto[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [checkingChurchAccount, setCheckingChurchAccount] = useState<boolean>(false);
  const intl = useIntl();

  useEffect(() => {
    if (facilitators.length <= 0) {
      addClassNames("right");
    } else {
      removeClassNames("right");
    }
    areFacilitatorsValid(facilitators);
  }, [facilitators]);

  useEffect(() => {
    if (group?.location?.stakeUnitNumber !== unitNumber) {
      //clear facilitators since the existing facilitators won't be in the new unit
      const newGroup = new Group({
        ...group!,
        facilitators: [],
      });
      setGroup(newGroup);
      setFacilitators([]);
      setUnitNumber(group?.location?.stakeUnitNumber);
      
    }
  }, [group?.location]);

  useEffect(() => {
    getMembersRequest();
  }, [unitNumber]);

  useEffect(() => {
    if (props.className) {
      setPropsClassNames(props.className);
    } else {
      setPropsClassNames();
    }
  }, [props.className]);

  useEffect(() => {
    areFacilitatorsValid(facilitators);
  }, [language]);

  const isGuestFacilitatorValid = (
    recordKey: number,
    facilitator: GuestFacilitator
  ) => {

    let isValid = true;

    const invalidNameErrorMessage = intl.formatMessage({
      id: "facilitator_has_invalid_name",
      defaultMessage: "Facilitator must have a name.",
    });
    const duplicateFacilitatorErrorMessage = intl.formatMessage({
      id: "facilitator_is_duplicate",
      defaultMessage: "This facilitator is a duplicate.",
    });

    formStatus.errors.removeMessage(
      invalidNameErrorMessage,
      FacilitatorSelectorErrorKey,
      `${recordKey}`
    );

    formStatus.errors.removeMessage(
      duplicateFacilitatorErrorMessage,
      FacilitatorSelectorErrorKey,
      `${recordKey}`
    );

    const facilitatorName = facilitator.name?.trim();

    if (!facilitatorName) {
      isValid = false;

      formStatus.errors.addMessage(
        invalidNameErrorMessage,
        FacilitatorSelectorErrorKey,
        `${recordKey}`
      );
    }

    const hasDuplicates = ((): boolean => {
      const otherFacilitators = [
        ...facilitators.slice(0, recordKey),
        ...facilitators.slice(recordKey + 1),
      ];

      return !!otherFacilitators.find((otherFacilitator) => {
        if (otherFacilitator instanceof GuestFacilitator) {
          return otherFacilitator.name.trim().toLowerCase() === facilitatorName.toLowerCase();
        } else if (otherFacilitator instanceof StandardFacilitator) {
          const stakeMember = potentialFacilitators.find(sm => sm.id == otherFacilitator.cmisId);
          return `${otherFacilitator?.firstName} ${otherFacilitator?.lastName}`.toLowerCase() === facilitatorName.toLowerCase() ||
            (stakeMember && stakeMember.displayName.toLowerCase() == facilitatorName.toLowerCase());
        }
      });
    })();


    if (isValid && hasDuplicates) {
      isValid = false;
      formStatus.errors.addMessage(
        duplicateFacilitatorErrorMessage,
        FacilitatorSelectorErrorKey,
        `${recordKey}`
      );
    }

    formStatus.validity.setValidity(isValid, FacilitatorSelectorErrorKey, `${recordKey}`);

    return isValid;
  };

  const isFullFacilitatorValid = (
    recordKey: number,
    facilitator: StandardFacilitator
  ) => {
    let isValid = true;

    const invalidIdErrorMessage = intl.formatMessage({
      id: "facilitator_has_invalid_id",
      defaultMessage: "Facilitator must have a valid id.",
    });
    const duplicateFacilitatorErrorMessage = intl.formatMessage({
      id: "facilitator_is_duplicate",
      defaultMessage: "This facilitator is a duplicate.",
    });

    formStatus.errors.removeMessage(
      invalidIdErrorMessage,
      FacilitatorSelectorErrorKey,
      `${recordKey}`
    );
    formStatus.errors.removeMessage(
      duplicateFacilitatorErrorMessage,
      FacilitatorSelectorErrorKey,
      `${recordKey}`
    );

    if (!facilitator.cmisId || facilitator.cmisId <= 0) {
      isValid = false;

      formStatus.errors.addMessage(
        invalidIdErrorMessage,
        FacilitatorSelectorErrorKey,
        `${recordKey}`
      );
    }

    const hasDuplicates = ((): boolean => {
      const facilitatorName = `${facilitator?.firstName} ${facilitator?.lastName}`.toLowerCase();
      const otherFacilitators = [
        ...facilitators.slice(0, recordKey),
        ...facilitators.slice(recordKey + 1),
      ];

      return !!otherFacilitators.find((otherFacilitator) => {
        if (otherFacilitator instanceof GuestFacilitator) {
          const stakeMember = potentialFacilitators.find(sm => sm.id == facilitator.cmisId);
          return otherFacilitator.name.trim().toLowerCase() === facilitatorName.toLowerCase() ||
            (stakeMember && stakeMember.displayName.toLowerCase() == otherFacilitator.name.toLowerCase());
        } else {
          return (
            otherFacilitator?.cmisId === facilitator.cmisId ||
            `${otherFacilitator?.firstName} ${otherFacilitator?.lastName}`.toLowerCase() === facilitatorName.toLocaleLowerCase()
          );
        }
      });
    })();

    if (hasDuplicates) {
      isValid = false;
      formStatus.errors.addMessage(
        duplicateFacilitatorErrorMessage,
        FacilitatorSelectorErrorKey,
        `${recordKey}`
      );
    }

    formStatus.validity.setValidity(isValid, FacilitatorSelectorErrorKey, `${recordKey}`);

    return isValid;
  };

  const isFacilitatorValid = (
    recordKey: number,
    facilitator: StandardFacilitator | GuestFacilitator
  ) => {
    let isValid = true;

    const facilitatorIsGuestMessage = intl.formatMessage({
      id: "facilitator_is_guest",
      defaultMessage:
        "This facilitator does not have a Church Account with a membership record number, and will not be able to see group information.",
    });

    if (facilitator instanceof StandardFacilitator) {
      formStatus.messages.removeMessage(
        facilitatorIsGuestMessage,
        FacilitatorSelectorErrorKey,
        `${recordKey}`
      );

      isValid = isFullFacilitatorValid(recordKey, facilitator);
    } else if (facilitator instanceof GuestFacilitator) {
      isValid = isGuestFacilitatorValid(recordKey, facilitator);

      if (isValid) {
        formStatus.messages.addMessage(
          facilitatorIsGuestMessage,
          FacilitatorSelectorErrorKey,
          `${recordKey}`
        );
      }
    }

    setFormStatus(new FormStatus(formStatus));

    return isValid;
  };

  const getMembersRequest = async () => {
    setLoading(true);
    setIsRetryVisible(false);

    if (unitNumber) {

      const stakeMembers = await (async () => {
        const response = await StakeMembersApi.get(unitNumber);
        if (response.responseType === ServerResponseType.Success) {
          setPotentialFacilitators([...response.data]);

          const newAvailableStakeMembers = [...response.data];
          group?.facilitators.forEach(facilitator => {
            if (facilitator.id) {
              const newAvailableStakeMembersIndex = newAvailableStakeMembers.findIndex(newFacilitator => newFacilitator.id == facilitator.id);
              if (newAvailableStakeMembersIndex != -1) {
                newAvailableStakeMembers.splice(newAvailableStakeMembersIndex, 1);
              }
            }
          });
          return newAvailableStakeMembers;
        }
        return [];
      })();

      if (stakeMembers.length == 0) {
        setIsRetryVisible(true);
        return;
      }

      const newPotentialFacilitators: StakeMemberDto[] = [...stakeMembers];

      group?.facilitators.forEach(facilitator => {
        if (facilitator.id) {
          const newPotentialFacilitatorIndex = newPotentialFacilitators.findIndex(newFacilitator => newFacilitator.id == facilitator.id);
          if (newPotentialFacilitatorIndex != -1) {
            newPotentialFacilitators.splice(newPotentialFacilitatorIndex, 1);
          }
        }
      });
      setAvailablePotentialFacilitators(newPotentialFacilitators);
    }
    if (group?.facilitators && group.id) {
      setFacilitators(group.facilitators);
    }
    setLoading(false);
  };

  const handleFacilitatorAdd = () => {
    setFacilitators([...facilitators, new GuestFacilitator({ name: "" })]);
  };

  const handleFacilitatorClear = (recordKey: number, facilitator: StandardFacilitator | GuestFacilitator) => {
    if (facilitator) {
      makeFacilitatorAvailable(facilitator);
      formStatus.errors.clearMessages(
        FacilitatorSelectorErrorKey,
        `${recordKey}`
      );
      formStatus.messages.clearMessages(
        FacilitatorSelectorErrorKey,
        `${recordKey}`
      );
      formStatus.dirtiness.reset(FacilitatorSelectorErrorKey, `${recordKey}`);
      formStatus.validity.reset(FacilitatorSelectorErrorKey, `${recordKey}`);

      setFormStatus(new FormStatus(formStatus));

      const newFacilitators = [...facilitators];
      newFacilitators[recordKey] = new GuestFacilitator({ id: undefined, name: "" });
      setFacilitators(newFacilitators);
      let newGroupFacilitators = newFacilitators.filter((f) => !!f);
      newGroupFacilitators = newGroupFacilitators.map(facilitator => {
        if (facilitator instanceof GuestFacilitator && facilitator.id) {
          return new GuestFacilitator({
            name: facilitator.name
          });
        }

        return facilitator;
      });

      const newGroup = new Group({
        ...group!,
        facilitators: newGroupFacilitators as (
          | StandardFacilitator
          | GuestFacilitator
        )[],
      });
      setGroup(newGroup);

    }

  };

  const handleFacilitatorRemove = (recordKey: number, facilitator: StandardFacilitator | GuestFacilitator) => {
    if (facilitator) {
      makeFacilitatorAvailable(facilitator);
      const newFacilitators = [...facilitators];
      newFacilitators.splice(recordKey, 1);
      setFacilitators(newFacilitators);
      const oldFacilitatorsLastIndex =
        facilitators.length > 0 ? facilitators.length - 1 : 0;

      formStatus.errors.clearMessages(
        FacilitatorSelectorErrorKey,
        `${oldFacilitatorsLastIndex}`
      );
      formStatus.messages.clearMessages(
        FacilitatorSelectorErrorKey,
        `${oldFacilitatorsLastIndex}`
      );
      formStatus.dirtiness.reset(FacilitatorSelectorErrorKey, `${oldFacilitatorsLastIndex}`);
      formStatus.validity.reset(FacilitatorSelectorErrorKey, `${oldFacilitatorsLastIndex}`);

      setFormStatus(new FormStatus(formStatus));

      if (areFacilitatorsValid(newFacilitators)) {

        let newGroupFacilitators = newFacilitators.filter((f) => !!f);
        newGroupFacilitators = newGroupFacilitators.map(facilitator => {
          if (facilitator instanceof GuestFacilitator && facilitator.id) {
            return new GuestFacilitator({
              name: facilitator.name
            });
          }
          return facilitator;
        });

        const newGroup = new Group({
          ...group!,
          facilitators: newGroupFacilitators as (
            | StandardFacilitator
            | GuestFacilitator
          )[],
        });
        setGroup(newGroup);
      }
    }
  };

  const handleFacilitatorChange = async (recordKey: number, newFacilitator: StandardFacilitator | GuestFacilitator) => {

    const oldFacilitator = facilitators.at(recordKey);

    const standardFacilitatorIsGuestWarningMessage = intl.formatMessage({
      id: "facilitator_is_guest",
      defaultMessage: "This facilitator does not have a Church Account with a membership record number, and will not be able to see group information.",
    });

    formStatus.messages.removeMessage(
      standardFacilitatorIsGuestWarningMessage,
      FacilitatorSelectorErrorKey,
      `${recordKey}`
    );

    if (oldFacilitator instanceof StandardFacilitator && oldFacilitator.cmisId) {
      makeFacilitatorAvailable(oldFacilitator);
    }

    if (newFacilitator instanceof StandardFacilitator) {
      makeFacilitatorUnavailable(newFacilitator);
      setCheckingChurchAccount(true);
      const hasAccount = await Requests.getStakeMemberHasChurchAccount(
        newFacilitator.cmisId
      );

      if (!hasAccount) {
        newFacilitator = new GuestFacilitator({
          name: `${newFacilitator.firstName?.trim()} ${newFacilitator.lastName?.trim()}`.trim(),
          id: newFacilitator?.id
        });

        formStatus.messages.addMessage(
          standardFacilitatorIsGuestWarningMessage,
          FacilitatorSelectorErrorKey,
          `${recordKey}`
        );
      }

      setCheckingChurchAccount(false);
    }

    const newFacilitators = [...facilitators];
    newFacilitators[recordKey] = newFacilitator;
    setFacilitators(newFacilitators);

    formStatus.dirtiness.setDirty(true, FacilitatorSelectorErrorKey, `${recordKey}`);

    setFormStatus(new FormStatus(formStatus));

    if (
      isFacilitatorValid(recordKey, newFacilitator) &&
      areFacilitatorsValid(newFacilitators)
    ) {
      let newGroupFacilitators = newFacilitators.filter((f) => !!f);
      newGroupFacilitators = newGroupFacilitators.map(facilitator => {
        if (facilitator instanceof GuestFacilitator && facilitator.id) {
          return new GuestFacilitator({
            name: facilitator.name
          });
        }
        return facilitator;
      });

      const myGroup = new Group({
        ...group!,
        facilitators: newGroupFacilitators as (
          | StandardFacilitator
          | GuestFacilitator
        )[],
      });
      setGroup(myGroup);
    }

  };

  const areFacilitatorsValid = (
    facilitators: (FacilitatorOptions)[],
  ) => {

    clearValidationMessages();

    // (Re-)validate all facilitators
    facilitators.forEach((facilitator, index) => {
      if (facilitator) {
        isFacilitatorValid(index, facilitator);
      }
    });

    let areValid = true;

    const noStandardFacilitatorsErrorMessage = intl.formatMessage({
      id: "need-member-facilitator-explanation",
      defaultMessage:
        "There needs to be at least one facilitator with an Church Account so that they can login to get the group roster.  Plus, there needs to be at least one Church member present during the group meetings.",
    });

    if (
      facilitators.length > 0 &&
      !facilitators.find((f) => f instanceof StandardFacilitator)
    ) {
      areValid = false;

      formStatus.errors.addMessage(
        noStandardFacilitatorsErrorMessage,
        FacilitatorSelectorErrorKey
      );
      formStatus.validity.setValidity(areValid, FacilitatorSelectorErrorKey);
    } else {
      formStatus.errors.removeMessage(
        noStandardFacilitatorsErrorMessage,
        FacilitatorSelectorErrorKey
      );
      formStatus.validity.setValidity(areValid, FacilitatorSelectorErrorKey);
    }

    setFormStatus(new FormStatus(formStatus));

    return areValid;
  };

  const clearValidationMessages = () => {
    facilitators.forEach((_, index) => {
      formStatus.errors.clearMessages(FacilitatorSelectorErrorKey, `${index}`);
    });
  };

  const makeFacilitatorUnavailable = (facilitator: StandardFacilitator | GuestFacilitator): void => {
    const isStandard = facilitator instanceof StandardFacilitator;
    const availableStakeMemberIndex = isStandard ?
      availablePotentialFacilitators.findIndex((stakeMember) => stakeMember.id === facilitator.cmisId)
      :
      availablePotentialFacilitators.findIndex((stakeMember) => stakeMember.id === facilitator.id);
    if (availableStakeMemberIndex !== -1) {
      const newAvailableStakeMembers = [...availablePotentialFacilitators];
      newAvailableStakeMembers.splice(availableStakeMemberIndex, 1);
      setAvailablePotentialFacilitators(newAvailableStakeMembers);
    }
  };

  const makeFacilitatorAvailable = (facilitator: StandardFacilitator | GuestFacilitator): void => {
    const isStandard = facilitator instanceof StandardFacilitator;
    const availableStakeMemberIndex = isStandard ?
      availablePotentialFacilitators.findIndex((stakeMember) => stakeMember.id === facilitator.cmisId)
      :
      availablePotentialFacilitators.findIndex((stakeMember) => stakeMember.id === facilitator.id);
    if (availableStakeMemberIndex == -1) {
      const stakeMemberIndex = isStandard ?
        potentialFacilitators.findIndex((stakeMember) => stakeMember.id == facilitator.cmisId)
        :
        potentialFacilitators.findIndex((stakeMember) => stakeMember.id == facilitator.id);
      const facilitatorToMakeAvailable = stakeMemberIndex != -1 ? potentialFacilitators[stakeMemberIndex] : undefined;
      if (facilitatorToMakeAvailable) {
        const newAvailableStakeMembers = [...availablePotentialFacilitators];
        newAvailableStakeMembers.splice(stakeMemberIndex, 0, facilitatorToMakeAvailable);
        setAvailablePotentialFacilitators(newAvailableStakeMembers);
      }
    }
  };

  ////////////////////
  // Render Helpers //
  ////////////////////

  const facilitatorHasErrors = (recordKey: number) =>
    !formStatus.validity.isValid(FacilitatorSelectorErrorKey, `${recordKey}`) &&
    (formStatus.saveState === SaveState.ValidationFailed ||
      formStatus.dirtiness.isDirty(FacilitatorSelectorErrorKey, `${recordKey}`));

  ////////////
  // Render //
  ////////////
  if (!group?.location) {
    return <></>;
  }

  if (group?.location && isLoading) {
    return (
      <>
        <hr />
        <div className={className}>
          <Loading
            loading={isLoading}
            small={true}
            message={intl.formatMessage({
              id: "getting_stake_members",
              defaultMessage: "Loading Stake Members",
            })}
          />
        </div>
        <hr />
      </>
    );
  }

  return (
    <>
      <hr />
      <div className={className}>
        <>
          {facilitators.map(
            (facilitator, index, facilitators) =>
              (() => {
                return true;
              })() && facilitator && (
                <div
                  className="facilitatorFieldContainer"
                  key={`facilitator-${index}-${facilitator instanceof StandardFacilitator ? facilitator.cmisId : facilitator.name}`}
                >
                  <div className="typeAheadRemoveContainer">
                    <StakeMemberTypeAhead
                      availableStakeMembers={availablePotentialFacilitators}
                      stakeMembers={potentialFacilitators}
                      facilitator={facilitator}
                      onChange={(changed) =>
                        handleFacilitatorChange(index, changed)
                      }
                      onClear={() => handleFacilitatorClear(index, facilitator)}
                      error={facilitatorHasErrors(index)}
                      helperText={
                        facilitatorHasErrors(index)
                          ? formStatus.errors
                            .getMessages(FacilitatorSelectorErrorKey, `${index}`)
                            .map((message, index) => (
                              <span
                                key={`facilitator-picker-autocomplete-message-${index}`}
                              >
                                {message}
                              </span>
                            ))
                          : formStatus.messages
                            .getMessages(FacilitatorSelectorErrorKey, `${index}`)
                            .map((message, index) => (
                              <span
                                key={`facilitator-picker-autocomplete-message-${index}`}
                              >
                                {message}
                              </span>
                            ))
                      }
                    />
                    <div className="removeButtonContainer">
                      <IconButton
                        className="removeField"
                        onClick={() => handleFacilitatorRemove(index, facilitator)}
                        disabled={checkingChurchAccount}
                      >
                        <RemoveCircleOutlineIcon className="removeIcon" />
                        <FormattedMessage id="remove" defaultMessage="Remove" />
                      </IconButton>
                    </div>
                  </div>

                  {(index === facilitators.length - 1 || facilitators.slice(index + 1).every((f) => !f)) &&
                    (
                      <div className="addFieldButton">
                        {!formStatus.validity.isValid(FacilitatorSelectorErrorKey) &&
                          (formStatus.saveState === SaveState.ValidationFailed ||
                            formStatus.dirtiness.isDirty(FacilitatorSelectorErrorKey)) &&
                          (
                            <InfoHoverBox status={InfoHoverStatus.Error}>
                              <>
                                {formStatus.errors
                                  .getMessages(FacilitatorSelectorErrorKey)
                                  .map((message, index) => (
                                    <div
                                      key={`facilitator-picker-autocomplete-message-${index}`}
                                    >
                                      {message}
                                    </div>
                                  ))}
                              </>
                            </InfoHoverBox>
                          )}

                        <Button
                          variant="outlined"
                          onClick={handleFacilitatorAdd}
                          disabled={checkingChurchAccount}
                        >
                          <FormattedMessage
                            id="add_facilitator"
                            defaultMessage="Add Facilitator"
                          />
                        </Button>
                      </div>
                    )}
                </div>
              )
          )}

          <div className="buttonSection">
            {(facilitators.length <= 0 || facilitators.every((f) => !f)) && (
              <Button
                className="addFieldButton"
                variant="outlined"
                onClick={handleFacilitatorAdd}
                disabled={isRetryVisible}
              >
                <FormattedMessage
                  id="add_facilitator"
                  defaultMessage="Add Facilitator"
                />
              </Button>
            )}

            {isRetryVisible && (
              <div className="retrySection">
                <Button
                  className="retryButton"
                  variant="outlined"
                  onClick={getMembersRequest}
                >
                  <FormattedMessage
                    id="retry"
                    defaultMessage="Retry"
                  />
                </Button>
                <span>
                  <FormattedMessage
                    id="retry"
                    defaultMessage="The service failed to get members for your stake. Please click retry to try again."
                  />
                </span>
              </div>
            )}
          </div>
        </>
      </div>
      <hr />
    </>
  );
};

export default FacilitatorSelector;
