import "./group-details.scss";
import React, { useEffect, useState } from "react";
import GroupActionMenuV2 from "../group-action-menu/group-action-menu-v2";
import { getLocaleDateString, getWeekdayName, getHHMM, isValidWeekday } from "utilities/time";
import { FormattedMessage } from "react-intl";
import Loading from "components/loading/loading";
import GroupStatus from "../group-status/group-status";
import GroupStatuses from "constants/group-constants";
import Badge from "components/badge/badge";
import NoticeCreation from "lib/data/model/NoticeCreation";
import { Group, StandardFacilitator } from "lib/data/model";
import { GroupDto } from "lib/api/backend/model";
import { ProviderProgramConsentFormUrlDto } from "lib/api/backend/model/provider";
import { FacilitatorDto } from "lib/api/backend/model/facilitator";
import { GroupToGroupDtoConverter } from "lib/api/backend/converters/GroupToGroupDtoConverter";
import { GroupDtoToGroupConverter } from "lib/api/backend/converters/GroupDtoToGroupConverter";
import { GroupAgeTypes } from "constants/group-constants";
import { getGroupWithProtectedData } from "lib/api/backend/requests/groups";
import dispatchers from "datastore/dispatcher";
import { useDispatch } from "react-redux";
import usePreloadStore, { getLanguages } from "datastore/preload";
import ConsentFormUrlSelector from "components/consent-form-url-selector";
import { FeatureFlagEnabled } from "components/feature-flag-enabled";
import FeatureFlags from "constants/feature-flags";

const converter = new GroupToGroupDtoConverter();
const groupDtoConverter = new GroupDtoToGroupConverter();

interface IGroupDetails {
  group?: Group | GroupDto | null
  locationId?: number,
  programId?: number,
  groupId?: number
  headerOnly?: boolean,
  showCourseDescription?: boolean,
  showEditMenu?: boolean,
  smallDetails?: boolean,
  hideFacilitators?: boolean,
  includeConsent?: boolean,
  onEnrollmentChange?: () => void,
  onLoadingChange?: (isLoading: boolean) => void
}

const GroupDetails = (props: IGroupDetails) => {

  const [group, setGroup] = useState<GroupDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const reduxDispatch = useDispatch();
  const notify = dispatchers(reduxDispatch).notify as (notice: NoticeCreation) => void;
  const allLanguages = usePreloadStore(getLanguages);
  const [hasConsentForms, setHasConsentForms] = useState<boolean>(false);

  //Temporary until we only use the FullGroup type.
  const convertPropGroupToFullGroup = () => {
    if (props.group instanceof Group) {
      return (props.group as Group).convertToFullGroup(allLanguages);
    }

    const group = groupDtoConverter.toModel(props.group as GroupDto);
    const fullGroup = group.convertToFullGroup(allLanguages);

    return fullGroup;
  };

  useEffect(() => {
    if (group == null) {
      getGroup();
    }
  }, []);

  useEffect(() => {
    if (!props.group) {
      return;
    }
    if (props.group instanceof Group) {
      const convertedGroup = converter.toDto(props.group);
      setGroup(convertedGroup);
    } else {
      setGroup(props.group);
    }
  }, [props.group]);

  const getGroup = async () => {
    if (props?.locationId && props?.programId && props?.groupId && group == null) {
      setLoading(true);
      try {
        const groupDto = await getGroupWithProtectedData(props.locationId, props.programId, props.groupId);
        setGroup(groupDto);
        setLoading(false);
      } catch (e) {
        notify({ status: "error", message: <FormattedMessage id="get_group_error" defaultMessage="Could not get group." /> });
        setGroup(null);
        setLoading(false);
      }
    }
  };

  const handleConsentLoad = (consentFormUrls?: ProviderProgramConsentFormUrlDto[]) => {
    if (!consentFormUrls || (consentFormUrls && consentFormUrls.length == 0)) {
      setHasConsentForms(false);
    } else {
      setHasConsentForms(true);
    }
  };

  const facilitators = (() => {
    if (!group) {
      return [];
    }
    let allFacilitors: FacilitatorDto[] = [];
    if (group.facilitators) {
      allFacilitors = allFacilitors.concat(group.facilitators);
    }
    if (group.nonMemberFacilitators) {
      allFacilitors = allFacilitors.concat(group.nonMemberFacilitators);
    }
    return allFacilitors;
  })();

  return (
    <div className="groupDetailsContainer hasLoader">
      {group !== null && typeof group !== "undefined" &&
        <>
          <Loading loading={loading} />
          <div className="nameStatusContainer">
            <h3 className="groupName">{group?.courseName}</h3>
            <GroupStatus status={group.isPending ? GroupStatuses.Pending : group.status} />
          </div>
          <div className="typeAddressContainer">
            {group.isVirtual && <Badge className="groupBadge" type="virtual">Virtual</Badge>}
            {(() => {
              switch (group.groupAgeType) {
                case GroupAgeTypes.Youth:
                  return (<Badge className="groupBadge" type="ageGroup"><FormattedMessage id="group_age_type_youth" defaultMessage="Youth Only" /></Badge>);
                case GroupAgeTypes.Combined:
                  return (<Badge className="groupBadge" type="ageGroup"><FormattedMessage id="group_age_type_combined" defaultMessage="Combined" /></Badge>);
                case GroupAgeTypes.Adult:
                  return (<Badge className="groupBadge" type="ageGroup"><FormattedMessage id="group_age_type_adult" defaultMessage="Adult Only" /></Badge>);
              }
            })()}
            {group?.address1 &&
              <address>
                {group?.address1}, {(group.address2) && `${group.address2}, `}{group.city} {group.state} {group.postalCode}
              </address>
            }
          </div>
          {(props.headerOnly !== true && props.smallDetails) &&
            <div className="smallDetails">
              {group?.startDate &&
                <>
                  <time dateTime={new Date(group.startDate).toISOString()}>{getLocaleDateString(group.startDate)}</time>
                  <span> - </span>
                </>
              }
              {(group?.startDate) && !(group?.endDate) &&
                <span><FormattedMessage id="open" defaultMessage="Open" /></span>
              }
              {(group?.endDate && group?.startDate) &&
                <time dateTime={new Date(group.endDate).toISOString()}>{getLocaleDateString(group.endDate)}</time>
              }
              {(group?.endDate && !group?.startDate) &&
                <>
                  <span><FormattedMessage id="end_date" defaultMessage="End Date" /> </span>
                  <time dateTime={new Date(group.endDate).toISOString()}>{getLocaleDateString(group.endDate)}</time>
                </>
              }
              {((group?.startDate || group.endDate) && group?.meetingTimes?.length > 0) &&
                <>
                  ,&nbsp;
                </>
              }
              {group?.meetingTimes && group.meetingTimes.map((meetingTime, index) => {
                return (
                  <React.Fragment key={`day-${index}`}>
                    {index > 0 &&
                      <>&nbsp;</>
                    }
                    <span className="detailValue">
                      {isValidWeekday(meetingTime?.dayOfWeek) &&
                        <>
                          <FormattedMessage id={getWeekdayName(meetingTime.dayOfWeek).toLowerCase()} defaultMessage={getWeekdayName(meetingTime.dayOfWeek)} /> <time>{getHHMM(meetingTime.startTime)}{meetingTime.endTime != null && " - " + getHHMM(meetingTime.endTime)}</time>
                        </>
                      }
                    </span>
                  </React.Fragment>);
              })}
              <div className="facilitatorEnrollment">
                {!props?.hideFacilitators &&
                  <>
                    {facilitators?.length > 0 ?
                      facilitators.map((facilitator, index) => (
                        <span key={`${group.id}-facilitator-${index}`}>
                          {facilitator instanceof StandardFacilitator ? `${facilitator.firstName} ${facilitator.lastName}` : facilitator.name},&nbsp;
                        </span>
                      )
                      )
                      :
                      <div className="error">
                        <FormattedMessage id="unassigned" defaultMessage="Un-Assigned" />,&nbsp;
                      </div>
                    }
                  </>
                }
                <div>
                  {group.groupLanguages.length > 0 ?
                    group.groupLanguages.map((groupLanguage, index) => {
                      const language = allLanguages.find(lang => lang.id == groupLanguage.languageId)?.description;
                      return (
                        <span key={`${group.id}-facilitator-${index}`}>{language ? language : <FormattedMessage id="multilingual" defaultMessage="Multilingual" />}</span>
                      );
                    })
                    :
                    <span><FormattedMessage id="multilingual" defaultMessage="MultiLingual" /></span>
                  }
                </div>
                {group.enrollmentCount} {group.enrollmentCount && group.enrollmentCount == 1 ? <FormattedMessage id="participant" defaultMessage="Participant" /> : <FormattedMessage id="participants" defaultMessage="Participants" />}
              </div>
            </div>
          }

          {props.showCourseDescription && group?.courseDescription &&
            <p>{group.courseDescription}</p>
          }
          {(props.headerOnly !== true && !props.smallDetails) &&
            <div className="classDetails">
              {group?.startDate &&
                <>
                  <time dateTime={new Date(group.startDate).toISOString()}>{getLocaleDateString(group.startDate)}</time>
                  <span> - </span>
                </>
              }
              {(group?.startDate) && !(group?.endDate) &&
                <span><FormattedMessage id="open" defaultMessage="Open" /></span>
              }
              {(group?.endDate && group?.startDate) &&
                <time dateTime={new Date(group.endDate).toISOString()}>{getLocaleDateString(group.endDate)}</time>
              }
              {(group?.endDate && !group?.startDate) &&
                <>
                  <span><FormattedMessage id="end_date" defaultMessage="End Date" /> </span>
                  <time dateTime={new Date(group.endDate).toISOString()}>{getLocaleDateString(group.endDate)}</time>
                </>
              }
              <div className="bottomRow">
                <div className="groupContainer">
                  <div className="detailGroup">
                    <div className="detailName"><FormattedMessage id="duration" defaultMessage="Duration" /></div>
                    <div className="detailValue">
                      {(group.endDate) ?
                        <>
                          {group.duration} <FormattedMessage id="weeks" defaultMessage="Weeks" />
                        </>
                        :
                        <FormattedMessage id="open_schedule" defaultMessage="Open" />
                      }
                    </div>
                  </div>
                  <div className="detailGroup">
                    <div className="detailName"><FormattedMessage id="day_and_time" defaultMessage="Day and Time" /></div>
                    {group?.meetingTimes && group.meetingTimes.map((meetingTime, index) => {
                      return (
                        <div key={`day-${index}`} className="detailValue">
                          {isValidWeekday(meetingTime?.dayOfWeek) &&
                            <>
                              <FormattedMessage id={getWeekdayName(meetingTime.dayOfWeek).toLowerCase()} defaultMessage={getWeekdayName(meetingTime.dayOfWeek)} /> <time>{getHHMM(meetingTime.startTime)}{meetingTime.endTime != null && " - " + getHHMM(meetingTime.endTime)}</time>
                            </>
                          }
                        </div>);
                    })}
                  </div>
                  {!props?.hideFacilitators &&
                    <div className="detailGroup">
                      <div className="detailName"><FormattedMessage id="facilitators" defaultMessage="Facilitator(s)" /></div>
                      <div className="detailValue">
                        {facilitators?.length ?
                          facilitators.map((facilitator, index) => {
                            if (index == facilitators.length - 1) {
                              return (
                                <span key={`${group.id}-facilitator-${index}`}>
                                  {facilitator instanceof StandardFacilitator ? `${facilitator.firstName} ${facilitator.lastName}` : facilitator.name}
                                </span>
                              );
                            } else {
                              return (
                                <React.Fragment key={`${group.id}-facilitator-${index}`}>
                                  <span>
                                    {`${facilitator instanceof StandardFacilitator ? `${facilitator.firstName} ${facilitator.lastName}` : facilitator.name}, `}
                                  </span>
                                  <br />
                                </React.Fragment>
                              );
                            }
                          })
                          :
                          <div className="error">
                            <FormattedMessage id="unassigned" defaultMessage="Un-Assigned" />
                          </div>
                        }
                      </div>
                    </div>
                  }

                  <div className="detailGroup">
                    <div className="detailName"><FormattedMessage id="language" defaultMessage="Language" /></div>
                    <div className="detailValue">
                      {group.groupLanguages.length > 0 ?
                        group.groupLanguages.map((groupLanguage, index) => {
                          const language = allLanguages.find(lang => lang.id == groupLanguage.languageId)?.description;
                          return (
                            <span key={`${group.id}-facilitator-${index}`}>{language ? language : <FormattedMessage id="multilingual" defaultMessage="Multilingual" />}</span>
                          );
                        })
                        :
                        <span><FormattedMessage id="multilingual" defaultMessage="MultiLingual" /></span>
                      }
                    </div>
                  </div>

                  <div className="detailGroup">
                    <div className="detailName">
                      <FormattedMessage id="participants" defaultMessage="Participants" />
                    </div>
                    <div className="detailValue">{(group?.enrollmentCount) ? group.enrollmentCount : 0}{group?.maxEnrollment && group.maxEnrollment > 0 ? `/${group.maxEnrollment}` : ""}</div>
                  </div>
                  {group?.status &&
                    <div className="detailGroup">
                      <div className="detailName"><FormattedMessage id="status" defaultMessage="Status" /></div>
                      <div className="detailValue">
                        <GroupStatus status={group.isPending ? GroupStatuses.Pending : group.status} uppercase={false} />
                      </div>
                    </div>
                  }
                  {props?.includeConsent && group.groupAgeType != GroupAgeTypes.Adult &&
                    <FeatureFlagEnabled featureFlagName={FeatureFlags.ProviderProgramConsentFormUrls}>
                      <div className={`detailGroup ${!hasConsentForms ? "hidden" : ""}`}>
                        <div className="detailName"><FormattedMessage id="youth_consent_form" defaultMessage="Youth Consent Form" /></div>
                        <div className="detailValue">
                          <ConsentFormUrlSelector programId={group.programId!} providerId={group.providerId!} hideHeading={true} onLoad={handleConsentLoad} />
                        </div>
                      </div>
                    </FeatureFlagEnabled>
                  }
                </div>
                {props?.showEditMenu !== false &&
                  <GroupActionMenuV2
                    group={convertPropGroupToFullGroup()}
                    borderOnToggle={true}
                    onEnrollmentChange={props.onEnrollmentChange}
                    onLoadingChange={props.onLoadingChange}
                  />
                }
              </div>
            </div>
          }
        </>
      }
    </div>
  );
};

export default GroupDetails;
