import { AddressDto } from "../model";
import SiteAddressDto from "../model/SiteAddressDto";
import { IDtoToDtoConverter } from "./IDtoConverter";

export class SiteAddressDtoToAddressDtoConverter implements IDtoToDtoConverter<SiteAddressDto, AddressDto> {
  public toDto = (dto: SiteAddressDto): AddressDto => {
    const address = new AddressDto({
      address1: dto.line1,
      address2: dto.line2,
      city: dto.city,
      state: dto.state,
      postalCode: dto.postalCode,
      country: dto.country
    });

    return address;
  };
}