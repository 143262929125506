import dayjs from "dayjs";
import GeoCoordinateDto, { IGeoCoordinateDto } from "./GeoCoordinateDto";
import AddressCreationDto, { IAddressCreationDto } from "./AddressCreationDto";
import FacilitatorCreationDto, { IFacilitatorCreationDtoCreationValues } from "./FacilitatorCreationDto";
import GroupTimeCreationDto, { IGroupTimeCreationDto } from "./GroupTimeCreationDto";
import NonMemberFacilitatorCreationDto, { INonMemberFacilitatorCreationDto } from "./NonMemberFacilitatorCreationDto";

export interface IGroupCreationDto {
  facilityId?: number;
  customAddressCreationModel?: IAddressCreationDto;
  isVirtual: boolean;
  isHidden: boolean;
  isCustom: boolean;
  isPending: boolean;
  providerId?: number;
  programId?: number;
  groupAgeType: string;
  programName: string;
  startDate?: string;
  endDate?: string;
  hasAcceptedYouthTerms?: boolean;
  meetingTimes: IGroupTimeCreationDto[];
  facilitators: IFacilitatorCreationDtoCreationValues[];
  nonMemberFacilitators: INonMemberFacilitatorCreationDto[];
  maxEnrollment?: number;
  stakeUnitNumber?: number;
  coordinates: IGeoCoordinateDto;
  languageIds: number[];
}

export default class GroupCreationDto implements GroupCreationDto {

  public facilityId?: number;
  public customAddressCreationModel?: AddressCreationDto;
  public isVirtual: boolean;
  public isHidden: boolean;
  public isCustom: boolean;
  public isPending: boolean;
  public providerId?: number;
  public programId?: number;
  public groupAgeType: string;
  public programName: string;
  public startDate?: string;
  public endDate?: string;
  public hasAcceptedYouthTerms?: boolean;
  public meetingTimes: GroupTimeCreationDto[];
  public facilitators: FacilitatorCreationDto[];
  public nonMemberFacilitators: NonMemberFacilitatorCreationDto[];
  public maxEnrollment?: number;
  public stakeUnitNumber?: number;
  public coordinates: GeoCoordinateDto;
  public languageIds: number[];

  constructor(creationValues: IGroupCreationDto) {
    this.facilityId = creationValues.facilityId;
    this.customAddressCreationModel = creationValues.customAddressCreationModel ? new AddressCreationDto(creationValues.customAddressCreationModel) : undefined;
    this.isVirtual = creationValues.isVirtual;
    this.isHidden = creationValues.isHidden;
    this.isCustom = creationValues.isCustom;
    this.isPending = creationValues.isPending;
    this.providerId = creationValues.providerId;
    this.programId = creationValues.programId;
    this.groupAgeType = creationValues.groupAgeType;
    this.programName = creationValues.programName;
    this.startDate = creationValues.startDate ? dayjs(creationValues.startDate).format("YYYY-MM-DD") : undefined;
    this.endDate = creationValues.endDate ? dayjs(creationValues.endDate).format("YYYY-MM-DD") : undefined;
    this.hasAcceptedYouthTerms = creationValues.hasAcceptedYouthTerms;
    this.meetingTimes = creationValues.meetingTimes.map(mt => new GroupTimeCreationDto(mt));
    this.facilitators = creationValues.facilitators.map(f => new FacilitatorCreationDto(f));
    this.nonMemberFacilitators = creationValues.nonMemberFacilitators.map(nmf => new NonMemberFacilitatorCreationDto(nmf));
    this.maxEnrollment = creationValues.maxEnrollment;
    this.stakeUnitNumber = creationValues.stakeUnitNumber;
    this.coordinates = new GeoCoordinateDto(creationValues.coordinates);
    this.languageIds = creationValues.languageIds;
  }

  public export(): IGroupCreationDto {
    return {
      facilityId: this.facilityId,
      customAddressCreationModel: this.customAddressCreationModel?.export(),
      isVirtual: this.isVirtual,
      isHidden: this.isHidden,
      isCustom: this.isCustom,
      isPending: this.isPending,
      providerId: this.providerId,
      programId: this.programId,
      groupAgeType: this.groupAgeType,
      programName: this.programName,
      startDate: this.startDate,
      endDate: this.endDate,
      hasAcceptedYouthTerms: this.hasAcceptedYouthTerms,
      meetingTimes: this.meetingTimes.map(mt => mt.export()),
      facilitators: this.facilitators.map(f => f.export()),
      nonMemberFacilitators: this.nonMemberFacilitators.map(nmf => nmf.export()),
      maxEnrollment: this.maxEnrollment,
      stakeUnitNumber: this.stakeUnitNumber,
      coordinates: this.coordinates.export(),
      languageIds: this.languageIds
    };
  }

  public clone(): GroupCreationDto {
    return new GroupCreationDto(this.export());
  }
}