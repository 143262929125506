export interface IGeoCoordinateDto {
  latitude: number;
  longitude: number;
}

export default class GeoCoordinateDto implements IGeoCoordinateDto {

  public latitude: number;
  public longitude: number;

  constructor(creationValues: IGeoCoordinateDto) {
    this.latitude = creationValues.latitude;
    this.longitude = creationValues.longitude;
  }

  public export(): IGeoCoordinateDto {
    return {
      latitude: this.latitude,
      longitude: this.longitude
    };
  }

  public clone(): GeoCoordinateDto {
    return new GeoCoordinateDto(this.export());
  }
}