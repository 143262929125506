import { useRef } from "react";
import { mapStateToProps, mapDispatchToProps } from "datastore/store";
import { connect } from "react-redux";
import { SupportedLanguages } from "constants/language-constants";
import { FormattedMessage } from "react-intl";
import "./language-selector.scss";

const LanguageSelector = (props) => {
  const open = useRef(false);

  const selectLanguage = (supportedLanguageIndex) => {
    props.updateLanguage(SupportedLanguages[supportedLanguageIndex].localeCodes[0]);
    open.current = false;
  };

  const openLanguageSelection = (event) => {
    event.preventDefault();
    if (!open.current) {
      props.prompt({
        status: "none",
        message:
          <div className="languageButtonContainer">
            {SupportedLanguages.map((language, index) => {
              return (
                <button className="languageButton" onClick={() => selectLanguage(index)} key={`${index}-${language.nativeName}`} data-close-on-click={true}>
                  {language.nativeName}
                </button>
              );
            })}
          </div>,
        buttons: <button className="primary hollow languageSelectCancel"><FormattedMessage id="cancel" defaultMessage="Cancel" /></button>
      });
    }
  };

  return (
    <div className="languageSelectorContainer">
      <button className="languageSelectorToggle globeIcon" onClick={openLanguageSelection}></button>
    </div>
  );

};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);