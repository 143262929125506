import "./location-selector.scss";
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Loading from "components/loading/loading";
import LocationInputPanel from "./components/location-input-panel";
import LocationMapPanel from "./components/location-map-panel";
import { Permissions } from "constants/api-auth-constants";
import { AuthorizeService } from "auth/authorize-service";
import useClassName from "utilities/useClassName";
import { useGroup } from "lib/data/contexts";
import { Group, Location } from "lib/data/model";
import { useFormStatus } from "lib/form/contexts";
import { FormStatus } from "lib/form/model";
import { IUnit, Unit } from "./model";
import { SiteDto } from "lib/api/backend/model";
import { LocationType } from "lib/data/model/enum";
import { getSitesForStakeId } from "lib/api/backend/requests/sites/site-requests";
import { ServerResponseType } from "lib/api/backend/request-utils";

interface ILocationSelectorProps {
  className?: string;
  location?: Location;
  onLocationChange?: (location: Location) => void;
}

const LocationSelector: React.VFC<ILocationSelectorProps> = (props) => {
  const [className, setPropsClassNames] = useClassName("locationSelectorContainer", "hasLoader");
  const [formStatus, setFormStatus] = useFormStatus();
  const [group, setGroup] = useGroup();

  const [isLoading, setLoading] = useState(false);
  const [authorizedUnits, setAuthorizedUnits] = useState<Unit[]>([]);
  const [authorizedSites, setAuthorizedSites] = useState<SiteDto[]>([]);
  const [selectedUnitNumber, setSelectedUnitNumber] = useState<number | null>(null);
  const [location, setLocation] = useState<Location>();

  const intl = useIntl();

  useEffect(
    () => {
      setPropsClassNames(props.className ?? "");
    },
    [props.className]
  );

  useEffect(
    () => {
      if (props.location) {
        isLocationValid(props.location);
        setLocation(props.location);
      }
    },
    [props.location]
  );

  /*useEffect(
    () => {
      const getAuthorizedUnits = () => {
        const allowedUnits: IUnit[] = AuthorizeService.getAllowedUnits();
        const units = allowedUnits.map(au => new Unit(au));
        setAuthorizedUnits(units);
        if (location && !location.stakeUnitNumber) {
          setLocation(new Location({
            ...location,
            stakeUnitNumber: units[0].areaUnitNumber,
          }));
        } else if (!location) {
          setLocation(new Location({
            type: LocationType.ChurchOwned,
            stakeUnitNumber: group?.stakeUnitNumber || units[0].areaUnitNumber,
          }));
        }
      };

      const getAuthorizedSites = async () => {
        setLoading(true);

        const existing: ISiteDto[] | Error = (await Requests.getSites()) ?? [];

        if (existing instanceof Error) {
          throw existing;
        }
       
        const sites = existing.map(s => new SiteDto(s));
        const authorizedSites = sites.filter((loc) => AuthorizeService.userCan([Permissions.NewGroupWrite], loc.stakeUnitNumber as any));

        setAuthorizedSites(authorizedSites);

        setLoading(false);
      };

      getAuthorizedUnits();
      getAuthorizedSites();
      isLocationValid(props.location);
    },
    []
  );*/

  useEffect(() => {
    const getAuthorizedUnits = () => {
      const allowedUnits: IUnit[] = AuthorizeService.getAllowedUnits();
      const units = allowedUnits.map(au => new Unit(au));
      setAuthorizedUnits(units);

      if (location && !location.stakeUnitNumber) {
        setLocation(new Location({
          ...location,
          stakeUnitNumber: units[0].areaUnitNumber,
        }));
      } else if (!location) {
        setLocation(new Location({
          type: LocationType.ChurchOwned,
          stakeUnitNumber: group?.stakeUnitNumber || units[0].areaUnitNumber,
        }));
      }

      /*setLocation({
        type: LocationType.ChurchOwned,
        stakeUnitNumber: group?.stakeUnitNumber || units[0].areaUnitNumber,
      });*/

      //Start the form as invalid, but with no errors.
      formStatus.validity.setValidity(false, "location");

      setFormStatus(
        new FormStatus(formStatus)
      );
    };

    getAuthorizedUnits();
    isLocationValid(props.location);
  }, []);

  useEffect(() => {
    const unitNumber = location?.stakeUnitNumber;

    if (unitNumber) {
      setSelectedUnitNumber(unitNumber);
    }
  }, [location?.stakeUnitNumber]);

  useEffect(() => {
    const getStakeSites = async () => {
      setLoading(true);

      const sitesResult = await getSitesForStakeId(selectedUnitNumber as number);

      if (sitesResult.responseType === ServerResponseType.Error) {
        throw new Error("Error getting sites for stake.");
      }

      const sites = sitesResult.data;
      const authorizedSites = sites.filter((loc) => AuthorizeService.userCan([Permissions.NewGroupWrite], loc.stakeUnitNumber as any));

      setAuthorizedSites(authorizedSites);
      setLoading(false);
    };

    if (selectedUnitNumber) {
      getStakeSites();
    }
  }, [selectedUnitNumber]);

  const handleLocationChange = (newLocation: Location, overrideGroup?: Group) => {
    const typeChange = newLocation.type != location?.type;
    setLocation(newLocation);

    if (props.onLocationChange) {
      props.onLocationChange(newLocation);
    }

    if (typeChange) {
      formStatus.dirtiness.setDirty(false, "location");
    } else {
      formStatus.dirtiness.setDirty(true, "location");
    }

    setFormStatus(
      new FormStatus(formStatus)
    );

    //The map pin click handler will pass in its own "override" group
    //Why Though?
    //The google map pin click handler doesn't keep an up to date version of this handler so group gets set wrong.
    //The google map pin click handler does keep track of the group via the group context and knows the correct group.
    //This location change handler still gets called with old data despite updating the google map click handlers with an effect on the group, and the change handler
    const myGroup = overrideGroup ? overrideGroup : group;

    if (isLocationValid(newLocation)) {
      const newGroup = new Group({
        ...myGroup!,
        location: newLocation
      });
      setGroup(newGroup);
    }
  };

  const isLocationValid = (location?: Location) => {
    let isValid = true;

    const noLocationErrorMessage = intl.formatMessage({ id: "please_select_location", defaultMessage: "Please select a location to continue." });

    if (!location || !location?.stakeUnitNumber) {
      isValid = false;
      formStatus.errors.addMessage(noLocationErrorMessage, "location");
    }
    else {
      formStatus.errors.removeMessage(noLocationErrorMessage, "location");
    }

    formStatus.validity.setValidity(isValid, "location");

    setFormStatus(
      new FormStatus(formStatus)
    );

    return isValid;
  };

  return (
    <div className={className}>
      {isLoading ?
        <Loading loading={isLoading} message={intl.formatMessage({ id: "getting_locations", defaultMessage: "Getting location information." })} />
        :
        <>
          <LocationInputPanel
            location={location}
            sites={authorizedSites.filter(site => site.stakeUnitNumber == location?.stakeUnitNumber)}
            units={authorizedUnits}
            onLocationChange={handleLocationChange}
            allowUnitChange={!(group?.id)}
          />
          <LocationMapPanel
            sites={authorizedSites.filter(site => site.stakeUnitNumber == location?.stakeUnitNumber)}
            location={location}
            onLocationChange={handleLocationChange}
          />
        </>
      }
    </div>
  );
};

export default LocationSelector;