import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useClassName from "utilities/useClassName";
import { StakeMemberDto } from "lib/api/backend/model";
import { FacilitatorCreationDto } from "lib/api/backend/model/group/GroupCreationDto";

export interface IStakeMemberTypeAheadProps {
  className?: string;
  stakeMembers: StakeMemberDto[];
  availableStakeMembers: StakeMemberDto[];
  onChange?: (facilitator: FacilitatorCreationDto) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  onClear: () => void;
}

export const StakeMemberTypeAhead: React.VFC<IStakeMemberTypeAheadProps> = (props) => {

  const [className, setPropsClassNames] = useClassName("stakeMemberTypeAheadContainer");
  const [stakeMember, setStakeMember] = useState<StakeMemberDto | null>(null);
  const intl = useIntl();
  const missionaryTranslation = intl.formatMessage({ id: "missionary", defaultMessage: "Missionary" });

  useEffect(
    () => {
      if (props.className) {
        setPropsClassNames(props.className);
      }
      else {
        setPropsClassNames();
      }
    },
    [props.className]
  );

  //Accepts a string for type compliance  reasons, but newValue is never a string.
  const handleAutoCompleteChange = (event, newValue: string | StakeMemberDto | null) => {
    if (newValue && newValue instanceof StakeMemberDto) {
      selectMember(newValue);
    } 
    else {
      if (props.onClear) {
        props.onClear();
      }
    }
  };

  const selectMember = (selectedMember: StakeMemberDto) => {
    setStakeMember(selectedMember);
    if (props.onChange) {
      props.onChange({
        cmisId: selectedMember.id,
        firstName: selectedMember.firstName,
        lastName: selectedMember.lastName,
        gender: "",
        name: selectedMember.displayName,
      } as FacilitatorCreationDto);
    }
  };

  const getDisplayName = (member: StakeMemberDto | string) => {
    if (member instanceof StakeMemberDto) {
      return `${member.displayName}${member.isMissionary ? ` (${missionaryTranslation})` : ""}`;
    } else {
      return member.trim();
    }
  };

  return (
    <div className={className}>
      <Autocomplete
        freeSolo={true}
        onChange={handleAutoCompleteChange}
        value={
          stakeMember
        }
        options={props.availableStakeMembers}
        getOptionLabel={option => getDisplayName(option)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {getDisplayName(option)}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={intl.formatMessage({ id: "facilitator", defaultMessage: "Facilitator" })}
            //onBlur={(event) => selectGuest(event.target.value)}
            error={props.error}
            helperText={props.helperText}
          />
        )}
      />
    </div>
  );

};



export default StakeMemberTypeAhead;