import "./edit-participant.scss";
import React from "react";
import EditParticipantForm from "components/edit-participant-form/edit-participant-form";

const EditParticipants: React.VFC = () => {
  return (
    <EditParticipantForm />
  );
};

export default EditParticipants;