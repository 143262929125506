import StudentEnrollmentCourse from "./StudentEnrollmentCourse";

export interface IStudentEnrollmentV2 {
  course: StudentEnrollmentCourse;
  email?: string;
  enrollmentId: number;
  firstName: string;
  isLimitedStudent: boolean;
  lastName: string;
  phone?: string;
  studentId: number;
  status: string;
}

export class StudentEnrollmentV2 implements IStudentEnrollmentV2 {
  public course: StudentEnrollmentCourse;
  public email?: string;
  public enrollmentId: number;
  public firstName: string;
  public isLimitedStudent: boolean;
  public lastName: string;
  public phone?: string;
  public studentId: number;
  public status: string;

  constructor(creationValues: IStudentEnrollmentV2) {
    this.course = creationValues.course;
    this.email = creationValues.email;
    this.enrollmentId = creationValues.enrollmentId;
    this.firstName = creationValues.firstName;
    this.isLimitedStudent = creationValues.isLimitedStudent;
    this.lastName = creationValues.lastName;
    this.phone = creationValues.phone;
    this.studentId = creationValues.studentId;
    this.status = creationValues.status;
  }

}

export default StudentEnrollmentV2;
