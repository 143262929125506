import { ReactElement, useState } from "react";
import { MoveParticipantFormControls } from "./components";
import { MoveParticipantGroupSelector } from "./components";
import { FullGroup, QuickRegParticipant } from "lib/models-v2";
import { LoadingStatus, NoticeCreation } from "lib/data/model";
import { updateParticipant } from "lib/api/backend/requests/participant";
import { FormattedMessage, useIntl } from "react-intl";
import { ParticipantUpdateDto } from "lib/api/backend/model/participant/ParticipantUpdateDto";
import { useDispatch } from "react-redux";
import dispatchers from "datastore/dispatcher";
import Loading from "components/loading/loading";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import MoveParticipantSelectedGroup from "./components/move-participant-selected-group";
import useGroupStore from "datastore/groups";
import "./move-participant-dialog.scss";

interface IMoveParticipantButtonProps {
  participant: QuickRegParticipant;
  show: boolean;
  onClose?: () => void;
  onChange?: (participant: QuickRegParticipant) => void;
}

export const MoveParticipantDialog = (props: IMoveParticipantButtonProps): ReactElement => {

  const [selectedGroup, setSelectedGroup] = useState<FullGroup | null>(null);
  const [loading, setLoading] = useState<LoadingStatus>({ status: false, message: "" });
  const [saveInProgess, setSaveInProgess] = useState<boolean>(false);
  const groupStore = useGroupStore();
  const intl = useIntl();
  const reduxDispatch = useDispatch();
  const notify = dispatchers(reduxDispatch).notify as (notice: NoticeCreation) => void;

  const handleLoadingChange = (status: LoadingStatus) => {
    setLoading(status);
  };

  const handleGroupSelect = (group: FullGroup) => {
    setSelectedGroup(group);
  };

  const handleClearSelectedGroup = () => {
    setSelectedGroup(null);
  };

  const handleCancel = () => {
    close();
  };

  const handleClose = () => {
    close();
  };

  const close = () => {
    setSelectedGroup(null);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleSave = async () => {
    if (selectedGroup != null) {
      setSaveInProgess(true);
      setLoading({ status: true, message: intl.formatMessage({ id: "saving_changes", defaultMessage: "Saving Changes" }) });
      const updateDto: ParticipantUpdateDto = {
        groupId: selectedGroup.id
      };
      const result = await updateParticipant(props.participant.id, updateDto);
      if (result) {
        updateStore();
        notify({ status: "success", message: intl.formatMessage({ id: "changes_saved", defaultMessage: "Changes Saved" }) });
      }
      else {
        notify({ status: "error", message: intl.formatMessage({ id: "changes_not_saved", defaultMessage: "An error occured while saving the changes" }) });
      }
      setSaveInProgess(false);
      setLoading({ status: false, message: "" });
      close();
    }
  };

  const updateStore = () => {
    const originalGroup = groupStore.getGroup(props.participant.groupId);
    if (originalGroup) {
      const originalParticipantIndex = originalGroup?.quickRegParticipants.findIndex(groupParticipant => groupParticipant.id == props.participant.id);
      if (originalParticipantIndex != -1) {
        originalGroup?.quickRegParticipants.splice(originalParticipantIndex, 1);
        originalGroup.enrollmentCount--;
        groupStore.editGroup(originalGroup);
      }
    }
    if (selectedGroup) {
      const newGroup = groupStore.getGroup(selectedGroup.id);
      if (newGroup) {
        const newParticipant: QuickRegParticipant = { ...props.participant, groupId: newGroup.id };
        newGroup?.quickRegParticipants.push(newParticipant);
        newGroup.enrollmentCount++;
        groupStore.editGroup(newGroup);
        if (props.onChange) {
          props.onChange(newParticipant);
        }
      }
    }
  };

  return (
    <div className="moveParticipantDialogContainer">
      <Dialog
        fullWidth={true}
        open={props.show}
        onClose={handleClose}
      >
        <DialogTitle>
          <FormattedMessage
            id="select_move_group"
            defaultMessage={"Select a group to move {givenName} {surname} to:"}
            values={{ givenName: props.participant.givenName, surname: props.participant.surname }}
          />
          <hr />
        </DialogTitle>
        <DialogContent>
          <div className="hasLoader">
            <Loading loading={loading.status} message={loading.message} />
            {!selectedGroup ?
              <MoveParticipantGroupSelector participantGroupId={props.participant.groupId} onLoadingChange={handleLoadingChange} onSelect={handleGroupSelect} />
              :
              <MoveParticipantSelectedGroup participant={props.participant} group={selectedGroup} onClear={handleClearSelectedGroup} />
            }
          </div>
          {!loading.status &&
            <DialogActions>
              <hr />
              <MoveParticipantFormControls saveDisabled={!selectedGroup || saveInProgess} onSave={handleSave} onCancel={handleCancel} />
            </DialogActions>
          }
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MoveParticipantDialog;