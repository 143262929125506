import React, { useState } from "react";
import { useSelector } from "react-redux";
import MenuIcon from "assets/images/icons/hamburger-menu.svg";
import CloseIcon from "assets/images/icons/cross.svg";
import Notifier from "components/notifier/notifier";
import { Permissions } from "constants/api-auth-constants";
import AuthService from "auth/authorize-service";
import LanguageSelector from "components/language-selector/language-selector";
import UserMenu from "components/user-menu/user-menu";
import ImpersonationBar from "./ImpersonationBar";
import SubHeaderOptions from "components/header/header-options";
import AuthorizeService from "auth/authorize-service";
import { MaxUnitNumbersPerFetch } from "constants/group-constants";
import MenuItem from "@mui/material/MenuItem";
import { useIntl } from "react-intl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useGroupStore from "datastore/groups";
import "./header.scss";

interface IHeaderProps {
  showProtectedNav: boolean,
}

const Header = (props: IHeaderProps) => {

  const { showProtectedNav } = props;
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const intl = useIntl();
  const user = useSelector((state: any) => state.userReducer.user);
  const store = useGroupStore();
  const isAuthenticated = useSelector((state: any) => state.userReducer.isAuthenticated);

  const canAccess = {
    courseCatalog: AuthService.userCan([Permissions.CourseCatalogRead]),
    groupsPage: AuthService.userCan([Permissions.GroupIndexRead]),
    studentsPage: AuthService.userCan([Permissions.StudentsRead])
  };

  const hasAccess = () => {
    const hasNoAccess = Object.keys(canAccess).every((key) => !canAccess[key]);

    return !hasNoAccess && isAuthenticated && AuthService.userAllowed() && showProtectedNav;
  };

  const toggleNav = (): void => {
    setShowMobileNav(!showMobileNav);
  };

  const handleActiveUnitChange = (event: SelectChangeEvent) => {
    if (event.target.value) {
      store.setActiveUnitNumber(parseInt(event.target.value));
    }
  };

  return (
    <header className={`provider ${(showMobileNav) ? "mobileMenuOpen" : ""}`}>
      <ImpersonationBar user={user} />
      <div className={`headerTop ${(user?.impersonatedBy?.id) ? "impersonate" : ""}`}>
        <div className="headerTopLeft">
          <span className="headerTitle">QuickReg</span>
          {AuthorizeService?.authorizedUnits?.length && AuthorizeService?.authorizedUnits?.length > MaxUnitNumbersPerFetch &&
            <Select
              value={store.activeUnitNumber?.toString() || ""}
              onChange={handleActiveUnitChange}
              placeholder={intl.formatMessage({ id: "select_unit_placeholder", defaultMessage: "Select A Unit" })}
              style={{ marginLeft: "8px", width: "200px", backgroundColor: "#fff" }}
            >
              {AuthorizeService.authorizedUnits.map((authUnit, index) => <MenuItem key={`${index}-${authUnit.unitNumber}`} value={authUnit.unitNumber.toString()}>{authUnit.unitName}</MenuItem>)}
            </Select>
          }
        </div>
        <div className="headerTopRight">
          {isAuthenticated &&
            <UserMenu />
          }
          <LanguageSelector />
        </div>
      </div>
      <nav>
        <button className="icon navMenuToggle" onClick={toggleNav}>
          <img src={MenuIcon} alt="Menu Toggle" />
        </button>
        <div className={`navLeft${(!showMobileNav) ? " off" : ""}`}>
          <div className="mobileHead">
            <button className="icon navMenuClose" onClick={toggleNav}>
              <img src={CloseIcon} alt="Close Menu Icon" />
            </button>
          </div>
          <SubHeaderOptions
            mobileNavClick={() => { setShowMobileNav(false); }}
            canAccess={canAccess}
            isAuthenticated={isAuthenticated}
            hasAccess={hasAccess()}
          />
        </div>
        <div className="navRight">
        </div>
      </nav>
      <Notifier />
    </header>
  );
};

export default Header;