import React from "react";
import AuthService from "auth/authorize-service";
import { Permissions } from "constants/api-auth-constants";
import Card from "components/card/card";
import RightArrow from "assets/images/icons/right-arrow.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import GroupStatuses, { GroupAgeTypes } from "constants/group-constants";
import GroupStatus from "components/group-status/group-status";
import Badge from "components/badge/badge";
import { getHHMM, getLocaleDateString, getWeekdayName } from "utilities/time";
import GroupActionMenu from "components/group-action-menu/group-action-menu";
import "./group-list-card.scss";

const GroupListCard = (props) => {

  const { group, className, onCourseSignup } = props;
  const intl = useIntl();
  const addStudentsButtonDisabled = !AuthService.userCan([Permissions.CourseSignUpWrite], group.stakeUnitNumber) || [GroupStatuses.Completed, GroupStatuses.Cancelled].includes(group?.status) || (group.maxEnrollment > 0 && group.enrollmentCount >= group.maxEnrollment);
  const canManageGroups = AuthService.userCan([Permissions.ManageGroupsWrite]);

  const displayEnrollmentCount = (enrollmentCount, maxEnrollment) => {

    if (enrollmentCount === null) {
      return "--/--";
    }

    if (maxEnrollment === null || maxEnrollment <= 0) {
      return `${enrollmentCount}`;
    }

    return `${enrollmentCount}/${maxEnrollment}`;
  };

  const facilitators = (() => {
    let result = group?.facilitators;
    if (group?.nonMemberFacilitators?.length) {
      result = group?.facilitators?.concat(group?.nonMemberFacilitators);
    }
    if (!result && group?.nonMemberFacilitators?.length) {
      result = group?.nonMemberFacilitators;
    }
    return result;
  })();

  const cardHead = (
    <div className="courseCardHead">
      <div className="leftHead">
        <span className="groupHeading"><Link className={canManageGroups ? "" : "disabled"} to={canManageGroups ? `/groups/${group.locationId}/${group.programId}/${group.id}` : ""}>{group.programName}</Link></span>
        {(() => {
          switch (group.groupAgeType) {
            case GroupAgeTypes.Combined: {
              return (<Badge pill type="ageGroup">{intl.formatMessage({ id: "group_age_type_combined", defaultMessage: "Combined" })}</Badge>);
            }
            case GroupAgeTypes.Youth: {
              return (<Badge pill type="ageGroup">{intl.formatMessage({ id: "group_age_type_youth", defaultMessage: "Youth only" })}</Badge>);
            }
            case GroupAgeTypes.Adult: {
              return (<Badge pill type="ageGroup">{intl.formatMessage({ id: "group_age_type_adult", defaultMessage: "Adult only" })}</Badge>);
            }
          }
        })()}
        {group.isVirtual && <Badge type="virtual"><FormattedMessage id="virtual" defaultMessage="Virtual" /></Badge>}
        <address className="groupAddress">
          {group.address1}, {(group.address2) && `${group.address2}, `}{group.city}
        </address>
      </div>
      <div className="rightHead">
        <Link className={canManageGroups ? "" : "disabled"} to={canManageGroups ? `/groups/${group.locationId}/${group.programId}/${group.id}` : ""}><img className="arrow" src={RightArrow} alt="Arrow Pointing Right" /></Link>
      </div>
    </div>
  );

  return (
    <div className={`groupListCardContainer ${className ? className : ""}`}>
      <Card head={cardHead}>

        <div className="dataContainer">
          <div className="dataHeading"><FormattedMessage id="schedule" defaultMessage="Schedule" /></div>
          <div className="data">
            {
              <>
                {group?.startDate &&
                  <>
                    <time dateTime={group.startDate}>{getLocaleDateString(group.startDate)}</time>
                    <span> - </span>
                  </>
                }
                {(group?.startDate && !group?.endDate) &&
                  <span><FormattedMessage id="open" defaultMessage="Open" /></span>
                }
                {(group?.endDate && group?.startDate) &&
                  <time dateTime={group.endDate}>{getLocaleDateString(group.endDate)}</time>
                }
                {(group?.endDate && !group?.startDate) &&
                  <>
                    <span><FormattedMessage id="end_date" defaultMessage="End date" /> </span>
                    <time dateTime={group.endDate}>{getLocaleDateString(group.endDate)}</time>
                  </>
                }
                {group?.meetingTimes && group.meetingTimes.sort((a, b) => a.dayOfWeek - b.dayOfWeek).map((meetingTime, index) => {
                  return (
                    <div key={`day-${index}`} className="detailValue">
                      <FormattedMessage id={getWeekdayName(meetingTime.dayOfWeek).toLowerCase()} defaultMessage={getWeekdayName(meetingTime.dayOfWeek)} /> <time>{getHHMM(meetingTime.startTime)}{meetingTime.endTime != null && " - " + getHHMM(meetingTime.endTime)}</time>
                    </div>);
                })}
              </>
            }
          </div>
        </div>

        <div className="dataContainer">
          <div className="dataHeading"><FormattedMessage id="facilitators" defaultMessage="Facilitator(s)" /></div>
          <div className="dataValue">
            {facilitators?.length ?
              facilitators.map((facilitator, index) => {
                if (index == facilitators.length - 1) {
                  return (<span key={`${group.id}-facilitator-${index}`}>{facilitator.name}</span>);
                } else {
                  return (<span key={`${group.id}-facilitator-${index}`}>{`${facilitator.name}, `}</span>);
                }
              })
              :
              <div className="error">
                <FormattedMessage id="unassigned" defaultMessage="Un-Assigned" />
              </div>
            }
          </div>
        </div>

        <div className="dataContainer">
          <div className="dataHeading"><FormattedMessage id="language" defaultMessage="Language" /></div>
          <div className="dataValue">
            <span key={`${group.id}-language`}>
              {group.language ? group.language.description : <FormattedMessage id="multilingual" defaultMessage="Multilingual" />}
            </span>
          </div>
        </div>

        <div className="dataContainer">
          <div className="dataHeading"><FormattedMessage id="enrolled" defaultMessage="Enrolled" /></div>
          <div className="data">
            {displayEnrollmentCount(group.enrollmentCount, group.maxEnrollment)}
          </div>
        </div>

        <div className="dataContainer">
          <div className="dataHeading"><FormattedMessage id="status" defaultMessage="Status" /></div>
          <div className={`data ${group.status}`}>
            {
              group?.isPending
                ? <GroupStatus status={GroupStatuses.Pending} />
                : (
                  group?.status
                  && <GroupStatus status={group.status} />
                )
            }
          </div>
        </div>

        <div className="actionContainer">
          <button disabled={addStudentsButtonDisabled} data-group={group.Id} onClick={(event) => onCourseSignup(event, group)} className="listAction"><span className="buttonPlainTextIcon">+</span> <FormattedMessage id="participants" defaultMessage="Participants" /></button>
          <GroupActionMenu group={group} borderOnToggle />
        </div>

      </Card>
    </div>
  );
};

export default GroupListCard;