export function removeByOutsideDateRange(students, startDate, endDate){

  if(!startDate && !endDate){
    return students;
  }

  let filteredData = [];
  
  if(startDate !== "" && endDate == ""){
    startDate = new Date(startDate);
    endDate = false;
    students.forEach((student) => {
      const newStudent = {...student, courses: []};
      newStudent.courses = student.courses.filter((course) => {
        const courseDate = new Date(course.date); 
        return courseDate >= startDate;
      });
      if(newStudent.courses.length > 0){
        filteredData.push(newStudent);
      }
    });
  } else if(startDate !== "" && endDate !== ""){
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    students.forEach((student) => {
      const newStudent = {...student, courses: []};
      newStudent.courses = student.courses.filter((course) => {
        const courseDate = new Date(course.date); 
        return courseDate >= startDate && courseDate <= endDate;
      });
      if(newStudent.courses.length > 0){
        filteredData.push(newStudent);
      }
    });
  } else {
    startDate = false;
    endDate = new Date(endDate);
    students.forEach((student) => {
      const newStudent = {...student, courses: []};
      newStudent.courses = student.courses.filter((course) => {
        const courseDate = new Date(course.date); 
        return courseDate <= endDate;
      });
      if(newStudent.courses.length > 0){
        filteredData.push(newStudent);
      }
    });
  }

  return filteredData;
}

export function removeStudentByAccountType(students, allowedValues){
  if(!students || !allowedValues){
    return students;
  }
  return students.filter(student => {
    let type = "";
    if(student.isLimitedStudent){
      type = "Email/Phone";
    } else {
      type = "Church Account"; 
    }
    return allowedValues.includes(type);
  });
}

export function removeStudentByStatus(students, allowedValues){
  if(!allowedValues || allowedValues?.length < 1){
    return students;
  }
  let filteredData = [];
  students.forEach((student) => {
    const newStudent = {...student, courses: []};
    student.courses.forEach(course => {
      if(allowedValues.includes(course.status)){
        newStudent.courses.push(course);
      }
    });
    if(newStudent.courses.length > 0){
      filteredData.push(newStudent);
    }
  });
  return filteredData;
}

export function removeStudentByCourse(students, allowedValues){
  if(!allowedValues || allowedValues?.length < 1){
    return students;
  }
  let filteredData = [];
  students.forEach((student) => {
    const newStudent = {...student, courses: []};
    student.courses.forEach(course => {
      if(allowedValues.includes(course.id)){
        newStudent.courses.push(course);
      }
    });
    if(newStudent.courses.length > 0){
      filteredData.push(newStudent);
    }
  });
  return filteredData;
}

export function removeStudentByProvider(students, removalValues){
  if(!removalValues || removalValues?.length < 1){
    return students;
  }
  let filteredData = [];
  students.forEach((student) => {
    const newStudent = {...student, courses: []};
    student.courses.forEach(course => {
      if(!removalValues.includes(course.providerId)){
        newStudent.courses.push(course);
      }
    });
    if(newStudent.courses.length > 0){
      filteredData.push(newStudent);
    }
  });
  return filteredData;
}