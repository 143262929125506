
import create from "zustand";
import { LocationResult } from "utilities/geolocation";
import GroupFinderView from "../models";
import { LatLng, MapLocation } from "lib/data/model";
import { GroupDetails } from "lib/models-v2";

const getAvailableGroupTypes = (groupsList: GroupDetails[]) => {
  const programIds = groupsList.map(x => x.programId!);

  return [...new Set(programIds)];
};

export type GroupFinderStore = {
  mapInView?: google.maps.Map | null;
  placesAutoCompleteCenter?: LatLng;
  currentLocation?: MapLocation;
  defaultSearchRadius: number;
  defaultMapZoom: number;
  minMapZoom: number;
  maxMapZoom: number;
  searchItem?: string;
  availableGroups: GroupDetails[];
  availableGroupTypeIds: number[];
  filteredGroups: GroupDetails[];
  sortedLocationGroups: any[];
  lastSearchLocation?: LocationResult;
  isLoading: boolean;
  currentView: GroupFinderView;
  setMapInView: (newMap: google.maps.Map | null) => void,
  setCurrentLocation: (newLocation: MapLocation) => void;
  setResults: (results: GroupDetails[]) => void;
  setFilteredResults: (filteredGroups: GroupDetails[]) => void;
  setView: (view: GroupFinderView) => void;
  setIsLoading: (isLoading: boolean) => void;
  setSearchItem: (searchItem: string) => void;
};

export const useGroupFinderStore = create<GroupFinderStore>(
  (set): GroupFinderStore => ({
    availableGroups: [],
    availableGroupTypeIds: [],
    filteredGroups: [],
    isLoading: false,
    defaultSearchRadius: 26660, //default zoom(12) at the equater has a distance of 26.659986209227586 km between the center and the northeast corner. converted and rounded to 26660 meters,
    defaultMapZoom: 12,
    minMapZoom: 8,
    maxMapZoom: 18,
    sortedLocationGroups: [],
    currentView: GroupFinderView.mapView,
    setResults: (results: GroupDetails[]) => set(() => ({ availableGroupTypeIds: getAvailableGroupTypes(results), availableGroups: results, filteredGroups: results, isLoading: false })),
    setFilteredResults: (filteredGroups: GroupDetails[]) => set((state) => {
      if (!state.isLoading) {
        return { filteredGroups: filteredGroups };
      }

      return { filteredGroups: [] };
    }),
    setMapInView: (newMap: google.maps.Map | null) => set(() => ({ mapInView: newMap })),
    setCurrentLocation: (newLocation: MapLocation) => set(() => ({ currentLocation: newLocation })),
    setView: (view: GroupFinderView) => set(() => ({ currentView: view })),
    setIsLoading: (isLoading: boolean) => set(() => ({ isLoading: isLoading })),
    setSearchItem: (searchItem: string) => set(() => ({ searchItem: searchItem }))
  })
);

export default useGroupFinderStore;