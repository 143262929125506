import API from "./api";
import { ApplicationPaths, EndPoints } from "constants/api-auth-constants";

export default class Requests extends API {

  static checkAuthenticated() {
    return API.parseResponse("get", ApplicationPaths.IsAuthenticatedCheckPath);
  }

  static getUser() {
    return API.parseResponse("get", ApplicationPaths.CurrentUserPath);
  }

  static getProviders() {
    return API.parseResponse("get", "providers/providers");
  }

  static getProvider(providerId) {
    return API.parseResponse("get", `providers/${providerId}/providers`);
  }

  static getProviderTermsAndConditions(providerId, programId) {
    return API.parseResponse("get", `providers/${providerId}/program/${programId}/program-conditions`);
  }

  static createGroup(groupData) {
    return API.authorizeAndParseResponse(EndPoints.CreateGroup, groupData);
  }

  static getGroupWithProtectedData(locationId, programId, groupId, includeOutcomes = false) {
    const newEndPoint = { ...EndPoints.GetGroupWithProtectedData, path: `${EndPoints.GetGroupWithProtectedData.path}/location/${locationId}/program/${programId}/group/${groupId}/group-protected-info?includeOutcomes=${includeOutcomes}` };
    return API.authorizeAndParseResponse(newEndPoint);
  }

  static saveOutcomes(locationId, programId, groupId, outcomes) {
    const newEndPoint = { ...EndPoints.SaveOutcomes, path: `${EndPoints.SaveOutcomes.path}/location/${locationId}/program/${programId}/group/${groupId}/wsrs-outcome-surveys` };
    return API.authorizeAndParseResponse(newEndPoint, outcomes);
  }

  static getGroup(locationId, programId, groupId) {
    return API.parseResponse("get", `groups/location/${locationId}/program/${programId}/group/${groupId}/group`, null, null, true);
  }

  static getEnrollmentExists(locationId, programId, groupId) {
    return API.parseResponse("get", `groups/location/${locationId}/program/${programId}/group/${groupId}/enrollment-exists`, null, null, true);
  }

  static updateGroup(locationId, programId, groupId, group) {
    const newEndPoint = { ...EndPoints.UpdateGroup, path: `${EndPoints.UpdateGroup.path}/location/${locationId}/program/${programId}/group/${groupId}/group` };
    return API.authorizeAndParseResponse(newEndPoint, group);
  }

  static getSites() {
    return API.authorizeAndParseResponse(EndPoints.GetSites);
  }

  static registerLimitedStudent(groupId, providerId, registrationData) {
    return API.parseResponse("post", `groups/group/${groupId}/${providerId}/limited-enrollment`, registrationData, null, true);
  }

  static registerStudent(providerId, locationId, programId, groupId, trackId, termId, registrationData) {
    return API.parseResponse("post",
      `groups/provider/${providerId}/location/${locationId}/program/${programId}/group/${groupId}/track/${trackId}/term/${termId}/enrollment`,
      registrationData, null, true);
  }

  static cancelGroup(locationId, programId, groupId, trackId) {
    const CancelGroupEndPoint = { ...EndPoints.CancelGroup, path: `${EndPoints.CancelGroup.path}/location/${locationId}/program/${programId}/group/${groupId}/track/${trackId}/cancel-group` };
    return API.authorizeAndParseResponse(CancelGroupEndPoint);
  }

  static concludeGroup(locationId, programId, groupId, trackId) {
    const ConcludeGroupEndPoint = { ...EndPoints.ConcludeGroup, path: `${EndPoints.ConcludeGroup.path}/location/${locationId}/program/${programId}/group/${groupId}/track/${trackId}/conclude-group` };
    return API.authorizeAndParseResponse(ConcludeGroupEndPoint);
  }

  static completeStudents(locationId, programId, groupId, studentIds) {
    const newEndPoint = { ...EndPoints.CompleteStudents, path: `${EndPoints.CompleteStudents.path}/location/${locationId}/program/${programId}/group/${groupId}/complete-students` };
    return API.authorizeAndParseResponse(newEndPoint, studentIds);
  }

  static dropStudents(locationId, programId, groupId, studentIds) {
    const newEndPoint = { ...EndPoints.DropStudents, path: `${EndPoints.DropStudents.path}/location/${locationId}/program/${programId}/group/${groupId}/drop-students` };
    return API.authorizeAndParseResponse(newEndPoint, studentIds);
  }

  static completeLimitedStudents(locationId, programId, groupId, studentIds) {
    const newEndPoint = { ...EndPoints.CompleteLimitedStudents, path: `${EndPoints.CompleteLimitedStudents.path}/location/${locationId}/program/${programId}/group/${groupId}/complete-limited-students` };
    return API.authorizeAndParseResponse(newEndPoint, studentIds);
  }

  static dropLimitedStudents(groupId, studentIds) {
    const newEndPoint = { ...EndPoints.DropLimitedStudents, path: `${EndPoints.DropLimitedStudents.path}/group/${groupId}/drop-limited-students` };
    return API.authorizeAndParseResponse(newEndPoint, studentIds);
  }

  static updateConsents(locationId, programId, groupId, studentIds) {
    const newEndPoint = { ...EndPoints.UpdateConsents, path: `${EndPoints.UpdateConsents.path}/location/${locationId}/program/${programId}/group/${groupId}/update-consents` };
    return API.authorizeAndParseResponse(newEndPoint, studentIds);
  }

  static updateLimitedConsents(groupId, studentIds) {
    const newEndPoint = { ...EndPoints.UpdateLimitedConsents, path: `${EndPoints.UpdateLimitedConsents.path}/group/${groupId}/update-limited-consents` };
    return API.authorizeAndParseResponse(newEndPoint, studentIds);
  }

  static getUserGroups() {
    return API.authorizeAndParseResponse(EndPoints.GetUserGroups);
  }

  static getProviderGroups(providerId, mapBounds, mapCenter, courseIds, token, excludeCompletedCancelled, excludeFull, locationsLimit) {
    var data = { excludeCompletedCancelled, excludeFull, locationsLimit };
    if (mapBounds && mapCenter) {
      data.northLatitude = mapBounds.neLat;
      data.eastLongitude = mapBounds.neLng;
      data.southLatitude = mapBounds.swLat;
      data.westLongitude = mapBounds.swLng;
      data.mapCenterLatitude = mapCenter.lat;
      data.mapCenterLongitude = mapCenter.lng;
    }
    if (courseIds && courseIds.length > 0) {
      data.courseIds = courseIds;
    }
    return API.parseResponse("get", `groups/provider/${providerId}/provider-groups`, data, token, true);
  }

  static getCourses() {
    return API.authorizeAndParseResponse(EndPoints.GetCourses);
  }

  static getProviderCourses(providerId) {
    return API.parseResponse("get", `courses/provider/${providerId}/courses`);
  }

  static getFacilitators(providerId, stakeUnitNumber) {
    const newEndPoint = { ...EndPoints.GetFacilitators, path: `${EndPoints.GetFacilitators.path}/provider/${providerId}/stake/${stakeUnitNumber}/stake-facilitators` };
    return API.authorizeAndParseResponse(newEndPoint);
  }

  static getStakeMembers(stakeUnitNumber) {
    const newEndPoint = { ...EndPoints.GetStakeMembers, path: `${EndPoints.GetStakeMembers.path}/${stakeUnitNumber}/members` };
    return API.authorizeAndParseResponse(newEndPoint);
  }

  static getStakeMemberHasChurchAccount(cmisId) {
    const stakeMemberHasChurchAccountEndPoint = { ...EndPoints.stakeMemberHasChurchAccount, path: `${EndPoints.stakeMemberHasChurchAccount.path}/${cmisId}` };
    return API.authorizeAndParseResponse(stakeMemberHasChurchAccountEndPoint);
  }

  static getStudents() {
    return API.authorizeAndParseResponse(EndPoints.GetStudents);
  }

}