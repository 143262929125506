import Column from "components/table/column/column";
import Pagination from "components/table/pagination/pagination";
import CheckboxColumn from "components/table/checkbox-column/checkbox-column";
import SortableColumn from "components/table/sortable-column/sortable-column";
import Table from "components/table/table";
import { useIntl } from "react-intl";
import { TruncatedParticipantName } from "components/truncated-participant-name";
import ParticipantActionMenu from "components/participant-action-menu";
import { ReactElement, useState } from "react";
import TruncatedEmail from "components/truncated-email/truncated-email";
import ParticipantStatus from "components/participant-status";
import { FullGroup, QuickRegParticipant } from "lib/models-v2";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ParticipantRegistrationDate from "components/participant-registration-date";
import { Link } from "react-router-dom";

interface IGroupStudentListProps {
  group: FullGroup;
  filteredGroupStudents: QuickRegParticipant[];
  onSelectionChange: (checked: boolean, student: QuickRegParticipant, accessor: string) => void;
  onAllSelectionChange: (checked: boolean) => void;
  actions: ReactElement
  isSelected?: (student: QuickRegParticipant) => boolean;
  onParticipantChange?: (participant: QuickRegParticipant) => void;
}

export const GroupStudentListTableV2 = (props: IGroupStudentListProps) => {

  const [perPageCount, setPerPageCount] = useState(25);
  const intl = useIntl();

  return (
    <div className="groupStudentListContainer">
      <Table rows={props.filteredGroupStudents} className="groupStudentListTable" id={"groupStudentListTable"}>
        <CheckboxColumn>
          <CheckboxColumn.Header onCheck={props.onAllSelectionChange} />
          <CheckboxColumn.Row onCheck={props.onSelectionChange} isSelected={props.isSelected} />
        </CheckboxColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "name", defaultMessage: "name" })} />
          <SortableColumn.Row
            render={(participant: QuickRegParticipant) => (
              <Link to={`/participant/${participant.id}/group/${participant.groupId}`}>
                <TruncatedParticipantName participant={participant} />
              </Link>
            )}
            sort={(a: QuickRegParticipant, b: QuickRegParticipant) => `${a.surname}, ${a.givenName}` > `${b.surname}, ${b.givenName}` ? 1 : -1}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header
            name={intl.formatMessage({
              id: "church_account",
              defaultMessage: "Church Account",
            })}
          />
          <SortableColumn.Row
            render={(participant: QuickRegParticipant) => {
              return participant.cmisId ? <CheckIcon /> : <CloseIcon />;
            }}
            sort={(a: QuickRegParticipant, b: QuickRegParticipant) =>
              a.cmisId && !b.cmisId ? 1 : -1
            }
          ></SortableColumn.Row>
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "email", defaultMessage: "Email" })} />
          <SortableColumn.Row
            render={(participant: QuickRegParticipant) => (
              <TruncatedEmail email={participant.email} />
            )}
            sort={(a: QuickRegParticipant, b: QuickRegParticipant) => a.email > b.email ? 1 : -1}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "phone_number", defaultMessage: "Phone Number" })} />
          <SortableColumn.Row
            render={(participant: QuickRegParticipant) => (<>
              {participant.phoneNumber ?
                `${participant.dialCode ? `+${participant.dialCode}-` : ""}${participant.phoneNumber}`
                :
                "---"
              }
            </>
            )}
            sort={(a: QuickRegParticipant, b: QuickRegParticipant) => a.phoneNumber > b.phoneNumber ? 1 : -1}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header name={intl.formatMessage({ id: "status", defaultMessage: "status" })} />
          <SortableColumn.Row
            render={(participant: QuickRegParticipant) => <ParticipantStatus status={participant.status} />}
            sort={(a: QuickRegParticipant, b: QuickRegParticipant) => a.status > b.status ? 1 : -1}
          />
        </SortableColumn>
        <SortableColumn>
          <SortableColumn.Header
            name={intl.formatMessage({
              id: "sign_up_date",
              defaultMessage: "Sign Up Date",
            })}
          />
          <SortableColumn.Row
            render={(participant: QuickRegParticipant) => <ParticipantRegistrationDate date={participant.createdDate} />}
            sort={(a: QuickRegParticipant, b: QuickRegParticipant) =>
              a.createdDate > b.createdDate ? 1 : -1
            }
          />
        </SortableColumn>
        <Column>
          <Column.Header actions={props.actions} />
          <Column.Row
            render={(participant) => (
              <ParticipantActionMenu
                participant={participant}
                groupName={props.group.name}
                groupStatus={props.group.status}
                hasCompletionCertificate={props.group.hasCompletionCertificate}
                hasParticipationCertificate={props.group.hasParticipationCertificate}
                stakeUnitNumber={props.group.stakeUnitNumber}
                onParticipantChange={props.onParticipantChange}
              />
            )}
          />
        </Column>
        <Pagination perPageCount={perPageCount} recordCount={props.filteredGroupStudents.length} onChange={(_, pageCount) => { setPerPageCount(pageCount); }} perPageOptions={[{ label: "All", value: -1 }, 5, 10, 25, 50]} />
      </Table>
    </div>
  );
};

export default GroupStudentListTableV2;