import { Group, GuestFacilitator, StandardFacilitator } from "lib/data/model";
import { AgeType, LocationType } from "lib/data/model/enum";
import GroupDto from "../model/GroupDto";
import { GuestFacilitatorToFacilitatorDtoConverter } from "./GuestFacilitatorToFacilitatorDtoConverter";
import { IModelToDtoConverter } from "./IDtoConverter";
import { StandardFacilitatorToFacilitatorDtoConverter } from "./StandardFacilitatorToFacilitatorDtoConverter";

export class GroupToGroupDtoConverter implements IModelToDtoConverter<Group, GroupDto> {

  private toISOStringWithoutTz = (date: Date | undefined): string | undefined => {
    if(!date) return undefined;

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);

    const dateString = date.toISOString().split("T", 1)[0];

    const isoStringWithoutTz = `${dateString}T00:00:00`;

    return isoStringWithoutTz;
  };

  public toDto = (model: Group): GroupDto => {
    const standardFacilitatorConverter = new StandardFacilitatorToFacilitatorDtoConverter();
    const guestFacilitatorConverter = new GuestFacilitatorToFacilitatorDtoConverter();

    /////////////
    // Convert //
    /////////////

    return new GroupDto({
      id: model.id,
      name: model.name,
      locationId: model.location?.id,
      courseId: model.course?.id,
      courseName: model.course?.name ?? "",
      courseDescription: model.course?.description ?? "",
      courseOutcomes: model.courseOutcomes.map(co => co.export()),
      numberOfLessons: model.numberOfLessons,
      lessonTime: model.lessonTime,
      providerId: model.providerId,
      programId: model.course?.id,
      programDescription: model.course?.description ?? "",
      programLanguages: model.programLanguages.map(pl => pl.export()),
      termId: model.termId,
      trackId: model.trackId,
      address1: model.location?.address?.address1 ?? "",
      address2: model.location?.address?.address2 ?? "",
      city: model.location?.address?.city ?? "",
      state: model.location?.address?.state ?? "",
      country: model.location?.address?.country ?? "",
      postalCode: model.location?.address?.postalCode ?? "",
      latitude: model.location?.coordinates?.latitude,
      longitude: model.location?.coordinates?.longitude,
      startDate: this.toISOStringWithoutTz(model.startDate),
      endDate: this.toISOStringWithoutTz(model.endDate),
      meetingTimes: model.meetingTimes.map(mt => mt.export()),
      facilitators: model.facilitators
        .filter(f => f instanceof StandardFacilitator)
        .map(f => standardFacilitatorConverter.toDto(f as StandardFacilitator))
        .map(f => f.export()),
      nonMemberFacilitators: model.facilitators
        .filter(f => f instanceof GuestFacilitator)
        .map(f => guestFacilitatorConverter.toDto(f as GuestFacilitator))
        .map(f => f.export()),
      students: model.students.map(s => s.export()),
      enrollmentCount: model.enrollmentCount,
      maxEnrollment: model.maxEnrollment,
      status: model.status,
      duration: model.duration,
      propId: model.location?.propertyId,
      isVirtual: model.location?.type === LocationType.Virtual,
      isYouthOnly: model.groupAgeType === AgeType.Youth,
      isAdultOnly: model.groupAgeType === AgeType.Adult,
      groupAgeType: model.groupAgeType === AgeType.Combined ? AgeType[model.groupAgeType] : `${AgeType[model.groupAgeType]} only`,
      isHidden: model.isHidden,
      isCustom: model.location?.type === LocationType.Other,
      isPending: model.isPending,
      hasAcceptedYouthTerms: model.hasAcceptedYouthTerms,
      hasParticipationCertificate: model.hasParticipationCertificate,
      hasCompletionCertificate: model.hasCompletionCertificate,
      wsrsOutcomes: model.wsrsOutcomes.map(wo => wo.export()),
      stakeUnitNumber: model.stakeUnitNumber,
      groupLanguages: model?.groupLanguages ? model.groupLanguages.map(groupLangDto => groupLangDto.export()) : []
    });
  };
}