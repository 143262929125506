import "./groups.scss";

import React, { useEffect, useState } from "react";
import GroupList from "components/group-list/group-list";
import { FormattedMessage } from "react-intl";
import { resetCourseSignupSession } from "../../utilities/navUtils";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import AuthService from "auth/authorize-service";
import { Permissions } from "constants/api-auth-constants";
import GroupCreationManager from "components/group-creation-manager";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import useGroupCreationFormStore from "datastore/groupCreationForm";

const Groups: React.VFC = () => {

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const groupFormStore = useGroupCreationFormStore();

  useEffect(() => {
    resetCourseSignupSession();
  }, []);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    groupFormStore.dropGroup();
  };

  return (
    <div className="groupPageContainer">
      <div className="groupPageHeader">
        <h1><FormattedMessage id="groups" defaultMessage="Groups" /></h1>
        <Button
          disabled={!AuthService.userCan([Permissions.NewGroupWrite])}
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleModalOpen}
        >
          <FormattedMessage id="add_group" defaultMessage="Add Group" />
        </Button>
      </div>

      <Dialog
        className="groups-dialog"
        fullWidth={true}
        maxWidth="xl"
        open={modalOpen}
        onClose={handleModalClose}
      >
        <DialogTitle className="groups-dialog-title" component="div">
          <h5>
            <FormattedMessage id="create_group" defaultMessage="Create Group" />
          </h5>
        </DialogTitle>

        <DialogContent className="groups-dialog-content">
          <GroupCreationManager onSave={handleModalClose} onCancel={handleModalClose} />
        </DialogContent>
      </Dialog>

      <section>
        <GroupList />
      </section>
    </div>
  );
};

export default Groups;


