export const enum FeatureFlags {
  ProviderProgramConsentFormUrls = "Global_ProviderProgramConsentFormUrls", //Global in Pathway.Common
  DuplicateGroupCheck = "QuickReg_DuplicateGroupCheck",
  UnitMemberCMISPrivacy = "QuickReg_MemberCMISPrivacy",
  GroupEndPoints = "Global_QuickRegGroupEndpoints",
  MissionaryAccess = "QuickReg_MissionaryAccess",
  QuickRegEditParticipants = "QuickReg_EditParticipants",
  QuickRegMoveParticipants = "QuickReg_MoveParticipants",
  QuickRegShowSignUpMembershipQuestions = "QuickReg_SignUpMembershipQuestions"
}

export default FeatureFlags;