export interface IProviderDto {
  id?: number;
  name: string;
  colorHex: string;
  languageName: string;
  languageDescription: string;
  languageCodes: string[];
}

export default class ProviderDto implements IProviderDto {
  public readonly id?: number;
  public readonly name: string;
  public readonly colorHex: string;
  public readonly languageName: string;
  public readonly languageDescription: string;
  public readonly languageCodes: string[];

  constructor(creationValues: IProviderDto) {
    this.id = creationValues.id;
    this.name = creationValues.name;
    this.colorHex = creationValues.colorHex;
    this.languageName = creationValues.languageName;
    this.languageDescription = creationValues.languageDescription;
    this.languageCodes = creationValues.languageCodes;
  }

  public export(): IProviderDto {
    return {
      id: this.id,
      name: this.name,
      colorHex: this.colorHex,
      languageName: this.languageName,
      languageDescription: this.languageDescription,
      languageCodes: this.languageCodes
    };
  }

  public clone(): ProviderDto {
    return new ProviderDto(this.export());
  }
}