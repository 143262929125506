import React from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { redirectToServerSignIn } from "utilities/navUtils";
import { mapStateToProps } from "datastore/store";
import AppFrame from "components/app-frame/app-frame";

const ProtectedRoute = (props) => {
  const userState = props.store;
  const showProtectedNav = props?.showProtectedNav === false ? false : true;

  if (userState.isLoading) {
    return null;
  } else if (userState.loginRequired && !userState.isAuthenticated) {
    redirectToServerSignIn();
    return null;
  }

  return (
    <AppFrame showProtectedNav={showProtectedNav}>
      <Outlet />
    </AppFrame>
  );
};

export default connect(mapStateToProps)(ProtectedRoute);
