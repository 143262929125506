import { useState, useEffect } from "react";
import GroupStatus from "../../group-status/group-status";
import GroupHasRoomV2 from "components/group-has-room-v2/group-has-room-v2";
import GroupStatuses from "constants/group-constants";
import Badge from "components/badge/badge";
import { useIntl, FormattedMessage } from "react-intl";
import { getHHMM, getLocaleDateString, getWeekdayName } from "utilities/time";
import GroupFinderGroupDetails from "./group-finder-group-details/group-finder-group-details";
import usePreloadStore, { getLanguages } from "datastore/preload";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { setCourseSignupSession } from "utilities/navUtils";
import "./group-finder-card.scss";
import { GroupDetails } from "lib/models-v2";

interface IProps {
  onSelect: () => void;
  group: GroupDetails;
}

const GroupFinderCard: React.VFC<IProps> = (props) => {

  const { onSelect } = props;
  const [group, setGroup] = useState<GroupDetails>(props.group);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const intl = useIntl();
  const navigate = useNavigate();
  const allLanguages = usePreloadStore(getLanguages);

  const groupLanguages = ((): string[] => {
    if (group?.languages && group.languages.length > 0) {
      return group.languages.map((groupLang) => {
        const myLanguage = allLanguages.find(lang => lang.id == groupLang.id)?.description || intl.formatMessage({ id: "multilingual", defaultMessage: "Multilingual" });
        return myLanguage;
      });
    }
    return [intl.formatMessage({ id: "multilingual", defaultMessage: "Multilingual" })];
  })();

  useEffect(() => {
    setGroup(props.group);
  }, [props.group]);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const goToGroupDetails = () => {
    setCourseSignupSession(
      group.providerId,
      group,
      `${window.location.origin}${window.location.pathname}`,
      `${window.location.origin}${window.location.pathname}`
    );
    navigate(`/group-details/${group.locationId}/${group.programId}/${group.id}`);
  };

  const extraContent = <GroupFinderGroupDetails courseDescription={group.programDescription} />;

  return (
    <div className="groupFinderCardContainer">
      <div className="itemContent">
        <div className="itemTitle">
          <h4 className="infoTitle" style={{ margin: "2px", fontSize: "16px", lineHeight: "normal" }} onClick={goToGroupDetails}>
            <Link to="" onClick={goToGroupDetails}>{group.programName}</Link>
          </h4>
        </div>
        <div className="itemDetails">
          <div className="badgeContainer">
            {(() => {
              if (group.isAdultOnly) {
                return (<Badge type="ageGroup">{intl.formatMessage({ id: "group_age_type_adult", defaultMessage: "Adult only" })}</Badge>);
              } else if (group.isYouthOnly) {
                return (<Badge type="ageGroup">{intl.formatMessage({ id: "group_age_type_youth", defaultMessage: "Youth only" })}</Badge>);
              } else {
                return (<Badge type="ageGroup">{intl.formatMessage({ id: "group_age_type_combined", defaultMessage: "Combined" })}</Badge>);
              }
            })()}
            {groupLanguages && groupLanguages.map((gLang, index) => <Badge key={`group-language-${index}`} type="ageGroup">{gLang}</Badge>)}
          </div>

          {group.isVirtual && !group.isPending && <Badge type="virtual"><FormattedMessage id="virtual" defaultMessage="Virtual" /></Badge>}

          {
            group.isPending
              ? <FormattedMessage id="pending" defaultMessage="Pending" />
              : (
                group.address1
                && !group.isVirtual
                && (
                  <address className="groupAddress">
                    {group.address1}, {(group.address2) && `${group.address2}, `}{group.city} {group.state ? group.state : group.country}
                  </address>
                )
              )
          }

          {
            !group.isPending
            && group.startDate
            && (
              <div className="data">
                <time dateTime={group.startDate}>{getLocaleDateString(group.startDate)}</time>
                <span> - </span>
                {group.endDate ? <time dateTime={group.endDate}>{getLocaleDateString(group.endDate)}</time> : <span><FormattedMessage id="open_schedule" defaultMessage="Open" /></span>}
              </div>
            )
          }

          {
            !group.isPending
            && group.meetingTimes
            && (
              <div className="data">
                {group.meetingTimes.map((day, index) => {
                  return (<div key={`${group.id}-day-${index}`}>{`${intl.formatMessage({ id: getWeekdayName(day.dayOfWeek).toLowerCase(), defaultMessage: getWeekdayName(day.dayOfWeek) })} ${getHHMM(day.startTime)}`}{day.endTime != null && " - " + getHHMM(day.endTime)}</div>);
                })}
              </div>
            )
          }

          <a className="viewMore" onClick={toggleDetails}>
            {!showDetails && <FormattedMessage id="view_more" defaultMessage="View more" />}
          </a>
          {showDetails && extraContent}
          <a className="viewMore" onClick={toggleDetails}>
            {showDetails && <FormattedMessage id="view_less" defaultMessage="View less" />}
          </a>
        </div>
        <div className="statusSignupContainer">
          <GroupHasRoomV2 group={group}>
            <button className="primary" onClick={onSelect}><FormattedMessage id="signup" defaultMessage="Sign Up" /></button>
          </GroupHasRoomV2>
          <GroupStatus status={group.isPending ? GroupStatuses.Pending : group.status} uppercase={false} />
        </div>
      </div>
    </div>
  );
};

export default GroupFinderCard;