import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loading from "components/loading/loading";
import Drawer from "components/drawer/drawer";
import CourseListCard from "./course-list-card/course-list-card";
import AddIcon from "assets/images/icons/add.svg";
import { FormattedMessage } from "react-intl";
import { mapStateToProps, mapDispatchToProps } from "datastore/store";
import { connect } from "react-redux";
import AuthService from "auth/authorize-service";
import { Permissions } from "constants/api-auth-constants";
import UserCan from "components/user-can/user-can";
import CourseListControls from "./course-list-controls/course-list-controls";
import Layouts from "constants/layout-constants";
import "./course-list.scss";

export const ViewNames = Object.freeze({
  Table: "List View",
  Card: "Card View"
});

const CourseList = (props) => {

  const { notify, updateCourses, store, store: { layout } } = props;
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showView, setShowView] = useState(layout == Layouts.Desktop ? ViewNames.Table : ViewNames.Card);
  const canCreateNewGroup = AuthService.userCan([Permissions.NewGroupWrite]);

  useEffect(() => {
    const getCourses = async () => {
      try {
        setLoading(true);
        const newCourses = store.courses || await updateCourses();
        newCourses.forEach((providerGroup, providerGroupIndex) => {
          //Adding property to keep track of drawer open status
          providerGroup["drawerOpen"] = (providerGroupIndex == 0); //Only the first item is open by default
        });
        setCourses(newCourses);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        notify({ status: "error", message: <FormattedMessage id="get_group_type_information_error" defaultMessage="Could not get course information." /> });
        setCourses([]);
      }
    };
    getCourses();
  }, [notify, store.courses, updateCourses]);

  const handleProviderGroupDrawerToggle = (providerGroupIndex) => {
    const newCourses = [...filteredCourses];
    newCourses[providerGroupIndex]["drawerOpen"] = !newCourses[providerGroupIndex]["drawerOpen"];
    setFilteredCourses(newCourses);
  };

  const handleFilter = (filteredData) => {
    if (filteredData.length) {
      const newProviderGroups = [];
      filteredData.forEach(course => {
        const newProviderGroupIndex = newProviderGroups.findIndex(newProviderGroup => newProviderGroup.provider.name == course.providerName);
        if (newProviderGroupIndex !== -1) {
          newProviderGroups[newProviderGroupIndex].courses.push(course);
        } else {
          const provider = courses.find(providerGroup => providerGroup.provider.name == course.providerName).provider;
          newProviderGroups.push({
            provider,
            courses: [course],
            drawerOpen: (newProviderGroups.length == 0) //Only the first item is open by default
          });
        }
      });
      setFilteredCourses(newProviderGroups);
    } else {
      setFilteredCourses(filteredData);
    }
  };

  const buildTableLayout = () => {
    return (
      <>
        <div className="row headings">
          <span className="column course"><FormattedMessage defaultMessage="Group Type" id="group_type" /></span>
          <span className="column description"><FormattedMessage defaultMessage="Description" id="description" /></span>
          <span className="column action"></span>
        </div>
        {filteredCourses?.length > 0 && filteredCourses.map((providerGroup, providerIndex) => {
          return (
            <Drawer
              open={providerGroup.drawerOpen}
              onToggle={() => handleProviderGroupDrawerToggle(providerIndex)}
              summary={
                <>
                  <span>{providerGroup.provider.name} ({providerGroup.courses.length})</span>
                </>}
              key={`provider-${providerIndex}`}
            >
              {providerGroup.courses.map((course, courseIndex) => {
                return (
                  <div className="row content" key={`classData-${courseIndex}`}>
                    <div className="column course">
                      {course.name}
                    </div>
                    <div className="column description">
                      {course.description}
                    </div>
                    <div className="column action">
                      <Link to={canCreateNewGroup ? { pathname: "/groups/add", state: { defaultCourse: { providerId: course.providerId, courseId: course.id } } } : ""}><button disabled={!canCreateNewGroup} className="primary"><span className="buttonIcon"><img src={AddIcon} alt="Addition Symbol" /></span><FormattedMessage id="group" defaultMessage="Group" /></button></Link>
                    </div>
                  </div>
                );
              })}
            </Drawer>
          );
        })}
      </>
    );
  };

  const buildCardLayout = () => {
    return (
      <>
        {filteredCourses?.length && filteredCourses.map((providerGroup, providerIndex) => {
          return (
            <Drawer
              open={providerGroup.drawerOpen}
              onToggle={() => handleProviderGroupDrawerToggle(providerIndex)}
              summary={`${providerGroup.provider.name} (${providerGroup.courses.length})`}
              key={`provider-${providerIndex}`}
            >
              <div className="cardListContainer">
                {providerGroup.courses.length && providerGroup.courses.map((course, courseIndex) => {
                  return (
                    <React.Fragment key={courseIndex}>
                      <CourseListCard course={course} />
                    </React.Fragment>
                  );
                })}
              </div>
            </Drawer>
          );
        })}
      </>
    );
  };

  return (
    <UserCan perform={[Permissions.CourseCatalogRead]} showFail={true}>
      <div className="courseListContainer hasLoader">
        <CourseListControls courses={courses} onFilter={handleFilter} isLoading={loading} viewValue={showView} viewSetter={setShowView} />
        <Loading loading={loading} />
        {showView == ViewNames.Table && props.store.layout == Layouts.Desktop ? buildTableLayout() : buildCardLayout()}
      </div>
    </UserCan>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseList);
