import "./table.scss";
import { ReactElement, useEffect, useState } from "react";
import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import Column from "./column/column";
import SortableColumn from "./sortable-column/sortable-column";
import Pagination from "./pagination/pagination";
import useClassName from "utilities/useClassName";
import CheckboxColumn from "./checkbox-column/checkbox-column";
import { saveSortToSession, getSortFromSession } from "./session/session";
import { PageRow, TablePagination, TableSort } from "./models";
import Row from "./column/row/row";
import { ColumnModel } from "./models";

const allowedColumnChildren = [Column, SortableColumn, CheckboxColumn];
const allowedChildren = [...allowedColumnChildren, Pagination];

const allowedHeaderCells = [Column.Header, SortableColumn.Header, CheckboxColumn.Header];
const allowedRowCells = [Column.Row, SortableColumn.Row, CheckboxColumn.Row];

type RowType = typeof Row;

interface ITableProps {
  id: string,
  className: string;
  rows: any[];
  children: ReactElement[];
}

const Table = (props: ITableProps): ReactElement => {

  const [className, setPropClassNames] = useClassName("table-container");
  const [columns, setColumns] = useState<ColumnModel[]>([]);
  const [rows, setRows] = useState<RowType[]>([]);
  const [pageRows, setPageRows] = useState<PageRow[]>([]);
  const [sort, setSort] = useState<TableSort | null>(getSortFromSession(props.id));
  const [pagination, setPagination] = useState<TablePagination | null>(null);

  useEffect(
    () => {
      setPropClassNames(props.className);
    },
    [props.className]
  );

  useEffect(() => {
    if (props?.id && sort) {
      saveSortToSession(props.id, sort);
    }
  }, [sort]);

  useEffect(
    () => {

      if (props.rows && Array.isArray(props.rows)) {
        const newRows = Array.from(props.rows);

        if (columns.length > 0) {
          const CheckboxColumns = columns.filter(column => column.type === CheckboxColumn);
          if (CheckboxColumns.length > 0) {
            CheckboxColumns.forEach(
              column => {
                column.header!.checked = false;
              }
            );
          }
          setColumns([...columns]);
        }

        if (sort) {
          setSort({ ...sort, applied: false });
        }
        setRows(newRows);
      }
    },
    [props.rows]
  );

  useEffect(
    () => {

      if (sort !== null && !sort.applied) {
        handleColumnSort(sort.columnIndex, sort.direction);
      } else {
        let paginatedRows = Array.from(rows);

        if (pagination) {
          pagination.recordCount = rows.length;
          pagination.pageCount = Math.ceil(pagination.recordCount / pagination.perPageCount);

          setPagination({ ...pagination });

          paginatedRows = paginateRows(paginatedRows);
        }

        setPageRows(paginatedRows.map(row => ({ isSelected: false, data: row })));
      }
    },
    [rows]
  );

  useEffect(
    () => {
      const processChildren = (children) => {
        if (Array.isArray(children)) {
          children = children.filter(i => allowedChildren.includes(i.type));

          const paginationChild = children.find(i => i.type === Pagination);

          if (paginationChild) {
            setPagination({
              currentPage: paginationChild.props.currentPage ?? pagination?.currentPage ?? 1,
              pageCount: paginationChild.props.pageCount ?? pagination?.pageCount ?? 1,
              perPageCount: paginationChild.props.perPageCount ?? pagination?.perPageCount ?? -1,
              perPageOptions: paginationChild.props.perPageOptions ?? pagination?.perPageOptions ?? [{ label: "All", value: -1 }, 5, 10, 25, 50, 100],
              recordCount: paginationChild.props.recordCount ?? pagination?.recordCount ?? 0,
              onChangeHandler: paginationChild.props.onChange,
              onSortHandler: paginationChild.props.onSort,
              element: paginationChild
            });
          }
          setColumns(
            children
              .filter(i => allowedColumnChildren.includes(i.type))
              .map(
                (column, index) => ({
                  type: column.type,
                  element: column,
                  ...processColumnChildren(column.props.children, index)
                })
              )
          );
        }
        else if (allowedColumnChildren.includes(children.type)) {
          const column = children;
          setColumns(
            [{
              type: column.type,
              element: column,
              ...processColumnChildren(column.props.children)
            }]
          );
        }
        else if (children.type === Pagination) {
          setPagination({
            currentPage: children.props.currentPage ?? pagination?.currentPage ?? 1,
            pageCount: children.props.pageCount ?? pagination?.pageCount ?? 1,
            perPageCount: children.props.perPageCount ?? pagination?.perPageCount ?? -1,
            perPageOptions: children.props.perPageOptions ?? pagination?.perPageOptions ?? [{ label: "All", value: -1 }, 5, 10, 25, 50, 100],
            recordCount: children.props.recordCount ?? pagination?.recordCount ?? 0,
            onChangeHandler: children.props.onChange,
            onSortHandler: children.props.onSort,
            element: children
          });
        }
        else {
          setPagination(null);
          setColumns([]);
        }
      };

      if (props.children) {
        processChildren(props.children);
      }
    },
    [props.children]
  );

  const paginateRows = (rows) => {
    if (pagination && pagination.recordCount > 0) {
      let firstIndex = 0;
      let expectedLastIndex = 0;

      firstIndex = (pagination.currentPage - 1) * pagination.perPageCount;

      if (pagination.perPageCount > 0) {
        expectedLastIndex = (Math.max(pagination.currentPage, 1) * pagination.perPageCount) - 1;
      }
      else {
        expectedLastIndex = pagination.recordCount - 1;
      }

      const actualLastIndex = expectedLastIndex > pagination.recordCount ? pagination.recordCount : expectedLastIndex;

      return rows.slice(firstIndex, actualLastIndex + 1);
    }

    return rows;
  };

  const processColumnChildren = (columnChildElements, index = null) => {
    let headerChild = null;
    let rowChild = null;

    if (columnChildElements) {
      if (Array.isArray(columnChildElements)) {
        headerChild = columnChildElements.find(child => allowedHeaderCells.includes(child.type)) ?? null;
        rowChild = columnChildElements.find(child => allowedRowCells.includes(child.type)) ?? null;
      }
      else if (allowedHeaderCells.includes(columnChildElements.type)) {
        headerChild = columnChildElements;
      }
      else if (allowedRowCells.includes(columnChildElements.type)) {
        rowChild = columnChildElements;
      }
    }

    return {
      header: processColumnHeader(headerChild, index),
      row: processColumnRow(rowChild)
    };
  };

  const processColumnHeader = (headerElement, index) => {
    if (!headerElement) {
      return null;
    }
    return {
      sorted: !!(index != null && sort != null && index === sort?.columnIndex),
      sortDirection: sort?.direction || null,
      checked: false,
      name: headerElement.props.name,
      type: headerElement.type,
      element: headerElement
    };
  };

  const processColumnRow = (rowElement) => {
    if (!rowElement) {
      return null;
    }

    return {
      accessor: rowElement.props.accessor,
      sort: rowElement.props.sort,
      type: rowElement.type,
      element: rowElement,
      isSelected: rowElement.props.isSelected
    };
  };

  const handleColumnSort = (columnIndex, direction) => {
    const column = columns[columnIndex];

    if (pagination && pagination.onChangeHandler && pagination.onSortHandler) {
      pagination.onSortHandler(column.row!.accessor, direction);
    }
    else {
      if (column && column.row && rows.length > 0) {

        const sortedRows = Array.from(rows);

        if (direction && ["asc", "desc"].includes(direction)) {

          sortedRows.sort(
            (a, b) => {
              if (direction === "desc") {
                const temp = a;
                a = b;
                b = temp;
              }

              const aValue = column.row!.accessor ? a[column.row!.accessor] : a;
              const bValue = column.row!.accessor ? b[column.row!.accessor] : b;

              if (column.row!.sort) {
                return column.row!.sort(aValue, bValue);
              }
              else if (!aValue && !bValue) {
                return 0;
              }
              else if (Array.isArray(aValue) || Array.isArray(bValue)) {
                console.warn("Unable to sort array beyond length. Provide sorting function to row in order to sort properly.");
                return (aValue?.length ?? 0) - (bValue?.length ?? 0);
              }
              else if (typeof aValue === "object" || typeof bValue === "object") {
                console.warn("Unable to sort object. Provide sorting function to row in order to sort.");
                return 0;
              }

              return aValue.localeCompare(bValue);
            }
          );
        }
        setSort({ columnIndex, direction, applied: true });
        setRows(sortedRows);
      }
    }

    const newColumns = columns;
    newColumns.forEach((c, i) => {
      if (i === columnIndex) {
        c.header!.sorted = true;
        c.header!.sortDirection = direction;
      }
      else if (c.header) {
        c.header.sorted = false;
        c.header.sortDirection = null;
      }
    });
    setColumns(newColumns);
  };

  const handlePaginationChange = (newCurrentPage, newPerPageCount) => {
    const pageOptions = {
      currentPage: Math.max(newCurrentPage, 1),
      perPageCount: newPerPageCount <= 0 ? -1 : Math.max(newPerPageCount, 1),
      pageCount: Math.max(pagination!.pageCount, 1)
    };

    if (pageOptions.perPageCount !== pagination!.perPageCount) {
      pageOptions.pageCount = pageOptions.perPageCount === -1 ? 1 : Math.ceil(pagination!.recordCount / pageOptions.perPageCount);

      if (pageOptions.currentPage > pageOptions.pageCount) {
        pageOptions.currentPage = pageOptions.pageCount;
      }
    }

    pagination!.currentPage = pageOptions.currentPage;
    pagination!.perPageCount = pageOptions.perPageCount;
    pagination!.pageCount = pageOptions.pageCount;

    setPagination({ ...pagination } as TablePagination);

    const paginatedRows = paginateRows(Array.from(rows));

    setPageRows(paginatedRows.map(row => ({ isSelected: false, data: row })));
  };

  const handleHeaderCheckboxCheck = (columnIndex, checked) => {
    const header = columns[columnIndex].header;

    columns.filter(column => column.type === CheckboxColumn)
      .forEach(
        column => {
          column.header!.checked = checked;
        }
      );

    setColumns([...columns]);

    const newPageRows = pageRows.map(row => ({ isSelected: checked, data: row.data }));

    setPageRows(newPageRows);

    if (header!.element.props.onCheck) {
      header!.element.props.onCheck(checked, pageRows);
    }
  };

  const handleRowCheckboxCheck = (columnIndex, rowIndex, checked, record, value) => {
    const columnRow = columns[columnIndex].row;

    const newPageRows = pageRows.map((row, index) => ({ isSelected: index === rowIndex ? checked : row.isSelected, data: row.data }));

    setPageRows(newPageRows);

    columns.filter(column => column.type === CheckboxColumn)
      .forEach(
        column => {
          if (column.header!.checked && !checked) {
            column.header!.checked = false;
          }
          else if (newPageRows.every(row => row.isSelected)) {
            column.header!.checked = true;
          }
        }
      );

    setColumns([...columns]);

    if (columnRow!.element.props.onCheck) {
      columnRow!.element.props.onCheck(checked, record, value);
    }
  };

  return (
    <Paper className="table-container-wrapper" id={props.id}>
      <TableContainer className={className}>
        <MuiTable className="table" aria-label="simple table" stickyHeader={true}>
          {
            columns.some(column => column.header)
            && (
              <TableHead>
                <TableRow>
                  {
                    columns.map(
                      (column, columnIndex) => (
                        column?.header
                          ? (
                            <column.header.type
                              {...column.header.element.props}
                              key={`header-cell-${columnIndex}`}
                              isSorted={column.header.sorted}
                              sortDirection={column.header.sortDirection}
                              onClick={(direction) => handleColumnSort(columnIndex, direction)}
                              checked={column.header.checked}
                              onCheck={(checked) => handleHeaderCheckboxCheck(columnIndex, checked)}
                            />
                          )
                          : <Column.Header key={`header-cell-${columnIndex}`} />
                      )
                    )
                  }
                </TableRow>
              </TableHead>
            )
          }

          <TableBody>
            {
              pageRows.map(
                (row, rowIndex) => (
                  <TableRow key={`row-${rowIndex}`}>
                    {columns.map(
                      (column, columnIndex) => (
                        column?.row
                          ? (
                            <column.row.type
                              {...column.row.element.props}
                              record={row.data}
                              key={`row-${rowIndex}-cell-${columnIndex}`}
                              checked={column.row.isSelected ? column.row.isSelected : row.isSelected}
                              onCheck={(checked, record, value) => handleRowCheckboxCheck(columnIndex, rowIndex, checked, record, value)}
                            />
                          )
                          : <Column.Row key={`row-${rowIndex}-cell-${columnIndex}`} />
                      )
                    )}
                  </TableRow>
                )
              )
            }
          </TableBody>
        </MuiTable>
      </TableContainer>

      {
        pagination
        && pageRows.length > 0
        && (
          <div className="pagination-container">
            {
              pagination?.onChangeHandler ?
                <>
                  <Pagination
                    pageCount={pagination.pageCount}
                    currentPage={pagination.currentPage}
                    perPageOptions={pagination.perPageOptions}
                    perPageCount={pagination.perPageCount}
                    recordCount={pagination.recordCount}
                    onChange={(currentPage, perPage) => {
                      handlePaginationChange(currentPage, perPage);
                      if (pagination.onChangeHandler) {
                        pagination.onChangeHandler(currentPage, perPage);
                      }
                    }}
                  />
                </>
                : (
                  <Pagination
                    pageCount={pagination.pageCount}
                    currentPage={pagination.currentPage}
                    perPageOptions={pagination.perPageOptions}
                    perPageCount={pagination.perPageCount}
                    recordCount={pagination.recordCount}
                    onChange={handlePaginationChange}
                  />
                )
            }
          </div>
        )
      }

    </Paper>
  );

};

export default Table;