import "./join-now.scss";
import { useEffect } from "react";
import { mapStateToProps, mapDispatchToProps } from "datastore/store";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { resetCourseSignupSession } from "../../utilities/navUtils";
import { redirectToServerSignOut } from "utilities/navUtils";
import AuthorizeService from "../../auth/authorize-service";
import { FormattedMessage } from "react-intl";
import { SignOutReason } from "constants/api-auth-constants";
import { GroupFinderV2 } from "components/group-finderV2";

function getIdsFromParams(params: string[]): number[] {
  if (Array.isArray(params)) {
    const ids: number[] = [];
    params.forEach(param => {
      try {
        const parsedValue = parseInt(param);
        if (parsedValue) {
          ids.push(parsedValue);
        }
      } catch {
        //do nothing
      }
    });
    return ids;
  }
  return [];
}

interface IJoinNowProps {
  store: any;
}

const JoinNow: React.VFC<IJoinNowProps> = (props) => {

  const [searchParams] = useSearchParams();
  const parentProgramIds: number[] = getIdsFromParams(searchParams.getAll("parentProgramId"));
  const providerIds: number[] = getIdsFromParams(searchParams.getAll("providerId"));
  const programIds: number[] = getIdsFromParams(searchParams.getAll("programId"));

  useEffect(() => {
    resetCourseSignupSession();
  }, []);

  useEffect(() => {
    AuthorizeService.getAuthStatus()
      .then((authStatus) => {
        if (authStatus && authStatus.authenticated) {
          redirectToServerSignOut({ reason: SignOutReason.SignUpSessionActive });
        }
      })
      .catch(() => {
        redirectToServerSignOut({ reason: SignOutReason.SignUpSessionActive });
      });
  }, [props.store.user]);

  return (
    <div className="joinNowPageContainer">
      <div className="pageHead">
        <h1>
          <FormattedMessage id="find_group" defaultMessage="Find Group" />{" "}
        </h1>
      </div>
      <hr />
      <GroupFinderV2
        limitToParentPrograms={parentProgramIds.length ? parentProgramIds : undefined}
        limitToProviders={providerIds.length ? providerIds : undefined}
        limitToPrograms={programIds.length ? programIds : undefined}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinNow);