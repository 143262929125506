export interface IWsrsOutcomeDto {
  id?: number;
  groupId?: number;
  studentId?: number;
  limitedStudentId?: number;
  startedOrImprovedBusiness?: boolean;
  startedEduProgram?: boolean;
  newOrBetterJob?: boolean;
  startedOrImprovedBudget?: boolean;
  earnedCertificate?: boolean;
}

export default class WsrsOutcomeDto implements IWsrsOutcomeDto {

  public id?: number;
  public groupId?: number;
  public studentId?: number;
  public limitedStudentId?: number;
  public startedOrImprovedBusiness?: boolean;
  public startedEduProgram?: boolean;
  public newOrBetterJob?: boolean;
  public startedOrImprovedBudget?: boolean;
  public earnedCertificate?: boolean;

  constructor(creationValues?: IWsrsOutcomeDto) {
    this.id = creationValues?.id;
    this.groupId = creationValues?.groupId;
    this.studentId = creationValues?.studentId;
    this.limitedStudentId = creationValues?.limitedStudentId;
    this.startedOrImprovedBusiness = creationValues?.startedOrImprovedBusiness;
    this.startedEduProgram = creationValues?.startedEduProgram;
    this.newOrBetterJob = creationValues?.newOrBetterJob;
    this.startedOrImprovedBudget = creationValues?.startedOrImprovedBudget;
    this.earnedCertificate = creationValues?.earnedCertificate;
  }

  public export(): IWsrsOutcomeDto {
    return {
      id: this.id,
      groupId: this.groupId,
      studentId: this.studentId,
      limitedStudentId: this.limitedStudentId,
      startedOrImprovedBusiness: this.startedOrImprovedBusiness,
      startedEduProgram: this.startedEduProgram,
      newOrBetterJob: this.newOrBetterJob,
      startedOrImprovedBudget: this.startedOrImprovedBudget,
      earnedCertificate: this.earnedCertificate
    };
  }

  public clone(): WsrsOutcomeDto {
    return new WsrsOutcomeDto(this.export());
  }
}