import { KeyboardEvent, ReactElement, useContext } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilterIcon from "assets/images/icons/filter-icon.svg";
import Layouts from "constants/layout-constants";
import { ControlInput, FilterConfig } from "../../models";
import Badge from "components/badge/badge";
import { useSelector } from "react-redux";
import FilterPanelModal from "../filter-panel-modal/";
import { InputPanel } from "../../input-panel";
import { ListControlPanelContext } from "../../list-control-panel-store";
import "./filter-panel-mobile-map-view.scss";
import { useStore } from "zustand";

interface IFilterPanelMobileMapView {
  showSearch: boolean,
  customSearch?: ReactElement,
  handleSearchKeyUp: (event: KeyboardEvent<HTMLInputElement>) => void;
  handleFilterGroupsChange: (dataset: FilterConfig) => void;
  inputs: ControlInput[];
}

export const FilterPanelMobileMapView = (props: IFilterPanelMobileMapView): ReactElement => {

  const { showSearch, customSearch, handleSearchKeyUp, handleFilterGroupsChange, inputs } = props;
  const filterStore = useContext(ListControlPanelContext);
  const { filterConfig, isLoading, setIsFilterModalOpen } = useStore(filterStore!, s => s);
  const layout = useSelector((state: any) => state.appReducer.layout);

  const activeCount = (() => {
    if (filterConfig?.length) {
      let myCount = 0;
      filterConfig.forEach(filterGroup => {
        if (filterGroup?.filters?.length) {
          filterGroup.filters.forEach(filter => {
            myCount += filter.activeValues.length;
          });
        }
      });
      return myCount;
    }
    return 0;
  })();

  return (
    <div className="filterPanelMobileMapViewContainer">
      {showSearch !== false && !isLoading &&
        <div className="searchContainer">
          {customSearch ?
            customSearch
            :
            <TextField
              onKeyUp={handleSearchKeyUp}
              InputProps={{
                disableUnderline: true,
                className: "searchInput",
                endAdornment:
                  <InputAdornment
                    position="end"
                  >
                    <SearchIcon />
                  </InputAdornment>
              }}
            />
          }
        </div>
      }
      <div className="filterMenu">
        {!isLoading &&
          <>
            <div
              className="narrowResultsContainer"
              onClick={() => setIsFilterModalOpen(true)}
            >
              <div className="filterIconContainer">
                <img src={FilterIcon} alt="filters" />
                {(layout == Layouts.Tablet || layout == Layouts.Mobile) && activeCount > 0 &&
                  <Badge type="count">{activeCount}</Badge>
                }
              </div>
            </div>
            <FilterPanelModal
              onApplyFilters={handleFilterGroupsChange}
            />
          </>
        }
      </div>
      <InputPanel
        isLoading={isLoading}
        inputs={inputs}
      />
    </div>
  );

};

export default FilterPanelMobileMapView;