import { ReactElement } from "react";
import { DaysStudentIsConsideredNew } from "constants/student-constants";
import { dateIsLessThanDaysOld } from "utilities/time";
import { QuickRegParticipant } from "lib/models-v2";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { ParticipantGroupDto } from "lib/api/backend/model/participant/ParticipantGroupDto";
import "./truncated-participant-name.scss";

const defaultMaxLength = 96;
const defaultTruncatedPartsLength = 17;

export interface ITruncatedParticipantNameProps {
  participant: QuickRegParticipant | ParticipantGroupDto;
  maxLength?: number;
  truncatedPartsLength?: number;
  showNew?: boolean;
}

export const TruncatedParticipantName = (props: ITruncatedParticipantNameProps): ReactElement => {

  const maxLength = (props.maxLength == undefined) ? defaultMaxLength : props.maxLength;
  const truncatedPartsLength = (props.truncatedPartsLength == undefined) ? defaultTruncatedPartsLength : props.truncatedPartsLength;
  const fullName = `${props.participant.surname}, ${props.participant.givenName}`;
  const fullNameTooLong = fullName.length > maxLength;
  const lastNameShouldBeTruncated = fullNameTooLong && props.participant.surname.length > truncatedPartsLength;
  const firstNameShouldBeTruncated = fullNameTooLong && props.participant.givenName.length > truncatedPartsLength;
  const lastNameWidth = lastNameShouldBeTruncated ? `${truncatedPartsLength}ch` : "auto";
  const firstNameWidth = firstNameShouldBeTruncated ? `${truncatedPartsLength}ch` : "auto";
  const showNew = props.showNew !== false && props.participant.createdDate ? dateIsLessThanDaysOld(props.participant.createdDate, DaysStudentIsConsideredNew) : false;

  return (
    <span className={`truncatedStudentNameContainer${showNew && " new"}`} title={fullName}>
      <span className="namePart" style={{ width: lastNameWidth }}>{props.participant.surname},&nbsp;</span>
      <span dir="rtl" className="namePart" style={{ width: firstNameWidth }}>{props.participant.givenName}</span>
      {showNew &&
        <FiberNewIcon className={"newIcon"} />
      }
    </span>
  );
};

export default TruncatedParticipantName;