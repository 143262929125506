import { useState, useEffect, useRef } from "react";
import "./prompt.scss";
import { getFadeAnimationDuration } from "utilities/styles";
import StatusBox from "components/status-box/status-box";

const Prompt = (props) => {

  const [removing, setRemoving] = useState(false);
  const lastFocusedElement = useRef(document.activeElement);
  const promptId = props.prompt.id;

  useEffect(() => {
    const buttonContainer = document.getElementById(`${promptId}-buttonDiv`);
    if (buttonContainer.firstChild) {
      buttonContainer.firstChild.focus();
    }
  }, []);

  const handleInternalClick = (event) => {
    if (event?.target?.dataset?.closeOnClick) {
      close();
    }
  };

  const handleButtonContainerClick = (event) => {
    event.stopPropagation();
    if (event.target.nodeName == "BUTTON") {
      close();
    }
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
    close();
  };

  const close = () => {
    if (props.onRemove) {
      setRemoving(true);
      setTimeout(() => {
        props.onRemove(promptId);
        lastFocusedElement.current.focus();
      }, getFadeAnimationDuration() - 100); // subtracting 100ms to account for the possible margin of error that occurs with setTimeout
      //                                      causing time between the animation finishing and the element being removed
      //                                      without that the element may flash on the screen again after fading away.
    }
  };

  return (
    <div id={promptId} onClick={handleInternalClick} className={`promptContainer ${props.prompt.status} ${(removing) ? "removing" : ""}`}>
      <StatusBox status={props.prompt.status} head={props.prompt.head} disableOverflowScroll={true}>
        {props.prompt.message}
        <div id={`${promptId}-buttonDiv`} className="buttonContainer" onClick={handleButtonContainerClick}>
          {props.prompt.buttons ?
            <>
              {props.prompt.buttons}
            </>
            :
            <button id={`${promptId}-buttonDiv`} onClick={handleButtonClick}>Ok</button>
          }
        </div>
      </StatusBox>
    </div>
  );
};

export default Prompt;