import React from "react";
import { useLocation } from "react-router-dom";

const Error404: React.FC = () => {

  const location = useLocation();

  return (
    <>
      <h1>Page not found</h1>
      <section>
        <p className="error">404 - No matching route found for: {location.pathname}</p>
        <p>Sorry, it appears the page you are trying to reach does not exist.</p>
      </section>
    </>
  );
};

export default Error404;
