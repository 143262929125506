import { useState, useEffect } from "react";
import PrivateGroupDetails from "components/group-details-private/group-details-private";
import Loading from "components/loading/loading";
import { FormattedMessage } from "react-intl";
import { Permissions } from "constants/api-auth-constants";
import UserCan from "components/user-can/user-can";
import NoticeCreation from "lib/data/model/NoticeCreation";
import dispatchers from "datastore/dispatcher";
import { AddParticipantsButton } from "components/add-participants-button";
import { useDispatch } from "react-redux";
import type { FullGroup } from "lib/models-v2/LeadersGroupResponse";
import { getFullGroup } from "lib/api/backend/requests/groups";
import "./group-participant-editor.scss";
import { ServerResponseType } from "lib/api/backend/request-utils";
import GroupParticipantList from "components/group-participant-list/group-participant-list";
import useGroupStore from "datastore/groups";

interface IGroupStudentEditor {
  groupId: number;
}

const GroupParticipantEditor = (props: IGroupStudentEditor) => {

  const groupStore = useGroupStore();
  const group = groupStore.getGroup(props.groupId);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const reduxDispatch = useDispatch();
  const notify = dispatchers(reduxDispatch).notify as (notice: NoticeCreation) => void;

  useEffect(() => {
    getGroup();
  }, [props.groupId]);

  const getGroup = async (): Promise<void> => {
    setLoading(true);
    if (props.groupId !== null) {
      const groupResponse = await getFullGroup(props.groupId);

      if (groupResponse?.responseType == ServerResponseType.Error) {
        notify({ status: "error", message: <FormattedMessage id="get_group_error" defaultMessage="Could not get group." /> });
        setError(true);
        return;
      }

      if (groupResponse?.responseType == ServerResponseType.Success) {
        groupStore.editGroup(groupResponse.data);
      }

      setLoading(false);

    }
    setLoading(false);
  };

  const handleActionMenuLoadChange = (isLoading) => {
    setLoading(isLoading);
  };

  const handleGroupUpdate = (updatedGroup: FullGroup) => {
    groupStore.editGroup(updatedGroup);
  };

  const handleEnrollmentChange = () => {
    getGroup();
  };

  return (
    <UserCan perform={[Permissions.ManageGroupsRead]} showFail={true}>
      <div className="groupStudentEditorContainer hasLoader">
        <Loading loading={loading} />
        {!loading &&
          <>
            {error &&
              <div className="error">
                <FormattedMessage id="get_group_error" defaultMessage="Could not get group." />
              </div>
            }
            {group && !error &&
              <>
                <PrivateGroupDetails group={group} onLoadingChange={handleActionMenuLoadChange} onGroupUpdate={handleGroupUpdate} onEnrollmentChange={handleEnrollmentChange} includeConsent={true} />
                <div className="participantsHeadingContainer">
                  <h2><FormattedMessage id="participants" defaultMessage="Participants" /></h2>
                  <AddParticipantsButton group={group} />
                </div>
                <GroupParticipantList group={group} />
              </>
            }
          </>
        }
      </div >
    </ UserCan>
  );
};

export default GroupParticipantEditor;
