import React from "react";
import { FormattedMessage } from "react-intl";
import CourseList from "components/course-list/course-list";

const CourseCatalog = () => {
  return (
    <>
      <h1><FormattedMessage id="general_catalog" defaultMessage="Course Catalog" /></h1>
      <CourseList />
    </>
  );
};

export default CourseCatalog;