import { ReactElement, useRef, useEffect } from "react";
import { MapsLoaderContext } from "./store";
import { createMapLoaderStore } from "./store";
import * as MapConstants from "./store/maps-loader-store-constants";

interface IMapsLoaderProvider {
  language: string;
  children: ReactElement;
  onLoad?: () => void;
}

export const MapsLoaderProvider = (props: IMapsLoaderProvider): ReactElement => {

  const store = useRef(createMapLoaderStore({ language: props.language })).current;
  const { ready, mapLanguage } = store.getState();

  useEffect(() => {
    if (!ready && mapLanguage != props.language) {
      removeMapsLibrary();
      addMapsLibrary(props.language);
    } else {
      addMapsLibrary();
    }
  }, [ready]);

  useEffect(() => {
    if (props.language != mapLanguage) {
      store.setState((state) => ({
        ...state,
        ready: false,
      }));
    }
  }, [props.language]);

  const handleScriptLoaded = () => {
    store.setState(state => ({ ...state, ready: true }));
    if (props.onLoad) {
      props.onLoad();
    }
  };

  const addMapsLibrary = (newLanguage?: string) => {
    const languageToApply = newLanguage ? newLanguage : props.language;
    const scriptAlreadyAdded = document.getElementById(MapConstants.mapsScriptElementId) != null;
    if (!scriptAlreadyAdded) {
      const scriptSrc = `${MapConstants.mapsScriptSrc}&lang=${encodeURIComponent(languageToApply)}`;
      const scriptElem = document.createElement("script");
      scriptElem.id = MapConstants.mapsScriptElementId;
      scriptElem.src = scriptSrc;
      scriptElem.async = true;
      scriptElem.defer = true;
      scriptElem.addEventListener("load", handleScriptLoaded);
      document.body.appendChild(scriptElem);
      store.setState(state => ({ ...state, mapLanguage: languageToApply }));
    }
  };

  const removeMapsLibrary = () => {
    //https://developers.google.com/maps/documentation/javascript/localization
    //when the google library is loaded any custom localization comes from the query parameters used to load the library
    //it appears they don't provide a way to update the language after the library has been loaded
    //So to achieve a dynamic language we must delete the library and reload it whenever the language changes
    const scriptElem = document.getElementById(MapConstants.mapsScriptElementId);
    if (scriptElem != null) {
      scriptElem.remove();
    }
    if (window.google) {
      // @ts-ignore surpessing ts error, no-choice but to delete and reload
      delete window.google;
    }
  };

  return (
    <MapsLoaderContext.Provider value={store}>
      {props.children}
    </MapsLoaderContext.Provider>
  );
};

export default MapsLoaderProvider;