import TrackTemplateDto, { ITrackTemplateDto } from "./TrackTemplateDto";

export interface ICourseDto {
  id?: number;
  name: string;
  description: string;
  providerId?: number;
  trackTemplates: ITrackTemplateDto[];
  languageName?: string;
  languageDescription?: string;
  languageCodes?: string[];
}

export default class CourseDto implements ICourseDto {
  public readonly id?: number;
  public readonly name: string;
  public readonly description: string;
  public readonly providerId?: number;
  public readonly trackTemplates: TrackTemplateDto[];
  public readonly languageName?: string;
  public readonly languageDescription?: string;
  public readonly languageCodes?: string[];

  constructor(creationValues: ICourseDto) {
    this.id = creationValues.id;
    this.name = creationValues.name;
    this.description = creationValues.description;
    this.providerId = creationValues.providerId;
    this.trackTemplates = creationValues.trackTemplates ? creationValues.trackTemplates.map(tt => new TrackTemplateDto(tt)) : [];
    this.languageName = creationValues.languageName;
    this.languageDescription = creationValues.languageDescription;
    this.languageCodes = creationValues.languageCodes;
  }

  public export(): ICourseDto {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      providerId: this.providerId,
      trackTemplates: this.trackTemplates.map(tt => tt.export()),
      languageName: this.languageName,
      languageDescription: this.languageDescription,
      languageCodes: this.languageCodes
    };
  }

  public clone(): CourseDto {
    return new CourseDto(this.export());
  }
}