import { KeyboardEvent, ReactElement, useContext } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilterIcon from "assets/images/icons/filter-icon.svg";
import Layouts from "constants/layout-constants";
import { FilterConfig } from "../../models";
import Badge from "components/badge/badge";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import FilterPanelModal from "../filter-panel-modal/";
import { ListControlPanelContext } from "../../list-control-panel-store";
import "./filter-panel-standard-view.scss";
import { useStore } from "zustand";

interface IFilterPanelStandardView {
  showSearch: boolean,
  customSearch?: ReactElement,
  handleSearchKeyUp: (event: KeyboardEvent<HTMLInputElement>) => void;
  handleFilterGroupsChange: (filters: FilterConfig) => void;
  clearSelectedFilter: (filterGroupIndex: number, filterIndex: number, filterActiveValueIndex: number) => void;
}

export const FilterPanelStandardView = (props: IFilterPanelStandardView): ReactElement => {

  const { showSearch, customSearch, handleSearchKeyUp, handleFilterGroupsChange, clearSelectedFilter } = props;
  const filterStore = useContext(ListControlPanelContext);
  const { isLoading, filterConfig, setIsFilterModalOpen } = useStore(filterStore!, s => s);
  const intl = useIntl();
  const layout = useSelector((state: any) => state.appReducer.layout);

  const activeCount = (() => {
    if (filterConfig?.length) {
      let myCount = 0;
      filterConfig.forEach(filterGroup => {
        if (filterGroup?.filters?.length) {
          filterGroup.filters.forEach(filter => {
            myCount += filter.activeValues.length;
          });
        }
      });
      return myCount;
    }
    return 0;
  })();

  return (
    <div className="filterPanelStandardViewContainer">
      {showSearch !== false && !isLoading &&
        <>
          {customSearch ?
            customSearch
            :
            <TextField
              onKeyUp={handleSearchKeyUp}
              InputProps={{
                className: "searchInput",
                endAdornment:
                  <InputAdornment
                    position="end"
                  >
                    <SearchIcon />
                  </InputAdornment>
              }}
            />
          }
        </>
      }
      <div className="filterMenu">
        {!isLoading &&
          <>
            <div
              className={`narrowResultsContainer ${(layout == Layouts.Tablet || layout == Layouts.Mobile) && activeCount > 0 ? "active" : ""} ${(layout == Layouts.Tablet) ? "clearBg" : ""}`}
              onClick={() => setIsFilterModalOpen(true)}
            >
              <img src={FilterIcon} alt="filters" />
              <span>{intl.formatMessage({ id: "narrow_results", defaultMessage: "Narrow Results" })}</span>
              {(layout == Layouts.Tablet || layout == Layouts.Mobile) && activeCount > 0 &&
                <Badge type="count">{activeCount}</Badge>
              }
            </div>
            <FilterPanelModal
              onApplyFilters={handleFilterGroupsChange}
            />
          </>
        }
      </div>
      {layout == Layouts.Desktop && !isLoading &&
        <div className="badgesContainer">
          {filterConfig && filterConfig.map((filterGroup, filterGroupIndex) => {
            return filterGroup.filters.map((filter, filterIndex) => {
              return filter.activeValues.map((value, filterActiveValueIndex) => {
                const valueDisplayText = (typeof value == "object" && filter.displayField) ? value[filter.displayField] : value;
                const filterBadgeLabel = filter?.badgeLabel ? `${filter.badgeLabel}: ` : "";
                const displayText = `${filterBadgeLabel}${valueDisplayText}`;
                return (
                  <Badge type="filter" key={`${displayText}-badge-${filterGroupIndex}-${filterIndex}-${filterActiveValueIndex}`}>{displayText} <span className="pillClear" onClick={() => clearSelectedFilter(filterGroupIndex, filterIndex, filterActiveValueIndex)}>X</span></Badge>
                );
              });
            });
          })}
        </div>
      }
    </div>
  );
};

export default FilterPanelStandardView;