import "./consent-form-url-selector.scss";
import { useState, useEffect, ReactElement } from "react";
import { MenuItem, TextField } from "@mui/material";
import FeatureFlags from "constants/feature-flags";
import { ProviderProgramConsentFormUrlDto } from "lib/api/backend/model/provider";
import { getFeatureFlagValue } from "lib/api/backend/requests/feature-flags";
import { getProviderProgramConsentFormUrls } from "lib/api/backend/requests/providers";
import { FormattedMessage } from "react-intl";
import Loading from "components/loading/loading";

interface IConsentFormUrlSelectorProps {
  providerId: number;
  programId: number;
  hideHeading?: boolean;
  showWarning?: boolean;
  onLoad?: (urls?: ProviderProgramConsentFormUrlDto[]) => void
}

export const ConsentFormUrlSelector = (props: IConsentFormUrlSelectorProps): ReactElement => {

  const [consentFormUrlsFlagEnabled, setConsentFormUrlsFlagEnabled] = useState<boolean>(false);
  const [consentFormUrls, setConsentFormUrls] = useState<ProviderProgramConsentFormUrlDto[]>([]);
  const [selectedConsentFormUrl, setSelectedConsentFormUrl] = useState<ProviderProgramConsentFormUrlDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const selectedConsentFormIndex = selectedConsentFormUrl ? consentFormUrls.findIndex(formUrl => formUrl.id == selectedConsentFormUrl.id) : null;

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const featureFlag = await getFeatureFlagValue(FeatureFlags.ProviderProgramConsentFormUrls);
    setConsentFormUrlsFlagEnabled(featureFlag.enabled);
    if (featureFlag.enabled) {
      getConsentFormUrls();
    }
  };

  const getConsentFormUrls = async () => {
    if (props.providerId && props.programId) {
      try {
        setLoading(true);
        const consentFormUrls = await getProviderProgramConsentFormUrls(props.providerId, props.programId!);
        setConsentFormUrls(consentFormUrls);
        setSelectedConsentFormUrl(consentFormUrls[0]);
        setLoading(false);
        if (props.onLoad) {
          props.onLoad(consentFormUrls);
        }
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const handleSelectedConsentFormUrlChange = (event) => {
    const consentFormUrlIndex = event.target.value;
    const newSelectedConsentFormUrl = consentFormUrls[consentFormUrlIndex];
    setSelectedConsentFormUrl(newSelectedConsentFormUrl);
  };

  const handleViewFormClick = () => {
    if (selectedConsentFormUrl && selectedConsentFormUrl.url) {
      window.open(selectedConsentFormUrl?.url, "_blank");
    }
  };

  if (!consentFormUrlsFlagEnabled) {
    return (<></>);
  }

  return (
    <div className="consentFormUrlSelectorContainer">
      < Loading loading={loading} />
      {props.showWarning &&
        <>
          {consentFormUrls.length > 0 ?
            <p><FormattedMessage id="age_warning_form" defaultMessage="A parental consent form is required for all group members 17 and younger. You can access the consent form here. Please share it with your group facilitator after it is signed." /></p>
            :
            <p><FormattedMessage id="age_warning" defaultMessage="A parental consent form is required for all participants 17 and younger. Request a parental consent form from the group facilitator." /></p>
          }
        </>
      }
      {consentFormUrls.length > 0 &&
        <>
          {!props.hideHeading &&
            <h6 className="consentHead"><FormattedMessage id="youth_consent_form" defaultMessage="Youth Consent Form" /></h6>
          }
          <div className="inputs">
            {!loading && consentFormUrls.length > 1 &&
              <TextField
                className="consentLanguageSelect"
                select={true}
                value={selectedConsentFormIndex}
                onChange={handleSelectedConsentFormUrlChange}
                label={<FormattedMessage id="language" defaultMessage="Language" />}
              >
                {consentFormUrls.map((formUrl, index) => (
                  <MenuItem key={`consent-form-${index}`} value={index}>{formUrl.languageName}</MenuItem>
                ))}
              </TextField>
            }
            {!loading &&
              <button className="consentViewFormButton primary hollow" disabled={!selectedConsentFormUrl} onClick={handleViewFormClick}><FormattedMessage id="view_form" defaultMessage="View Form" /></button>
            }
          </div>
        </>
      }
    </div >
  );
};

export default ConsentFormUrlSelector;