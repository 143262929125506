import React, { useEffect, useRef, ReactElement, KeyboardEvent, useContext } from "react";
import Layouts from "constants/layout-constants";
import searchDataSet from "utilities/search";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import { ControlInput, FilterConfig } from "../models";
import FilterPanelStandardView from "./filter-panel-standard-view";
import FilterPanelMobileMapView from "./filter-panel-mobile-map-view";
import { ListControlPanelContext } from "../list-control-panel-store";
import "./filter-panel.scss";
import { useStore } from "zustand";

const searchInputDelay = 700;

interface IFilterPanel {
  dataset: any[];
  onFilter: (dataset: any[]) => void;
  showSearch: boolean;
  customSearch?: ReactElement;
  showMobileMapView?: boolean;
  className?: string;
  inputs: ControlInput[];
  searchFields?: string[] | ((dataset: any[]) => any[]);
}

export const FilterPanel = (props: IFilterPanel): ReactElement => {

  const { dataset, onFilter, showSearch, customSearch, showMobileMapView, inputs, searchFields } = props;
  const layout = useSelector((state: any) => state.appReducer.layout);
  const filterStore = useContext(ListControlPanelContext);
  const { filterConfig, setFilterConfig, isLoading } = useStore(filterStore!, s => s);
  const searchTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    filterData();
  }, [filterConfig]);

  useEffect(() => {
    filterData();
  }, [props.dataset]);

  useEffect(() => {
    handleFilterConfigChange(filterConfig!);
  }, [isLoading]);

  useEffect(() => {
    handleFilterConfigChange(filterConfig!);
  }, [filterConfig]);

  const handleSearchKeyUp = (event: KeyboardEvent) => {
    //already responds to every key press. No need to take any action when the user presses enter.
    if (event.key == "Enter") {
      return;
    }
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }
    const value = event.target ? (event.target as HTMLInputElement).value.trim() : "";
    if (value != "") {
      searchTimeout.current = setTimeout(() => {
        filterData(undefined, value);
      }, searchInputDelay);
    } else {
      filterData();
    }
  };

  const handleFilterConfigChange = (newFilterConfig: FilterConfig) => {
    //attempt to maintain active values when the config changes
    newFilterConfig.forEach((newFilterGroup, newFilterGroupIndex) => {
      newFilterGroup.filters.forEach((newFilter, newFilterIndex) => {
        const existingFilter = filterConfig![newFilterGroupIndex]?.filters[newFilterIndex];
        if (existingFilter) {
          let newActiveValues = newFilter.activeValues;
          if (existingFilter?.isTouched) {
            newFilter.isTouched = true;
            newActiveValues = [];
          }
          existingFilter.activeValues.forEach((activeValue) => {
            if (
              newFilter.allPossibleValues.some(possibleValue => isEqual(possibleValue, activeValue)) &&
              !newActiveValues.some(newFilterActiveValue => isEqual(newFilterActiveValue, activeValue))
            ) {
              newActiveValues.push(activeValue);
            }
          });
          newFilter.activeValues = newActiveValues;
        }
      });
    });
    setFilterConfig(newFilterConfig);
  };

  const clearSelectedFilter = (filterGroupIndex: number, filterIndex: number, filterActiveValueIndex: number) => {
    const newFilters = [...filterConfig!];
    newFilters[filterGroupIndex].filters[filterIndex].activeValues.splice(filterActiveValueIndex, 1);
    newFilters[filterGroupIndex].filters[filterIndex].isTouched = true;
    setFilterConfig(newFilters);
    filterData(newFilters);
  };

  const handleFilterGroupsChange = (newFilterGroups: FilterConfig) => {
    setFilterConfig(newFilterGroups);
    filterData(newFilterGroups);
  };

  const filterData = (filters?: FilterConfig, search?: string) => {
    if (dataset?.length && filterConfig) {
      const myFilters = filters == null ? filterConfig : filters;
      let filteredData = [...dataset];
      myFilters.forEach(filterGroup => {
        filterGroup.filters.forEach(filter => {
          if (filter.activeValues.length > 0) {
            const activeValues = (filter?.valueField) ? filter.activeValues.map(value => {
              if (filter.valueField) {
                return value[filter.valueField];
              }
            }) : filter.activeValues;
            filteredData = filter.filterFunction(filteredData, activeValues, filter?.filterFunctionExtraParam);
          }
        });
      });
      if (search) {
        filteredData = searchDataSet(filteredData, search, searchFields);
      }
      onFilter(filteredData);
    } else if (onFilter) {
      onFilter([]);
    }
  };

  return (
    <div className={`filterPanelContainer ${props.className ? props.className : ""}`}>
      {layout == Layouts.Mobile && showMobileMapView ?
        <FilterPanelMobileMapView
          showSearch={showSearch}
          customSearch={customSearch}
          handleSearchKeyUp={handleSearchKeyUp}
          handleFilterGroupsChange={handleFilterGroupsChange}
          inputs={inputs}
        />
        :
        <FilterPanelStandardView
          showSearch={showSearch}
          customSearch={customSearch}
          handleSearchKeyUp={handleSearchKeyUp}
          handleFilterGroupsChange={handleFilterGroupsChange}
          clearSelectedFilter={clearSelectedFilter}
        />
      }
    </div>
  );
};

export default FilterPanel;