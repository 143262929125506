import "./options-selector.scss";

import React from "react";
import { FormattedMessage } from "react-intl";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormStatus } from "lib/form/model";
import { useFormStatus } from "lib/form/contexts";
import InfoHoverBox from "components/info-hover-box/info-hover-box";
import useGroupCreationFormStore from "datastore/groupCreationForm";

const OptionsSelector: React.VFC = () => {
  const [formStatus, setFormStatus] = useFormStatus();

  const formStore = useGroupCreationFormStore();

  const group = formStore.group;
  const isPending = group?.isPending;
  const isHidden = group?.isHidden;

  const handlePendingChange = (_, isChecked: boolean) => {
    formStore.setPending(isChecked);

    formStatus.dirtiness.setDirty(true, "options", "pending");

    setFormStatus(
      new FormStatus(formStatus)
    );
  };

  const handleHiddenChange = (_, isChecked: boolean) => {
    formStore.setHidden(isChecked);

    formStatus.dirtiness.setDirty(true, "options", "hidden");

    setFormStatus(
      new FormStatus(formStatus)
    );
  };

  return (
    <div className="options-selector-container">
      <div className="options-selector">
        <div>
          <FormControlLabel
            label={<FormattedMessage id="pending" defaultMessage="Pending" />}
            control={
              <Checkbox
                checked={isPending}
                onChange={handlePendingChange}
              />
            }
          />
          <InfoHoverBox head={<FormattedMessage id="pending" defaultMessage="Pending" />}>
            <p><FormattedMessage id="group_is_pending_description" defaultMessage="When a group is marked as pending, participants will not be able to see location, date, time or other information on the group list." /></p>
          </InfoHoverBox>
        </div>

        <div>
          <FormControlLabel
            label={<FormattedMessage id="group_is_hidden_for_join_now" defaultMessage="Hide from public list" />}
            control={
              <Checkbox
                checked={isHidden}
                onChange={handleHiddenChange}
              />
            }
          />
          <InfoHoverBox head={<FormattedMessage id="hide_group_head" defaultMessage="Hide from public list" />}>
            <p><FormattedMessage id="group_will_not_show_on_find_a_group_or_map" defaultMessage="Hidden groups will not appear for participants searching for a group. The participant must know the URL/Link to sign up for a hidden group." /></p>
          </InfoHoverBox>
        </div>
      </div>
    </div>
  );
};

export default OptionsSelector;