export interface IGroupLanguageDto {
  groupLanguageId?: number;
  groupId?: number;
  languageId: number;
}

export interface IGroupLanguageDtoCreationValues {
  groupLanguageId?: number;
  groupId?: number;
  languageId: number;
}

export class GroupLanguageDto implements IGroupLanguageDto {

  public groupLanguageId?: number;
  public groupId?: number;
  public languageId: number;

  constructor(creationValues: IGroupLanguageDtoCreationValues) {
    this.groupLanguageId = creationValues.groupLanguageId;
    this.groupId = creationValues.groupId;
    this.languageId = creationValues.languageId;
  }

  public export(): IGroupLanguageDtoCreationValues {
    return {
      groupLanguageId: this.groupLanguageId,
      groupId: this.groupId,
      languageId: this.languageId
    };
  }

  public clone(): GroupLanguageDto {
    return new GroupLanguageDto(this.export());
  }

}

export default GroupLanguageDto;