import { useState, useEffect, useMemo } from "react";
import { mapStateToProps, mapDispatchToProps } from "datastore/store";
import { connect } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { ViewNames } from "../course-list";
import Layouts from "constants/layout-constants";
import { SupportedLanguages } from "constants/language-constants";
import * as CourseFilterFunctions from "utilities/filters/course-filters";
import ListControlPanel from "components/list-control-panel";

const CourseListControls = (props) => {

  const { courses, onFilter, isLoading, viewValue, viewSetter, store: { layout } } = props;
  const allCourses = useMemo(() => {
    const newCourseList = [];
    courses.forEach((providerCourse) => {
      providerCourse.courses.forEach((course) => {
        newCourseList.push({
          ...course,
          providerName: providerCourse.provider.name
        });
      });
    });
    return newCourseList;
  }, [props.courses]);
  const intl = useIntl();
  const buildConfig = () => {

    const newFilterConfig = [
      {
        filterGroupHeading: intl.formatMessage({ id: "language", defaultMessage: "Language" }),
        filters: [
          {
            name: intl.formatMessage({ id: "language", defaultMessage: "Language" }),
            allPossibleValues: SupportedLanguages,
            displayField: "nativeName",
            valueField: "englishName",
            filterFunction: CourseFilterFunctions.filterCourseByLanguage,
            inputType: "typeAhead",
            activeValues: [],
            badgeLabel: intl.formatMessage({ id: "language", defaultMessage: "Language" })
          },
        ]
      }
    ];
    return newFilterConfig;
  };

  const [filterConfig, setFilterConfig] = useState(buildConfig());

  const searchFields = function (allCourses) {
    return allCourses.map(course => {
      return {
        name: course.name,
        description: course.description,
        providerName: course.providerName
      };
    });
  };

  useEffect(() => {
    const newConfig = buildConfig();
    setFilterConfig(newConfig);
  }, [courses, props.store.language]);

  const inputs = [];

  if (layout == Layouts.Desktop) {
    inputs.push(
      {
        text: <FormattedMessage id="add_group" defaultMessage="View" />,
        action: viewSetter,
        type: "select",
        options: [
          {
            value: ViewNames.Table,
            label: intl.formatMessage({ id: "list_view", defaultMessage: "List View" })
          },
          {
            value: ViewNames.Card,
            label: intl.formatMessage({ id: "card_view", defaultMessage: "Card View" })
          }
        ],
        value: viewValue
      }
    );
  }

  return (
    <div className="courseListControlsContainer">
      <ListControlPanel dataset={allCourses} isLoading={isLoading} filterConfig={filterConfig} onFilter={onFilter} inputs={inputs} searchFields={searchFields} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseListControls);