import create from "zustand";
import type { GroupCreationDto, LocationCreationDto, MeetingTimeCreationDto, FacilitatorCreationDto } from "lib/api/backend/model/group/GroupCreationDto";
import { AgeType } from "lib/data/model/enum";

export type GroupFormStore = {
  group: GroupCreationDto | null;
  setGroup: (group: GroupCreationDto) => void;
  setLanguage: (languageId: number) => void;
  setProgram: (providerId: number, programId: number, programName: string) => void;
  setLocation: (location: LocationCreationDto) => void;
  setGroupAgeType: (groupAgeTypeId: AgeType) => void;
  setStartDate: (startDate: Date | null) => void;
  setEndDate: (endDate: Date | null) => void;
  setMeetingTimeAtIndex: (meetingTime: MeetingTimeCreationDto, index: number) => void;
  setMaxEnrollment: (maxEnrollment: number) => void;
  setPending: (isPending: boolean) => void;
  setHidden: (isHidden: boolean) => void;
  setHasAcceptedYouthTerms: (hasAcceptedYouthTerms: boolean) => void;
  setFacilitatorAtIndex: (facilitators: FacilitatorCreationDto, index) => void;
  addEmptyMeetingTime: () => void;
  addEmptyFacilitator: () => void;
  dropMeetingTimeAtIndex: (index: number) => void;
  dropFacilitatorAtIndex: (index: number) => void;
  dropGroup: () => void;
  dropProgramAndLanguage: () => void;
  dropFacilitatorList: () => void;
};

const useGroupCreationFormStore = create<GroupFormStore>(
  (set): GroupFormStore => ({
    group: {
      programName: null,
      location: null,
      providerId: null,
      programId: null,
      languageId: null,
      startDate: null,
      endDate: null,
      maxEnrollment: null,
      stakeUnitNumber: null,
      groupAgeTypeId: null,
      isVirtual: false,
      isHidden: false,
      isPending: false,
      needsYouthTerms: false,
      hasAcceptedYouthTerms: false,
      meetingTimes: [],
      facilitators: []
    },
    setGroup: (group: GroupCreationDto) => {
      set(() => ({
        group: group
      }));
    },
    setLanguage: (languageId: number) => {
      set((state) => ({
        group: {
          ...state.group!,
          languageId: languageId
        }
      }));
    },
    setProgram: (providerId: number, programId: number, programName: string) => {
      set((state) => ({
        group: {
          ...state.group!,
          providerId: providerId,
          programId: programId,
          programName: programName
        }
      }));
    },
    setLocation: (location: LocationCreationDto) => {
      set((state) => ({
        group: {
          ...state.group!,
          location: location,
          stakeUnitNumber: location.unitNumber
        }
      }));
    },
    setGroupAgeType: (groupAgeTypeId: number) => {
      set((state) => ({
        group: {
          ...state.group!,
          groupAgeTypeId: groupAgeTypeId
        }
      }));
    },
    setStartDate: (startDate: Date | null) => {
      set((state) => ({
        group: {
          ...state.group!,
          startDate: startDate
        }
      }));
    },
    setEndDate: (endDate: Date | null) => {
      set((state) => ({
        group: {
          ...state.group!,
          endDate: endDate
        }
      }));
    },
    setMeetingTimeAtIndex(meetingTime: MeetingTimeCreationDto, index: number) {
      set((state) => {
        state.group!.meetingTimes[index] = meetingTime;

        const meetingTimesArray = state.group!.meetingTimes;
        return {
          group: {
            ...state.group!,
            meetingTimes: [...meetingTimesArray]
          }
        };
      });
    },
    setMaxEnrollment: (maxEnrollment: number) => {
      set((state) => ({
        group: {
          ...state.group!,
          maxEnrollment: maxEnrollment
        }
      }));
    },
    setPending(isPending) {
      set((state) => ({
        group: {
          ...state.group!,
          isPending: isPending
        }
      }));
    },
    setHidden(isHidden) {
      set((state) => ({
        group: {
          ...state.group!,
          isHidden: isHidden
        }
      }));
    },
    setFacilitatorAtIndex(facilitator, index) {
      set((state) => {
        state.group!.facilitators[index] = facilitator;

        const facilitatorsArray = state.group!.facilitators;
        return {
          group: {
            ...state.group!,
            facilitators: [...facilitatorsArray]
          }
        };
      });
    },
    setHasAcceptedYouthTerms: (hasAcceptedYouthTerms: boolean) => {
      set((state) => ({
        group: {
          ...state.group!,
          hasAcceptedYouthTerms: hasAcceptedYouthTerms
        }
      }));
    },
    addEmptyMeetingTime: () => {
      set((state) => ({
        group: {
          ...state.group!,
          meetingTimes: [
            ...state.group!.meetingTimes,
            {
              startTime: null,
              endTime: null,
              dayOfWeek: null,
              timeZoneName: null
            }
          ]
        }
      }));
    },
    addEmptyFacilitator: () => {
      set((state) => ({
        group: {
          ...state.group!,
          facilitators: [
            ...state.group!.facilitators,
            {
              name: "",
            } as FacilitatorCreationDto
          ]
        }
      }));
    },
    dropGroup: () => {
      set(() => ({
        group: {
          programName: null,
          location: null,
          providerId: null,
          programId: null,
          languageId: null,
          startDate: null,
          endDate: null,
          maxEnrollment: null,
          stakeUnitNumber: null,
          groupAgeTypeId: null,
          isVirtual: false,
          isHidden: false,
          isPending: false,
          needsYouthTerms: false,
          hasAcceptedYouthTerms: false,
          meetingTimes: [],
          facilitators: []
        }
      }));
    },
    dropProgramAndLanguage: () => {
      set((state) => ({
        group: {
          ...state.group!,
          providerId: null,
          programId: null,
          languageId: null
        }
      }));
    },
    dropMeetingTimeAtIndex: (index: number) => {
      set((state) => {
        const meetingTimes = state.group!.meetingTimes;
        meetingTimes.splice(index, 1);
        return {
          group: {
            ...state.group!,
            meetingTimes: meetingTimes
          }
        };
      });
    },
    dropFacilitatorAtIndex: (index: number) => {
      set((state) => {
        const facilitators = state.group!.facilitators;
        facilitators.splice(index, 1);
        return {
          group: {
            ...state.group!,
            facilitators: facilitators
          }
        };
      });
    },
    dropFacilitatorList: () => {
      set((state) => ({
        group: {
          ...state.group!,
          facilitators: []
        }
      }));
    }
  })
);

export default useGroupCreationFormStore;