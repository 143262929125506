import "./group-language-selector.scss";
import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useIntl, FormattedMessage } from "react-intl";
import Loading from "components/loading/loading";
import { useGroup } from "lib/data/contexts";
import { useFormStatus } from "lib/form/contexts";
import { Group } from "lib/data/model";
import { FormStatus, SaveState, RecordType } from "lib/form/model";
import { GetCoursesByLanguage } from "lib/api/backend/requests/courses";
import { CourseDto, CourseProviderGroupDto, CourseLanguageDto } from "lib/api/backend/model/course";
import { ServerResponseType } from "lib/api/backend/request-utils";
import usePreloadStore, { getLanguagesWithMultilingual } from "datastore/preload";
import GroupLanguageDto from "lib/api/backend/model/GroupLanguageDto";
import { getEmptyGroup } from "components/group-manager/group-manager";

interface ILanguageSelectorProps {
  className?: string;
  onChange?: (providerId: number) => void;
}

const GroupLanguageSelector: React.FC<ILanguageSelectorProps> = (props) => {
  const [group, setGroup] = useGroup();
  const [formStatus, setFormStatus] = useFormStatus();
  let lang: CourseLanguageDto | null = null;
  const languages = usePreloadStore(getLanguagesWithMultilingual);

  if (group && group.programLanguages && group.programLanguages.length > 0) {
    if (group.groupLanguages && group.groupLanguages[0]) {
      const langId = group.groupLanguages[0];
      lang = languages.filter(x => x.id == langId.languageId)[0];
    }
    else {
      lang = languages[0];
    }
  }

  const intl = useIntl();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [languageList, setLanguageList] = useState<CourseLanguageDto[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<CourseLanguageDto | null>(lang);
  const [courses, setCourses] = useState<CourseDto[] | null>(null);
  const [selectedCourse, setSelectedCourse] = useState<CourseDto | null>(group?.course ?? null);

  useEffect(() => {
    if (languageList.length == 0) {
      setLanguageList(languages);
    }
  }, []);

  useEffect(() => {
    //Call function to get courses.
    const getCourses = async () => {
      setLoading(true);
      const courseProviders = await GetCoursesByLanguage(selectedLanguage!.name);

      if (courseProviders!.responseType === ServerResponseType.Success) {
        const courses = CourseProviderGroupDto.mapToCourses(courseProviders.data);

        setCourses(courses);
      }

      if (selectedLanguage) {

        //some multilingual groups were set to language id 24, which does not represent the current quickreg concept of Multilingual
        //In QuickReg groups that are multilingual do not have any groupLanguages
        //24 does not always exist as a program language so setting multilingual groups to 24 means the language name will not appear in other views that only know the program languages
        //This fix will set multilingual groupLanguages to an empty array when the group is resaved and prevents 24 or any other non program language from being set in the future.
        const isSupportedProgramLanguage = languages.find(language => language.id !== 0 && language.id == selectedLanguage.id) != undefined;

        const newGroup = new Group({
          ...group!,
          groupLanguages: isSupportedProgramLanguage ?
            [
              new GroupLanguageDto({
                languageId: selectedLanguage.id
              })
            ]
            :
            []
        });

        if (selectedCourse) {
          newGroup.course = selectedCourse;
        } else {
          delete newGroup.course;
        }

        setGroup(newGroup);
      }
      setLoading(false);
    };

    if (selectedLanguage) {
      getCourses();
    }
  }, [selectedLanguage]);

  const handleLanguageChange = (event, newLang: any) => {
    setSelectedCourse(null);
    setSelectedLanguage(newLang);
    const newGroup = getEmptyGroup();
    setGroup(newGroup);
  };

  const handleCourseChange = (event, newCourse: CourseDto | null) => {
    setSelectedCourse(newCourse ?? null);

    formStatus.dirtiness.setDirty(true, "course");

    setFormStatus(
      new FormStatus(formStatus)
    );

    if (isCourseValid(newCourse ?? undefined)) {
      setGroup(
        new Group({
          ...group!,
          course: newCourse!,
          providerId: newCourse!.providerId
        })
      );
    } else {
      setGroup(
        new Group({
          ...group!,
          course: undefined,
          providerId: undefined
        })
      );
    }

    if (newCourse && props.onChange) {
      props.onChange(newCourse.providerId!);
    }
  };

  const isCourseValid = (course?: CourseDto) => {
    let isValid = true;

    const invalidCourseErrorMessage = intl.formatMessage({ id: "invalid_course_selection", defaultMessage: "Invalid course selection" });

    if (!course || !course.id) {
      isValid = false;
      formStatus.errors.addMessage(invalidCourseErrorMessage, "course");
    }
    else {
      formStatus.errors.removeMessage(invalidCourseErrorMessage, "course");
    }

    formStatus.validity.setValidity(isValid, "course");

    setFormStatus(
      new FormStatus(formStatus)
    );

    return isValid;
  };

  const courseHasErrors = (
    !formStatus.validity.isValid("course")
    && (
      formStatus.saveState === SaveState.ValidationFailed
      || formStatus.dirtiness.isDirty("course")
    )
  );

  const renderLanguageAutoComplete = () => {
    return (
      <div className="languageSelector">
        {!isLoading && (
          <Autocomplete
            getOptionLabel={(option) => option.description}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={languageList}
            disableClearable={selectedLanguage != null}
            disabled={formStatus.recordType === RecordType.Existing}
            value={selectedLanguage}
            onChange={handleLanguageChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <FormattedMessage id="language_select" defaultMessage="Select a Language" />
                }
              />
            )}
          />)
        }
      </div>
    );
  };

  const renderCourseAutoComplete = () => {
    return (
      <>
        {!isLoading && selectedLanguage &&
          <Autocomplete
            onChange={handleCourseChange}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={courses ?? []}
            disableClearable={selectedCourse != null}
            disabled={formStatus.recordType === RecordType.Existing}
            value={selectedCourse}
            renderInput={(params) => (
              <TextField
                {...params}
                label={<FormattedMessage id="group_type" defaultMessage="Group Type" />}
                error={courseHasErrors}
                helperText={
                  courseHasErrors
                    ? formStatus.errors.getMessages("course").map(
                      (message, index) => (
                        <span key={`course-picker-autocomplete-message-${index}`}>
                          {message}
                        </span>
                      ))
                    : formStatus.messages.getMessages("course").map(
                      (message, index) => (
                        <span key={`course-picker-autocomplete-message-${index}`}>
                          {message}
                        </span>
                      ))
                }
              />)
            }
          />
        }
      </>
    );
  };

  return (
    <div className={`courseLanguageSelectorContainer ${props.className}`}>
      <Loading loading={isLoading} small={true} />
      {!isLoading && !group?.course &&
        <p className="helperText"><FormattedMessage id="select_group_type" defaultMessage="Please select the type of group you want to set up." /></p>
      }
      <div className="selectContainer">
        {renderLanguageAutoComplete()}
        {renderCourseAutoComplete()}
      </div>
    </div>
  );
};

export default GroupLanguageSelector;
