export const ApiUrl = `${window.location.protocol}//${window.location.host}${process.env.REACT_APP_API_URL}`;

export const ApplicationName = "QuickReg";

export const AuthBroadCastMessages = Object.freeze({
  SignOut: "sign out", //message broadcasted to all open tabs telling them to sign out when the user signs out in one tab
  SignOutImminent: "sign out soon", //message broadcasted when sign out is imminent
  StaySignedIn: "stay signed in", //message broadcasted when user chooses to stay signed in.
  SignedIn: "signed in", //message broadcasted when a user signs in.
  ImpersonationStart: "impersonation start",
  ImpersonationEnd: "impersonation end",
});

export enum SignOutReason {
  SignUpSessionActive = "signUpActive"
}

export const AuthBroadcastChannelName = `${ApplicationName} Auth Broadcast`;

export const ImpersonationCookieName = "Impersonation";

export type Permission = {
  name: string;
  write: boolean;
}

export const QueryParameterNames = Object.freeze({
  ReturnUrl: "returnUrl",
  State: "state",
  ProviderId: "providerId",
  Message: "message"
});

export const LogoutActions = Object.freeze({
  LogoutCallback: "logout-callback",
  Logout: "logout",
  LoggedOut: "logged-out"
});

export const LoginActions = Object.freeze({
  Login: "login",
  LoginCallback: "login-callback",
  LoginFailed: "login-failed"
});

const prefix = "account";

export const ApplicationPaths = Object.freeze({
  DefaultLoginRedirectPath: "/",
  SignInPath: `${prefix}/sign-in`,
  SignInCallback: `/${LoginActions.LoginCallback}`,
  SignOutPath: `${prefix}/sign-out`,
  SignOutCallback: `/${LogoutActions.LogoutCallback}`,
  CurrentUserPath: `${prefix}/current-user`,
  IsAuthenticatedCheckPath: `${prefix}/is-authenticated`
});

export const AllowedEmployeeStatusCodes = Object.freeze(["HO", "PS"]);

const permissionsPrefix = "QuickReg";

export const Permissions = Object.freeze({
  GroupIndexRead: Object.freeze({
    name: `${permissionsPrefix}GroupIndex`,
    write: false
  }),
  GroupIndexWrite: Object.freeze({
    name: `${permissionsPrefix}GroupIndex`,
    write: true
  }),
  NewGroupRead: Object.freeze({
    name: `${permissionsPrefix}NewGroup`,
    write: false
  }),
  NewGroupWrite: Object.freeze({
    name: `${permissionsPrefix}NewGroup`,
    write: true
  }),
  ManageGroupsRead: Object.freeze({
    name: `${permissionsPrefix}ManageGroups`,
    write: false
  }),
  ManageGroupsWrite: Object.freeze({
    name: `${permissionsPrefix}ManageGroups`,
    write: true
  }),
  EditGroupWrite: Object.freeze({
    name: `${permissionsPrefix}EditGroup`,
    write: true
  }),
  StudentsRead: Object.freeze({
    name: `${permissionsPrefix}Students`,
    write: false
  }),
  CourseSignUpWrite: Object.freeze({
    name: `${permissionsPrefix}CourseSignup`,
    write: true,
  }),
  CourseCatalogRead: Object.freeze({
    name: `${permissionsPrefix}CourseCatalog`,
    write: false
  }),
  PrintStudentCompletionCertificatesRead: Object.freeze({
    name: `${permissionsPrefix}PrintCompletionCertificates`,
    write: false
  }),
  PrintStudentCompletionCertificatesWrite: Object.freeze({
    name: `${permissionsPrefix}PrintCompletionCertificates`,
    write: true
  })
});

export const EndPoints = Object.freeze({
  GetUserGroups: Object.freeze({
    path: "groups/user-groups",
    method: "get",
    requiredPermissions: [Permissions.GroupIndexRead]
  }),
  GetGroupWithProtectedData: Object.freeze({
    path: "groups",
    method: "get",
    requiredPermissions: [Permissions.ManageGroupsRead, Permissions.NewGroupWrite, Permissions.EditGroupWrite]
  }),
  CreateGroup: Object.freeze({
    path: "groups/group",
    method: "post",
    requiredPermissions: [Permissions.NewGroupWrite]
  }),
  UpdateGroup: Object.freeze({
    path: "groups",
    method: "put",
    requiredPermissions: [Permissions.EditGroupWrite]
  }),
  ConcludeGroup: Object.freeze({
    path: "groups",
    method: "put",
    requiredPermissions: [Permissions.GroupIndexWrite]
  }),
  CancelGroup: Object.freeze({
    path: "groups",
    method: "put",
    requiredPermissions: [Permissions.GroupIndexWrite]
  }),
  GetCourses: Object.freeze({
    path: "courses/courses",
    method: "get",
    requiredPermissions: [Permissions.CourseCatalogRead]
  }),
  GetStudents: Object.freeze({
    path: "students/user-students",
    method: "get",
    requiredPermissions: [Permissions.StudentsRead]
  }),
  GetCertificate: Object.freeze({
    path: "certificates",
    method: "get",
    requiredPermissions: [Permissions.ManageGroupsWrite]
  }),
  CompleteStudents: Object.freeze({
    path: "groups",
    method: "put",
    requiredPermissions: [Permissions.ManageGroupsWrite]
  }),
  CompleteLimitedStudents: Object.freeze({
    path: "groups",
    method: "put",
    requiredPermissions: [Permissions.ManageGroupsWrite]
  }),
  DropStudents: Object.freeze({
    path: "groups",
    method: "put",
    requiredPermissions: [Permissions.ManageGroupsWrite]
  }),
  DropLimitedStudents: Object.freeze({
    path: "groups",
    method: "put",
    requiredPermissions: [Permissions.ManageGroupsWrite]
  }),
  UpdateConsents: Object.freeze({
    path: "groups",
    method: "put",
    requiredPermissions: [Permissions.ManageGroupsWrite]
  }),
  UpdateLimitedConsents: Object.freeze({
    path: "groups",
    method: "put",
    requiredPermissions: [Permissions.ManageGroupsWrite]
  }),
  GetSites: Object.freeze({
    path: "sites/user-sites",
    method: "get",
    requiredPermissions: [Permissions.NewGroupWrite, Permissions.EditGroupWrite]
  }),
  GetFacilitators: Object.freeze({
    path: "facilitators",
    method: "get",
    requiredPermissions: [Permissions.NewGroupWrite, Permissions.EditGroupWrite]
  }),
  GetStakeMembers: Object.freeze({
    path: "stake",
    method: "get",
    requiredPermissions: [Permissions.NewGroupWrite, Permissions.EditGroupWrite]
  }),
  stakeMemberHasChurchAccount: Object.freeze({
    path: "facilitators/stake-member-has-church-account",
    method: "get",
    requiredPermissions: [Permissions.NewGroupWrite, Permissions.EditGroupWrite]
  }),
  SaveOutcomes: Object.freeze({
    path: "groups",
    method: "post",
    requiredPermissions: [Permissions.GroupIndexWrite]
  })
});
