export default function getValueFromQueryStringKey(searchKey){
  const keyValues = buildKeyValueTable();
  return (keyValues[searchKey]) ? keyValues[searchKey] : undefined;
}

export function buildKeyValueTable() {
  const queryString = decodeURI(window.location.href.substring(window.location.href.indexOf("?")+1));
  const split = queryString.split("&");
  let keyValues = {};
  for(let i = 0; i < split.length; i++){
    const assignmentIndex = split[i].indexOf("=");
    const key = split[i].substring(0,assignmentIndex);
    const value = split[i].substring(assignmentIndex + 1);
    keyValues[key] = value;
  }
  return keyValues;
}

export function getRawQueryString(includeQuestionMark){
  let indexModifier = (includeQuestionMark)? 0 : 1;
  return window.location.href.substring(location.href.indexOf("?") + indexModifier);
}