interface IAuthStatus {
  authenticated: boolean;
  sessionTimeoutLengthMs: number;
}

export default class AuthStatus {

  public authenticated: boolean;
  public sessionTimeoutLengthMs: number;

  constructor(creationValues: IAuthStatus) {
    this.authenticated = creationValues.authenticated;
    this.sessionTimeoutLengthMs = creationValues.sessionTimeoutLengthMs;
  }

}