import "./group-finder-map-view-list.scss";

import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Loading from "components/loading/loading";
import GroupFinderCard from "../group-finder-card/group-finder-card";
import { GroupDetails } from "lib/models-v2";

interface IProps {
  isLoading: boolean;
  groups: GroupDetails[];
  haveLocation: boolean;
  onSelect: (groupIndex: number) => void;
}

const GroupFinderMapViewList: React.VFC<IProps> = (props: IProps) => {
  const { groups } = props;

  const intl = useIntl();

  return (
    <div className="groupFinderMapViewListContainer">
      <div className="listContainer">
        <Loading loading={props.isLoading} message={intl.formatMessage({ id: "getting_groups", defaultMessage: "Getting Groups" })} blackout={true} />
        <div className="scrollList">
          {groups && groups.map((group, group_idx) => {
            return (
              <GroupFinderCard key={`group-finder-card-${group_idx}`} group={group} onSelect={() => props.onSelect(group_idx)} />
            );
          })}
          {(!groups || (groups && groups.length === 0)) && !props.isLoading &&
            <p className="no-location">
              {props.haveLocation ?
                <FormattedMessage id="groups_not_found_search_message" defaultMessage="No groups found in your search area."></FormattedMessage>
                :
                <FormattedMessage id="enter_search_location" defaultMessage="Enter a search location to see results." />
              }
            </p>
          }
        </div>
      </div>
    </div>
  );
};

export default GroupFinderMapViewList;