import AddressDto, { /* IAddressDto */ } from "../../api/backend/model/AddressDto";
import { LocationType } from "./enum";
import GeoCoordinateDto, { /* IGeoCoordinateDto */ } from "../../api/backend/model/GeoCoordinateDto";

export default class Location {
  public id?: number;
  public propertyId?: number; // Same as `Site.id`
  public stakeUnitNumber?: number;
  public name?: string;
  public address?: AddressDto;
  public coordinates?: GeoCoordinateDto;
  public type: LocationType;

  constructor(creationValues: Location) {
    this.id = creationValues.id;
    this.propertyId = creationValues.propertyId;
    this.stakeUnitNumber = creationValues.stakeUnitNumber;
    this.name = creationValues.name;
    this.address = creationValues.address;
    this.coordinates = creationValues.coordinates;
    this.type = creationValues.type;
  }
}